import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { GI } from "data";
import Link from "@material-ui/core/Link";
import { MyButton } from "pages/components";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            textAlign: "center",
            padding: theme.spacing(10, 0),
            backgroundColor: "#1a1a84",
            color: "white",
        },
        typography: {
            margin: theme.spacing(1),
        },
        greeting: {
            letterSpacing: "-0.9px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "24px",
            },
        },
        welcome: {
            color: "#ffbbd4",
            fontWeight: 700,
        },
        pink: {
            color: "#ffbbd4",
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                // padding: "0 calc((100% - 296px) / 2)",
                padding: "0 32px",
            },
        },
        subColumnItem: {
            flex: "0 0 auto",
        },
    };
});

export default function RecruitmentIntro(params) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid
                    container
                    direction="column"
                    //justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} className={classes.subColumnItem}>
                        <Typography
                            variant={isMobile ? "subtitle1" : "h5"}
                            className={classes.welcome}
                        >
                            Welcome!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.subColumnItem}>
                        <Typography variant={"h4"} className={classes.greeting}>
                            <b>
                                어서오세요, 곧 2020년 하반기
                                <br />
                                SW인재{" "}
                                <span className={classes.pink}>
                                    통합 공개채용
                                </span>
                                이 {isMobile && <br />}
                                시작됩니다
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.subColumnItem}>
                        <Typography variant="body1">
                            유망 기업들의 면접 제안을 한 번에 받을 수 있는{" "}
                            <b>One stop</b> 통합 공채. {!isMobile && <br />}나와
                            잘 맞는 기업을 찾고 있다면 지금 바로 지원하세요!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.subColumnItem}>
                        <GI />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
