import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import DevStarXHeaderD from "./DevStarXHeaderD";
import { CompanyServiceHeaderD, ServiceFooterD } from "pages/components";
import {
    Container,
    Box,
    Typography,
    Grid,
    Divider,
    Button,
    Card,
    CardContent,
    CardActions,
} from "@material-ui/core";
import { TXImage } from "../../../components/tx";
import { useStyles } from "./styleDevStarXD";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { profileData } from "data/profileData";
import { getCompanies, getDevstarxProfiles } from "apis/firestore/fetch";
import { devstarxCompanies, nickNameMap } from "../../../data";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { slice } from "lodash";

function shuffle(array) {
    var m = array?.length,
        t,
        i;

    // While there remain elements to shuffle…
    while (m) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
}

const DevStarXD = (props) => {
    useEffect(() => window.scrollTo({ top: 0 }), []);

    const classes = useStyles();
    const history = useHistory();
    // const [popUpOpen, setPopUpOpen] = useState(true);
    // const popUpOpenChangeHandler = () => {
    //     setPopUpOpen((prev) => !prev);
    // };
    const [devstarx, setDevstarx] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        (async () => {
            const result = await getCompanies();
            //console.log("companies :", result);
            setCompanies(
                result.filter(
                    (company) =>
                        company.hirings.length > 0 &&
                        company.registrationNumber === "4358800655"
                )
            );
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const result = await getDevstarxProfiles();
            //console.log("devstarx :", result);
            setDevstarx(shuffle(result));
        })();
    }, []);

    const [numOfDevstarx, setNumOfDevstarx] = useState(12);

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 데브스타X</title>
            </Helmet>
            <CompanyServiceHeaderD />
            {/* <DevStarXHeaderD /> */}
            {/* {popUpOpen ? (
                <Box className={classes.ebPopup}>
                    <Typography className={classes.ebPopupTitle}>
                        지금 신청하면 누릴 수 있는<br></br>
                        특별한 얼리버드 혜택!
                    </Typography>
                    {<Box className={classes.ebPopupBox1}>
                        <Typography className={classes.ebPopupBoxTxt1}>
                            3월 22일 이내 신청 시
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt2_1}>
                            총 300만원의 채용 할인권 제공
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt3}>
                            (200만원 1장 + 100만원 1장)
                        </Typography>
                    </Box>}
                    {<Box className={classes.ebPopupBox2}>
                        <Typography className={classes.ebPopupBoxTxt1}>
                            3월 23 ~ 30일 이내 신청 시
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt2_2}>
                            총 200만원의 채용 할인권 제공
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt3}>
                            (100만원 2장)
                        </Typography>
                    </Box>}
                    <Box className={classes.ebPopupBox1}>
                        <Typography className={classes.ebPopupBoxTxt1}>
                            4월 15일 내 참가 신청 시
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt2_2}>
                            총 100만원의 채용 할인권 제공
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt3}>
                            (50만원 할인권 2장)
                        </Typography>
                    </Box>
                    <Box className={classes.ebPopupOffBtnWrapper}>
                        <Box
                            className={classes.ebPopupOffBtn}
                            onClick={popUpOpenChangeHandler}
                        >
                            <Typography className={classes.ebPopupOffBtnTxt}>
                                닫기
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box className={classes.ebBtnBox}>
                    <Typography className={classes.ebBtnBoxTxt}>
                        💎 얼리버드 혜택
                    </Typography>
                    <Box
                        className={classes.ebBtn}
                        onClick={popUpOpenChangeHandler}
                    >
                        <Typography className={classes.ebBtnTxt}>
                            확인하기
                        </Typography>
                    </Box>
                </Box>
            )} */}

            <Box className={classes.section1} id="intro"></Box>
            <Box className={classes.section2}></Box>
            <Box className={classes.section3}>
                <Container
                    maxWidth="md"
                    classes={{ maxWidthMd: classes.maxWidthMd }}
                    disableGutters
                >
                    <Box className={classes.pointToScroll} id="companies"></Box>
                    <Typography className={classes.sectionTitle}>
                        공개영입 포지션{" "}
                        <Typography className={classes.totalOfDevstarx}>
                            (
                            {devstarxCompanies
                                .map((company) => company.hirings.length)
                                .reduce(
                                    (previous, current) => previous + current
                                )}
                            개+, 추가 중)
                        </Typography>
                    </Typography>
                    <Grid container spacing={2}>
                        {devstarxCompanies.map((company, index) => (
                            <Grid item xs={6} lg={4}>
                                <Card className={classes.companyCard}>
                                    <CardContent
                                        className={classes.companyCardContent}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            className={classes.company}
                                            gutterBottom
                                        >
                                            {company.name}
                                        </Typography>
                                        <Typography
                                            className={classes.tag}
                                            gutterBottom
                                        >
                                            {company.slogan}
                                        </Typography>
                                        <Box className={classes.positionWrap}>
                                            <Typography className={classes.pos}>
                                                {company.hirings
                                                    .map(
                                                        (hiring) =>
                                                            hiring.position
                                                    )
                                                    .join(", ")}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            className={classes.cardBtn}
                                            onClick={() => {
                                                window.open(
                                                    `https://talentx.co.kr/scout/${company.registrationNumber}`,
                                                    "_blank"
                                                );
                                            }}
                                            disabled={
                                                !company.registrationNumber
                                            }
                                        >
                                            개발팀 보기
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Box className={classes.pointToScroll} id="devstars"></Box>
                    <Typography className={classes.sectionTitle}>
                        공개이직 개발자{" "}
                        <Typography className={classes.totalOfDevstarx}>
                            ({devstarx.length}명+, 모집중)
                        </Typography>
                    </Typography>
                    <Typography className={classes.contentInfo}>
                        참가 개발자들의 이력을 확인해보세요. 귀사의 영입제안을
                        기다리고 있습니다.
                    </Typography>
                    <Box className={classes.displayInline}>
                        <img
                            src={require("assets/icon-error-filled-24-dp.png")}
                            srcSet={`${require("assets/icon-error-filled-24-dp@2x.png")} 2x, ${require("assets/icon-error-filled-24-dp@3x.png")} 3x`}
                        ></img>
                        <Typography className={classes.contentAdditInfo}>
                            {" "}
                            보다 상세한 정보는 본 프로그램 참여 시 앱을 통해
                            열람 가능합니다.
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {devstarx
                            ?.slice(0, numOfDevstarx)
                            .map((profile, idx) => (
                                <Grid item xs={6} lg={4}>
                                    <Box
                                        key={idx}
                                        className={classes.profileCard}
                                    >
                                        <Box
                                            className={
                                                classes.ProfileCardHeader
                                            }
                                        >
                                            <Typography
                                                className={classes.nickName}
                                            >
                                                {nickNameMap.get(profile.id) ??
                                                    "닉작명중..."}
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.participationLabel
                                                }
                                            >
                                                참가번호
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.participationNumber
                                                }
                                            >
                                                {profile.id}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.careerSection}>
                                            <Typography
                                                className={classes.hopePosition}
                                            >
                                                {profile.position}
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.hopePositionYearsOfExp
                                                }
                                            >
                                                {profile.yearsOfExp ?? "신입"}
                                            </Typography>
                                            {/* <Typography
                                            className={classes.totalYearsOfExp}
                                        >
                                            | 총 {profile.yearsOfTotalExp}
                                        </Typography> */}
                                        </Box>
                                        <Box className={classes.itemHeader}>
                                            <Typography
                                                className={classes.itmeLabel}
                                            >
                                                보유기술
                                            </Typography>
                                            <Typography
                                                className={classes.numOfitem}
                                            >
                                                {profile.skills.length}개
                                            </Typography>
                                        </Box>
                                        <Box className={classes.skill}>
                                            {profile.skills.map((skill, idx) =>
                                                idx ? ", " + skill : "" + skill
                                            )}
                                        </Box>
                                        <Divider
                                            className={classes.brightDivider}
                                        />
                                        <Box className={classes.itemHeader}>
                                            <Typography
                                                className={classes.itmeLabel}
                                            >
                                                공개 포트폴리오 수
                                            </Typography>
                                            <Typography
                                                className={classes.numOfitem}
                                            >
                                                {profile.numOfPjt +
                                                    profile.numOfCareer}
                                                개
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                    </Grid>
                    {numOfDevstarx < devstarx.length && (
                        <Box
                            className={classes.moreBtn}
                            onClick={() => setNumOfDevstarx((prev) => prev + 6)}
                        >
                            <Typography className={classes.moreBtnText}>
                                더보기
                            </Typography>
                            <ExpandMoreIcon className={classes.moreBtnIcon} />
                        </Box>
                    )}
                </Container>
            </Box>
            <Box className={classes.section4}></Box>
            {/* <Box className={classes.section5}></Box> */}
            <Box className={classes.section6}></Box>
            <Box className={classes.section7}></Box>
            {/* <Box className={classes.section8}></Box> */}
            {/* <Box className={classes.section9} id="schedule"></Box> */}
            {/* <Box className={classes.section10} id="FAQ"></Box> */}
            {/* <Box
                className={classes.applyBtn}
                onClick={() => {
                    props.history.push("/company-application");
                }}
            >
                <Typography className={classes.applyBtnText}>
                    기업 회원 신청하기
                </Typography>
            </Box> */}
            <RouterLink
                to="/company-contract"
                className={classes.fixedButtonRouteLink}
            >
                <Box className={classes.fixedButton}>
                    <Typography className={classes.fixedButtonName}>
                        기업 멤버십 신청하기
                    </Typography>
                    <Typography className={classes.fixedButtonDesc}>
                        새로운 가격을 확인
                    </Typography>
                </Box>
            </RouterLink>
            <ServiceFooterD backgroudColor={"#2c2c2c"} />
        </React.Fragment>
    );
};

export default DevStarXD;
