import React from "react";

export function TXImage(props) {
    const { imgName, ...rest } = props;
    return (
        <img
            src={require(`assets/${imgName}.png`)}
            srcSet={`${require(`assets/${imgName}@2x.png`)} 2x, ${require(`assets/${imgName}@3x.png`)} 3x`}
            alt={imgName}
            {...rest}
        ></img>
    );
}
