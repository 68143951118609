import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles(() => ({
    root: (props) => ({
        backgroundColor: "#f8f8f8",
        paddingBottom: 40,
    }),
    inner: (props) => ({ padding: props.isMobile ? 4 : 0 }),
    headSection: (props) => ({
        position: "relative",
        textAlign: "center",
        width: "100%",
        height: props.isMobile ? 377 : 349,
        paddingTop: props.isMobile ? 97 : 124,
        backgroundImage: "linear-gradient(to bottom, #0b48ff, #3775ff)",
    }),
    newHeadSection: (props) => ({
        position: "relative",
        width: "100%",
        paddingTop: props.isMobile ? 128 : 110,
    }),
    newHeadImg: (props) =>
        props.isMobile
            ? {
                  height: 32,
                  width: 32,
              }
            : { height: 52, width: 52 },
    newHeadTitle: (props) =>
        props.isMobile
            ? {
                  marginTop: 24,
                  marginBottom: 48,
                  ...Texts.basic(24, "bold", "#111111", 1.38),
                  whiteSpace: "pre-wrap",
              }
            : {
                  marginTop: 32,
                  marginBottom: 80,
                  ...Texts.basic(40, "bold", "#111111", 1.4),
                  whiteSpace: "pre-wrap",
              },
    headTitle: (props) =>
        props.isMobile
            ? { ...Texts.basic(28, "bold", "#ffffff", 1.29) }
            : { ...Texts.basic(44, "bold", "#ffffff", 1.36) },
    headImg: (props) =>
        props.isMobile
            ? {
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
              }
            : { position: "absolute", bottom: 0, right: 85 },
    companyBox: {
        marginTop: 44,
    },
    newCompanyBox: (props) => ({
        borderRadius: 20,
        padding: props.isMobile ? 24 : 40,
        backgroundColor: "#ffffff",
        boxShadow: "0 4px 40px 0 #e5e5e5",
        marginBottom: props.isMobile ? 24 : 30,
    }),
    scrollToBox: (props) => ({
        position: "relative",
        top: props.isMobile ? -100 : -120,
    }),
    slogan: (props) => ({
        ...Texts.basic(24, "bold", "#111111", 1.33),
        marginBottom: 16,
        marginTop: 40,
        whiteSpace: props.isMobile ? "pre-wrap" : undefined,
    }),
    companyHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    newSlogan: (props) => ({
        ...Texts.basic(20, "bold", "#111111", 1.4),
        marginBottom: 32,
        whiteSpace: props.isMobile ? "pre-wrap" : undefined,
    }),
    devTeamBtn: (props) => ({
        borderRadius: props.isMobile ? 8 : 22,
        backgroundColor: "#3c6af5",
        padding: "13px 16px 12px 20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginTop: props.isMobile ? 24 : 0,
    }),
    devTeamBtnText: {
        ...Texts.basic(14, 500, "#ffffff", 1.36),
        marginRight: 8,
    },
    infoWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 21,
    },
    logo: {
        height: 40,
        width: 40,
        //borderRadius: "50%",
        border: "solid 1px #eeeeee",
        marginRight: 12,
    },
    newLogo: (props) => ({
        height: props.isMobile ? 64 : 72,
        width: props.isMobile ? 64 : 72,
        borderRadius: 12,
        border: "solid 1px #eeeeee",
        marginRight: 12,
    }),
    company: {
        display: "flex",
        flexDirection: "row",
    },
    premium: {
        height: 20,
        marginLeft: 8,
    },
    name: {
        flexShrink: 0,
        ...Texts.basic(14, "bold", "#111111", 1.57),
    },
    service: {
        ...Texts.basic(12, "normal", "#111111", 1.75),
    },
    ceo: {
        ...Texts.basic(12, "normal", "#ababab", 1.75),
    },
    imagesWrap: {
        display: "flex",
        overflowX: "auto",
        overflowY: "hidden",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 24,
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    imageBox: (props) =>
        props.isMobile
            ? {
                  flexShrink: 0,
                  width: 84,
                  height: 68,
                  backgroundColor: "#eeeeee",
                  borderRadius: 8,
                  marginRight: 10,
              }
            : {
                  flexShrink: 0,
                  width: 256,
                  height: 161,
                  backgroundColor: "#eeeeee",
                  borderRadius: 4,
                  marginRight: 12,
              },
    companyCardDivider: (props) =>
        props.isMobile
            ? {
                  marginTop: 24,
                  marginBottom: 24,
                  marginLeft: -24,
                  marginRight: -24,
                  backgroundColor: "#eeeeee",
              }
            : {
                  marginTop: 40,
                  marginBottom: 32,
                  marginLeft: -40,
                  marginRight: -40,
                  backgroundColor: "#eeeeee",
              },
    positionWrap: (props) => ({
        backgroundColor: "#f4f4f4",
        borderRadius: 12,
        padding: props.isMobile ? 16 : 20,
        height: "100%",
    }),
    positionName: (props) => ({
        ...Texts.basic(props.isMobile ? 14 : 16, 500, "#111111", 1.38),
        marginBottom: 4,
    }),
    skillsWrap: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    skillBox: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginTop: 8,
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        backgroundColor: "#454545",
    },
    skillName: {
        ...Texts.basic(12, 500, "#ffffff", 1.67),
    },
    hiring: (props) => ({
        //display: props.isMobile ? "block" : "inline",
        flexShrink: 0,
        marginRight: 12,
        marginBottom: 16,
        ...Texts.basic(16, "bold", "#0c46f2", 1.5),
    }),
    positionsWrap: (props) => ({
        //display: props.isMobile ? "flex" : "inline-flex",
        //flexDirection: "row",
        //alignItems: "center",
        marginBottom: 18,
        //flexWrap: "wrap",
    }),
    scouting: (props) => ({
        marginBottom: 12,
        ...Texts.basic(props.isMobile ? 12 : 16, "bold", "#111111", 1.38),
    }),
    position: {
        flexShrink: 0,
        ...Texts.basic(14, "normal", "#2c2c2c", 1.75),
        marginBottom: 4,
    },
    hardSkillsWrap: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 8,
        flexWrap: "wrap",
    },
    hardSkillBox: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: 8,
        borderRadius: 4,
        border: "solid 1px #eeeeee",
    },
    hardSkillIcon: { marginRight: 4 },
    hardSkill: {
        ...Texts.basic(12, "bold", "#111111", 1.67),
    },
    detailBtn: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: 40,
    },
    detail: {
        ...Texts.basic(14, "bold", "#787878", 1.57),
        marginRight: 4,
    },
    disclaimer: {
        ...Texts.basic(12, "bold", "#929292", 1.5),
        marginTop: 40,
        //marginBottom: 40,
    },
    btn: {
        position: "fixed",
        bottom: 20,
        left: "50%",
        marginLeft: -160,
        width: 320,
        paddingTop: 18,
        paddingBottom: 18,
        textAlign: "center",
        ...Texts.basic(16, "bold", "#ffffff", 1.5),
        borderRadius: 4,
        //boxShadow: "0 4px 12px 0 #9db4fa, 0 2px 8px 0 #9db4fa",
        backgroundColor: "#0c46f2",
        cursor: "pointer",
    },
    btnFixedWrap: {
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    fullWidthBtn: {
        position: "relative",
        margin: 20,
        padding: 18,
        textAlign: "center",
        ...Texts.basic(16, "bold", "#ffffff", 1.5),
        borderRadius: 4,
        //boxShadow: "0 4px 12px 0 #9db4fa, 0 2px 8px 0 #9db4fa",
        backgroundColor: "#0c46f2",
        cursor: "pointer",
    },
    modalWrap: (props) => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: props.isMobile ? "100%" : 880,
        height: "auto",
        textAlign: "center",
    }),
    modalCloseBtn: (props) =>
        props.isMobile
            ? {
                  position: "absolute",
                  bottom: 25,
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  paddingTop: 11,
                  paddingBottom: 11,
                  paddingLeft: 31,
                  paddingRight: 31,
                  ...Texts.basic(16, "normal", "#ffffff", 1.38),
                  cursor: "pointer",
              }
            : {
                  position: "absolute",
                  top: 48,
                  right: 48,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 27,
                  paddingRight: 27,
                  ...Texts.basic(20, 500, "#ffffff", 1.4),
                  cursor: "pointer",
              },
    modalImage: {
        display: "block",
        width: "100%",
        height: "100%",
    },
    photoIndicator: (props) => ({
        marginTop: props.isMobile ? 20 : 28,
        ...Texts.basic(props.isMobile ? 16 : 20, 500, "#ffffff", 1.38),
        opacity: 0.5,
    }),
}));
