import { makeStyles } from "@material-ui/core";
import { Texts, Colors } from "../../styles";
import { blue } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 106,
        marginBottom: 26,
    },
    title: {
        marginLeft: 8,
        ...Texts.basic(24, "bold", Colors.Gray900, 1.21),
    },

    guide: {
        marginTop: 48,
        ...Texts.basic(20, "bold", Colors.Gray900, 1.2),
    },
    autoCompleteTextFieldWrap: {
        position: "relative",
    },
    autoCompleteList: {
        position: "absolute",
        top: -12,
        width: "100%",
        //padding: 8,
        borderRadius: 8,
        boxShadow: "0 6px 16px 0 #dadada",
        backgroundColor: Colors.White,
        zIndex: 10,
    },
    autoCompleteListItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: 12,
        cursor: "pointer",
    },
    skillWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
    },
    skillChip: {
        padding: "9px 10px 9px 18px",
        borderRadius: 24,
        backgroundColor: Colors.Gray50,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
    },
    skillIcon: {
        width: 24,
        height: 24,
    },
    skillName: {
        marginLeft: 8,
        ...Texts.basic(14, 500, Colors.Gray700, 1.71),
    },
    skillLevel: {
        marginLeft: 8,
        ...Texts.basic(14, 500, Colors.Gray400, 1.71),
    },
    skillDeleteBtn: {
        borderRadius: "50%",
        padding: 4,
        backgroundColor: Colors.Gray100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 10,
    },
    skillDeleteBtnIcon: {
        width: 16,
        height: 16,
    },
    uploading: {
        marginLeft: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    skillRatePopup: {
        //minWidth: 500,
        //maxHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        padding: 32,
        backgroundColor: Colors.White,
        borderRadius: 20,
    },
    skillRatePopupHeaderTitle: {
        ...Texts.basic(20, "bold", Colors.Gray800, 1.2),
        marginBottom: 24,
        marginLeft: 82,
        marginRight: 82,
    },
    skillChipUnrated: {
        padding: "8px 20px",
        borderRadius: 24,
        backgroundColor: Colors.Gray50,
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: 24,
    },
    skillIconUnrated: {
        width: 28,
        height: 28,
    },
    skillNameUnrated: {
        marginLeft: 8,
        ...Texts.basic(16, 500, Colors.Gray900, 1.71),
    },
    skillLevelUnrated: {
        marginLeft: 8,
        ...Texts.basic(16, 500, Colors.Gray600, 1.71),
    },
    photoEditPopupBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 32,
        backgroundColor: Colors.White,
        borderRadius: 20,
    },
    photoEditPopupBodyFrame: {},
    skillRateBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "14px 16px",
        borderRadius: 8,
        border: "1px solid #dddddd",
        marginTop: 8,
    },
    skillRateBoxChecked: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "14px 16px",
        borderRadius: 8,
        border: "1px solid #3c6af5",
        marginTop: 8,
    },
    skillRateDesc: {
        marginLeft: 11,
        ...Texts.basic(14, 500, Colors.Gray600, 1.71),
        flexGrow: 1,
    },
    skillRateLow: {
        ...Texts.basic(14, "bold", Colors.Gray400, 1.21),
        marginRight: 12,
    },
    skillRateMedium: {
        ...Texts.basic(14, "bold", Colors.Blue500, 1.21),
        marginRight: 12,
    },
    skillRateHigh: {
        ...Texts.basic(14, "bold", Colors.Citrus500, 1.21),
        marginRight: 12,
    },
    skillRatePopupBtnWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    skillRatePopupBtn: {
        marginTop: 32,
        marginLeft: 8,
    },
}));
