import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    headSection: {
        height: 500,
        backgroundImage: `url(${require("assets/bgi_d_salary_levels.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi_d_salary_levels.png")}) 1x, url(${require("assets/bgi_d_salary_levels@2x.png")}) 2x, url(${require("assets/bgi_d_salary_levels@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top left",
    },
    haedImgsContainer: {
        height: "100%",
        textAlign: "center",
    },
    headImgHelper: {
        display: "inline-block",
        height: "100%",
        verticalAlign: "middle",
    },
    headImg1: {
        position: "relative",
        zIndex: 3,
        right: 29,
        top: 100,
        display: "inline-block",
        verticalAlign: "bottom",
    },
    headImg2: {
        display: "inline-block",
        verticalAlign: "middle",
    },
    headImg3: {
        display: "inline-block",
        verticalAlign: "bottom",
    },
    headImg4: {
        position: "relative",
        zIndex: 1,
        top: 30,
        display: "inline-block",
        verticalAlign: "bottom",
    },
    promotionSection: {
        position: "relative",
        zIndex: 2,
        backgroundColor: "#040c16",
        textAlign: "center",
        paddingTop: 158,
        paddingBottom: 158,
    },
    promotionImg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    promotionBtn: {
        width: 470,
        height: 64,
        backgroundColor: "#2f64ff",
        ...Texts.basic(20, "bold", "#ffffff"),
    },
    companySection: {
        width: 800,
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 100,
        paddingBottom: 100,
        textAlign: "center",
    },
    companySectionDesc: {
        ...Texts.basic(16, 300, "#999999"),
        marginTop: 40,
        marginBottom: 20,
    },
    companyCard: {
        display: "flex",
        alignItems: "center",
        border: "solid 1px #bbbbbb",
        borderRadius: 16,
        padding: "24px 20px 24px 24px",
    },
    companyLogo: {
        width: 46,
        height: 46,
        backgroundColor: "#dddddd",
        border: "solid 1px #dddddd",
        borderRadius: 45,
    },
    companyInfo: {
        flex: "1 0 0",
        textAlign: "left",
        margin: "auto 15px",
    },
    companyName: {
        fontSize: 16,
        fontWeight: "bold",
    },
    companyFeature: {
        fontSize: 14,
        fontWeight: 500,
    },
    companyDetailBtn: {
        padding: "6px 12px",
        border: "solid 1px #444444",
        borderRadius: 15,
        fontSize: 12,
        fontWeight: 500,
    },
    huddleSection: {
        backgroundColor: "#d0e7ff",
        textAlign: "center",
        paddingTop: 100,
        paddingBottom: 100,
    },
    huddleSectionTitle: {
        ...Texts.basic(32, "bold", "#222222"),
        whiteSpace: "pre-wrap",
    },
    huddleFrame: {
        width: 633,
        marginTop: 40,
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 40,
        paddingBottom: 48,
        borderRadius: 20,
        backgroundColor: "#ffffff",
        textAlign: "center",
    },
    huddleFrameDesc: {
        ...Texts.basic(24, "bold", "#222222"),
        whiteSpace: "pre-wrap",
        marginTop: 48,
        marginBottom: 41,
    },
    huddleImg: {
        marginBottom: 24,
    },
    appSection: {
        backgroundColor: "#24429a",
        textAlign: "center",
        paddingTop: 98,
        paddingBottom: 114,
    },
    appSlider: {
        width: 447,
        marginTop: 48,
        marginLeft: "auto",
        marginRight: "auto",
    },
    suggestionSectionTop: {
        backgroundColor: "#ffffff",
        textAlign: "center",
    },
    suggestionSectionTopImg: {
        position: "relative",
        top: -40,
    },
    suggestionSectionBottom: {
        backgroundImage: "linear-gradient(to bottom, #ffffff, #94cbff)",
        paddingBottom: 84,
        textAlign: "center",
    },
    suggestionImg: {
        position: "relative",
        top: -10,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 50,
    },
    applyBox: {
        position: "relative",
        backgroundColor: "#ffffff",
        textAlign: "center",
        paddingTop: 150,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 150,
        "& > img": {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    applyBtnImg: (props) => ({
        position: "absolute",
        zIndex: 1,
        bottom: 800,
        left: "50%",
        transform: "translate(-50%, 0)",
        opacity: props.clicked ? 0 : 1,
        transition: "opacity 0.1s ease-out",
    }),
    applyBtnClickedImg: (props) => ({
        // position: "relative",
        // top: -50,
        position: "absolute",
        zIndex: 0,
        bottom: 800,
        left: "50%",
        transform: "translate(-50%, 0)",
    }),
    reassurance: {
        marginTop: 64,
        marginBottom: 64,
    },
    leftRoundBox: {
        width: 306,
        height: 306,
        backgroundColor: "#efefef",
        borderRadius: 153,
        marginRight: 20,
    },
    rightRoundBox: {
        position: "relative",
        width: 306,
        height: 306,
        backgroundColor: "#061d5e",
        borderRadius: 153,
    },
    timeSaving: {
        position: "absolute",
        top: -10,
        right: 0,
    },
    offerSection: {
        backgroundColor: "#3d58ff",
        paddingTop: 100,
        textAlign: "center",
    },
    offerImg: {
        display: "block",
        marginTop: 68,
        marginLeft: "auto",
        marginRight: "auto",
    },
    reasonSection: {
        backgroundColor: "#ffffff",
        paddingTop: 100,
        paddingBottom: 100,
        paddingLeft: 20,
        paddingRight: 20,
        textAlign: "center",
    },
    reasonSectionFrame: {
        marginTop: 48,
    },
    reasonCard: {
        display: "flex",
        flexDirection: "column",
        height: 543,
        borderRadius: 20,
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        textAlign: "left",
    },
    reasonCardTop: {
        position: "relative",
        zIndex: 0,
        width: "100%",
        borderRadius: "20px 20px 0px 0px",
        flexGrow: 1,
        padding: 24,
        backgroundImage: "linear-gradient(to bottom , #3a3b41, #1030ff)",
    },
    reasonCardImg: {
        position: "absolute",
        zIndex: -1,
        right: 0,
        bottom: 0,
    },
    reasonLabel: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#ffffff",
        display: "inline-block",
        padding: "4px 12px",
        borderRadius: 14,
        backgroundColor: "#2f64ff",
    },
    reasonMsgImg: {
        display: "block",
        marginTop: 24,
        marginBottom: 156,
    },
    reasonManagerProfile: {
        position: "absolute",
        bottom: 20,
        ...Texts.basic(16, "bold", "#ffffff"),
        whiteSpace: "pre-wrap",
    },
    reasonDesc: {
        ...Texts.basic(16, 500, "#999999"),
        padding: 24,
        width: "100%",
    },
    reasonSingleCard: {
        position: "relative",
        zIndex: 0,
        height: 543,
        borderRadius: 20,
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        textAlign: "left",
        padding: 20,
    },
    reasonSecondCardImg: {
        position: "absolute",
        zIndex: -1,
        right: 0,
        bottom: 40,
    },
    reasonITCompanies: {
        position: "absolute",
        bottom: 24,
        ...Texts.basic(16, 500, "#222222"),
    },
    reasonThirdCard: {
        position: "relative",
        width: "100%",
        borderRadius: 20,
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        textAlign: "left",
        padding: 24,
        backgroundImage: `url(${require("assets/img_hand_shaking.png")})`,
        backgroundImage: `image-set(url(${require("assets/img_hand_shaking.png")}) 1x, url(${require("assets/img_hand_shaking@2x.png")}) 2x, url(${require("assets/img_hand_shaking@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center",
        height: 320,
    },
    reasonMsgImgBot: {
        display: "block",
        marginTop: 70,
    },
    reasonDesc3: {
        position: "absolute",
        bottom: 24,
        ...Texts.basic(16, 500, "#ffffff"),
        whiteSpace: "pre-wrap",
    },
    timingSection: {
        backgroundColor: "#222222",
        paddingTop: 80,
        textAlign: "center",
    },
    timingImg: {
        display: "block",
        marginTop: 40,
        marginLeft: "auto",
        marginRight: "auto",
    },
    reviewSection: {
        backgroundColor: "#efefef",
        textAlign: "center",
        paddingTop: 100,
        paddingBottom: 100,
    },
    reviewSectionTitle: {
        marginBottom: 24,
    },
    reviewFrame: {
        textAlign: "center",
        marginTop: 40,
    },
    reviewCardAccessory: {
        display: "inline-block",
        verticalAlign: "top",
        width: 81,
        height: 81,
        backgroundColor: "#dddddd",
        borderRadius: 40,
    },
    reviewCard: {
        width: 470,
        verticalAlign: "middle",
        display: "inline-block",
        backgroundColor: "#ffffff",
        borderRadius: 8,
        padding: 24,
        marginLeft: 20,
        marginRight: 20,
        textAlign: "left",
    },
    reviewCardTitle: {
        whiteSpace: "pre-wrap",
        ...Texts.basic(24, "bold", "#222222"),
    },
    reviewCardContent: {
        ...Texts.basic(16, 500, "#222222"),
        marginTop: 12,
        marginBottom: 24,
    },
    reviewCardReviewer: {
        ...Texts.basic(14, "normal", "#999999"),
        marginBottom: 8,
    },
    reviewCardStarIcon: {
        width: 24,
        height: 24,
    },

    phantomFixedBtnsBox: {
        width: "100%",
        height: 100,
    },
    fixedBtnsBox: {
        position: "fixed",
        bottom: 0,
        zIndex: 4,
        width: "100%",
    },
    fixedTopBtnsBox: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: 18,
    },
    fixedTopLeftBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff",
        border: "solid 1px #bbbbbb",
        borderRadius: 16,
        padding: "6px 12px",
        marginRight: 8,
        ...Texts.basic(12, 500, "#bbbbbb", 1.67),
    },
    fixedTopRightBtn: {
        width: 32,
        height: 32,
        marginRight: 16,
        padding: 6,
        border: "solid 1px #bbbbbb",
        borderRadius: 45,
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fixedBottomBtnsBox: {
        position: "relative",
        width: "100%",
        backgroundColor: "#333435",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 18,
        textAlign: "center",
    },
    fixedBottomTitle: {
        ...Texts.basic(20, "normal", "#ffffff"),
        marginLeft: 8,
        marginRight: 82,
    },
    fixedBottomLeftBtn: {
        ...Texts.basic(20, "bold", "#ffffff"),
        backgroundColor: "#ff6438",
        height: 64,
        maxWidth: 396,
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        textDecoration: "none",
        marginRight: 12,
    },
    fixedBottomRightBtn: {
        ...Texts.basic(20, 500, "#222222"),
        backgroundColor: "#ffd646",
        height: 64,
        width: 225,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 8,
        borderRadius: 4,
        textDecoration: "none",
    },
    targetSection: {
        backgroundColor: "#2f64ff",
        padding: "80px 20px 100px",
        textAlign: "center",
    },
    targetTitle: {
        marginBottom: 48,
    },
    targetBoxList: {
        backgroundColor: "#ffffff",
        padding: "16px 20px",
        textAlign: "left",
        borderRadius: 8,
        marginBottom: 12,
    },
    targetBoxListIcon: {
        width: 24,
        height: 24,
        marginRight: 16,
        verticalAlign: "middle",
    },
    targetBoxListItem: {
        ...Texts.basic(20, 500, "#111111"),
        verticalAlign: "middle",
    },
    secretBox: {
        width: "100%",
        height: 218,
        backgroundColor: "#222222",
        backgroundImage: `url(${require("assets/bgi_privacy_keeping.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi_privacy_keeping.png")}) 1x, url(${require("assets/bgi_privacy_keeping@2x.png")}) 2x, url(${require("assets/bgi_privacy_keeping@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        textAlign: "center",
    },
    secretMsgImg: { marginTop: 40 },
    onboardingBox: {
        width: "100%",
        paddingTop: 100,
        paddingBottom: 64,
        textAlign: "center",
    },
    onboardingTitleImg: { marginBottom: 72 },
    onboardingSteps: {
        marginBottom: 16,
    },
    onboardingStepCard: {
        border: "solid 1px #2f64ff",
        borderRadius: 12,
        height: 264,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    onboardingStepIcon: {
        marginBottom: 12,
    },
    onboardingStepDesc: {
        ...Texts.basic(18, 500, "#222222"),
        whiteSpace: "pre-wrap",
    },
    onboardingNotice: {
        textAlign: "left",
        ...Texts.basic(14, 500, "#999999"),
        marginBottom: 4,
    },
    faqBox: {
        //padding: 20,
        paddingBottom: 100,
        textAlign: "left",
    },
    faqBoxTitle: {
        fontSize: 16,
        fontWeight: "bold",
        color: "#222222",
    },
    faqBoxToggleBtn: {
        float: "right",
    },
    thickDivider: {
        height: 2,
        backgroundColor: "#222222",
        margin: "12px auto",
    },
    faq: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#444444",
        marginBottom: 8,
    },
    answer: {
        fontSize: 14,
        fontWeight: 300,
        color: "#444444",
        marginBottom: 16,
    },
    thinDivider: {
        backgroundColor: "#999999",
        marginBottom: 12,
    },
}));
