export const nickNameMap = new Map([
    [68, "콸콸콸곰탕"],
    [88, "자라장장"],
    [89, "정달마"],
    [94, "룸구"],
    [102, "부르르르회"],
    [109, "당당한닭발"],
    [191, "서운한스시"],
    [198, "조그만갈비"],
    [216, "트랜디"],
    [225, "풍덩풍덩죽"],
    [253, "TEDDY"],
    [279, "front-k"],
    [289, "보헤미안"],
    [324, "어제보다오늘더"],
    [396, "개미"],
    [343, "탄두리파전"],
    [344, "쌀쌀한월병"],
    [404, "눈부신교자"],
    [413, "허니마라탕"],
    [474, "만만한도넛"],
    [493, "화창한파닭"],
    [500, "반물질숟가락"],
    [512, "지금이봄날"],
    [527, "당나귀"],
    [559, "히포"],
    [567, "규트"],
    [622, "순수한밀면"],
    [664, "김토토마"],
    [665, "픽미업"],
    [666, "절레절레떡"],
    [667, "가빈"],
    [668, "담백한케밥"],
    [669, "신나는덮밥"],
    [672, "꼬르륵쫄면"],
    [673, "라이언"],
    [676, "포돌이"],
    [677, "Bac"],
    [678, "괜찮은국수"],
    [680, "모두의개발자"],
    [681, "씩씩한백숙"],
    [684, "pravda"],
    [688, "죠수박"],
    [689, "심각한육회"],
    [690, "샤론"],
    [692, "Chris"],
    [693, "Ain"],
    [694, "시원한짬뽕"],
    [699, "잡부"],
    [703, "minbr0ther"],
    [707, "올바른도넛"],
    [708, "구름건설업자"],
    [711, "간편한김밥"],
    [712, "익명의다람쥐"],
    [713, "화끈한잡채"],
    [714, "열정뿜뿜"],
    [718, "화끈한커리"],
    [720, "Marvin gerad"],
    [727, "세련된치킨"],
    [730, "kyle"],
    [731, "싱거운간장"],
    [733, "꿀꿀고구마"],
    [735, "아코"],
    [736, "폭넓은파전"],
    [737, "하얀짜장면"],
    [738, "쓸쓸한튀김"],
    [740, "양규야"],
    [741, "즐거운잡탕"],
    [742, "거친샐러드"],
    [743, "진지한우동"],
    [745, "Zlolz"],
    [746, "이트"],
    [749, "야생의망나뇽"],
    [751, "낯설은훠궈"],
    [753, "ej"],
    [754, "핫마요네즈"],
    [756, "프론트엔드 개발자"],
    [760, "바삭인절미"],
    [761, "짬뽕맛라멘"],
    [763, "순진한보쌈"],
    [764, "can"],
    [765, "찐도가니탕"],
    [771, "냉정한피클"],
    [776, "선지맛케찹"],
    [777, "리미"],
    [778, "단팥빵빙수"],
    [779, "부드러운 크림빵"],
    [780, "Yong"],
    [781, "배부른케익"],
    [782, "납작마카롱"],
    [783, "갓생치즈볼"],
    [784, "진한신라면"],
    [785, "끼쟁이뇨끼"],
    [786, "건방진건빵"],
    [787, "전통파스타"],
    [788, "이태리국수"],
    [789, "하얀육개장"],
    [790, "레드동치미"],
    [791, "육식파두부"],
    [792, "어썸과메기"],
    [793, "배부른통닭"],
    [795, "킹부대찌개"],
    [797, "어메이징탕"],
    [800, "아이스햇반"],
    [801, "abby"],
    [802, "유쾌한육회"],
    [803, "약간갈비탕"],
    [804, "바삭한한과"],
    [805, "회색단백질바"],
    [806, "두리안김치"],
    [807, "휴게소떡소"],
    [808, "슐미랭삼겹"],
    [809, "미래지향국"],
    [810, "니즈"],
    [811, "생생굴국밥"],
    [812, "은혁"],
    [813, "애플바게트"],
    [814, "펄샌드위치"],
    [815, "박하츄러스"],
    [817, "소중한막창"],
    [818, "당근빵나무"],
    [819, "꿀스위프트"],
    [820, "두부쉐이크"],
    [821, "오버쿡쿠키"],
    [822, "뛰어난나물"],
    [824, "쪼르륵라면"],
    [825, "넉넉한족발"],
    [826, "삼삼한김치"],
    [827, "놀라운콜팝"],
    [829, "단단한떡국"],
    [830, "배부른유과"],
    [831, "덜덜덜나초"],
    [832, "따르릉김밥"],
    [834, "쫀쫀한쿠키"],
    [835, "오래된우럭"],
    [836, "지루한곰탕"],
    [837, "목마른감튀"],
    [838, "미끈한편육"],
    [839, "고마운와인"],
    [840, "늘바쁜피자"],
    [841, "조용한덮밥"],
    [842, "적절한순대"],
    [843, "확실한분식"],
    [844, "단정한나또"],
    [845, "갸우뚱약밥"],
    [846, "느끼한냉면"],
    [847, "우아한국밥"],
    [848, "힘찬탕수육"],
    [849, "신기한물회"],
    [850, "속꽉찬만두"],
    [851, "칭따양갈비"],
    [852, "부릉부릉죽"],
    [853, "우육면치기"],
    [854, "킹최고구마"],
    [855, "점잖은비냉"],
    [856, "으르렁쌈장"],
    [857, "깻잎마요밥"],
    [858, "녹차떡볶이"],
    [860, "버섯크림빵"],
    [861, "행운의찜닭"],
]);
