import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setProjects } from "../../redux/slice/profileSlice";
import {
    Box,
    Typography,
    Divider,
    Button,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Icon,
} from "@material-ui/core";
import { AntSwitch } from "../components";
import { useStyles } from "./styleProfileProject";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import { hopePositions } from "../../data";
import { displayLink, displayLocalYearMonth } from "../../helper/misc";

import {
    createTalentProfileProject,
    updateTalentProfileProject,
    deleteTalentProfileProject,
} from "../../apis/profile/profile";

const initialProject = {
    id: undefined,
    name: "",
    organization: "",
    team: null,
    position: "",
    beginAt: null,
    endAt: null,
    working: false,
    task: "",
    link: "",
};

const ProfileProject = ({ edit = true }) => {
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [projectAdd, setProjectAdd] = useState(false);
    const [projectEditIndex, setProjectEditIndex] = useState(-1);
    const [projectBeingCreated, setProjectBeingCreated] =
        useState(initialProject);
    const [isUploading, setIsUploading] = useState(false);

    const handleProjectChange = (event) => {
        const key = event.target.id || event.target.name;
        const value = event.target.value;
        setProjectBeingCreated((prev) => {
            const newProject = { ...prev };
            newProject[key] = value;
            return newProject;
        });
    };

    const handleProjectWorkingChange = (event) => {
        setProjectBeingCreated((prev) => {
            const newProject = { ...prev };
            newProject.working = event.target.checked;
            newProject.endAt = null;
            return newProject;
        });
    };

    const handleBeginAtChange = (date, value) => {
        setProjectBeingCreated((prev) => {
            const newProject = { ...prev };
            newProject.beginAt = date?.toJSON();
            return newProject;
        });
    };

    const handleEndAtChange = (date, value) => {
        setProjectBeingCreated((prev) => {
            const newProject = { ...prev };
            newProject.endAt = date?.toJSON();
            return newProject;
        });
    };

    const handleProjectCancle = (event) => {
        event.preventDefault();
        setProjectBeingCreated(initialProject);
        setProjectEditIndex(-1);
        setProjectAdd(false);
    };

    const handleProjectCreate = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        let newProject = { ...projectBeingCreated };
        let newProjects;

        const createdProject = await createTalentProfileProject(
            profile.token,
            projectBeingCreated
        );
        if (createdProject) {
            newProject.id = createdProject.id;
            newProjects = profile.projects.concat(newProject);
        } else {
            history.push("/profile/access-expired");
        }

        console.log("newProjects", newProjects);
        dispatch(setProjects(newProjects));
        setProjectBeingCreated(initialProject);
        setProjectEditIndex(-1);
        setIsUploading(false);
        setProjectAdd(false);
    };

    const handleProjectUpdate = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        let newProjects;

        const updateResult = await updateTalentProfileProject(
            profile.token,
            projectBeingCreated.id,
            projectBeingCreated
        );
        if (updateResult?.affected === 1) {
            newProjects = profile.projects.map((project, index) => {
                if (index === projectEditIndex) {
                    return projectBeingCreated;
                }
                return project;
            });
        } else if (!updateResult) {
            history.push("/profile/access-expired");
        }

        console.log("newProjects", newProjects);
        dispatch(setProjects(newProjects));
        setProjectBeingCreated(initialProject);
        setProjectEditIndex(-1);
        setIsUploading(false);
        setProjectAdd(false);
    };

    const handleProjectEdit = (index) => {
        setProjectEditIndex(index);
        setProjectBeingCreated(profile.projects[index]);
        setProjectAdd(true);
    };

    const handleProjectDelete = async (index, projectId) => {
        const isDeleted = await deleteTalentProfileProject(
            profile.token,
            projectId
        );
        if (isDeleted) {
            const newProjects = profile.projects.filter((_, i) => i !== index);
            dispatch(setProjects(newProjects));
        } else {
            history.push("/profile/access-expired");
        }
    };

    const submitable =
        projectBeingCreated.name &&
        projectBeingCreated.organization &&
        projectBeingCreated.position &&
        projectBeingCreated.beginAt &&
        (projectBeingCreated.working || projectBeingCreated.endAt) &&
        projectBeingCreated.task &&
        !isUploading;

    return (
        <Box className={classes.infoWrap}>
            <Box className={classes.infoHeader}>
                <Typography className={classes.infoTitle}>프로젝트</Typography>
                {edit && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setProjectAdd(true)}
                        disabled={projectAdd}
                    >
                        추가하기
                    </Button>
                )}
            </Box>
            {!projectAdd &&
                projectEditIndex === -1 &&
                profile.projects?.map((project, index) => (
                    <Fragment key={index}>
                        {index > 0 && <Divider />}
                        <Grid
                            key={index}
                            container
                            spacing={1}
                            className={classes.projectItemWrap}
                        >
                            <Grid item xs={10}>
                                <Typography className={classes.projectName}>
                                    {project.name}
                                </Typography>
                            </Grid>
                            {edit && (
                                <Grid item xs={2}>
                                    <Box className={classes.projectItemBtnWrap}>
                                        <Typography
                                            className={classes.projectEditBtn}
                                            onClick={() =>
                                                handleProjectEdit(index)
                                            }
                                        >
                                            편집
                                        </Typography>
                                        <Typography
                                            className={classes.projectDeleteBtn}
                                            onClick={() =>
                                                handleProjectDelete(
                                                    index,
                                                    project.id
                                                )
                                            }
                                        >
                                            삭제
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12} />
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.projectHost}
                                    display="inline"
                                >
                                    {project.organization ??
                                        project.team ??
                                        "개인 프로젝트"}
                                    , {project.position}
                                </Typography>
                                <Typography
                                    className={classes.projectDuration}
                                    display="inline"
                                >
                                    {"   "}|{"   "}
                                    {displayLocalYearMonth(
                                        project.beginAt
                                    )} ~{" "}
                                    {project.endAt
                                        ? displayLocalYearMonth(project.endAt)
                                        : "수행중"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.projectDetail}>
                                    {project.task}
                                </Typography>
                            </Grid>
                            {project.link && (
                                <Grid item xs={12}>
                                    <Box className={classes.projectLinkWrap}>
                                        <Icon>link</Icon>
                                        <Typography
                                            className={classes.projectLink}
                                        >
                                            {displayLink(project.link)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Fragment>
                ))}

            {projectAdd && (
                <>
                    <Divider className={classes.darkDivider} />

                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    프로젝트명
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                inputProps={{ maxLength: 64 }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="name"
                                variant="outlined"
                                placeholder="프로젝트명 - 한줄 소개글"
                                value={projectBeingCreated.name}
                                onChange={handleProjectChange}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    수행 주체
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                inputProps={{ maxLength: 64 }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="organization"
                                variant="outlined"
                                placeholder="기업명 / 팀명 / '개인 프로젝트'"
                                value={projectBeingCreated.organization}
                                onChange={handleProjectChange}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    담당 역할
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.noBorderSelect}
                                    labelId="demo-simple-select-label"
                                    id="position"
                                    name="position"
                                    displayEmpty
                                    value={projectBeingCreated.position}
                                    onChange={handleProjectChange}
                                    classes={{
                                        root: classes.resize,
                                    }}
                                >
                                    <MenuItem
                                        value=""
                                        classes={{
                                            root: classes.resize,
                                        }}
                                        disabled
                                    >
                                        선택해주세요.
                                    </MenuItem>
                                    {hopePositions.map((item, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={item}
                                            classes={{
                                                root: classes.resize,
                                            }}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    시작
                                </Typography>
                                <Box className={classes.required}></Box>
                                <Box className={classes.flexGrowBox} />
                                <Typography className={classes.infoSubLabel}>
                                    수행중
                                </Typography>
                                <AntSwitch
                                    id="working"
                                    name="working"
                                    checked={projectBeingCreated.working}
                                    onChange={handleProjectWorkingChange}
                                />
                            </Box>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: classes.dateInput,
                                            root: classes.adornedEnd,
                                            notchedOutline: classes.whiteBorder,
                                        },
                                    }}
                                    KeyboardButtonProps={{
                                        classes: {
                                            root: classes.keyboardBtn,
                                        },
                                        "aria-label": "change date",
                                    }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    views={["year", "month"]}
                                    format="yyyy/MM"
                                    invalidDateMessage={
                                        <Typography style={{ fontSize: 12 }}>
                                            YYYY/MM 형식으로 입력해 주세요!
                                        </Typography>
                                    }
                                    margin="normal"
                                    id="beginAt"
                                    disableFuture
                                    maxDateMessage={
                                        <Typography style={{ fontSize: 12 }}>
                                            오늘 이전 날짜를 입력해 주세요!
                                        </Typography>
                                    }
                                    value={projectBeingCreated.beginAt}
                                    onChange={handleBeginAtChange}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!projectBeingCreated.working && (
                            <Grid item xs={6} className={classes.gridItem}>
                                <Box className={classes.gridItemHeader}>
                                    <Typography
                                        className={classes.infoSubTitle}
                                    >
                                        종료
                                    </Typography>
                                    <Box className={classes.required}></Box>
                                </Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.dateInput,
                                                root: classes.adornedEnd,
                                                notchedOutline:
                                                    classes.whiteBorder,
                                            },
                                        }}
                                        KeyboardButtonProps={{
                                            classes: {
                                                root: classes.keyboardBtn,
                                            },
                                            "aria-label": "change date",
                                        }}
                                        variant="inline"
                                        inputVariant="outlined"
                                        views={["year", "month"]}
                                        format="yyyy/MM"
                                        invalidDateMessage={
                                            <Typography
                                                style={{ fontSize: 12 }}
                                            >
                                                YYYY/MM 형식으로 입력해 주세요!
                                            </Typography>
                                        }
                                        margin="normal"
                                        id="endAt"
                                        disableFuture
                                        minDate={projectBeingCreated.beginAt}
                                        minDateMessage={
                                            <Typography
                                                style={{ fontSize: 12 }}
                                            >
                                                시작 이후 날짜를 입력해 주세요!
                                            </Typography>
                                        }
                                        maxDateMessage={
                                            <Typography
                                                style={{ fontSize: 12 }}
                                            >
                                                오늘 이전 날짜를 입력해 주세요!
                                            </Typography>
                                        }
                                        value={projectBeingCreated.endAt}
                                        onChange={handleEndAtChange}
                                        autoOk={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    내용
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                inputProps={{ maxLength: 1000 }}
                                multiline
                                minRows={3}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="task"
                                variant="outlined"
                                placeholder={`- 프로젝트 타겟 고객, 핵심 제공 가치, 제공 형태\n- 주요 사용 기술 및 세부 구현 사항\n- 프로젝트 성과 등 (최대 1000자)`}
                                value={projectBeingCreated.task}
                                onChange={handleProjectChange}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    참고 링크 (선택)
                                </Typography>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="link"
                                variant="outlined"
                                placeholder="https://www.talentx.co.kr"
                                value={projectBeingCreated.link}
                                onChange={handleProjectChange}
                            />
                        </Grid>
                    </Grid>
                    <Box className={classes.infoFooter}>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="outlined"
                            color="default"
                            disableElevation
                            onClick={handleProjectCancle}
                        >
                            취소
                        </Button>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={
                                projectEditIndex !== -1
                                    ? handleProjectUpdate
                                    : handleProjectCreate
                            }
                            disabled={!submitable}
                        >
                            {projectEditIndex !== -1 ? "수정" : "등록"}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProfileProject;
