import { makeStyles } from "@material-ui/core/styles";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    featureSection: {
        backgroundColor: "#ffffff",
    },
    featureContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        whiteSpace: "pre-wrap",
        paddingTop: 150,
        paddingBottom: 150,
    },
    featureCoreMsg: {
        ...Texts.basic(48, "bold", "#2c2c2c", 1.33),
        marginBottom: 120,
    },
    featureBoxes: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    featureBox: { textAlign: "center", flex: 1 },
    featureTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        marginTop: 32,
        marginBottom: 12,
    },
    featureDesc: {
        ...Texts.basic(16, 500, "#5f5f5f", 1.38),
        whiteSpace: "pre-wrap",
    },
    clientsSection: {
        backgroundColor: "#ffffff",
        textAlign: "center",
        paddingTop: 150,
        paddingBottom: 150,
    },
    clientsTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.33),
        whiteSpace: "pre-wrap",
    },
    clientsDesc: {
        ...Texts.basic(20, 500, "#787878", 1.4),
        whiteSpace: "pre-wrap",
        marginTop: 20,
        marginBottom: 64,
    },
    clientsLogos: {
        flexGrow: 1,
    },
    clientLogoGridItem: {
        height: 100,
    },
    clientLogo: {
        height: 100,
    },
    statSection: {
        backgroundColor: "#f8f8f8",
    },
    statContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    statWrap: {
        textAlign: "left",
    },
    statTitleWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    statTitle: { ...Texts.basic(24, "bold", "#929292", 1.38), marginLeft: 12 },
    statDesc: {
        ...Texts.basic(48, "bold", "#2c2c2c", 1.17),
        whiteSpace: "pre-wrap",
        marginTop: 32,
        marginBottom: 44,
    },
    statValueWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
    },
    statKey: { ...Texts.basic(16, 500, "#ababab", 1.38), marginBottom: 18 },
    statValue: { ...Texts.basic(40, 500, "#111111", 1.4) },
    statImg: { display: "block" },
    simpleSection: { backgroundColor: "#ebf0ff" },
    simpleContainer: {
        textAlign: "center",
        paddingTop: 150,
        paddingBottom: 150,
    },
    simpleTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 33,
    },
    simpleTitle: {
        ...Texts.basic(24, "bold", "#929292", 1.38),
        marginLeft: 12,
    },
    simpleDesc: {
        ...Texts.basic(48, "bold", "#020e30", 1.33),
        whiteSpace: "pre-wrap",
        marginBottom: 64,
    },
    simpleContentTopWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        paddingTop: 63,
        marginBottom: 30,
        boxShadow: "0 8px 32px 0 #cbd2e9",
    },
    simpleContentTopImg: {},
    simpleContentTopDesc: {
        ...Texts.basic(26, "bold", "#2c2c2c", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginLeft: 72,
        marginBottom: 175,
    },
    simpleContentBottomWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    simpleContentBottomLeftWrapper: {
        flex: 2,
        borderRadius: 28,
        backgroundColor: "#3c6af5",
        padding: 40,
        alignItem: "flex-start",
    },
    simpleContentBottomLeftDesc: {
        ...Texts.basic(24, "bold", "#9db4fa", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginBottom: 24,
    },
    simpleContentBottomLeftMore: {
        ...Texts.basic(24, "bold", "#ffffff", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginBottom: 109,
    },
    simpleContentBottomLeftImg: { alignSelf: "center" },
    simpleContentBottomRightWrapper: {
        flex: 1,
        marginLeft: 29,
        backgroundColor: "#051c60",
        borderRadius: 28,
        padding: 40,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItem: "flex-start",
    },
    simpleContentBottomRightDesc: {
        ...Texts.basic(30, "bold", "#ffffff", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
    },
    simpleContentBottomRightImg: { alignSelf: "center" },
    profileSection: { backgroundColor: "#ffffff" },
    profileContainer: {
        textAlign: "left",
        paddingTop: 150,
        paddingBottom: 90,
    },
    profileTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 33,
    },
    profileTitle: {
        ...Texts.basic(24, "bold", "#929292", 1.38),
        marginLeft: 12,
    },
    profileContentWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    profileContentLeftWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        marginBottom: 30,
    },
    profileDesc: {
        ...Texts.basic(48, "bold", "#020e30", 1.33),
        whiteSpace: "pre-wrap",
        marginBottom: 150,
    },
    profileContentLeftImg: { marginLeft: -50 },
    profileContentRightWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        marginBottom: 30,
    },
    profileContentRightImg: { marginBottom: 30 },
    profileContentRightIcon: {
        marginLeft: 50,
        marginBottom: 20,
        alignSelf: "flex-start",
    },
    profileContentRightDesc: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        whiteSpace: "pre-wrap",
        alignSelf: "flex-start",
        marginLeft: 50,
    },
    supportSection: { backgroundColor: "#f8f8f8" },
    supportContainer: {
        textAlign: "center",
        paddingTop: 150,
        paddingBottom: 150,
    },
    supportTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 33,
    },
    supportTitle: {
        ...Texts.basic(24, "bold", "#929292", 1.38),
        marginLeft: 12,
    },
    supportDesc: {
        ...Texts.basic(32, "bold", "#2c2c2c", 1.41),
        whiteSpace: "pre-wrap",
        marginBottom: 8,
    },
    supportKeyword: {
        ...Texts.basic(48, "bold", "#3c6af5", 1.33),
        whiteSpace: "pre-wrap",
        marginBottom: 64,
    },
    supportContentTopWrapper: {
        backgroundColor: "#051c60",
        borderRadius: 28,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        marginBottom: 30,
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    supportContentTopDesc: {
        ...Texts.basic(30, "bold", "#ffffff", 1.4),
        alignSelf: "center",
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginRight: 121,
    },
    supportContentTopImg: {},
    supportContentMiddleWrapper: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 30,
    },
    supportContentMiddleLeftWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0a38c1",
        borderRadius: 28,
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    supportContentMiddleLeftImg: { marginBottom: 52 },
    supportContentMiddleLeftDesc: {
        ...Texts.basic(30, "bold", "#ffffff", 1.4),
        whiteSpace: "pre-wrap",
    },
    supportContentMiddleRightWrapper: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        padding: 48,
        marginLeft: 30,
        boxShadow: "0 4px 40px 0 #e5e5e5",
    },
    supportContentMiddleRightChatLeftWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
    },
    supportContentMiddleRightChatLeftImg: {
        width: 56,
        height: 56,
        marginRight: 20,
    },
    supportContentMiddleRightChatLeftTextWrapper: {
        backgroundColor: "#3c6af5",
        borderRadius: 28,
        paddingLeft: 24,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 32,
        marginLeft: 20,
        whiteSpace: "pre-wrap",
        textAlign: "left",
        ...Texts.basic(20, 500, "#ffffff", 1.4),
    },
    supportContentMiddleRightChatRightWrapper: {
        backgroundColor: "#eeeeee",
        flex: 1,
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        borderRadius: 28,
        paddingLeft: 24,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 32,
        whiteSpace: "pre-wrap",
        textAlign: "left",
        ...Texts.basic(20, 500, "#111111", 1.4),
        marginTop: 32,
        marginBottom: 41,
    },
    supportContentBottomWrapper: {
        display: "flex",
        flexDirection: "row",
        height: 520,
    },
    supportContentBottomLeftWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        boxShadow: "0 4px 40px 0 #e5e5e5",
    },
    supportContentBottomTitle: {
        marginTop: 64,
        ...Texts.basic(28, "bold", "#2c2c2c", 1.4),
        marginBottom: 12,
    },
    supportContentBottomDesc: {
        ...Texts.basic(24, 500, "#454545", 1.38),
        whiteSpace: "pre-wrap",
        flexGrow: 1,
    },
    supportContentBottomImg: { marginBottom: 80 },
    supportContentBottomRightWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        marginLeft: 30,
        boxShadow: "0 4px 40px 0 #e5e5e5",
    },
    supportContentLeftImg: { marginLeft: -50 },
    supportContentRightWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        marginLeft: 30,
    },
    supportContentRightImg: { marginBottom: 120 },
    supportContentRightDesc: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        whiteSpace: "pre-wrap",
        alignSelf: "flex-start",
        marginLeft: 50,
    },
    brandingSection: { backgroundColor: "#ffffff" },
    brandingContainer: {
        paddingTop: 150,
        paddingBottom: 150,
        textAlign: "left",
    },
    brandingTitleWrapper: { marginBottom: 20 },
    brandingTitle: { ...Texts.basic(24, "bold", "#3c6af5", 1.38) },
    brandingDesc: {
        ...Texts.basic(48, "bold", "#111111", 1.17),
        marginBottom: 64,
        whiteSpace: "pre-wrap",
    },
    brandingContentTopWrapper: {
        backgroundColor: "#3c6af5",
        borderRadius: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 30,
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    brandingContentTopLeftWrapper: { padding: 40, textAlign: "left" },
    brandingContentTopLeftTitle: {
        ...Texts.basic(24, "bold", "#ffffff", 1.38),
        marginBottom: 33,
        whiteSpace: "pre-wrap",
    },
    brandingContentTopLeftDesc: {
        ...Texts.basic(24, "bold", "#cdd9fd", 1.46),
        whiteSpace: "pre-wrap",
    },
    brandingContentTopRightWrapper: { marginLeft: 45 },
    brandingContentTopRightImg: { display: "block" },
    brandingContentBottomWrapper: { display: "flex", flexDirection: "row" },
    brandingContentBottomLeftWrapper: {
        flex: 1,
        borderRadius: 28,
        backgroundColor: "#213563",
        display: "flex",
        flexDirection: "column",
        padding: 40,
        textAlign: "left",
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    brandingContentBottomLeftTitle: {
        ...Texts.basic(30, "bold", "#ffffff", 1.4),
        whiteSpace: "pre-wrap",
    },
    brandingContentBottomLeftImg: { marginTop: 92, alignSelf: "center" },
    brandingContentBottomRightWrapper: {
        flex: 2,
        marginLeft: 30,
        borderRadius: 28,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "0 4px 40px 0 #e5e5e5",
    },
    brandingContentBottomRightImg: { display: "block", marginTop: 46 },
    openscoutSection: { backgroundColor: "#f8f8f8" },
    openscoutContainer: {
        paddingTop: 150,
        paddingBottom: 150,
        textAlign: "left",
    },
    openscoutTitleWrapper: { marginBottom: 20 },
    openscoutTitle: { ...Texts.basic(24, "bold", "#3c6af5", 1.38) },
    openscoutDesc: {
        ...Texts.basic(48, "bold", "#111111", 1.17),
        marginBottom: 40,
        whiteSpace: "pre-wrap",
    },
    openscoutContentTopWrapper: { marginBottom: 40 },
    openscoutContentTopTitle: {
        ...Texts.basic(28, "bold", "#3c6af5", 1.43),
        marginBottom: 64,
        whiteSpace: "pre-wrap",
    },
    openscoutContentTopImgWarpper: {
        backgroundColor: "#020e30",
        borderRadius: 24,
        boxShadow: "0 8px 48px 0 #e6e6e6",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    openscoutContentTopImg: { display: "block" },
    openscoutContentMiddleTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 12,
    },
    openscoutContentMiddleTitle: {
        ...Texts.basic(22, "bold", "#5f5f5f", 1.36),
        marginLeft: 12,
    },
    openscoutContentMiddleDesc: {
        ...Texts.basic(20, 500, "#929292", 1.4),
        marginBottom: 80,
        whiteSpace: "pre-wrap",
    },
    openscoutContentBottomWrapper: { display: "flex", flexDirection: "row" },
    openscoutContentBottomLeftWrapper: { flex: 1 },
    openscoutContentBottomTitle: {
        ...Texts.basic(28, "bold", "#111111", 1.43),
        marginTop: 24,
        marginBottom: 20,
        whiteSpace: "pre-wrap",
    },
    openscoutContentBottomDesc: {
        ...Texts.basic(20, 500, "#5f5f5f", 1.4),
        whiteSpace: "pre-wrap",
    },
    openscoutContentBottomMiddleWrapper: { flex: 1 },
    openscoutContentBottomRightWrapper: { flex: 1 },
    pricingSection: { backgroundColor: "#ebf0ff" },
    pricingContainer: { paddingTop: 150, paddingBottom: 120 },
    pricingTitle: {
        ...Texts.basic(48, "bold", "#020e30", 1.33),
        marginBottom: 80,
    },
    pricingSubTitle: {
        ...Texts.basic(24, "bold", "#020e30", 1.33),
        marginBottom: 36,
        whiteSpace: "pre-wrap",
    },
    pricingContentWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        textAlign: "left",
    },
    pricingContentLeftWrapper: {
        flex: 1,
        backgroundColor: "#ffffff",
        padding: 30,
        borderRadius: 12,
        boxShadow: "0 4px 32px -10px #d6dcec",
        borderTopStyle: "solid",
        borderTopWidth: 10,
        borderTopColor: "#929292",
    },
    pricingContentLeftTitle: {
        ...Texts.basic(20, "bold", "#2c2c2c", 1.4),
        marginBottom: 30,
    },

    pricingContentDesc: {
        ...Texts.basic(16, 500, "#5f5f5f", 1.4),
        marginTop: -22,
        marginBottom: 30,
    },

    pricingContentPriceWrapper: { marginBottom: 40 },
    pricingContentLeftPrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentServiceWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    pricingContentLeftService: {
        marginLeft: 8,
        ...Texts.basic(16, 500, "#5f5f5f", 1.38),
    },
    membershipBtn: {
        marginTop: 16,
        width: "100%",
    },
    pricingContentMiddleWrapper: {
        flex: 1,
        marginLeft: 30,
        backgroundColor: "#ffffff",
        padding: 30,
        borderRadius: 12,
        border: "solid 2px #c6d4ff",
        borderTopWidth: 10,
        borderTopColor: "#8ca9ff",
        boxShadow: "0 4px 32px -10px #cbd2e9",
    },
    pricingContentMiddleTitle: {
        ...Texts.basic(20, "bold", "#496fe5", 1.4),
        marginBottom: 30,
    },
    pricingContentMiddlePrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentMiddleService: {
        marginLeft: 8,
        ...Texts.basic(16, "bold", "#6a8ceb", 1.38),
    },

    pricingContentRightWrapper: {
        flex: 1,
        marginLeft: 30,
        backgroundColor: "#ffffff",
        padding: 30,
        borderRadius: 12,
        border: "solid 2px #a1b1e3",
        borderTopWidth: 10,
        borderTopColor: "#2352dc",
        boxShadow: "0 4px 32px -10px #cbd2e9",
    },
    pricingContentRightTitle: {
        ...Texts.basic(20, "bold", "#0d3bc0", 1.4),
        marginBottom: 30,
    },
    pricingContentRightPrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentRightService: {
        marginLeft: 8,
        ...Texts.basic(16, "bold", "#0d3bc0", 1.38),
    },

    pricingContentBasicWrapper: {
        flex: 1,
        marginLeft: 30,
        backgroundColor: "#ffffff",
        padding: 30,
        borderRadius: 12,
        border: "solid 2px #d6dcec",
        borderTopWidth: 10,
        borderTopColor: "#111111",
        boxShadow: "0 4px 32px -10px #cbd2e9",
    },
    pricingContentBasicTitle: {
        ...Texts.basic(20, "bold", "#111111", 1.4),
        marginBottom: 30,
    },
    pricingContentBasicPrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentBasicService: {
        marginLeft: 8,
        ...Texts.basic(16, "bold", "#111111", 1.38),
    },
    hiringManagerSection: {
        height: 800,
        backgroundImage: `url(${require("assets/bgi-hiring-manager.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-hiring-manager.png")}) 1x, url(${require("assets/bgi-hiring-manager@2x.png")}) 2x, url(${require("assets/bgi-hiring-manager@2x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
    },
    hiringManagerContainer: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    hiringManagerTitle: {
        ...Texts.basic(48, "bold", "#ffffff", 1.33),
        textShadow: "0px 0px 8px rgba(0, 0, 0, 0.29)",
        whiteSpace: "pre-wrap",
    },
    reviewSection: {
        backgroundColor: "#f8f8f8",
        textAlign: "left",
        paddingTop: 150,
        paddingBottom: 160,
        overflowX: "hidden",
    },
    reviewSectionContainer: {
        position: "relative",
        overflowX: "visible",
    },
    reviewTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.33),
        whiteSpace: "pre-wrap",
        marginBottom: 64,
    },
    reviewCardWindow: {
        display: "flex",
        position: "relative",
        marginRight: -1100,
        paddingRight: 1780,
        overflowX: "auto",
        overflowY: "hidden",
        flexDirection: "row",
        marginBottom: 24,
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    reviewCard: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
        borderRadius: 20,
        boxShadow: "0 2px 16px 0 #eee",
        width: 529,
        height: 330,
        padding: 32,
        marginRight: 20,
    },
    reviewRatingWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    reviewRating: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    reviewRatingDesc: {
        marginLeft: 12,
        ...Texts.basic(16, 500, "#ababab", 1.38),
    },
    reviewContent: {
        flexGrow: 1,
        ...Texts.basic(20, 500, "#454545", 1.6),
    },
    reviewClientLogo: {
        height: 30,
    },
    reviewClient: {
        marginTop: 12,
        ...Texts.basic(16, 500, "#929292", 1.38),
    },
    reviewCardWindowScroll: {},
    reviewCardWindowScroller: {
        display: "inline-flex",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        boxShadow: "0 2px 16px 0 #eee",
        width: 56,
        height: 56,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 16,
    },

    benefitSection: {
        backgroundColor: "#3c73ff",
        textAlign: "center",
        paddingTop: 140,
    },
    benefitKeyword: {
        display: "inline-block",
        backgroundColor: "#ffffff",
        color: "#3c73ff",
        padding: "8px 16px",
        borderRadius: 24,
        marginBottom: 32,
    },
    benefitTitle: {
        ...Texts.basic(48, "bold", "#ffffff", 1.33),
        whiteSpace: "pre-wrap",
    },
    benefitDesc: {
        ...Texts.basic(16, 500, "#ffffff"),
        marginTop: 24,
        marginBottom: 43,
        whiteSpace: "pre-wrap",
    },
    benefitImg: { display: "block", marginLeft: "auto", marginRight: "auto" },
    timeBenefitSection: {
        backgroundColor: "#ffffff",
        textAlign: "center",
        paddingTop: 140,
        paddingBottom: 24,
    },
    timeBenefitKeyword: {
        display: "inline-block",
        backgroundColor: "#3c73ff",
        color: "#ffffff",
        padding: "8px 16px",
        borderRadius: 24,
        marginBottom: 32,
    },
    timeBenefitTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.33),
        marginBottom: 24,
        whiteSpace: "pre-wrap",
    },
    timeBenefitDesc: {
        display: "inline-block",
        margin: 6,
        width: 350,
        ...Texts.basic(20, 500, "#666666"),
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: "#f8f8f8",
        boxShadow: "inset 0 2px 8px 0 rgba(125, 125, 125, 0.24)",
        borderRadius: 8,
    },
    timeBenefitLongDesc: {
        display: "inline-block",
        margin: 6,
        width: 712,
        ...Texts.basic(20, 500, "#666666"),
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: "#f8f8f8",
        boxShadow: "inset 0 2px 8px 0 rgba(125, 125, 125, 0.24)",
        borderRadius: 8,
    },
    timeBenefitCaption: {
        ...Texts.basic(16, 300, "#999999"),
    },
    timeBenefitImgIconWrap: {
        position: "relative",
    },
    timeBenefitImg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    timeBenefitIcon: {
        position: "absolute",
        left: "55%",
        top: "60%",
        animation: "$motion 0.7s ease-in-out 0.3s infinite alternate",
        marginTop: 0,
    },
    "@keyframes motion": {
        "0%": {
            marginTop: 0,
        },
        "100%": {
            marginTop: 20,
        },
    },
    effectiveness: {
        marginTop: 64,
        marginBottom: 8,
        ...Texts.basic(14, 500, "#444444"),
    },
    matchFunctionSection: {
        height: 960,
        backgroundColor: "#f8f8f8",
    },
    functionContainer: {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
    },
    matchFunctionBox: {},
    matchFunctionSlogan: {
        marginBottom: 64,
        ...Texts.basic(48, "bold", "#222222", 1.33),
        whiteSpace: "pre-wrap",
    },
    matchFunctionBtn: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#0c46f2",
        borderRadius: 26.5,
        ...Texts.basic(20, "bold", "#ffffff"),
    },
    matchFunctionBtnUnClicked: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#eeeeee",
        borderRadius: 26.5,
        ...Texts.basic(20, 300, "#999999"),
    },
    matchFunctionDesc: {
        marginTop: 32,
        ...Texts.basic(16, 300, "#444444"),
    },
    interviewFunctionSection: {
        height: 960,
        backgroundColor: "#074396",
    },
    interviewFunctionSlogan: {
        marginBottom: 64,
        ...Texts.basic(48, "bold", "#ffffff", 1.33),
        whiteSpace: "pre-wrap",
    },
    interviewFunctionBtn: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#ffffff",
        borderRadius: 26.5,
        ...Texts.basic(20, "bold", "#074396"),
    },
    interviewFunctionBtnUnClicked: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#023378",
        borderRadius: 26.5,
        ...Texts.basic(20, "bold", "#8da4c3"),
    },
    interviewFunctionDesc: {
        marginTop: 32,
        ...Texts.basic(16, 300, "#ffffff"),
    },
    managerSection: {
        backgroundColor: "#f0f4ff",
        textAlign: "center",
        paddingTop: 140,
    },
    managerKeyword: {
        display: "inline-block",
        backgroundColor: "#dfe7ff",
        color: "#6c8ff8",
        //border: "solid 2px #0c46f2",
        padding: "8px 16px",
        borderRadius: 24,
        marginBottom: 41,
    },
    managerTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.3),
        whiteSpace: "pre-wrap",
    },
    managerDesc: {
        ...Texts.basic(16, 500, "#ffffff"),
        marginTop: 24,
        marginBottom: 43,
    },
    managerImg: { display: "block", marginLeft: "auto", marginRight: "auto" },
    progressBar: {
        position: "relative",
        zIndex: 0,
        display: "inline-block",
        textAlign: "center",
        width: 420,
        marginTop: 52,
    },
    line: {
        position: "absolute",
        top: "50%",
        left: "10%",
        width: "80%",
        borderBottom: "2px solid #1a6bff",
        zIndex: 1,
    },
    progress: {
        position: "relative",
        display: "inline-block",
        padding: "20px 22px",
        borderRadius: 45,
        border: "2px solid #1a6bff",
        ...Texts.basic(20, "bold", "#444444", 0.8),
        margin: theme.spacing(2, 5),
        zIndex: 2,
        backgroundColor: "#ffffff",
    },
    progressLabel: {
        display: "inline-block",
        ...Texts.basic(16, 500, "#444444", 1.25),
        width: 120,
        margin: theme.spacing(0, 1.5, 7),
        whiteSpace: "pre-wrap",
    },

    qnaSection: {
        backgroundColor: "#ffffffg",
        paddingTop: 100,
        paddingBottom: 100,
        textAlign: "center",
    },
    qnaSectionTitle: {
        ...Texts.basic(32, "bold", "#222222"),
        marginBottom: 56,
    },
    qnaBox: {
        marginLeft: 24,
        marginRight: 24,
        textAlign: "left",
        marginBottom: 16,
    },
    questionBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 16,
    },
    question: {
        ...Texts.basic(16, "bold", "#222222"),
        marginLeft: 4,
        flexGrow: 1,
    },
    answerBox: {
        backgroundColor: "#f2f2f2",
        justifyContent: "flex-start",
        padding: 16,
    },
    answer: {
        ...Texts.basic(14, 300, "#444444"),
        whiteSpace: "pre-wrap",
    },
    answerBoxClosed: {
        display: "none",
    },
    divider: {
        width: "100%",
    },
    fixedButtonRouteLink: {
        textDecoration: "none",
    },
    fixedButton: {
        backgroundColor: "#4d4d4d",
        textAlign: "center",
        position: "fixed",
        zIndex: 1,
        bottom: 0,
        width: "100%",
        height: 60,
        paddingTop: 18,
        paddingBottom: 18,
        boxShadow: "0px 0px 10px 1px #00000040",
    },
    fixedButtonName: {
        ...Texts.basic(14, "bold", "#ffffff", 1.43),
    },
    // fixedButtonDesc: {
    //     ...Texts.basic(10, 500, "#ffffff", 1.43),
    // },
    phantom: {
        width: "100%",
        height: 50,
    },
}));
