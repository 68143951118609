import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

let defaultHopeWorkBeginAt = new Date();
defaultHopeWorkBeginAt.setDate(defaultHopeWorkBeginAt.getDate() + 30);
defaultHopeWorkBeginAt = defaultHopeWorkBeginAt.toISOString();

let defaultBirthDay = new Date();
defaultBirthDay.setFullYear(defaultBirthDay.getFullYear() - 20);
defaultBirthDay = defaultBirthDay.toISOString();
console.log("defaultBirthDay", defaultBirthDay);
console.log("defaultHopeWorkBeginAt", defaultHopeWorkBeginAt);

let defaultHopeSalary = 30000000;

let defaultPhotos = [
    {
        id: "frame-1",
        url: null,
        order: 0,
        file: null,
    },
    {
        id: "frame-2",
        url: null,
        order: 1,
        file: null,
    },
    {
        id: "frame-3",
        url: null,
        order: 2,
        file: null,
    },
    {
        id: "frame-4",
        url: null,
        order: 3,
        file: null,
    },
    {
        id: "frame-5",
        url: null,
        order: 4,
        file: null,
    },
    {
        id: "frame-6",
        url: null,
        order: 5,
        file: null,
    },
];

export const profileSlice = createSlice({
    name: "profile",
    initialState: {
        enableRecommendation: true,
        email: "",
        token: null,
        name: "",
        thumbnail: null,
        photos: defaultPhotos,
        birth: defaultBirthDay,
        hopePosition: "",
        position: "",
        positionExpYears: "",
        devExpYears: "",
        postalCode: "",
        address: "",
        addressDetail: "",
        githubLink: "",
        introduction: "",
        skills: [],
        hopeSalary: defaultHopeSalary,
        hopeSalaryRange: [],
        hopeWorkBeginAt: defaultHopeWorkBeginAt,
        schools: [],
        companies: [],
        projects: [],
        portfolioLink: "",
        portfolios: [],
        military: "",
        isComplete: undefined,
        isRecommAllowed: undefined,
        isRecommAllowedNotified: undefined,
    },
    reducers: {
        setEnableRecommendation: (state, action) => {
            state.enableRecommendation = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setThumbnail: (state, action) => {
            state.thumbnail = action.payload;
            console.log("Global state.thumbnail", state.thumbnail);
        },
        setPhotos: (state, action) => {
            state.photos = action.payload;
            console.log("Global state.photos", state.photos);
        },
        setBirth: (state, action) => {
            state.birth = action.payload;
        },
        setHopePosition: (state, action) => {
            state.hopePosition = action.payload;
        },
        setPosition: (state, action) => {
            state.position = action.payload;
        },
        setPositionExpYears: (state, action) => {
            state.positionExpYears = action.payload;
        },
        setDevExpYears: (state, action) => {
            state.devExpYears = action.payload;
        },
        setPostalCode: (state, action) => {
            state.postalCode = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setAddressDetail: (state, action) => {
            state.addressDetail = action.payload;
        },
        setGithubLink: (state, action) => {
            state.githubLink = action.payload;
        },
        setIntroduction: (state, action) => {
            state.introduction = action.payload;
        },
        setSkills: (state, action) => {
            state.skills = action.payload;
        },
        setHopeSalary: (state, action) => {
            state.hopeSalary = action.payload;
        },
        setHopeSalaryRange: (state, action) => {
            state.hopeSalaryRange = action.payload;
        },
        setHopeWorkBeginAt: (state, action) => {
            state.hopeWorkBeginAt = action.payload;
        },
        setSchools: (state, action) => {
            state.schools = action.payload;
        },
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setProjects: (state, action) => {
            state.projects = action.payload;
        },
        setPortfolioLink: (state, action) => {
            state.portfolioLink = action.payload;
        },
        setPortfolios: (state, action) => {
            state.portfolios = action.payload;
        },
        setMilitary: (state, action) => {
            state.military = action.payload;
        },
        setIsComplete: (state, action) => {
            state.isComplete = action.payload;
        },
        setIsRecommAllowed: (state, action) => {
            state.isRecommAllowed = action.payload;
        },
        setIsRecommAllowedNotified: (state, action) => {
            state.isRecommAllowedNotified = action.payload;
        },
        setProfile: (state, action) => {
            state = action.payload;
        },
    },
});

export const {
    setEnableRecommendation,
    setEmail,
    setToken,
    setName,
    setThumbnail,
    setPhotos,
    setBirth,
    setHopePosition,
    setPosition,
    setPositionExpYears,
    setDevExpYears,
    setPostalCode,
    setAddress,
    setAddressDetail,
    setGithubLink,
    setIntroduction,
    setSkills,
    setHopeSalary,
    setHopeSalaryRange,
    setHopeWorkBeginAt,
    setSchools,
    setCompanies,
    setProjects,
    setPortfolioLink,
    setPortfolios,
    setMilitary,
    setIsComplete,
    setIsRecommAllowed,
    setIsRecommAllowedNotified,
} = profileSlice.actions;

export const setHopeSalaryAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(setHopeSalary(amount));
    }, 100);
};

export const setHopeSalaryRangeAsync = (range) => (dispatch) => {
    setTimeout(() => {
        dispatch(setHopeSalaryRange(range));
    }, 100);
};

export const setProfile = (profile) => (dispatch) => {
    const newPhotos = _.cloneDeep(defaultPhotos);
    profile.photos.forEach((photo, index) => {
        newPhotos[photo.index].url = photo.url;
    });
    dispatch(
        setEnableRecommendation(profile.user.preference.enableRecommendation)
    );
    dispatch(setEmail(profile.user.email));
    dispatch(setThumbnail(profile.thumbnail));
    dispatch(setPhotos(newPhotos));
    dispatch(setName(profile.name));
    dispatch(setBirth(profile.birth));
    dispatch(setHopePosition(profile.hopePositions[0].position.name));
    dispatch(setPosition(profile.mainPosition.name));
    dispatch(setPositionExpYears(profile.mainCareer));
    dispatch(setDevExpYears(profile.totalCareer));
    dispatch(setPostalCode(profile.zipCode));
    dispatch(setAddress(profile.address1));
    dispatch(setAddressDetail(profile.address2));
    // dispatch(setGithubLink(profile.githubLink));
    dispatch(setIntroduction(profile.introduction));
    dispatch(
        setSkills(
            profile.hardSkills.map((skill) => ({
                skill: skill.skill.name,
                rate: skill.rate,
            }))
        )
    );

    dispatch(setHopeSalary(profile.hopeSalary));

    dispatch(setHopeSalaryRange([profile.hopeSalary, profile.hopeSalary]));
    dispatch(setHopeWorkBeginAt(profile.hopeWorkBeginAt));

    dispatch(
        setSchools(
            profile.educations.map((education) => ({
                id: education.id,
                type: education.type,
                organization: education.organization,
                major: education.major,
                degree: education.degree,
                beginAt: education.beginAt,
                endAt: education.endAt ?? null,
                attending: education.endAt ? false : true,
                activity: education.activity ?? "",
            }))
        )
    );

    dispatch(
        setCompanies(
            profile.careers.map((career) => ({
                id: career.id,
                company: career.company,
                position: career.position.name,
                beginAt: career.beginAt,
                endAt: career.endAt ?? null,
                working: career.endAt ? false : true,
                task: career.task,
                // link: career.link ?? "",
            }))
        )
    );

    dispatch(
        setProjects(
            profile.projects.map((project) => ({
                id: project.id,
                name: project.name,
                organization: project.organization,
                team: project.team,
                position: project.position.name,
                beginAt: project.beginAt,
                endAt: project.endAt ?? null,
                working: project.endAt ? false : true,
                task: project.task,
                link: project.link ?? "",
            }))
        )
    );

    dispatch(setPortfolioLink(profile.portfolioLink));
    // dispatch(setPortfolios([{ type: "기타", link: profile.portfolioLink }]));

    dispatch(setMilitary(profile.gender === "female" ? "해당없음" : ""));
    dispatch(setIsComplete(profile.isComplete));
    dispatch(setIsRecommAllowed(profile.user.isRecommAllowed));
    dispatch(setIsRecommAllowedNotified(profile.user.isRecommAllowedNotified));

    return true;
};

export const setBasicProfile = (basicProfile) => (dispatch) => {
    dispatch(setName(basicProfile.name));
    dispatch(setBirth(basicProfile.birth));
    dispatch(setHopePosition(basicProfile.hopePosition));
    dispatch(setPosition(basicProfile.position));
    dispatch(setPositionExpYears(basicProfile.positionExpYears));
    dispatch(setDevExpYears(basicProfile.devExpYears));
    dispatch(setPostalCode(basicProfile.postalCode));
    dispatch(setAddress(basicProfile.address));
    dispatch(setAddressDetail(basicProfile.addressDetail));
    // dispatch(setGithubLink(basicProfile.githubLink));
    dispatch(setIntroduction(basicProfile.introduction));
    return true;
};

export default profileSlice.reducer;
