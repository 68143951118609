import React from "react";
import { Container, Box, Typography, Button } from "@material-ui/core";
import { ServiceHeader } from "../components";
import { Helmet } from "react-helmet";
import { useStyles } from "./styleProfileUnsupported";
import { TXImage } from "../../components/tx";

const ProfileUnsupportedForCompany = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필 검토 미지원</title>
            </Helmet>
            <ServiceHeader />
            <Container maxWidth="xs" disableGutters>
                <Box className={classes.inner}>
                    <TXImage imgName="img-mobile-app-bg" />
                    <Typography className={classes.alert}>
                        모바일 앱으로 검토해주세요
                    </Typography>
                    <Typography className={classes.guide}>
                        인재 프로필 검토는 모바일 브라우저에서는 지원하지
                        않아요. 모바일에서는 앱을 이용해주세요.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.btn}
                        onClick={() =>
                            window.open(
                                "https://talentx.co.kr/app/company/hiring",
                                "newWindow"
                            )
                        }
                    >
                        앱에서 프로필 검토
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default ProfileUnsupportedForCompany;
