import React from "react";
import Root from "./Root";
import { Helmet } from "react-helmet";

import UserProvider from "providers/UserProvider";
import {
    MuiThemeProvider,
    createTheme,
    responsiveFontSizes,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { blue, pink } from "@material-ui/core/colors";

let theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    letterSpacing: -0.6,
                    backgroundColor: "#ffffff",
                },
            },
        },
    },
    palette: {
        //primary: { main: "#1a1a84" },
        primary: { main: "#0c46f2" },
        secondary: { main: "#ffbbd4" },
    },
    typography: {
        fontSize: 14, //Mui default: 14px,
        htmlFontSize: 16, //Browser default: 16px
        fontFamily: "Noto Sans KR",
        body1: {
            lineHeight: 1.78,
        },
    },
});

theme = responsiveFontSizes(theme);

function App() {
    return (
        <React.StrictMode>
            <MuiThemeProvider theme={theme}>
                <React.Fragment>
                    <Helmet>
                        <title>탤런트엑스</title>
                        <meta
                            property="og:image"
                            content="https://www.talentinum.com/new-thumnail.png"
                        />
                    </Helmet>
                    <CssBaseline />
                    <UserProvider>
                        <Root />
                    </UserProvider>
                </React.Fragment>
            </MuiThemeProvider>
        </React.StrictMode>
    );
}

export default App;
