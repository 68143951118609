import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Header, Footer } from "pages/components";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import { termsOfTalentUser, termsOfCompanyUser, privacyPolicyData } from "data";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { auth } from "apis/firebase";
import { UserContext } from "../providers/UserProvider";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(5, 0, 10),
            backgroundColor: theme.palette.background.paper,
            color: "#434343",
        },
        title: {
            margin: theme.spacing(5, 0),
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                // padding: "0 calc((100% - 296px) / 2)",
                padding: "0 32px",
            },
        },
        article: {
            marginBottom: theme.spacing(5),
        },
        clouses: {
            listStyleType: "decimal",
            paddingLeft: "24px",
        },
        items: {
            listStyleType: "upper-alpha",
            paddingLeft: "16px",
        },
        upBtn: {
            marginTop: theme.spacing(5),
            left: "50%",
            right: "50%",
            transform: "translate(-50%, 0%)",
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
    };
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

export default function TermsOfUse(params) {
    const [value, setValue] = React.useState(0);
    const user = useContext(UserContext);
    let name;
    if (user) name = user.displayName;
    console.log(user);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();

    let terms = value === 0 ? termsOfCompanyUser : termsOfTalentUser;

    const onPressUp = () => window.scrollTo({ top: 0, behavior: "smooth" });

    useEffect(() => {
        window.scrollTo({ top: 0 });
        return () => {
            window.scrollTo({ top: 0 });
        };
    }, []);

    return (
        <React.Fragment>
            <Header />
            <Box className={classes.root}>
                <Container maxWidth="lg" className={classes.container}>
                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                        {privacyPolicyData}
                    </Typography>

                    <Fab
                        size="medium"
                        variant="extended"
                        className={classes.upBtn}
                        onClick={onPressUp}
                    >
                        <NavigationIcon className={classes.extendedIcon} />
                        맨위로
                    </Fab>
                </Container>
            </Box>
            <Footer />
        </React.Fragment>
    );
}
