import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSchools } from "../../redux/slice/profileSlice";
import {
    Box,
    Typography,
    Divider,
    Button,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
} from "@material-ui/core";
import { AntSwitch } from "../components";
import { useStyles } from "./styleProfileSchool";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import {
    createTalentProfileEducation,
    updateTalentProfileEducation,
    deleteTalentProfileEducation,
} from "../../apis/profile/profile";

import { displayLocalYearMonth } from "../../helper/misc";

const degreeBySchoolType = {
    대학교: ["학사", "전문학사"],
    대학원: ["석사", "박사"],
};

const initialSchool = {
    id: undefined,
    type: "",
    organization: "",
    major: null,
    degree: null,
    beginAt: null,
    endAt: null,
    attending: false,
    activity: "",
};
// const initialSchool = {
//     id: undefined,
//     type: "",
//     organization: "",
//     major: null,
//     degree: null,
//     beginAt: new Date(2000, 0, 1).toISOString(),
//     endAt: new Date(2000, 0, 1).toISOString(),
//     attending: false,
//     activity: "",
// };

console.log("initialSchool", initialSchool);

const ProfileSchool = ({ edit = true }) => {
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [schoolAdd, setSchoolAdd] = useState(false);
    const [schoolEditIndex, setSchoolEditIndex] = useState(-1);
    const [schoolBeingCreated, setSchoolBeingCreated] = useState(initialSchool);
    const [isUploading, setIsUploading] = useState(false);

    const handleSchoolChange = (event) => {
        const key = event.target.id || event.target.name;
        const value = event.target.value;
        setSchoolBeingCreated((prev) => {
            const newSchool = { ...prev };
            newSchool[key] = value;
            return newSchool;
        });
    };

    const handleSchoolAttendingChange = (event) => {
        setSchoolBeingCreated((prev) => {
            const newSchool = { ...prev };
            newSchool.attending = event.target.checked;
            newSchool.endAt = null;
            return newSchool;
        });
    };

    const handleSchoolBeginAtChange = (date, value) => {
        setSchoolBeingCreated((prev) => {
            const newSchool = { ...prev };
            console.log("date?.toJSON()", date?.toJSON());
            newSchool.beginAt = date?.toJSON();
            return newSchool;
        });
    };

    const handleSchoolEndAtChange = (date, value) => {
        setSchoolBeingCreated((prev) => {
            const newSchool = { ...prev };
            console.log("date?.toJSON()", date?.toJSON());
            newSchool.endAt = date?.toJSON();
            return newSchool;
        });
    };

    const handleSchoolCancle = (event) => {
        event.preventDefault();
        setSchoolBeingCreated(initialSchool);
        setSchoolEditIndex(-1);
        setSchoolAdd(false);
    };

    const handleSchoolSubmit = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        let newSchool = { ...schoolBeingCreated };
        let newSchools;
        if (schoolEditIndex === -1) {
            const createdEducation = await createTalentProfileEducation(
                profile.token,
                schoolBeingCreated
            );
            if (createdEducation) {
                newSchool.id = createdEducation.id;
                newSchools = profile.schools.concat(newSchool);
            } else history.push("/profile/access-expired");
        } else {
            console.log(
                "schoolBeingCreated before updating in server",
                schoolBeingCreated
            );
            const updateResult = await updateTalentProfileEducation(
                profile.token,
                schoolBeingCreated.id,
                schoolBeingCreated
            );
            if (updateResult?.affected === 1) {
                newSchools = profile.schools.map((school, index) => {
                    if (index === schoolEditIndex) {
                        return schoolBeingCreated;
                    }
                    return school;
                });
            } else if (!updateResult) history.push("/profile/access-expired");
        }

        console.log("newSchools", newSchools);
        dispatch(setSchools(newSchools));
        setSchoolBeingCreated(initialSchool);
        setSchoolEditIndex(-1);
        setIsUploading(false);
        setSchoolAdd(false);
    };

    const handleSchoolEdit = (index) => {
        setSchoolEditIndex(index);
        setSchoolBeingCreated(profile.schools[index]);
        setSchoolAdd(true);
    };

    const handleSchoolDelete = async (index, educationId) => {
        const isDeleted = await deleteTalentProfileEducation(
            profile.token,
            educationId
        );
        if (isDeleted) {
            const newSchools = profile.schools.filter((_, i) => i !== index);
            dispatch(setSchools(newSchools));
        } else history.push("/profile/access-expired");
    };

    const submitable =
        (schoolBeingCreated.type === "고등학교" &&
            schoolBeingCreated.organization &&
            schoolBeingCreated.beginAt &&
            (schoolBeingCreated.attending || schoolBeingCreated.beginAt) &&
            !isUploading) ||
        (schoolBeingCreated.type &&
            schoolBeingCreated.organization &&
            schoolBeingCreated.major &&
            schoolBeingCreated.degree &&
            schoolBeingCreated.beginAt &&
            (schoolBeingCreated.attending || schoolBeingCreated.beginAt) &&
            !isUploading);

    return (
        <Box className={classes.infoWrap}>
            <Box className={classes.infoHeader}>
                <Typography className={classes.infoTitle}>학력</Typography>
                {edit && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setSchoolAdd(true)}
                        disabled={schoolAdd}
                    >
                        추가하기
                    </Button>
                )}
            </Box>
            {!schoolAdd &&
                schoolEditIndex === -1 &&
                profile.schools?.map((school, index) => (
                    <Fragment key={index}>
                        {index > 0 && <Divider />}
                        <Grid
                            key={index}
                            container
                            spacing={1}
                            className={classes.schoolItemWrap}
                        >
                            <Grid item xs={4}>
                                <Typography className={classes.schoolName}>
                                    {school.organization}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    className={classes.schoolMajorDegree}
                                >
                                    {school.major}{" "}
                                    {school.type !== "고등학교" &&
                                        school.degree}
                                </Typography>
                            </Grid>
                            {edit && (
                                <Grid item xs={2}>
                                    <Box className={classes.schoolItemBtnWrap}>
                                        <Typography
                                            className={classes.schoolEditBtn}
                                            onClick={() =>
                                                handleSchoolEdit(index)
                                            }
                                        >
                                            편집
                                        </Typography>
                                        <Typography
                                            className={classes.schoolDeleteBtn}
                                            onClick={() =>
                                                handleSchoolDelete(
                                                    index,
                                                    school.id
                                                )
                                            }
                                        >
                                            삭제
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <Typography className={classes.schoolDuration}>
                                    {displayLocalYearMonth(school.beginAt)} ~{" "}
                                    {school.endAt
                                        ? displayLocalYearMonth(school.endAt)
                                        : "재학중"}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.schoolDetail}>
                                    {school.activity}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fragment>
                ))}

            {schoolAdd && (
                <>
                    <Divider className={classes.darkDivider} />
                    <Grid container spacing={3}>
                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    학력 구분
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.noBorderSelect}
                                    labelId="demo-simple-select-label"
                                    id="type"
                                    name="type"
                                    displayEmpty
                                    value={schoolBeingCreated.type}
                                    onChange={handleSchoolChange}
                                    classes={{
                                        root: classes.resize,
                                    }}
                                >
                                    <MenuItem
                                        value=""
                                        classes={{
                                            root: classes.resize,
                                        }}
                                        disabled
                                    >
                                        선택해주세요.
                                    </MenuItem>
                                    {["고등학교", "대학교", "대학원"].map(
                                        (item, idx) => (
                                            <MenuItem
                                                key={idx}
                                                value={item}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {schoolBeingCreated.type && (
                            <>
                                <Grid item xs={6} className={classes.gridItem}>
                                    <Box className={classes.gridItemHeader}>
                                        <Typography
                                            className={classes.infoSubTitle}
                                        >
                                            학교명
                                        </Typography>
                                        <Box className={classes.required}></Box>
                                    </Box>
                                    <TextField
                                        required
                                        className={classes.textField}
                                        inputProps={{ maxLength: 64 }}
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                                notchedOutline:
                                                    classes.noBorder,
                                            },
                                        }}
                                        id="organization"
                                        variant="outlined"
                                        placeholder="학교명 입력"
                                        value={schoolBeingCreated.organization}
                                        onChange={handleSchoolChange}
                                    />
                                </Grid>
                                {schoolBeingCreated.type !== "고등학교" && (
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.gridItem}
                                    >
                                        <Box className={classes.gridItemHeader}>
                                            <Typography
                                                className={classes.infoSubTitle}
                                            >
                                                전공명
                                            </Typography>
                                            <Box
                                                className={classes.required}
                                            ></Box>
                                        </Box>
                                        <TextField
                                            required
                                            className={classes.textField}
                                            inputProps={{ maxLength: 64 }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                    notchedOutline:
                                                        classes.noBorder,
                                                },
                                            }}
                                            id="major"
                                            variant="outlined"
                                            placeholder="전공명 입력"
                                            value={schoolBeingCreated.major}
                                            onChange={handleSchoolChange}
                                        />
                                    </Grid>
                                )}
                                {schoolBeingCreated.type !== "고등학교" && (
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.gridItem}
                                    >
                                        <Box className={classes.gridItemHeader}>
                                            <Typography
                                                className={classes.infoSubTitle}
                                            >
                                                학위
                                            </Typography>
                                            <Box
                                                className={classes.required}
                                            ></Box>
                                        </Box>
                                        <FormControl variant="outlined">
                                            <Select
                                                className={
                                                    classes.noBorderSelect
                                                }
                                                labelId="demo-simple-select-label"
                                                id="degree"
                                                name="degree"
                                                displayEmpty
                                                value={
                                                    schoolBeingCreated.degree
                                                }
                                                onChange={handleSchoolChange}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                <MenuItem
                                                    value=""
                                                    classes={{
                                                        root: classes.resize,
                                                    }}
                                                    disabled
                                                >
                                                    선택해주세요.
                                                </MenuItem>
                                                {schoolBeingCreated.type &&
                                                    degreeBySchoolType[
                                                        schoolBeingCreated.type
                                                    ].map((item, idx) => (
                                                        <MenuItem
                                                            key={idx}
                                                            value={item}
                                                            classes={{
                                                                root: classes.resize,
                                                            }}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={6} className={classes.gridItem}>
                                    <Box className={classes.gridItemHeader}>
                                        <Typography
                                            className={classes.infoSubTitle}
                                        >
                                            입학
                                        </Typography>
                                        <Box className={classes.required}></Box>
                                        <Box className={classes.flexGrowBox} />
                                        <Typography
                                            className={classes.infoSubLabel}
                                        >
                                            재학중
                                        </Typography>
                                        <AntSwitch
                                            id="attending"
                                            name="attending"
                                            checked={
                                                schoolBeingCreated.attending
                                            }
                                            onChange={
                                                handleSchoolAttendingChange
                                            }
                                        />
                                    </Box>

                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            required
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 0,
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.dateInput,
                                                    root: classes.adornedEnd,
                                                    notchedOutline:
                                                        classes.whiteBorder,
                                                },
                                            }}
                                            KeyboardButtonProps={{
                                                classes: {
                                                    root: classes.keyboardBtn,
                                                },
                                            }}
                                            variant="inline"
                                            inputVariant="outlined"
                                            views={["year", "month"]}
                                            format="yyyy/MM"
                                            invalidDateMessage={
                                                <Typography
                                                    style={{ fontSize: 12 }}
                                                >
                                                    YYYY/MM 형식으로 입력해
                                                    주세요!
                                                </Typography>
                                            }
                                            margin="normal"
                                            id="beginAt"
                                            disableFuture
                                            maxDateMessage={
                                                <Typography
                                                    style={{ fontSize: 12 }}
                                                >
                                                    오늘 이전 날짜를 입력해
                                                    주세요!
                                                </Typography>
                                            }
                                            value={schoolBeingCreated.beginAt}
                                            onChange={handleSchoolBeginAtChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {!schoolBeingCreated.attending && (
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.gridItem}
                                    >
                                        <Box className={classes.gridItemHeader}>
                                            <Typography
                                                className={classes.infoSubTitle}
                                            >
                                                졸업
                                            </Typography>
                                            <Box
                                                className={classes.required}
                                            ></Box>
                                        </Box>

                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                required
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.dateInput,
                                                        root: classes.adornedEnd,
                                                        notchedOutline:
                                                            classes.whiteBorder,
                                                    },
                                                }}
                                                KeyboardButtonProps={{
                                                    classes: {
                                                        root: classes.keyboardBtn,
                                                    },
                                                }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                views={["year", "month"]}
                                                format="yyyy/MM"
                                                invalidDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        YYYY/MM 형식으로 입력해
                                                        주세요!
                                                    </Typography>
                                                }
                                                margin="normal"
                                                id="endAt"
                                                disableFuture
                                                minDate={
                                                    schoolBeingCreated.beginAt
                                                }
                                                minDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        입학 이후 날짜를 입력해
                                                        주세요!
                                                    </Typography>
                                                }
                                                maxDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        오늘 이전 날짜를 입력해
                                                        주세요!
                                                    </Typography>
                                                }
                                                value={schoolBeingCreated.endAt}
                                                onChange={
                                                    handleSchoolEndAtChange
                                                }
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                autoOk={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                )}

                                <Grid item xs={12} className={classes.gridItem}>
                                    <Box className={classes.gridItemHeader}>
                                        <Typography
                                            className={classes.infoSubTitle}
                                        >
                                            주요 학업 내용 및 성과 (선택)
                                        </Typography>
                                    </Box>
                                    <TextField
                                        required
                                        className={classes.textField}
                                        inputProps={{ maxLength: 1000 }}
                                        multiline
                                        minRows={3}
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                                notchedOutline:
                                                    classes.noBorder,
                                            },
                                        }}
                                        id="activity"
                                        variant="outlined"
                                        placeholder="주요 학업 내용 입력 (최대 1000자)"
                                        value={schoolBeingCreated.activity}
                                        onChange={handleSchoolChange}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Box className={classes.infoFooter}>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="outlined"
                            color="default"
                            disableElevation
                            onClick={handleSchoolCancle}
                        >
                            취소
                        </Button>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleSchoolSubmit}
                            disabled={!submitable}
                        >
                            {schoolEditIndex !== -1 ? "수정" : "등록"}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProfileSchool;
