import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPortfolioLink } from "../../redux/slice/profileSlice";
import {
    Box,
    Typography,
    Divider,
    Button,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Icon,
} from "@material-ui/core";
import { TXImage } from "components/tx";
import { useStyles } from "./styleProfilePortfolio";
import { portfolioTypeIcon } from "data";

import "date-fns";
import { displayLink } from "../../helper/misc";

import { updateTalentProfileBasicinfo } from "../../apis/profile/profile";

const portfolioLinkType = (link) => {
    if (link.includes("github")) {
        return "code";
    } else if (link.includes("notion")) {
        return "notes";
    } else return "web_stories";
};

const ProfilePortfolio = ({ edit = true }) => {
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [portfolioLinkAdd, setPortfolioLinkAdd] = useState(false);
    const [portfolioLinkBeingCreated, setPortfolioLinkBeingCreated] =
        useState("");
    const [isUploading, setIsUploading] = useState(false);

    const handlePortfolioLinkChange = (event) => {
        setPortfolioLinkBeingCreated(event.target.value);
    };

    const handlePortfolioLinkCancle = (event) => {
        event.preventDefault();
        setPortfolioLinkBeingCreated("");
        setPortfolioLinkAdd(false);
    };

    const handlePortfolioLinkSubmit = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        const updateResult = await updateTalentProfileBasicinfo(profile.token, {
            portfolioLink: portfolioLinkBeingCreated
                ? portfolioLinkBeingCreated.trim()
                : null,
        });
        if (updateResult?.affected === 1) {
            dispatch(
                setPortfolioLink(
                    portfolioLinkBeingCreated
                        ? portfolioLinkBeingCreated.trim()
                        : null
                )
            );
            setPortfolioLinkBeingCreated("");
            setIsUploading(false);
            setPortfolioLinkAdd(false);
        } else if (!updateResult) history.push("/profile/access-expired");
    };

    const handlePortfolioLinkAdd = () => {
        setPortfolioLinkAdd(true);
    };

    const handlePortfolioLinkEdit = () => {
        setPortfolioLinkBeingCreated(profile.portfolioLink);
        setPortfolioLinkAdd(true);
    };

    const handlePortfolioLinkDelete = async () => {
        dispatch(setPortfolioLink(""));
        const updateResult = await updateTalentProfileBasicinfo(profile.token, {
            portfolioLink: null,
        });
        console.log("updateResult", updateResult);
        if (!updateResult) history.push("/profile/access-expired");
    };

    const submitable = !!portfolioLinkBeingCreated && !isUploading;

    return (
        <Box className={classes.infoWrap}>
            <Box className={classes.infoHeader}>
                <Typography className={classes.infoTitle}>
                    IT 포트폴리오
                </Typography>
                {edit && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={
                            profile.portfolioLink
                                ? handlePortfolioLinkEdit
                                : handlePortfolioLinkAdd
                        }
                        disabled={portfolioLinkAdd}
                    >
                        {profile.portfolioLink ? "수정하기" : "추가하기"}
                    </Button>
                )}
            </Box>
            {!portfolioLinkAdd && profile.portfolioLink && (
                <Grid
                    container
                    spacing={0}
                    className={classes.portfolioItemWrap}
                >
                    <Grid item xs={12}>
                        <Box className={classes.portfolioBox}>
                            <Icon>
                                {portfolioLinkType(profile.portfolioLink)}
                            </Icon>
                            <Typography className={classes.portfolioTypeLink}>
                                {displayLink(profile.portfolioLink)}
                            </Typography>
                            <Box className={classes.flexGrowBox} />
                            {edit && (
                                <TXImage
                                    imgName="icon-close-gray-24-dp"
                                    onClick={handlePortfolioLinkDelete}
                                    className={classes.portfolioDeleteBtn}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}

            {portfolioLinkAdd && (
                <>
                    <Divider className={classes.darkDivider} />

                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    공개 링크
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                inputProps={{ maxLength: 250 }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="link"
                                variant="outlined"
                                placeholder="URL"
                                value={portfolioLinkBeingCreated}
                                onChange={handlePortfolioLinkChange}
                            />
                        </Grid>
                    </Grid>
                    <Box className={classes.infoFooter}>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="outlined"
                            color="default"
                            disableElevation
                            onClick={handlePortfolioLinkCancle}
                        >
                            취소
                        </Button>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handlePortfolioLinkSubmit}
                            disabled={!submitable}
                        >
                            {profile.portfolioLink ? "수정" : "등록"}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProfilePortfolio;
