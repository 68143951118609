export const mentorData = [
    "박상현 멘토",
    "한신환 멘토",
    "오성원 멘토",
    "조성득 멘토",
    "박현준 멘토",
    "단곤영 멘토",
    "이정훈 멘토",
    "고준희 멘토",
    "김동진 멘토",
    "신재철 멘토",
    "성지웅 멘토",
    "권병준 멘토",
    "조영일 멘토",
    "이현우 멘토",
    "배지원 멘토",
    "기타",
];
