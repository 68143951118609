import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    LinearProgress,
    Tooltip,
    Icon,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setIsComplete } from "../../redux/slice/profileSlice";
import { useStyles } from "./styleProfileProgress";
import { updateTalentProfileIsComplete } from "../../apis/profile/profile";

function LinearProgressWithLabel(props) {
    const classes = useStyles();
    return (
        <Box display="flex" alignItems="center">
            <Tooltip
                title={
                    <React.Fragment>
                        <Typography className={classes.progressGuideTitle}>
                            프로필 완성도 기준
                        </Typography>
                        <Typography className={classes.progressGuide}>
                            프로필 사진 등록: +10%
                            <br />
                            영입 제안 받기 설정: +10%
                            <br />
                            기본 정보 편집: +30%
                            <br />
                            보유 기술 입력: +10%
                            <br />
                            학력 입력: +10%
                            <br />
                            경력 입력: +10%
                            <br />
                            프로젝트 입력: +10%
                            <br />
                            IT포트폴리오 링크 입력: +10%
                        </Typography>
                    </React.Fragment>
                }
                classes={{
                    tooltip: classes.tooltip,
                }}
            >
                <Box display="flex" alignItems="center" minWidth={100} mr={1}>
                    <Typography className={classes.progressTitle}>
                        프로필 완성도
                    </Typography>
                    <Icon className={classes.questionMark}>help</Icon>
                </Box>
            </Tooltip>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography className={classes.progressValue}>{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const ProfileProgress = () => {
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const progress =
        (profile.photos[0].url ? 10 : 0) +
        (profile.enableRecommendation ? 10 : 0) +
        (profile.position ? 10 : 0) +
        (profile.positionExpYears ? 10 : 0) +
        (profile.devExpYears ? 10 : 0) +
        (profile.skills.length ? 10 : 0) +
        (profile.schools.length ? 10 : 0) +
        (profile.companies.length ? 10 : 0) +
        (profile.projects.length ? 10 : 0) +
        (profile.portfolioLink ? 10 : 0);

    useEffect(() => {
        async function updateTalentProfileProgress() {
            console.log("Start updateTalentProfileProgress()", progress);
            const updateResult = await updateTalentProfileIsComplete(
                profile.token,
                {
                    isComplete: true,
                }
            );
            if (updateResult) {
                dispatch(setIsComplete(true));
            }
        }
        if (profile.token && profile.isComplete === false && progress >= 90) {
            console.log("Talent Profile Complete", progress);
            updateTalentProfileProgress();
        }
    }, [progress]);

    return (
        <Box className={classes.infoWrap}>
            {/* <Box className={classes.infoHeader}>
                <Typography className={classes.infoTitle}>
                    프로필 완성도
                </Typography>
            </Box> */}
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
};

export default ProfileProgress;
