import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { companyData } from "data";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            textAlign: "center",
            padding: theme.spacing(10, 0),
        },
        typography: {
            margin: theme.spacing(1),
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                // padding: "0 calc((100% - 296px) / 2)",
                padding: "0 32px",
            },
        },
        logoWrapper: {
            height: "240px",
            [theme.breakpoints.down("xs")]: {
                height: "180px",
            },
            border: "solid 1px black",
            margin: "0 -1px -1px 0",
        },
        logo: {
            [theme.breakpoints.down("xs")]: {
                maxWidth: 250,
            },
        },
        companyListButton: {
            width: "320px",
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
    };
});

export default function RecruitmentSummary(params) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const mainFeaturingCompanies = companyData.filter(
        (company) => company.mainFeaturing
    );

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={isMobile ? 4 : 10}>
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.typography}>
                            <b>COMPANY</b>
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            className={classes.typography}
                        >
                            유망 기업들이 당신을 기다리고 있습니다.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={0}>
                        {mainFeaturingCompanies.map((company, idx) => (
                            <Grid
                                key={idx.toString()}
                                item
                                xs={12}
                                md={4}
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                className={classes.logoWrapper}
                            >
                                <img
                                    src={company.img}
                                    className={classes.logo}
                                    alt={company.name}
                                ></img>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip
                            title={`현재 ${companyData.length}개 유망 기업 참여중`}
                            placement="top"
                            arrow
                        >
                            <span>
                                <Link
                                    to="/company-list"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        endIcon={<NavigateNextIcon />}
                                        className={classes.companyListButton}
                                    >
                                        전체 참여기업 확인
                                    </Button>
                                </Link>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
