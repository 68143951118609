import React, { useEffect } from "react";
import { isAndroid, isWindows } from "react-device-detect";

function Download() {
    useEffect(() => {
        window.location.href =
            isAndroid || isWindows
                ? "https://play.google.com/store/apps/details?id=com.talentinum.talentx"
                : "https://apps.apple.com/kr/app/%ED%83%A4%EB%9F%B0%ED%8A%B8%EC%97%91%EC%8A%A4/id1529534277";
    }, []);

    return (
        <div style={{ textAlign: "center" }}>
            <p>Connecting...</p>
        </div>
    );
}

export default Download;
