import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((props) => ({
    inner: (props) => ({
        paddingTop: props.isMobile ? 0 : 96,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingLeft: props.isMobile ? 4 : 0,
        paddingRight: props.isMobile ? 4 : 0,
        marginBottom: 20,
    }),
    leftSection: (props) => ({
        flex: "2 1 600px",
        overflow: "hidden",
    }),
    imageSlider: (props) => ({
        marginBottom: 24,
        paddingTop: props.isMobile ? 95 : 0,
    }),
    image: (props) => ({
        borderRadius: props.isMobile ? 0 : 8,
        width: "100%",
    }),
    company: {
        ...Texts.basic(24, "bold", "#2c2c2c", 1.21),
        marginBottom: 8,
    },
    companyWrap: {
        display: "flex",
        flexDirection: "row",
    },
    premium: {
        height: 20,
        marginLeft: 8,
    },
    topSlogan: {
        ...Texts.basic(21, "bold", "#111111", 1.38),
        marginLeft: 20,
        marginRight: 20,
        whiteSpace: "pre-wrap",
    },
    slogan: {
        ...Texts.basic(16, 500, "#ababab", 1.75),
        marginBottom: 64,
    },
    subTitle: (props) => ({
        ...Texts.basic(props.isMobile ? 16 : 18, "bold", "#2c2c2c", 1.5),
        marginBottom: 20,
    }),
    devTeamReferenceWrap: {
        marginTop: -24,
        borderRadius: 8,
    },
    devTeamReferenceTitle: {
        ...Texts.basic(14, 500, "#111111", 1.5),
        marginBottom: 8,
    },
    devTeamReference: {
        display: "inline-block",
        cursor: "pointer",
        ...Texts.basic(14, 500, "#787878", 1.79),
        "&:hover": {
            color: "#454545",
        },
    },
    devPosition: (props) => ({
        ...Texts.basic(props.isMobile ? 16 : 18, "bold", "#2c2c2c", 1.22),
        marginBottom: 20,
    }),
    devPositionTabWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: 20,
    },
    devPositionTabSelected: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 12,
        paddingRight: 12,
        borderRadius: 8,
        backgroundColor: "#3c6af5",
        ...Texts.basic(14, 500, "#ffffff", 1.71),
        marginRight: 8,
        marginBottom: 8,
        cursor: "pointer",
    },
    devPositionTab: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 12,
        paddingRight: 12,
        borderRadius: 8,
        backgroundColor: "#eeeeee",
        ...Texts.basic(14, 500, "#5f5f5f", 1.71),
        marginRight: 8,
        marginBottom: 8,
        cursor: "pointer",
    },
    devPositionInfoWrap: {
        padding: 24,
        borderRadius: 12,
        border: "solid 1px #dddddd",
        marginBottom: 65,
    },
    devPositionLevelWrap: {
        padding: 20,
        borderRadius: 8,
        backgroundColor: "#f7f8fa",
    },
    devPositionSkillStackWrap: {
        marginTop: 20,
        padding: 20,
        borderRadius: 8,
        backgroundColor: "#f7f8fa",
    },
    devPositionSkillStackTitle: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 18,
    },
    devPositionSkillStack: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap: "wrap",
    },
    devPositionSkill: {
        width: 76,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: "center",
        ...Texts.basic(12, "bold", "#5f5f5f", 1.75),
    },
    devPositionSkillIcon: {
        width: 50,
        height: 50,
        marginBottom: 8,
    },
    devTeam: (props) => ({
        ...Texts.basic(props.isMobile ? 16 : 18, "bold", "#2c2c2c", 1.22),
        marginBottom: 12,
    }),
    devTeamIntro: {
        ...Texts.basic(16, 500, "#5f5f5f", 1.75),
        marginBottom: 64,
        whiteSpace: "pre-wrap",
    },
    devTeamWrap: {
        padding: 20,
        borderRadius: 8,
        border: "solid 1px #eeeeee",
        marginBottom: 56,
    },
    devTeamHeadWrap: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 19,
    },
    devTeamImg: {},
    devTeamStructure: {
        marginLeft: 8,
        ...Texts.basic(14, "bold", "#111111", 1.57),
    },
    devTeamPositionWrap: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginBottom: 12,
    },
    devTeamPositionName: {
        flex: "3 0 90px",
        ...Texts.basic(14, 500, "#454545", 1.79),
    },
    devTeamPositionCount: (props) => ({
        flex: "1 0 30px",
        textAlign: props.isMobile ? "right" : "left",
        ...Texts.basic(14, "normal", "#454545", 1.79),
    }),
    devTeamPositionYearsBar: {
        flex: "8 0 240px",
        display: "flex",
        flexDirection: "row",
        borderRadius: 2,
        textAlign: "center",
        color: "#ffffff",
        ...Texts.basic(9, 500, "#ffffff", 2.33),
    },
    legendWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    updated: {
        ...Texts.basic(12, "normal", "#ababab", 1.75),
        flexShrink: 0,
    },
    experienceRangesWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    experienceRange: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 15,
        ...Texts.basic(12, "normal", "#787878", 1.75),
    },
    skillStack: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 12,
    },
    hardSkillsWrap: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 64,
        flexWrap: "wrap",
    },
    hardSkillBox: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: 8,
        borderRadius: 4,
        border: "solid 1px #eeeeee",
    },
    hardSkillIcon: { marginRight: 4 },
    hardSkill: {
        ...Texts.basic(12, "bold", "#111111", 1.67),
    },
    PositionReferenceWrap: {
        marginTop: 20,
        padding: 20,
        borderRadius: 8,
        backgroundColor: "#f7f8fa",
    },
    PositionReferenceTitle: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 18,
    },
    PositionReference: {
        display: "inline-block",
        cursor: "pointer",
        ...Texts.basic(14, 500, "#787878", 1.79),
        "&:hover": {
            color: "#454545",
        },
    },
    devStyleTitle: (props) => ({
        ...Texts.basic(props.isMobile ? 16 : 18, "bold", "#2c2c2c", 1.19),
        marginBottom: 20,
    }),
    devStyleWrap: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 56,
        flexWrap: "wrap",
    },
    devStyleBox: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: "6px 12px",
        borderRadius: 16,
        backgroundColor: "#f8f8f8",
        ...Texts.basic(12, 500, "#2c2c2c", 1.67),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0c46f2",
            color: "#ffffff",
        },
    },
    devStyleBoxWithoutDesc: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: "6px 12px",
        borderRadius: 16,
        backgroundColor: "#f8f8f8",
        ...Texts.basic(12, 500, "#2c2c2c", 1.67),
    },
    devStyle: {},
    popover: {},
    popoverRoot: {},
    popoverPaper: {
        //boxShadow: "none",
        border: "solid 1px #eeeeee",
    },
    devStyleDesc: {
        padding: 8,
        ...Texts.basic(12, "normal", "#2c2c2c", 1.79),
    },
    servicesWrap: { marginBottom: 40 },
    serviceWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        border: "solid 1px #eeeeee",
        borderRadius: 8,
        marginBottom: 20,
    },
    serviceInfoWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    serviceLinkWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
    },
    detail: {
        ...Texts.basic(14, "normal", "#787878", 1.79),
        marginRight: 4,
    },
    companyIntroTitle: (props) => ({
        marginTop: 40,
        marginBottom: 20,
        ...Texts.basic(props.isMobile ? 16 : 18, "bold", "#2c2c2c", 1.19),
    }),
    companyIntro: {
        ...Texts.basic(16, "normal", "#2c2c2c", 1.75),
        marginBottom: 56,
        whiteSpace: "pre-wrap",
    },
    perkBox: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 4,
        border: "solid 1px #eeeeee",
        backgroundColor: "#f8f8f8",
        padding: 12,
    },
    perkImg: {},
    perk: {
        marginLeft: 8,
        ...Texts.basic(14, 500, "#2c2c2c", 1.5),
    },
    disclaimer: {
        ...Texts.basic(12, "normal", "#c5c5c5", 1.5),
        marginTop: 40,
        marginBottom: 40,
    },
    rightSection: { flex: "1 0 300px", position: "relative" },
    actionCard: (props) => {
        return props.isMobile
            ? { backgroundColor: "#ffffff", marginBottom: 48 }
            : {
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  border: "solid 1px #eeeeee",
                  marginLeft: 20,
                  marginRight: 20,
                  top: 96,
                  position: "sticky",
              };
    },
    infoWrap: (props) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: props.isMobile ? 20 : 24,
    }),

    logo: {
        height: 40,
        width: 40,
        //borderRadius: "50%",
        border: "solid 1px #eeeeee",
        marginRight: 12,
    },
    name: {
        ...Texts.basic(14, "bold", "#111111", 1.57),
    },
    service: {
        ...Texts.basic(12, "normal", "#111111", 1.75),
    },
    ceo: {
        ...Texts.basic(12, "normal", "#ababab", 1.75),
    },
    hiringWrap: (props) => ({
        margin: props.isMobile ? 20 : 24,
    }),
    hiringTitle: {
        ...Texts.basic(14, "bold", "#111111", 1.57),
        marginBottom: 9,
    },
    positionBox: {
        borderRadius: 4,
        border: "solid 1px #eeeeee",
        backgroundColor: "#f8f8f8",
        textAlign: "center",
        marginBottom: 7,
        paddingTop: 8,
        paddingBottom: 8,
    },
    position: {
        ...Texts.basic(14, 500, "#111111", 1.75),
    },
    salary: {
        ...Texts.basic(14, 500, "#3c6af5", 1.71),
    },
    addressWrap: (props) => ({
        margin: props.isMobile ? 20 : 24,
    }),
    addressBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
    },
    addressTitle: {
        ...Texts.basic(14, "bold", "#111111", 1.57),
        //marginBottom: 9,
    },
    address: {
        ...Texts.basic(14, "normal", "#5f5f5f", 1.5),
        //marginBottom: 9,
    },
    mapBtn: {
        ...Texts.basic(14, 500, "#ababab", 1.5),
        textAlign: "right",
        cursor: "pointer",
        textDecoration: "underline",
        marginBottom: 20,
    },
    actionsFixedWrap: {
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    actionsWrap: (props) => ({
        //width: "100%",
        margin: props.isMobile ? 20 : 24,
    }),
    question: (props) => ({
        ...Texts.basic(14, "bold", "#111111", 1.57),
        marginBottom: 9,
        display: props.isMobile && "none",
    }),
    applyBtn: {
        backgroundColor: "#0c46f2",
        width: "100%",
        borderRadius: 4,
        padding: 11,
        textAlign: "center",
        cursor: "pointer",
    },
    btnSubTitle: {
        ...Texts.basic(12, "normal", "#ffffff", 1.75),
    },
    btnTitle: {
        ...Texts.basic(14, "bold", "#ffffff", 1.57),
    },
    companyInfoWrap: {
        marginTop: 48,
        marginBottom: 40,
    },
    companyInfoBox: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 8,
    },
    companyInfoTitle: {
        flex: "1 0 50px",
        ...Texts.basic(16, 500, "#5f5f5f", 1.75),
    },
    companyInfoContent: {
        flex: 11,
        ...Texts.basic(16, "normal", "#787878", 1.75),
    },
}));
