import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ladderImage from "assets/ladder.png";
import ladderStartImage from "assets/ladderStart.png";
import mobileFirstRailImage from "assets/mobileFirstRail.png";
import mobileSecondRailImage from "assets/mobileSecondRail.png";
import mobileThirdRailImage from "assets/mobileThirdRail.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            flexGrow: 1,
            textAlign: "center",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(20, 0),
            minWidth: "1280px",
        },
        mobileRoot: {
            flexGrow: 1,
            textAlign: "center",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(12, 0),
            // maxWidth: "360px",
        },
        title: {
            marginBottom: theme.spacing(5),
        },
        firstRail: {
            height: "340px",
            backgroundImage: `url(${ladderStartImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right",
            margin: "100px 0",
        },
        secondRail: {
            height: "340px",
            backgroundImage: `url(${ladderImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center left",
            margin: "100px 0",
        },
        mobileFirstRail: {
            height: "150px",
            backgroundImage: `url(${mobileFirstRailImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right",
            margin: "30px 0",
        },
        mobileSecondRail: {
            height: "150px",
            backgroundImage: `url(${mobileSecondRailImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            margin: "30px 0",
        },
        mobileThirdRail: {
            height: "150px",
            backgroundImage: `url(${mobileThirdRailImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center left",
            margin: "30px 0",
        },
        notice: {
            color: theme.palette.text.primary,
            margin: theme.spacing(6, 0, 0),
            textAlign: "left",
        },
        firstSteps: {
            display: "flex",
            height: "340px",
            width: "340px",

            backgroundColor: "#1a1a84",
            color: "#ffbbd4",
            borderRadius: "50%",
            "&:nth-child(even)": {
                backgroundColor: "#ffbbd4",
                color: "#1a1a84",
            },
            padding: theme.spacing(5, 0),
            [theme.breakpoints.down("xs")]: {
                height: "150px",
                width: "150px",
                padding: theme.spacing(1, 0),
            },
        },
        secondSteps: {
            display: "flex",
            height: "340px",
            width: "340px",

            backgroundColor: "#ffbbd4",
            color: "#1a1a84",
            borderRadius: "50%",
            "&:nth-child(even)": {
                backgroundColor: "#1a1a84",
                color: "#ffbbd4",
            },
            padding: theme.spacing(5, 0),
            [theme.breakpoints.down("xs")]: {
                height: "150px",
                width: "150px",
                padding: theme.spacing(1, 0),
            },
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                // padding: "0 calc((100% - 296px) / 2)",
                padding: "0 32px",
            },
        },
        subColumnItem: {
            flex: "0 0 auto",
        },
    };
});

export default function RecruitmentProcess(props) {
    const classes = useStyles();
    const { steps, notices } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return isMobile ? (
        <Box className={classes.mobileRoot}>
            <Container maxWidth="sm">
                <Typography
                    maxWidth="sm"
                    variant="h3"
                    component="h3"
                    className={classes.title}
                >
                    <b>공채 과정</b>
                </Typography>
            </Container>
            <Container
                maxWidth="xs"
                className={classes.mobileFirstRail}
                disableGutters
            >
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                    {steps.slice(0, 2).map((step) => (
                        <Grid
                            item
                            container
                            direction="column"
                            justify="space-evenly"
                            alignItems="center"
                            className={classes.firstSteps}
                        >
                            <Typography variant="caption">
                                {step.order}
                            </Typography>
                            <Typography variant="sutitle1">
                                <b>
                                    {step.title.split("\n").map((line, idx) => (
                                        <span key={idx.toString()}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                                </b>
                            </Typography>
                            <Typography variant="caption">
                                {step.period}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container
                maxWidth="xs"
                className={classes.mobileSecondRail}
                disableGutters
            >
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                    {steps.slice(2, 4).map((step) => (
                        <Grid
                            item
                            container
                            direction="column"
                            justify="space-evenly"
                            alignItems="center"
                            className={classes.firstSteps}
                        >
                            <Typography variant="caption">
                                {step.order}
                            </Typography>
                            <Typography variant="sutitle1">
                                <b>
                                    {step.title.split("\n").map((line, idx) => (
                                        <span key={idx.toString()}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                                </b>
                            </Typography>
                            <Typography variant="caption">
                                {step.period}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container
                maxWidth="xs"
                className={classes.mobileThirdRail}
                disableGutters
            >
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                    {steps.slice(4, 6).map((step) => (
                        <Grid
                            item
                            container
                            direction="column"
                            justify="space-evenly"
                            alignItems="center"
                            className={classes.firstSteps}
                        >
                            <Typography variant="caption">
                                {step.order}
                            </Typography>
                            <Typography variant="sutitle1">
                                <b>
                                    {step.title.split("\n").map((line, idx) => (
                                        <span key={idx.toString()}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                                </b>
                            </Typography>
                            <Typography variant="caption">
                                {step.period}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container maxWidth="sm" className={classes.container}>
                <Box className={classes.notice}>
                    <Typography variant="h6" gutterBottom>
                        <b>미리 알아두어야 할 내용</b>
                    </Typography>
                    {notices.map((notice) => (
                        <Typography key={notice.id} variant="body2">
                            {notice.content}
                        </Typography>
                    ))}
                </Box>
            </Container>
        </Box>
    ) : (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Typography
                    variant="h3"
                    component="h3"
                    className={classes.title}
                >
                    <b>공채 과정</b>
                </Typography>
            </Container>
            <Container maxWidth="xl" className={classes.firstRail}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        {steps.slice(0, 3).map((step, idx) => (
                            <Grid
                                key={idx.toString()}
                                item
                                container
                                direction="column"
                                justify="space-between"
                                alignItems="center"
                                className={classes.firstSteps}
                                style={{
                                    display: props.isLoaded ? "flex" : "block", // For microsoft edge
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.subColumnItem}
                                >
                                    <Typography>{step.order}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.subColumnItem}
                                >
                                    <Typography variant="h6">
                                        <b>
                                            {step.title
                                                .split("\n")
                                                .map((line, idx) => (
                                                    <span key={idx.toString()}>
                                                        {line}
                                                        <br />
                                                    </span>
                                                ))}
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.subColumnItem}
                                >
                                    <Typography>{step.period}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Container>
            <Container maxWidth="xl" className={classes.secondRail}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        {steps.slice(3).map((step, idx) => (
                            <Grid
                                key={idx.toString()}
                                item
                                container
                                direction="column"
                                justify="space-between"
                                alignItems="center"
                                className={classes.secondSteps}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.subColumnItem}
                                >
                                    <Typography>{step.order}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.subColumnItem}
                                >
                                    <Typography variant="h6">
                                        <b>
                                            {step.title
                                                .split("\n")
                                                .map((line, idx) => (
                                                    <span key={idx.toString()}>
                                                        {line}
                                                        <br />
                                                    </span>
                                                ))}
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.subColumnItem}
                                >
                                    <Typography>{step.period}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Container>

            <Container maxWidth="lg">
                <Box className={classes.notice}>
                    <Typography variant="h6" gutterBottom>
                        <b>미리 알아두어야 할 내용</b>
                    </Typography>
                    {notices.map((notice) => (
                        <Typography key={notice.id} variant="body2">
                            {notice.content}
                        </Typography>
                    ))}
                </Box>
            </Container>
        </Box>
    );
}
