import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
    Container,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    FormControl,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Modal,
    Select,
    MenuItem,
    IconButton,
    Chip,
} from "@material-ui/core";
import { ServiceHeader, ServiceHeaderD } from "pages/components";

import CloseIcon from "@material-ui/icons/Close";
import { TXImage } from "../../../components/tx";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
    onCompanyApply,
    putCCApplication,
} from "../../../apis/firestore/apply";

import "date-fns";
import NumberFormat from "react-number-format";
import { companyChannelData } from "data";

import { useStyles } from "./styleDevStarXCompanyApplication";
import DaumPostcode from "react-daum-postcode";

import { openScoutPositions, salaryData } from "../../../data";

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            //prefix="₩"
            suffix="원"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const DevStarXCompanyApplication = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const query = new URLSearchParams(window.location.search);
    const utmSource = query.get("utm_source") || query.get("source");
    const utmMedium = query.get("utm_medium") || query.get("media");
    const initialChannel = `${utmSource ? utmSource + " " : ""}${
        utmMedium ? utmMedium : ""
    }`;
    let initialUsers = [{ name: "", email: "", phone: "" }];
    const classes = useStyles({ isMobile });
    const [companyName, setCompanyName] = useState("");
    const [businessNumber, setBusinessNumber] = useState("");
    const [phone, setPhone] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [isOpenPost, setIsOpenPost] = useState(false);
    const [users, setUsers] = useState(initialUsers);
    const [numOfUsers, setNumOfUsers] = useState(1);
    const nextId = useRef(initialUsers.length);
    const [numOfEmployees, setNumOfEmployees] = useState("");
    const [numOfSWEngineers, setNumOfSWEngineers] = useState("");
    const [leader, setLeader] = useState("yes");
    const [revenue, setRevenue] = useState("");
    const [fund, setFund] = useState("");
    const [channel, setChannel] = useState(initialChannel);
    const [hiringPositions, setHiringPositions] = useState(
        openScoutPositions.map(() => false)
    );
    const [guarantee, setGuarantee] = useState(false);
    const [consent, setConsent] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const handleBusinessNumberChange = (event) => {
        setBusinessNumber(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    //Adress Input
    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    const AddressSearchButton = () => {
        return (
            <Button
                onClick={onChangeOpenPost}
                variant="contained"
                color="primary"
                disableElevation
                className={classes.searchButton}
            >
                주소검색
            </Button>
        );
    };

    const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddr += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddr +=
                    extraAddr !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
        }

        setPostalCode(data.zonecode);
        setAddress(fullAddr);
        setIsOpenPost(false);
    };

    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleAddressDetailChange = (event) => {
        setAddressDetail(event.target.value);
    };

    const handleUsersChange = (event) => {
        const { id, name, value } = event.target;
        let newUsers = users.slice(0);
        newUsers[Number(id)][name] = value;
        setUsers(newUsers);
    };

    const addUser = () => {
        let newUser = { name: "", email: "", phone: "" };
        setUsers((prevUsers) => prevUsers.concat(newUser));
        setNumOfUsers(++nextId.current);
    };

    const deleteUser = (idx) => {
        let newUsers = users.slice(0);
        newUsers.splice(idx, 1);
        setUsers(newUsers);

        setNumOfUsers(--nextId.current);
    };

    const handleNumOfEmployeesChange = (event) => {
        setNumOfEmployees(event.target.value);
    };

    const handleNumOfSWEngineersChange = (event) => {
        setNumOfSWEngineers(event.target.value);
    };

    const handleLeaderChange = (event) => {
        setLeader(event.target.value);
    };
    const handleRevenueChange = (event) => {
        setRevenue(event.target.value);
    };
    const handleFundChange = (event) => {
        setFund(event.target.value);
    };
    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    };
    const handleSubmit = () => {
        setIsFetching(true);
        const usersWithAdmin = users.slice(0).concat({
            name: "휴리",
            email: `${businessNumber}@talentx.co.kr`,
            phone: "01048547456",
        });
        const hiringPositionArray = hiringPositions.reduce(
            (previousValue, currentValue, currentIndex) => {
                if (currentValue)
                    return [
                        ...previousValue,
                        openScoutPositions[currentIndex].position,
                    ];
                else return previousValue;
            },
            []
        );

        const application = {
            companyName,
            businessNumber,
            phone: String(phone),
            zipCode: String(postalCode),
            address1: address,
            address2: addressDetail ?? null,
            users: usersWithAdmin,
            numOfEmployees: Number(numOfEmployees),
            numOfSWEngineers: Number(numOfSWEngineers),
            leader: leader === "yes",
            revenue: String(revenue),
            fund: String(fund),
            positions: hiringPositionArray,
            channel,
        };
        console.log(application);
        const result = onCompanyApply(application, setIsFetching, setOpen);
        if (result) putCCApplication(application, setIsFetching, setOpen);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        setIsFetching(true);
        setTimeout(() => props.history.push("/"), 1000);
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        //setSubmittable(true);
        setSubmittable(
            companyName &&
                businessNumber &&
                phone &&
                postalCode &&
                address &&
                users &&
                numOfEmployees &&
                numOfSWEngineers &&
                leader &&
                revenue &&
                fund &&
                channel &&
                hiringPositions.includes(true) &&
                guarantee &&
                consent
        );
    }, [
        companyName,
        businessNumber,
        phone,
        postalCode,
        address,
        users,
        numOfEmployees,
        numOfSWEngineers,
        leader,
        revenue,
        fund,
        channel,
        hiringPositions,
        guarantee,
        consent,
    ]);

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 기업 회원 신청</title>
            </Helmet>
            {isMobile ? <ServiceHeader /> : <ServiceHeaderD />}
            <Container maxWidth="sm" disableGutters>
                <Box className={classes.section}>
                    <Box className={classes.programTitleWrap}>
                        <Typography className={classes.programTitle}>
                            오픈스카우트 시즌2
                        </Typography>
                    </Box>
                    <Typography className={classes.pageTitle}>
                        데브스타X 기업 참가 신청
                    </Typography>
                    <Divider className={classes.thickDivider}></Divider>
                    <Typography className={classes.sectionTitle}>
                        진행 절차 안내
                    </Typography>

                    <Box className={classes.stepList}>
                        <Typography className={classes.stepTitle}>
                            STEP 1
                        </Typography>
                        <Typography className={classes.stepContent}>
                            신청서 작성
                        </Typography>
                    </Box>
                    <Box className={classes.stepList}>
                        <Typography className={classes.stepTitle}>
                            STEP 2
                        </Typography>
                        <Typography className={classes.stepContent}>
                            참가대상 기업 여부 심사
                        </Typography>
                    </Box>
                    <Box className={classes.stepList}>
                        <Typography className={classes.stepTitle}>
                            STEP 3
                        </Typography>
                        <Typography className={classes.stepContent}>
                            온라인 계약 및 보증금 납입
                            <Typography className={classes.stepNotice}>
                                제공 서비스와 가격정책에 대한 계약을 온라인으로
                                체결한 후 인재DB 보증금 1천만원('22년 3월 기준,
                                서비스 해지 시 100% 환급)을 납입합니다.
                            </Typography>
                        </Typography>
                    </Box>
                    <Box className={classes.stepList}>
                        <Typography className={classes.stepTitle}>
                            STEP 4
                        </Typography>
                        <Typography className={classes.stepContent}>
                            앱 회원가입 및 기업 프로필 작성
                        </Typography>
                    </Box>

                    <Box className={classes.eventBox}>
                        <Chip label="특별 혜택" className={classes.eventChip}>
                            {" "}
                        </Chip>
                        <Typography className={classes.eventTitle}>
                            데브스타X 참가 개발자들의 선호도가 높은 기업인 경우
                            <Typography className={classes.eventTitleBlue}>
                                {" "}
                                보증금 1천만원 전액이 면제{" "}
                            </Typography>
                            됩니다.
                        </Typography>
                        <Typography className={classes.eventGuide}>
                            귀사가 보증금 면제 대상 기업인지 확인하고 싶으시면{" "}
                            <Typography
                                display="inline"
                                className={classes.eventGuideLink}
                                onClick={() =>
                                    window.open("https://talentx.channel.io")
                                }
                            >
                                문의하기
                            </Typography>
                            를 이용해주세요.
                        </Typography>
                    </Box>
                </Box>

                {/* <Divider className={classes.sectionDivider} light></Divider> */}

                <Box className={classes.body}>
                    <Divider className={classes.darkDevider} />
                    <Typography className={classes.sectionTitle}>
                        신청서 작성
                    </Typography>
                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography className={classes.inputTitle}>
                            기업명
                        </Typography>
                        <TextField
                            required
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="기업명을 입력해주세요."
                            //label="이름"
                            value={companyName}
                            onChange={handleCompanyNameChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            사업자 등록번호
                        </Typography>
                        <TextField
                            required
                            inputProps={{ maxLength: 10 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="사업자 등록번호를 입력해주세요.(-없이 10자리)"
                            //label="이름"
                            value={businessNumber}
                            onChange={handleBusinessNumberChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            기업 전화번호
                        </Typography>
                        <TextField
                            required
                            inputProps={{ maxLength: 14 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="기업 전화번호를 입력해주세요.(-없이 번호만)"
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                        <Box
                            style={{ marginTop: 8, marginBottom: 8 }}
                            className={classes.addressTitleWrap}
                        >
                            <Typography className={classes.inputTitle}>
                                기업 주소 (채용 시 근무지)
                            </Typography>
                            {isOpenPost ? (
                                <IconButton
                                    className={classes.closeBtn}
                                    aria-label="close"
                                    onClick={onChangeOpenPost}
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : (
                                <Box />
                            )}
                        </Box>
                        {isOpenPost ? (
                            <DaumPostcode
                                style={{
                                    display: "block",
                                    position: "relative",
                                    top: "50%",
                                    width: "100%",
                                    height: "500px",
                                    //padding: "7px",
                                }}
                                autoClose
                                onComplete={onCompletePost}
                            />
                        ) : (
                            <>
                                <TextField
                                    required
                                    style={{ marginBottom: 0 }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        classes: {
                                            root: classes.resize2,
                                            input: classes.resize,
                                        },
                                        endAdornment: <AddressSearchButton />,
                                    }}
                                    id="standard-basic"
                                    variant="outlined"
                                    placeholder="우편번호"
                                    value={postalCode}
                                    onChange={handlePostalCodeChange}
                                />
                                <TextField
                                    required
                                    style={{ marginBottom: 0 }}
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    id="standard-basic"
                                    variant="outlined"
                                    placeholder="기본 주소"
                                    value={address}
                                    onChange={handleAddressChange}
                                />
                                <TextField
                                    required
                                    style={{ marginBottom: 32 }}
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    id="standard-basic"
                                    variant="outlined"
                                    placeholder="상세 주소"
                                    value={addressDetail}
                                    onChange={handleAddressDetailChange}
                                />
                            </>
                        )}
                        <Typography className={classes.inputTitle}>
                            채용 담당자
                        </Typography>
                        <Typography className={classes.inputNotices}>
                            - 기업 도메인으로 된 이메일로만 가입 가능
                            <br />- 기업당 최대 3명까지 가입 가능
                        </Typography>
                        {users.map((user, idx) => {
                            return (
                                <Box key={idx} className={classes.userBox}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>담당자 정보</Typography>
                                        {idx > 0 && (
                                            <CloseIcon
                                                style={{ color: "#bbbbbb" }}
                                                onClick={() => deleteUser(idx)}
                                            />
                                        )}
                                    </Box>
                                    <TextField
                                        required
                                        id={String(idx)}
                                        name="name"
                                        variant="outlined"
                                        inputProps={{ maxLength: 10 }}
                                        InputProps={{
                                            classes: { input: classes.resize },
                                        }}
                                        placeholder="담당자 이름"
                                        value={users[idx].name}
                                        onChange={handleUsersChange}
                                    />
                                    <TextField
                                        required
                                        id={String(idx)}
                                        name="email"
                                        variant="outlined"
                                        InputProps={{
                                            classes: { input: classes.resize },
                                        }}
                                        placeholder="사용 이메일"
                                        value={users[idx].email}
                                        onChange={handleUsersChange}
                                    />
                                    <TextField
                                        required
                                        id={String(idx)}
                                        name="phone"
                                        variant="outlined"
                                        inputProps={{ maxLength: 11 }}
                                        InputProps={{
                                            classes: { input: classes.resize },
                                        }}
                                        placeholder="휴대폰번호(-없이 최대 11자리)"
                                        value={users[idx].phone}
                                        onChange={handleUsersChange}
                                    />
                                </Box>
                            );
                        })}

                        <Button
                            variant="contained"
                            //color="primary"
                            onClick={addUser}
                            style={{
                                boxShadow: "none",
                                height: 56,
                                backgroundColor: "#eeeeee",
                                fontWeight: "bold",
                            }}
                            disabled={numOfUsers === 3}
                        >
                            <img src={require("assets/icon-add.png")} />{" "}
                            추가하기
                        </Button>
                        <Divider />
                        <Typography className={classes.inputTitle}>
                            전체 사원 수
                        </Typography>
                        <TextField
                            required
                            inputProps={{ min: "0", maxLength: 5 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="전체 사원 수를 입력해주세요.(단위 : 명)"
                            //label="이름"
                            type="number"
                            value={numOfEmployees}
                            onChange={handleNumOfEmployeesChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            개발팀 인원
                        </Typography>
                        <TextField
                            required
                            inputProps={{ min: "0" }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="개발팀 인원을 입력해주세요.(단위 : 명)"
                            //label="이름"
                            type="number"
                            value={numOfSWEngineers}
                            onChange={handleNumOfSWEngineersChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            현재 CTO 또는, 개발팀 리더가 있나요?
                        </Typography>
                        <FormControl component="fieldset">
                            {/* <FormLabel component="legend">
                                labelPlacement
                            </FormLabel> */}
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={leader}
                                defaultValue="yes"
                                onChange={handleLeaderChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="있다"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="없다"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                        <Divider />

                        <Typography className={classes.inputTitle}>
                            매출액
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                전년도 매출액 (단위 : 원)
                            </span>
                        </Typography>
                        <TextField
                            required
                            name="numberformat"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                classes: { input: classes.resize },
                            }}
                            inputProps={{ maxLength: 21 }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="숫자로만 입력해주세요.(단위 : 원)"
                            //label="이름"
                            //type="number"
                            value={revenue}
                            onChange={handleRevenueChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            투자액
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                누적 투자유치액 (단위 : 원)
                            </span>
                        </Typography>
                        <TextField
                            required
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                classes: { input: classes.resize },
                            }}
                            inputProps={{ maxLength: 21 }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="숫자로만 입력해주세요.(단위 : 원)"
                            //label="이름"
                            //type="number"
                            value={fund}
                            onChange={handleFundChange}
                            name="numberformat"

                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            저희를 어떤 경로로 알게 되셨나요?
                        </Typography>
                        <FormControl variant="outlined">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={channel}
                                onChange={handleChannelChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    value={initialChannel}
                                    classes={{ root: classes.resize }}
                                    disabled={!initialChannel}
                                >
                                    {initialChannel || "선택해주세요."}
                                </MenuItem>
                                {companyChannelData.map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        value={item}
                                        classes={{ root: classes.resize }}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <FormHelperText>
                                SW개발 경력만 계산해서 선택해 주세요.
                            </FormHelperText> */}
                        </FormControl>
                        <Typography className={classes.inputTitle}>
                            채용 희망 직무
                        </Typography>
                        <Typography className={classes.inputGuide}>
                            데브스타X 프로그램을 통해 채용하고자 하는 직무를
                            모두 선택해주세요
                        </Typography>
                        <FormControl>
                            {openScoutPositions.map((item, idx) => (
                                <Box
                                    key={idx}
                                    className={
                                        hiringPositions[idx]
                                            ? classes.positionBoxChecked
                                            : classes.positionBox
                                    }
                                    onClick={() => {
                                        setHiringPositions((prev) => {
                                            const prevDeepCopy = [...prev];
                                            prevDeepCopy[idx] =
                                                !prevDeepCopy[idx];
                                            return prevDeepCopy;
                                        });
                                    }}
                                >
                                    <TXImage
                                        imgName={
                                            hiringPositions[idx]
                                                ? "icon-check-circle-24-dp-active"
                                                : "icon-check-circle-24-dp-inactive"
                                        }
                                    />
                                    <Typography
                                        className={classes.positionName}
                                    >
                                        {item.domain} : {item.position}
                                    </Typography>
                                </Box>
                            ))}
                        </FormControl>
                    </form>

                    <Backdrop className={classes.backdrop} open={isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        className={classes.modalScreen}
                    >
                        <Box className={classes.modalBox}>
                            <Typography className={classes.modalTitle}>
                                데브스타X 신청완료
                            </Typography>
                            <Typography className={classes.modalMessage}>
                                신청해주셔서 감사합니다!
                                <br />
                                담당자 확인 후 가입 조건 충족 시
                                <br />
                                가격 정책을 안내해 드리겠습니다.
                            </Typography>
                            <Typography className={classes.modalInfo}>
                                영업일 기준 1 - 3일 소요됨
                            </Typography>
                            <Button
                                onClick={handleOk}
                                variant="contained"
                                color="primary"
                                className={classes.modalButton}
                            >
                                확인
                            </Button>
                        </Box>
                    </Modal>
                </Box>

                <Box className={classes.foot}>
                    <Divider className={classes.darkDevider} />
                    <Typography className={classes.consentTitle}>
                        최소 연봉 보장 조건 동의
                    </Typography>
                    <Typography className={classes.consentNotice}>
                        경력별 최소 연봉 정책에 동의해 주셔야 참가가 가능합니다.
                    </Typography>
                    <Box className={classes.table}>
                        <Box className={classes.tableRow}>
                            <Box className={classes.tableLeftTopCol}>
                                <Typography className={classes.tableTopColText}>
                                    경력 및 직무
                                </Typography>
                            </Box>
                            <Box className={classes.tableRightTopCol}>
                                <Typography className={classes.tableTopColText}>
                                    최소 연봉
                                </Typography>
                            </Box>
                        </Box>
                        {salaryData.map((item, idx) => (
                            <Box key={idx} className={classes.tableRow}>
                                <Box className={classes.tableLeftCol}>
                                    <Typography
                                        className={classes.tableLeftColText}
                                    >
                                        {item.category}
                                    </Typography>
                                </Box>
                                <Box className={classes.tableRightCol}>
                                    <Typography
                                        className={classes.tableRightColText}
                                    >
                                        {item.minSalary}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Typography className={classes.noticeCaution}>
                        본 프로그램 기간 동안 추천 받은 개발자를 채용할 시 위의
                        최소 조건을 필수로 지키셔야 합니다. 만일, 이를 어길 시
                        추후 채용을 진행함에 있어 귀사에 불이익이 발생할 수
                        있습니다.
                    </Typography>
                    <Box
                        className={classes.consentBtn}
                        onClick={() => {
                            setGuarantee((prev) => !prev);
                        }}
                    >
                        {guarantee ? (
                            <img
                                src={require("assets/icon-toggle-checkbox-checked-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-checked-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-checked-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        ) : (
                            <img
                                src={require("assets/icon-toggle-checkbox-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        )}
                        <Typography className={classes.consent}>
                            모든 내용을 확인하였고, 이에 동의합니다.
                        </Typography>
                    </Box>
                    <Divider className={classes.darkDevider} />
                    <Typography className={classes.consentTitle}>
                        기업 및 개인정보 수집·이용 동의
                    </Typography>
                    <Typography className={classes.consentNotice}>
                        (주)탤런티넘은 서비스 제공을 위한 기업 및 개인정보를
                        수집·이용합니다.
                    </Typography>
                    <Box className={classes.noticeBox}>
                        <Typography className={classes.noticeTitle}>
                            1) 기업 및 개인정보 수집 목적
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            가입 심사 및 확인
                        </Typography>
                        <Typography className={classes.noticeTitle}>
                            2) 기업 및 개인정보 수집 항목
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            기업명, 사업자등록번호, 채용담당자
                            이름/이메일/연락처, 사내인력현황, 매출액, 투자액,
                            가입신청경로, 채용직무
                        </Typography>

                        <Typography className={classes.noticeCaption}>
                            &#8226; 수집 목적이 달성된 이후 개인정보는 즉시
                            파기합니다.
                        </Typography>
                    </Box>
                    <Box
                        className={classes.consentBtn}
                        onClick={() => {
                            setConsent((prev) => !prev);
                        }}
                    >
                        {consent ? (
                            <img
                                src={require("assets/icon-toggle-checkbox-checked-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-checked-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-checked-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        ) : (
                            <img
                                src={require("assets/icon-toggle-checkbox-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        )}
                        <Typography className={classes.consent}>
                            기업 및 개인정보 수집·이용에 동의합니다
                        </Typography>
                    </Box>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!submittable}
                        className={classes.submitButton}
                    >
                        신청하기
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default DevStarXCompanyApplication;
