import React from "react";
import {
    AppBar,
    makeStyles,
    Toolbar,
    Typography,
    IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        display: "flex",
        minHeight: 230,
        backgroundColor: theme.palette.primary,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(2),
    },
    toolbarTop: {
        justifyContent: "space-between",
    },
    backBtn: {
        width: 25,
        height: 20,
    },
    dualLogo: {
        height: 20,
        [theme.breakpoints.down("xs")]: {
            height: 0,
        },
    },
    titleWrap: {
        alignItems: "flex-end",
        flex: 1,
    },
    title: {
        fontFamily: "GmarketSans-Bold",
        fontSize: 24,
    },
    titleSub: {
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
    },
}));

function Header(props) {
    const { onBackPress } = props;

    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbarTop}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onBackPress}
                >
                    <img
                        src={require("assets/icon-back.png")}
                        alt=""
                        className={classes.backBtn}
                    />
                </IconButton>

                <img
                    src={require("assets/logo-dual-grey.png")}
                    alt=""
                    className={classes.dualLogo}
                />
            </Toolbar>

            <Toolbar className={classes.titleWrap}>
                <Typography
                    variant="h4"
                    className={classes.title}
                    color="secondary"
                    noWrap
                >
                    {"COMPANY LIST\n"}
                    <Typography
                        variant="body1"
                        color="secondary"
                        noWrap
                        className={classes.titleSub}
                    >
                        참여기업 목록
                    </Typography>
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
