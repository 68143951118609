import { makeStyles } from "@material-ui/core";
import { Texts, Colors } from "../../styles";
import { blue } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 106,
        marginBottom: 26,
    },
    title: {
        marginLeft: 8,
        ...Texts.basic(24, "bold", Colors.Gray900, 1.21),
    },
    guideWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 30,
        marginBottom: 8,
    },
    guide: {
        marginLeft: 8,
        ...Texts.basic(16, "bold", Colors.Blue400, 1.19),
    },
    subGuide: {
        marginLeft: 32,
        ...Texts.basic(14, 500, Colors.Gray600, 1.71),
    },
    photoWrap: {
        marginTop: 32,
        marginBottom: 12,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: 9,
    },
    firstPhotoFrame: {
        position: "relative",
        width: 178,
        height: 178,
        borderRadius: 8,
        backgroundColor: Colors.Gray50,
        border: `2px solid ${Colors.Blue400}`,
    },
    firstPhotoBadge: {
        position: "absolute",
        top: 0,
        left: 0,
        borderTopLeftRadius: 1,
        borderBottomRightRadius: 6,
        backgroundColor: Colors.Blue400,
        padding: "1px 8px 2px 7px",
    },
    firstPhotoBadgeTitle: {
        ...Texts.basic(12, 500, Colors.White, 1.75),
    },
    tip: {
        marginBottom: 24,
        ...Texts.basic(14, 500, Colors.Gray400, 1.71),
    },
    photoFrame: {
        position: "relative",
        width: 178,
        height: 178,
        borderRadius: 8,
        backgroundColor: Colors.Gray50,
        border: `1px solid ${Colors.Gray100}`,
    },
    photoDeleteBtn: {
        position: "absolute",
        top: 10,
        right: 10,
        width: 20,
        height: 20,
        borderRadius: "50%",
        padding: 4,
        backgroundColor: Colors.White,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    photoDeleteBtnIcon: {
        width: 16,
        height: 16,
    },
    btnText: {
        marginLeft: 8,
        ...Texts.basic(16, 500, Colors.Gray700, 1.75),
    },
    photo: {
        width: "100%",
        height: "100%",
        borderRadius: 6,
        objectFit: "cover",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        //color: "#fff",
    },
    photoEditPopup: {
        maxWidth: 500,
        maxHeight: "80vh",
    },
    photoEditPopupHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 24px",
        backgroundColor: Colors.Gray50,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
    },
    photoEditPopupHeaderTitle: {
        ...Texts.basic(16, "bold", Colors.Gray900, 1.19),
    },
    photoEditPopupHeaderIconBtn: {
        cursor: "pointer",
    },
    photoEditPopupBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        padding: 24,
        backgroundColor: Colors.White,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
    },
    photoEditPopupBodyFrame: {},
    photoEditPopupBodyBtn: {
        marginTop: 32,
    },
}));
