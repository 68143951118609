import { useRef, useCallback, useEffect } from "react";

export const useObserver = (setIndex) => {
    const dom = useRef();

    const handleScroll = useCallback(
        ([entry]) => {
            const { current } = dom;

            if (entry.isIntersecting) {
                console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@");
                setIndex(String(current.id));
            }
        },
        [setIndex]
    );

    useEffect(() => {
        let observer;
        //const { current } = dom;
        if (dom.current) {
            observer = new IntersectionObserver(handleScroll, {
                threshold: 0.1,
            });
            observer.observe(dom.current);

            return () => observer && observer.disconnect();
        }
    }, [handleScroll]);

    return {
        ref: dom,
    };
};
