import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ebBtnBox: {
        //padding: "15px 16px 14px 24px",
        borderRadius: 20,
        backgroundColor: "#242427",
        boxShadow: "0 4px 10px #00000055",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "fixed",
        zIndex: 3,
        right: 16,
        bottom: 76,
    },
    ebBtnBoxTxt: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.22,
        letterSpacing: -0.6,
        color: "#1eb2ff",
    },
    ebBtn: {
        //marginLeft: 20,
        padding: "11px 12px 10px",
        textAlign: "center",
        borderRadius: 8,
        backgroundColor: "#313135",
        cursor: "pointer",
    },
    ebBtnTxt: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.36,
        letterSpacing: -0.6,
        color: "#ffffff",
    },
    ebPopup: {
        width: "90%",
        padding: 16,
        borderRadius: 20,
        background: "radial-gradient(#414851, #393b43, #393b43)",
        boxShadow: "0 4px 20px #00000055",
        position: "fixed",
        zIndex: 3,
        left: "50%",
        transform: "translateX(-50%)",
        top: 110,
    },
    ebPopupTitle: {
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.21,
        letterSpacing: -0.6,
        textAlign: "center",
        color: "#f1faff",
        marginTop: 12,
        marginBottom: 24,
        whiteSpace: "pre-wrap",
    },
    ebPopupBox1: {
        padding: 20,
        borderRadius: 12,
        //boxShadow: "0px 2px 16px 0px",
        border: "solid 1px #27c9ff",
        backgroundColor: "#464c56",
        marginBottom: 12,
    },
    ebPopupBox2: {
        padding: 20,
        borderRadius: 12,
        //boxShadow: "0px 2px 16px 0px",
        border: "solid 1px #21f0ff",
        backgroundColor: "#464c56",
        marginBottom: 12,
    },
    ebPopupBox3: {
        padding: 20,
        borderRadius: 12,
        backgroundColor: "#313136",
        marginBottom: 20,
    },
    ebPopupBoxTxt1: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#c7eaff",
        marginBottom: 12,
    },
    ebPopupBoxTxt2_1: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#27c9ff",
        marginBottom: 8,
    },
    ebPopupBoxTxt2_2: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#21f0ff",
        marginBottom: 8,
    },
    ebPopupBoxTxt3: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#93a5ae",
    },
    ebPopupBoxTxt4: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#ffffff",
        marginRight: 4,
    },
    ebPopupOffBtnWrapper: {
        display: "flex",
        // flexDirection: "row-reverse",
        justifyContent: "center",
        alignItems: "center",
    },
    ebPopupOffBtn: {
        padding: "8px 20px",
        borderRadius: 8,
        // backgroundColor: "#313135",
        cursor: "pointer",
    },
    ebPopupOffBtnTxt: {
        fontSize: 13,
        fontWeight: 400,
        lineHeight: 1.19,
        letterSpacing: -0.6,
        textAlign: "center",
        color: "#ffffff",
    },
}));

const data = {
    talent: {
        title: "탤런트엑서만의 특별한 혜택!",
        benefits: [
            {
                title: "탤런트엑스 포인트",
                desc: "회원 가입 시 네이버 포인트 1K+",
                extra: "믿고 맡겨주신 경력정보에 대한 공정 사용료를\n매월 말에 네이버 포인트로 드립니다.",
            },
            {
                title: "탤런트엑스 로열티",
                desc: "이직 성공 시 로열티 50만원",
                extra: "탤런트엑스를 애용해 주신 것에 대한 사례금을\n입사 후 3개월에 현금으로 드립니다.",
            },
        ],
    },
    company: {},
};

const PopupD = () => {
    const classes = useStyles();
    const [popUpOpen, setPopUpOpen] = useState(false);
    const popUpOpenChangeHandler = () => {
        setPopUpOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            {popUpOpen ? (
                <Box className={classes.ebPopup}>
                    <Typography className={classes.ebPopupTitle}>
                        {data.talent.title}
                    </Typography>

                    <Box className={classes.ebPopupBox1}>
                        <Typography className={classes.ebPopupBoxTxt1}>
                            {data.talent.benefits[0].title}
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt2_1}>
                            {data.talent.benefits[0].desc}
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt3}>
                            {data.talent.benefits[0].extra}
                        </Typography>
                    </Box>

                    <Box className={classes.ebPopupBox2}>
                        <Typography className={classes.ebPopupBoxTxt1}>
                            {data.talent.benefits[1].title}
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt2_2}>
                            {data.talent.benefits[1].desc}
                        </Typography>
                        <Typography className={classes.ebPopupBoxTxt3}>
                            {data.talent.benefits[1].extra}
                        </Typography>
                    </Box>
                    <Box className={classes.ebPopupOffBtnWrapper}>
                        <Box
                            className={classes.ebPopupOffBtn}
                            onClick={popUpOpenChangeHandler}
                        >
                            <Typography className={classes.ebPopupOffBtnTxt}>
                                닫기
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box className={classes.ebBtnBox}>
                    {/* <Typography className={classes.ebBtnBoxTxt}>
                        탤런트엑서 특별혜택
                    </Typography> */}
                    <Box
                        className={classes.ebBtn}
                        onClick={popUpOpenChangeHandler}
                    >
                        {/* <Typography className={classes.ebBtnTxt}>
                            확인하기
                        </Typography> */}
                        <Typography className={classes.ebBtnBoxTxt}>
                            탤런트엑서 특별혜택
                        </Typography>
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
};

export default PopupD;
