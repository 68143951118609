import { makeStyles } from "@material-ui/core";
import { Texts, Colors } from "styles";

export const useStyles = makeStyles((theme) => ({
    ebPopup: {
        width: "90%",
        padding: 20,
        borderRadius: 20,
        backgroundColor: "#242427",
        boxShadow: "0 4px 20px 0",
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        top: 80,
    },
    ebPopupTitle: {
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.21,
        letterSpacing: -0.6,
        color: "#ffffff",
        marginBottom: 20,
    },
    ebPopupBox1: {
        padding: 20,
        borderRadius: 12,
        boxShadow: "0px 2px 16px 0px",
        border: "solid 1px #27c9ff",
        backgroundColor: "#313136",
        marginBottom: 12,
    },
    ebPopupBox2: {
        padding: 20,
        borderRadius: 12,
        boxShadow: "0px 2px 16px 0px",
        border: "solid 1px #21f0ff",
        backgroundColor: "#313136",
        marginBottom: 12,
    },
    ebPopupBox3: {
        padding: 20,
        borderRadius: 12,
        backgroundColor: "#313136",
        marginBottom: 20,
    },
    ebPopupBoxTxt1: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#c5c5c5",
        marginBottom: 12,
    },
    ebPopupBoxTxt2_1: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#27c9ff",
        marginBottom: 4,
    },
    ebPopupBoxTxt2_2: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#21f0ff",
        marginBottom: 4,
    },
    ebPopupBoxTxt3: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#5f5f5f",
    },
    ebPopupBoxTxt4: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
        color: "#ffffff",
        marginRight: 4,
    },
    ebPopupOffBtnWrapper: {
        display: "flex",
        flexDirection: "row-reverse",
        //justifyContent: "flex-start",
        //alignItems: "center",
    },
    ebPopupOffBtn: {
        padding: "16px 20px 15px",
        borderRadius: 8,
        backgroundColor: "#313135",
        cursor: "pointer",
    },
    ebPopupOffBtnTxt: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.19,
        letterSpacing: -0.6,
        textAlign: "center",
        color: "#ffffff",
    },
    container: { position: "relative" },
    section1: {
        marginTop: 95,
        width: "100%",
        height: "171vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-1.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-1.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-1@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-1@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section2: {
        width: "100%",
        height: "177vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-2.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-2.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-2@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-2@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section3: {
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 32,
        backgroundColor: "#010101",
    },
    pointToScroll: {
        height: 100,
    },
    sectionTitle: {
        marginBottom: 24,
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#ffffff",
    },
    totalOfDevstarx: {
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#ffffff",
        display: "inline-block",
    },
    contentInfo: {
        marginBottom: 10,
        fontSize: 18,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#929292",
    },
    contentAdditInfo: {
        marginLeft: 4,
        //marginBottom: 2,
        fontSize: 14,
        lineHeight: 1.7,
        letterSpacing: -0.6,
        color: "#929292",
    },
    displayInline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginBottom: 38,
    },
    profileCard: {
        width: "100%",
        height: "100%",
        borderRadius: 20,
        backgroundColor: "#1f1f1f",
        padding: 24,
        display: "flex",
        flexDirection: "column",
    },
    ProfileCardHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 12,
    },
    nickName: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#ffffff",
        flexGrow: 1,
    },
    participationLabel: {
        fontSize: 13,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#454545",
    },
    participationNumber: {
        marginLeft: 4,
        marginRight: 4,
        fontSize: 13,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#787878",
    },
    careerSection: {
        width: "100%",
        borderRadius: 8,
        backgroundColor: "#111111",
        padding: "13px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 12,
    },
    hopePosition: {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#ffffff",
    },
    hopePositionYearsOfExp: {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#3c6af5",
        marginLeft: 6,
    },
    totalYearsOfExp: {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#787878",
        marginLeft: 6,
    },
    itemHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    itmeLabel: {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#929292",
    },
    numOfitem: {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: -0.6,
        color: "#c5c5c5",
        marginLeft: 12,
    },
    skill: {
        fontSize: 16,
        lineHeight: 1.75,
        letterSpacing: -0.6,
        color: "#ffffff",
        marginTop: 4,
        marginBottom: 12,
        flexGrow: 1,
    },
    brightDivider: {
        backgroundColor: "#454545",
        marginBottom: 12,
    },
    section4: {
        width: "100%",
        height: "129vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-4.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-4.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-4@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-4@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },

    section5: {
        width: "100%",
        height: "153vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-5.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-5.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-5@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-5@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section6: {
        width: "100%",
        height: "274vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-6.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-6.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-6@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-6@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section7: {
        width: "100%",
        height: "106vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-7.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-7.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-7@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-7@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section8: {
        width: "100%",
        height: "263vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-8.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-8.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-8@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-8@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section9: {
        width: "100%",
        height: "74vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-9.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-9.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-9@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-9@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    section10: {
        width: "100%",
        height: "277vw",
        backgroundImage: `url(${require("assets/bgi-openscout-devstarX-10.png")})`,
        backgroundImage: `image-set(url(${require("assets/bgi-openscout-devstarX-10.png")}) 1x, url(${require("assets/bgi-openscout-devstarX-10@2x.png")}) 2x, url(${require("assets/bgi-openscout-devstarX-10@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    moreBtn: {
        marginTop: 16,
        height: 48,
        border: 1,
        borderRadius: 12,
        backgroundColor: "#1f1f1f",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    moreBtnText: {
        fontSize: 14,
        fontWeight: "Bold",
        lineHeight: 1.33,
        letterSpacing: -0.6,
        color: "#ffffff",
    },
    moreBtnIcon: {
        fontSize: 20,
        fontWeight: "Bold",
        color: "#ffffff",
        marginLeft: 4,
    },
    applyBtnWrapper: {
        height: 64,
        padding: 8,
        backgroundColor: "#010101",
        position: "sticky",
        bottom: 0,
    },
    applyBtn: {
        height: 48,
        borderRadius: 8,
        backgroundColor: "#0c46f2",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    applyBtnText: {
        fontSize: 14,
        fontWeight: "Bold",
        lineHeight: 1.33,
        letterSpacing: -0.6,
        color: "#ffffff",
    },
    companyCard: {
        width: "100%",
        height: "100%",
        background:
            "linear-gradient(321deg,#aaaaaa,#bbbbbb, #cccccc,#dddddd,#e2e2e2,#e0e0e0, #eeeeee)",
        borderRadius: 20,
        padding: 8,
        opacity: 1,
        display: "flex",
        flexDirection: "column",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    companyCardContent: {
        flexGrow: 1,
    },
    tag: {
        ...Texts.basic(14, "normal", "#777777", 1.2),
    },
    company: {
        ...Texts.basic(20, "bold", "#000000", 1.2),
    },
    positionWrap: {
        marginTop: 12,
        backgroundColor: "#F7F5F2",
        borderRadius: 8,
        padding: "13px 16px",
    },
    pos: {
        ...Texts.basic(14, 500, "#000000", 1.38),
    },
    cardBtn: {
        color: "#000000",
    },
    fixedButton: {
        backgroundColor: "#4d4d4d",
        textAlign: "center",
        position: "fixed",
        zIndex: 1,
        bottom: 0,
        width: "100%",
        height: 60,
        left: "50%",
        transform: "translate(-50%, 0)",
        paddingTop: 12,
        paddingBottom: 12,
    },
    fixedButtonName: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.43,
    },
    fixedButtonDesc: {
        color: "#ffffff",
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.43,
    },
    phantom: {
        width: "100%",
        height: 50,
    },
}));
