export const companyData = [
    {
        name: "신세계아이앤씨",
        img: require("assets/company-logo/logo-신세계.png"),
        link: "http://www.shinsegae-inc.com",
        goTo: "https://tyle.io/explore/s9zw45ofbq7pe6",
        hopePositions: [
            "머신러닝 엔지니어",
            "데이터 엔지니어",
            "클라우드 엔지니어",
        ],
        mainFeaturing: false,
    },
    {
        name: "피트",
        img: require("assets/company-logo/logo-피트.png"),
        link: "https://www.fitt.kr",
        goTo: "https://tyle.io/explore/yvynq5ofc4jq36",
        hopePositions: [
            "풀스택 엔지니어",
            "안드로이드 엔지니어",
            "iOS 엔지니어",
        ],
        mainFeaturing: true,
        isHot: true,
    },
    {
        name: "네오위즈",
        img: require("assets/company-logo/logo-네오위즈.png"),
        link: "https://www.neowiz.com/neowiz/",
        goTo: "https://tyle.io/explore/tb0jz5og2a4r3d",
        hopePositions: ["백엔드 엔지니어"],
        mainFeaturing: false,
    },
    {
        name: "스푼라디오",
        img: require("assets/company-logo/logo-스푼라디오.png"),
        link: "https://company.spoonradio.co/kr/",
        goTo: "https://tyle.io/explore/x0gjr5offncohb",
        hopePositions: ["iOS 엔지니어"],
        mainFeaturing: false,
    },
    {
        name: "마인즈랩",
        img: require("assets/company-logo/logo-마인즈랩.png"),
        link: "https://maum.ai",
        goTo: "https://tyle.io/explore/tpok95ofdb7pnd",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "머신러닝 엔지니어",
        ],
        mainFeaturing: false,
    },
    {
        name: "코비젼",
        img: require("assets/company-logo/logo-코비젼.png"),
        link: "https://www.covision.co.kr/main/",
        goTo: "https://tyle.io/explore/gkdk65offbgfg3",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "풀스택 엔지니어",
        ],
        mainFeaturing: false,
    },
    {
        name: "플레이오니",
        img: require("assets/company-logo/logo-플레이오니.png"),
        link: "http://pleiony.com",
        goTo: "https://tyle.io/explore/ydxqp5ofei6x6j",
        hopePositions: ["머신러닝 엔지니어"],
    },
    {
        name: "위즈돔",
        img: require("assets/company-logo/logo-위즈돔.png"),
        link: "https://www.wizdome.co.kr/",
        goTo: "https://tyle.io/explore/f4rzg5ofe1w7e9",
        hopePositions: ["프론트엔드 엔지니어", "백엔드 엔지니어"],
    },
    {
        name: "코멘토",
        img: require("assets/company-logo/logo-코멘토.png"),
        link: "https://comento.kr/",
        goTo: "https://tyle.io/explore/oc67t5ohozsp5z",
        hopePositions: ["프론트엔드 엔지니어"],
        mainFeaturing: false,
    },
    {
        name: "디아이티",
        img: require("assets/company-logo/logo-디아이티.png"),
        link: "http://www.okdit.co.kr/",
        goTo: "https://tyle.io/explore/gbibf5ofdeu7uz",
        hopePositions: ["머신러닝 엔지니어", "컴퓨터 비전 엔지니어"],
        mainFeaturing: true,
    },
    {
        name: "그레이프시티",
        img: require("assets/company-logo/logo-그레이프시티.png"),
        link: "https://www.grapecity.co.kr/",
        goTo: "https://tyle.io/explore/3mir45offimnyk",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "풀스택 엔지니어",
        ],
    },
    {
        name: "코어트러스트",
        img: require("assets/company-logo/logo-코어트러스트.png"),
        link: "http://www.coretrust.com/kr/cover.asp",
        goTo: "https://tyle.io/explore/qt6as5ofecdstj",
        hopePositions: [
            "iOS 엔지니어",
            "안드로이드 엔지니어",
            "백엔드 엔지니어",
        ],
        mainFeaturing: true,
        isGood: true,
    },
    {
        name: "헤븐트리",
        img: require("assets/company-logo/logo-헤븐트리.png"),
        link: "http://www.heaventree.co.kr/",
        goTo: "https://tyle.io/explore/y9d2okfxp64c3",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "머신러닝 엔지니어",
        ],
    },
    {
        name: "오스템임플란트",
        img: require("assets/company-logo/logo-오스템임플란트.png"),
        link: "https://www.osstem.com",
        goTo: "https://tyle.io/explore/2qebw5ofnevh62",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "풀스택 엔지니어",
            "영상처리 엔지니어",
        ],
    },
    {
        name: "조아라",
        img: require("assets/company-logo/logo-조아라.png"),
        link: "https://www.joara.com",
        goTo: "https://tyle.io/explore/bg3n35ofclg1bs",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "데이터 엔지니어",
        ],
        mainFeaturing: false,
    },
    {
        name: "오픈엣지테크놀로지",
        img: require("assets/company-logo/logo-오픈엣지.png"),
        link: "https://www.openedges.com",
        goTo: "https://tyle.io/explore/zymep5ofctxnd8",
        hopePositions: ["프론트엔드 엔지니어", "풀스택 엔지니어"],
    },
    {
        name: "레인보우브레인",
        img: require("assets/company-logo/logo-레인보우브레인.png"),
        link: "http://rbrain.co.kr",
        goTo: "https://tyle.io/explore/wxaea5ofd3fjbl",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "데이터 엔지니어",
        ],
        mainFeaturing: true,
    },
    {
        name: "넷앤드",
        img: require("assets/company-logo/logo-넷앤드.png"),
        link: "http://www.netand.co.kr/",
        goTo: "https://tyle.io/explore/hwuff5off0uafw",
        hopePositions: ["프론트엔드 엔지니어", "백엔드 엔지니어"],
    },
    {
        name: "에스큐엔티",
        img: require("assets/company-logo/logo-에스큐엔티.png"),
        link: "http://www.sqnt.co.kr/default/",
        goTo: "https://tyle.io/explore/co42s5ofmqte8h",
        hopePositions: ["풀스택 엔지니어", "백엔드 엔지니어"],
    },
    {
        name: "싸이멕스",
        img: require("assets/company-logo/logo-싸이멕스.png"),
        link: "http://cymechs.com/",
        goTo: "https://tyle.io/explore/k40p65off420em",
        hopePositions: ["임베디드 엔지니어"],
    },
    {
        name: "람다256",
        img: require("assets/company-logo/logo-람다256.png"),
        link: "https://luniverse.io",
        goTo: "https://tyle.io/explore/er5th5offshh3p",
        hopePositions: [
            "프론트엔드 엔지니어",
            "백엔드 엔지니어",
            "풀스택 엔지니어",
        ],
        mainFeaturing: true,
        isGood: true,
    },
    {
        name: "탤런티넘",
        img: require("assets/company-logo/logo-탤런티넘.png"),
        link: "https://www.talentinum.com",
        goTo: "https://tyle.io/explore/dalmi5ohlvrub2",
        hopePositions: ["프론트엔드 엔지니어"],
    },
    {
        name: "핏펫",
        img: require("assets/company-logo/logo-핏펫.png"),
        link: "http://www.fitpet.co.kr",
        goTo: "https://tyle.io/explore/puink5ohmc34ua",
        hopePositions: [
            "데이터 엔지니어",
            "컴퓨터 비전 엔지니어",
            "머신러닝 엔지니어",
        ],
    },
    {
        name: "하이",
        img: require("assets/company-logo/logo-하이.png"),
        link: "https://www.haii.io/",
        goTo: "https://tyle.io/explore/easkakh33chjt",
        hopePositions: [
            "풀스택 엔지니어",
            "백엔드 엔지니어",
            "머신러닝 엔지니어",
        ],
        isGood: true,
    },
    {
        name: "알고케어",
        img: require("assets/company-logo/logo-알고케어.png"),
        link: "https://www.algocarelab.com/",
        goTo: "https://tyle.io/explore/q6vja5p14v9wo8",
        hopePositions: ["경력 프론트엔드 엔지니어", "경력 백엔드 엔지니어"],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
    {
        name: "펫핀스",
        img: require("assets/company-logo/logo-펫핀스.png"),
        link: "http://petfins.net",
        goTo: "https://tyle.io/explore/9wite5p12eh9as",
        hopePositions: ["신입 프론트엔드 엔지니어"],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
    {
        name: "주식회사 사고링크",
        img: require("assets/company-logo/logo-사고링크.png"),
        link: "https://www.sagolink.com/",
        goTo: "https://tyle.io/explore/dptmj5p1x9cfer",
        hopePositions: [
            "신입 프론트엔드 엔지니어, 신입 백엔드 엔지니어, 신입 풀스택 엔지니어",
        ],
        mainFeaturing: true,
        isNew: true,
        isHot: true,
    },
    {
        name: "주식회사 왓섭",
        img: require("assets/company-logo/logo-왓섭.png"),
        link: "https://whatssub.co",
        goTo: "https://tyle.io/explore/3xvzd5p1ze48e5",
        hopePositions: [
            "경력 1년 이상 프론트엔드 엔지니어, 경력 1년 이상 백엔드 엔지니어, 경력 1년 이상 풀스택 엔지니어",
        ],
        mainFeaturing: true,
        isNew: true,
        isHot: true,
    },
    {
        name: "커넥트히어로",
        img: require("assets/company-logo/logo-커넥트히어로.png"),
        link: "http://battong.com",
        goTo: "https://tyle.io/explore/djnpr5p1ywsa7o",
        hopePositions: [
            "신입 백엔드 엔지니어, 신입 iOS 엔지니어, 신입 안드로이드 엔지니어",
        ],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
    {
        name: "픽셀릭코리아",
        img: require("assets/company-logo/logo-픽셀릭코리아.png"),
        link: "https://pixelic.io",
        goTo: "https://tyle.io/explore/soqki5p1vx6bly",
        hopePositions: ["신입 백엔드 엔지니어, 신입 프론트엔드 엔지니어"],
        mainFeaturing: false,
    },
    {
        name: "주식회사 토닥",
        img: require("assets/company-logo/logo-토닥.png"),
        link: "http://www.todoc.co.kr/",
        goTo: "https://tyle.io/explore/okqfx5p28wufn9",
        hopePositions: ["신입 iOS 엔지니어, 신입 안드로이드 엔지니어"],
        mainFeaturing: false,
    },
    {
        name: "(주)에이치랩",
        img: require("assets/company-logo/logo-에이치랩.png"),
        link: "http://hlabtech.com",
        goTo: "https://tyle.io/explore/zw8z95p290zhh9",
        hopePositions: ["신입 머신러닝 엔지니어"],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
    {
        name: "주식회사 비브리지",
        img: require("assets/company-logo/logo-비브리지.png"),
        link: "https://slid.cc",
        goTo: "https://tyle.io/explore/u7xpe5p2prvh2s",
        hopePositions: ["신입 프론트엔드 엔지니어"],
        mainFeaturing: false,
    },
    {
        name: "주식회사 데이터비",
        img: require("assets/company-logo/logo-데이터비.png"),
        link: "https://pcanpi.com",
        goTo: "https://tyle.io/explore/19cjn5p2q2ferf",
        hopePositions: ["신입 머신러닝 엔지니어"],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
    {
        name: "주식회사 모자이크",
        img: require("assets/company-logo/logo-모자이크.png"),
        link: "https://taxmiin.com",
        goTo: "https://tyle.io/explore/d4agx5p2q5y3iq",
        hopePositions: [
            "신입 백엔드 엔지니어",
            "신입 프론트엔드 엔지니어",
            "신입 데이터 엔지니어",
        ],
        mainFeaturing: false,
    },
    {
        name: "(주)네이앤컴퍼니",
        img: require("assets/company-logo/logo-네이앤컴퍼니.png"),
        link: "https://neibus.kr/",
        goTo: "https://tyle.io/explore/esizy5p3xrbuxj",
        hopePositions: [
            "경력 1년 이상 안드로이드 엔지니어",
            "경력 1년 이상 백엔드 엔지니어",
            "경력 1년 이상 데이터 엔지니어",
        ],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
    {
        name: "(주)호두랩스",
        img: require("assets/company-logo/logo-호두랩스.png"),
        link: "https://hodooenglish.com/",
        goTo: "https://tyle.io/explore/ucygl5p3zkmbcn",
        hopePositions: [
            "경력 3년 이상 프론트엔드 엔지니어",
            "경력 3년 이상 풀스택 엔지니어",
        ],
        mainFeaturing: true,
        isHot: true,
        isNew: true,
    },
    {
        name: "(주)아이알엠",
        img: require("assets/company-logo/logo-아이알엠.png"),
        link: "http://www.irm.kr/",
        goTo: "http://www.irm.kr/",
        hopePositions: [
            "신입 프론트엔드 엔지니어",
            "신입 백엔드 엔지니어",
            "신입 풀스택 엔지니어",
        ],
        mainFeaturing: true,
        isGood: true,
        isNew: true,
    },
];
