import React, { useEffect } from "react";
import {
    Box,
    Typography,
    Container,
    Paper,
    Tabs,
    Tab,
} from "@material-ui/core";
import { ServiceHeaderD, ServiceFooterD } from "pages/components";
import { makeStyles } from "@material-ui/core/styles";

import TalentContentD from "./TalentContentD";
import ConpanyContentD from "./CompanyContentD";

import "date-fns";

const content = {
    mission: "실력 있는 개발자 채용\n프리미엄 스카우트 플랫폼,\n탤런트엑스",
};

const useStyles = makeStyles((theme) => ({
    wallpaper: {
        height: 750,
        backgroundImage: `url(${require("assets/bgi_talentx_app.jpg")})`,
        backgroundImage: `image-set(url(${require("assets/bgi_talentx_app.jpg")}) 1x, url(${require("assets/bgi_talentx_app@2x.jpg")}) 2x, url(${require("assets/bgi_talentx_app@2x.jpg")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    topContainer: {
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    mainTitle: {
        color: "#ffffff",
        fontSize: 48,
        fontWeight: "bold",
        lineHeight: 1.17,
        whiteSpace: "pre-wrap",
    },
    stickyTabs: {
        position: "-webkit-sticky",
        position: "sticky",
        top: 56,
        zIndex: 1,
        flexGrow: 1,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        textAlign: "center",
        width: "100%",
    },
    phantom: {
        width: "100%",
        height: 60,
    },
}));

const TalentXD = () => {
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);
    const [userType, setUserType] = React.useState(
        query.get("type") === "talent" ? 1 : 0
    );

    const handleUserTypeChange = (event, newUserType) => {
        setUserType(newUserType);
    };

    useEffect(() => window.scrollTo({ top: 0 }), []);

    return (
        <React.Fragment>
            <ServiceHeaderD userType={userType} />
            <Box className={classes.wallpaper}>
                <Container maxWidth="lg" className={classes.topContainer}>
                    <Typography className={classes.mainTitle}>
                        {content.mission}
                    </Typography>
                </Container>
            </Box>
            <Paper className={classes.stickyTabs} variant="outlined" square>
                <Tabs
                    value={userType}
                    onChange={handleUserTypeChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="채용 담당자" />
                    <Tab label="개발자" />
                </Tabs>
            </Paper>
            <Box className={classes.body}>
                {userType === 1 ? <TalentContentD /> : <ConpanyContentD />}
            </Box>
            <ServiceFooterD backgroudColor={"#2c2c2c"} />
            <Box className={classes.phantom}></Box>
        </React.Fragment>
    );
};

export default TalentXD;
