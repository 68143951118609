import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "80px 20px 12px",
        "& > *": {
            color: "#222222",
        },
    },
    body: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "32px 20px 12px",
        "& > *": {
            color: "#222222",
        },
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(1),
            width: "100%",
        },
        "& > div": {
            margin: theme.spacing(1, 0, 4),
        },
        "& > hr": {
            margin: theme.spacing(3, 0),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    resize: {
        fontSize: 14,
    },
    resize2: {
        paddingRight: 0,
    },
    sectionIcon: {
        marginBottom: 20,
    },
    title: {
        ...Texts.basic(20, "bold", "#222222", 1.4),
        marginBottom: 12,
    },
    introduction: {
        ...Texts.basic(12, "normal", "#444444", 1.43),
        marginBottom: 28,
    },
    subTitle: {
        ...Texts.basic(20, "bold", "#222222", 1.6),
        marginBottom: 28,
    },
    noticeBox: {
        backgroundColor: "#f8f8f8",
        border: "1px solid #dddddd",
        borderRadius: 8,
        padding: 16,
        marginBottom: 12,
        "& > *": {
            fontSize: 14,
            color: "#222222",
            margin: theme.spacing(0.5, 0, 0),
        },
        "& > p:first-child": {
            marginTop: 0,
        },
    },
    noticeTitle: {
        fontWeight: "bold",
        margin: theme.spacing(0, 0, 1),
    },
    inputTitle: {
        fontSize: 16,
        fontWeight: "bold",
    },
    imputLabel: {
        flex: 1,
    },
    inputUsage: {
        ...Texts.basic(12, "normal", "#444444"),
        marginBottom: 32,
    },
    sectionDivider: {
        height: theme.spacing(1),
    },
    addressTitleWrap: {
        display: "flex",
        justifyContent: "space-between",
    },
    searchButton: {
        backgroundColor: "#0c46f2",
        ...Texts.basic(14, "normal", "#ffffff"),
        padding: 17,
        width: 140,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    submitButton: {
        backgroundColor: "#0c46f2",
        ...Texts.basic(16, "normal", "#ffffff"),
        height: 56,
        width: "100%",
    },
    modalScreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        outline: 0,
        textAlign: "center",
        borderRadius: 12,
        width: 320,
        padding: "32px 16px 20px",
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 8,
    },
    modalMessage: {
        fontSize: 16,
        fontWeight: 500,
        color: "#222222",
        marginBottom: 12,
    },
    modalInfo: {
        fontSize: 12,
        fontWeight: 300,
        color: "#999999",
        marginBottom: 32,
    },
    modalButton: {
        width: "100%",
        fontSize: 16,
        height: 56,
    },
    foot: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "32px 20px 20px",
    },
    consentTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 8,
    },
    consentNotice: {
        fontSize: 14,
        fontWeight: 300,
        marginBottom: 20,
    },
    noticeContent: {
        marginBottom: 16,
    },
    noticeCaption: {
        fontSize: 12,
        fontWeight: 500,
        color: "#bbbbbb",
    },
    consentBtn: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: 20,
        marginBottom: 28,
    },
    consent: {
        fontSize: 16,
        fontWeight: 300,
        marginLeft: 9,
    },
}));
