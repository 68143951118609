import React from "react";
import { Container, Box, Typography, Button } from "@material-ui/core";
import { ServiceHeader } from "../components";
import { Helmet } from "react-helmet";
import { useStyles } from "./styleProfileUnsupported";
import { TXImage } from "../../components/tx";

const ProfileUnsupported = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필 작성 미지원</title>
            </Helmet>
            <ServiceHeader />
            <Container maxWidth="xs" disableGutters>
                <Box className={classes.inner}>
                    <TXImage imgName="img-mobile-app-bg" />
                    <Typography className={classes.alert}>
                        모바일 앱을 통해 작성해주세요
                    </Typography>
                    <Typography className={classes.guide}>
                        프로필 작성은 모바일 브라우저에서는 지원하지 않아요.
                        모바일에서는 앱을 통해 작성해주세요
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.btn}
                        onClick={() =>
                            window.open(
                                "https://talentx.co.kr/app/talent/profile",
                                "newWindow"
                            )
                        }
                    >
                        앱에서 프로필 작성
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default ProfileUnsupported;
