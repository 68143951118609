import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
    Box,
    Typography,
    Container,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Modal,
    Icon,
} from "@material-ui/core";
import { TalentServiceHeader } from "pages/components";

import {
    getTalentUserInvitorById,
    onTalentApply,
    putTalentDocument,
    putTalentApplication,
} from "apis/firestore/apply";
import { hopePositions, channelData, mentorData } from "data";
import { getLocalYYYYMMDD } from "helper/misc";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import { useStyles } from "./styleTalentApplication";

const TalentMembership = (props) => {
    const { target, invitor } = props.match.params;
    const query = new URLSearchParams(window.location.search);
    const utmSource = query.get("utm_source") || query.get("source");
    const utmMedium = query.get("utm_medium") || query.get("media");
    const initialChannel = `${target ? target + " " : ""}${
        utmSource ? utmSource + " " : ""
    }${utmMedium ? utmMedium : ""}`;
    const classes = useStyles();
    let today = new Date();
    today.setDate(today.getDate() + 1);

    const [name, setName] = useState("");
    const [birth, setBirth] = useState(new Date("1990-01-01T00:00:00Z"));
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [school, setSchool] = useState("");
    const [major, setMajor] = useState("");
    const [eduState, setEduState] = useState("졸업");
    const [bootcamp, setBootcamp] = useState("yes");
    const [working, setWorking] = useState("yes");
    const [yearsOfExperience, setYearsOfExperience] = useState("");
    const [hopePosition, setHopePosition] = useState("");
    const [testType, setTestType] = useState("프로필 평가");
    const [testDate, setTestDate] = useState(today);
    const [channel, setChannel] = useState(initialChannel);
    const [mentorReferral, setMentorReferral] = useState(false);
    const [mentor, setMentor] = useState("");
    const [consent, setConsent] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);
    const [invitorName, setInvitorName] = useState(null);
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [websiteURL, setWebsiteURL] = useState(null);

    useEffect(() => {
        async function getTalentUserInvitor() {
            const result = await getTalentUserInvitorById(invitor);
            if (result) {
                setInvitorName(result.name);
                setChannel(`${result.name} ( ${result.email} )`);
            }
            console.log("result", result);
        }
        if (invitor) getTalentUserInvitor();
    }, []);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleBirthChange = (date) => {
        setBirth(date);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    const handleSchoolChange = (event) => {
        setSchool(event.target.value);
    };
    const handleMajorChange = (event) => {
        setMajor(event.target.value);
    };
    const handleEduStateChange = (event) => {
        setEduState(event.target.value);
    };
    const handleBootcampChange = (event) => {
        setBootcamp(event.target.value);
    };
    const handleWorkingChange = (event) => {
        setWorking(event.target.value);
    };
    const handleYearsOfExperienceChange = (event) => {
        setYearsOfExperience(event.target.value);
    };
    const handleHopePositionChange = (event) => {
        setHopePosition(event.target.value);
    };
    const handleTestTypeChange = (event) => {
        setTestType(event.target.value);
    };
    const handleTestDateChange = (date) => {
        setTestDate(date);
    };
    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    };

    const handleMentorChange = (event) => {
        setMentor(event.target.value);
    };

    useEffect(() => {
        if (channel === "탤런트엑스 멘토 추천") setMentorReferral(true);
        else {
            setMentorReferral(false);
            setMentor("");
        }
    }, [channel]);

    useEffect(() => {
        const fileSelect = document.getElementById("fileSelect");
        const fileElem = document.getElementById("fileElem");
        fileSelect.addEventListener(
            "click",
            function (e) {
                if (fileElem) {
                    fileElem.click();
                }
                e.preventDefault(); // "#" 해시로 이동을 방지
            },
            false
        );
    }, []);

    const openFiles = (e) => {
        const files = e.target.files;
        console.log("openFiles@@@@@@@@@@@@@@", files[0]);
        if (files[0]) {
            setFile(files[0]);
        }
    };

    useEffect(() => {
        async function uploadFile() {
            if (file) {
                const headers = {
                    "Content-Type": "multipart/form-data",
                };
                const data = new FormData();
                data.append("resume", file);
                console.log("FormData", data);
                console.log({ headers });
                const uploadFileResult = await putTalentDocument(data, {
                    headers,
                });
                console.log("uploadFileResult", uploadFileResult);
                console.log(
                    "uploadFileResult Location",
                    uploadFileResult.data.Location
                );
                setFileURL(uploadFileResult.data.Location);
            }
        }
        if (file) uploadFile();
    }, [file]);

    const handleWebsiteURLChange = (event) => {
        setWebsiteURL(event.target.value);
    };

    const handleSubmit = () => {
        setIsFetching(true);
        const application = {
            name: name,
            birth: getLocalYYYYMMDD(birth),
            email: email,
            phone: phone,
            school: school,
            major: major,
            eduState: eduState,
            bootcamp: bootcamp === "yes",
            working: working === "yes",
            yearsOfExperience: String(yearsOfExperience),
            position: hopePosition,
            testType: testType,
            testDate: getLocalYYYYMMDD(testDate),
            channel: mentor ? channel + " " + mentor : channel,
            mentor: mentor,
            invitor: invitorName ? invitor : undefined,
            documentURL: fileURL,
            websiteURL: websiteURL,
        };
        const result = onTalentApply(application, setIsFetching, setOpen);
        if (result) putTalentApplication(application, setIsFetching, setOpen);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        setIsFetching(true);
        setTimeout(() => props.history.push("/"), 1000);
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        setSubmittable(
            name &&
                birth &&
                email &&
                phone &&
                school &&
                major &&
                (!!yearsOfExperience ||
                    typeof yearsOfExperience === "number") &&
                hopePosition &&
                testType &&
                testDate &&
                channel &&
                mentorReferral === !!mentor &&
                consent
        );
    }, [
        name,
        birth,
        email,
        phone,
        school,
        major,
        yearsOfExperience,
        hopePosition,
        testType,
        testDate,
        channel,
        mentorReferral,
        mentor,
        consent,
    ]);

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    탤런트엑스 |{" "}
                    {invitorName ? "프리미엄 멤버 초대" : "개발자 회원 신청"}
                </title>
            </Helmet>
            <TalentServiceHeader />
            <Container maxWidth="xs" disableGutters>
                <Box className={classes.head}>
                    <img
                        src={require("assets/icon-talent.png")}
                        srcSet={`${require("assets/icon-talent@2x.png")} 2x, ${require("assets/icon-talent@3x.png")} 3x`}
                        className={classes.sectionIcon}
                        alt="talent icon"
                    ></img>
                    {invitorName ? (
                        <>
                            <Typography className={classes.sectionTitle}>
                                {invitorName}님이 귀하를
                                <br />
                                탤런트엑스 프리미엄 멤버로
                                <br />
                                초대했습니다!
                            </Typography>
                            <Box className={classes.requirementBox}>
                                <Typography
                                    className={classes.requirementTitle}
                                >
                                    프리미엄 멤버 혜택
                                </Typography>

                                <Box className={classes.requirementItemBox}>
                                    <Icon>card_giftcard</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            스카우트 제안
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            유망 기업의 매력적인 스카우트 제안을
                                            단독으로 받을 수 있고, {invitorName}
                                            님과 함께 팀 단위로도 받을 수
                                            있습니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <Icon>currency_bitcoin</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            탤런트엑스 포인트
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            귀하의 우수한 개발경력 프로필에 대한
                                            공정한 정보 사용료를 매월 산정하여
                                            네이버 포인트로 드립니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <Icon>monetization_on</Icon>

                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            탤런트엑스 로열티
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            단독 스카우트 제안을 받아 이직하시면
                                            일반 멤버 대비 40% 높은 70만원의
                                            로열티를 지급해 드립니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <Icon>30fps</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            프리미엄 멤버 초대권
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            평소 알고 지내는 경력 개발자 친구를
                                            최대 30명까지 프리미엄 멤버로
                                            초대하여 동일한 혜택을 함께 받을 수
                                            있습니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <Icon>diversity_1</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            친구 초대 리워드
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            초대한 친구가 프리미엄 멤버가 되면
                                            1만 포인트를 드리고, 스카우트 제안을
                                            받아 이직까지 하면 추가로 30만원을
                                            귀하에게 드립니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    className={classes.requirementGuide}
                                >
                                    * 프리미엄 멤버십은 무료입니다.
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Typography className={classes.sectionTitle}>
                                탤런트엑스 멤버가 되어,
                                <br />
                                {target || "다양한 유망 IT기업"}의 스카우트
                                제안을
                                <br />
                                채용팀에게 직접 받아보세요!
                            </Typography>
                            <Box className={classes.requirementBox}>
                                <Typography
                                    className={classes.requirementTitle}
                                >
                                    멤버십 혜택
                                </Typography>

                                <Box className={classes.requirementItemBox}>
                                    <Icon>card_giftcard</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            스카우트 제안
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            채용 공고마다 힘들게 지원하지
                                            않고도, 다양한 기업으로부터 매력적인
                                            스카우트 제안을 받아 볼 수 있습니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <Icon>currency_bitcoin</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            탤런트엑스 포인트
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            귀하의 개발경력 프로필에 대한 공정한
                                            정보 사용료를 매월 산정하여 네이버
                                            포인트로 드립니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <Icon>monetization_on</Icon>

                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            탤런트엑스 로열티
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            탤런트엑스 인증 기업들에게 스카우트
                                            제안을 받아 이직하시면 50만원의
                                            로열티를 지급해 드립니다.
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography
                                    className={classes.requirementGuide}
                                >
                                    * 탤런트엑스 멤버십은 무료입니다.
                                </Typography>
                            </Box>
                            {/* <Box className={classes.requirementBox}>
                                <Typography
                                    className={classes.requirementTitle}
                                >
                                    가입 조건
                                </Typography>
                                <Typography
                                    className={classes.requirementGuide}
                                >
                                    &#8226; 아래 조건 중 1개만 만족해도 신청
                                    가능해요!
                                </Typography>
                                <Box className={classes.requirementItemBox}>
                                    <img
                                        src={require("assets/icon-career-29.png")}
                                        srcSet={`${require("assets/icon-career-29@2x.png")} 2x, ${require("assets/icon-career-29@3x.png")} 3x`}
                                        className={classes.requirementIcon}
                                        alt="education icon"
                                    ></img>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            경력
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            현재 기업에 재직 중이거나 프리랜서로
                                            활동 중인 현업 개발자
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <img
                                        src={require("assets/icon-education-29.png")}
                                        srcSet={`${require("assets/icon-education-29@2x.png")} 2x, ${require("assets/icon-education-29@3x.png")} 3x`}
                                        className={classes.requirementIcon}
                                        alt="employee icon"
                                    ></img>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            전공
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            SW분야 전공 전문학사학위 이상 소지자
                                            혹은, 6개월 내 취득 예정자
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.requirementItemBox}>
                                    <img
                                        src={require("assets/icon-coding-29.png")}
                                        srcSet={`${require("assets/icon-coding-29@2x.png")} 2x, ${require("assets/icon-coding-29@3x.png")} 3x`}
                                        className={classes.requirementIcon}
                                        alt="employee icon"
                                    ></img>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            교육
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            SW분야 전문 교육 프로그램 이수자
                                            (예: SW마에스트로, SSAFY, 42서울 등)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
                        </>
                    )}
                    <Box className={classes.stepSection}>
                        <Typography className={classes.stepTitle}>
                            온보딩 절차
                        </Typography>
                        <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNum}>1</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    신청서 제출
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    - 아래 신청서를 제출하시면 탤런트엑스
                                    매니저가 가입 요건 검토 후, 승인 안내와
                                    탤런트엑스 앱 설치 링크를 보내 드립니다.
                                </Typography>
                                <Typography className={classes.duration}>
                                    영업일 기준 1일 ~ 3일 소요
                                </Typography>
                            </Box>
                        </Box>
                        {/* <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNumDark}>2</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    코딩 테스트{" "}
                                    <span style={{ color: "blue" }}>
                                        (합격 시 다수 기업 코테 면제)
                                    </span>
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    - 희망일 전일 또는 당일에 발송되는 이메일을
                                    통해 코딩테스트에 응시해 주세요. (총 3개 ~
                                    4개 문항, 영문으로 출제, 재응시 가능)
                                </Typography>
                                <Typography className={classes.duration}>
                                    이메일 수신일로부터 10일 이내 응시 가능
                                </Typography>
                            </Box>
                        </Box> */}
                        <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNum}>2</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    정식 회원가입
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    - 탤런트엑스 앱을 설치하고 승인 받은
                                    이메일로 정식 회원가입을 진행합니다.
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNumDark}>3</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    프로필 작성
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    - 회원가입 후 프로필을 작성하시면 유망
                                    기업으로부터 스카우트 제안을 받아 보실 수
                                    있습니다.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider className={classes.sectionDivider} light></Divider>
                <Box className={classes.body}>
                    <Typography className={classes.sectionTitle}>
                        신청서 작성
                    </Typography>
                    <form
                        id="talent-membership"
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography className={classes.inputTitle}>
                            이름 *
                        </Typography>
                        <TextField
                            required
                            id="name"
                            inputProps={{ maxLength: 10 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="이름"
                            //label="이름"
                            value={name}
                            onChange={handleNameChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            생년월일 *
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            //locale={koLocale}
                        >
                            <KeyboardDatePicker
                                required
                                id="birth"
                                //disableToolbar
                                InputProps={{
                                    classes: { input: classes.resize },
                                }}
                                //openTo="year"
                                //type="date"
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy/MM/dd"
                                invalidDateMessage={
                                    <Typography style={{ fontSize: 12 }}>
                                        YYYY/MM/DD 형식으로 입력해 주세요!
                                    </Typography>
                                }
                                margin="normal"
                                maxDate={new Date()}
                                //label="생년월일"
                                value={birth}
                                onChange={handleBirthChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                        <Typography className={classes.inputTitle}>
                            이메일 *
                        </Typography>
                        <TextField
                            required
                            id="email"
                            variant="outlined"
                            style={{ marginBottom: 0 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            placeholder="이메일을 입력해주세요"
                            //label="이메일"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <Typography className={classes.inputUsage}>
                            입력하신 이메일로 승인 여부를 안내 드리며, 승인 시
                            해당 이메일로만 서비스 이용이 가능하므로, 반드시
                            수신 가능한 이메일 주소를 입력해주세요.
                        </Typography>
                        <Typography className={classes.inputTitle}>
                            휴대폰번호 *
                        </Typography>
                        <TextField
                            required
                            id="phone"
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                            inputProps={{ maxLength: 11 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            placeholder="휴대폰번호를 입력해주세요.(-없이 11자리)"
                            //label="휴대폰번호"
                            value={phone}
                            onChange={handlePhoneChange}
                            //helperText="'-' 없이 번호만 입력해 주세요."
                        />
                        <Divider></Divider>
                        <Typography className={classes.inputTitle}>
                            최종 학력 및 전공 *
                        </Typography>

                        <TextField
                            required
                            id="school"
                            variant="outlined"
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            placeholder="최종 졸업 학교명을 입력해주세요.(예 : 엑스대학교)"
                            //label="학교"
                            value={school}
                            onChange={handleSchoolChange}
                            //helperText="최종 졸업 학교명을 입력해 주세요."
                            style={{ marginBottom: 9 }}
                        />
                        <Box className={classes.majorBox}>
                            <TextField
                                required
                                id="major"
                                InputProps={{
                                    classes: { input: classes.resize },
                                }}
                                variant="outlined"
                                placeholder="전공을 입력해주세요."
                                //label="전공"
                                value={major}
                                onChange={handleMajorChange}
                                className={classes.major}
                            />
                            <FormControl
                                variant="outlined"
                                className={classes.schoolState}
                            >
                                <Select
                                    displayEmpty
                                    value={eduState}
                                    onChange={handleEduStateChange}
                                    classes={{ root: classes.inlinSelect }}
                                >
                                    {["졸업", "졸업예정", "재학중"].map(
                                        (item, idx) => (
                                            <MenuItem
                                                id="edu-state"
                                                key={idx}
                                                value={item}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                {/* <FormHelperText>
                                SW개발 경력만 계산해서 선택해 주세요.
                            </FormHelperText> */}
                            </FormControl>
                        </Box>
                        <Typography className={classes.inputTitle}>
                            SW개발 교육 프로그램을 수료한 적이 있나요? *
                        </Typography>
                        <FormControl component="fieldset">
                            {/* <FormLabel component="legend">
                                labelPlacement
                            </FormLabel> */}
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={bootcamp}
                                defaultValue="yes"
                                onChange={handleBootcampChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="있어요"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="없어요"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                        <Typography className={classes.inputTitle}>
                            현재 SW개발자로 일을 하고 계신가요? *
                        </Typography>
                        <FormControl component="fieldset">
                            {/* <FormLabel component="legend">
                                labelPlacement
                            </FormLabel> */}
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={working}
                                defaultValue="yes"
                                onChange={handleWorkingChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="예"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="아니오"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                        <Typography className={classes.inputTitle}>
                            개발 경력 *
                        </Typography>
                        <FormControl variant="outlined">
                            <Select
                                displayEmpty
                                value={yearsOfExperience}
                                onChange={handleYearsOfExperienceChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    id="years-of-experience"
                                    value=""
                                    classes={{ root: classes.resize }}
                                    disabled
                                >
                                    선택해주세요.
                                </MenuItem>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                                    .slice(invitorName ? 1 : 0)
                                    .map((idx) => (
                                        <MenuItem
                                            id="years-of-experience"
                                            key={idx}
                                            value={idx}
                                            classes={{ root: classes.resize }}
                                        >
                                            {idx ? idx + "년" : "신입"}
                                        </MenuItem>
                                    ))}
                                <MenuItem
                                    id="years-of-experience"
                                    key={10}
                                    value={10}
                                    classes={{ root: classes.resize }}
                                >
                                    10년 이상
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Typography className={classes.inputTitle}>
                            주요 직무 *
                        </Typography>
                        <FormControl
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                        >
                            <Select
                                displayEmpty
                                value={hopePosition}
                                onChange={handleHopePositionChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    id="position"
                                    value=""
                                    classes={{ root: classes.resize }}
                                    disabled
                                >
                                    선택해주세요.
                                </MenuItem>
                                {hopePositions.map((item, idx) => (
                                    <MenuItem
                                        id="position"
                                        key={idx}
                                        value={item}
                                        classes={{ root: classes.resize }}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Divider></Divider>
                        <Typography className={classes.inputTitle}>
                            IT포트폴리오 링크
                            <span
                                style={{
                                    fontSize: 13,
                                    color: "#787878",
                                    verticalAlign: "text-top",
                                }}
                            >
                                {" "}
                                [선택]
                            </span>
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                URL (최대 256자)
                            </span>
                        </Typography>
                        <TextField
                            required
                            id="website-url"
                            style={{ marginBottom: 0 }}
                            inputProps={{ maxLength: 256 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="문서 링크 / 개인 웹페이지 링크 / 노션 페이지 링크"
                            //label="이름"
                            value={websiteURL}
                            onChange={handleWebsiteURLChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputUsage}>
                            등록 링크를 바탕으로 귀하의 앱프로필 초안을 자동
                            생성해 드립니다.
                        </Typography>

                        <Typography className={classes.inputTitle}>
                            IT포트폴리오 문서
                            <span
                                style={{
                                    fontSize: 13,
                                    color: "#787878",
                                    verticalAlign: "text-top",
                                }}
                            >
                                {" "}
                                [선택]
                            </span>
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                PDF (최대 3MB)
                            </span>
                        </Typography>
                        <Button
                            id="fileSelect"
                            variant="contained"
                            //size="large"
                            disabled={false}
                            disableElevation
                            style={{
                                boxShadow: "none",
                                height: 56,
                                backgroundColor: "#eeeeee",
                                fontWeight: "bold",
                                marginBottom: 0,
                            }}
                        >
                            {/* <TXImage imgName="icon-camera-black-24" /> */}
                            <Icon>post_add</Icon>

                            {file?.name ?? "파일 등록"}
                        </Button>
                        <input
                            type="file"
                            id="fileElem"
                            //multiple
                            accept="image/*,.pdf"
                            style={{ display: "none" }}
                            onChange={openFiles}
                        />
                        <Typography className={classes.inputUsage}>
                            등록 문서를 바탕으로 귀하의 앱프로필 초안을 자동
                            생성해 드립니다.
                        </Typography>

                        {/* <Typography className={classes.inputTitle}>
                            코딩 테스트 희망 유형 (네카라쿠배 중 선택)
                        </Typography>
                        <FormControl
                            variant="outlined"
                            style={{ marginBottom: 0 }}
                        >
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={testType}
                                onChange={handleTestTypeChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    value=""
                                    classes={{ root: classes.resize }}
                                    disabled
                                >
                                    선택해주세요.
                                </MenuItem>
                                {testTypeData.map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        value={item}
                                        classes={{ root: classes.resize }}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography className={classes.inputUsage}>
                            유망 기업별 최신 출제 유형에 맞춰 코딩 테스트가
                            제공되며{" "}
                            <span style={{ color: "blue" }}>
                                합격 시 다수 기업의 코딩테스트가 면제됩니다.
                            </span>
                        </Typography>
                        <Typography className={classes.inputTitle}>
                            코딩 테스트 응시 희망일
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}

                            //locale={koLocale}
                        >
                            <KeyboardDatePicker
                                required
                                //disableToolbar
                                style={{ marginBottom: 0 }}
                                InputProps={{
                                    classes: { input: classes.resize },
                                }}
                                //openTo="year"
                                //type="date"
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy/MM/dd"
                                invalidDateMessage={
                                    <Typography style={{ fontSize: 12 }}>
                                        YYYY/MM/DD 형식으로 입력해 주세요!
                                    </Typography>
                                }
                                margin="normal"
                                id="date-picker-inline"
                                minDate={today}
                                minDateMessage={
                                    <Typography style={{ fontSize: 12 }}>
                                        내일 이후 날짜를 입력해 주세요!
                                    </Typography>
                                }
                                maxDate={lastDay}
                                //label="생년월일"
                                value={testDate}
                                onChange={handleTestDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                autoOk={true}
                            />
                            <Typography className={classes.inputUsage}>
                                신청서를 검토하여 가입 조건이 만족할 경우, 응시
                                희망일에 맞춰 코딩 테스트 링크를 발송해드립니다.
                            </Typography>
                        </MuiPickersUtilsProvider> */}
                        {!invitorName && (
                            <>
                                <Divider />
                                <Typography className={classes.inputTitle}>
                                    저희를 어떤 경로로 알게 되셨나요? *
                                </Typography>
                                <FormControl
                                    variant="outlined"
                                    style={{ marginBottom: 8 }}
                                >
                                    {/* <InputLabel id="demo-simple-select-label">
                                경력년수
                            </InputLabel> */}
                                    <Select
                                        displayEmpty
                                        value={channel}
                                        onChange={handleChannelChange}
                                        classes={{ root: classes.resize }}
                                    >
                                        <MenuItem
                                            id="channel"
                                            value={initialChannel}
                                            classes={{ root: classes.resize }}
                                            disabled={!initialChannel}
                                        >
                                            {initialChannel
                                                ? initialChannel
                                                : "선택해주세요."}
                                        </MenuItem>
                                        {channelData.map((item, idx) => (
                                            <MenuItem
                                                id="channel"
                                                key={idx}
                                                value={item}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        {mentorReferral && (
                            <>
                                <Typography className={classes.inputTitle}>
                                    저희를 추천한 멘토를 선택해주세요.
                                </Typography>
                                <FormControl variant="outlined">
                                    {/* <InputLabel id="demo-simple-select-label">
                                경력년수
                            </InputLabel> */}
                                    <Select
                                        displayEmpty
                                        value={mentor}
                                        onChange={handleMentorChange}
                                        classes={{ root: classes.resize }}
                                    >
                                        <MenuItem
                                            id="mentor"
                                            value=""
                                            classes={{ root: classes.resize }}
                                            disabled
                                        >
                                            선택해주세요.
                                        </MenuItem>
                                        {mentorData.map((item, idx) => (
                                            <MenuItem
                                                id="mentor"
                                                key={idx}
                                                value={item}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                    </form>

                    <Backdrop className={classes.backdrop} open={isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        className={classes.modalScreen}
                    >
                        <Box className={classes.modalBox}>
                            <Typography className={classes.modalTitle}>
                                신청완료
                            </Typography>
                            <Typography className={classes.modalMessage}>
                                신청해주셔서 감사합니다!
                                <br />
                                담당자 확인 후 가입 조건 충족 시
                                <br />
                                탤런트엑스 앱 설치 링크를 보내드립니다.
                            </Typography>
                            <Typography className={classes.modalInfo}>
                                가입 심사는 영업일 기준 1 - 3일 소요
                            </Typography>
                            <Button
                                onClick={handleOk}
                                variant="contained"
                                color="primary"
                                className={classes.modalButton}
                            >
                                확인
                            </Button>
                        </Box>
                    </Modal>
                </Box>
                <Divider className={classes.sectionDivider} light></Divider>
                <Box className={classes.foot}>
                    <Typography className={classes.consentTitle}>
                        개인정보 수집·이용 동의
                    </Typography>
                    <Typography className={classes.consentNotice}>
                        (주)탤런티넘은 '개인정보 보호법'에 따라 본인의 동의를
                        얻어 서비스 제공을 위한 개인정보를 수집·이용합니다.
                    </Typography>
                    <Box className={classes.noticeBox}>
                        <Typography className={classes.noticeTitle}>
                            1) 개인정보 수집 목적
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            가입심사, 온라인 프로필 초안 자동 생성
                        </Typography>
                        <Typography className={classes.noticeTitle}>
                            2) 개인정보 수집 항목
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            이름, 생년월일, 이메일, 연락처, 학력, 교육수료이력,
                            직업현황, 개발경력
                        </Typography>
                        <Typography className={classes.noticeCaption}>
                            &#8226; 가입심사 완료 후 개인정보가 불필요하게
                            되었을 때 개인정보를 즉시 파기합니다.
                        </Typography>
                    </Box>
                    <Box
                        className={classes.consentBtn}
                        onClick={() => {
                            setConsent((prev) => !prev);
                        }}
                    >
                        {consent ? (
                            <img
                                src={require("assets/icon-toggle-checkbox-checked-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-checked-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-checked-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        ) : (
                            <img
                                src={require("assets/icon-toggle-checkbox-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        )}
                        <Typography className={classes.consent}>
                            개인정보 수집·이용에 동의합니다
                        </Typography>
                    </Box>
                    <Button
                        id="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!submittable}
                        className={classes.submitButton}
                    >
                        신청하기
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default TalentMembership;
