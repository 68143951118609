import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { getCompany } from "apis/firestore/fetch";
import {
    TalentServiceHeader,
    TalentServiceHeaderD,
    ServiceFooter,
    ServiceFooterD,
} from "pages/components";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import { companies, scoutCompanies } from "../dataCompanyScout";

import {
    Container,
    Typography,
    Box,
    Divider,
    Grid,
    Popover,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
//import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./styleCompanyDetail.js";

import { openLinkNewTab } from "helper/misc";
import { deviconMap, devicon, companyNameMap } from "data";

const getRegNum = (id) => {
    return isNaN(id) ? companyNameMap.get(id) : id;
};

const CompanyDetail = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles({ isMobile });
    const history = useHistory();
    console.log(props.match.params);
    const { id } = props.match.params;
    const regNum = getRegNum(id);
    const query = new URLSearchParams(window.location.search);
    const utmSource = query.get("utm_source") || query.get("source");
    const utmMedium = query.get("utm_medium") || query.get("media");
    let utm = utmSource ? `utm_source=${utmSource}` : "";
    utm += utmMedium
        ? utm
            ? `&utm_medium=${utmMedium}`
            : `utm_medium=${utmMedium}`
        : "";
    utm = utm ? `?${utm}` : "";
    console.log("utm", utm);

    const [company, setCompany] = useState();
    useEffect(() => window.scrollTo({ top: 0 }), []);
    useEffect(() => {
        (async () => {
            const result = await getCompany(regNum);
            console.log("company-info-in-cloud :", result);
            if (result) {
                if (
                    result.devTeam.positions.length === 0 ||
                    regNum === "1448108465" || //아이알엠
                    regNum === "2948701037" || //로랩스
                    //regNum === "4128801781" || //밴플
                    regNum === "1148607247" || //큐램
                    regNum === "2148859980" || //더핑크퐁컴퍼니
                    //regNum === "1068677081" || //오픈서베이
                    regNum === "2158772878" || //플리토
                    regNum === "5138601891" || //두들린
                    //regNum === "2808600274" || //아이엠에스모빌리티
                    //regNum === "2138641955" || //알티엠
                    regNum === "5708501897" || //섹타나인
                    regNum === "2208741741" || //아이지에이웍스
                    //regNum === "1078772034" || //글로브포인트
                    //regNum === "5758700451" || //차봇 모빌리티
                    regNum === "5728602360" || //주식회사 빅크
                    regNum === "3278700041" //주식회사 닷
                ) {
                    const staticCompanyInfo = scoutCompanies.find(
                        (company) => company.registrationNumber === regNum
                    );
                    result.serviceLink = staticCompanyInfo.serviceLink;
                    result.devTeam = staticCompanyInfo.devTeam;
                    result.devStyles = staticCompanyInfo.devStyles;
                    result.welfares = staticCompanyInfo.welfares;
                }
                result.devTeam.total = result.devTeam.positions.reduce(
                    (accumulator, currentPosition) =>
                        accumulator + currentPosition.total,
                    0
                );
                setCompany(result);
            }
        })();
        // const targetCompany = scoutCompanies.find(
        //     (company) => company.registrationNumber === regNum
        // );
        // console.log("regNum :", targetCompany);
        // setCompany(targetCompany);
    }, []);

    const [anchorEl, setAnchorEl] = React.useState();
    const [devStyleDesc, setDevStyleDesc] = React.useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        const devStyleObj = company.devStyles.find(
            (element) => element.name === event.currentTarget.innerText
        );
        setDevStyleDesc(devStyleObj.description);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popId = open ? "simple-popover" : undefined;

    function CustomPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={require("assets/icon-prev-arrow-32.png")}
                srcSet={`${require("assets/icon-prev-arrow-32@2x.png")} 2x, ${require("assets/icon-prev-arrow-32@3x.png")} 3x`}
                className={className}
                style={{ ...style, zIndex: 1, left: 20 }}
                onClick={onClick}
            ></img>
        );
    }

    function CustomNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={require("assets/icon-next-arrow-32.png")}
                srcSet={`${require("assets/icon-next-arrow-32@2x.png")} 2x, ${require("assets/icon-next-arrow-32@3x.png")} 3x`}
                className={className}
                style={{ ...style, zIndex: 1, right: 20 }}
                onClick={onClick}
            ></img>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveWidth: true,
        adaptiveHeight: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    const expRangeStr = (min, max) =>
        min === 0
            ? `신입 ~ 경력 ${max}년${max === 10 ? " 이상" : ""}`
            : `경력 ${min}년 ~ ${max}년${max === 10 ? " 이상" : ""}`;

    const salaryRangeStr = (min, max) => {
        if (
            regNum === "1058802387" || //지속가능발전소
            regNum === "2958800023" || //오피지지
            regNum === "2138641955" || //알티엠
            regNum === "3278700041" //주식회사 닷
        )
            return "면접 후 연봉 협의";

        let minSalaryR = min % 10000;
        let minSalaryM = parseInt(min / 10000);
        let maxSalaryR = max % 10000;
        let maxSalaryM = parseInt(max / 10000);

        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        } ~ ${maxSalaryM ? maxSalaryM + "억" : ""}${
            maxSalaryR ? " " + maxSalaryR + "만원" : "원"
        }`;
    };

    const [selectedPosIdx, setSelectedPosIdx] = useState(0);

    const handlePositionClick = (idx) => {
        setSelectedPosIdx(idx);
    };

    const handleImgError = (e) => {
        e.target.src = require("assets/icon-skill-default-52.png");
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    탤런트엑스 | {company ? company.name : "유망 개발팀"}
                </title>
            </Helmet>
            {isMobile ? (
                <>
                    <TalentServiceHeader />
                    {company && (
                        <>
                            <Box className={classes.imageSlider}>
                                <Slider {...settings}>
                                    {company.photos.map((item, idx) => (
                                        <img
                                            key={idx}
                                            src={item.url}
                                            className={classes.image}
                                        ></img>
                                    ))}
                                </Slider>
                            </Box>
                            <Typography className={classes.topSlogan}>
                                {company.slogan}
                            </Typography>
                            <Box className={classes.actionCard}>
                                <Box className={classes.infoWrap}>
                                    <img
                                        src={company.logo.url}
                                        className={classes.logo}
                                        alt="logo"
                                    ></img>
                                    <Box>
                                        <Box className={classes.companyWrap}>
                                            <Typography
                                                className={classes.name}
                                            >
                                                {company.name}
                                            </Typography>
                                            {company.plan === "premium" && (
                                                <img
                                                    src={require("assets/img-premium-badge.png")}
                                                    srcSet={`${require("assets/img-premium-badge@2x.png")} 2x, ${require("assets/img-premium-badge@3x.png")} 3x`}
                                                    className={classes.premium}
                                                ></img>
                                            )}
                                        </Box>
                                        <Typography className={classes.service}>
                                            {company.services[0].name}
                                            <span className={classes.ceo}>
                                                {" "}
                                                | {company.ceo}
                                            </span>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box className={classes.hiringWrap}>
                                    <Typography className={classes.hiringTitle}>
                                        채용중
                                    </Typography>
                                    {company.hirings?.map((position, idx) => (
                                        <Box
                                            key={idx}
                                            className={classes.positionBox}
                                        >
                                            <Typography
                                                className={classes.position}
                                            >
                                                {`${position.position} ·` +
                                                    expRangeStr(
                                                        position.lowYears,
                                                        position.highYears
                                                    )}
                                            </Typography>
                                            <Typography
                                                className={classes.salary}
                                            >
                                                {salaryRangeStr(
                                                    position.lowSalary,
                                                    position.highSalary
                                                )}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Box className={classes.addressWrap}>
                                    <Box className={classes.addressBox}>
                                        <Typography
                                            className={classes.addressTitle}
                                        >
                                            회사 위치
                                        </Typography>
                                        <Typography className={classes.address}>
                                            {company.address1}
                                        </Typography>
                                    </Box>
                                    {/* <Typography className={classes.mapBtn}>
                                        위치보기
                                    </Typography> */}
                                    <Divider />
                                </Box>
                            </Box>
                            <Box className={classes.actionsFixedWrap}>
                                <Box className={classes.actionsWrap}>
                                    <Typography className={classes.question}>
                                        {company.name}에 관심이 있으신가요?
                                    </Typography>
                                    <Box
                                        className={classes.applyBtn}
                                        onClick={() => {
                                            history.push(
                                                `/talent-application/${company.name}${utm}`
                                            );
                                        }}
                                    >
                                        <Typography
                                            className={classes.btnSubTitle}
                                        >
                                            탤런트엑스 가입하고
                                        </Typography>
                                        <Typography
                                            className={classes.btnTitle}
                                        >
                                            제안 받아보기
                                        </Typography>
                                    </Box>
                                    <Box></Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <TalentServiceHeaderD />
            )}
            <Container maxWidth="lg">
                <Box className={classes.inner}>
                    <Box className={classes.leftSection}>
                        {company ? (
                            <>
                                {!isMobile && (
                                    <>
                                        <Box className={classes.imageSlider}>
                                            <Slider {...settings}>
                                                {company.photos.map(
                                                    (item, idx) => (
                                                        <img
                                                            key={idx}
                                                            src={item.url}
                                                            className={
                                                                classes.image
                                                            }
                                                        ></img>
                                                    )
                                                )}
                                            </Slider>
                                        </Box>
                                        <Typography className={classes.company}>
                                            {company.name}
                                        </Typography>
                                        <Typography className={classes.slogan}>
                                            {company.slogan}
                                        </Typography>
                                    </>
                                )}

                                <Typography className={classes.devTeam}>
                                    개발팀 소개
                                </Typography>
                                <Typography className={classes.devTeamIntro}>
                                    {company.devTeam.description}
                                </Typography>
                                <Typography className={classes.devTeamIntro}>
                                    {company.devTeam.link?.url && (
                                        <Box
                                            className={
                                                classes.devTeamReferenceWrap
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.devTeamReferenceTitle
                                                }
                                            >
                                                참고 링크
                                            </Box>

                                            <Typography
                                                className={
                                                    classes.devTeamReference
                                                }
                                                onClick={() =>
                                                    openLinkNewTab(
                                                        company.devTeam.link.url
                                                    )
                                                }
                                            >
                                                {company.devTeam.link.label}
                                            </Typography>
                                        </Box>
                                    )}
                                </Typography>
                                {/* <Box className={classes.devTeamWrap}>
                                    <Box className={classes.devTeamHeadWrap}>
                                        <img
                                            src={require("assets/icon-devteam-people.png")}
                                            srcSet={`${require("assets/icon-devteam-people@2x.png")} 2x, ${require("assets/icon-devteam-people@3x.png")} 3x`}
                                            className={classes.devTeamImg}
                                            alt="arrow"
                                        ></img>
                                        <Typography
                                            className={classes.devTeamStructure}
                                        >
                                            개발팀 구성{" "}
                                            {company.devTeam?.total &&
                                                "(총 " +
                                                    company.devTeam?.total +
                                                    "명)"}
                                        </Typography>
                                    </Box>
                                    {company.devTeam.positions?.map(
                                        (position, idx) => {
                                            if (position.total)
                                                return (
                                                    <Box
                                                        key={idx}
                                                        className={
                                                            classes.devTeamPositionWrap
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.devTeamPositionName
                                                            }
                                                        >
                                                            {position.name}
                                                        </Typography>
                                                        <Typography
                                                            className={
                                                                classes.devTeamPositionCount
                                                            }
                                                        >
                                                            총 {position.total}
                                                            명
                                                        </Typography>
                                                        <Box
                                                            className={
                                                                classes.devTeamPositionYearsBar
                                                            }
                                                        >
                                                            {!!position.rookie && (
                                                                <Box
                                                                    style={{
                                                                        flex: position.rookie,
                                                                        height: "100%",
                                                                        backgroundColor:
                                                                            "#cdd9fd",
                                                                        color: "#ffffff",
                                                                    }}
                                                                >
                                                                    {
                                                                        position.rookie
                                                                    }
                                                                </Box>
                                                            )}
                                                            {!!position.junior && (
                                                                <Box
                                                                    style={{
                                                                        flex: position.junior,
                                                                        height: "100%",
                                                                        backgroundColor:
                                                                            "#9db4fa",
                                                                        color: "#ffffff",
                                                                    }}
                                                                >
                                                                    {
                                                                        position.junior
                                                                    }
                                                                </Box>
                                                            )}
                                                            {!!position.senior && (
                                                                <Box
                                                                    style={{
                                                                        flex: position.senior,
                                                                        height: "100%",
                                                                        backgroundColor:
                                                                            "#6c8ff8",
                                                                        color: "#ffffff",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {
                                                                        position.senior
                                                                    }
                                                                </Box>
                                                            )}
                                                            {!!position.leader && (
                                                                <Box
                                                                    style={{
                                                                        flex: position.leader,
                                                                        height: "100%",
                                                                        backgroundColor:
                                                                            "#3c6af5",
                                                                    }}
                                                                >
                                                                    {
                                                                        position.leader
                                                                    }
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                );
                                        }
                                    )}

                                    <Box className={classes.legendWrap}>
                                        <Box className={classes.updated}>
                                            {new Date(
                                                company.updatedAt
                                            ).toLocaleDateString("ko-KR")}{" "}
                                            기준
                                        </Box>
                                        <Box
                                            className={
                                                classes.experienceRangesWrap
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.experienceRange
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        width: 8,
                                                        height: 8,
                                                        backgroundColor:
                                                            "#cdd9fd",
                                                        marginRight: 4,
                                                    }}
                                                ></Box>
                                                신입 (1년 미만)
                                            </Box>
                                            <Box
                                                className={
                                                    classes.experienceRange
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        width: 8,
                                                        height: 8,
                                                        backgroundColor:
                                                            "#9db4fa",
                                                        marginRight: 4,
                                                    }}
                                                ></Box>
                                                주니어 (1~4년)
                                            </Box>
                                            <Box
                                                className={
                                                    classes.experienceRange
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        width: 8,
                                                        height: 8,
                                                        backgroundColor:
                                                            "#6c8ff8",
                                                        marginRight: 4,
                                                    }}
                                                ></Box>
                                                시니어 (5~8년)
                                            </Box>
                                            <Box
                                                className={
                                                    classes.experienceRange
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        width: 8,
                                                        height: 8,
                                                        backgroundColor:
                                                            "#3c6af5",
                                                        marginRight: 4,
                                                    }}
                                                ></Box>
                                                리더 (9년 이상)
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box> */}

                                <Typography className={classes.devPosition}>
                                    개발팀 현황
                                </Typography>
                                <Box className={classes.devPositionTabWrap}>
                                    {company.devTeam?.positions?.map(
                                        (position, idx) => (
                                            <Box
                                                className={
                                                    selectedPosIdx === idx
                                                        ? classes.devPositionTabSelected
                                                        : classes.devPositionTab
                                                }
                                                key={idx}
                                                onClick={() =>
                                                    handlePositionClick(idx)
                                                }
                                            >
                                                {position.name}
                                            </Box>
                                        )
                                    )}
                                </Box>
                                {company.devTeam.positions?.map(
                                    (position, idx) => {
                                        if (
                                            position.total &&
                                            selectedPosIdx === idx
                                        )
                                            return (
                                                <Box
                                                    className={
                                                        classes.devPositionInfoWrap
                                                    }
                                                >
                                                    <Box
                                                        className={
                                                            classes.devPositionLevelWrap
                                                        }
                                                    >
                                                        <Box
                                                            className={
                                                                classes.devPositionSkillStackTitle
                                                            }
                                                        >
                                                            인원 구성
                                                        </Box>

                                                        <Box
                                                            key={idx}
                                                            className={
                                                                classes.devTeamPositionWrap
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.devTeamPositionName
                                                                }
                                                            >
                                                                {position.name}
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.devTeamPositionCount
                                                                }
                                                            >
                                                                총{" "}
                                                                {position.total}
                                                                명
                                                            </Typography>
                                                            <Box
                                                                className={
                                                                    classes.devTeamPositionYearsBar
                                                                }
                                                            >
                                                                {!!position.rookie && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.rookie,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#c7e0ff",
                                                                            color: "#ffffff",
                                                                        }}
                                                                    >
                                                                        {
                                                                            position.rookie
                                                                        }
                                                                    </Box>
                                                                )}
                                                                {!!position.junior && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.junior,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#4693ff",
                                                                            color: "#ffffff",
                                                                        }}
                                                                    >
                                                                        {
                                                                            position.junior
                                                                        }
                                                                    </Box>
                                                                )}
                                                                {!!position.senior && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.senior,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#0c46f2",
                                                                            color: "#ffffff",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {
                                                                            position.senior
                                                                        }
                                                                    </Box>
                                                                )}
                                                                {!!position.leader && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.leader,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#051c60",
                                                                        }}
                                                                    >
                                                                        {
                                                                            position.leader
                                                                        }
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            className={
                                                                classes.legendWrap
                                                            }
                                                        >
                                                            <Box
                                                                className={
                                                                    classes.updated
                                                                }
                                                            >
                                                                {new Date(
                                                                    company.updatedAt
                                                                ).toLocaleDateString(
                                                                    "ko-KR"
                                                                )}{" "}
                                                                기준
                                                            </Box>
                                                            <Box
                                                                className={
                                                                    classes.experienceRangesWrap
                                                                }
                                                            >
                                                                <Box
                                                                    className={
                                                                        classes.experienceRange
                                                                    }
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            width: 8,
                                                                            height: 8,
                                                                            backgroundColor:
                                                                                "#c7e0ff",
                                                                            marginRight: 4,
                                                                        }}
                                                                    ></Box>
                                                                    신입 (1년
                                                                    미만)
                                                                </Box>
                                                                <Box
                                                                    className={
                                                                        classes.experienceRange
                                                                    }
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            width: 8,
                                                                            height: 8,
                                                                            backgroundColor:
                                                                                "#4693ff",
                                                                            marginRight: 4,
                                                                        }}
                                                                    ></Box>
                                                                    주니어
                                                                    (1~4년)
                                                                </Box>
                                                                <Box
                                                                    className={
                                                                        classes.experienceRange
                                                                    }
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            width: 8,
                                                                            height: 8,
                                                                            backgroundColor:
                                                                                "#0c46f2",
                                                                            marginRight: 4,
                                                                        }}
                                                                    ></Box>
                                                                    시니어
                                                                    (5~8년)
                                                                </Box>
                                                                <Box
                                                                    className={
                                                                        classes.experienceRange
                                                                    }
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            width: 8,
                                                                            height: 8,
                                                                            backgroundColor:
                                                                                "#051c60",
                                                                            marginRight: 4,
                                                                        }}
                                                                    ></Box>
                                                                    리더 (9년
                                                                    이상)
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                    {position.skills?.length >
                                                        0 && (
                                                        <Box
                                                            className={
                                                                classes.devPositionSkillStackWrap
                                                            }
                                                        >
                                                            <Box
                                                                className={
                                                                    classes.devPositionSkillStackTitle
                                                                }
                                                            >
                                                                기술 스택
                                                            </Box>
                                                            <Box
                                                                className={
                                                                    classes.devPositionSkillStack
                                                                }
                                                            >
                                                                {position.skills?.map(
                                                                    (
                                                                        skill,
                                                                        idx
                                                                    ) => (
                                                                        <Box
                                                                            className={
                                                                                classes.devPositionSkill
                                                                            }
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    classes.devPositionSkillIcon
                                                                                }
                                                                                src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                                                                    skill
                                                                                )}/${deviconMap.get(
                                                                                    skill
                                                                                )}-${
                                                                                    devicon.find(
                                                                                        (
                                                                                            obj
                                                                                        ) =>
                                                                                            obj.name ===
                                                                                            deviconMap.get(
                                                                                                skill
                                                                                            )
                                                                                    )
                                                                                        ?.versions
                                                                                        .svg[0]
                                                                                }.svg`}
                                                                                onError={
                                                                                    handleImgError
                                                                                }
                                                                            />
                                                                            {
                                                                                skill
                                                                            }
                                                                        </Box>
                                                                    )
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    {position.link?.url && (
                                                        <Box
                                                            className={
                                                                classes.PositionReferenceWrap
                                                            }
                                                        >
                                                            <Box
                                                                className={
                                                                    classes.PositionReferenceTitle
                                                                }
                                                            >
                                                                참고 링크
                                                            </Box>

                                                            <Typography
                                                                className={
                                                                    classes.PositionReference
                                                                }
                                                                onClick={() =>
                                                                    openLinkNewTab(
                                                                        position
                                                                            .link
                                                                            .url
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    position
                                                                        .link
                                                                        .label
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            );
                                    }
                                )}

                                {/* <Typography className={classes.skillStack}>
                                    기술 스택
                                </Typography>
                                <Box className={classes.hardSkillsWrap}>
                                    {company.hardSkills
                                        ?.filter(
                                            (obj, idx, self) =>
                                                idx ===
                                                self.findIndex(
                                                    (t) => t.name === obj.name
                                                )
                                        )
                                        .map((hardSkill, idx) => (
                                            <Box
                                                key={idx}
                                                className={classes.hardSkillBox}
                                            >
                                                {hardSkill.url && (
                                                    <img
                                                        className={
                                                            classes.hardSkillIcon
                                                        }
                                                        alt="skill"
                                                    ></img>
                                                )}
                                                <Typography
                                                    className={
                                                        classes.hardSkill
                                                    }
                                                >
                                                    {hardSkill.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                </Box> */}

                                <Typography className={classes.devStyleTitle}>
                                    개발 방식
                                </Typography>
                                <Box className={classes.devStyleWrap}>
                                    {company.devStyles?.map((devStyle, idx) => (
                                        <Box key={idx}>
                                            <Box
                                                //aria-describedby={popId}
                                                className={
                                                    devStyle.description
                                                        ? classes.devStyleBox
                                                        : classes.devStyleBoxWithoutDesc
                                                }
                                                onClick={
                                                    devStyle.description
                                                        ? handleClick
                                                        : undefined
                                                }
                                            >
                                                {devStyle.name}
                                            </Box>
                                        </Box>
                                    ))}
                                    <Popover
                                        id={popId}
                                        className={classes.popover}
                                        classes={{
                                            paper: classes.popoverPaper,
                                        }}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                    >
                                        <Typography
                                            className={classes.devStyleDesc}
                                        >
                                            {devStyleDesc}
                                        </Typography>
                                    </Popover>
                                </Box>
                                <Typography className={classes.subTitle}>
                                    개발 서비스
                                </Typography>
                                <Box className={classes.servicesWrap}>
                                    {company.services?.map((service, idx) => (
                                        <Box
                                            key={idx}
                                            className={classes.serviceWrap}
                                        >
                                            <Box
                                                className={
                                                    classes.serviceInfoWrap
                                                }
                                            >
                                                <img
                                                    src={service.logo}
                                                    className={classes.logo}
                                                    alt="service logo"
                                                ></img>
                                                <Box>
                                                    <Typography
                                                        className={classes.name}
                                                    >
                                                        {service.name}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.service
                                                        }
                                                    >
                                                        {service.description}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                className={
                                                    classes.serviceLinkWrap
                                                }
                                                onClick={() =>
                                                    openLinkNewTab(
                                                        company.serviceLink ??
                                                            service.url
                                                    )
                                                }
                                            >
                                                {!isMobile && (
                                                    <Typography
                                                        className={
                                                            classes.detail
                                                        }
                                                    >
                                                        자세히 보기
                                                    </Typography>
                                                )}
                                                <img
                                                    src={require("assets/icon-arrow-right-24.png")}
                                                    srcSet={`${require("assets/icon-arrow-right-24@2x.png")} 2x, ${require("assets/icon-arrow-right-24@3x.png")} 3x`}
                                                    className={
                                                        classes.arrowIcon
                                                    }
                                                    alt="arrow"
                                                ></img>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Divider />
                                <Typography
                                    className={classes.companyIntroTitle}
                                >
                                    기업 소개
                                </Typography>
                                <Typography className={classes.companyIntro}>
                                    {company.description}
                                </Typography>
                                <Typography className={classes.subTitle}>
                                    복지
                                </Typography>
                                <Grid container spacing={1}>
                                    {company.welfares?.map((perk, idx) => (
                                        <Grid key={idx} item xs={12} sm={6}>
                                            <Box className={classes.perkBox}>
                                                {perk.image && (
                                                    <img
                                                        className={
                                                            classes.perkImg
                                                        }
                                                        alt="perk"
                                                    ></img>
                                                )}
                                                <Typography
                                                    className={classes.perk}
                                                >
                                                    {perk.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box className={classes.companyInfoWrap}>
                                    <Box className={classes.companyInfoBox}>
                                        <Typography
                                            className={classes.companyInfoTitle}
                                        >
                                            산업
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.companyInfoContent
                                            }
                                        >
                                            {company.category}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.companyInfoBox}>
                                        <Typography
                                            className={classes.companyInfoTitle}
                                        >
                                            사원수
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.companyInfoContent
                                            }
                                        >
                                            {company.numOfEmployees}명
                                        </Typography>
                                    </Box>
                                    <Box className={classes.companyInfoBox}>
                                        <Typography
                                            className={classes.companyInfoTitle}
                                        >
                                            설립연도
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.companyInfoContent
                                            }
                                        >
                                            {new Date(
                                                company.since
                                            ).getUTCFullYear()}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography className={classes.disclaimer}>
                                    기업정보는 사업자등록번호에 기재된 정보와
                                    동일하게 관리되며 기업회원이 입력한 정보를
                                    활용하고 있습니다.
                                    {!isMobile && <br />}
                                    실시간으로 반영되는 정보가 아니므로 현재
                                    기업 정보와 차이가 있을 수 있는 점 참고
                                    부탁드립니다.
                                </Typography>
                            </>
                        ) : (
                            <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={1000}
                                animation="wave"
                            />
                        )}
                    </Box>
                    {!isMobile && (
                        <Box className={classes.rightSection}>
                            {company ? (
                                <Box className={classes.actionCard}>
                                    <Box className={classes.infoWrap}>
                                        <img
                                            src={company.logo?.url}
                                            className={classes.logo}
                                            alt="logo"
                                        ></img>
                                        <Box>
                                            <Typography
                                                className={classes.name}
                                            >
                                                {company.name}
                                            </Typography>
                                            <Typography
                                                className={classes.service}
                                            >
                                                {company.services[0].name}
                                                <span className={classes.ceo}>
                                                    {" "}
                                                    | {company.ceo}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.hiringWrap}>
                                        <Typography
                                            className={classes.hiringTitle}
                                        >
                                            채용중
                                        </Typography>
                                        {company.hirings?.map(
                                            (position, idx) => (
                                                <Box
                                                    key={idx}
                                                    className={
                                                        classes.positionBox
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.position
                                                        }
                                                    >
                                                        {`${position.position} ·` +
                                                            expRangeStr(
                                                                position.lowYears,
                                                                position.highYears
                                                            )}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.salary
                                                        }
                                                    >
                                                        {salaryRangeStr(
                                                            position.lowSalary,
                                                            position.highSalary
                                                        )}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                    <Box className={classes.addressWrap}>
                                        <Box className={classes.addressBox}>
                                            <Typography
                                                className={classes.addressTitle}
                                            >
                                                회사 위치
                                            </Typography>
                                            <Typography
                                                className={classes.address}
                                            >
                                                {company.address1}
                                            </Typography>
                                        </Box>
                                        {/* <Typography className={classes.mapBtn}>
                                            위치보기
                                        </Typography> */}
                                        <Divider />
                                    </Box>
                                    <Box className={classes.actionsWrap}>
                                        <Typography
                                            className={classes.question}
                                        >
                                            {company.name}에 관심이 있으신가요?
                                        </Typography>
                                        <Box
                                            className={classes.applyBtn}
                                            onClick={() => {
                                                history.push(
                                                    `/talent-application/${company.name}${utm}`
                                                );
                                            }}
                                        >
                                            <Typography
                                                className={classes.btnSubTitle}
                                            >
                                                탤런트엑스 가입하고
                                            </Typography>
                                            <Typography
                                                className={classes.btnTitle}
                                            >
                                                제안 받아보기
                                            </Typography>
                                        </Box>
                                        <Box></Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Skeleton
                                    variant="rect"
                                    animation="wave"
                                    height={1000}
                                    style={{ marginLeft: 20, marginRight: 20 }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Container>
            {isMobile ? (
                <ServiceFooter backgroudColor="#2c2c2c" />
            ) : (
                <ServiceFooterD backgroudColor="#2c2c2c" />
            )}
        </React.Fragment>
    );
};

export default CompanyDetail;
