import React, { useState, useEffect } from "react";

import { Container, AppBar, Toolbar, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ServiceFooter } from "pages/components";
import useScroll from "hooks/useScroll";
import { useObserver } from "hooks/useObserver";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroudColor: "#ffffff",
    },
    index: {
        position: "fixed",
        zIndex: 1,
        //bottom: 30,
        left: 33,
    },
    indexItem: (props) => ({
        fontSize: 10,
        fontWeight: "bold",
        color: "#ffffff",
        opacity: 0.2,
    }),
    indexItemFocused: (props) => ({
        fontSize: 10,
        fontWeight: "bold",
        color: "#222222",
        opacity: 1,
    }),
    scrollTopBtn: (props) => ({
        position: "fixed",
        zIndex: 1,
        bottom: 30,
        right: 33,
        fontSize: 16,
        fontWeight: "bold",
        color: 1 < props.index && props.index < 6 ? "#222222" : "#ffffff",
        visibility: props.index === 1 && "hidden",
    }),
    header: {
        width: "100%",
        height: 760,
        maxWidth: 444,
        backgroundImage: `url(${require("assets/img-people.png")})`,
        backgroundImage: `image-set(url(${require("assets/img-people.png")}) 1x, url(${require("assets/img-people@2x.png")}) 2x, url(${require("assets/img-people@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        textAlign: "center",
        paddingTop: 136,
    },
    mainTitle: {
        //display: "block",
        fontSize: 32,
        fontWeight: "bold",
        color: "#ffffff",
        textShadow: "0 2px 12px rgba(101, 101, 101, 0.33)",
        lineHeight: 1.38,
        //marginTop: 140,
        //marginBottom: 200,
    },
    body: {
        padding: "56px 32px 80px",
    },
    title: {
        fontSize: 32,
        fontWeight: "bold",
        color: "#111111",
        marginTop: 68,
        lineHeight: 1.38,
    },
    buildingsImg: {
        width: "100%",
        marginTop: 40,
        marginBottom: 28,
    },
    subTitle: {
        fontSize: 24,
        fontWeight: "bold",
        color: "#111111",
        marginTop: 52,
        lineHeight: 1.38,
    },
    content: {
        fontSize: 18,
        fontWeight: 500,
        color: "#111111",
        marginTop: 24,
        marginBottom: 24,
    },
    unemploymentImg: {
        width: "100%",
    },
    caption: {
        fontSize: 12,
        fontWeight: "normal",
        color: "#999999",
        marginTop: 20,
        marginBottom: 20,
    },
    ceo: {
        position: "relative",
        width: "100%",
        height: 675,
        maxWidth: 444,
        backgroundImage: `url(${require("assets/img-ceo.png")})`,
        backgroundImage: `image-set(url(${require("assets/img-ceo.png")}) 1x, url(${require("assets/img-ceo@2x.png")}) 2x, url(${require("assets/img-ceo@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    ceoMsg: {
        position: "absolute",
        left: 32,
        bottom: 100,
        fontSize: 18,
        fontWeight: "bold",
        color: "#ffffff",
        textShadow: "0 2px 8px rgba(75, 75, 75, 0.48)",
    },
    ceoProfile: {
        position: "absolute",
        left: 32,
        bottom: 60,
        fontSize: 16,
        fontWeight: 300,
        color: "#ffffff",
    },
    ealryLeaveImg: {
        width: "100%",
    },
    pointBox: {
        textAlign: "center",
        paddingTop: 64,
        paddingBottom: 64,
    },
    point: {
        fontSize: 24,
        fontWeight: 500,
        color: "#111111",
        lineHeight: 1.67,
        marginTop: 20,
        marginBottom: 20,
    },
    appBox: {
        backgroundColor: "#f4f4f4",
        textAlign: "left",
        paddingTop: 48,
        paddingBottom: 64,
    },
    appTitle: {
        fontSize: 16,
        fontWeight: 300,
        color: "#999999",
        marginLeft: 32,
    },
    appDesc: {
        fontSize: 24,
        fontWeight: "bold",
        color: "#222222",
        lineHeight: 1.33,
        marginLeft: 32,
        marginTop: 4,
        marginBottom: 32,
    },
    appWrap: {
        position: "relative",
    },
    appImg: {
        width: "100%",
    },
    appBtn: {
        position: "absolute",
        left: 32,
        top: 0,
        backgroundColor: "#222222",
        width: 165,
        height: 48,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 16px",
    },
    appBtnTitle: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 500,
    },
    appBar: (props) => ({
        backgroundColor:
            props.scroll.scrollY === -0
                ? "transparent"
                : theme.palette.background.paper,
        height: 56,
        maxWidth: 444,
        left: "50%",
        transform: "translate(-50%, 0)",
    }),
    toolBar: {
        minHeight: 56,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    identity: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexGrow: 1,
    },
    logo: {
        position: "fixed",
        zIndex: 1,
        top: 35,
        left: "50%",
        transform: "translate(-50%, 0)",
    },
}));

const contents = [
    "목차",
    "미션",
    "일자리 미스매치",
    "정보의 불균형",
    "문제의 중요성",
    "우리의 목표",
    "우리가 하고 있는 일",
];

const CompanyIdentity = (props) => {
    const scroll = useScroll();
    const [index, setIndex] = useState(1);
    const target = [];
    target[1] = useObserver(setIndex);
    target[2] = useObserver(setIndex);
    target[3] = useObserver(setIndex);
    target[4] = useObserver(setIndex);
    target[5] = useObserver(setIndex);
    target[6] = useObserver(setIndex);

    useEffect(() => {
        console.log(index);
    }, [index]);

    const classes = useStyles({ index, scroll });

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <Container maxWidth="xs" className={classes.container} disableGutters>
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar classes={{ root: classes.toolBar }} disableGutters>
                    {scroll.scrollY === -0 ? (
                        <img
                            src={require("assets/img-company-logo-white.png")}
                            alt="ci"
                            //className={classes.logo}
                            srcSet={`${require("assets/img-company-logo-white@2x.png")} 2x, ${require("assets/img-company-logo-white@3x.png")} 3x`}
                        ></img>
                    ) : (
                        <img
                            src={require("assets/img-company-logo-black.png")}
                            alt="ci"
                            //className={classes.logo}
                            srcSet={`${require("assets/img-company-logo-black@2x.png")} 2x, ${require("assets/img-company-logo-black@3x.png")} 3x`}
                        ></img>
                    )}
                    {/* <Box className={classes.index}>
                        <Typography className={classes.indexItemFocused}>
                            &#9724; {contents[index]} ({index}/
                            {contents.length - 1})
                        </Typography>
                    </Box> */}
                </Toolbar>
            </AppBar>
            {/* <Box className={classes.index}>
                <Typography className={classes.indexItemFocused}>
                    &#9724; {contents[index]} ({index}/{contents.length - 1})
                </Typography>
            </Box>
            <Box
                className={classes.scrollTopBtn}
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }}
            >
                맨위로 &#8593;
            </Box> */}
            <Box className={classes.header}>
                <Typography className={classes.mainTitle} id="1" {...target[1]}>
                    사람과 사람을
                    <br />
                    연결해 새로운 가치를
                    <br />
                    만들어갑니다
                </Typography>
                {/* <img
                    src={require("assets/icon-nav-chevron-down-40.png")}
                    srcSet={`${require("assets/icon-nav-chevron-down-40@2x.png")} 2x, ${require("assets/icon-nav-chevron-down-40@3x.png")} 3x`}
                    alt="arrow down"
                    className={classes.arrowDown}
                ></img> */}
            </Box>
            <Box className={classes.body}>
                <Typography className={classes.title} id="2" {...target[2]}>
                    탤런티넘은
                    <br />
                    고용 문제를 해결하고
                    <br />
                    양질의 일자리를
                    <br />
                    창출합니다
                </Typography>
                <img
                    src={require("assets/img-buildings.png")}
                    srcSet={`${require("assets/img-buildings@2x.png")} 2x, ${require("assets/img-buildings@3x.png")} 3x`}
                    alt="buildings"
                    className={classes.buildingsImg}
                ></img>
                <Typography className={classes.subTitle}>
                    대한민국의 고용 문제
                </Typography>
                <Typography className={classes.content}>
                    2020년에 대한민국은 평균 4%의 실업률(15~64세)을
                    기록했습니다. 수치상으로 보면 OECD 국가 중 상당히 양호한
                    편이죠. 근데 참 아이러니합니다. 실업률은 낮은데 실제 우리가
                    체감하는 실업률은 그보다 훨씬 높으니까요.
                </Typography>
                <Typography className={classes.content}>
                    기업에선 갈수록 지원자들의 스펙은 높아지는데 실무 기술과
                    숙련도가 부족한 경우가 많아 채용에 어려움이 있다고 말합니다.
                    반면에 구직자들은 적절한 보상을 받을 수 있는 좋은 일자리를
                    찾지 못해 교육기관에 계속 머물거나 구직활동을 아예
                    포기하기까지 합니다.
                </Typography>
                <img
                    src={require("assets/img-unemployment.png")}
                    srcSet={`${require("assets/img-unemployment@2x.png")} 2x, ${require("assets/img-unemployment@3x.png")} 3x`}
                    alt="unemployment"
                    className={classes.unemploymentImg}
                ></img>
                <Typography className={classes.caption}>
                    통계청이 지난해 16일 공개한 ‘11월 고용동향’에 따르면
                    235만명의 인구가 ‘그냥 쉬었다’라고 응답했습니다.
                </Typography>
                <Typography className={classes.content}>
                    왜 이런 기현상이 벌어지는 걸까요? 대게는 기업과 구직자가
                    생각하는 보상의 차이가 큰 것 때문이라고 많이 생각합니다.
                    하지만, 단순히 보상만이 전체 현상의 원인이라고 볼 수
                    없습니다.
                </Typography>
                <Typography className={classes.subTitle} id="3" {...target[3]}>
                    정보의 불균형
                </Typography>
                <Typography className={classes.content}>
                    기본적으로 일자리 미스매치의 원인에는 기업과 구직자간의
                    보상의 차이도 분명 있지만 더 큰 원인 중 하나는 '정보의
                    불균형'입니다. 구직자는 나와 잘 맞고 내가 일하기 좋은 회사가
                    어디 있는지 모르는 반면 기업은 어떤 사람이 우리 회사와 잘
                    맞고 그런 인재가 어디 있는지 모릅니다. 서로가 서로를 찾기
                    힘드니 매치가 일어나기 어려울 수밖에 없는 거죠.
                </Typography>
                <Typography className={classes.content}>
                    특히나 구직자들은 구직 활동을 할 때 기업의 비전이나 서비스,
                    급여와 복리후생 등 기업의 상세한 정보를 알고 싶어 하는데
                    충분한 정보가 제공되지 않아 가고 싶은 기업임에도 지원을
                    망설인 적이 많다고 이야기해요. 취업 관련 사이트에 채용
                    공고를 띄워도 지원자가 빠른 시일 내 발생하지 않는 것도 바로
                    이 ‘정보의 불균형’ 때문에 발생합니다.
                </Typography>
                <Typography className={classes.content}>
                    그럼에도 불구하고 채용 사이트들은 여전히 유료 채용 공고
                    서비스를 제공하는 것에 머물러 있습니다. 그래서 기업들은
                    자구책으로 내부 추천 프로그램을 운영하거나 전문 헤드헌터
                    서비스를 이용하기도 합니다.
                </Typography>
            </Box>
            <Box className={classes.ceo}>
                <Typography className={classes.ceoMsg}>
                    고용 문제가 해결되면 우리 사회는 선순환
                    <br />
                    되어 모두 함께 성장할 수 있습니다.
                </Typography>
                <Typography className={classes.ceoProfile}>
                    CEO 한신환
                </Typography>
            </Box>
            <Box className={classes.body}>
                <Typography className={classes.subTitle} id="4" {...target[4]}>
                    일자리 미스매치는
                    <br />
                    반드시 해결해야 하는
                    <br />
                    문제입니다
                </Typography>
                <Typography className={classes.content}>
                    2019년에 1년 미만 신입사원의 퇴사율은 48.6%를 기록했습니다.
                    그럼 경력직을 뽑으면 되는 거 아니냐구요? 채용 포털 조사
                    결과에 따르면 직장인 2명 중 1명은 이직 실패로 퇴사한 경험이
                    있다고 이야기 합니다.
                </Typography>
                <Typography className={classes.content}>
                    1년차 신입사원의 가장 큰 퇴사 사유는 ‘적성과 안맞는
                    직무(59%)’였고 이직자의 퇴사 사유는 ‘업무 내용이 예상과
                    다름(52%)’이었습니다. 사전에 많은 고려를 하고 회사에
                    들어갔음에도 불구하고 그들은 정보 불균형에 따른 업무
                    미스매치로 퇴사를 선택합니다. 결국, 경력직을 채용하나
                    신입사원을 채용하나 얼마 지나지 않아 퇴사할 확률이 높다는
                    것입니다.
                </Typography>
                <img
                    src={require("assets/img-ealry-leave.png")}
                    srcSet={`${require("assets/img-ealry-leave@2x.png")} 2x, ${require("assets/img-ealry-leave@3x.png")} 3x`}
                    alt="early leave"
                    className={classes.ealryLeaveImg}
                ></img>
                <Typography className={classes.caption}>
                    채용 포털 조사결과에 따르면 1년 이내에 퇴사한 신입사원이
                    있었냐는 질문에 중소기업의 77.3%가 ‘있다’고 답했다.
                </Typography>
                <Typography className={classes.content}>
                    현재 상태가 지속되면 대한민국은 실업률의 증가, 양질의 일자리
                    감소, 소득 불평등의 심화, 근로빈곤 등 많은 문제를 마주할
                    우려가 있습니다. 이에 고용 시장의 일자리 미스매치를 해결하면
                    우리 사회의 많은 갈등과 불안을 해소하고 여러 고민들을 해결할
                    수 있습니다.
                </Typography>
                <Box className={classes.pointBox} id="5" {...target[5]}>
                    <img
                        src={require("assets/img-check-point.png")}
                        srcSet={`${require("assets/img-check-point@2x.png")} 2x, ${require("assets/img-check-point@3x.png")} 3x`}
                        alt="check point"
                    ></img>
                    <Typography className={classes.point}>
                        데이터 기술을 활용해
                        <br />
                        누구나 자신에게 알맞은,
                    </Typography>
                    <Typography className={classes.point}>
                        최적의 일자리를
                        <br />
                        찾을 수 있게
                    </Typography>
                </Box>
                <Typography className={classes.content}>
                    “누구나 자신의 시야의 한계를 세계의 한계로 간주한다.”라는
                    말을 들어보셨나요? 독일의 철학자 슈펜하우어의 명언입니다.
                </Typography>
                <Typography className={classes.content}>
                    저희는 데이터 기술을 활용해 대중의 시야의 한계를 넘어 누구나
                    최적의 일자리를 손쉽게 찾을 수 있는 해결책을 제시하려합니다.
                </Typography>
                <Typography className={classes.subTitle} id="6" {...target[6]}>
                    알맞은 연결을 통해
                    <br />
                    사람과 기업을 성장시킵니다
                </Typography>
                <Typography className={classes.content}>
                    탤런티넘은 정보의 불균형을 해소하여 사람과 기업을 알맞게
                    연결하고 성장시키는 것을 핵심가치로 여기는 기업입니다.
                </Typography>
                <Typography className={classes.content}>
                    누구나 알맞은 일자리와 인재를 찾을 수 있는 데이터 기반 추천
                    플랫폼을 만들어 고용 문제를 효과적으로 해결하고 함께
                    성장해나가는 사회를 만들어 나가겠습니다.
                </Typography>
            </Box>
            <Box className={classes.appBox}>
                <Typography className={classes.appTitle}>탤런트엑스</Typography>
                <Typography className={classes.appDesc}>
                    누구나 손쉽게,
                    <br />
                    알맞은 일자리와 인재를
                    <br />
                    찾을 수 있게.
                </Typography>
                <Box className={classes.appWrap}>
                    <img
                        src={require("assets/img-talentx-app.png")}
                        srcSet={`${require("assets/img-talentx-app@2x.png")} 2x, ${require("assets/img-talentx-app@3x.png")} 3x`}
                        alt="talentx app"
                        className={classes.appImg}
                    ></img>
                    <Box
                        className={classes.appBtn}
                        onClick={() => {
                            props.history.push("/");
                        }}
                    >
                        <Typography className={classes.appBtnTitle}>
                            서비스 보기
                        </Typography>
                        <img
                            src={require("assets/icon-nav-arrow-right-20.png")}
                            srcSet={`${require("assets/icon-nav-arrow-right-20@2x.png")} 2x, ${require("assets/icon-nav-arrow-right-20@3x.png")} 3x`}
                        ></img>
                    </Box>
                </Box>
            </Box>
            <ServiceFooter backgroudColor="#151515" />
        </Container>
    );
};

export default CompanyIdentity;
