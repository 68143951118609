import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
	Box,
	Typography,
	Container,
	Paper,
	Tabs,
	Tab,
	Divider,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import { talentData as content } from "./dataTalentX3";
import { useStyles } from "./styleTalentContent3";

const TalentContent = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	console.log("isMobile :", isMobile);

	const classes = useStyles();

	const [step, setStep] = useState(0);
	const handleStepChange = (event, newStep) => {
		setStep(newStep);
	};

	const [open, setOpen] = useState(new Map());
	const handleAnswerOpen = (key, value) => (event) => {
		setOpen({ ...open, [key]: value });
	};

	function TXImage(props) {
		const { imgName, ...rest } = props;
		return (
			<img
				src={require(`assets/${imgName}.png`)}
				srcSet={`${require(`assets/${imgName}@2x.png`)} 2x, ${require(`assets/${imgName}@3x.png`)} 3x`}
				alt={imgName}
				{...rest}
			></img>
		);
	}

	return (
		<React.Fragment>
			<Container maxWidth="xs" disableGutters>
				<Box className={classes.body}>
					<Box className={classes.values}>
						<Typography className={classes.proposition}>
							{content.proposition}
						</Typography>
						{content.values.map((value, idx) => (
							<Box key={idx} className={classes.value}>
								<img
									src={value.image}
									srcSet={`${value.image2x} 2x, ${value.image3x} 3x`}
									alt="value"
									className={classes.valueImg}
								></img>
								<Typography className={classes.valueTitle}>
									{value.title}
								</Typography>
								<Typography className={classes.valueDesc}>
									{value.desc}
								</Typography>
							</Box>
						))}
					</Box>
					<Box className={classes.reviewSection}>
						<Typography className={classes.reviewTitle}>
							{content.talentReview.title}
						</Typography>
						<Box className={classes.reviewCardWindow}>
							{content.talentReview.reviews.map((review, idx) => (
								<Box key={idx} className={classes.reviewCard}>
									<Box className={classes.reviewRating}>
										{Array.apply(
											null,
											Array(review.rating)
										).map((star, idx) => (
											<TXImage
												key={idx}
												imgName="icon-star-24"
												className={classes.reviewStar}
											/>
										))}
										{Array.apply(
											null,
											Array(5 - review.rating)
										).map((star, idx) => (
											<TXImage
												key={idx}
												imgName="icon-unstar-24"
												className={classes.reviewStar}
											/>
										))}
									</Box>
									<Typography
										className={classes.reviewRatingDesc}
									>
										{review.ratingDesc}
									</Typography>
									<Typography
										className={classes.reviewContent}
									>
										{review.content}
									</Typography>
									<TXImage
										imgName={review.clientLogo}
										className={classes.reviewClientLogo}
									/>
									<Typography
										className={classes.reviewClient}
									>
										{review.client}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
					<Box className={classes.benefits}>
						{content.benefits.map((benefit, idx) => (
							<Box key={idx} className={classes.benefit}>
								<Typography className={classes.benefitKeyword}>
									{benefit.keyword}
								</Typography>
								<Typography className={classes.benefitTitle}>
									{benefit.title}
								</Typography>
								<Box className={classes.benefitImgIconWrap}>
									<img
										src={benefit.image}
										srcSet={`${benefit.image2x} 2x, ${benefit.image3x} 3x`}
										alt="benefit"
										className={classes.benefitImg}
									></img>
									{idx === 0 && (
										<img
											src={benefit.icon}
											srcSet={`${benefit.icon2x} 2x, ${benefit.icon3x} 3x`}
											alt="arrow"
											className={classes.benefitIcon}
										/>
									)}
								</Box>
								{idx === 1 ? (
									<Typography
										className={classes.benefitDesc0}
									>
										{benefit.desc[idx]}
									</Typography>
								) : (
									benefit.desc.map((item, subIdx) => (
										<Typography
											key={subIdx}
											className={classes.benefitDesc1}
										>
											{item}
										</Typography>
									))
								)}
								{/* {idx === 0 && (
                                    <>
                                        <Typography className={classes.message}>
                                            {benefit.message}
                                        </Typography>
                                        <img
                                            src={require("assets/icon-nav-chevron-down-24.png")}
                                            alt="chevron"
                                        />
                                    </>
                                )} */}
							</Box>
						))}
					</Box>
					<Box className={classes.manager}>
						<Typography className={classes.managerKeyword}>
							{content.manager.keyword}
						</Typography>
						<Typography className={classes.managerTitle}>
							{content.manager.title}
						</Typography>
						<Box className={classes.managerImgIconWrap}>
							<img
								src={content.manager.image}
								srcSet={`${content.manager.image2x} 2x, ${content.manager.image3x} 3x`}
								alt="manager"
								className={classes.managerImg}
							></img>
						</Box>
						<Box className={classes.progressBar}>
							<Box className={classes.line}></Box>
							<Typography className={classes.progress}>
								1
							</Typography>
							<Typography className={classes.progress}>
								2
							</Typography>
							<Typography className={classes.progress}>
								3
							</Typography>
						</Box>
						<Box>
							<Typography className={classes.progressLabel}>
								{content.manager.desc[0]}
							</Typography>
							<Typography className={classes.progressLabel}>
								{content.manager.desc[1]}
							</Typography>
							<Typography className={classes.progressLabel}>
								{content.manager.desc[2]}
							</Typography>
						</Box>
					</Box>
					{/* <Box className={classes.featureBox}>
                        <Typography className={classes.featureBoxTitle}>
                            {content.features[0].title}
                        </Typography>
                        <Typography className={classes.featureBoxMsg}>
                            {content.features[0].message}
                        </Typography>
                        <img
                            src={content.features[0].image}
                            srcSet={`${content.features[0].image2x} 2x, ${content.features[0].image3x} 3x`}
                            alt="feature"
                            className={classes.featureImg}
                        />
                    </Box>
                    <Box className={classes.features}>
                        <Typography className={classes.featureTitle}>
                            {content.features[1].title}
                        </Typography>
                        <img
                            src={content.features[1].image}
                            srcSet={`${content.features[1].image2x} 2x, ${content.features[1].image3x} 3x`}
                            alt="feature"
                            className={classes.featureImg}
                        />
                    </Box>
                    <Box>
                        <Typography className={classes.featureSubtitle}>
                            {content.features[1].subtitle}
                        </Typography>
                        <Typography className={classes.featureMsg}>
                            {content.features[1].message}
                        </Typography>
                    </Box> */}
					<Box className={classes.steps}>
						<Typography className={classes.stepTitle}>
							{content.steps[step].title}
						</Typography>
						<img
							src={content.steps[step].image}
							srcSet={`${content.steps[step].image2x} 2x, ${content.steps[step].image3x} 3x`}
							alt="feaure"
							className={classes.stepImg}
						/>
					</Box>
					<Paper className={classes.tabs} elevation={0} square>
						<Tabs
							value={step}
							onChange={handleStepChange}
							centered
							classes={{
								root: classes.newTabs,
								indicator: classes.newIndicator,
							}}
						>
							<Tab
								label="받은 제안"
								classes={{ root: classes.newTab }}
							/>
							<Tab
								label="일정 변경"
								classes={{ root: classes.newTab }}
							/>
							<Tab
								label="면접 알림"
								classes={{ root: classes.newTab }}
							/>
						</Tabs>
					</Paper>
					<Box>
						<Typography className={classes.stepMsg}>
							{content.steps[step].message}
						</Typography>
					</Box>

					{/* <Box className={classes.qnaSection}>
                        <Typography className={classes.qnaSectionTitle}>
                            {content.qna.title}
                        </Typography>
                        {content.qna.faqs.map((faq, idx) => (
                            <Box key={idx} className={classes.qnaBox}>
                                <Box className={classes.questionBox}>
                                    <img
                                        src={require("assets/question-mark.png")}
                                        alt="question"
                                    />
                                    <span className={classes.question}>
                                        {faq.question}
                                    </span>
                                    <img
                                        src={
                                            open[idx]
                                                ? require("assets/icon-chevron-up.png")
                                                : require("assets/icon-nav-chevron-down-24.png")
                                        }
                                        alt="close"
                                        style={{ width: 20, hieght: 20 }}
                                        onClick={handleAnswerOpen(
                                            idx,
                                            !open[idx]
                                        )}
                                    />
                                </Box>
                                {!open[idx] && (
                                    <Divider className={classes.divider} />
                                )}
                                <Box
                                    className={clsx(classes.answerBox, {
                                        [classes.answerBoxClosed]: !open[idx],
                                    })}
                                >
                                    {faq.html ? (
                                        <Typography
                                            dangerouslySetInnerHTML={faq.html}
                                            className={classes.answer}
                                        ></Typography>
                                    ) : (
                                        <Typography className={classes.answer}>
                                            {faq.answer}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Box> */}
				</Box>
				{/* <RouterLink
                    to="/talent-membership"
                    className={classes.fixedButtonRouteLink}
                >
                    <Box className={classes.fixedButton}>
                        <Typography className={classes.fixedButtonName}>
                            개발자 멤버십 신청
                        </Typography>
                        <Typography className={classes.fixedButtonDesc}>
                            이직 성공 시 로열티 50만원!
                        </Typography>
                    </Box>
                </RouterLink> */}
			</Container>
		</React.Fragment>
	);
};

export default TalentContent;
