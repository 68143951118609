import { firestore } from "apis/firebase";

export const uploadAllApplicants = (applicants) => {
    console.log(applicants[0]);
    applicants.slice(0, 10).forEach((applicant) => {
        let positionTags = [];
        if (applicant.first_position)
            positionTags.push(applicant.first_position);
        if (applicant.second_position)
            positionTags.push(applicant.second_position);
        if (applicant.third_position)
            positionTags.push(applicant.third_position);

        let skillTags = [];
        if (applicant.first_skill && applicant.first_skill !== "미제출")
            skillTags.push(applicant.first_skill);
        if (applicant.second_skill && applicant.second_skill !== "미제출")
            skillTags.push(applicant.second_skill);
        if (applicant.third_skill && applicant.third_skill !== "미제출")
            skillTags.push(applicant.third_skill);
        if (applicant.fourth_skill && applicant.fourth_skill !== "미제출")
            skillTags.push(applicant.fourth_skill);

        let companyTags = [];
        if (applicant.first_company && applicant.first_company !== "미제출")
            companyTags.push(applicant.first_company);
        if (applicant.second_company && applicant.second_company !== "미제출")
            companyTags.push(applicant.second_company);
        if (applicant.third_company && applicant.third_company !== "미제출")
            companyTags.push(applicant.third_company);

        // let testCompanyData =
        //     companyTestData[
        //         Math.floor(Math.random() * companyTestData.length) || 1
        //     ];

        firestore
            .collection("applicants")
            .add({
                uid: parseInt(applicant.id),
                name: applicant.name,
                // // birth: new Date(applicant.birth + " 00:00:00"),
                birth: new Date(applicant.birth),
                email: applicant.email,
                phone: applicant.phone,
                career_years: parseInt(applicant.career_years),
                positions: positionTags,
                first_position: applicant.first_position,
                second_position: applicant.second_position
                    ? applicant.second_position
                    : "미지정",
                third_position: applicant.third_position
                    ? applicant.third_position
                    : "미지정",
                skills: skillTags,
                first_skill: applicant.first_skill || "미제출",
                second_skill: applicant.second_skill || "미제출",
                third_skill: applicant.third_skill || "미제출",
                fourth_skill: applicant.fourth_skill || "미제출",
                companies: companyTags,
                first_company: applicant.first_company || "미제출",
                second_company: applicant.second_company || "미제출",
                third_company: applicant.third_company || "미제출",
                records: [
                    {
                        name: "블라인드지원",
                        date: new Date(applicant.created_at),
                        score: "해당없음",
                        level: "해당없음",
                        ranking: applicant.id,
                        result: "합격",
                    },
                    {
                        name: "코딩테스트",
                        date:
                            applicant.coding_test_date === "미응시"
                                ? null
                                : new Date(applicant.coding_test_date),
                        score:
                            applicant.coding_test_score === "미응시"
                                ? 0
                                : applicant.coding_test_score,
                        level:
                            applicant.coding_test_level === "미응시"
                                ? 0
                                : applicant.coding_test_level,
                        ranking:
                            applicant.coding_test_ranking === "미응시"
                                ? 0
                                : applicant.coding_test_ranking,
                        result: applicant.coding_test_result,
                    },
                    {
                        name: "인적성검사",
                        date:
                            applicant.aptitude_test_date === "미응시"
                                ? null
                                : new Date(applicant.aptitude_test_date),
                        score:
                            applicant.aptitude_test_score === "미응시"
                                ? 0
                                : applicant.aptitude_test_score,
                        level:
                            applicant.aptitude_test_level === "미응시"
                                ? 0
                                : applicant.aptitude_test_level,
                        ranking:
                            applicant.aptitude_test_ranking === "미응시"
                                ? 0
                                : applicant.aptitude_test_ranking,
                        result:
                            applicant.aptitude_test_result === "미응시"
                                ? "불합격"
                                : applicant.aptitude_test_result,
                    },
                    {
                        name: "IT포트폴리오",
                        date:
                            applicant.portfolio_submit_date === "미제출"
                                ? null
                                : new Date(applicant.portfolio_submit_date),
                        score:
                            applicant.portfolio_submit_date === "미제출"
                                ? 0
                                : "미정",
                        level:
                            applicant.portfolio_submit_date === "미제출"
                                ? 0
                                : "미정",
                        ranking:
                            applicant.portfolio_submit_date === "미제출"
                                ? 0
                                : "미정",
                        result: applicant.portfolio_result,
                    },
                ],
                portfolio: applicant.portfolio_link,
                result: applicant.final_result,
                extra_round: applicant.extra_round,
                created_at: new Date(applicant.created_at),
                tags: [...positionTags, ...skillTags, ...companyTags],
                position_match_rating: 0,
                skill_match_rating: 0,
                company_match_rating: 0,
                match_rating: 0,
                match: "",
            })
            .then(function (docRef) {
                console.log(
                    "Talent Profile Document written with ID: ",
                    docRef.id
                );
            })
            .catch(function (error) {
                console.log("Error adding document: ", error);
            });
    });
};

export const uploadAllCompanies = (openingJobs) => {
    console.log(openingJobs);

    const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const jobsByCompany = groupBy(openingJobs, "uid");

    console.log(jobsByCompany);

    for (const key in jobsByCompany) {
        console.log(jobsByCompany[key]);
        const positions = jobsByCompany[key].reduce(
            (jobsOfCompany, jobOfCompany) => {
                jobsOfCompany.push(jobOfCompany.position);
                return jobsOfCompany;
            },
            []
        );
        const skills = jobsByCompany[key].reduce(
            (skillsOfCompany, skillOfCompany) => {
                skillsOfCompany.push(
                    ...skillOfCompany.skills
                        .split(",")
                        .map((item) => item.trim())
                );
                return skillsOfCompany;
            },
            []
        );

        const uniqueSkills = skills.reduce(
            (uniqueItems, item) =>
                uniqueItems.includes(item)
                    ? uniqueItems
                    : [...uniqueItems, item],
            []
        );

        const tags = [...positions, ...uniqueSkills].slice(0, 20);

        const company = jobsByCompany[key][0];
        console.log(positions);
        firestore
            .collection("companies")
            .add({
                uid: parseInt(company.uid),
                name: company.name,
                business_number: company.business_number,
                tags: tags,
                skills: uniqueSkills,
            })
            .then(function (docRef) {
                console.log(
                    "Company Profile Document written with ID: ",
                    docRef.id
                );
            })
            .catch(function (error) {
                console.log("Error adding document: ", error);
            });
    }
};

export const uploadAllJobs = (openingJobs) => {
    console.log(openingJobs);

    openingJobs.forEach((job) => {
        let skillArray = job.skills.split(",").map((item) => item.trim());
        const tags = [job.position, ...skillArray].slice(0, 20);

        firestore
            .collection("jobs")
            .add({
                company_uid: parseInt(job.uid),
                company_name: job.name,
                name: job.name + " - " + job.position,
                position: job.position,
                skills: skillArray,
                tags: tags,
            })
            .then(function (docRef) {
                console.log("Job Document written with ID: ", docRef.id);
            })
            .catch(function (error) {
                console.log("Error adding job document: ", error);
            });
    });
};

// const uploadAllApplicants = (applicants) => {
//     applicants.slice(0, 3).forEach((applicant) =>
//         firestore
//             .collection("applicant")
//             .add({
//                 uid: parseInt(applicant.id),
//                 name: applicant.name,
//                 birth: new Date(applicant.birth + " 00:00:00"),
//                 email: applicant.email,
//                 phone: applicant.phone,
//                 positions: [
//                     applicant.first_position,
//                     applicant.second_position
//                         ? applicant.second_position
//                         : "미지정",
//                     applicant.third_position
//                         ? applicant.third_position
//                         : "미지정",
//                 ],
//                 testObject: [
//                     { test1: "value" },
//                     { test2: "value2" },
//                     { test3: "value3" },
//                 ],
//                 first_position: applicant.first_position,
//                 second_position: applicant.second_position
//                     ? applicant.second_position
//                     : "미지정",
//                 third_position: applicant.third_position
//                     ? applicant.third_position
//                     : "미지정",
//                 created_at: new Date(applicant.created_at),
//             })
//             .then(function (docRef) {
//                 console.log(
//                     "Talent Profile Document written with ID: ",
//                     docRef.id
//                 );
//                 applicant.records.forEach((record) => {
//                     firestore
//                         .collection("applicant")
//                         .doc(docRef.id)
//                         .collection("records")
//                         .add({
//                             test: record.test,
//                             score: record.score,
//                         })
//                         .then(function (docRef) {
//                             console.log(
//                                 "Talent History Document written with ID: ",
//                                 docRef.id
//                             );
//                         })
//                         .catch(function (error) {
//                             console.log("Error adding document: ", error);
//                         });
//                 });
//             })
//             .catch(function (error) {
//                 console.log("Error adding document: ", error);
//             })
//     );
// };
