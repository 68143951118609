import { makeStyles } from "@material-ui/core";
import { Texts, Colors } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    topPadding: {
        height: 56,
    },
    inner: {
        display: "flex",
        flexDirection: "row",
        paddingTop: 56,
        paddingBottom: 56,
    },
    leftSection: {
        flex: "1 0 300px",
    },
    leftSectionSkeleton: {
        width: 331,
        height: 1000,
        marginTop: 48,
        borderRadius: 12,
    },
    rightSectionSkeleton: {
        width: "100%",
        height: 1000,
        marginTop: 48,
        borderRadius: 12,
    },
    basicProfile: {
        width: 333,
        top: 56,
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        paddingTop: 48,
    },
    profileImgWrap: {
        position: "relative",
        height: 333,
        width: 333,
        backgroundColor: Colors.Gray50,
        borderRadius: 12,
        border: `1px solid ${Colors.Gray150}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    profileImgUploadBtn: {
        position: "absolute",
        bottom: 20,
        right: 20,
        height: 48,
        width: 48,
        borderRadius: 24,
        backgroundColor: Colors.Blue400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    profileImgPlaceholder: {
        ...Texts.basic(16, 500, Colors.Gray200, 1.5),
    },
    profileImg: {
        width: "100%",
        height: "100%",
        borderRadius: 12,
        objectFit: "cover",
    },
    talentNameOfferSetWrap: {
        marginTop: 32,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    talentName: {
        ...Texts.basic(32, "bold", Colors.Gray900, 1.19),
    },
    offerSetWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    offerSetTitle: {
        ...Texts.basic(14, 500, Colors.Gray600, 1.71),
    },
    offerSetHelpIconWrap: {
        marginLeft: 4,
        marginRight: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 8,
        paddingBottom: 8,
    },
    offerSetHelpIcon: {
        marginLeft: 4,
        marginRight: 8,
    },
    targetPositionWrap: {
        marginTop: 16,
        marginBottom: 22,
        padding: "4px 12px",
        borderRadius: 8,
        backgroundColor: "#eff3ff",
        alignSelf: "flex-start",
    },
    targetPosition: {
        ...Texts.basic(14, 500, Colors.Blue400, 1.71),
    },
    basicInfoWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
    },
    basicInfo: {
        marginLeft: 14,
        ...Texts.basic(14, 500, Colors.Gray700, 1.71),
    },
    selfIntroEmpty: {
        marginTop: 16,
        ...Texts.basic(14, 500, Colors.Gray300, 1.71),
    },
    selfIntroductionTitle: {
        ...Texts.basic(15, "bold", Colors.Gray700, 1.71),
        marginTop: 16,
    },
    selfIntroduction: {
        marginTop: 16,
        ...Texts.basic(14, 500, Colors.Gray600, 1.5),
        whiteSpace: "pre-wrap",
    },
    basicInfoEditBtn: {
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        borderRadius: 8,
        border: `1px solid ${Colors.Gray200}`,
        cursor: "pointer",
    },
    basicProfileEditBtnLabel: {
        ...Texts.basic(14, 500, Colors.Gray900, 1.5),
    },
    basicInfoEditBtnWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    basicInfoEditCancleBtn: {
        flex: 1,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        borderRadius: 8,
        backgroundColor: Colors.Gray100,
        cursor: "pointer",
    },
    basicProfileEditCancleBtnLabel: {
        ...Texts.basic(14, 500, Colors.Gray900, 1.5),
    },
    basicInfoEditSaveBtn: {
        flex: 1,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        borderRadius: 8,
        cursor: "pointer",
        backgroundColor: Colors.Blue400,
        marginLeft: 12,
    },
    basicProfileEditSaveBtnLabel: {
        ...Texts.basic(14, 500, Colors.White, 1.5),
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        marginTop: 16,
    },
    inputTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.19,
        marginTop: 32,
        marginBottom: 8,
    },
    textField: {
        backgroundColor: "#f4f4f4",
    },
    resize: {
        fontSize: 14,
        height: "auto",
    },
    positionYearsWrap: {
        display: "flex",
    },
    position: {
        flex: 0.6,
        marginRight: 12,
    },
    years: {
        flex: 0.4,
    },
    addressTitleWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    resize2: {
        paddingRight: 0,
    },
    noBorder: {
        borderWidth: 0,
    },
    noBorderSelect: {
        backgroundColor: "#f4f4f4",
        "& > .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
        },
    },
    searchButton: {
        backgroundColor: "#ffffff",
        ...Texts.basic(14, 500, "#2c2c2c"),
        padding: 17,
        width: 140,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    closeBtn: {
        marginBottom: 4,
    },
    inlieSelect: {
        paddingTop: 17.5,
        paddingBottom: 17.5,
        fontSize: 14,
    },
    questionMark: {
        verticalAlign: "middle",
    },
    divider: {
        marginTop: 24,
        marginBottom: 24,
        backgroundColor: Colors.Gray150,
    },
    skillHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    skillLabel: {
        ...Texts.basic(18, "bold", Colors.Gray900, 1.22),
        marginRight: 8,
    },
    flexGrowBox: {
        flexGrow: 1,
    },
    skillWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
    },
    skillChip: {
        padding: "8px 20px",
        borderRadius: 24,
        backgroundColor: Colors.Gray50,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
    },
    skillIcon: {
        width: 24,
        height: 24,
    },
    skillName: {
        marginLeft: 8,
        ...Texts.basic(14, 500, Colors.Gray700, 1.71),
    },
    skillLevel: {
        marginLeft: 8,
        ...Texts.basic(14, 500, Colors.Gray400, 1.71),
    },
    skillLevelGuide: {
        ...Texts.basic(12, 500, Colors.Gray400, 1.33),
    },
    badgePlaceholder: {
        height: 64,
        width: 64,
        borderRadius: "50%",
        backgroundColor: Colors.Gray100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    rightSection: {
        flex: "2 1 600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
    },
    detailProfile: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        paddingTop: 48,
    },
    hopeSalaryWrap: {
        borderRadius: 8,
        border: `1px solid ${Colors.Gray200}`,
        padding: "32px 30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 35,
    },
    hopeSalaryLabel: {
        ...Texts.basic(18, 500, Colors.Gray800, 1.22),
        marginBottom: 4,
    },
    hopeSalaryNotice: {
        ...Texts.basic(12, 500, Colors.Gray400, 1.33),
        marginBottom: 24,
    },
    hopeSalaryRange: {
        ...Texts.basic(24, 500, Colors.Gray900, 1.21),
        marginBottom: 41,
    },
    hopeSalaryRangeSlider: {
        alignSelf: "stretch",
    },
    hopeSalaryInputGuide: {
        marginTop: 6,
        ...Texts.basic(12, 500, Colors.Gray400, 1.33),
    },
    hopeSalaryWrapViewMode: {
        padding: 24,
        borderRadius: 8,
        border: `1px solid ${Colors.Gray200}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 30,
    },
    hopeSalaryLabelViewMode: { ...Texts.basic(16, 500, Colors.Gray900, 1.19) },
    hopeSalaryRangeViewMode: { ...Texts.basic(14, 500, Colors.Gray700, 1.71) },
    hopeSwitchDateWrap: {
        padding: 24,
        borderRadius: 8,
        border: `1px solid ${Colors.Gray200}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 30,
    },
    hopeSwitchDateLabelWrap: {},
    hopeSwitchDateTitle: {
        ...Texts.basic(16, 500, Colors.Gray900, 1.19),
    },
    hopeSwitchDateViewMode: {
        ...Texts.basic(14, 500, Colors.Gray700, 1.71),
    },
    hopeSwitchDateDesc: {
        marginTop: 6,
        ...Texts.basic(12, 500, Colors.Gray400, 1.33),
    },
    hopeSwitchDatePickerWrap: {},
    hopeSwitchDatePickerNotice: {
        ...Texts.basic(12, 500, Colors.Gray300, 1.75),
        textAlign: "right",
    },
    inputFontSize: {
        fontSize: 14,
    },
    birthInput: {
        fontSize: 14,
        backgroundColor: "#f4f4f4",
    },
    adornedEnd: {
        paddingRight: 0,
    },
    adornedEnd2: {
        paddingRight: 8,
    },
    testBorder: {
        borderColor: "#f4f4f4",
    },
    infoWrap: {
        borderRadius: 8,
        border: `1px solid ${Colors.Gray200}`,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        marginBottom: 30,
    },
    infoHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    infoTitle: {
        ...Texts.basic(18, 500, Colors.Gray800, 1.22),
    },
    infoAddBtn: {
        backgroundColor: Colors.Blue400,
        ...Texts.basic(14, 500, Colors.White, 1.5),
    },
    darkDivider: {
        marginTop: 24,
        marginBottom: 24,
        backgroundColor: Colors.Gray600,
        height: 1,
    },
    infoSubTitle: {
        ...Texts.basic(16, 500, Colors.Gray800, 1.75),
    },
    gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
    },
    gridItemHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 8,
    },
    required: {
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: Colors.Citrus500,
        marginLeft: 8,
    },
    popover: {
        pointerEvents: "none",
        marginBottom: 8,
    },
    paper: {
        padding: 16,
        width: 303,
    },
    popoverTitle: {
        ...Texts.basic(16, "bold", Colors.Gray900, 1.19),
    },
    popoverDesc: {
        marginTop: 12,
        ...Texts.basic(14, 500, Colors.Gray600, 1.71),
    },
    arrow: {
        color: theme.palette.common.white,
    },
    tooltipArrow: {
        boxShadow: theme.shadows[1],
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: Colors.Gray600,
        boxShadow: theme.shadows[1],
        ...Texts.basic(14, 500, Colors.Gray600, 1.71),
        padding: 16,
    },
}));
