import React from "react";

export const openLinkNewTab = (url) => {
    if (!url) return;

    window.open(url, "_blank");
    window.focus();
};

export const shuffle = (array) => {
    let currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const getLocalYYYYMMDD = (date) => {
    const years = date.getFullYear();
    let months = date.getMonth();
    months++;
    months = months < 10 ? "0" + months : months;
    let days = date.getDate();
    days = days < 10 ? "0" + days : days;
    return String(years) + String(months) + String(days);
};

export const displayLocalBirthDayAndAge = (birthStr) => {
    if (!birthStr) return "생년월일을 입력해주세요";

    let birthDate = new Date(birthStr);
    const years = birthDate.getFullYear();
    let months = birthDate.getMonth();
    let days = birthDate.getDate();

    let today = new Date();
    let age = today.getFullYear() - years;
    let m = today.getMonth() - months;
    if (m < 0 || (m === 0 && today.getDate() < days)) {
        age--;
    }

    months++;
    months = months < 10 ? "0" + months : months;
    days = days < 10 ? "0" + days : days;

    return `${years}/${months}/${days} (만 ${age}세)`;
};

export const displayLocalYearMonthDay = (dateStr) => {
    if (!dateStr) return "비공개";

    let date = new Date(dateStr);
    const years = date.getFullYear();
    let months = date.getMonth() + 1;
    let days = date.getDate();

    months = months < 10 ? "0" + months : months;
    days = days < 10 ? "0" + days : days;

    return `${years}/${months}/${days}`;
};

export const displayLocalYearMonth = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const zeroMonth = month < 10 ? "0" + month : month;

    return `${year}/${zeroMonth}`;
};

export const displayLink = (link) => {
    let newLink;
    let newLinkText;
    if (link.startsWith("http://") || link.startsWith("https://")) {
        newLink = link;
        newLinkText = link.split("//")[1];
    } else if (link) {
        newLink = `http://${link}`;
        newLinkText = link;
    } else {
        newLink = "";
        newLinkText = "";
    }
    return (
        <a
            href={newLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
        >
            {newLinkText}
        </a>
    );
};
