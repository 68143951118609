import { makeStyles } from "@material-ui/core";
import { Texts, Colors } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    flexGrowBox: {
        flexGrow: 1,
    },
    infoSubLabel: {
        ...Texts.basic(14, 500, Colors.Gray700, 1.5),
        marginRight: 8,
    },
    dateInput: {
        fontSize: 14,
        backgroundColor: "#f4f4f4",
    },
    adornedEnd: {
        paddingRight: 8,
    },
    whiteBorder: {
        borderColor: "#f4f4f4",
    },
    textField: {
        backgroundColor: "#f4f4f4",
    },
    resize: {
        fontSize: 14,
        height: "auto",
    },
    noBorder: {
        borderWidth: 0,
    },
    noBorderSelect: {
        backgroundColor: "#f4f4f4",
        "& > .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
        },
    },
    infoWrap: {
        borderRadius: 8,
        border: `1px solid ${Colors.Gray200}`,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        marginBottom: 30,
    },
    infoHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    infoTitle: {
        ...Texts.basic(18, 500, Colors.Gray800, 1.22),
    },
    infoAddBtn: {
        backgroundColor: Colors.Blue400,
        ...Texts.basic(14, 500, Colors.White, 1.5),
    },
    darkDivider: {
        marginTop: 24,
        marginBottom: 24,
        backgroundColor: Colors.Gray600,
        height: 1,
    },
    infoSubTitle: {
        ...Texts.basic(16, 500, Colors.Gray800, 1.75),
    },
    gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
    },
    gridItemHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 8,
    },
    required: {
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: Colors.Citrus500,
        marginLeft: 8,
    },
    infoFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: 30,
    },
    infoFooterBtn: {
        marginLeft: 8,
    },
    companyItemWrap: {
        marginTop: 24,
        marginBottom: 24,
    },
    companyName: {
        ...Texts.basic(18, "bold", Colors.Gray800, 1.22),
    },
    companyPosition: {
        ...Texts.basic(18, 500, Colors.Gray800, 1.22),
    },
    companyDuration: {
        ...Texts.basic(14, 500, Colors.Gray300, 1.71),
    },
    companyDetail: {
        ...Texts.basic(14, 500, Colors.Gray600, 1.71),
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
    },
    companyItemBtnWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    companyEditBtn: {
        ...Texts.basic(14, 500, Colors.Gray500, 1.71),
        textDecoration: "underline",
        cursor: "pointer",
    },
    companyDeleteBtn: {
        ...Texts.basic(14, 500, Colors.Gray500, 1.71),
        textDecoration: "underline",
        cursor: "pointer",
        marginLeft: 34,
    },
}));
