import React, { useContext, useEffect, useState } from "react";
import { auth } from "../apis/firebase";
import { UserContext } from "providers/UserProvider";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Header, Footer } from "pages/components";
import { Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BusinessIcon from "@material-ui/icons/Business";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import { TalentAddForm } from "pages/components";
import { Talents, Pagination } from "pages";
import { Typography } from "@material-ui/core";
import {
    companiesListener,
    jobsListener,
    talentsListener,
} from "apis/firestore/subscribe";
import { filterTalents } from "apis/firestore/filter";
import { searchTalents } from "apis/firestore/search";
import { fetchCompanies, fetchJobs, fetchTalents } from "apis/firestore/fetch";
import {
    uploadAllApplicants,
    uploadAllCompanies,
    uploadAllJobs,
} from "apis/firestore/upload";
import { onDownload } from "apis/firestore/download";
import { onDeleteSelectedApplicants } from "apis/firestore/delete";
import { onJudgeOnApplicants } from "apis/firestore/update";
import { SettingsSystemDaydream } from "@material-ui/icons";

const jobposts = require("data/admin/jobs.json");
const applicants = require("data/admin/rawApplicants.json");

const useStyles = makeStyles((theme) => {
    return {
        root: {
            textAlign: "center",
            padding: theme.spacing(10, 0),
            backgroundColor: "#ffbbd4",
            color: "#1a1a84",
        },
        container: {
            //height: "100vh",
            padding: theme.spacing(10, 3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(4, 4),
            },
        },
        control: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            //textAlign: "right",
        },
        title: {
            flexGrow: 1,
        },
    };
});

export default function Dashboard(props) {
    const admin = useContext(UserContext);
    let adminName = admin?.displayName;

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(true);

    const [companies, setCompanies] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [oracle, setOracle] = useState([]);
    const [talents, setTalents] = useState([]);
    const [name, setName] = useState("전체");
    const [filters, setFilters] = useState({
        result: "전체",
        position: "전체",
        skill: "전체",
        company: "전체",
        job: "전체",
        all: false,
    });
    const [selected, setSelected] = useState([]);

    useEffect(
        auth.onAuthStateChanged(function (user) {
            if (user) {
                setIsLoading(false);
                setIsSignedIn(true);
            } else {
                setIsSignedIn(false);
                setIsLoading(false);
            }
        }),
        []
    );

    // useEffect(
    //     fetch("../data/admin/test.json")
    //         .then((response) => {
    //             return response.json();
    //         })
    //         .then((data) => console.log(data)),
    //     []
    // );

    useEffect(() => {
        fetchCompanies(setCompanies);
        //companiesListener(setCompanies);
        fetchJobs(setJobs);
        //jobsListener(setJobs);
        //fetchTalents(setOracle, setTalents);
        //talentsListener(setTalents);

        return () => {
            fetchCompanies(setCompanies);
            //companiesListener(setCompanies);
            fetchJobs(setJobs);
            //jobsListener(setJobs);
            //fetchTalents(setOracle, setTalents);
            //talentsListener(setTalents);
        };
    }, []);

    const onCloud = () => fetchTalents(setOracle, setTalents);

    useEffect(() => {
        filterTalents(filters, companies, jobs, oracle, setTalents);

        return () => {
            filterTalents(filters, companies, jobs, oracle, setTalents);
        };
    }, [filters]);

    const onFilter = (key, filter) => {
        if (key === "all") {
            setFilters((prev) => ({
                result: "전체",
                position: "전체",
                skill: "전체",
                company: "전체",
                job: "전체",
                all: !prev.all,
            }));
        } else if (key === "job") {
            setFilters({
                ...filters,
                company: "전체",
                position: "전체",
                skill: "전체",
                all: false,
                [key]: filter,
            });
        } else if (key === "result") {
            setFilters({ ...filters, all: false, [key]: filter });
        } else {
            setFilters({ ...filters, job: "전체", all: false, [key]: filter });
        }
        setName("전체");
    };

    const onSelect = (newSelected) => {
        setSelected(newSelected);
        console.log(newSelected);
    };

    const onJudge = (result) =>
        onJudgeOnApplicants(selected, adminName, result);

    const onSearch = (keyword, inSearch) => {
        if (keyword) {
            searchTalents(keyword, inSearch, talents, setTalents, oracle);
            setName(keyword);
        } else {
            setFilters({
                result: "전체",
                company: "전체",
                position: "전체",
                skill: "전체",
                job: "전체",
                all: false,
            });
            setName("전체");
        }
    };

    return (
        <div>
            {!isSignedIn ? (
                <Redirect to="/talentx/admin/authentication" />
            ) : isLoading ? (
                <Container maxWidth="lg" className={classes.container}>
                    <div>Authentication Verifying...</div>
                </Container>
            ) : (
                <div>
                    <Header />
                    <Container maxWidth="lg" className={classes.container}>
                        <Paper className={classes.control}>
                            <Typography
                                variant="subtitle1"
                                className={classes.title}
                            >
                                <b>talentX heuri</b>
                            </Typography>
                            <Tooltip title="클라우드 연결 상태">
                                <IconButton
                                    aria-label="cloud database connection"
                                    onClick={onCloud}
                                >
                                    <CloudDoneIcon
                                        color={
                                            oracle.length > 0
                                                ? "secondary"
                                                : "inherit"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="인재 정보 업로드">
                                <IconButton
                                    aria-label="applicants data upload"
                                    onClick={() =>
                                        uploadAllApplicants(applicants)
                                    }
                                >
                                    <CloudUploadIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="기업 정보 업로드">
                                <IconButton
                                    aria-label="company data upload"
                                    onClick={() =>
                                        uploadAllCompanies(jobposts.slice(-1))
                                    }
                                >
                                    <CloudUploadIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="채용 정보 업로드">
                                <IconButton
                                    aria-label="job list upload"
                                    onClick={() =>
                                        uploadAllJobs(jobposts.slice(-1))
                                    }
                                >
                                    <CloudUploadIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            {/* <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => uploadAllApplicants(applicants)}
                                //disabled
                            >
                                인재정보 업로드
                            </Button> */}

                            {/* <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => uploadAllCompanies(jobposts)}
                                //disabled
                            >
                                기업정보 업로드
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => uploadAllJobs(jobposts)}
                                //disabled
                            >
                                채용정보 업로드
                            </Button> */}
                            {/* <Typography variant="subtitle2">
                                총 기업: {companies.length}개, 직무:{" "}
                                {jobs.length}개
                            </Typography> */}
                        </Paper>
                        <Talents
                            talents={talents}
                            companies={companies}
                            jobs={jobs}
                            loading={isLoading}
                            filters={filters}
                            onFilter={onFilter}
                            selected={selected}
                            onSelect={onSelect}
                            onJudge={onJudge}
                            onDelete={() =>
                                onDeleteSelectedApplicants(selected, onSelect)
                            }
                            onDownload={() =>
                                onDownload(
                                    filters,
                                    name,
                                    selected,
                                    talents,
                                    onSelect
                                )
                            }
                            onSearch={onSearch}
                        />
                    </Container>
                    <Footer />
                </div>
            )}
        </div>
    );
}
