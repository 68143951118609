import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FavoriteIcon from "@material-ui/icons/Favorite";
import WorkIcon from "@material-ui/icons/Work";
import { Autorenew } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(5),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function FloatingActionButton() {
    const classes = useStyles();

    return (
        <Fab
            color="primary"
            className={classes.root}
            variant="extended"
            aria-label="apply"
        >
            <WorkIcon className={classes.extendedIcon} />
            통합 공채 지원하기
        </Fab>
    );
}
