import React from "react";
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Typography,
    Tooltip,
} from "@material-ui/core";
import { openLinkNewTab } from "helper/misc";

const useStyles = makeStyles((theme) => ({
    companyListItem: {
        position: "relative",
        minWidth: 320,
        backgroundColor: "white",
        [theme.breakpoints.down("xs")]: {
            minWidth: "100%",
        },
        "@media (max-width: 319px)": {
            minWidth: 320,
        },
    },
    companyImgWrap: {
        border: "1px solid black",
        borderBottom: "0px",
        alignItems: "center",
        justifyContent: "center",
        height: 280,
        [theme.breakpoints.down("xs")]: {
            height: 300,
        },
        "@media (max-width: 360px)": {
            height: 240,
        },
    },
    companyImg: {
        [theme.breakpoints.down("lg")]: {
            maxWidth: "90%",
        },
    },
    companyNameWrap: {
        position: "relative",
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        border: "1px solid black",
        height: 130,
        [theme.breakpoints.down("xs")]: {
            alignItems: "center",
            height: "auto",
        },
        display: "flex",
    },
    companyName: {
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
        fontSize: 20,
        "@media (max-width: 480px)": {
            fontSize: 14,
        },
        display: "flex",
        //flexBasis: "auto",
    },
    hiringStr: {
        fontFamily: "Spoqa Han Sans",
        fontSize: 14,
        color: "#999999",
        marginTop: 5,
        "@media (max-width: 480px)": {
            fontSize: 12,
        },
        display: "flex",
        flexBasis: "auto",
    },
    linkBtn: {
        position: "absolute",
        top: 20,
        right: 18,
        justifyContent: "center",
        alignItems: "center",
    },
    link: {
        fontFamily: "Spoqa Han Sans",
        fontSize: 14,
        marginRight: 5,
        color: "#999999",
        "@media (max-width: 480px)": {
            fontSize: 12,
        },
    },
    tagWrap: {
        position: "absolute",
        top: 8,
        left: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,

        backgroundColor: "#222222",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    tag: {
        fontFamily: "Spoqa Han Sans",
        fontSize: 14,
        marginLeft: 8,
        color: theme.palette.common.white,
        "@media (max-width: 480px)": {
            fontSize: 12,
        },
    },
    tooltip: {
        position: "absolute",
        width: 47,
        top: -24,
        animation: "$motion 0.7s linear 0s infinite alternate",
        marginTop: 0,
    },
    "@keyframes motion": {
        "0%": {
            marginTop: 0,
        },
        "100%": {
            marginTop: 10,
        },
    },
    badge: {
        fontFamily: "Spoqa Han Sans",
        fontSize: 14,
        height: 26,
        marginLeft: 10,
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 8,
        paddingRight: 8,
        "@media (max-width: 480px)": {
            height: 20,
            fontSize: 10,
        },
        color: "#ffffff",
        backgroundColor: "#52b0ff",
    },
}));

function CompanyListItem(props) {
    const classes = useStyles();

    return (
        <Grid
            container
            item
            xs={12}
            sm={4}
            direction="column"
            className={classes.companyListItem}
            key={props.idx}
        >
            <Grid
                container
                item
                className={classes.companyImgWrap}
                justify="center"
            >
                <img src={props.img} alt="" className={classes.companyImg} />
            </Grid>

            <Grid
                container
                item
                justify="space-between"
                className={classes.companyNameWrap}
            >
                <Grid container item xs={8} direction="column">
                    {props.isNew && (
                        <img
                            className={classes.tooltip}
                            src={require("assets/new.png")}
                            alt=""
                        />
                    )}
                    <Typography className={classes.companyName} noWrap>
                        {props.name}
                        {props.isGood && (
                            <Box className={classes.badge}>추천</Box>
                        )}
                    </Typography>

                    <Typography className={classes.hiringStr}>
                        {props.hiringStr}
                    </Typography>
                </Grid>

                <Button
                    onClick={() => openLinkNewTab(props.goTo)}
                    disabled={props.goTo ? false : true}
                >
                    <img
                        src={require("assets/icon-toggle-next-pink.png")}
                        alt=""
                    />
                </Button>
            </Grid>

            <Button
                className={classes.linkBtn}
                onClick={() => openLinkNewTab(props.link)}
            >
                <Typography className={classes.link}>홈페이지</Typography>
                <img
                    src={require("assets/icon-toggle-arrow-right-grey-16.png")}
                    alt=""
                />
            </Button>

            {props.isHot && (
                <Box className={classes.tagWrap}>
                    <img src={require("assets/heart.png")} alt="" />
                    <Typography className={classes.tag}>
                        <strong>인기</strong>
                    </Typography>
                </Box>
            )}
        </Grid>
    );
}

export default CompanyListItem;
