import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { PassImage } from "data";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            textAlign: "center",
            backgroundColor: "#f8f8f8",
            padding: theme.spacing(20, 0, 10),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(10, 0),
            },
        },
        title: {
            marginBottom: theme.spacing(5),
        },
        gridItem: {
            height: 343,
        },
        gridItemPaperTitle: {
            height: "30%",
            width: "100%",
            backgroundColor: "#474747",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        gridItemPaperImage: {
            height: "30%",
            width: "100%",
            backgroundColor: "#474747",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
        },
        gridItemPaper: {
            height: "70%",
            width: "100%",
            color: "black",
            border: "solid 1px #000000",
            borderTop: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        gridItemPaperCenter: {
            height: "70%",
            width: "100%",
            color: "black",
            borderBottom: "solid 1px #000000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        gridItemPaperPaper: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(1, 2),
            borderRadius: "27.5px",
            border: "solid 1px #979797",
            backgroundColor: "#eeeeee",
        },
        mobileGridItemPaperTitleImage: {
            padding: theme.spacing(4, 0, 0),
            backgroundColor: "#474747",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        mobileGridItemPaper: {
            padding: theme.spacing(4, 0),
            color: "black",
            border: "solid 1px #000000",
            borderTop: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                // padding: "0 calc((100% - 296px) / 2)",
                padding: "0 32px",
            },
        },
    };
});

export default function RecruitmentMilestones(params) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return isMobile ? (
        <Box className={classes.root}>
            <Container maxWidth="sm" className={classes.container}>
                <Paper
                    variant="outlined"
                    className={classes.mobileGridItemPaperTitleImage}
                    square
                >
                    <Typography variant="h6" gutterBottom>
                        <b>합격자 발표일정</b>
                    </Typography>
                    <Typography>
                        (모든 합격자 발표는 개인별로 안내됩니다.)
                    </Typography>
                    <PassImage />
                </Paper>
                <Paper
                    variant="outlined"
                    className={classes.mobileGridItemPaper}
                    square
                >
                    <Typography variant="h6" gutterBottom>
                        <b>1차 합격자 발표</b>
                    </Typography>
                    <Typography variant="caption" display="block">
                        (코딩 테스트 결과)
                    </Typography>
                    <Paper
                        variant="outlined"
                        className={classes.gridItemPaperPaper}
                    >
                        <Typography>10월 29일</Typography>
                    </Paper>
                </Paper>
                <Paper
                    variant="outlined"
                    className={classes.mobileGridItemPaper}
                    square
                >
                    <Typography variant="h6" gutterBottom>
                        <b>2차 합격자 발표</b>
                    </Typography>
                    <Typography variant="caption" display="block">
                        (AI 인적성, 이력서, 포트폴리오 평가 결과)
                    </Typography>
                    <Paper
                        variant="outlined"
                        className={classes.gridItemPaperPaper}
                    >
                        <Typography>11월 11일</Typography>
                    </Paper>
                </Paper>
                <Paper
                    variant="outlined"
                    className={classes.mobileGridItemPaper}
                    square
                >
                    <Typography variant="h6" gutterBottom>
                        <b>최종 합격자 발표</b>
                    </Typography>
                    <Typography variant="caption" display="block">
                        (기업별 면접 결과)
                    </Typography>
                    <Paper
                        variant="outlined"
                        className={classes.gridItemPaperPaper}
                    >
                        <Typography>12월 14일 - 12월 31일</Typography>
                    </Paper>
                </Paper>
            </Container>
        </Box>
    ) : (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.gridItem}
                    >
                        <Paper
                            variant="outlined"
                            className={classes.gridItemPaperTitle}
                            square
                        ></Paper>
                        <Paper
                            variant="outlined"
                            className={classes.gridItemPaper}
                            square
                        >
                            <Typography variant="h6" gutterBottom>
                                <b>1차 합격자 발표</b>
                            </Typography>
                            <Typography variant="caption" display="block">
                                (코딩 테스트 결과)
                            </Typography>
                            <Paper
                                variant="outlined"
                                className={classes.gridItemPaperPaper}
                            >
                                <Typography>10월 29일</Typography>
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.gridItem}
                    >
                        <Paper
                            variant="outlined"
                            className={classes.gridItemPaperTitle}
                            square
                        >
                            <Typography variant="h6" gutterBottom>
                                <b>합격자 발표일정</b>
                            </Typography>
                            <Typography>
                                (모든 합격자 발표는 개인별로 안내됩니다.)
                            </Typography>
                        </Paper>

                        <Paper
                            variant="outlined"
                            className={classes.gridItemPaperCenter}
                            square
                        >
                            <Typography variant="h6" gutterBottom>
                                <b>2차 합격자 발표</b>
                            </Typography>
                            <Typography variant="caption" display="block">
                                (AI 인적성, 이력서, 포트폴리오 평가 결과)
                            </Typography>
                            <Paper
                                variant="outlined"
                                className={classes.gridItemPaperPaper}
                            >
                                <Typography>11월 11일</Typography>
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.gridItem}
                    >
                        <Paper
                            variant="outlined"
                            className={classes.gridItemPaperImage}
                            square
                        >
                            <PassImage />
                        </Paper>
                        <Paper
                            variant="outlined"
                            className={classes.gridItemPaper}
                            square
                        >
                            <Typography variant="h6" gutterBottom>
                                <b>최종 합격자 발표</b>
                            </Typography>
                            <Typography variant="caption" display="block">
                                (기업별 면접 결과)
                            </Typography>
                            <Paper
                                variant="outlined"
                                className={classes.gridItemPaperPaper}
                            >
                                <Typography>12월 14일 - 12월 31일</Typography>
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
