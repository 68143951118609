export const openScoutData = {
    menus: [
        "특별 혜택",
        "기업 소개",
        "모집 분야",
        "개발팀 소개",
        "일정 안내",
        "자주 묻는 질문",
    ],
    menusx: [
        "특별 혜택",
        "기업 소개",
        "개발팀 소개",
        "모집 분야",
        "일정 안내",
        "자주 묻는 질문",
    ],
    notices: [
        "•  이벤트 당첨자 발표는 2021년 10월 11일(월) 당첨자에게 개별 연락(문자, 전화, 이메일) 드립니다.",
        "•  애플워치 경품은 Apple Watch SE 스페이스 그레이 알루미늄 케이스, 스포츠 밴드, GPS, 44mm 모델로, 색상 변경은 불가합니다.",
        "•  5만원 이상 경품은 22% 제세공과금이 부과됩니다.",
        "•  경품은 이미지와 차이가 있을 수 있으며, 당사의 사정에 따라 대체될 수 있습니다.",
        "•  연락처 미등록 또는 오등록으로 인한 미수령은 책임지지 않습니다.",
        "•  당첨자 안내 수신 후 7일이내에 미회신시 당첨이 자동 취소 될 수 있음을 안내 드립니다.",
    ],
    extraNotices: [
        "•  본 프로모션 참여를 위해서는 탤런트엑스 앱에서 프로필 작성을 완료해야 참여 가능합니다.",
        "•  본 프로모션에서 면접/채용 제안은 탤런트엑스 앱을 활용합니다.",
        "•  과정 진행을 위해 문자, 이메일, 전화, 카카오톡 등을 통해 개별 연락 드릴 수 있습니다.",
    ],
    faqs: [
        {
            question: "Q. 본 프로그램의 지원 자격은 어떻게 되나요?",
            answer: "본 프로그램은 3년차 이상 경력 또는 이에 준하는 실력을 보유한 개발자를 대상으로 합니다.",
        },
        {
            question: "Q. IT/SW 관련 전공 학위 보유자만 지원 가능한가요?",
            answer: "전공 및 최종 학력과 무관하게 지원할 수 있습니다.",
        },
        {
            question: "Q. 해당 기업에 불합격 이력이 있어도 지원 가능한가요?",
            answer: "해당 사안에 제한 규정은 없으며 타 플랫폼, 직접 지원 등을 통해 불합격한 이력이 있어도 지원 가능합니다.",
        },
        {
            question: "Q. 과제 테스트 합격 시 후속 전형은 어떻게 진행되나요?",
            answer: "기업에서 합격자의 프로필을 검토한 후 탤런트엑스 앱을 통해 후속 전형을 안내 드릴 예정입니다.",
        },
        {
            question: "Q. 탤런트엑스에 대해 자세히 알고 싶어요.",
            answer: "탤런트엑스 공식 홈페이지(https://talentx.co.kr/?type=talent)에서 자세한 내용을 확인하세요.",
        },
        {
            question:
                "Q. 탤런트엑스 SW인재 회원 신청 페이지에 코딩 테스트 절차가 있던데 본 과정 지원 시 코딩 테스트도 응시해야 하는 건가요?",
            answer: "본 과정은 기존 가입신청 절차와 다르게 코딩 테스트가 과제 테스트로 대체된 것으로, 본 프로그램은 코딩 테스트 전형이 없습니다.",
        },
    ],
    history: [
        {
            year: 2021,
            months: [
                {
                    month: "9월",
                    content:
                        "‘삼쩜삼’ 누적가입자 500만명 돌파, 누적 환급액 1500억 돌파",
                },
                {
                    month: "4월",
                    content: "‘삼쩜삼’ 누적가입자 100만명 돌파",
                },
                {
                    month: "1월",
                    content:
                        "‘자비스’ 가입 회원사 4만 3천개 돌파, 관리 금액 규모 29조",
                },
            ],
        },
        {
            year: 2020,
            months: [
                {
                    month: "12월",
                    content:
                        "'삼쩜삼' 환급신청자 55만명 돌파, 총 환급액 89억 돌파",
                },
                {
                    month: "6월",
                    content: "자비스앤빌런즈 손익분기점(BEP) 달성 ",
                },
                {
                    month: "5월",
                    content: "‘삼쩜삼’ 출시",
                },
            ],
        },
        {
            year: 2018,
            months: [
                {
                    month: "5월",
                    content: "‘자비스’ 가입 회원사 5000개 돌파 ",
                },
            ],
        },
    ],
};
