import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: 120,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    programTitleWrap: {
        alignSelf: "flex-start",
        backgroundColor: "#3c6af5",
        borderRadius: 4,
    },
    programTitle: {
        marginTop: 4,
        marginRight: 8,
        marginBottom: 4,
        marginLeft: 8,
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.71,
        color: "#ffffff",
        letterSpacing: -0.6,
    },
    pageTitle: {
        marginTop: 12,
        marginBottom: 12,
        fontSize: 32,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
    },
    thickDivider: {
        marginBottom: 32,
        height: 2,
        backgroundColor: "#111111",
    },
    sectionTitle: {
        fontSize: 20,
        lineHeight: 1.2,
        fontWeight: "bold",
    },
    additInfo: {
        marginTop: 4,
        marginBottom: 8,
        fontSize: 14,
        fontWeight: "500",
        lineHeight: 1.7,
        color: "#929292",
    },
    stepTitle: {
        marginTop: 8,
        fontsize: 16,
        fontWeight: 500,
        lineHeight: 1.75,
        color: "#929292",
    },
    stepContent: {
        marginTop: 8,
        marginLeft: 41,
        fontsize: 16,
        fontWeight: 500,
        lineHeight: 1.75,
        color: "#111111",
    },
    eventBox: {
        marginTop: 24,
        height: 140,
        border: "1px solid #eeeeee",
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        borderRadius: 8,
    },
    eventChip: {
        width: 56,
        height: 28,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 20,
        backgroundColor: "#e6ecfe",
        color: "#3c6af5",
        fontSize: 12,
        fontWeight: "bold",
    },
    eventTitle: (props) => ({
        marginBottom: 23,
        marginLeft: 20,
        fontSize: props.isMobile ? 18 : 20,
        fontWeight: "bold",
        lineHeight: 1.19,
    }),
    eventTitleBlue: (props) => ({
        color: "#3c6af5",
        fontSize: props.isMobile ? 18 : 20,
        fontWeight: 700,
        lineHeight: 1.19,
        display: "inline",
    }),
    divider: {
        marginTop: 32,
        marginBottom: 32,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    inputTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.19,
        marginTop: 32,
        marginBottom: 8,
    },
    inputDesc: {
        fontSize: 14,
        fontWeight: 500,
        color: "#ababab",
        lineHeight: 1.43,
        marginTop: -4,
        marginBottom: 8,
    },
    tooltipBase: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    hidden: {
        display: "none",
    },
    tooltip: {
        position: "absolute",
        top: -40,
        right: 0,
        backgroundColor: "#2c2c2c",
        borderRadius: 8,
        padding: "4px 10px",
    },
    tooltipText: {
        fontSize: 12,
        fontWeight: 500,
        color: "#ffffff",
        lineHeight: 1.75,
    },
    textField: {
        backgroundColor: "#f4f4f4",
    },
    resize: {
        fontSize: 14,
    },
    adornment: {
        fontSize: 14,
        color: "#787878",
    },
    positionYearsWrap: {
        display: "flex",
    },
    position: {
        flex: 0.6,
        marginRight: 12,
    },
    years: {
        flex: 0.4,
    },
    inlieSelect: {
        paddingTop: 17.5,
        paddingBottom: 17.5,
        fontSize: 14,
    },
    companyWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
    },
    companyChip: {
        borderRadius: 16,
        backgroundColor: "#454545",
        padding: "6px 6px 6px 12px",
        display: "flex",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
    },
    companyLabel: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#ffffff",
        lineHeight: 1.75,
        marginRight: 4,
    },
    foot: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        //width: "100%",
        padding: "32px 0px 20px",
    },
    consentTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 8,
    },
    consentNotice: {
        fontSize: 14,
        fontWeight: 300,
        marginBottom: 20,
    },
    noticeBox: {
        backgroundColor: "#f8f8f8",
        border: "1px solid #dddddd",
        borderRadius: 8,
        padding: 16,
        marginBottom: 12,
        "& > *": {
            fontSize: 14,
            color: "#222222",
            margin: theme.spacing(0.5, 0, 0),
        },
        "& > p:first-child": {
            marginTop: 0,
        },
    },
    noticeTitle: {
        fontWeight: "bold",
        margin: theme.spacing(0, 0, 1),
    },
    noticeContent: {
        marginBottom: 16,
    },
    noticeCaption: {
        fontSize: 12,
        fontWeight: 500,
        color: "#bbbbbb",
    },
    consentBtn: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: 20,
        marginBottom: 28,
    },
    consent: {
        fontSize: 16,
        fontWeight: 300,
        marginLeft: 9,
    },
    submitButton: {
        height: 56,
        width: "100%",
        backgroundColor: "#0c46f2",
        ...Texts.basic(16, "normal", "#ffffff"),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    modalScreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        outline: 0,
        textAlign: "center",
        borderRadius: 12,
        width: 320,
        padding: "32px 16px 20px",
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 8,
    },
    modalMessage: {
        fontSize: 16,
        fontWeight: 500,
        color: "#222222",
        marginBottom: 12,
    },
    modalInfo: {
        fontSize: 12,
        fontWeight: 300,
        color: "#999999",
        marginBottom: 32,
    },
    modalButton: {
        width: "100%",
        fontSize: 16,
        height: 56,
    },
}));
