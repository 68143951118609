import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
    AppBar,
    Toolbar,
    Box,
    IconButton,
    Badge,
    Typography,
    Container,
    List,
    ListItem,
    ListItemText,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import logo from "talentx_logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import { isAndroid, isWindows } from "react-device-detect";

const useStyles = makeStyles((props) => ({
    container: {
        position: "relative",
    },
    appBar: (props) => ({
        backgroundColor: props.theme.palette.background.paper,
        height: 56,
        maxWidth: props.maxWidth ? undefined : 444,
        left: "50%",
        transform: "translate(-50%, 0)",
    }),
    toolBar: {
        minHeight: 56,
    },
    identity: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexGrow: 1,
    },
    logo: {
        width: 70,
        height: 20,
        marginLeft: 20,
    },
    link: {
        color: "#111111",
        fontSize: 14,
        fontWeight: 500,
        marginRight: 12,
    },
    menuButton: {
        marginRight: 4,
    },
    badge: {
        backgroundColor: "#ff1717",
    },
    menuLink: {
        textDecoration: "none",
        color: "inherit",
    },
    listItemText: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 300,
    },
}));

const ServiceHeader = ({ userType, maxWidth }) => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    // console.log("isMobile :", isMobile);

    const classes = useStyles({ theme, maxWidth });

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <React.Fragment>
            <Container
                maxWidth={maxWidth || "xs"}
                disableGutters
                className={classes.container}
            >
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    elevation={0}
                >
                    <Toolbar classes={{ root: classes.toolBar }} disableGutters>
                        <RouterLink to="/" className={classes.identity}>
                            <img
                                src={logo}
                                //srcSet={`${require("assets/talentx_logo@2x.png")} 2x, ${require("assets/talentx_logo@3x.png")} 3x`}
                                className={classes.logo}
                                alt="talentX"
                            />
                        </RouterLink>
                        {/* {!menuOpen && (
                            <Typography className={classes.link}>
                                <Link
                                    href="https://www.talentx.co.kr"
                                    target="_blank"
                                    rel="noopener"
                                    color="inherit"
                                >
                                    앱다운로드
                                </Link>
                            </Typography>
                        )} */}
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            aria-label="menu"
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            <Badge
                                badgeContent=" "
                                overlap="circle"
                                variant="dot"
                                invisible={menuOpen}
                                classes={{ dot: classes.badge }}
                            >
                                {!menuOpen ? <MenuIcon /> : <CloseIcon />}
                            </Badge>
                        </IconButton>
                    </Toolbar>
                    {/* {userType === 1 && (
                        <Box
                            style={{ position: "absolute", top: 42, right: 16 }}
                            hidden={menuOpen}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    left: 87,
                                    width: 0,
                                    height: 0,
                                    borderLeft: "7px solid transparent",
                                    borderRight: "4px solid transparent",
                                    borderBottom: "5px solid #ff5252",
                                }}
                            ></div>
                            <Typography
                                style={{
                                    backgroundColor: "#ff5252",
                                    borderRadius: 20,
                                    fontSize: 12,
                                    fontWeight: 500,
                                    padding: theme.spacing(0.5, 2),
                                }}
                            >
                                연봉로켓 탑승중
                            </Typography>
                        </Box>
                    )} */}

                    {menuOpen && (
                        <Box
                            style={{
                                position: "absolute",
                                top: 55,
                                width: "100%",
                                backgroundColor: theme.palette.background.paper,
                                color: "black",
                            }}
                        >
                            <List component="nav">
                                {[
                                    {
                                        route: true,
                                        title: "탤런트엑스",
                                        url: "/",
                                    },
                                    {
                                        route: true,
                                        title: "데브스타 스카우트",
                                        url: "/devstarx",
                                    },
                                    {
                                        route: true,
                                        title: "가격 정책",
                                        url: "/pricing",
                                    },
                                    {
                                        route: true,
                                        title: "스카우트 제안받기",
                                        url: "/scout",
                                    },
                                    {
                                        route: false,
                                        title: "오픈 채팅방",
                                        url: "https://open.kakao.com/o/gr3vXt4c",
                                    },
                                    {
                                        route: false,
                                        title: "앱 설치",
                                        url:
                                            isAndroid || isWindows
                                                ? "https://play.google.com/store/apps/details?id=com.talentinum.talentx"
                                                : "https://apps.apple.com/kr/app/%ED%83%A4%EB%9F%B0%ED%8A%B8%EC%97%91%EC%8A%A4/id1529534277",
                                    },
                                    // {
                                    //     route: false,
                                    //     title: "문의하기",
                                    //     url: "https://talentx.channel.io/",
                                    // },
                                ].map((menu, idx) =>
                                    menu.route ? (
                                        <RouterLink
                                            key={idx}
                                            to={menu.url}
                                            className={classes.menuLink}
                                        >
                                            <ListItem button>
                                                <ListItemText
                                                    primary={menu.title}
                                                    classes={{
                                                        primary:
                                                            classes.listItemText,
                                                    }}
                                                ></ListItemText>
                                            </ListItem>
                                        </RouterLink>
                                    ) : (
                                        <Link
                                            key={idx}
                                            href={menu.url}
                                            color="inherit"
                                            target="_blank"
                                        >
                                            <ListItem button>
                                                <ListItemText
                                                    primary={menu.title}
                                                    classes={{
                                                        primary:
                                                            classes.listItemText,
                                                    }}
                                                ></ListItemText>
                                            </ListItem>
                                        </Link>
                                    )
                                )}
                            </List>
                        </Box>
                    )}
                </AppBar>
            </Container>
        </React.Fragment>
    );
};

export default ServiceHeader;
