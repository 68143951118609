import React from "react";
import {
    CssBaseline,
    Typography,
    Container,
    Button,
    TextField,
} from "@material-ui/core";
import axios from "axios";

let isButtonActive = true;
let pw = "";
let pwConform = "";

function handleChangePw(event) {
    pw = event.target.value;
}

function handleChangePwConform(event) {
    pwConform = event.target.value;
}

async function handleClick(type, token) {
    if (!isButtonActive) return;

    isButtonActive = false;

    try {
        if (!pw) {
            alert("비밀번호를 입력해주세요.");
            return;
        }

        if (pw.length < 8) {
            alert("비밀번호는 최소 8자 이상이어야 합니다.");
            return;
        }

        if (pw !== pwConform) {
            alert("비밀번호와 비밀번호 확인이 다릅니다.");
            return;
        }

        await axios.patch(
            `https://api.talentx.talentinum.com/${type}/lost-and-founds/passwords/${token}`,
            {
                pw,
            }
        );

        alert("비밀번호가 변경되었습니다.");
    } catch (err) {
        if (err.response) {
            if (err.response.status === 400)
                alert(
                    "인증 기한이 만료되었습니다.\n로그인하여 인증 메일을 새로 요청해주세요."
                );
            else if (err.response.status === 401)
                alert(
                    "인증 기한이 만료되었습니다.\n비밀번호 변경 메일을 새로 요청해주세요."
                );
            else
                alert(
                    "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
                );
        } else
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
    } finally {
        isButtonActive = true;
    }
}

class PassReset extends React.Component {
    render() {
        const query = new URLSearchParams(window.location.search);

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            margin: "0 auto",
                            padding: "30px",
                            maxWidth: "350px",
                        }}
                    >
                        <Typography
                            component="h3"
                            style={{
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            비밀번호 변경
                        </Typography>
                        <TextField
                            label="Password"
                            type="password"
                            onChange={handleChangePw}
                            style={{
                                marginBottom: "20px",
                                width: "100%",
                            }}
                        />
                        <TextField
                            label="Password Confirm"
                            type="password"
                            onChange={handleChangePwConform}
                            style={{
                                marginBottom: "30px",
                                width: "100%",
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() =>
                                handleClick(this.props.type, query.get("token"))
                            }
                        >
                            비밀번호 변경하기
                        </Button>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default PassReset;
