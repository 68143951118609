import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyAZh7P6dWF94ZXv8fw8VWjXJLzezZY8gOc",
	authDomain: "talentinum-cb9c9.firebaseapp.com",
	databaseURL: "https://talentinum-cb9c9.firebaseio.com",
	projectId: "talentinum-cb9c9",
	storageBucket: "talentinum-cb9c9.appspot.com",
	messagingSenderId: "941164413405",
	appId: "1:941164413405:web:792d615f8acd1d81547634",
	measurementId: "G-GXH40L0FQG",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
// 	apiKey: "AIzaSyAZh7P6dWF94ZXv8fw8VWjXJLzezZY8gOc",
// 	authDomain: "talentinum-cb9c9.firebaseapp.com",
// 	databaseURL: "https://talentinum-cb9c9.firebaseio.com",
// 	projectId: "talentinum-cb9c9",
// 	storageBucket: "talentinum-cb9c9.appspot.com",
// 	messagingSenderId: "941164413405",
// 	appId: "1:941164413405:web:792d615f8acd1d81547634",
// 	measurementId: "G-GXH40L0FQG",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
// export const firestore = getFirestore(app);
// export const auth = getAuth(app);
