import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    Container,
    List,
    ListItem,
    ListItemText,
    Button,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isAndroid, isWindows } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
    },
    appBar: {
        backgroundColor: "#010101",
        height: 56,
    },
    toolBar: {
        minHeight: 56,
    },
    identity: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    logo: {
        width: 70,
        height: 21,
    },
    link: {
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 500,
        marginRight: 12,
    },
    paddingBox: {
        flexGrow: 1,
    },
    promotionWrap: {
        position: "relative",
    },
    menuTextButton: {
        color: "#ffffff",
    },
    menuButton: {
        marginRight: 4,
    },
    badge: {
        backgroundColor: "#ff1717",
    },
    menuLink: {
        textDecoration: "none",
        color: "inherit",
    },
    listItemText: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 300,
    },
}));
/*
const menuRefs = [];

menuRefs["데브스타X"] = useRef();
menuRefs["참가 개발자"] = useRef();
menuRefs["일정"] = useRef();
menuRefs["자주 묻는 질문"] = useRef();
*/

const DevStarXHeaderD = ({ userType }) => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Container maxWidth="lg" className={classes.container}>
                    <Toolbar classes={{ root: classes.toolBar }} disableGutters>
                        <RouterLink to="/company" className={classes.identity}>
                            <img
                                src={require("assets/talentx-logo-white.png")}
                                srcSet={`${require("assets/talentx-logo-white@2x.png")} 2x, ${require("assets/talentx-logo-white@3x.png")} 3x`}
                                className={classes.logo}
                                alt="talentX"
                            />
                        </RouterLink>
                        <Box className={classes.paddingBox}></Box>
                        <Button
                            className={classes.menuTextButton}
                            onClick={() => {
                                window.location.replace(
                                    "/openscout/devstarx/#intro"
                                );
                            }}
                        >
                            데브스타X
                        </Button>

                        <Button
                            className={classes.menuTextButton}
                            onClick={() => {
                                window.location.replace(
                                    "/openscout/devstarx/#companies"
                                );
                            }}
                        >
                            공개영입 포지션
                        </Button>

                        <Button
                            className={classes.menuTextButton}
                            onClick={() => {
                                window.location.replace(
                                    "/openscout/devstarx/#devstars"
                                );
                            }}
                        >
                            공개이직 개발자
                        </Button>

                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() => {
                                window.location.replace(
                                    "/openscout/devstarx/#schedule"
                                );
                            }}
                        >
                            일정
                        </Button> */}

                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() => {
                                window.location.replace(
                                    "/openscout/devstarx/#FAQ"
                                );
                            }}
                        >
                            자주 묻는 질문
                        </Button> */}
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    );
};

export default DevStarXHeaderD;
