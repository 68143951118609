import xlsx from "json-as-xlsx";

export const onDownload = (
    filters,
    keyword,
    selectedRowIds,
    allRows,
    onSelect
) => {
    console.log(allRows);

    const selectedRows = allRows.filter(
        (row) => selectedRowIds.indexOf(row.id || row.uid) !== -1
    );

    const columns = [
        { label: "인재번호", value: "uid" },
        { label: "이름", value: "name" },
        { label: "이메일", value: "email" },
        // { label: "휴대폰번호", value: "phone" },

        { label: "희망직무 1", value: "first_position" },
        { label: "희망직무 2", value: "second_position" },
        { label: "희망직무 3", value: "third_position" },
        { label: "기술스택 1", value: "first_skill" },
        { label: "기술스택 2", value: (row) => row.second_skill },
        { label: "기술스택 3", value: (row) => row.third_skill },
        { label: "기술스택 4", value: (row) => row.fourth_skill },
        { label: "희망기업 1", value: "first_company" },
        { label: "희망기업 2", value: "second_company" },
        { label: "희망기업 3", value: "third_company" },
        {
            label: "코딩테스트 등급",
            value: (row) => (row.records[1] ? row.records[1].level || "" : ""),
        },
        {
            label: "인적성검사 등급",
            value: (row) => (row.records[2] ? row.records[1].level || "" : ""),
        },
        { label: "IT포트폴리오", value: "portfolio" },
        // { label: "이메일", value: (row) => row.email },
    ]; // Run functions];

    if (
        filters.all ||
        filters.job !== "전체" ||
        filters.company !== "전체" ||
        filters.skill !== "전체"
    ) {
        columns.unshift({ label: "추천점수", value: "match_rating" });
        columns.unshift({ label: "추천대상", value: "match" });
    }

    const namer = (type) => {
        let nameTokens = type === "file" ? ["탤런트엑스"] : [];
        if (filters.all) nameTokens.push("전체 기업");
        else if (filters.job !== "전체") nameTokens.push(filters.job);
        else {
            if (filters.company !== "전체") nameTokens.push(filters.company);
            if (filters.position !== "전체") nameTokens.push(filters.position);
            if (filters.skill !== "전체") nameTokens.push(filters.skill);
        }
        console.log("검색어 : " + keyword);
        if (keyword !== "전체") nameTokens.push(keyword);
        if (type === "file" && nameTokens.length === 1)
            nameTokens.push("전체 인재");
        if (type === "sheet" && nameTokens.length === 0)
            nameTokens.push("전체 인재");
        return nameTokens.join(" - ") || "전체";
    };

    const settings = {
        sheetName: namer("sheet"),
        fileName: namer("file"),
        extraLength: 5, // A bigger number means that columns should be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    const download = true; // If true will download the xlsx file, otherwise will return a buffer

    async function asyncDownloadAndDeselect() {
        await xlsx(columns, selectedRows, settings, download);
        onSelect([]);
    }

    asyncDownloadAndDeselect();
};
