import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
        display: "inline-block",
        fontSize: 20,
        marginBottom: theme.spacing(1),
    },
    titleBlue: {
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
        display: "inline-block",
        fontSize: 20,
    },
    titleSub: {
        fontFamily: "Spoqa Han Sans",
        fontSize: 14,
    },
}));

function CompanyListTitle(props) {
    const classes = useStyles();

    return (
        <Grid container item direction="column">
            <Typography className={classes.title}>
                전체{" "}
                <Typography className={classes.titleBlue} color="primary">
                    {props.numCompanies}
                </Typography>
                곳의 기업에서
                <br />
                당신을 기다리고 있습니다.
            </Typography>

            <Typography className={classes.titleSub}>
                클릭하여 각 기업에 대한 자세한 정보를 확인해보세요!
            </Typography>
        </Grid>
    );
}

export default CompanyListTitle;
