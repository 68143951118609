import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    head: {
        width: "100%",
        height: "35vw",
        marginTop: 55,
        backgroundImage: `url(${require("assets/showcase_morning.png")})`,
        backgroundImage: `image-set(url(${require("assets/showcase_morning.png")}) 1x, url(${require("assets/showcase_morning@2x.png")}) 2x, url(${require("assets/showcase_morning@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    headMobile: {
        width: "100%",
        height: "64vw",
        marginTop: 55,
        backgroundImage: `url(${require("assets/showcase_morning_mobile.png")})`,
        backgroundImage: `image-set(url(${require("assets/showcase_morning_mobile.png")}) 1x, url(${require("assets/showcase_morning_mobile@2x.png")}) 2x, url(${require("assets/showcase_morning_mobile@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    headMobileDarkMode: {
        width: "100%",
        height: "64vw",
        marginTop: 55,
        backgroundImage: `url(${require("assets/showcase_night_mobile.png")})`,
        backgroundImage: `image-set(url(${require("assets/showcase_night_mobile.png")}) 1x, url(${require("assets/showcase_night_mobile@2x.png")}) 2x, url(${require("assets/showcase_night_mobile@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    headDarkMode: {
        width: "100%",
        height: "35vw",
        marginTop: 55,
        backgroundImage: `url(${require("assets/showcase_night.png")})`,
        backgroundImage: `image-set(url(${require("assets/showcase_night.png")}) 1x, url(${require("assets/showcase_night@2x.png")}) 2x, url(${require("assets/showcase_night@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
    },
    inner: (props) => ({
        paddingTop: props.isMobile ? 0 : 20,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingLeft: props.isMobile ? 4 : 0,
        paddingRight: props.isMobile ? 4 : 0,
        marginBottom: 20,
    }),
    leftSection: (props) => ({
        flex: "2 1 600px",
        overflow: "hidden",
        height: "auto",
    }),
    benefitImg: (props) => ({
        width: "100%",
        marginBottom: props.isMobile ? 12 : 4,
    }),
    imageSlider: (props) => ({
        marginBottom: 24,
        paddingTop: props.isMobile ? 24 : 0,
    }),
    image: (props) => ({
        borderRadius: props.isMobile ? 0 : 8,
        width: "100%",
    }),
    company: {
        ...Texts.basic(24, "bold", "#111111", 1.33),
        marginBottom: 5,
        position: "relative",
    },
    companyWrap: {
        display: "flex",
        flexDirection: "row",
    },
    premium: {
        height: 20,
        marginLeft: 8,
    },
    topSlogan: {
        ...Texts.basic(21, "bold", "#111111", 1.38),
        marginLeft: 20,
        marginRight: 20,
        whiteSpace: "pre-wrap",
    },
    slogan: {
        ...Texts.basic(14, "normal", "#2c2c2c", 1.79),
        marginBottom: 39,
    },
    subTitle: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 12,
    },
    devTeam: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 12,
    },
    devTeamIntro: {
        ...Texts.basic(16, "normal", "#2c2c2c", 1.75),
        marginBottom: 32,
        whiteSpace: "pre-wrap",
        marginTop: 20,
    },
    devTeamWrap: {
        borderRadius: 12,
        border: "solid 1px #eeeeee",
        boxShadow: "0 6px 24px 0 rgba(198, 198, 198, 0.5)",
    },
    devTeamStructureWrap: {
        padding: 24,
    },
    devTeamHeadWrap: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 19,
    },
    devTeamImg: {},
    devTeamStructure: {
        marginLeft: 8,
        ...Texts.basic(16, "bold", "#111111", 1.38),
    },
    devTeamPositionWrap: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginBottom: 12,
    },
    devTeamPositionName: {
        flex: "3 0 90px",
        ...Texts.basic(14, 500, "#454545", 1.79),
    },
    devTeamPositionCount: (props) => ({
        flex: "1 0 30px",
        textAlign: props.isMobile ? "right" : "left",
        ...Texts.basic(14, "normal", "#454545", 1.79),
    }),
    devTeamPositionYearsBar: {
        flex: "8 0 240px",
        display: "flex",
        flexDirection: "row",
        borderRadius: 2,
        textAlign: "center",
        color: "#ffffff",
        ...Texts.basic(9, 500, "#ffffff", 2.33),
    },
    legendWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        //marginBottom: 24,
    },
    updated: {
        ...Texts.basic(12, "normal", "#ababab", 1.75),
        flexShrink: 0,
    },
    experienceRangesWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    experienceRange: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 15,
        ...Texts.basic(12, "normal", "#787878", 1.75),
    },
    hardSkillStyleWrap: {
        padding: 24,
    },
    skillStack: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 12,
    },
    hardSkillsWrap: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 40,
    },
    hardSkillBox: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: 8,
        borderRadius: 4,
        border: "solid 1px #eeeeee",
    },
    hardSkillIcon: { marginRight: 4 },
    hardSkill: {
        ...Texts.basic(12, "bold", "#111111", 1.67),
    },
    devStyleTitle: {
        ...Texts.basic(16, "bold", "#111111", 1.5),
        marginBottom: 12,
    },
    devStyleWrap: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    devStyleBox: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: "6px 12px",
        borderRadius: 16,
        backgroundColor: "#f8f8f8",
        ...Texts.basic(12, 500, "#2c2c2c", 1.67),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0c46f2",
            color: "#ffffff",
        },
    },
    devStyleBoxWithoutDesc: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 8,
        marginBottom: 8,
        padding: "6px 12px",
        borderRadius: 16,
        backgroundColor: "#f8f8f8",
        ...Texts.basic(12, 500, "#2c2c2c", 1.67),
    },
    devStyle: {},
    popover: {},
    popoverRoot: {},
    popoverPaper: {
        //boxShadow: "none",
        border: "solid 1px #eeeeee",
    },
    devStyleDesc: {
        padding: 8,
        ...Texts.basic(12, "normal", "#2c2c2c", 1.79),
    },
    servicesWrap: { marginBottom: 40 },
    serviceWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        border: "solid 1px #eeeeee",
        borderRadius: 8,
        marginBottom: 20,
    },
    serviceInfoWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    serviceLinkWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
    },
    detail: {
        ...Texts.basic(14, "normal", "#787878", 1.79),
        marginRight: 4,
    },
    companyIntroTitle: {
        marginTop: 40,
        marginBottom: 12,
        ...Texts.basic(16, "bold", "#111111", 1.5),
    },
    companyIntro: {
        ...Texts.basic(16, "normal", "#111111", 1.75),
        marginTop: 20,
        marginBottom: 56,
        whiteSpace: "pre-wrap",
    },
    perkBox: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 4,
        border: "solid 1px #eeeeee",
        backgroundColor: "#f8f8f8",
        padding: 12,
    },
    perkImg: {},
    perk: {
        marginLeft: 8,
        ...Texts.basic(14, 500, "#2c2c2c", 1.5),
    },
    disclaimer: {
        ...Texts.basic(12, "normal", "#c5c5c5", 1.5),
        marginTop: 40,
        marginBottom: 40,
    },
    rightSection: { flex: "1 0 300px", zIndex: 2 },
    actionCard: (props) => {
        return props.isMobile
            ? { backgroundColor: "#ffffff", padding: 20, }
            : {
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  border: "solid 1px #eeeeee",
                  marginTop: -100,
                  marginLeft: 20,
                  marginRight: 20,
                  top: 76,
                  position: "sticky",
              };
    },
    actionCardInner: {
        margin: 24,
        marginBottom: 16,
    },
    openScoutTitle: {
        ...Texts.basic(20, "bold", "#111111", 1.4),
        marginBottom: 24,
    },
    openScoutSeason: {
        ...Texts.basic(16, "bold", "#111111", 1.4),
        marginBottom: 4,
    },
    openScoutEpisode: {
        ...Texts.basic(20, "bold", "#111111", 1.4),
        marginBottom: 24,
    },
    openScoutStep: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12,
        marginBottom: 12,
    },
    openScoutStepContent: {
        ...Texts.basic(14, 500, "#2c2c2c", 1.57),
    },
    openScoutTimerBox: (props) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 20,
        marginBottom: props.isMobile ? 24 : 20,
        padding: 10,
        paddingLeft: 12,
        paddingRight: 12,
        border: "solid 1px #3c6af5",
        borderRadius: 8,
    }),
    openScoutStepTimerIcon: {
        width: 24,
        height: 24,
    },
    openScoutStepTimerLabel: {
        flexGrow: 1,
        marginLeft: 4,
        ...Texts.basic(14, 500, "#2c2c2c", 1.57),
    },
    openScoutStepTimer: {
        ...Texts.basic(14, 500, "#3c6af5", 1.57),
    },
    infoWrap: (props) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: props.isMobile ? 20 : 24,
    }),
    companyIdentityWrap: (props) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 24,
    }),

    logo: {
        height: 52,
        width: 52,
        //borderRadius: "50%",
        //border: "solid 1px #eeeeee",
        marginRight: 12,
    },
    name: {
        ...Texts.basic(14, "bold", "#111111", 1.57),
    },
    service: {
        ...Texts.basic(12, "normal", "#111111", 1.75),
    },
    ceo: {
        ...Texts.basic(12, "normal", "#ababab", 1.75),
    },
    hiringWrap: (props) => ({
        margin: props.isMobile ? 20 : 24,
    }),
    hiringTitle: {
        ...Texts.basic(14, "bold", "#111111", 1.57),
        marginBottom: 9,
    },
    positionBox: {
        borderRadius: 4,
        border: "solid 1px #eeeeee",
        backgroundColor: "#f8f8f8",
        textAlign: "center",
        marginBottom: 7,
        paddingTop: 8,
        paddingBottom: 8,
    },
    position: {
        ...Texts.basic(14, "normal", "#111111", 1.79),
    },
    salary: {
        ...Texts.basic(14, "normal", "#5f5f5f", 1.57),
    },
    addressWrap: (props) => ({
        margin: props.isMobile ? 20 : 24,
    }),
    addressBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
    },
    addressTitle: {
        ...Texts.basic(14, "bold", "#111111", 1.57),
        //marginBottom: 9,
    },
    address: {
        ...Texts.basic(14, "normal", "#5f5f5f", 1.5),
        //marginBottom: 9,
    },
    mapBtn: {
        ...Texts.basic(14, 500, "#ababab", 1.5),
        textAlign: "right",
        cursor: "pointer",
        textDecoration: "underline",
        marginBottom: 20,
    },
    actionsFixedWrap: {
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    actionsWrap: (props) => ({
        //width: "100%",
        margin: props.isMobile ? 20 : 24,
    }),
    question: (props) => ({
        ...Texts.basic(14, "bold", "#111111", 1.57),
        marginBottom: 9,
        display: props.isMobile && "none",
    }),
    applyBtn: {
        backgroundColor: "#0c46f2",
        width: "100%",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 15,
        textAlign: "center",
        cursor: "pointer",
        ...Texts.basic(14, "bold", "#ffffff", 1.57),
        marginTop: 16,
    },
    applyBtnDisabled: {
        backgroundColor: "#c5c5c5",
        width: "100%",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 15,
        textAlign: "center",
        ...Texts.basic(14, "bold", "#ffffff", 1.57),
        marginTop: 16,
    },
    btnSubTitle: {
        ...Texts.basic(12, "normal", "#ffffff", 1.75),
    },
    btnTitle: {
        ...Texts.basic(14, "bold", "#ffffff", 1.57),
    },
    companyInfoWrap: (props) => ({
        display: "flex",
        flexDirection: props.isMobile ? "column" : "row",
        padding: 24,
    }),
    companyInfoBox: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 8,
    },
    companyInfoTitle: {
        //flex: "1 0 50px",
        ...Texts.basic(16, 500, "#5f5f5f", 1.75),
        marginRight: 16,
    },
    companyInfoContent: {
        //flex: 11,
        ...Texts.basic(16, "normal", "#787878", 1.75),
        marginRight: 64,
    },
    resize: {
        width: "100%",
        fontSize: 14,
        height: "auto",
    },
    applicationTitle: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 4,
    },
    applicationForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(1, 0, 1),
            width: "100%",
        },
    },
    applicationFormD: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(0.5, 0, 0.5),
            width: "100%",
        },
    },
    menuTitle: (props) => ({
        marginTop: props.isMobile ? 100 : 132,
        ...Texts.basic(24, "bold", "#111111", 1.38),
        marginBottom: 32,
        position: "relative",
    }),
    subMenuTitle: (props) => ({
        ...Texts.basic(props.isMobile ? 20 : 24, "bold", "#111111", 1.38),
        marginBottom: props.isMobile ? 16 : 32,
        position: "relative",
    }),
    quoteBox: {
        //border: "solid 1px #111111",
        borderLeftStyle: "solid",
        borderLeftWidth: 2,
        borderLeftColor: "#111111",
        paddingLeft: 20,
        paddingTop: 8,
        paddingBottom: 8,
        marginTop: 47,
        marginBottom: 40,
    },
    quote: (props) => ({
        ...Texts.basic(props.isMobile ? 20 : 32, 500, "#111111", 1.41),
    }),
    scoutInfoWrap: {
        marginTop: 40,
        marginBottom: 132,
    },
    scoutPositionTitle: {
        marginTop: 25,
        ...Texts.basic(20, "bold", "#111111", 1.4),
        marginBottom: 12,
    },
    requiredHardskillWrap: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 24,
        flexWrap: "wrap",
    },
    requirementBox: {
        padding: 24,
        backgroundColor: "#f8f8f8",
    },
    requirementCategoryLabel: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    requirementContent: {
        ...Texts.basic(14, "normal", "#454545", 1.79),
        marginBottom: 32,
    },
    devTeamIntroduction: {
        marginTop: 20,
        marginBottom: 32,
        ...Texts.basic(16, "normal", "#111111", 1.75),
    },
    timeTable: {
        borderTopStyle: "solid",
        borderTopWidth: 2,
        borderTopColor: "#111111",
        marginBottom: 20,
    },
    timeTableRow: {
        display: "flex",
        flexDirection: "row",
    },
    timeTableLeftCol: {
        flex: 1,
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#c5c5c5",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#c5c5c5",
        paddingTop: 20,
    },
    timeTableLeftColText: (props) => ({
        ...Texts.basic(props.isMobile ? 20 : 16, "bold", "#454545", 1.38),
        marginBottom: 18,
    }),

    timeTableRightCol: (props) => ({
        flex: 2,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: props.isMobile ? 0 : 20,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#c5c5c5",
    }),
    timeTableRightColText: (props) => ({
        ...Texts.basic(16, props.isMobile ? 500 : "normal", "#454545", 1.38),
        marginBottom: 8,
    }),
    timeTableRightColNoticeBox: {
        backgroundColor: "#e6ecfe",
        borderRadius: 8,
        marginTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12,
    },
    timeTableRightColNoticeTitle: {
        ...Texts.basic(15, 500, "#0c46f2", 1.33),
        marginBottom: 7,
    },
    timeTableRightColNotice: {
        ...Texts.basic(14, "normal", "#0c46f2", 1.43),
    },
    kakaoOpenChatTitleBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 68,
        marginBottom: 32,
    },
    kakaoOpenChatTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
    },
    kakaoOpenChatBtn: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        border: "solid 1px #0c46f2",
        borderRadius: 8,
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 20,
        paddingRight: 20,
        cursor: "pointer",
    },
    kakaoOpenChatLabel: {
        ...Texts.basic(16, 500, "#0c46f2", 1.38),
        marginRight: 4,
    },
    drawAnnoucement: {
        ...Texts.basic(16, 500, "#929292", 1.38),
    },
    bigTitle: {
        marginTop: 132,
        marginBottom: 24,
        ...Texts.basic(36, "bold", "#111111", 1.39),
        position: "relative",
    },
    noticeTitle: {
        ...Texts.basic(16, 500, "#111111", 1.38),
        marginBottom: 8,
        position: "relative",
    },
    extraNoticeTitle: {
        ...Texts.basic(16, 500, "#111111", 1.38),
        marginTop: 40,
        marginBottom: 8,
        position: "relative",
    },
    noticeItem: {
        ...Texts.basic(14, "normal", "#454545", 1.79),
    },
    faqTitle: {
        marginTop: 64,
        marginBottom: 24,
        ...Texts.basic(36, "bold", "#111111", 1.39),
        position: "relative",
    },
    faqNoticeBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 12,
        paddingLeft: 16,
        backgroundColor: "#f8f8f8",
    },
    faqNotice: {
        marginLeft: 8,
        ...Texts.basic(14, "normal", "#787878", 1.79),
    },
    openScoutFooter: {
        marginTop: 100,
        backgroundColor: "#3c6af5",
        padding: 32,
        textAlign: "center",
    },
    openScoutFooterTitle: {
        ...Texts.basic(16, "normal", "#9db4fa", 1.38),
    },
    actionBoxFixed: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        zIndex: 2,
        textAlign: "center",
    },
    promotionPlaCardFixed: {
        backgroundColor: "#111111",
        height: 40,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    promotionPlaCardFixedTitle: {
        ...Texts.basic(12, "bold", "#ffffff", 1.33),
    },
    applyBtnFixed: {
        padding: 15,
        backgroundColor: "#3c6af5",
    },
    applyBtnFixedTitle: {
        ...Texts.basic(14, "bold", "#ffffff", 1.57),
    },
    questionItem: {
        marginTop: 24,
        ...Texts.basic(16, "bold", "#111111", 1.38),
    },
    answerItem: {
        marginTop: 8,
        ...Texts.basic(14, "normal", "#454545", 1.79),
    },
    historyBox: (props) => ({
        marginTop: 32,
        padding: 32,
        paddingLeft: props.isMobile ? 20 : 32,
        paddingRight: props.isMobile ? 20 : 32,
        backgroundColor: "#f8f8f8",
    }),
    historyTitle: {
        ...Texts.basic(16, 500, "#787878", 1.38),
        position: "relative",
    },
    historyYear: {
        marginTop: 32,
        ...Texts.basic(40, 500, "#2c2c2c", 1.4),
        marginBottom: 12,
    },
    historyItem: {
        display: "flex",
        flexDirection: "row",
        //alignItems: "center",
    },
    historyMonth: {
        flex: "1 0 40px",
        ...Texts.basic(16, "normal", "#c5c5c5", 1.75),
    },
    historyContent: {
        flex: 11,
        ...Texts.basic(16, "normal", "#454545", 1.75),
    },
    openScoutMenuBar: (props) => ({
        position: "-webkit-sticky",
        position: "sticky",
        top: 56,
        backgroundColor: "#ffffff",
        height: props.isMobile ? "auto" : 70,
        borderBottomWidth: 1,
        borderBottomStyle: props.isMobile ? "solid" : undefined,
        borderBottomColor: "#eeeeee",
        zIndex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        paddingTop: props.isMobile ? 0 : 20,
        marginBottom: props.isMobile ? 40 : 30,
        overflowX: "auto",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }),
    scrollBtn: {
        flexShrink: 0,
        borderBottomStyle: "solid",
        borderBottomColor: "#0c46f2",
        borderBottomWidth: 3,
        paddingTop: 15,
        paddingBottom: 14,
        marginRight: 24,
        ...Texts.basic(14, "bold", "#111111", 1.36),
    },
    scrollBtnOff: {
        flexShrink: 0,
        paddingTop: 15,
        paddingBottom: 14,
        marginRight: 24,
        ...Texts.basic(14, 500, "#929292", 1.36),
        cursor: "pointer",
    },
    askingChip: {
        backgroundColor: "#2c2c2c",
        padding: 12,
        borderRadius: 8,
        display: "inline-box",
        ...Texts.basic(16, 500, "#ffffff", 1.38),
        marginBottom: 20,
    },

    askingTooltip: {
        backgroundColor: "#0c46f2",
        padding: 12,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        display: "inline-box",
        ...Texts.basic(14, "bold", "#ffffff", 1.36),
        marginBottom: 20,
    },
    scrollCap: (props) => ({
        position: "absolute",
        top: props.isMobile ? -130 : -160,
    }),
    successImg: (props) => ({
        width: "100%",
        marginTop: 32,
        marginBottom: props.isMobile ? 20 : 137,
    }),
    successImgBottom: (props) => ({
        width: "100%",
        marginBottom: 137,
    }),
    serviceImg: {
        width: "100%",
        marginBottom: 137,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    modalScreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBottomUpScreen: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    modalBottomUp: {
        backgroundColor: theme.palette.background.paper,
        outline: 0,

        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        width: "100%",
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        outline: 0,
        textAlign: "center",
        borderRadius: 12,
        width: 320,
    },
    modalBoxTop: {
        padding: "32px 20px 24px",
    },
    modalBoxMiddle: {
        padding: "24px 20px 29px",
    },
    modalBoxBottom: {
        padding: 20,
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 8,
    },
    modalMessage: {
        fontSize: 16,
        fontWeight: 500,
        color: "#222222",
        marginBottom: 12,
    },
    modalInfo: {
        fontSize: 12,
        fontWeight: 300,
        color: "#999999",
    },
    modalButton: {
        width: "100%",
        fontSize: 16,
        height: 56,
    },
    promotionTooltip: {
        position: "absolute",
        width: "100%",
        bottom: -75,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    promotionTooltipArrow: {
        width: 0,
        height: 0,
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderBottom: "10px solid black",
    },
    promotionTooltipIconBox: {
        borderRadius: 10,
        width: 20,
        height: 20,
        backgroundColor: "#3c6af5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 4,
    },
    promotionTooltipBody: {
        borderRadius: 4,
        padding: 14,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: "#111111",
        ...Texts.basic(14, "bold", "#ffffff", 1.36),
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    modalIcon: {
        marginBottom: 8,
    },
    modalQuestion: {
        ...Texts.basic(15, "bold", "#111111", 1.38),
        marginBottom: 21,
    },
    modalLink: {
        ...Texts.basic(16, "bold", "#0c46f2", 1.38),
    },
    sectionDivider: {
        height: 10,
    },
    inputFieldsBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        margin: 0,
    },
    leftInputFieldBox: {
        flex: 5,
        marginTop: 4,
        marginRight: 8,
        marginBottom: 4,
    },
    rightInputFieldBox: {
        flex: 5,
        marginTop: 4,
        marginBottom: 4,
    },
    inlinePicker: {
        fontSize: 14,
        marginBottom: 8,
    },
}));
