import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setToken,
    setEnableRecommendation,
    setProfile,
    setBasicProfile,
    setHopeSalaryAsync,
    setHopeSalaryRangeAsync,
    setHopeWorkBeginAt,
} from "../../redux/slice/profileSlice";
import { useHistory } from "react-router-dom";
import {
    Container,
    Box,
    Typography,
    Divider,
    Tooltip,
    Button,
    Popover,
    TextField,
    FormControl,
    Select,
    MenuItem,
    IconButton,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
    ProfileHeaderD,
    ServiceFooterD,
    AntSwitch,
    IOSSlider,
} from "../components";
import { Helmet } from "react-helmet";
import { useStyles } from "./styleProfile";
import { TXImage } from "../../components/tx";
import { Create } from "@material-ui/icons";

import { hopePositions, deviconMap, devicon } from "data";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import DaumPostcode from "react-daum-postcode";

import ProfileProgress from "./ProfileProgress";
import ProfileSchool from "./ProfileSchool";
import ProfileCompany from "./ProfileCompany";
import ProfileProject from "./ProfileProject";
import ProfilePortfolioLink from "./ProfilePortfolioLink";
// import ProfilePortfolio from "./ProfilePortfolio";
// import ProfileMilitary from "./ProfileMilitary";

import {
    getTalentProfile,
    updateTalentProfileHopePosition,
    updateTalentProfileBasicinfo,
    updateTalentPreferenceEnableRecommendation,
} from "../../apis/profile/profile";

import { displayLocalBirthDayAndAge } from "../../helper/misc";

function salaryRangeStr(min, max) {
    let minSalaryR = (min % 100000000) / 10000;
    let minSalaryM = parseInt(min / 100000000);
    let maxSalaryR = (max % 100000000) / 10000;
    let maxSalaryM = parseInt(max / 100000000);

    if (min === max) {
        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        }`;
    } else {
        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        } ~ ${maxSalaryM ? maxSalaryM + "억" : ""}${
            maxSalaryR ? " " + maxSalaryR + "만원" : "원"
        }`;
    }
}

function valuetext(value) {
    return `${value}만원`;
}

const marks = [
    {
        value: 30000000,
        label: "3000만원",
    },
    {
        value: 100000000,
        label: "1억원",
    },
];

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#3c6af5",
    },
    tooltip: {
        backgroundColor: "#3c6af5",
    },
    popper: {
        zIndex: 1,
    },
}));

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    const classes = useStylesBootstrap();

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={value}
            arrow
            classes={classes}
        >
            {children}
        </Tooltip>
    );
}

const displayGithubLink = (link) => {
    let newLink;
    if (link.startsWith("http://") || link.startsWith("https://")) {
        newLink = link;
    } else if (link) {
        newLink = `http://${link}`;
    } else {
        newLink = "";
    }
    return newLink ? (
        <a
            href={newLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
        >
            {newLink}
        </a>
    ) : (
        "깃허브 링크를 입력해주세요"
    );
};

const displayAddress = (address) => {
    return address
        ? address.split(" ").slice(0, 3).join(" ")
        : "거주지를 입력해주세요";
};

const displayHopePosition = (hopePosition) => {
    return hopePosition ? `${hopePosition} 희망` : "희망 직무를 선택해 주세요";
};

const expYearEnum = [
    "신입",
    "1년차",
    "2년차",
    "3년차",
    "4년차",
    "5년차",
    "6년차",
    "7년차",
    "8년차",
    "9년차",
    "10년차 이상",
];

const Profile = (props) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    console.log("token", token);
    const history = useHistory();
    if (!token) history.push("/profile/access-expired");
    const dispatch = useDispatch();
    dispatch(setToken(token));
    const profile = useSelector((state) => state.profile);
    const classes = useStyles();
    const today = new Date();

    useEffect(() => {
        async function fetchTalentProfile() {
            const cloudProfile = await getTalentProfile(token);
            console.log(cloudProfile);
            if (cloudProfile) {
                if (
                    !cloudProfile.hopeWorkBeginAt ||
                    cloudProfile.hopeWorkBeginAt?.split("T")[0] <
                        today.toISOString().split("T")[0]
                ) {
                    cloudProfile.hopeWorkBeginAt = profile.hopeWorkBeginAt;
                    const updateResult = updateTalentProfileBasicinfo(token, {
                        hopeWorkBeginAt: profile.hopeWorkBeginAt,
                    });
                    if (!updateResult) history.push("/profile/access-expired");
                }
                dispatch(setProfile(cloudProfile));
            } else {
                history.push("/profile/access-expired");
            }
        }
        if (token && profile.photos[0].url === null) {
            fetchTalentProfile();
        }
    }, []);

    const handlePhotoChange = () => {
        history.push(`/profile/photo`);
    };

    const handleSkillEdit = () => {
        history.push(`/profile/skill`);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleEnableRecommendationChange = async (event) => {
        const { checked } = event.target;
        const updateResult = await updateTalentPreferenceEnableRecommendation(
            token,
            { enableRecommendation: checked }
        );
        if (updateResult?.affected === 1) {
            dispatch(setEnableRecommendation(checked));
        } else if (!updateResult) history.push("/profile/access-expired");
    };

    const handleImgError = (e) => {
        e.target.src = require("assets/icon-skill-default-52.png");
    };

    const handleHopeSalaryChangeAsync = (event, newValue) => {
        console.log("Changing value", newValue);
        dispatch(setHopeSalaryAsync(newValue));
    };

    const handleHopeSalaryChangeCommittedAsync = async (event, newValue) => {
        console.log("Commiting value", newValue);
        const updateResult = await updateTalentProfileBasicinfo(token, {
            hopeSalary: newValue,
        });
        if (!updateResult) history.push("/profile/access-expired");
    };

    const handleHopeSalaryRangeChangeAsync = (event, newValue) => {
        console.log("Changing value", newValue);
        dispatch(setHopeSalaryRangeAsync(newValue));
    };

    const handleHopeSalaryRangeChangeCommittedAsync = async (
        event,
        newValue
    ) => {
        console.log("Commiting value", newValue);
        const updateResult = await updateTalentProfileBasicinfo(token, {
            hopeSalary: newValue[1],
        });
        if (!updateResult) history.push("/profile/access-expired");
    };

    const handleHopeWorkBeginAtChange = async (date, value) => {
        console.log("handleHopeWorkStartDateChange value", value);
        console.log("handleHopeWorkStartDateChange date", date);
        console.log(
            "handleHopeWorkStartDateChange date.toJSON()",
            date?.toJSON()
        );
        dispatch(setHopeWorkBeginAt(date?.toJSON() ?? value));
        if (date?.toJSON()) {
            const updateResult = updateTalentProfileBasicinfo(token, {
                hopeWorkBeginAt: date.toJSON(),
            });
            if (!updateResult) history.push("/profile/access-expired");
        }
    };

    const [basicProfileEdit, setBasicProfileEdit] = useState(false);
    const [basicProfileBeingCreated, setBasicProfileBeingCreated] = useState();

    const initBasicProfileBeingCreated = () => {
        console.log("profile", profile);
        setBasicProfileBeingCreated({
            name: profile.name,
            birth: profile.birth,
            hopePosition: profile.hopePosition,
            position: profile.position,
            positionExpYears: profile.positionExpYears,
            devExpYears: profile.devExpYears,
            postalCode: profile.postalCode,
            address: profile.address,
            addressDetail: profile.addressDetail,
            // githubLink: profile.githubLink,
            introduction: profile.introduction,
        });
    };

    const saveBasicProfileBeingCreated = async () => {
        console.log("basicProfileBeingCreated", basicProfileBeingCreated);
        const submittable =
            !!basicProfileBeingCreated.name &&
            basicProfileBeingCreated.birth?.includes("T") &&
            !!basicProfileBeingCreated.hopePosition &&
            !!basicProfileBeingCreated.position &&
            !!basicProfileBeingCreated.positionExpYears &&
            !!basicProfileBeingCreated.devExpYears &&
            !!basicProfileBeingCreated.postalCode &&
            !!basicProfileBeingCreated.address &&
            !!basicProfileBeingCreated.addressDetail;
        console.log("submittable", submittable);
        console.log(
            'basicProfileBeingCreated.birth.includes("T")',
            basicProfileBeingCreated.birth?.includes("T")
        );

        if (submittable) {
            if (basicProfileBeingCreated.hopePosition !== profile.hopePosition)
                await updateTalentProfileHopePosition(token, {
                    hopePosition: basicProfileBeingCreated.hopePosition,
                });
            dispatch(setBasicProfile(basicProfileBeingCreated));

            const updateResult = await updateTalentProfileBasicinfo(token, {
                name: basicProfileBeingCreated.name,
                birth: basicProfileBeingCreated.birth,
                mainPosition: basicProfileBeingCreated.position,
                mainCareer: basicProfileBeingCreated.positionExpYears,
                totalCareer: basicProfileBeingCreated.devExpYears,
                zipCode: basicProfileBeingCreated.postalCode,
                address1: basicProfileBeingCreated.address,
                address2: basicProfileBeingCreated.addressDetail,
                introduction: basicProfileBeingCreated.introduction,
            });
            if (updateResult) setBasicProfileEdit(false);
            else history.push("/profile/access-expired");
        }
    };

    const deleteBasicProfileBeingCreated = () => {
        initBasicProfileBeingCreated();
        setBasicProfileEdit(false);
    };

    const handleBasicProfileChange = (event) => {
        console.log("basicProfileBeingCreated", basicProfileBeingCreated);
        const key = event.target.id || event.target.name;
        const value = event.target.value;
        console.log("key", key);
        setBasicProfileBeingCreated((prev) => {
            const newBasicProfile = { ...prev };
            newBasicProfile[key] = value;
            if (key === "hopePosition" && newBasicProfile.position === "") {
                newBasicProfile.position = value;
            }
            if (
                key === "positionExpYears" &&
                (newBasicProfile.devExpYears === "" ||
                    expYearEnum.indexOf(newBasicProfile.devExpYears) <
                        expYearEnum.indexOf(value))
            ) {
                newBasicProfile.devExpYears = value;
            }

            return newBasicProfile;
        });
    };

    const handleBasicProfileBirthDayChange = (date, value) => {
        console.log("handleBasicProfileBirthDayChange value", value);
        console.log("handleBasicProfileBirthDayChange date", date);
        console.log(
            "handleBasicProfileBirthDayChange date.toJSON()",
            date?.toJSON()
        );
        setBasicProfileBeingCreated((prev) => {
            const newBasicProfile = { ...prev };
            newBasicProfile.birth = date?.toJSON() ?? value;
            return newBasicProfile;
        });
    };

    const devPositionExpYearTexter = (
        position,
        positionExpYears,
        devExpYears
    ) => {
        let positionText = "소프트웨어 엔지니어";
        let positionExpYearsText = "신입",
            devExpYearsText = "";
        if (position) positionText = position;
        if (positionExpYears) positionExpYearsText = positionExpYears;
        if (devExpYears) devExpYearsText = ` | 총 개발 경력 ${devExpYears}`;
        return position
            ? `${positionText} ${positionExpYearsText}${devExpYearsText}`
            : "현재 직무와 경력을 입력해주세요";
    };

    //Adress Input
    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    const AddressSearchButton = () => {
        return (
            <Button
                onClick={onChangeOpenPost}
                variant="outlined"
                //color="primary"
                disableElevation
                className={classes.searchButton}
            >
                검색
            </Button>
        );
    };

    const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddr += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddr +=
                    extraAddr !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
        }

        setBasicProfileBeingCreated((prev) => {
            const newBasicProfile = { ...prev };
            newBasicProfile.postalCode = data.zonecode;
            newBasicProfile.address = fullAddr;
            return newBasicProfile;
        });

        setIsOpenPost(false);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필 작성</title>
            </Helmet>
            <ProfileHeaderD />
            <Container maxWidth="lg">
                <Box className={classes.inner}>
                    <Box className={classes.leftSection}>
                        {profile.email ? (
                            <Box className={classes.basicProfile}>
                                <Box className={classes.profileImgWrap}>
                                    {profile.photos[
                                        profile.photos.findIndex(
                                            (photo) => photo.order === 0
                                        )
                                    ].url ? (
                                        <img
                                            src={
                                                profile.photos[
                                                    profile.photos.findIndex(
                                                        (photo) =>
                                                            photo.order === 0
                                                    )
                                                ].url
                                            }
                                            className={classes.profileImg}
                                            alt="profile"
                                        />
                                    ) : (
                                        <Typography
                                            className={
                                                classes.profileImgPlaceholder
                                            }
                                        >
                                            프로필 사진을 등록해주세요
                                        </Typography>
                                    )}
                                    <Box
                                        className={classes.profileImgUploadBtn}
                                        onClick={handlePhotoChange}
                                    >
                                        <TXImage imgName="icon-edit-white-24-dp" />
                                    </Box>
                                </Box>
                                {basicProfileBeingCreated &&
                                basicProfileEdit ? (
                                    <form className={classes.form}>
                                        <Typography
                                            className={classes.inputTitle}
                                        >
                                            이름
                                        </Typography>
                                        <TextField
                                            required
                                            className={classes.textField}
                                            inputProps={{ maxLength: 10 }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                    notchedOutline:
                                                        classes.noBorder,
                                                },
                                            }}
                                            id="name"
                                            variant="outlined"
                                            placeholder="이름"
                                            value={
                                                basicProfileBeingCreated.name
                                            }
                                            onChange={handleBasicProfileChange}
                                        />
                                        <Typography
                                            className={classes.inputTitle}
                                        >
                                            생년월일
                                        </Typography>

                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                required
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.birthInput,
                                                        root: classes.adornedEnd2,
                                                        notchedOutline:
                                                            classes.testBorder,
                                                    },
                                                }}
                                                KeyboardButtonProps={{
                                                    classes: {
                                                        root: classes.keyboardBtn,
                                                    },
                                                    "aria-label": "change date",
                                                }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="yyyy/MM/dd"
                                                invalidDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        YYYY/MM/DD 형식으로
                                                        입력해 주세요!
                                                    </Typography>
                                                }
                                                margin="normal"
                                                id="birth"
                                                disableFuture
                                                maxDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        오늘 이전 날짜를 입력해
                                                        주세요!
                                                    </Typography>
                                                }
                                                value={
                                                    basicProfileBeingCreated.birth
                                                }
                                                onChange={
                                                    handleBasicProfileBirthDayChange
                                                }
                                                keyboardIcon={<Create />}
                                                autoOk={true}
                                            />
                                        </MuiPickersUtilsProvider>

                                        <Typography
                                            className={classes.inputTitle}
                                        >
                                            희망 직무
                                        </Typography>

                                        <FormControl
                                            variant="outlined"
                                            style={{
                                                marginBottom: 0,
                                            }}
                                        >
                                            <Select
                                                className={
                                                    classes.noBorderSelect
                                                }
                                                labelId="demo-simple-select-label"
                                                id="hopePosition"
                                                name="hopePosition"
                                                displayEmpty
                                                value={
                                                    basicProfileBeingCreated.hopePosition
                                                }
                                                onChange={
                                                    handleBasicProfileChange
                                                }
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                <MenuItem
                                                    value=""
                                                    classes={{
                                                        root: classes.resize,
                                                    }}
                                                    disabled
                                                >
                                                    선택해주세요.
                                                </MenuItem>
                                                {hopePositions.map(
                                                    (item, idx) => (
                                                        <MenuItem
                                                            key={idx}
                                                            value={item}
                                                            classes={{
                                                                root: classes.resize,
                                                            }}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        <Box
                                            className={
                                                classes.positionYearsWrap
                                            }
                                        >
                                            <FormControl
                                                variant="outlined"
                                                className={classes.position}
                                            >
                                                <Typography
                                                    className={
                                                        classes.inputTitle
                                                    }
                                                >
                                                    현재 직무
                                                </Typography>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="position"
                                                    name="position"
                                                    displayEmpty
                                                    value={
                                                        basicProfileBeingCreated.position
                                                    }
                                                    onChange={
                                                        handleBasicProfileChange
                                                    }
                                                    classes={{
                                                        root: classes.resize,
                                                    }}
                                                    className={
                                                        classes.noBorderSelect
                                                    }
                                                >
                                                    <MenuItem
                                                        value=""
                                                        classes={{
                                                            root: classes.resize,
                                                        }}
                                                        disabled
                                                    >
                                                        선택
                                                    </MenuItem>
                                                    {hopePositions.map(
                                                        (item, idx) => (
                                                            <MenuItem
                                                                key={idx}
                                                                value={item}
                                                                classes={{
                                                                    root: classes.resize,
                                                                }}
                                                            >
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.years}
                                            >
                                                <Typography
                                                    className={
                                                        classes.inputTitle
                                                    }
                                                >
                                                    해당 경력
                                                </Typography>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="positionExpYears"
                                                    name="positionExpYears"
                                                    displayEmpty
                                                    value={
                                                        basicProfileBeingCreated.positionExpYears
                                                    }
                                                    onChange={
                                                        handleBasicProfileChange
                                                    }
                                                    classes={{
                                                        root: classes.resize,
                                                    }}
                                                    className={
                                                        classes.noBorderSelect
                                                    }
                                                >
                                                    <MenuItem
                                                        value=""
                                                        classes={{
                                                            root: classes.resize,
                                                        }}
                                                        disabled
                                                    >
                                                        선택
                                                    </MenuItem>
                                                    {expYearEnum.map(
                                                        (item, idx) => (
                                                            <MenuItem
                                                                key={idx}
                                                                value={item}
                                                                classes={{
                                                                    root: classes.resize,
                                                                }}
                                                            >
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <FormControl variant="outlined">
                                            <Typography
                                                className={classes.inputTitle}
                                            >
                                                총 개발 경력
                                            </Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="devExpYears"
                                                name="devExpYears"
                                                displayEmpty
                                                value={
                                                    basicProfileBeingCreated.devExpYears
                                                }
                                                onChange={
                                                    handleBasicProfileChange
                                                }
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                                className={
                                                    classes.noBorderSelect
                                                }
                                            >
                                                <MenuItem
                                                    value=""
                                                    classes={{
                                                        root: classes.resize,
                                                    }}
                                                    disabled
                                                >
                                                    선택
                                                </MenuItem>
                                                {expYearEnum
                                                    .slice(
                                                        basicProfileBeingCreated.positionExpYears
                                                            ? expYearEnum.indexOf(
                                                                  basicProfileBeingCreated.positionExpYears
                                                              )
                                                            : 0
                                                    )
                                                    .map((item, idx) => (
                                                        <MenuItem
                                                            key={idx}
                                                            value={item}
                                                            classes={{
                                                                root: classes.resize,
                                                            }}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        <Box
                                            className={classes.addressTitleWrap}
                                        >
                                            <Typography
                                                className={classes.inputTitle}
                                            >
                                                거주지 주소
                                            </Typography>
                                            {isOpenPost ? (
                                                <IconButton
                                                    className={classes.closeBtn}
                                                    aria-label="close"
                                                    onClick={onChangeOpenPost}
                                                    size="small"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            ) : (
                                                <Box />
                                            )}
                                        </Box>
                                        {isOpenPost ? (
                                            <DaumPostcode
                                                style={{
                                                    display: "block",
                                                    position: "relative",
                                                    top: "50%",
                                                    width: "100%",
                                                    height: "500px",
                                                    //padding: "7px",
                                                }}
                                                autoClose
                                                onComplete={onCompletePost}
                                            />
                                        ) : (
                                            <>
                                                <TextField
                                                    required
                                                    style={{
                                                        marginBottom: 8,
                                                        backgroundColor:
                                                            "#f4f4f4",
                                                    }}
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.resize2,
                                                            input: classes.resize,
                                                            notchedOutline:
                                                                classes.noBorder,
                                                        },
                                                        endAdornment: (
                                                            <AddressSearchButton />
                                                        ),
                                                    }}
                                                    id="postalCode"
                                                    variant="outlined"
                                                    placeholder="우편번호"
                                                    value={
                                                        basicProfileBeingCreated.postalCode
                                                    }
                                                    onChange={
                                                        handleBasicProfileChange
                                                    }
                                                />
                                                <TextField
                                                    required
                                                    style={{
                                                        marginBottom: 8,
                                                        backgroundColor:
                                                            "#f4f4f4",
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                            notchedOutline:
                                                                classes.noBorder,
                                                        },
                                                    }}
                                                    id="address"
                                                    variant="outlined"
                                                    placeholder="기본 주소"
                                                    value={
                                                        basicProfileBeingCreated.address
                                                    }
                                                    onChange={
                                                        handleBasicProfileChange
                                                    }
                                                />
                                                <TextField
                                                    required
                                                    style={{
                                                        marginBottom: 0,
                                                        backgroundColor:
                                                            "#f4f4f4",
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                            notchedOutline:
                                                                classes.noBorder,
                                                        },
                                                    }}
                                                    id="addressDetail"
                                                    variant="outlined"
                                                    placeholder="상세 주소"
                                                    value={
                                                        basicProfileBeingCreated.addressDetail
                                                    }
                                                    onChange={
                                                        handleBasicProfileChange
                                                    }
                                                />
                                            </>
                                        )}
                                        {/* <Typography
                                            className={classes.inputTitle}
                                        >
                                            깃허브 링크 (선택)
                                        </Typography>
                                        <TextField
                                            className={classes.textField}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                    notchedOutline:
                                                        classes.noBorder,
                                                },
                                            }}
                                            id="githubLink"
                                            variant="outlined"
                                            placeholder="https://github.com/talentx"
                                            value={
                                                basicProfileBeingCreated.githubLink
                                            }
                                            onChange={handleBasicProfileChange}
                                        /> */}
                                        <Typography
                                            className={classes.inputTitle}
                                        >
                                            자기 소개 (선택)
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.textField}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                    notchedOutline:
                                                        classes.noBorder,
                                                },
                                            }}
                                            id="introduction"
                                            placeholder="간략한 자기 소개를 입력해주세요"
                                            value={
                                                basicProfileBeingCreated.introduction
                                            }
                                            onChange={handleBasicProfileChange}
                                            multiline
                                            rows={3}
                                        />
                                        <Box
                                            className={
                                                classes.basicInfoEditBtnWrap
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.basicInfoEditCancleBtn
                                                }
                                                onClick={
                                                    deleteBasicProfileBeingCreated
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.basicProfileEditCancleBtnLabel
                                                    }
                                                >
                                                    취소
                                                </Typography>
                                            </Box>
                                            <Box
                                                className={
                                                    classes.basicInfoEditSaveBtn
                                                }
                                                onClick={
                                                    saveBasicProfileBeingCreated
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.basicProfileEditSaveBtnLabel
                                                    }
                                                >
                                                    저장
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </form>
                                ) : (
                                    <>
                                        <Box
                                            className={
                                                classes.talentNameOfferSetWrap
                                            }
                                        >
                                            <Box>
                                                <Typography
                                                    className={
                                                        classes.talentName
                                                    }
                                                >
                                                    {profile.name}
                                                </Typography>
                                            </Box>

                                            <Box
                                                className={classes.offerSetWrap}
                                            >
                                                <Typography
                                                    className={
                                                        classes.offerSetTitle
                                                    }
                                                >
                                                    영입 제안 받기
                                                </Typography>
                                                <Box
                                                    aria-owns={
                                                        open
                                                            ? "mouse-over-popover"
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onMouseEnter={
                                                        handlePopoverOpen
                                                    }
                                                    onMouseLeave={
                                                        handlePopoverClose
                                                    }
                                                    className={
                                                        classes.offerSetHelpIconWrap
                                                    }
                                                >
                                                    <TXImage imgName="icon-help-question-18-dp" />
                                                </Box>
                                                <Popover
                                                    id="mouse-over-popover"
                                                    className={classes.popover}
                                                    classes={{
                                                        paper: classes.paper,
                                                    }}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    onClose={handlePopoverClose}
                                                    disableRestoreFocus
                                                    elevation={1}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.popoverTitle
                                                        }
                                                    >
                                                        '영입 제안 받기'가
                                                        뭔가요?
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.popoverDesc
                                                        }
                                                    >
                                                        기업으로부터 매력적인
                                                        영입 제안을 받을 수 있는
                                                        기능으로, 채용 담당자가
                                                        귀하의 프로필을 확인하고
                                                        먼저 제안을 보내
                                                        드립니다.
                                                    </Typography>
                                                </Popover>
                                                <AntSwitch
                                                    checked={
                                                        profile.enableRecommendation
                                                    }
                                                    onChange={
                                                        handleEnableRecommendationChange
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        <Box
                                            className={
                                                classes.targetPositionWrap
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.targetPosition
                                                }
                                            >
                                                {displayHopePosition(
                                                    profile.hopePosition
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.basicInfoWrap}>
                                            <TXImage imgName="icon-calendar-24-dp" />
                                            <Typography
                                                className={classes.basicInfo}
                                            >
                                                {displayLocalBirthDayAndAge(
                                                    profile.birth
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.basicInfoWrap}>
                                            <TXImage imgName="icon-briefcase-24-dp" />
                                            <Typography
                                                className={classes.basicInfo}
                                            >
                                                {devPositionExpYearTexter(
                                                    profile.position,
                                                    profile.positionExpYears,
                                                    profile.devExpYears
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.basicInfoWrap}>
                                            <TXImage imgName="icon-location-24-dp" />
                                            <Typography
                                                className={classes.basicInfo}
                                            >
                                                {displayAddress(
                                                    profile.address
                                                )}
                                            </Typography>
                                        </Box>
                                        {/* <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-github-18-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {displayGithubLink(
                                                profile.githubLink
                                            )}
                                        </Typography>
                                    </Box> */}
                                        {/* <Typography
                                            className={classes.selfIntroEmpty}
                                        >
                                            {profile.introduction}
                                        </Typography> */}
                                        {profile.introduction && (
                                            <>
                                                <Typography
                                                    className={
                                                        classes.selfIntroductionTitle
                                                    }
                                                >
                                                    자기 소개
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.selfIntroduction
                                                    }
                                                >
                                                    {profile.introduction}
                                                </Typography>
                                            </>
                                        )}
                                        <Box
                                            className={classes.basicInfoEditBtn}
                                            onClick={() => {
                                                initBasicProfileBeingCreated();
                                                setBasicProfileEdit(true);
                                            }}
                                        >
                                            <Typography
                                                className={
                                                    classes.basicProfileEditBtnLabel
                                                }
                                            >
                                                기본 정보 편집
                                            </Typography>
                                        </Box>
                                    </>
                                )}

                                <Divider className={classes.divider} />

                                <Box className={classes.skillHeader}>
                                    <Typography className={classes.skillLabel}>
                                        보유 기술
                                    </Typography>
                                    <Tooltip
                                        title="자신이 보유한 기술 중에서 희망 직무와 연관된 기술 스택을 입력할수록 더 알맞은 스카우트 제안을 받을 수 있습니다."
                                        placement="top"
                                        classes={{
                                            tooltip: classes.tooltip,
                                        }}
                                    >
                                        <Box>
                                            <TXImage
                                                imgName="icon-help-question-24-dp"
                                                className={classes.questionMark}
                                            />
                                        </Box>
                                    </Tooltip>
                                    <Box className={classes.flexGrowBox}></Box>
                                    <TXImage
                                        imgName="icon-edit-24-dp"
                                        onClick={handleSkillEdit}
                                        style={{ cursor: "pointer" }}
                                    />
                                </Box>
                                <Box className={classes.skillWrap}>
                                    {profile.skills.map((item, index) => (
                                        <Box
                                            key={index}
                                            className={classes.skillChip}
                                        >
                                            <img
                                                className={classes.skillIcon}
                                                src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                                    item.skill
                                                )}/${deviconMap.get(
                                                    item.skill
                                                )}-${
                                                    devicon.find(
                                                        (obj) =>
                                                            obj.name ===
                                                            deviconMap.get(
                                                                item.skill
                                                            )
                                                    )?.versions.svg[0]
                                                }.svg`}
                                                onError={handleImgError}
                                                alt={item.skill}
                                            />
                                            <Typography
                                                className={classes.skillName}
                                            >
                                                {item.skill}
                                            </Typography>
                                            <Typography
                                                className={classes.skillLevel}
                                            >
                                                {item.rate}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>

                                {/* <Divider className={classes.divider} />
                                <Tooltip
                                    title="프로필 작성 퀘스트를 완료하시고 특별 배지를 획득하세요!"
                                    placement="top"
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                >
                                    <Box className={classes.badgePlaceholder}>
                                        <TXImage imgName="icon-lock-24-dp" />
                                    </Box>
                                </Tooltip> */}
                            </Box>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                className={classes.leftSectionSkeleton}
                            />
                        )}
                    </Box>

                    <Box className={classes.rightSection}>
                        {profile.email ? (
                            <Box className={classes.detailProfile}>
                                <ProfileProgress />
                                <Box className={classes.hopeSalaryWrap}>
                                    <Typography
                                        className={classes.hopeSalaryLabel}
                                    >
                                        희망 연봉
                                    </Typography>
                                    <Typography
                                        className={classes.hopeSalaryNotice}
                                    >
                                        기업 담당자에게 노출되는 정보입니다.
                                    </Typography>
                                    <Typography
                                        className={classes.hopeSalaryRange}
                                    >
                                        {salaryRangeStr(
                                            profile.hopeSalary,
                                            profile.hopeSalary
                                        )}
                                    </Typography>
                                    <Box
                                        className={
                                            classes.hopeSalaryRangeSlider
                                        }
                                    >
                                        <IOSSlider
                                            getAriaLabel={() => "Salary range"}
                                            min={30000000}
                                            max={100000000}
                                            step={1000000}
                                            value={profile.hopeSalary}
                                            scale={(x) => x / 10000}
                                            onChange={
                                                handleHopeSalaryChangeAsync
                                            }
                                            onChangeCommitted={
                                                handleHopeSalaryChangeCommittedAsync
                                            }
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                            getAriaValueText={valuetext}
                                            ValueLabelComponent={
                                                ValueLabelComponent
                                            }
                                        />
                                    </Box>
                                    {profile.hopeSalary >= 100000000 && (
                                        <Typography
                                            className={
                                                classes.hopeSalaryInputGuide
                                            }
                                        >
                                            희망연봉이 1억원을 초과하는 경우
                                            앱에서 입력해 주세요.
                                        </Typography>
                                    )}

                                    {/* <Typography className={classes.hopeSalaryRange}>
                                    {salaryRangeStr(
                                        profile.hopeSalaryRange[0],
                                        profile.hopeSalaryRange[1]
                                    )}
                                </Typography>
                                <Box className={classes.hopeSalaryRangeSlider}>
                                    <IOSSlider
                                        getAriaLabel={() => "Salary range"}
                                        min={3000}
                                        max={20000}
                                        step={100}
                                        value={[...profile.hopeSalaryRange]}
                                        onChange={
                                            handleHopeSalaryRangeChangeAsync
                                        }
                                        onChangeCommitted={handleHopeSalaryRangeChangeCommittedAsync}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        getAriaValueText={valuetext}
                                        ValueLabelComponent={
                                            ValueLabelComponent
                                        }
                                    />
                                </Box> */}
                                </Box>
                                <Box className={classes.hopeSwitchDateWrap}>
                                    <Box
                                        className={
                                            classes.hopeSwitchDateLabelWrap
                                        }
                                    >
                                        <Typography
                                            className={
                                                classes.hopeSwitchDateTitle
                                            }
                                        >
                                            근무시작 희망일
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.hopeSwitchDateDesc
                                            }
                                        >
                                            새로운 회사에서 언제부터 근무를
                                            <br />
                                            시작하고 싶으신가요?
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={
                                            classes.hopeSwitchDatePickerWrap
                                        }
                                    >
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                required
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.inputFontSize,
                                                        adornedEnd:
                                                            classes.adornedEnd,
                                                    },
                                                }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="yyyy/MM/dd"
                                                invalidDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        YYYY/MM/DD 형식으로
                                                        입력해 주세요!
                                                    </Typography>
                                                }
                                                margin="normal"
                                                id="date-picker-inline"
                                                disablePast
                                                minDateMessage={
                                                    <Typography
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        오늘 이후 날짜를 입력해
                                                        주세요!
                                                    </Typography>
                                                }
                                                value={profile.hopeWorkBeginAt}
                                                onChange={
                                                    handleHopeWorkBeginAtChange
                                                }
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={<Create />}
                                                autoOk={true}
                                            />
                                            <Typography
                                                className={
                                                    classes.hopeSwitchDatePickerNotice
                                                }
                                            >
                                                *해당 일이 초과될 경우 30일 자동
                                                연장됩니다.
                                            </Typography>
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                </Box>
                                <ProfileSchool />
                                <ProfileCompany />
                                <ProfileProject />
                                <ProfilePortfolioLink />
                                {/* <ProfilePortfolio />
                            <ProfileMilitary /> */}
                            </Box>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                className={classes.rightSectionSkeleton}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
            <ServiceFooterD backgroudColor={"#2c2c2c"} />
        </React.Fragment>
    );
};

export default Profile;
