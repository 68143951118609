import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setToken,
    setEnableRecommendation,
    setProfile,
    setBasicProfile,
    setHopeSalaryAsync,
    setHopeSalaryRangeAsync,
    setHopeWorkBeginAt,
} from "../../redux/slice/profileSlice";
import { useHistory } from "react-router-dom";
import {
    Container,
    Box,
    Typography,
    Divider,
    Tooltip,
    Button,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
    ProfileHeaderD,
    ServiceFooterD,
    AntSwitch,
    IOSSlider,
} from "../components";
import { Helmet } from "react-helmet";
import { useStyles } from "./styleProfile";
import { TXImage } from "../../components/tx";

import { deviconMap, devicon } from "data";

import "date-fns";

import ProfileSchool from "./ProfileSchool";
import ProfileCompany from "./ProfileCompany";
import ProfileProject from "./ProfileProject";
import ProfilePortfolioLink from "./ProfilePortfolioLink";

import {
    getTalentProfile,
    updateTalentProfileBasicinfo,
    updateTalentPreferenceEnableRecommendation,
} from "../../apis/profile/profile";

import {
    displayLocalBirthDayAndAge,
    displayLocalYearMonthDay,
} from "../../helper/misc";

function salaryRangeStr(min, max) {
    let minSalaryR = (min % 100000000) / 10000;
    let minSalaryM = parseInt(min / 100000000);
    let maxSalaryR = (max % 100000000) / 10000;
    let maxSalaryM = parseInt(max / 100000000);

    if (min === max) {
        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        }`;
    } else {
        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        } ~ ${maxSalaryM ? maxSalaryM + "억" : ""}${
            maxSalaryR ? " " + maxSalaryR + "만원" : "원"
        }`;
    }
}

const displayAddress = (address) => {
    return address
        ? address.split(" ").slice(0, 3).join(" ")
        : "거주지를 입력해주세요";
};

const displayHopePosition = (hopePosition) => {
    return hopePosition ? `${hopePosition} 희망` : "희망 직무를 선택해 주세요";
};

const expYearEnum = [
    "신입",
    "1년차",
    "2년차",
    "3년차",
    "4년차",
    "5년차",
    "6년차",
    "7년차",
    "8년차",
    "9년차",
    "10년차 이상",
];

const ProfileView = (props) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    console.log("token", token);
    const history = useHistory();
    if (!token) history.push("/profile/access-expired");
    const dispatch = useDispatch();
    dispatch(setToken(token));
    const profile = useSelector((state) => state.profile);
    const classes = useStyles();
    const today = new Date();

    useEffect(() => {
        async function fetchTalentProfile() {
            const cloudProfile = await getTalentProfile(token);
            console.log(cloudProfile);
            if (cloudProfile) {
                dispatch(setProfile(cloudProfile));
            } else {
                history.push("/profile/access-expired");
            }
        }
        if (token && profile.photos[0].url === null) {
            fetchTalentProfile();
        }
    }, []);

    const handlePhotoChange = () => {
        history.push(`/profile/photo`);
    };

    const handleSkillEdit = () => {
        history.push(`/profile/skill`);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleEnableRecommendationChange = async (event) => {
        const { checked } = event.target;
        const updateResult = await updateTalentPreferenceEnableRecommendation(
            token,
            { enableRecommendation: checked }
        );
        if (updateResult?.affected === 1) {
            dispatch(setEnableRecommendation(checked));
        } else if (!updateResult) history.push("/profile/access-expired");
    };

    const handleImgError = (e) => {
        e.target.src = require("assets/icon-skill-default-52.png");
    };

    const handleHopeSalaryChangeAsync = (event, newValue) => {
        console.log("Changing value", newValue);
        dispatch(setHopeSalaryAsync(newValue));
    };

    const handleHopeSalaryChangeCommittedAsync = async (event, newValue) => {
        console.log("Commiting value", newValue);
        const updateResult = await updateTalentProfileBasicinfo(token, {
            hopeSalary: newValue,
        });
        if (!updateResult) history.push("/profile/access-expired");
    };

    const handleHopeSalaryRangeChangeAsync = (event, newValue) => {
        console.log("Changing value", newValue);
        dispatch(setHopeSalaryRangeAsync(newValue));
    };

    const handleHopeSalaryRangeChangeCommittedAsync = async (
        event,
        newValue
    ) => {
        console.log("Commiting value", newValue);
        const updateResult = await updateTalentProfileBasicinfo(token, {
            hopeSalary: newValue[1],
        });
        if (!updateResult) history.push("/profile/access-expired");
    };

    const handleHopeWorkBeginAtChange = async (date, value) => {
        console.log("handleHopeWorkStartDateChange value", value);
        console.log("handleHopeWorkStartDateChange date", date);
        console.log(
            "handleHopeWorkStartDateChange date.toJSON()",
            date?.toJSON()
        );
        dispatch(setHopeWorkBeginAt(date?.toJSON() ?? value));
        if (date?.toJSON()) {
            const updateResult = updateTalentProfileBasicinfo(token, {
                hopeWorkBeginAt: date.toJSON(),
            });
            if (!updateResult) history.push("/profile/access-expired");
        }
    };

    const [basicProfileEdit, setBasicProfileEdit] = useState(false);
    const [basicProfileBeingCreated, setBasicProfileBeingCreated] = useState();

    const initBasicProfileBeingCreated = () => {
        console.log("profile", profile);
        setBasicProfileBeingCreated({
            name: profile.name,
            birth: profile.birth,
            hopePosition: profile.hopePosition,
            position: profile.position,
            positionExpYears: profile.positionExpYears,
            devExpYears: profile.devExpYears,
            postalCode: profile.postalCode,
            address: profile.address,
            addressDetail: profile.addressDetail,
            // githubLink: profile.githubLink,
            introduction: profile.introduction,
        });
    };

    const saveBasicProfileBeingCreated = async () => {
        console.log("basicProfileBeingCreated", basicProfileBeingCreated);
        const submittable =
            !!basicProfileBeingCreated.name &&
            basicProfileBeingCreated.birth?.includes("T") &&
            !!basicProfileBeingCreated.hopePosition &&
            !!basicProfileBeingCreated.position &&
            !!basicProfileBeingCreated.positionExpYears &&
            !!basicProfileBeingCreated.devExpYears &&
            !!basicProfileBeingCreated.postalCode &&
            !!basicProfileBeingCreated.address &&
            !!basicProfileBeingCreated.addressDetail;
        console.log("submittable", submittable);
        console.log(
            'basicProfileBeingCreated.birth.includes("T")',
            basicProfileBeingCreated.birth?.includes("T")
        );

        if (submittable) {
            dispatch(setBasicProfile(basicProfileBeingCreated));
            const updateResult = await updateTalentProfileBasicinfo(token, {
                name: basicProfileBeingCreated.name,
                birth: basicProfileBeingCreated.birth,
                mainPosition: basicProfileBeingCreated.position,
                mainCareer: basicProfileBeingCreated.positionExpYears,
                totalCareer: basicProfileBeingCreated.devExpYears,
                zipCode: basicProfileBeingCreated.postalCode,
                address1: basicProfileBeingCreated.address,
                address2: basicProfileBeingCreated.addressDetail,
            });
            if (updateResult) setBasicProfileEdit(false);
            else history.push("/profile/access-expired");
        }
    };

    const deleteBasicProfileBeingCreated = () => {
        initBasicProfileBeingCreated();
        setBasicProfileEdit(false);
    };

    const handleBasicProfileChange = (event) => {
        console.log("basicProfileBeingCreated", basicProfileBeingCreated);
        const key = event.target.id || event.target.name;
        const value = event.target.value;
        console.log("key", key);
        setBasicProfileBeingCreated((prev) => {
            const newBasicProfile = { ...prev };
            newBasicProfile[key] = value;
            if (key === "hopePosition" && newBasicProfile.position === "") {
                newBasicProfile.position = value;
            }
            if (
                key === "positionExpYears" &&
                (newBasicProfile.devExpYears === "" ||
                    expYearEnum.indexOf(newBasicProfile.devExpYears) <
                        expYearEnum.indexOf(value))
            ) {
                newBasicProfile.devExpYears = value;
            }

            return newBasicProfile;
        });
    };

    const handleBasicProfileBirthDayChange = (date, value) => {
        console.log("handleBasicProfileBirthDayChange value", value);
        console.log("handleBasicProfileBirthDayChange date", date);
        console.log(
            "handleBasicProfileBirthDayChange date.toJSON()",
            date?.toJSON()
        );
        setBasicProfileBeingCreated((prev) => {
            const newBasicProfile = { ...prev };
            newBasicProfile.birth = date?.toJSON() ?? value;
            return newBasicProfile;
        });
    };

    const devPositionExpYearTexter = (
        position,
        positionExpYears,
        devExpYears
    ) => {
        let positionText = "소프트웨어 엔지니어";
        let positionExpYearsText = "신입",
            devExpYearsText = "";
        if (position) positionText = position;
        if (positionExpYears) positionExpYearsText = positionExpYears;
        if (devExpYears) devExpYearsText = ` | 총 개발 경력 ${devExpYears}`;
        return position
            ? `${positionText} ${positionExpYearsText}${devExpYearsText}`
            : "현재 직무와 경력을 입력해주세요";
    };

    //Adress Input
    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    const AddressSearchButton = () => {
        return (
            <Button
                onClick={onChangeOpenPost}
                variant="outlined"
                //color="primary"
                disableElevation
                className={classes.searchButton}
            >
                검색
            </Button>
        );
    };

    const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddr += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddr +=
                    extraAddr !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
        }

        setBasicProfileBeingCreated((prev) => {
            const newBasicProfile = { ...prev };
            newBasicProfile.postalCode = data.zonecode;
            newBasicProfile.address = fullAddr;
            return newBasicProfile;
        });

        setIsOpenPost(false);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필</title>
            </Helmet>
            <ProfileHeaderD edit={false} />
            <Container maxWidth="lg">
                <Box className={classes.inner}>
                    <Box className={classes.leftSection}>
                        {profile.email ? (
                            <Box className={classes.basicProfile}>
                                <Box className={classes.profileImgWrap}>
                                    {profile.photos[
                                        profile.photos.findIndex(
                                            (photo) => photo.order === 0
                                        )
                                    ].url ? (
                                        <img
                                            src={
                                                profile.photos[
                                                    profile.photos.findIndex(
                                                        (photo) =>
                                                            photo.order === 0
                                                    )
                                                ].url
                                            }
                                            className={classes.profileImg}
                                            alt="profile"
                                        />
                                    ) : (
                                        <Typography
                                            className={
                                                classes.profileImgPlaceholder
                                            }
                                        >
                                            프로필 사진 비공개
                                        </Typography>
                                    )}
                                </Box>

                                <Box className={classes.talentNameOfferSetWrap}>
                                    <Box>
                                        <Typography
                                            className={classes.talentName}
                                        >
                                            {profile.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                {profile.hopePosition && (
                                    <Box className={classes.targetPositionWrap}>
                                        <Typography
                                            className={classes.targetPosition}
                                        >
                                            {displayHopePosition(
                                                profile.hopePosition
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.birth && (
                                    <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-calendar-24-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {displayLocalBirthDayAndAge(
                                                profile.birth
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.position && (
                                    <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-briefcase-24-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {devPositionExpYearTexter(
                                                profile.position,
                                                profile.positionExpYears,
                                                profile.devExpYears
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.address && (
                                    <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-location-24-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {displayAddress(profile.address)}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.introduction && (
                                    <>
                                        <Typography
                                            className={
                                                classes.selfIntroductionTitle
                                            }
                                        >
                                            자기 소개
                                        </Typography>
                                        <Typography
                                            className={classes.selfIntroduction}
                                        >
                                            {profile.introduction}
                                        </Typography>
                                    </>
                                )}

                                <Divider className={classes.divider} />

                                <Box className={classes.skillHeader}>
                                    <Typography className={classes.skillLabel}>
                                        보유 기술
                                    </Typography>
                                    <Box className={classes.flexGrowBox}></Box>
                                    <Typography
                                        className={classes.skillLevelGuide}
                                    >
                                        숙련도 최저 1 ~ 최고 10
                                    </Typography>
                                </Box>
                                <Box className={classes.skillWrap}>
                                    {profile.skills.map((item, index) => (
                                        <Box
                                            key={index}
                                            className={classes.skillChip}
                                        >
                                            <img
                                                className={classes.skillIcon}
                                                src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                                    item.skill
                                                )}/${deviconMap.get(
                                                    item.skill
                                                )}-${
                                                    devicon.find(
                                                        (obj) =>
                                                            obj.name ===
                                                            deviconMap.get(
                                                                item.skill
                                                            )
                                                    )?.versions.svg[0]
                                                }.svg`}
                                                onError={handleImgError}
                                                alt={item.skill}
                                            />
                                            <Typography
                                                className={classes.skillName}
                                            >
                                                {item.skill}
                                            </Typography>
                                            <Typography
                                                className={classes.skillLevel}
                                            >
                                                {item.rate}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                className={classes.leftSectionSkeleton}
                            />
                        )}
                    </Box>

                    <Box className={classes.rightSection}>
                        {profile.email ? (
                            <Box className={classes.detailProfile}>
                                <ProfileSchool edit={false} />
                                <ProfileCompany edit={false} />
                                <ProfileProject edit={false} />
                                <ProfilePortfolioLink edit={false} />
                                <Box className={classes.hopeSalaryWrapViewMode}>
                                    <Typography
                                        className={
                                            classes.hopeSalaryLabelViewMode
                                        }
                                    >
                                        희망 연봉
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.hopeSalaryRangeViewMode
                                        }
                                    >
                                        {salaryRangeStr(
                                            profile.hopeSalary,
                                            profile.hopeSalary
                                        )}
                                    </Typography>
                                </Box>
                                <Box className={classes.hopeSwitchDateWrap}>
                                    <Typography
                                        className={classes.hopeSwitchDateTitle}
                                    >
                                        이직 희망일
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.hopeSwitchDateViewMode
                                        }
                                    >
                                        {displayLocalYearMonthDay(
                                            profile.hopeWorkBeginAt
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                className={classes.rightSectionSkeleton}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
            <ServiceFooterD backgroudColor={"#2c2c2c"} />
        </React.Fragment>
    );
};

export default ProfileView;
