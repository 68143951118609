import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSkills } from "../../redux/slice/profileSlice";
import { useHistory } from "react-router-dom";
import { setPhotos } from "../../redux/slice/profileSlice";
import { Helmet } from "react-helmet";
import { ServiceHeaderD } from "../components";
import {
    Container,
    Box,
    Typography,
    Divider,
    Button,
    Backdrop,
    InputAdornment,
    TextField,
    FormControl,
    CircularProgress,
} from "@material-ui/core";
import { TXImage } from "../../components/tx";
import { useStyles } from "./styleProfileSkill";

import { deviconMap, skillRates, devicon } from "data";

import {
    getSkillList,
    putTalentProfileHardSkills,
    putTalentProfileHardSkill,
} from "../../apis/profile/profile";
import _ from "lodash";

const ProfileSkill = (props) => {
    const { token, skills } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [skillBeingAdded, setSkillBeingAdded] = useState("");
    const [rateBeingAdded, setRateBeingAdded] = useState("");
    const [autoCompleteSkills, setAutoCompleteSkills] = useState([]);
    const skillInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [indexBeingChanged, setIndexBeingChanged] = useState(skills.length);

    async function loadSkillList(keyword) {
        if (keyword) {
            const result = await getSkillList(keyword);
            if (result) {
                setAutoCompleteSkills(result.data);
            }
        } else {
            setAutoCompleteSkills([]);
        }
    }

    const handleSkillChange = (e) => {
        setSkillBeingAdded(e.target.value);
        loadSkillList(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        console.log("input key", e.key);
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            const { id } = e.target;
            const [itemType, itemIndex] = id.split("-");
            const indexOffset = e.key === "ArrowDown" ? 1 : -1;
            const newIndex = parseInt(itemIndex) + indexOffset;
            const newId = `${itemType}-${newIndex}`;
            const newItem = document.getElementById(newId);
            if (newItem) {
                console.log("newItem", newItem);
                e.target.blur();
                newItem.focus();
            }
        }
        if (e.key === "Enter" && skillBeingAdded) {
            const input = e.target.value;
            if (
                skills.every(
                    (skill) => skill.skill.toLowerCase() !== input.toLowerCase()
                )
            ) {
                e.target.blur();
                setOpen(true);
            }
            setAutoCompleteSkills([]);
        }
    };

    const handleAddSkill = (e) => {
        if (
            skillBeingAdded &&
            skills.every(
                (skill) =>
                    skill.skill.toLowerCase() !== skillBeingAdded.toLowerCase()
            )
        ) {
            e.target.blur();
            setOpen(true);
        }
    };

    const handleListItemKeyDown = (e) => {
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            const { id } = e.target;
            const [itemType, itemIndex] = id.split("-");
            const indexOffset = e.key === "ArrowDown" ? 1 : -1;
            const newIndex = parseInt(itemIndex) + indexOffset;
            const newId = `${itemType}-${newIndex}`;
            const newItem = document.getElementById(newId);
            if (newItem) {
                console.log("newItem", newItem);
                e.target.blur();
                newItem.focus();
            }
        }
        if (e.key === "Enter") {
            e.target.blur();
            const newItem = document.getElementById("skill-0");
            newItem.focus();
            setAutoCompleteSkills([]);
            if (
                skills.every(
                    (skill) =>
                        skill.skill.toLowerCase() !==
                        skillBeingAdded.toLowerCase()
                )
            ) {
                e.target.blur();
                setOpen(true);
            }
        }
    };

    const handlePopupKeyDown = (e) => {
        console.log("Popup key", e.key);
        if (e.key === "Escape") {
            setOpen(false);
        }
    };

    const handleDeleteSkill = async (e) => {
        if (isUploading) return;
        const skillName = e.target.id;
        const skillsDeepCopy = _.cloneDeep(skills);
        const newSkills = skillsDeepCopy.filter(
            (skill) => skill.skill !== skillName
        );

        setIsUploading(true);
        const result = await putTalentProfileHardSkills(token, newSkills);
        console.log(
            "handleDeleteSkill putTalentProfileHardSkills result",
            result
        );
        setIsUploading(false);
        if (result) {
            dispatch(setSkills(newSkills));
            setIndexBeingChanged(newSkills.length);
        } else {
            history.push("/profile/access-expired");
        }
    };

    const confirmToAddSkill = async () => {
        const skillsDeepCopy = _.cloneDeep(skills);
        const newSkill = {
            skill: skillBeingAdded,
            rate: rateBeingAdded,
        };
        const newSkills = skillsDeepCopy.concat(newSkill);
        setSkillBeingAdded("");
        setRateBeingAdded("");
        dispatch(setSkills(newSkills));
        skillInputRef.current.focus();
        setOpen(false);
        setIsUploading(true);
        const result = await putTalentProfileHardSkills(token, newSkills);
        console.log(
            "confirmToAddSkill putTalentProfileHardSkills result",
            result
        );
        // const result = await putTalentProfileHardSkill(token, newSkill);
        // console.log(
        //     "confirmToAddSkill putTalentProfileHardSkill result",
        //     result
        // );
        setIndexBeingChanged(newSkills.length);
        setIsUploading(false);
        if (!result) history.push("/profile/access-expired");
    };
    const cancleToAddSkill = () => {
        setSkillBeingAdded("");
        setRateBeingAdded("");
        skillInputRef.current.focus();
        setOpen(false);
    };

    const handleImgError = (e) => {
        e.target.src = require("assets/icon-skill-default-52.png");
    };

    window.URL = window.URL || window.webkitURL;

    const handleEscape = (e) => {
        if (e.key === "Escape") {
            skillInputRef.current.focus();
            setOpen(false);
        }
    };

    const handleSaveAndGoBack = () => {
        history.goBack();
    };

    useEffect(() => {
        skillInputRef.current.focus();
        window.addEventListener("keydown", handleEscape, false);
        return () => {
            window.removeEventListener("keydown", handleEscape, false);
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필 스킬</title>
            </Helmet>
            <ServiceHeaderD />
            <Container maxWidth="sm" className={classes.container}>
                <Box className={classes.header}>
                    <TXImage
                        imgName="icon-left-arrow-32-dp"
                        onClick={handleSaveAndGoBack}
                    />
                    <Typography className={classes.title}>보유 기술</Typography>
                </Box>

                <Divider />
                <Typography className={classes.guide}>
                    보유하고 계신 기술을 등록해주세요
                </Typography>

                <TextField
                    inputRef={skillInputRef}
                    autoComplete="off"
                    required
                    style={{
                        marginTop: 24,
                        marginBottom: 25,
                    }}
                    inputProps={{ maxLength: 20 }}
                    InputProps={{
                        classes: { input: classes.resize },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button onClick={handleAddSkill}>
                                    <Typography className={classes.adornment}>
                                        추가 [Enter키]
                                    </Typography>
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    placeholder="영문 기술명"
                    value={skillBeingAdded}
                    onChange={handleSkillChange}
                    //onKeyPress={handleReturnKeyPress}
                    onKeyDown={handleInputKeyDown}
                    id="skill-0"
                    name="skill-0"
                />
                <Box className={classes.autoCompleteTextFieldWrap}>
                    <Box className={classes.autoCompleteList}>
                        {autoCompleteSkills.map((skill, index) => (
                            <Box
                                className={classes.autoCompleteListItem}
                                onClick={(e) => {
                                    setSkillBeingAdded(skill);
                                    setAutoCompleteSkills([]);
                                    handleAddSkill(e);
                                }}
                                tabindex={index + 1}
                                id={`skill-${index + 1}`}
                                onKeyDown={handleListItemKeyDown}
                                onFocus={() => setSkillBeingAdded(skill)}
                            >
                                <img
                                    className={classes.skillIcon}
                                    src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                        skill
                                    )}/${deviconMap.get(skill)}-${
                                        devicon.find(
                                            (obj) =>
                                                obj.name ===
                                                deviconMap.get(skill)
                                        )?.versions.svg[0]
                                    }.svg`}
                                    onError={handleImgError}
                                />
                                <Typography className={classes.skillName}>
                                    {skill}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box className={classes.skillWrap}>
                    {skills.map((item, index) => (
                        <Box key={index} className={classes.skillChip}>
                            <img
                                className={classes.skillIcon}
                                src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                    item.skill
                                )}/${deviconMap.get(item.skill)}-${
                                    devicon.find(
                                        (obj) =>
                                            obj.name ===
                                            deviconMap.get(item.skill)
                                    )?.versions.svg[0]
                                }.svg`}
                                onError={handleImgError}
                            />
                            <Typography className={classes.skillName}>
                                {item.skill}
                            </Typography>
                            <Typography className={classes.skillLevel}>
                                {item.rate}
                            </Typography>
                            {isUploading && indexBeingChanged === index ? (
                                <CircularProgress
                                    classes={{ root: classes.uploading }}
                                    color="inherit"
                                    size={20}
                                />
                            ) : (
                                <Box className={classes.skillDeleteBtn}>
                                    <img
                                        id={item.skill}
                                        src={require("assets/icon-close-24-dp.png")}
                                        className={classes.skillDeleteBtnIcon}
                                        onClick={(e) => {
                                            handleDeleteSkill(e);
                                            setIndexBeingChanged(index);
                                        }}
                                        disabled={isUploading}
                                    />
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            </Container>
            <Backdrop className={classes.backdrop} open={open}>
                <Box className={classes.skillRatePopup}>
                    <Typography className={classes.skillRatePopupHeaderTitle}>
                        해당 기술에 대한 숙련도가 얼마나 되시나요?
                    </Typography>

                    <Box className={classes.skillChipUnrated}>
                        <img
                            className={classes.skillIconUnrated}
                            src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                skillBeingAdded
                            )}/${deviconMap.get(skillBeingAdded)}-${
                                devicon.find(
                                    (obj) =>
                                        obj.name ===
                                        deviconMap.get(skillBeingAdded)
                                )?.versions.svg[0]
                            }.svg`}
                            onError={handleImgError}
                        />
                        <Typography className={classes.skillNameUnrated}>
                            {skillBeingAdded}
                        </Typography>
                        <Typography className={classes.skillLevelUnrated}>
                            {rateBeingAdded}
                        </Typography>
                    </Box>
                    <FormControl>
                        {skillRates.map((item, idx) => (
                            <Box
                                key={idx}
                                className={
                                    rateBeingAdded === item.rate
                                        ? classes.skillRateBoxChecked
                                        : classes.skillRateBox
                                }
                                onClick={() => {
                                    setRateBeingAdded(item.rate);
                                }}
                            >
                                <Typography className={classes.skillRateDesc}>
                                    {item.desc}
                                </Typography>
                                <Typography
                                    className={
                                        item.rate > 4
                                            ? item.rate > 7
                                                ? classes.skillRateHigh
                                                : classes.skillRateMedium
                                            : classes.skillRateLow
                                    }
                                >
                                    Level {item.rate}
                                </Typography>
                                <TXImage
                                    imgName={
                                        rateBeingAdded === item.rate
                                            ? "icon-check-circle-24-dp-active"
                                            : "icon-check-circle-24-dp-inactive"
                                    }
                                />
                            </Box>
                        ))}
                    </FormControl>

                    <Box className={classes.skillRatePopupBtnWrap}>
                        <Button
                            className={classes.skillRatePopupBtn}
                            variant="contained"
                            color="default"
                            size="large"
                            onClick={cancleToAddSkill}
                            disableElevation
                        >
                            취소
                        </Button>
                        <Button
                            className={classes.skillRatePopupBtn}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={confirmToAddSkill}
                            disableElevation
                            disabled={!rateBeingAdded}
                        >
                            등록
                        </Button>
                    </Box>
                </Box>
            </Backdrop>
            {/* <Backdrop className={classes.backdrop} open={isUploading}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </React.Fragment>
    );
};

export default ProfileSkill;
