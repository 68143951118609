import React from "react";
import { openLinkNewTab } from "helper/misc";
import { makeStyles } from "@material-ui/core";

const onClose = () => {
    document.getElementById("popup").style.display = "none";
};

const link =
    "https://www.talentx.co.kr/notice/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=5423719&t=board";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        top: 32,
        left: 32,
    },
    wrap: {
        position: "relative",
    },
    popup: {
        width: 300,
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 32px)",
        },
    },
    close: {
        position: "absolute",
        bottom: 0,
        height: 60,
        width: 300,
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 32px)",
        },
    },
}));

export default function PopUp(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div id="popup" className={classes.root}>
                <div className={classes.wrap}>
                    <img
                        className={classes.popup}
                        src={require("assets/popup.png")}
                        onClick={() => openLinkNewTab(link)}
                        alt=""
                    />
                    <div className={classes.close} onClick={onClose}></div>
                </div>
            </div>
        </React.Fragment>
    );
}
