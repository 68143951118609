import { firestore } from "apis/firebase";
import axios from "axios";
import { API_URL } from "../common/constants";

export const getTalentNum = async () => {
    try {
        const result = await axios.get(`${API_URL}/talent-stat`);

        return result.data;
    } catch {
    } finally {
    }
};

export const getDevstarxProfiles = async () => {
    try {
        const result = await axios.get(`${API_URL}/openscout/devstarx`);

        return result.data;
    } catch {
    } finally {
    }
};

export const getCompanies = async () => {
    try {
        const result = await axios.get(`${API_URL}/company-info`);

        return result.data;
    } catch {
    } finally {
    }
};

export const getCompany = async (companyId) => {
    try {
        const result = await axios.get(`${API_URL}/company-info/${companyId}`);
        return result.data;
    } catch {
    } finally {
    }
};

export const fetchTalents = (setOracle, setTalents) => {
    firestore
        .collection("applicants")
        .get()
        .then(
            (snapshot) => {
                const newTalents = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (newTalents.length !== 0) {
                    const collection = newTalents.sort(function (a, b) {
                        if (a.uid < b.uid) {
                            return -1;
                        }
                        if (a.uid > b.uid) {
                            return 1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    setOracle(collection);
                    setTalents(collection);
                }
            },
            (error) => {
                console.log("Error fetching talent documents: ", error);
            }
        );
};

export const fetchCompanies = (setCompanies) => {
    firestore
        .collection("companies")
        .get()
        .then(
            (snapshot) => {
                // console.log(snapshot);
                const newCompanies = snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    };
                });
                const sortedCompanies = newCompanies.sort(function (a, b) {
                    if (a.uid < b.uid) {
                        return -1;
                    }
                    if (a.uid > b.uid) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                });
                setCompanies(sortedCompanies);
            },
            (error) => {
                console.log("Error fetching company documents: ", error);
            }
        );
};

export const fetchJobs = (setJobs) => {
    let query = firestore.collection("jobs");

    query.onSnapshot(
        (snapshot) => {
            // console.log(snapshot);
            const newJobs = snapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            const sortedJobs = newJobs.sort(function (a, b) {
                if (a.company_uid < b.company_uid) {
                    return -1;
                }
                if (a.company_uid > b.company_uid) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
            setJobs(sortedJobs);
        },
        (error) => {
            console.log("Error subscribing job documents: ", error);
        }
    );
};
