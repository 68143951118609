import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Container, Paper } from "@material-ui/core";
import { CompanyServiceHeaderD, ServiceFooterD } from "pages/components";
import { makeStyles } from "@material-ui/core/styles";

import CompanyContentD from "./CompanyContentD3";
import { Colors } from "../../styles";

import "date-fns";

const content = {
	mission: "실력 있는 개발자 영입\n프리미엄 스카우트 플랫폼,\n탤런트엑스",
};

const useStyles = makeStyles((theme) => ({
	wallpaper: {
		marginTop: 56,
		height: 980,
		backgroundImage: `url(${require("assets/bgi-talentx-main-v2.png")})`,
		backgroundImage: `image-set(url(${require("assets/bgi-talentx-main-v2.png")}) 1x, url(${require("assets/bgi-talentx-main-v2@2x.png")}) 2x, url(${require("assets/bgi-talentx-main-v2@2x.png")}) 3x)`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top",
		backgroundSize: "cover",
	},
	topContainer: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	mainTitle: {
		color: "#ffffff",
		fontSize: 48,
		fontWeight: "bold",
		lineHeight: 1.17,
		whiteSpace: "pre-wrap",
	},
	body: {
		backgroundColor: theme.palette.common.white,
		textAlign: "center",
		width: "100%",
	},
	phantom: {
		width: "100%",
		height: 50,
	},
}));

const CompanyD3 = () => {
	const classes = useStyles();
	// const query = new URLSearchParams(window.location.search);
	// const [userType, setUserType] = React.useState(
	//     query.get("type") === "talent" ? 1 : 0
	// );

	// const handleUserTypeChange = (event, newUserType) => {
	//     setUserType(newUserType);
	// };

	useEffect(() => window.scrollTo({ top: 0 }), []);

	return (
		<React.Fragment>
			<Helmet>
				<title>탤런트엑스 | 기업</title>
			</Helmet>
			<CompanyServiceHeaderD />
			<Box className={classes.wallpaper}>
				<Container maxWidth="lg" className={classes.topContainer}>
					<Typography className={classes.mainTitle}>
						{content.mission}
					</Typography>
				</Container>
			</Box>
			<Box className={classes.body}>
				<CompanyContentD />
			</Box>
			<ServiceFooterD backgroudColor={"#2c2c2c"} />
			{/* <Box className={classes.phantom}></Box> */}
		</React.Fragment>
	);
};

export default CompanyD3;
