import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Container,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Divider,
    Modal,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceHeaderD } from "pages/components";
import { onDemoRequest } from "apis/firestore/apply";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
    DateTimePicker,
} from "@material-ui/pickers";
import DaumPostcode from "react-daum-postcode";

import { useStyles } from "./styleDemoRequestD";

const DemoRequestD = (props) => {
    const query = new URLSearchParams(window.location.search);
    const utmSource = query.get("utm_source") || query.get("source");
    const utmMedium = query.get("utm_medium") || query.get("media");
    const initialChannel = `${utmSource ? utmSource + " " : ""}${
        utmMedium ? utmMedium : ""
    }`;
    const classes = useStyles();
    const avaliableDateTime = new Date();
    avaliableDateTime.setDate(
        avaliableDateTime.getDate() + 8 - avaliableDateTime.getDay()
    );
    avaliableDateTime.setHours(14, 0, 0);
    const [companyName, setCompanyName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [demoDateTime, setDemoDateTime] = useState(avaliableDateTime);
    const [demoType, setDemoType] = useState("비대면 화상");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [isOpenPost, setIsOpenPost] = useState(false);
    const [numOfHire, setNumOfHire] = useState("");
    const [channel, setChannel] = useState(initialChannel);
    const [consent, setConsent] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    const handleDemoDateTimeChange = (datetime) => {
        setDemoDateTime(datetime);
    };
    const handleDemoTypeChange = (event) => {
        setDemoType(event.target.value);
    };
    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
    };
    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };
    const handleAddressDetailChange = (event) => {
        setAddressDetail(event.target.value);
    };
    const handleNumOfHireChange = (event) => {
        setNumOfHire(event.target.value);
    };
    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    };

    const handleSubmit = () => {
        setIsFetching(true);
        const application = {
            companyName: companyName,
            name: name,
            email: email,
            phone: phone,
            demoDateTime: demoDateTime,
            demoType: demoType,
            postalCode: postalCode,
            address: address,
            addressDetail: addressDetail,
            numOfHire: String(numOfHire),
            channel: channel,
        };
        onDemoRequest(application, setIsFetching, setOpen);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        setIsFetching(true);
        setTimeout(() => props.history.push("/"), 1000);
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        setSubmittable(
            companyName &&
                name &&
                email &&
                phone &&
                demoDateTime &&
                demoType &&
                (demoType === "방문" ? !!address : true) &&
                channel &&
                numOfHire &&
                consent
        );
    }, [
        companyName,
        name,
        email,
        phone,
        demoDateTime,
        demoType,
        address,
        numOfHire,
        channel,
        consent,
    ]);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    const AddressSearchButton = () => {
        return (
            <Button
                onClick={onChangeOpenPost}
                variant="contained"
                color="primary"
                disableElevation
                className={classes.searchButton}
            >
                주소검색
            </Button>
        );
    };

    const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddr += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddr +=
                    extraAddr !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
        }

        setPostalCode(data.zonecode);
        setAddress(fullAddr);
        setIsOpenPost(false);
    };

    function ableOnlyMonday(date) {
        return date.getDay() !== 1;
    }

    return (
        <React.Fragment>
            <ServiceHeaderD />
            <Container maxWidth="sm">
                <Box className={classes.head}>
                    <img
                        src={require("assets/icon-app.png")}
                        srcSet={`${require("assets/icon-app@2x.png")} 2x, ${require("assets/icon-app@3x.png")} 3x`}
                        className={classes.sectionIcon}
                        alt="talent app icon"
                    ></img>
                    <Typography className={classes.title}>
                        탤런트엑스 채용 플랫폼
                        <br />
                        데모 신청하기
                    </Typography>
                    <Typography className={classes.introduction}>
                        플랫폼 시연을 통해 서비스를 소개해 드리고
                        <br />
                        성공적인 개발자 채용 전략을 논의합니다.
                    </Typography>
                </Box>
            </Container>
            <Divider className={classes.sectionDivider} light></Divider>
            <Container maxWidth="sm">
                <Box className={classes.body}>
                    <Typography className={classes.subTitle}>
                        신청서 작성
                    </Typography>
                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography className={classes.inputTitle}>
                            기업명
                        </Typography>
                        <TextField
                            required
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="기업명을 입력해주세요."
                            value={companyName}
                            onChange={handleCompanyNameChange}
                        />
                        <Typography className={classes.inputTitle}>
                            담당자 성함
                        </Typography>
                        <TextField
                            required
                            inputProps={{ maxLength: 10 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="담당자 성함을 입력해주세요."
                            value={name}
                            onChange={handleNameChange}
                        />
                        <Typography className={classes.inputTitle}>
                            이메일
                        </Typography>
                        <TextField
                            required
                            id="standard-basic"
                            variant="outlined"
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            placeholder="이메일을 입력해주세요"
                            value={email}
                            onChange={handleEmailChange}
                        />

                        <Typography className={classes.inputTitle}>
                            휴대폰번호
                        </Typography>
                        <TextField
                            required
                            id="standard-basic"
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                            inputProps={{ maxLength: 11 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            placeholder="휴대폰번호를 입력해주세요.(-없이 11자리)"
                            //label="휴대폰번호"
                            value={phone}
                            onChange={handlePhoneChange}
                            //helperText="'-' 없이 번호만 입력해 주세요."
                        />

                        <Divider></Divider>
                        <Typography className={classes.inputTitle}>
                            데모 요청일시 (매주 월요일)
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            //locale={koLocale}
                        >
                            <DateTimePicker
                                required
                                //disableToolbar
                                style={{ marginBottom: 0 }}
                                InputProps={{
                                    classes: { input: classes.resize },
                                }}
                                //openTo="year"
                                //type="date"
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy/MM/dd HH:mm"
                                invalidDateMessage={
                                    <Typography style={{ fontSize: 12 }}>
                                        YYYY/MM/DD HH:MM 형식으로 입력해 주세요!
                                    </Typography>
                                }
                                margin="normal"
                                id="date-picker-inline"
                                minDate={avaliableDateTime}
                                minDateMessage={
                                    <Typography style={{ fontSize: 12 }}>
                                        최소 1주일 이후 월요일로 입력해 주세요!
                                    </Typography>
                                }
                                value={demoDateTime}
                                onChange={handleDemoDateTimeChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                shouldDisableDate={ableOnlyMonday}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                        <Typography className={classes.inputUsage}>
                            탤런트엑스 매니저가 신청서 확인 후 일정 컨펌을 위해
                            연락 드립니다.
                        </Typography>
                        <Typography className={classes.inputTitle}>
                            데모 방식 (비대면 화상 또는 방문)
                        </Typography>
                        <FormControl
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                        >
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={demoType}
                                onChange={handleDemoTypeChange}
                                classes={{ root: classes.resize }}
                            >
                                {/* <MenuItem
                                    value=""
                                    classes={{ root: classes.resize }}
                                    disabled
                                >
                                    선택해주세요.
                                </MenuItem> */}
                                {["비대면 화상", "방문"].map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        value={item}
                                        classes={{ root: classes.resize }}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {demoType !== "비대면 화상" && (
                            <>
                                <Box
                                    style={{ marginTop: 32, marginBottom: 8 }}
                                    className={classes.addressTitleWrap}
                                >
                                    <Typography className={classes.inputTitle}>
                                        회사 주소
                                    </Typography>
                                    {isOpenPost ? (
                                        <IconButton
                                            className={classes.closeBtn}
                                            aria-label="close"
                                            onClick={onChangeOpenPost}
                                            size="small"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <Box />
                                    )}
                                </Box>

                                {isOpenPost ? (
                                    <DaumPostcode
                                        style={{
                                            display: "block",
                                            position: "relative",
                                            top: "50%",
                                            width: "100%",
                                            height: "500px",
                                            //padding: "7px",
                                        }}
                                        autoClose
                                        onComplete={onCompletePost}
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            required
                                            style={{ marginBottom: 0 }}
                                            inputProps={{ maxLength: 10 }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.resize2,
                                                    input: classes.resize,
                                                },
                                                endAdornment: (
                                                    <AddressSearchButton />
                                                ),
                                            }}
                                            id="standard-basic"
                                            variant="outlined"
                                            placeholder="우편번호"
                                            value={postalCode}
                                            onChange={handlePostalCodeChange}
                                        />
                                        <TextField
                                            required
                                            style={{ marginBottom: 0 }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                },
                                            }}
                                            id="standard-basic"
                                            variant="outlined"
                                            placeholder="기본 주소"
                                            value={address}
                                            onChange={handleAddressChange}
                                        />
                                        <TextField
                                            required
                                            style={{ marginBottom: 8 }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                },
                                            }}
                                            id="standard-basic"
                                            variant="outlined"
                                            placeholder="상세 주소"
                                            value={addressDetail}
                                            onChange={handleAddressDetailChange}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        <Divider></Divider>
                        <Typography className={classes.inputTitle}>
                            채용 규모
                        </Typography>
                        <TextField
                            required
                            inputProps={{ min: "0", maxLength: 5 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="올해 개발자 채용 규모를 입력해주세요.(단위 : 명)"
                            type="number"
                            value={numOfHire}
                            onChange={handleNumOfHireChange}
                        />

                        <Typography className={classes.inputTitle}>
                            저희를 어떤 경로로 알게 되셨나요?
                        </Typography>
                        <FormControl variant="outlined">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={channel}
                                onChange={handleChannelChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    value={initialChannel}
                                    classes={{ root: classes.resize }}
                                    disabled={!initialChannel}
                                >
                                    {initialChannel || "선택해주세요."}
                                </MenuItem>
                                {[
                                    "지인 추천",
                                    "회사 동료 추천",
                                    "다른 회사 추천",
                                    "카카오톡 광고",
                                    "네이버 검색",
                                    "구글 검색",
                                    "페이스북 광고",
                                    "인스타그램 광고",
                                    "탤런트엑스 이메일",
                                    "기타",
                                ].map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        value={item}
                                        classes={{ root: classes.resize }}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>

                    <Backdrop className={classes.backdrop} open={isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        className={classes.modalScreen}
                    >
                        <Box className={classes.modalBox}>
                            <Typography className={classes.modalTitle}>
                                신청완료
                            </Typography>
                            <Typography className={classes.modalMessage}>
                                신청해주셔서 감사합니다!
                                <br />
                                담당자 확인 후 연락 드리겠습니다.
                            </Typography>
                            <Typography className={classes.modalInfo}>
                                영업일 기준 1 - 3일 소요됨
                            </Typography>
                            <Button
                                onClick={handleOk}
                                variant="contained"
                                color="primary"
                                className={classes.modalButton}
                            >
                                확인
                            </Button>
                        </Box>
                    </Modal>
                </Box>
            </Container>
            <Divider className={classes.sectionDivider} light></Divider>
            <Container maxWidth="sm">
                <Box className={classes.foot}>
                    <Typography className={classes.consentTitle}>
                        기업 및 개인정보 수집·이용 동의
                    </Typography>
                    <Typography className={classes.consentNotice}>
                        (주)탤런티넘은 서비스 제공을 위한 기업 및 개인정보를
                        수집·이용합니다.
                    </Typography>
                    <Box className={classes.noticeBox}>
                        <Typography className={classes.noticeTitle}>
                            1) 기업 및 개인정보 수집 목적
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            서비스 시연
                        </Typography>
                        <Typography className={classes.noticeTitle}>
                            2) 기업 및 개인정보 수집 항목
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            기업명, 채용 담당자 이름/이메일/연락처, 기업 주소,
                            채용규모, 가입신청경로
                        </Typography>
                        {/* <Typography className={classes.noticeTitle}>
                            3) 개인정보 보유 및 이용기간
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            가입심사 탈락 또는 회원 탈퇴 시
                        </Typography> */}
                        <Typography className={classes.noticeCaption}>
                            &#8226; 수집 목적이 달성된 이후 개인정보는 즉시
                            파기합니다.
                        </Typography>
                    </Box>
                    <Box
                        className={classes.consentBtn}
                        onClick={() => {
                            setConsent((prev) => !prev);
                        }}
                    >
                        {consent ? (
                            <img
                                src={require("assets/icon-toggle-checkbox-checked-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-checked-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-checked-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        ) : (
                            <img
                                src={require("assets/icon-toggle-checkbox-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        )}
                        <Typography className={classes.consent}>
                            기업 및 개인정보 수집·이용에 동의합니다
                        </Typography>
                    </Box>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!submittable}
                        className={classes.submitButton}
                    >
                        신청하기
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default DemoRequestD;
