//import preprocessedApplicants from "data/admin/preprocessedApplicants";
//import { newApplicants } from "data/admin/newApplicants";
const applicants = require("data/admin/applicants.json");

export const filterTalents = (filters, companies, jobs, oracle, setTalents) => {
    if (filters.all) {
        const matchedAllTalents = jobs.reduce(
            (collections, job) =>
                collections.concat(matchedTalents(applicants, job)),
            []
        );
        setTalents(matchedAllTalents);
    } else {
        setTalents(filteredTalents(applicants, filters, jobs));
    }
};

const matchedTalents = (oracle, job) => {
    const collectionHardCopy = oracle.map((doc) => ({
        ...doc,
    }));

    let filter = collectionHardCopy.filter(
        (applicant) =>
            applicant.result === "합격" && applicant.extra_round === "참석"
    );

    filter = filter.filter((applicant) => {
        const index = applicant.positions.indexOf(job.position);
        if (index === 0) {
            applicant.position_match_rating = 14;
            return true;
        } else if (index === 1) {
            applicant.position_match_rating = 12;
            return true;
        } else if (index === 2) {
            applicant.position_match_rating = 10;
            return true;
        } else return false;
    });

    filter = [3, 2, 1].reduce((union, minMatch, currentIndex, array) => {
        const subset = filter.filter((applicant) => {
            const match = applicant.skills.reduce((matchedSkills, skill) => {
                if (job.skills.indexOf(skill) !== -1) matchedSkills.push(skill);
                return matchedSkills;
            }, []);
            applicant.skill_match_rating = match.length * 10;
            // console.log("minMatch: " + minMatch);
            // console.log(applicant.name);
            // console.log(applicant.match_rating);
            // console.log(match);
            return match.length >= minMatch;
        });
        const returnArray = [...new Set([...union, ...subset])];
        // console.log(returnArray);
        // console.log(currentIndex);
        //if (returnArray.length > 0) array.splice(currentIndex + 1);
        // console.log(array);
        return returnArray;
    }, []);

    filter.forEach((applicant) => {
        const index = applicant.companies.indexOf(job.company_name);
        if (index === 0) applicant.company_match_rating = 15;
        else if (index === 1) applicant.company_match_rating = 13;
        else if (index === 2) applicant.company_match_rating = 11;
        // if (applicant.companies.indexOf(job.company_name) !== -1)
        //     applicant.match_rating = applicant.match_rating + 1.5;
        applicant.match = job.name;
    });

    filter.forEach(
        (applicant) =>
            (applicant.match_rating =
                applicant.position_match_rating +
                applicant.skill_match_rating +
                applicant.company_match_rating)
    );

    const collection = filter.sort(function (a, b) {
        if (a.match_rating > b.match_rating) {
            return -1;
        }
        if (a.match_rating < b.match_rating) {
            return 1;
        }
        // a must be equal to b
        return 0;
    });
    console.log("each collection");
    console.log(collection);
    return collection;
};

export const filteredTalents = (oracle, filters, jobs) => {
    const collectionHardCopy = oracle.map((doc) => ({
        ...doc,
    }));

    let filter = collectionHardCopy;
    if (filters.result !== "전체")
        filter = filter.filter(
            (applicant) => applicant.result === filters.result
        );
    if (filters.job !== "전체") {
        const selectedJob = jobs.find((job) => job.name === filters.job);

        filter = filter.filter((applicant) => {
            const index = applicant.positions.indexOf(selectedJob.position);
            if (index === 0) {
                applicant.position_match_rating = 14;
                return true;
            } else if (index === 1) {
                applicant.position_match_rating = 12;
                return true;
            } else if (index === 2) {
                applicant.position_match_rating = 10;
                return true;
            } else return false;
        });

        filter = [3, 2, 1].reduce((union, minMatch, currentIndex, array) => {
            const subset = filter.filter((applicant) => {
                const match = applicant.skills.reduce(
                    (matchedSkills, skill) => {
                        if (selectedJob.skills.indexOf(skill) !== -1)
                            matchedSkills.push(skill);
                        return matchedSkills;
                    },
                    []
                );
                applicant.skill_match_rating = match.length * 10;

                return match.length >= minMatch;
            });
            const returnArray = [...new Set([...union, ...subset])];

            console.log(array);
            return returnArray;
        }, []);

        filter.forEach((applicant) => {
            const index = applicant.companies.indexOf(selectedJob.company_name);
            if (index === 0) applicant.company_match_rating = 15;
            else if (index === 1) applicant.company_match_rating = 13;
            else if (index === 2) applicant.company_match_rating = 11;

            applicant.match = filters.job;
        });
    } else {
        if (filters.company !== "전체") {
            filter = filter.filter((applicant) => {
                const index = applicant.companies.indexOf(filters.company);
                if (index === 0) {
                    applicant.company_match_rating = 15;
                    return true;
                } else if (index === 1) {
                    applicant.company_match_rating = 13;
                    return true;
                } else if (index === 2) {
                    applicant.company_match_rating = 11;
                    return true;
                } else return false;
            });
            filter.forEach((applicant) => {
                if (applicant.match !== "") applicant.match += " - ";
                applicant.match += filters.company;
            });
        }
        if (filters.position !== "전체") {
            filter = filter.filter((applicant) => {
                const index = applicant.positions.indexOf(filters.position);
                if (index === 0) {
                    applicant.position_match_rating = 14;
                    return true;
                } else if (index === 1) {
                    applicant.position_match_rating = 12;
                    return true;
                } else if (index === 2) {
                    applicant.position_match_rating = 10;
                    return true;
                } else return false;
            });
            filter.forEach((applicant) => {
                if (applicant.match !== "") applicant.match += " - ";
                applicant.match += filters.position;
            });
        }
        if (filters.skill !== "전체") {
            filter = filter.filter(
                (applicant) => applicant.skills.indexOf(filters.skill) !== -1
            );
            filter.forEach((applicant) => {
                applicant.skill_match_rating = 10;
                if (applicant.match !== "") applicant.match += " - ";
                applicant.match += filters.skill;
            });
        }
    }

    filter.forEach((applicant) => {
        const sum =
            applicant.position_match_rating +
            applicant.skill_match_rating +
            applicant.company_match_rating;
        applicant.match_rating = sum;
    });

    const collection = filter.sort(function (a, b) {
        if (a.match_rating > b.match_rating) {
            return -1;
        }
        if (a.match_rating < b.match_rating) {
            return 1;
        }
        // a must be equal to b
        return 0;
    });

    console.log("single collection");
    console.log(collection);
    return collection;
};
