import axios from "axios";
import { API_URL } from "../common/constants";

export const getTalentProfileForCompany = async (recommendationId) => {
    const url = `${API_URL}/recommendations/${recommendationId}`;
    const headers = {
        "Content-Type": "application/json",
        "Api-Token": null,
    };
    try {
        const response = await axios.get(url, { headers });
        console.log("getTalentProfileForCompany response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("getTalentProfileForCompany error", error);
        return false;
    }
};

export const getTalentProfile = async (token) => {
    const url = `${API_URL}/talent/profile`;
    const headers = {
        "Content-Type": "application/json",
        "Api-Token": token,
    };
    try {
        const response = await axios.get(url, { headers });
        console.log("getTalentProfile response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("getTalentProfile error", error);
        return false;
    }
};

export const putTalentProfileThumbnail = async (data, config) => {
    try {
        const response = await axios.put(
            `${API_URL}/talent/profile/thumbnail`,
            data,
            config
        );
        console.log("putTalentProfileThumbnail response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("putTalentProfileThumbnail error", error);
        return false;
    }
};

export const postTalentProfilePhoto = async (data, config) => {
    const { index } = config.headers;
    try {
        const response = await axios.post(
            `${API_URL}/talent/profile/photos/${index}`,
            data,
            config
        );
        console.log("postTalentProfilePhoto response", response);
        return response;
    } catch (error) {
        console.log("postTalentPhoto error", error);
        return false;
    }
};

export const swapTalentProfilePhoto = async (data, config) => {
    const { index } = config.headers;
    try {
        const response = await axios.patch(
            `${API_URL}/talent/profile/photos/${index}`,
            data,
            config
        );
        console.log("swapTalentProfilePhoto response", response);
        return response;
    } catch (error) {
        console.log("swapTalentProfilePhoto error", error);
        return false;
    }
};

export const deleteTalentProfilePhoto = async (config) => {
    const { index } = config.headers;
    try {
        const response = await axios.delete(
            `${API_URL}/talent/profile/photos/${index}`,
            config
        );
        console.log("deleteTalentProfilePhoto response", response);
        return response;
    } catch (error) {
        console.log("deleteTalentPhoto error", error);
        return false;
    }
};

export const updateTalentPreferenceEnableRecommendation = async (
    token,
    data
) => {
    const url = `${API_URL}/talent/preference/enable-recommendation`;
    console.log("updating data", data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };
    try {
        const response = await axios.patch(url, data, config);
        console.log(
            "updateTalentPreferenceEnableRecommendation response",
            response
        );
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentPreference error", error);
        return false;
    }
};

export const updateTalentProfileBasicinfo = async (token, data) => {
    const url = `${API_URL}/talent/profile/basicinfo`;
    console.log("updating data", data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };
    try {
        const response = await axios.patch(url, data, config);
        console.log("updateTalentProfileBasicinfo response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentProfileBasicinfo error", error);
        return false;
    }
};

export const updateTalentProfileHopePosition = async (token, data) => {
    const url = `${API_URL}/talent/profile/hopeposition`;
    console.log("updating data", data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };
    try {
        const response = await axios.patch(url, data, config);
        console.log("updateTalentProfileHopePosition response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentProfileHopePosition error", error);
        return false;
    }
};

export const updateTalentProfileIsComplete = async (token, data) => {
    const url = `${API_URL}/talent/profile/complete`;
    console.log("updating data", data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };
    try {
        const response = await axios.patch(url, data, config);
        console.log("updateTalentProfileIsComplete response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentProfileIsComplete error", error.response);
        return false;
    }
};

export const getSkillList = async (prefix) => {
    try {
        const response = await axios.get(
            `${API_URL}/autocompletes/hardskills?prefix=${encodeURIComponent(
                prefix
            )}`
        );
        console.log("getSkillList response", response);
        return response;
    } catch (error) {
        console.log("getSkillList error", error);
        return false;
    }
};

export const putTalentProfileHardSkill = async (token, hardSkill) => {
    const url = `${API_URL}/talent/profile/hardskill`;
    const data = {
        "hard-skill": hardSkill,
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };
    try {
        const response = await axios.put(url, data, config);
        console.log("putTalentProfileHardSkill response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("putTalentProfileHardSkill error", error);
        return false;
    }
};

export const putTalentProfileHardSkills = async (token, hardSkills) => {
    const url = `${API_URL}/talent/profile/hardskills`;
    const data = {
        "hard-skills": hardSkills,
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };
    try {
        const response = await axios.put(url, data, config);
        console.log("putTalentProfileHardSkills response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("putTalentProfileHardSkills error", error);
        return false;
    }
};

export const createTalentProfileEducation = async (token, data) => {
    const url = `${API_URL}/talent/profile/education`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.post(url, data, config);
        console.log("createTalentProfileEducation response", response);
        if (response.status === 201) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("createTalentProfileEducation error", error);
        return false;
    }
};

export const updateTalentProfileEducation = async (token, id, data) => {
    const url = `${API_URL}/talent/profile/education/${id}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.patch(url, data, config);
        console.log("updateTalentProfileEducation response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentProfileEducation error", error);
        return false;
    }
};

export const deleteTalentProfileEducation = async (token, id) => {
    const url = `${API_URL}/talent/profile/education/${id}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.delete(url, config);
        console.log("deleteTalentProfileEducation response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("deleteTalentProfileEducation error", error);
        return false;
    }
};

export const createTalentProfileCareer = async (token, data) => {
    const url = `${API_URL}/talent/profile/career`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.post(url, data, config);
        console.log("createTalentProfileCareer response", response);
        if (response.status === 201) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("createTalentProfileCareer error", error);
        return false;
    }
};

export const updateTalentProfileCareer = async (token, id, data) => {
    const url = `${API_URL}/talent/profile/career/${id}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.patch(url, data, config);
        console.log("updateTalentProfileCareer response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentProfileCareer error", error);
        return false;
    }
};

export const deleteTalentProfileCareer = async (token, id) => {
    const url = `${API_URL}/talent/profile/career/${id}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.delete(url, config);
        console.log("deleteTalentProfileCareer response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("deleteTalentProfileCareer error", error);
        return false;
    }
};

export const createTalentProfileProject = async (token, data) => {
    const url = `${API_URL}/talent/profile/project`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.post(url, data, config);
        console.log("createTalentProfileProject response", response);
        if (response.status === 201) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("createTalentProfileProject error", error);
        return false;
    }
};

export const updateTalentProfileProject = async (token, id, data) => {
    const url = `${API_URL}/talent/profile/project/${id}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.patch(url, data, config);
        console.log("updateTalentProfileProject response", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log("updateTalentProfileProject error", error);
        return false;
    }
};

export const deleteTalentProfileProject = async (token, id) => {
    const url = `${API_URL}/talent/profile/project/${id}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Api-Token": token,
        },
    };

    try {
        const response = await axios.delete(url, config);
        console.log("deleteTalentProfileProject response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("deleteTalentProfileProject error", error);
        return false;
    }
};
