import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "100px 20px 20px",
        "& > *": {
            color: "#222222",
        },
    },
    body: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "32px 20px 32px",
        "& > *": {
            color: "#222222",
        },
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(1),
            width: "100%",
        },
        "& > div": {
            margin: theme.spacing(1, 0, 4),
        },
        "& > hr": {
            margin: theme.spacing(3, 0),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    resize: {
        fontSize: 14,
    },
    sectionIcon: {
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 20,
        fontWeight: "bold",
        "&:nth-of-type(1)": {
            marginBottom: 28,
        },
    },
    desc: {
        fontSize: 14,
        fontWeight: 300,
        margin: "8px 0px 20px",
    },
    noticeBox: {
        backgroundColor: "#f8f8f8",
        border: "1px solid #dddddd",
        borderRadius: 8,
        padding: 16,
        marginBottom: 12,
        "& > *": {
            fontSize: 14,
            color: "#222222",
            margin: theme.spacing(0.5, 0, 0),
        },
        "& > p:first-child": {
            marginTop: 0,
        },
    },
    caption: {
        ...Texts.basic(12, 300, "#bbbbbb"),
    },
    noticeTitle: {
        fontWeight: "bold",
        margin: theme.spacing(0, 0, 1),
    },
    requirementBox: {
        border: "1px solid #999999",
        borderRadius: 8,
        padding: theme.spacing(2, 2, 2),
        marginBottom: 32,
    },
    requirementTitle: {
        ...Texts.basic(16, 500, "#222222"),
    },
    requirementGuide: {
        textAlign: "right",
        marginTop: 20,
        ...Texts.basic(12, "normal", "#444444"),
    },
    requirementItemBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 24,
    },
    requirementContentBox: {
        marginLeft: 12,
    },
    requirementLabel: {
        ...Texts.basic(12, "normal", "#999999"),
    },
    requirement: {
        ...Texts.basic(14, "normal", "#222222"),
    },
    stepSection: {
        "& > stepRoundNum:nth-child(1)": {
            backgroundColor: "#444444",
        },
    },
    stepTitle: {
        marginTop: 32,
        ...Texts.basic(16, "bold", "#222222"),
        marginBottom: 20,
    },
    stepBox: { display: "flex", flexDirection: "row", marginBottom: 24 },
    stepRoundNum: {
        border: "1px solid #dddddd",
        borderRadius: 18,
        backgroundColor: "#eeeeee",
        width: 36,
        height: 36,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...Texts.basic(14, "normal", "#999999", 1.71),
        marginRight: 12,
        flexShrink: 0,
    },
    stepRoundNumDark: {
        border: "1px solid #dddddd",
        borderRadius: 18,
        backgroundColor: "#444444",
        width: 36,
        height: 36,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...Texts.basic(14, "normal", "#ffffff", 1.71),
        marginRight: 12,
        flexShrink: 0,
    },
    step: {
        marginTop: 5,
        ...Texts.basic(16, 500, "#222222", 1.5),
        marginBottom: 3,
    },
    stepContent: {
        ...Texts.basic(14, "normal", "#666666", 1.71),
    },
    duration: {
        marginTop: 12,
        backgroundColor: "#eeeeee",
        padding: "6px 12px",
        ...Texts.basic(12, "normal", "#999999", 1.33),
        borderRadius: 14,
        display: "inline-flex",
    },
    inputTitle: {
        fontSize: 16,
        fontWeight: "bold",
    },
    imputLabel: {
        flex: 1,
    },
    inputUsage: {
        ...Texts.basic(12, "normal", "#444444"),
        marginBottom: 32,
    },
    topTextField: {
        marginBottom: 9,
    },
    inlinSelect: {
        paddingTop: 17.5,
        paddingBottom: 17.5,
        fontSize: 14,
    },
    majorBox: {
        display: "flex",
    },
    major: {
        flex: 0.6,
        marginRight: 16,
    },
    schoolState: {
        flex: 0.4,
    },
    sectionDivider: {
        height: theme.spacing(1),
        //marginTop: 32,
    },
    submitButton: {
        height: 56,
        width: "100%",
        backgroundColor: "#0c46f2",
        ...Texts.basic(16, "normal", "#ffffff"),
    },
    modalScreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        outline: 0,
        textAlign: "center",
        borderRadius: 12,
        width: 320,
        padding: "32px 16px 20px",
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 8,
    },
    modalMessage: {
        fontSize: 16,
        fontWeight: 500,
        color: "#222222",
        marginBottom: 12,
    },
    modalInfo: {
        fontSize: 12,
        fontWeight: 300,
        color: "#999999",
        marginBottom: 32,
    },
    modalButton: {
        width: "100%",
        fontSize: 16,
        height: 56,
    },
    foot: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "32px 20px 20px",
    },
    consentTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 8,
    },
    consentNotice: {
        fontSize: 14,
        fontWeight: 300,
        marginBottom: 20,
    },
    noticeContent: {
        marginBottom: 16,
    },
    noticeCaption: {
        fontSize: 12,
        fontWeight: 500,
        color: "#bbbbbb",
    },
    consentBtn: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: 20,
        marginBottom: 28,
    },
    consent: {
        fontSize: 16,
        fontWeight: 300,
        marginLeft: 9,
    },
}));
