import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
	AppBar,
	Toolbar,
	Box,
	Typography,
	Container,
	Button,
	Paper,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isAndroid, isWindows } from "react-device-detect";

import { Colors } from "../../styles";

const useStyles = makeStyles((theme) => ({
	container: {
		position: "relative",
	},
	appBar: {
		backgroundColor: "#ffffff",
		height: 56,
	},
	toolBar: {
		minHeight: 56,
	},
	identity: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	logo: {
		width: 70,
		height: 21,
	},
	link: {
		color: theme.palette.common.black,
		fontSize: 14,
		fontWeight: 500,
		marginRight: 12,
	},
	paddingBox: {
		flexGrow: 1,
	},
	promotionWrap: {
		position: "relative",
	},
	menuTextButton: {
		color: "#666666",
	},
	badge: {
		backgroundColor: "#ff1717",
	},
	menuLink: {
		textDecoration: "none",
		color: "inherit",
	},
	listItemText: {
		textAlign: "center",
		fontSize: 14,
		fontWeight: 300,
	},
	OtherButton: {
		width: 150,
		color: Colors.Gray300,
	},
	stickyBannerForCompany: {
		position: "-webkit-sticky",
		position: "sticky",
		height: 40,
		top: 56,
		zIndex: 1,
		flexGrow: 1,
		backgroundColor: "#dee4fb",
		borderColor: "#dee4fb",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		color: Colors.Gray800,
		cursor: "pointer",
	},
}));

const CompanyServiceHeaderD = ({ userType }) => {
	const theme = useTheme();
	const history = useHistory();
	const classes = useStyles();

	return (
		<React.Fragment>
			<AppBar position="fixed" className={classes.appBar} elevation={0}>
				<Container maxWidth="lg" className={classes.container}>
					<Toolbar classes={{ root: classes.toolBar }} disableGutters>
						<RouterLink to="/company" className={classes.identity}>
							<img
								src={require("assets/talentx_logo_black.png")}
								srcSet={`${require("assets/talentx_logo_black@2x.png")} 2x, ${require("assets/talentx_logo_black@3x.png")} 3x`}
								className={classes.logo}
								alt="talentX for Company"
							/>
						</RouterLink>
						<Box className={classes.paddingBox}></Box>
						{/* <Button
							className={classes.menuTextButton}
							onClick={() => history.push("/devstarx")}
						>
							데브스타 스카우트
						</Button> */}
						<Button
							className={classes.menuTextButton}
							onClick={() => history.push("/pricing")}
						>
							기업 멤버십
						</Button>
						<Button
							className={classes.menuTextButton}
							onClick={() =>
								window.open(
									"https://talentx.channel.io/",
									"newWindow"
								)
							}
						>
							문의하기
						</Button>
						{/* <Button
							className={classes.menuTextButton}
							onClick={() =>
								window.open(
									isAndroid || isWindows
										? "https://play.google.com/store/apps/details?id=com.talentinum.talentx"
										: "https://apps.apple.com/kr/app/%ED%83%A4%EB%9F%B0%ED%8A%B8%EC%97%91%EC%8A%A4/id1529534277",
									"newWindow"
								)
							}
						>
							앱 설치
						</Button> */}
						<Button
							className={classes.OtherButton}
							onClick={() => history.push("/")}
						>
							인재 회원이신가요?
						</Button>
					</Toolbar>
				</Container>
			</AppBar>
			<Paper
				className={classes.stickyBannerForCompany}
				variant="outlined"
				square
				onClick={() =>
					window.open("https://devision.co.kr", "newWindow")
				}
			>
				[공지] 탤런트엑스 V2 개발중으로 '데비전'을 이용바랍니다.
			</Paper>
			{/* <Paper
				className={classes.stickyBannerForCompany}
				variant="outlined"
				square
				onClick={() => history.push("/company-contract")}
			>
				2024년 기업 멤버십 신청하기
			</Paper> */}
		</React.Fragment>
	);
};

export default CompanyServiceHeaderD;
