const applicants = require("data/admin/applicants.json");

export const searchTalents = (
    keyword,
    inSearch,
    talents,
    setTalents,
    oracle
) => {
    const collectionHardCopy = inSearch
        ? talents.map((doc) => ({
              ...doc,
          }))
        : applicants.map((doc) => ({
              ...doc,
          }));
    const searchedTalent = collectionHardCopy.filter((applicant) =>
        applicant.name.includes(keyword)
    );
    const collection = searchedTalent.sort(function (a, b) {
        if (a.match_rating > b.match_rating) {
            return -1;
        }
        if (a.match_rating < b.match_rating) {
            return 1;
        }
        // a must be equal to b
        return 0;
    });

    setTalents(collection);
};
