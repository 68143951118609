import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ServiceHeader, ServiceHeaderD } from "../components";
import {
    Box,
    Container,
    Typography,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Backdrop,
    CircularProgress,
    Modal,
    InputAdornment,
    Divider,
    Chip,
} from "@material-ui/core";
import { TXImage } from "../../components/tx";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./styleReverseOpenScoutApplication";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { getLocalYYYYMMDD } from "helper/misc";
import { openScoutPositions } from "../../data/positions";

import {
    getTalentApplcationByPhone,
    putTalentApplication,
} from "apis/firestore/apply";

const ReverseOpenScoutAppication = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles({ isMobile });
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [birth, setBirth] = useState(new Date("2000-01-01T00:00:00Z"));
    const [position, setPosition] = useState("");
    const [years, setYears] = useState("");
    const [company, setCompany] = useState("");
    const [companies, setCompanies] = useState([]);
    const [consent, setConsent] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePhoneChange = async (e) => {
        const _phone = e.target.value;
        setPhone(_phone);
        if (_phone.length === 11) {
            const application = await getTalentApplcationByPhone(_phone);
            if (application) {
                console.log("application", application);
                setBirth(new Date(application.birth));
                setEmail(application.email);
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                }, 3000);
            }
        }
    };
    const handleBirthChange = (date) => {
        setBirth(date);
    };
    const handlePositionChange = (e) => {
        setPosition(e.target.value);
    };
    const handleYearsChange = (e) => {
        setYears(e.target.value);
    };
    const handleCompanyChange = (e) => {
        console.log(e.target.value);
        setCompany(e.target.value);
    };
    const handleReturnKeyPress = (e) => {
        if (e.key === "Enter") {
            const input = e.target.value;
            if (!companies.includes(input))
                setCompanies((prev) => [...prev, input]);
            setCompany("");
        }
    };
    const handleRemoveCompany = (company) => {
        setCompanies((prev) => prev.filter((c) => c !== company));
    };

    useEffect(() => {
        setSubmittable(
            !!name &&
                !!birth &&
                !!email &&
                !!phone &&
                !!position &&
                (!!years || typeof years === "number") &&
                consent
        );
    }, [name, birth, email, phone, position, years, consent]);

    const handleSubmit = () => {
        setIsFetching(true);
        const application = {
            name: name.trim(),
            birth: getLocalYYYYMMDD(birth),
            email: email.trim(),
            phone: phone.trim(),
            school: null,
            major: null,
            eduState: null,
            bootcamp: null,
            working: null,
            yearsOfExperience: String(years),
            position: position,
            testType: "프로필 심사",
            testDate: getLocalYYYYMMDD(new Date()),
            channel: "오픈 스카우트 시즌2 데브스타X",
            mentor: null,
            companies: companies,
        };
        putTalentApplication(application, setIsFetching, setOpen);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        setIsFetching(true);
        setTimeout(() => props.history.push("/?type=talent"), 1000);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 데브스타X 지원</title>
            </Helmet>
            {isMobile ? <ServiceHeader /> : <ServiceHeaderD />}
            <Container maxWidth="sm">
                <Box className={classes.section}>
                    <Box className={classes.programTitleWrap}>
                        <Typography className={classes.programTitle}>
                            오픈스카우트 시즌2
                        </Typography>
                    </Box>
                    <Typography className={classes.pageTitle}>
                        데브스타X 참가 지원
                    </Typography>
                    <Divider className={classes.thickDivider}></Divider>
                    <Typography className={classes.sectionTitle}>
                        지원 방법 안내
                    </Typography>
                    <Typography className={classes.additInfo}>
                        앱 프로필 작성까지 하셔야 지원이 완료됩니다.
                    </Typography>
                    <Typography className={classes.stepTitle}>
                        {" "}
                        STEP 1
                        <Typography
                            className={classes.stepContent}
                            display="inline"
                        >
                            지원서 작성
                        </Typography>
                    </Typography>
                    <Typography className={classes.stepTitle}>
                        {" "}
                        STEP 2
                        <Typography
                            className={classes.stepContent}
                            display="inline"
                        >
                            지원 대상 여부 검토
                        </Typography>
                    </Typography>
                    <Typography className={classes.stepTitle}>
                        {" "}
                        STEP 3
                        <Typography
                            className={classes.stepContent}
                            display="inline"
                        >
                            앱 다운로드 및 회원가입
                        </Typography>
                    </Typography>
                    <Typography className={classes.stepTitle}>
                        {" "}
                        STEP 4
                        <Typography
                            className={classes.stepContent}
                            display="inline"
                        >
                            앱 프로필 작성(지원 완료)
                        </Typography>
                    </Typography>
                    <Box className={classes.eventBox}>
                        <Chip label="이벤트" className={classes.eventChip}>
                            {" "}
                        </Chip>
                        <Typography className={classes.eventTitle}>
                            앱 프로필 작성까지 완료한 지원자 전원 <br />
                            <Typography className={classes.eventTitleBlue}>
                                스타벅스 아메리카노{" "}
                            </Typography>
                            쿠폰 증정!
                        </Typography>
                    </Box>
                    <Divider className={classes.divider}></Divider>
                    <Typography className={classes.sectionTitle}>
                        지원서 작성
                    </Typography>
                    <form className={classes.form}>
                        <Typography className={classes.inputTitle}>
                            이름
                        </Typography>
                        <TextField
                            required
                            className={classes.textField}
                            inputProps={{ maxLength: 10 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="이름"
                            value={name}
                            onChange={handleNameChange}
                        />
                        <Typography className={classes.inputTitle}>
                            휴대폰번호
                        </Typography>
                        <TextField
                            required
                            id="standard-basic"
                            variant="outlined"
                            className={classes.textField}
                            inputProps={{ maxLength: 11 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            placeholder="휴대폰번호를 입력해주세요.(-없이 11자리)"
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                        <Typography className={classes.inputTitle}>
                            생년월일
                        </Typography>
                        <Box className={classes.tooltipBase}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    style={{
                                        marginTop: 0,

                                        backgroundColor: "#f4f4f4",
                                    }}
                                    InputProps={{
                                        classes: { input: classes.resize },
                                    }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd"
                                    invalidDateMessage={
                                        <Typography style={{ fontSize: 12 }}>
                                            YYYY/MM/DD 형식으로 입력해 주세요!
                                        </Typography>
                                    }
                                    margin="normal"
                                    id="date-picker-inline"
                                    maxDate={new Date()}
                                    value={birth}
                                    onChange={handleBirthChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                            <Box
                                className={
                                    tooltipOpen
                                        ? classes.tooltip
                                        : classes.hidden
                                }
                            >
                                <Typography className={classes.tooltipText}>
                                    회원님의 정보를 찾았어요!
                                </Typography>
                            </Box>
                        </Box>
                        <Typography className={classes.inputTitle}>
                            이메일
                        </Typography>
                        <Typography className={classes.inputDesc}>
                            앱 회원가입 시 사용하실 이메일을 입력해주세요
                        </Typography>
                        <Box className={classes.tooltipBase}>
                            <TextField
                                required
                                id="standard-basic"
                                variant="outlined"
                                className={classes.textField}
                                InputProps={{
                                    classes: { input: classes.resize },
                                }}
                                placeholder="이메일을 입력해주세요"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <Box
                                className={
                                    tooltipOpen
                                        ? classes.tooltip
                                        : classes.hidden
                                }
                            >
                                <Typography className={classes.tooltipText}>
                                    회원님의 정보를 찾았어요!
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.positionYearsWrap}>
                            <FormControl
                                variant="outlined"
                                className={classes.position}
                            >
                                <Typography className={classes.inputTitle}>
                                    지원 분야
                                </Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    displayEmpty
                                    value={position}
                                    onChange={handlePositionChange}
                                    classes={{ root: classes.inlinSelect }}
                                    style={{ backgroundColor: "#f4f4f4" }}
                                >
                                    <MenuItem
                                        value=""
                                        classes={{ root: classes.resize }}
                                        disabled
                                    >
                                        선택
                                    </MenuItem>
                                    {openScoutPositions.map((item, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={item.position}
                                            classes={{
                                                root: classes.resize,
                                            }}
                                        >
                                            {item.domain} : {item.position}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                className={classes.years}
                            >
                                <Typography className={classes.inputTitle}>
                                    지원 분야 경력
                                </Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    displayEmpty
                                    value={years}
                                    onChange={handleYearsChange}
                                    classes={{ root: classes.resize }}
                                    style={{ backgroundColor: "#f4f4f4" }}
                                >
                                    <MenuItem
                                        value=""
                                        classes={{ root: classes.resize }}
                                        disabled
                                    >
                                        선택
                                    </MenuItem>
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                                        (idx) => (
                                            <MenuItem
                                                key={idx}
                                                value={idx}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                {idx ? idx + "년" : "신입"}
                                            </MenuItem>
                                        )
                                    )}
                                    <MenuItem
                                        key={10}
                                        value={10}
                                        classes={{ root: classes.resize }}
                                    >
                                        10년 이상
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography className={classes.inputTitle}>
                            이직 희망 기업
                        </Typography>
                        <Typography className={classes.inputDesc}>
                            회원님이 이직하고 싶어하는 기업을 우선하여
                            프로그램을 공개 및 운영합니다.
                        </Typography>
                        <TextField
                            required
                            style={{
                                marginBottom: 10,
                                backgroundColor: "#f4f4f4",
                            }}
                            inputProps={{ maxLength: 10 }}
                            InputProps={{
                                classes: { input: classes.resize },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography
                                            className={classes.adornment}
                                        >
                                            추가하기[Enter키]
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                            id="standard-basic"
                            variant="outlined"
                            placeholder="기업 이름"
                            value={company}
                            onChange={handleCompanyChange}
                            onKeyPress={handleReturnKeyPress}
                        />
                        <Box className={classes.companyWrap}>
                            {companies?.map((item, idx) => (
                                <Box className={classes.companyChip} key={idx}>
                                    <Typography
                                        className={classes.companyLabel}
                                    >
                                        {item}
                                    </Typography>
                                    <TXImage
                                        imgName="icon-remove-20-dp"
                                        onClick={() =>
                                            handleRemoveCompany(item)
                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                    </form>
                    <Box className={classes.foot}>
                        <Typography className={classes.consentTitle}>
                            개인정보 수집·이용 동의
                        </Typography>
                        <Typography className={classes.consentNotice}>
                            (주)탤런티넘은 '개인정보 보호법'에 따라 본인의
                            동의를 얻어 서비스 제공을 위한 개인정보를
                            수집·이용합니다.
                        </Typography>
                        <Box className={classes.noticeBox}>
                            <Typography className={classes.noticeTitle}>
                                1) 개인정보 수집 목적
                            </Typography>
                            <Typography className={classes.noticeContent}>
                                지원서 심사, 경품 지급
                            </Typography>
                            <Typography className={classes.noticeTitle}>
                                2) 개인정보 수집 항목
                            </Typography>
                            <Typography className={classes.noticeContent}>
                                이름, 생년월일, 이메일, 연락처, 직업현황,
                                개발경력
                            </Typography>
                            <Typography className={classes.noticeCaption}>
                                &#8226; 지원서 심사 및 경품 지급 완료 후
                                개인정보가 불필요하게 되었을 때 개인정보를 즉시
                                파기합니다.
                            </Typography>
                        </Box>
                        <Box
                            className={classes.consentBtn}
                            onClick={() => {
                                setConsent((prev) => !prev);
                            }}
                        >
                            {consent ? (
                                <img
                                    src={require("assets/icon-toggle-checkbox-checked-24.png")}
                                    srcSet={`${require("assets/icon-toggle-checkbox-checked-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-checked-24@3x.png")} 3x`}
                                    alt="consent checkbox"
                                ></img>
                            ) : (
                                <img
                                    src={require("assets/icon-toggle-checkbox-24.png")}
                                    srcSet={`${require("assets/icon-toggle-checkbox-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-24@3x.png")} 3x`}
                                    alt="consent checkbox"
                                ></img>
                            )}
                            <Typography className={classes.consent}>
                                개인정보 수집·이용에 동의합니다
                            </Typography>
                        </Box>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.submitButton}
                            disabled={!submittable}
                            onClick={handleSubmit}
                        >
                            신청하기
                        </Button>
                    </Box>
                </Box>

                <Backdrop className={classes.backdrop} open={isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Modal
                    open={open}
                    onClose={handleClose}
                    className={classes.modalScreen}
                >
                    <Box className={classes.modalBox}>
                        <Typography className={classes.modalTitle}>
                            오픈스카우트 시즌 2 지원완료
                        </Typography>
                        <Typography className={classes.modalMessage}>
                            지원해주셔서 감사합니다!
                            <br />
                            담당자 확인 후 지원 조건 충족 시
                            <br />
                            탤런트엑스 앱 설치 링크를 보내드립니다.
                        </Typography>
                        <Typography className={classes.modalInfo}>
                            심사는 영업일 기준 1 - 3일 소요
                        </Typography>
                        <Button
                            onClick={handleOk}
                            variant="contained"
                            color="primary"
                            className={classes.modalButton}
                        >
                            확인
                        </Button>
                    </Box>
                </Modal>
            </Container>
        </React.Fragment>
    );
};

export default ReverseOpenScoutAppication;
