import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Container,
    Paper,
    Tabs,
    Tab,
    Tooltip,
} from "@material-ui/core";
import { ServiceHeader, ServiceFooter } from "pages/components";
import serviceMainImage from "assets/serviceMainImage@2x.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import TalentContent from "./TalentContent";
import ConpanyContent from "./CompanyContent";

import "date-fns";

const content = {
    mission: "실력 있는 개발자 채용\n프리미엄 스카우트 플랫폼,\n탤런트엑스",
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.background.paper,
    },
    identity: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexGrow: 1,
    },
    logo: {
        width: 70,
        height: 20,
        marginLeft: 20,
    },
    link: {
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 500,
        marginRight: 12,
    },
    menuButton: {
        marginRight: 4,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        textAlign: "center",
        width: "100%",
    },
    main: {
        position: "relative",
        backgroundImage: `url(${serviceMainImage})`,

        backgroundSize: "cover",
        // [theme.breakpoints.down("xs")]: {
        //     backgroundImage: `url(${serviceMainImageDesktop})`,
        //     //backgroundSize: "contain",
        // },

        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        //display: "flex",
        textAlign: "center",
    },
    dummy: {
        visibility: "hidden",
        width: "100%",
        objectFit: "contain",
    },
    badge: {
        backgroundColor: "#ff1717",
    },
    mission: {
        position: "absolute",
        top: 116,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "90%",
        fontSize: 28,
        fontWeight: "bold",
        color: "#222222",
        whiteSpace: "pre-wrap",
        lineHeight: 1.29,
    },
    stickyTabs: {
        position: "-webkit-sticky",
        position: "sticky",
        top: 56,
        zIndex: 1,
        flexGrow: 1,
    },

    values: {
        textAlign: "center",
        paddingTop: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    proposition: {
        fontSize: 28,
        fontWeight: "bold",
        marginBottom: 53,
        whiteSpace: "pre-wrap",
    },
    value: {
        marginBottom: 48,
    },

    phantom: {
        width: "100%",
        height: 60,
    },
    fixedButtonRouteLink: {
        textDecoration: "none",
    },
    menuLink: {
        textDecoration: "none",
        color: "inherit",
    },
    listItemText: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 300,
    },
}));

const LightTooltip = withStyles((theme) => ({
    arrow: {
        color: "#ff5252",
    },
    tooltip: {
        backgroundColor: "#ff5252",
        color: "#ffffff",
        //boxShadow: theme.shadows[1],
        fontSize: 12,
        marginTop: 0,
        marginRight: 20,
        borderRadius: 20,
        padding: theme.spacing(0.5, 2),
    },
}))(Tooltip);

const TalentX = () => {
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);
    const [userType, setUserType] = React.useState(
        query.get("type") === "talent" ? 1 : 0
    );

    const handleUserTypeChange = (event, newUserType) => {
        setUserType(newUserType);
    };

    useEffect(() => window.scrollTo({ top: 0 }), []);

    const [loaded, setLoaded] = useState(false);

    return (
        <React.Fragment>
            <ServiceHeader userType={userType} />
            <Container maxWidth="xs" disableGutters>
                <Box className={classes.body}>
                    {!loaded && (
                        <Skeleton variant="rect" width="100%" height="100vh" />
                    )}
                    <Box className={classes.main}>
                        <Typography className={classes.mission}>
                            {content.mission}
                        </Typography>
                        <img
                            src={serviceMainImage}
                            className={classes.dummy}
                            alt="main"
                            onLoad={() => setLoaded(true)}
                        ></img>
                    </Box>
                    <Paper
                        className={classes.stickyTabs}
                        variant="outlined"
                        square
                    >
                        <Tabs
                            value={userType}
                            onChange={handleUserTypeChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="채용 담당자" />
                            <Tab label="개발자" />
                        </Tabs>
                    </Paper>
                    {userType === 1 ? <TalentContent /> : <ConpanyContent />}
                </Box>
                <ServiceFooter backgroudColor={"#2c2c2c"} />
                <Box className={classes.phantom}></Box>
            </Container>
        </React.Fragment>
    );
};

export default TalentX;
