import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MyButton } from "pages/components";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(10, 0),
        },
        inputField: {
            height: "3rem",
            width: "44vmin",
            [theme.breakpoints.down("xs")]: {
                width: "80vmin",
            },
            margin: theme.spacing(1),
        },
    };
});

export default function RecruitmentApplyForm() {
    const classes = useStyles();
    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        birth: null,
    });

    let submittable = true;
    // values.name && values.email && values.phone && values.birth;

    const handleTextChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleDateChange = (date) => {
        setValues({
            ...values,
            birth: date,
        });
    };

    const handleSummit = async (event) => {
        try {
            event.preventDefault();
            await axios.post("http://localhost:8000/prospectivetalents", {
                name: "한신환",
                email: "arthu@talentx.co.kr",
                phone: "01048547456",
                birth: "20200930",
            });
        } catch (error) {
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
        } finally {
            submittable = true;
        }
    };

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <TextField
                    id="standard-basic"
                    name="name"
                    label="이름"
                    placeholder=""
                    defaultValue=""
                    helperText=""
                    size="medium"
                    className={classes.inputField}
                    value={values.name}
                    onChange={handleTextChange}
                />
                <TextField
                    id="standard-basic"
                    name="email"
                    label="이메일"
                    placeholder=""
                    helperText=""
                    size="medium"
                    className={classes.inputField}
                    value={values.email}
                    onChange={handleTextChange}
                />
                <TextField
                    id="standard-basic"
                    name="phone"
                    label="휴대폰번호"
                    placeholder="' - ' 없이 숫자만 입력"
                    helperText=""
                    size="medium"
                    className={classes.inputField}
                    value={values.phone}
                    onChange={handleTextChange}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        clearable
                        openTo="year"
                        variant="inline"
                        format="yyyy/MM/dd"
                        maxDate={new Date()}
                        margin="normal"
                        id="date-picker-inline"
                        label="생년월일"
                        value={values.birth}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        className={classes.inputField}
                    />
                </MuiPickersUtilsProvider>
                <MyButton
                    color="white"
                    onClick={handleSummit}
                    disabled={!submittable}
                >
                    <Typography variant="button" noWrap>
                        참가 신청하기
                    </Typography>
                </MyButton>
            </Box>
        </React.Fragment>
    );
}
