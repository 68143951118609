import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { BenefitImage, BenefitImage2 } from "data";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            textAlign: "center",
        },
        benefit1: {
            padding: theme.spacing(10, 0),
            backgroundColor: theme.palette.background.paper,
        },
        benefit2: {
            padding: theme.spacing(10, 0),
            backgroundColor: "#f9f9f9",
        },
        typography: {
            margin: theme.spacing(1, 0),
            textAlign: "left",
        },
        typographyBold: {
            margin: theme.spacing(1, 0),
            textAlign: "left",
            fontWeight: 700,
        },
        paper: {
            margin: theme.spacing(2, 0),
            borderColor: "black",
        },
        paperTypography: {
            display: "block",
            borderTop: "solid 1px #000000",
            padding: theme.spacing(2),
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                // padding: "0 calc((100% - 296px) / 2)",
                padding: "0 32px",
            },
        },
    };
});

export default function RecruitmentBenefits(params) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return isMobile ? (
        <Box className={classes.root}>
            <Box className={classes.benefit1}>
                <Container maxWidth="sm" className={classes.container}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            container
                            direction="column"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    className={classes.typographyBold}
                                >
                                    나와 핏이 맞는 기업들의
                                    <br />
                                    면접제안을 받아보세요!
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.typography}
                                >
                                    테스트를 통과하면
                                    <br />
                                    단 한 번의 지원으로 유망 기업들의
                                    <br />
                                    면접 제안을 받을 수 있습니다.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Paper
                                variant="outlined"
                                square
                                className={classes.paper}
                            >
                                <BenefitImage />
                                <Typography
                                    variant="body2"
                                    className={classes.paperTypography}
                                >
                                    가만히 있어도
                                    <br />
                                    자동으로 면접 제안이!
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.benefit2}>
                <Container maxWidth="sm" className={classes.container}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            direction="column"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    className={classes.typographyBold}
                                >
                                    전문 HR팀이
                                    <br />
                                    기업을 연결해줍니다.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.typography}
                                >
                                    아무곳이나 추천하지 않아요! 전문 HR팀이
                                    <br />
                                    나의 기술 역량과 선호도를 분석해
                                    <br />
                                    나와 정말 잘 맞는 기업을 연결해줍니다.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Paper
                                variant="outlined"
                                square
                                className={classes.paper}
                            >
                                <BenefitImage2 />
                                <Typography
                                    variant="body2"
                                    className={classes.paperTypography}
                                >
                                    내 취향을 저격하는
                                    <br />
                                    기업을 만나세요!
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    ) : (
        <Box className={classes.root}>
            <Box className={classes.benefit1}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            direction="column"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    className={classes.typographyBold}
                                >
                                    나와 핏이 맞는 기업들의
                                    <br />
                                    면접제안을 받아보세요!
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.typography}
                                >
                                    테스트를 통과하면 단 한 번의 지원으로
                                    <br />
                                    유망 기업들의 면접 제안을 받을 수 있습니다.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-end"
                        >
                            <Paper
                                variant="outlined"
                                square
                                className={classes.paper}
                            >
                                <BenefitImage />
                                <Typography
                                    variant="body2"
                                    className={classes.paperTypography}
                                >
                                    가만히 있어도
                                    <br />
                                    자동으로 면접 제안이!
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.benefit2}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-start"
                        >
                            <Paper
                                variant="outlined"
                                square
                                className={classes.paper}
                            >
                                <BenefitImage2 />
                                <Typography
                                    variant="body2"
                                    className={classes.paperTypography}
                                >
                                    내 취향을 저격하는
                                    <br />
                                    기업을 만나세요!
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    className={classes.typographyBold}
                                >
                                    전문 HR팀이
                                    <br />
                                    기업을 연결해줍니다.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.typography}
                                >
                                    아무곳이나 추천하지 않아요!
                                    <br />
                                    전문 HR팀이 나의 기술 역량과 선호도를 분석해
                                    <br />
                                    나와 정말 잘 맞는 기업을 연결해줍니다.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
