import React, { useState, useEffect } from "react";
import { Box, Typography, Container, Paper, Tooltip } from "@material-ui/core";
import { TalentServiceHeader, ServiceFooter } from "pages/components";
import serviceMainImage from "assets/serviceMainImage@2x.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import TalentContent from "./TalentContent3";
import ConpanyContent from "./CompanyContent3";

import "date-fns";
import { TXImage } from "../../components/tx";
import { Colors } from "../../styles";
import PopupM from "./PopupM";

const content = {
	mission: "기업에게 직접\n영입 제안을 받는\n정말 편한 개발자 이직",
};

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: theme.palette.background.paper,
	},
	identity: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		flexGrow: 1,
	},
	logo: {
		width: 70,
		height: 20,
		marginLeft: 20,
	},
	link: {
		color: theme.palette.common.black,
		fontSize: 14,
		fontWeight: 500,
		marginRight: 12,
	},
	menuButton: {
		marginRight: 4,
	},
	body: {
		backgroundColor: theme.palette.common.white,
		textAlign: "center",
		width: "100%",
	},
	main: {
		marginTop: 56,
		position: "relative",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		background: "linear-gradient(#363741,#101010)",
	},
	mission: {
		position: "relative",
		marginTop: 110,
		marginBottom: 60,
		width: "80%",
		fontSize: 28,
		fontWeight: "bold",
		color: "#ffffff",
		whiteSpace: "pre-wrap",
		lineHeight: 1.29,
	},
	mainImg: {
		display: "block",
		width: "90%",
		objectFit: "contain",
		marginLeft: "auto",
		marginRight: "auto",
	},
	dummy: {
		visibility: "hidden",
		width: "100%",
		objectFit: "contain",
	},
	badge: {
		backgroundColor: "#ff1717",
	},
	values: {
		textAlign: "center",
		paddingTop: 80,
		paddingLeft: 40,
		paddingRight: 40,
	},
	proposition: {
		fontSize: 28,
		fontWeight: "bold",
		marginBottom: 53,
		whiteSpace: "pre-wrap",
	},
	value: {
		marginBottom: 48,
	},

	phantom: {
		width: "100%",
		height: 50,
	},
	fixedButtonRouteLink: {
		textDecoration: "none",
	},
	menuLink: {
		textDecoration: "none",
		color: "inherit",
	},
	listItemText: {
		textAlign: "center",
		fontSize: 14,
		fontWeight: 300,
	},
}));

const LightTooltip = withStyles((theme) => ({
	arrow: {
		color: "#ff5252",
	},
	tooltip: {
		backgroundColor: "#ff5252",
		color: "#ffffff",
		//boxShadow: theme.shadows[1],
		fontSize: 12,
		marginTop: 0,
		marginRight: 20,
		borderRadius: 20,
		padding: theme.spacing(0.5, 2),
	},
}))(Tooltip);

const TalentX3 = () => {
	const classes = useStyles();
	const query = new URLSearchParams(window.location.search);
	// const [userType, setUserType] = React.useState(
	//     query.get("type") === "talent" ? 1 : 0
	// );

	// const handleUserTypeChange = (event, newUserType) => {
	//     setUserType(newUserType);
	// };

	useEffect(() => window.scrollTo({ top: 0 }), []);

	const [loaded, setLoaded] = useState(false);

	return (
		<React.Fragment>
			<TalentServiceHeader />
			<Container maxWidth="xs" disableGutters>
				<Box className={classes.body}>
					{!loaded && (
						<Skeleton variant="rect" width="100%" height="100vh" />
					)}
					<Box className={classes.main}>
						<Typography className={classes.mission}>
							{content.mission}
						</Typography>
						{/* <TXImage
                            imgName={"feature-incoming-offer"}
                            className={classes.dummy}
                            alt="main"
                            onLoad={() => setLoaded(true)}
                        /> */}
						<TXImage
							imgName={"feature-incoming-offer"}
							className={classes.mainImg}
							alt="main"
							onLoad={() => setLoaded(true)}
						/>
					</Box>
					{/* <PopupM /> */}
					<TalentContent />
				</Box>
				<ServiceFooter backgroudColor={"#2c2c2c"} />
				{/* <Box className={classes.phantom}></Box> */}
			</Container>
		</React.Fragment>
	);
};

export default TalentX3;
