export const positions = [
    "전체",
    "백엔드 엔지니어",
    "프론트엔드 엔지니어",
    "풀스택 엔지니어",
    "iOS 엔지니어",
    "안드로이드 엔지니어",
    "데이터 엔지니어",
    "머신러닝 엔지니어",
    "임베디드 엔지니어",
    "컴퓨터비전 엔지니어",
    "클라우드 엔지니어",
    "IT프로젝트 관리자",
    "보안 엔지니어",
    "게임 엔지니어",
    "IT 컨설턴트",
    "자연어처리 엔지니어",
    "QA 엔지니어",
    "블록체인 엔지니어",
];

export const hopePositions = [
    "프론트엔드 엔지니어",
    "백엔드 엔지니어",
    "풀스택 엔지니어",
    "데이터 엔지니어",
    "데이터 분석가",
    "iOS 엔지니어",
    "안드로이드 엔지니어",
    "모바일 엔지니어",
    "게임 엔지니어",
    "AR/VR 엔지니어",
    "블록체인 엔지니어",
    "머신러닝 엔지니어",
    "컴퓨터비전 엔지니어",
    "자연어처리 엔지니어",
    "검색 엔지니어",
    "보안 엔지니어",
    "시스템 엔지니어",
    "임베디드 엔지니어",
    "데이터베이스 관리자",
    "네트워크 엔지니어",
    "데브옵스 엔지니어",
    "클라우드 엔지니어",
    "QA 엔지니어",
    "UI/UX 엔지니어",
    "웹 퍼블리셔",
    "UI/UX 디자이너",
    "그래픽 디자이너",
    "브랜드 디자이너",
    "프로덕트 매니저",
    "프로젝트 매니저",
];

export const openScoutPositions = [
    { position: "머신러닝 엔지니어", domain: "AI" },
    { position: "컴퓨터비전 엔지니어", domain: "AI" },
    { position: "자연어처리 엔지니어", domain: "AI" },
    { position: "iOS 엔지니어", domain: "Mobile" },
    { position: "안드로이드 엔지니어", domain: "Mobile" },
    { position: "모바일 엔지니어", domain: "Mobile" },
    { position: "프론트엔드 엔지니어", domain: "Web" },
    { position: "백엔드 엔지니어", domain: "Web" },
    { position: "풀스택 엔지니어", domain: "Web" },
];
