import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    myButton: {
        background: (props) =>
            props.color === "red"
                ? "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
                : props.color === "blue"
                ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                : "white",
        borderRadius: 3,
        boxShadow: (props) =>
            props.color === "red"
                ? "0 3px 5px 2px rgba(255, 105, 135, .3)"
                : props.color === "blue"
                ? "0 3px 5px 2px rgba(33, 203, 243, .3)"
                : null,
        color: "black",
        height: "3rem",
        width: "385px",
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 64px)",
        },
        //padding: "0 30px",

        //margin: "0 auto",
        wordWrap: "noWap",
        position: "fixed",
        bottom: theme.spacing(3),
        left: "50%",
        //right: "50%",
        transform: "translate(-50%, 0%)",
        border: (props) => (props.color === "white" ? "solid 1px #000000" : 0),
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#39d07f",
        color: "#1a1a84",
        //boxShadow: theme.shadows[1],
        fontSize: 12,
        border: "solid 1px #1a1a84",
        padding: theme.spacing(1),
    },
    // arrow: {
    //     color: "#39d07f",
    //     border: "solid 1px #1a1a84",
    // },
}))(Tooltip);

function MyButton(props) {
    const { color, ...other } = props;
    const classes = useStyles(props);

    const calculateTimeLeft = () => {
        const difference = +new Date("2020-10-24 00:00:00") - +new Date();

        let timeLeft = false;

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });

    return (
        <LightTooltip
            title={
                timeLeft
                    ? `신청 마감까지 ${timeLeft.days}일 ${timeLeft.hours}시 ${timeLeft.minutes}분 ${timeLeft.seconds}초 남음`
                    : "신청이 마감되었습니다."
            }
            placement="top"
            aria-label="d day"
            open
        >
            <Button
                className={classes.myButton}
                disabled={!timeLeft}
                {...other}
            ></Button>
        </LightTooltip>
    );
}

MyButton.propTypes = {
    color: PropTypes.oneOf(["blue", "red", "white"]).isRequired,
};

export default MyButton;
