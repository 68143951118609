import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, fade, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { TableSortLabel, Toolbar, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import CategoryIcon from "@material-ui/icons/Category";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import ThumbsUpDownOutlinedIcon from "@material-ui/icons/ThumbsUpDownOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import WorkIcon from "@material-ui/icons/Work";
import CodeIcon from "@material-ui/icons/Code";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import DehazeIcon from "@material-ui/icons/Dehaze";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";
import { positions, skills, companyTestData } from "data";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "uid",
        numeric: true,
        disablePadding: true,
        label: "ID",
        disableSorting: false,
    },
    {
        id: "career_years",
        numeric: false,
        disablePadding: false,
        label: "구분",
        disableSorting: false,
    },
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "성명",
        disableSorting: false,
    },
    // {
    //     id: "email",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "이메일",
    //     disableSorting: false,
    // },
    // {
    //     id: "phone",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "휴대폰번호",
    //     disableSorting: false,
    // },
    // {
    //     id: "birth",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "생년월일",
    //     disableSorting: false,
    // },

    {
        id: "first_position",
        numeric: false,
        disablePadding: false,
        label: "직무",
        disableSorting: false,
    },
    // {
    //     id: "second_position",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "희망직무 2",
    //     disableSorting: false,
    // },
    // {
    //     id: "third_position",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "희망직무 3",
    //     disableSorting: false,
    // },
    {
        id: "first_skill",
        numeric: false,
        disablePadding: false,
        label: "기술스택",
        disableSorting: false,
    },
    // {
    //     id: "first_company",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "희망기업",
    //     disableSorting: false,
    // },
    // {
    //     id: "second_company",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "희망기업 2",
    //     disableSorting: false,
    // },
    // {
    //     id: "third_company",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "희망기업 3",
    //     disableSorting: false,
    // },

    {
        id: "result",
        numeric: false,
        disablePadding: false,
        label: "최종결과",
        disableSorting: false,
    },
    {
        id: "match",
        numeric: false,
        disablePadding: false,
        label: "추천대상",
        disableSorting: false,
    },
    {
        id: "match_rating",
        numeric: true,
        disablePadding: false,
        label: "추천지수",
        disableSorting: false,
    },
    {
        id: "history",
        numeric: false,
        disablePadding: false,
        label: "이력",
        disableSorting: true,
    },
    // {
    //     id: "created_at",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "지원일시",
    // },
];
function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all talents" }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : "asc"}
                    >
                        {headCell.disableSorting ? (
                            <Typography></Typography>
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : "asc"
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
    grow: {
        flexGrow: 1,
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.light, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.secondary.light, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(0),
            width: "auto",
        },
        display: "flex",
        alignItems: "center",
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
        height: "100%",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        height: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        numSelected,
        filters,
        onFilter,
        onChangePage,
        onResetOrderBy,
        onJudge,
        onDelete,
        onDownload,
        onSearch,
        talents,
        companies,
        jobs,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [checked, setChecked] = React.useState(false);

    const handleFunctionMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFunctionMenuClose = () => {
        setAnchorEl(null);
    };
    const handleJudge = (event) => {
        onJudge(event.target.textContent);
        setAnchorEl(null);
    };

    const handleResultFilterOpen = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleResultFilterClose = () => {
        setAnchorEl2(null);
    };
    const handleResultFilterChange = (event) => {
        onFilter("result", event.target.textContent);
        onChangePage(event, 0);
        onResetOrderBy();
        setAnchorEl2(null);
    };

    const handlePositionFilterOpen = (event) => {
        setAnchorEl3(event.currentTarget);
    };
    const handlePositionFilterClose = () => {
        setAnchorEl3(null);
    };
    const handlePositionFilterChange = (event) => {
        onFilter("position", event.target.textContent);
        onChangePage(event, 0);
        onResetOrderBy();
        setAnchorEl3(null);
    };

    const handleSkillFilterOpen = (event) => {
        setAnchorEl4(event.currentTarget);
    };
    const handleSkillFilterClose = () => {
        setAnchorEl4(null);
    };
    const handleSkillFilterChange = (event) => {
        onFilter("skill", event.target.textContent);
        onChangePage(event, 0);
        onResetOrderBy();
        setAnchorEl4(null);
    };

    const handleCompanyMatchOpen = (event) => {
        setAnchorEl5(event.currentTarget);
    };
    const handleCompanyMatchClose = () => {
        setAnchorEl5(null);
    };
    const handleCompanyMatchChange = (event) => {
        onFilter("company", event.target.textContent);
        onChangePage(event, 0);
        onResetOrderBy();
        setAnchorEl5(null);
    };

    const handleJobMatchOpen = (event) => {
        setAnchorEl6(event.currentTarget);
    };
    const handleJobMatchClose = () => {
        setAnchorEl6(null);
    };
    const handleJobMatchChange = (event) => {
        onFilter("job", event.target.textContent);
        onChangePage(event, 0);
        onResetOrderBy();
        setAnchorEl6(null);
    };

    const handleAllMatchClick = (event) => {
        onFilter("all", null);
        onChangePage(event, 0);
        onResetOrderBy();
    };

    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleSearch = (event) => {
        if (event.key === "Enter") {
            onSearch(event.target.value, checked);
            onChangePage(event, 0);
            event.preventDefault();
        }
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected}명 선택됨
                </Typography>
            ) : (
                <>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            //value={name}
                            //onChange={handleSearchInputChange}
                            onKeyPress={handleSearch}
                            inputProps={{ "aria-label": "search" }}
                        />
                        <Tooltip title="결과 내 재검색">
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckChange}
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                            />
                        </Tooltip>
                    </div>

                    <div className={classes.grow} />
                </>
            )}

            {/* <Typography
                    className={classes.title}
                    variant="subtitle1"
                    id="tableTitle"
                    component="div"
                >
                    SW엔지니어 리스트 <small>(총 {talents.length}명)</small>
                </Typography> */}

            {numSelected > 0 ? (
                <>
                    <Tooltip title="편집 기능">
                        <IconButton
                            aria-label="delete"
                            onClick={handleFunctionMenuOpen}
                        >
                            {/* <DeleteIcon /> */}
                            <DehazeIcon />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="function-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleFunctionMenuClose}
                    >
                        {/* {["합격", "불합격"].map((result) => (
                            <MenuItem key={result} onClick={handleJudge}>
                                {result}
                            </MenuItem>
                        ))} */}
                        <MenuItem key="다운로드" onClick={onDownload}>
                            다운로드
                        </MenuItem>
                        {/* <MenuItem key="삭제" onClick={onDelete}>
                            삭제
                        </MenuItem> */}
                    </Menu>
                </>
            ) : (
                <>
                    {/* <Autocomplete
                        id="combo-box-demo"
                        options={talents}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <div className={classes.inputRoot}>
                                    <TextField
                                        {...params}
                                        className={classes.inputInput}
                                        onKeyPress={(ev) => {
                                            console.log(
                                                `Pressed keyCode ${ev.key}`
                                            );
                                            if (ev.key === "Enter") {
                                                onSearch(ev.target.value);
                                                ev.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    /> */}

                    <Tooltip title={"결과별 필터 - " + filters.result}>
                        <IconButton
                            aria-label="result filter list"
                            onClick={handleResultFilterOpen}
                        >
                            <ThumbsUpDownIcon
                                color={
                                    filters.result !== "전체"
                                        ? "secondary"
                                        : "inherit"
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="result-filter-menu"
                        anchorEl={anchorEl2}
                        keepMounted
                        open={Boolean(anchorEl2)}
                        onClose={handleResultFilterClose}
                    >
                        {["전체", "합격", "미정", "불합격"].map((result) => (
                            <MenuItem
                                key={result}
                                onClick={handleResultFilterChange}
                                selected={filters.result === result}
                            >
                                {result}
                            </MenuItem>
                        ))}
                    </Menu>

                    <Tooltip title={"채용별 매치 - " + filters.job}>
                        <IconButton
                            aria-label="job match list"
                            onClick={handleJobMatchOpen}
                        >
                            <WorkIcon
                                color={
                                    filters.job !== "전체"
                                        ? "secondary"
                                        : "inherit"
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="job-match-menu"
                        anchorEl={anchorEl6}
                        keepMounted
                        open={Boolean(anchorEl6)}
                        onClose={handleJobMatchClose}
                    >
                        {[{ name: "전체" }, ...jobs].map((job) => (
                            <MenuItem
                                key={job.name}
                                onClick={handleJobMatchChange}
                                selected={filters.job === job.name}
                            >
                                {job.name}
                            </MenuItem>
                        ))}
                    </Menu>

                    <Tooltip title={"선호 기업별 필터 - " + filters.company}>
                        <IconButton
                            aria-label="company match list"
                            onClick={handleCompanyMatchOpen}
                        >
                            <BusinessIcon
                                color={
                                    filters.company !== "전체"
                                        ? "secondary"
                                        : "inherit"
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="company-match-menu"
                        anchorEl={anchorEl5}
                        keepMounted
                        open={Boolean(anchorEl5)}
                        onClose={handleCompanyMatchClose}
                    >
                        {[{ name: "전체" }, ...companies].map((company) => (
                            <MenuItem
                                key={company.name}
                                onClick={handleCompanyMatchChange}
                                selected={filters.company === company.name}
                            >
                                {company.name}
                            </MenuItem>
                        ))}
                    </Menu>

                    <Tooltip title={"직무별 필터 - " + filters.position}>
                        <IconButton
                            aria-label="position filter list"
                            onClick={handlePositionFilterOpen}
                        >
                            <CategoryIcon
                                color={
                                    filters.position !== "전체"
                                        ? "secondary"
                                        : "inherit"
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="position-filter-menu"
                        anchorEl={anchorEl3}
                        keepMounted
                        open={Boolean(anchorEl3)}
                        onClose={handlePositionFilterClose}
                    >
                        {positions.map((position) => (
                            <MenuItem
                                key={position}
                                onClick={handlePositionFilterChange}
                                selected={filters.position === position}
                            >
                                {position}
                            </MenuItem>
                        ))}
                    </Menu>

                    <Tooltip title={"기술별 필터 - " + filters.skill}>
                        <IconButton
                            aria-label="skill filter list"
                            onClick={handleSkillFilterOpen}
                        >
                            <CodeIcon
                                color={
                                    filters.skill !== "전체"
                                        ? "secondary"
                                        : "inherit"
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="skill-filter-menu"
                        anchorEl={anchorEl4}
                        keepMounted
                        open={Boolean(anchorEl4)}
                        onClose={handleSkillFilterClose}
                    >
                        {skills.map((skill) => (
                            <MenuItem
                                key={skill}
                                onClick={handleSkillFilterChange}
                                selected={filters.skill === skill}
                            >
                                {skill}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Tooltip title={"일괄 매치"}>
                        <IconButton
                            aria-label="all match list"
                            onClick={handleAllMatchClick}
                        >
                            <AllInclusiveIcon
                                color={filters.all ? "secondary" : "inherit"}
                            />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired,
    onJudge: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(Object),
    jobs: PropTypes.arrayOf(Object),
};

const useRowStyles = makeStyles((theme, styleProps) => {
    return {
        root: (styleProps) => ({
            "& > *": {
                borderBottom: "unset",
                backgroundColor: styleProps
                    ? theme.palette.secondary.light
                    : theme.palette.common.white,
            },
        }),
        table: {
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.background.default,
        },
    };
});

function ExpandableTableRow(props) {
    const { talent, index, isSelected, handleClick } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles(false);

    const isItemSelected = isSelected(talent.id || talent.uid);
    const labelId = `enhanced-table-checkbox-${index}`;
    //console.log(talent);
    let localBirth;
    if (typeof talent.birth === "object") {
        localBirth = new Date(talent.birth.seconds * 1000).toLocaleDateString();

        if (isNaN(new Date(talent.birth.seconds * 1000).getTime()))
            localBirth = talent.birth.toLocaleDateString();
    } else {
        localBirth = new Date(talent.birth).toLocaleDateString();
    }

    const onExpand = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={talent.id || talent.uid + talent.match}
                selected={isItemSelected}
                className={classes.root}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        onClick={(event) =>
                            handleClick(event, talent.id || talent.uid)
                        }
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="talent"
                    padding="none"
                    align="right"
                >
                    {talent.uid}
                </TableCell>
                <TableCell align="left">
                    {talent.career_years === 0
                        ? "신입"
                        : talent.career_years + "년차"}
                </TableCell>
                <TableCell align="left">{talent.name}</TableCell>
                {/* <TableCell align="left">{talent.email}</TableCell>
                <TableCell align="left">{talent.phone}</TableCell>
                <TableCell align="left">{localBirth}</TableCell> */}
                <TableCell align="left">{talent.first_position}</TableCell>
                {/* <TableCell align="left">{talent.second_position}</TableCell>
                <TableCell align="left">{talent.third_position}</TableCell> */}
                <TableCell align="left">{talent.first_skill}</TableCell>

                {/* <TableCell align="left">{talent.first_company}</TableCell> */}
                {/* <TableCell align="left">{talent.second_company}</TableCell>
                <TableCell align="left">{talent.third_company}</TableCell> */}
                <TableCell align="left">{talent.result}</TableCell>
                <TableCell align="left">{talent.match || "-"}</TableCell>
                <TableCell align="right">
                    {talent.match_rating || "-"}
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={onExpand}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon
                                color={
                                    talent.result === "합격"
                                        ? "primary"
                                        : "inherit"
                                }
                            />
                        ) : (
                            <KeyboardArrowDownIcon
                                color={
                                    talent.result === "합격"
                                        ? "primary"
                                        : "inherit"
                                }
                            />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography
                                variant="caption"
                                gutterBottom
                                component="div"
                            >
                                <b>[개인정보]</b>
                            </Typography>
                            <Table
                                size="small"
                                aria-label="records"
                                className={classes.table}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">
                                            이메일
                                        </TableCell>
                                        <TableCell align="left">
                                            휴대폰번호
                                        </TableCell>
                                        <TableCell align="left">
                                            생년월일
                                        </TableCell>
                                        <TableCell align="left">
                                            IT포트폴리오
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="left"
                                        >
                                            {talent.email}
                                        </TableCell>

                                        <TableCell align="left">
                                            {talent.phone}
                                        </TableCell>
                                        <TableCell align="left">
                                            {localBirth}
                                        </TableCell>
                                        <TableCell align="left">
                                            {talent.portfolio !== "미제출" ? (
                                                <Link
                                                    href={talent.portfolio}
                                                    target="_blank"
                                                    rel="noopener"
                                                    //color="inherit"
                                                >
                                                    열람하기
                                                </Link>
                                            ) : (
                                                talent.portfolio
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography
                                variant="caption"
                                gutterBottom
                                component="div"
                            >
                                <b>[지원정보]</b>
                            </Typography>
                            <Table
                                size="small"
                                aria-label="records"
                                className={classes.table}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">
                                            희망직무
                                        </TableCell>
                                        <TableCell align="left">
                                            기술스택
                                        </TableCell>
                                        <TableCell align="left">
                                            희망기업
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="left"
                                        >
                                            {talent.positions.join(", ") ||
                                                "미제출"}
                                            {/* {`${talent.first_position}, ${talent.second_position}, ${talent.third_position}`} */}
                                        </TableCell>

                                        <TableCell align="left">
                                            {talent.skills.join(", ") ||
                                                "미제출"}
                                        </TableCell>
                                        <TableCell align="left">
                                            {talent.companies.join(", ") ||
                                                "미제출"}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography
                                variant="caption"
                                gutterBottom
                                component="div"
                            >
                                <b>[평가정보]</b>
                            </Typography>
                            <Table
                                size="small"
                                aria-label="records"
                                className={classes.table}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">전형</TableCell>
                                        <TableCell align="left">점수</TableCell>
                                        <TableCell align="left">등급</TableCell>
                                        <TableCell align="left">순위</TableCell>
                                        <TableCell align="left">결과</TableCell>
                                        <TableCell align="left">일시</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {talent.records.map((record) => {
                                        let localDate;
                                        if (typeof record.date === "object") {
                                            localDate = record.date
                                                ? new Date(
                                                      record.date.seconds
                                                  ).toLocaleString()
                                                : "미응시";
                                        } else {
                                            localDate = new Date(
                                                record.date
                                            ).toLocaleString();
                                        }

                                        const percentageScore = isNaN(
                                            record.score
                                        )
                                            ? "해당없음"
                                            : Math.round(
                                                  (Number(record.score) / 350) *
                                                      100
                                              );
                                        return (
                                            <TableRow
                                                key={record.name + localDate}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="left"
                                                >
                                                    {record.name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {record.score}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {record.level}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {record.ranking}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {record.result}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {localDate}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function EnhancedTableBody(props) {
    const {
        talents,
        order,
        orderBy,
        page,
        rowsPerPage,
        isSelected,
        handleClick,
        emptyRows,
        dense,
    } = props;

    return (
        <TableBody>
            {stableSort(talents, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((talent, index) => (
                    <ExpandableTableRow
                        key={
                            talent.id
                                ? talent.id + talent.match
                                : talent.uid + talent.match
                        }
                        talent={talent}
                        index={index}
                        isSelected={isSelected}
                        handleClick={handleClick}
                    />
                ))}

            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: (dense ? 33 : 53) * emptyRows,
                    }}
                >
                    <TableCell colSpan={10} />
                </TableRow>
            )}
        </TableBody>
    );
}

EnhancedTableBody.propTypes = {
    talents: PropTypes.arrayOf(PropTypes.object),
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    isSelected: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    emptyRows: PropTypes.number.isRequired,
    dense: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const Talents = ({
    talents,
    companies,
    jobs,
    loading,
    filters,
    onFilter,
    selected,
    onSelect,
    onJudge,
    onDelete,
    onDownload,
    onSearch,
}) => {
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    //const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = talents.map(
                (talent) => talent.id || talent.uid
            );
            onSelect(newSelecteds);
            return;
        }
        onSelect([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        onSelect(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleResetOrderBy = () => setOrderBy("");

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, talents.length - page * rowsPerPage);

    if (loading) {
        return <h2>Loading...</h2>;
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        filters={filters}
                        onFilter={onFilter}
                        onChangePage={handleChangePage}
                        onResetOrderBy={handleResetOrderBy}
                        onJudge={onJudge}
                        onDelete={onDelete}
                        onDownload={onDownload}
                        onSearch={onSearch}
                        talents={talents}
                        companies={companies}
                        jobs={jobs}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={talents.length}
                            />
                            <EnhancedTableBody
                                talents={talents}
                                order={order}
                                orderBy={orderBy}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                isSelected={isSelected}
                                handleClick={handleClick}
                                emptyRows={emptyRows}
                                dense={dense}
                            />
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 30]}
                        component="div"
                        count={talents.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <FormControlLabel
                    control={
                        <Switch checked={dense} onChange={handleChangeDense} />
                    }
                    label="Dense padding"
                />
            </div>
        </React.Fragment>
    );
};

export default Talents;
