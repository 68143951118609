import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";

import desktopMainImage from "assets/desktopMainImage@2x.png";
import mobileMainImage from "assets/mobileMainImage@2x.png";
import groupIdentity from "assets/groupIdentity@3x.png";
import groupIdentityWhite from "assets/groupIdentityWhite@3x.png";
import benefitImage from "assets/benefitImage@3x.png";
import benefitImage2 from "assets/benefitImage2@3x.png";
import passImage from "assets/pass.png";
import ladderImage from "assets/ladder.png";

export function ServiceMainImage(props) {
    const { isLoaded = false, setIsLoaded = null } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <div>
            {isLoaded ? null : (
                <Skeleton
                    variant="rect"
                    width="100vw"
                    maxWidth="444px"
                    height="100vh"
                />
            )}
            {/* <Skeleton
                variant="rect"
                width="100vw"
                height="100vh"
                style={isLoaded ? { display: "none" } : {}}
            /> */}
            <img
                src={require("assets/serviceMainImage.png")}
                srcSet={`${require("assets/serviceMainImage@2x.png")} 2x, ${require("assets/serviceMainImage@3x.png")} 3x`}
                style={{ width: "100vw", display: "block" }}
                onLoad={setIsLoaded}
                alt="main"
            />
        </div>
    );
}

export function MainImage(props) {
    const { isLoaded = false, setIsLoaded = null } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <div>
            {isLoaded ? null : (
                <Skeleton variant="rect" width="100vw" height="100vh" />
            )}
            {/* <Skeleton
                variant="rect"
                width="100vw"
                height="100vh"
                style={isLoaded ? { display: "none" } : {}}
            /> */}
            <img
                src={isMobile ? mobileMainImage : desktopMainImage}
                style={{ width: "100vw", display: "block" }}
                onLoad={setIsLoaded}
                alt="main"
            />
        </div>
    );
}

MainImage.propTypes = {
    isLoaded: PropTypes.bool,
    setIsLoaded: PropTypes.func,
};

export function LadderImage(params) {
    return (
        <img
            src={ladderImage}
            style={{ width: "100vw", display: "block" }}
            alt="ladder"
        />
    );
}

export function GI() {
    const theme = useTheme();
    return (
        <img
            src={groupIdentity}
            style={{
                width: "295px",
                height: "22px",
                objectFit: "contain",
                marginTop: theme.spacing(8),
            }}
            alt="group identity"
        />
    );
}

export function GIWhite() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <img
            src={groupIdentityWhite}
            style={{
                width: isMobile ? "190px" : "295px",
                height: isMobile ? "15px" : "22px",
                objectFit: "contain",
            }}
            alt="group identity"
        />
    );
}

export function BenefitImage(params) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <img
            src={benefitImage}
            style={{
                maxWidth: isMobile ? "100%" : "448px",
                maxHeight: isMobile ? "auto" : "504px",
                display: "block",
            }}
            alt="main"
        />
    );
}

export function BenefitImage2(params) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <img
            src={benefitImage2}
            style={{
                maxWidth: isMobile ? "100%" : "448px",
                maxHeight: isMobile ? "auto" : "504px",
                display: "block",
            }}
            alt="main"
        />
    );
}

export function PassImage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <img
            src={passImage}
            style={{
                width: isMobile ? "160px" : "289px",
                height: isMobile ? "120px" : "204px",
                //objectFit: "contain",
                //marginTop: theme.spacing(8),
            }}
            alt="pass"
        />
    );
}
