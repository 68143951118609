import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../redux/slice/profileSlice";
import { useHistory } from "react-router-dom";
import { Container, Box, Typography, Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ProfileHeaderD, ServiceFooterD } from "../components";
import { Helmet } from "react-helmet";
import { useStyles } from "./styleProfile";
import { TXImage } from "../../components/tx";

import { deviconMap, devicon } from "data";

import "date-fns";

import ProfileSchool from "./ProfileSchool";
import ProfileCompany from "./ProfileCompany";
import ProfileProject from "./ProfileProject";
import ProfilePortfolioLink from "./ProfilePortfolioLink";

import { getTalentProfileForCompany } from "../../apis/profile/profile";

import {
    displayLocalBirthDayAndAge,
    displayLocalYearMonthDay,
} from "../../helper/misc";

function salaryRangeStr(min, max) {
    let minSalaryR = (min % 100000000) / 10000;
    let minSalaryM = parseInt(min / 100000000);
    let maxSalaryR = (max % 100000000) / 10000;
    let maxSalaryM = parseInt(max / 100000000);

    if (min === max) {
        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        }`;
    } else {
        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        } ~ ${maxSalaryM ? maxSalaryM + "억" : ""}${
            maxSalaryR ? " " + maxSalaryR + "만원" : "원"
        }`;
    }
}

const displayAddress = (address) => {
    return address
        ? address.split(" ").slice(0, 3).join(" ")
        : "거주지를 입력해주세요";
};

const displayHopePosition = (hopePosition) => {
    return hopePosition ? `${hopePosition} 희망` : "희망 직무를 선택해 주세요";
};

const ProfileViewForCompany = (props) => {
    const { id } = props.match.params;
    const history = useHistory();
    if (!id) history.push("/recommendations/access-expired");
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const classes = useStyles();

    useEffect(() => {
        async function fetchTalentProfile() {
            const cloudProfile = await getTalentProfileForCompany(id);
            console.log(cloudProfile);
            if (cloudProfile) {
                dispatch(setProfile(cloudProfile));
            } else {
                history.push("/recommendations/access-expired");
            }
        }
        if (id && profile.photos[0].url === null) {
            fetchTalentProfile();
        }
    }, []);

    const handleImgError = (e) => {
        e.target.src = require("assets/icon-skill-default-52.png");
    };

    const devPositionExpYearTexter = (
        position,
        positionExpYears,
        devExpYears
    ) => {
        let positionText = "소프트웨어 엔지니어";
        let positionExpYearsText = "신입",
            devExpYearsText = "";
        if (position) positionText = position;
        if (positionExpYears) positionExpYearsText = positionExpYears;
        if (devExpYears) devExpYearsText = ` | 총 개발 경력 ${devExpYears}`;
        return position
            ? `${positionText} ${positionExpYearsText}${devExpYearsText}`
            : "현재 직무와 경력을 입력해주세요";
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필</title>
            </Helmet>
            <ProfileHeaderD edit={false} />
            <Container maxWidth="lg">
                <Box className={classes.inner}>
                    <Box className={classes.leftSection}>
                        {profile.email ? (
                            <Box className={classes.basicProfile}>
                                <Box className={classes.profileImgWrap}>
                                    {profile.photos[
                                        profile.photos.findIndex(
                                            (photo) => photo.order === 0
                                        )
                                    ].url ? (
                                        <img
                                            src={
                                                profile.photos[
                                                    profile.photos.findIndex(
                                                        (photo) =>
                                                            photo.order === 0
                                                    )
                                                ].url
                                            }
                                            className={classes.profileImg}
                                            alt="profile"
                                        />
                                    ) : (
                                        <Typography
                                            className={
                                                classes.profileImgPlaceholder
                                            }
                                        >
                                            프로필 사진 비공개
                                        </Typography>
                                    )}
                                </Box>

                                <Box className={classes.talentNameOfferSetWrap}>
                                    <Box>
                                        <Typography
                                            className={classes.talentName}
                                        >
                                            {profile.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                {profile.hopePosition && (
                                    <Box className={classes.targetPositionWrap}>
                                        <Typography
                                            className={classes.targetPosition}
                                        >
                                            {displayHopePosition(
                                                profile.hopePosition
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.birth && (
                                    <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-calendar-24-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {displayLocalBirthDayAndAge(
                                                profile.birth
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.position && (
                                    <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-briefcase-24-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {devPositionExpYearTexter(
                                                profile.position,
                                                profile.positionExpYears,
                                                profile.devExpYears
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.address && (
                                    <Box className={classes.basicInfoWrap}>
                                        <TXImage imgName="icon-location-24-dp" />
                                        <Typography
                                            className={classes.basicInfo}
                                        >
                                            {displayAddress(profile.address)}
                                        </Typography>
                                    </Box>
                                )}
                                {profile.introduction && (
                                    <>
                                        <Typography
                                            className={
                                                classes.selfIntroductionTitle
                                            }
                                        >
                                            자기 소개
                                        </Typography>
                                        <Typography
                                            className={classes.selfIntroduction}
                                        >
                                            {profile.introduction}
                                        </Typography>
                                    </>
                                )}

                                <Divider className={classes.divider} />

                                <Box className={classes.skillHeader}>
                                    <Typography className={classes.skillLabel}>
                                        보유 기술
                                    </Typography>
                                    <Box className={classes.flexGrowBox}></Box>
                                    <Typography
                                        className={classes.skillLevelGuide}
                                    >
                                        숙련도 최저 1 ~ 최고 10
                                    </Typography>
                                </Box>
                                <Box className={classes.skillWrap}>
                                    {profile.skills.map((item, index) => (
                                        <Box
                                            key={index}
                                            className={classes.skillChip}
                                        >
                                            <img
                                                className={classes.skillIcon}
                                                src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconMap.get(
                                                    item.skill
                                                )}/${deviconMap.get(
                                                    item.skill
                                                )}-${
                                                    devicon.find(
                                                        (obj) =>
                                                            obj.name ===
                                                            deviconMap.get(
                                                                item.skill
                                                            )
                                                    )?.versions.svg[0]
                                                }.svg`}
                                                onError={handleImgError}
                                                alt={item.skill}
                                            />
                                            <Typography
                                                className={classes.skillName}
                                            >
                                                {item.skill}
                                            </Typography>
                                            <Typography
                                                className={classes.skillLevel}
                                            >
                                                {item.rate}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                className={classes.leftSectionSkeleton}
                            />
                        )}
                    </Box>

                    <Box className={classes.rightSection}>
                        {profile.email ? (
                            <Box className={classes.detailProfile}>
                                {profile.companies.length > 0 && (
                                    <ProfileCompany edit={false} />
                                )}
                                {profile.projects.length > 0 && (
                                    <ProfileProject edit={false} />
                                )}
                                {!!profile.portfolioLink && (
                                    <ProfilePortfolioLink edit={false} />
                                )}
                                {profile.schools.length > 0 && (
                                    <ProfileSchool edit={false} />
                                )}
                                <Box className={classes.hopeSwitchDateWrap}>
                                    <Typography
                                        className={classes.hopeSwitchDateTitle}
                                    >
                                        이직 희망일
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.hopeSwitchDateViewMode
                                        }
                                    >
                                        {displayLocalYearMonthDay(
                                            profile.hopeWorkBeginAt
                                        )}
                                    </Typography>
                                </Box>
                                <Box className={classes.hopeSalaryWrapViewMode}>
                                    <Typography
                                        className={
                                            classes.hopeSalaryLabelViewMode
                                        }
                                    >
                                        희망 연봉
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.hopeSalaryRangeViewMode
                                        }
                                    >
                                        {salaryRangeStr(
                                            profile.hopeSalary,
                                            profile.hopeSalary
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                className={classes.rightSectionSkeleton}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
            <ServiceFooterD backgroudColor={"#2c2c2c"} />
        </React.Fragment>
    );
};

export default ProfileViewForCompany;
