import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getCompany } from "apis/firestore/fetch";
import { ServiceHeader, ServiceHeaderD } from "pages/components";
import Slider from "react-slick";

import { scoutCompanies } from "../companyScout/dataCompanyScout";

import {
    Container,
    Typography,
    Box,
    Divider,
    Grid,
    Popover,
    Paper,
    Tabs,
    Tab,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Radio,
    RadioGroup,
    FormControlLabel,
    Backdrop,
    CircularProgress,
    Modal,
    Button,
    Link,
} from "@material-ui/core";

import { openScoutData } from "./dataOpenScout";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import Skeleton from "@material-ui/lab/Skeleton";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./styleOpenScout.js";

import { openLinkNewTab, getLocalYYYYMMDD } from "helper/misc";

import { putTalentApplication } from "apis/firestore/apply";

const OpenScout = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles({ isMobile });
    const history = useHistory();
    //console.log(props.match.params);
    //const { id } = props.match.params;
    const id = "1588600171";
    const query = new URLSearchParams(window.location.search);
    const utmSource = query.get("utm_source") || query.get("source");
    const utmMedium = query.get("utm_medium") || query.get("media");
    const utmContent = query.get("utm_content");
    let utm = utmSource ? `utm_source=${utmSource}` : "";
    utm += utmMedium
        ? utm
            ? `&utm_medium=${utmMedium}`
            : `utm_medium=${utmMedium}`
        : "";
    utm += utmContent
        ? utm
            ? `&utm_content=${utmContent}`
            : `utm_content=${utmContent}`
        : "";
    utm = utm ? `?${utm}` : "";
    //console.log("utm", utm);

    const [company, setCompany] = useState();
    useEffect(() => window.scrollTo({ top: 0 }), []);
    useEffect(() => {
        (async () => {
            const result = await getCompany(id);
            //console.log("company-info-in-cloud :", result);
            if (result) {
                if (result.devTeam.positions.length === 0) {
                    const staticCompanyInfo = scoutCompanies.find(
                        (company) => company.registrationNumber === id
                    );
                    result.devTeam = staticCompanyInfo.devTeam;
                    result.devStyles = staticCompanyInfo.devStyles;
                    result.welfares = staticCompanyInfo.welfares;
                }
                const staticCompanyInfo = scoutCompanies.find(
                    (company) => company.registrationNumber === id
                );
                result.devTeam = staticCompanyInfo.devTeam;
                result.devStyles = staticCompanyInfo.devStyles;
                result.devTeam.total = result.devTeam.positions.reduce(
                    (accumulator, currentPosition) =>
                        accumulator + currentPosition.total,
                    0
                );
                setCompany(result);
            }
        })();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState();
    const [devStyleDesc, setDevStyleDesc] = React.useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        const devStyleObj = company.devStyles.find(
            (element) => element.name === event.currentTarget.innerText
        );
        setDevStyleDesc(devStyleObj.description);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popId = open ? "simple-popover" : undefined;

    function CustomPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={require("assets/icon-prev-arrow-32.png")}
                srcSet={`${require("assets/icon-prev-arrow-32@2x.png")} 2x, ${require("assets/icon-prev-arrow-32@3x.png")} 3x`}
                className={className}
                style={{ ...style, zIndex: 1, left: 20 }}
                onClick={onClick}
            ></img>
        );
    }

    function CustomNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={require("assets/icon-next-arrow-32.png")}
                srcSet={`${require("assets/icon-next-arrow-32@2x.png")} 2x, ${require("assets/icon-next-arrow-32@3x.png")} 3x`}
                className={className}
                style={{ ...style, zIndex: 1, right: 20 }}
                onClick={onClick}
            ></img>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveWidth: true,
        adaptiveHeight: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    const expRangeStr = (min, max) =>
        min === 0
            ? `신입 ~ 경력 ${max}년${max === 10 ? " 이상" : ""}`
            : `경력 ${min}년 ~ ${max}년${max === 10 ? " 이상" : ""}`;

    const salaryRangeStr = (min, max) => {
        let minSalaryR = min % 10000;
        let minSalaryM = parseInt(min / 10000);
        let maxSalaryR = max % 10000;
        let maxSalaryM = parseInt(max / 10000);

        return `${minSalaryM ? minSalaryM + "억" : ""}${
            minSalaryR ? " " + minSalaryR + "만원" : "원"
        } ~ ${maxSalaryM ? maxSalaryM + "억" : ""}${
            maxSalaryR ? " " + maxSalaryR + "만원" : "원"
        }`;
    };

    const [name, setName] = useState(query.get("utm_talent"));
    const [birth, setBirth] = useState(null);
    const [email, setEmail] = useState(query.get("utm_email"));
    const [phone, setPhone] = useState(query.get("utm_phone"));
    const [yearsOfExperience, setYearsOfExperience] = useState("");
    const [testDate, setTestData] = useState(new Date("2021-09-23T00:00:00Z"));
    const [position, setPosition] = useState("백엔드 엔지니어");
    const [menu, setMenu] = useState("특별 혜택");
    const [isApplying, setIsApplying] = useState(false);
    const [summited, setSummited] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [isOnboarding, setIsOnboarding] = useState(false);
    const [secondsToDeadLine, setSecondsToDeadLine] = useState();
    const [brightMode, setBrigthMode] = useState(true);
    const [isClose, setIsClose] = useState(false);
    const DEAD_LINE = new Date("2021-09-22T14:59:59Z");

    useEffect(() => {
        const clock = setInterval(() => {
            let differenceInMS = DEAD_LINE - Date.now();
            //console.log("differenceInMS", differenceInMS);
            if (differenceInMS < 1000) {
                setIsClose(true);
                setSecondsToDeadLine("");
                clearInterval(clock);
            } else {
                differenceInMS = differenceInMS / 1000;
                let seconds = Math.floor(differenceInMS % 60);
                differenceInMS = differenceInMS / 60;
                let minutes = Math.floor(differenceInMS % 60);
                differenceInMS = differenceInMS / 60;
                let hours = Math.floor(differenceInMS % 24);
                let days = Math.floor(differenceInMS / 24);
                setSecondsToDeadLine(
                    `${
                        days ? days + " 일 " : ""
                    }${hours} : ${minutes} : ${seconds} 남음`
                );
            }
        }, 1000);
        return () => clearInterval(clock);
    }, []);

    useEffect(() => {
        function daytimeChecker() {
            let now = new Date();
            let nowHours = now.getHours();
            console.log("nowHours", nowHours);
            setBrigthMode(nowHours < 18 && nowHours > 6);
        }
        daytimeChecker();
        const daytimeCheckerRef = setInterval(daytimeChecker, 1000 * 60 * 3);
        return () => clearInterval(daytimeCheckerRef);
    }, []);

    useEffect(() => {
        setSubmittable(
            name &&
                birth &&
                email &&
                phone &&
                yearsOfExperience !== "" &&
                !isClose
        );
    }, [name, birth, email, phone, yearsOfExperience, isClose]);

    const [part, setPart] = useState(0);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleBirthChange = (date) => {
        setBirth(date);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    const handleYearsOfExperienceChange = (event) => {
        setYearsOfExperience(event.target.value);
    };
    const handlePositionChange = (event, newValue) => {
        setPosition(newValue);
    };

    const handleChangePart = (event, newPart) => {
        setPart(newPart);
    };

    const handleApply = () => {
        if (!submittable) {
            return;
        }
        setIsOnboarding(false);
        setIsApplying(true);
        const application = {
            name: name.trim(),
            //birth: birth.toISOString().slice(0, 10).replace(/-/g, ""),
            birth: getLocalYYYYMMDD(birth),
            email: email.trim(),
            phone: phone.trim(),
            school: null,
            major: null,
            eduState: null,
            bootcamp: null,
            working: null,
            yearsOfExperience: String(yearsOfExperience),
            testType: "과제 테스트",
            testDate: getLocalYYYYMMDD(testDate),
            channel: `오픈 스카우트 시즌1${
                props.attribution === "external" ? " 외부" : ""
            }${utmSource ? " " + utmSource : ""}${
                utmMedium ? " " + utmMedium : ""
            }${utmContent ? " " + utmContent : ""}`,
            mentor: null,
        };
        console.log("application", application);

        putTalentApplication(application, setIsApplying, setSummited);
    };

    const handleCancle = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSummited(false);
    };

    const handleOk = () => {
        setSummited(false);
        setIsApplying(true);
        setTimeout(() => history.push("/?type=talent"), 1000);
    };

    const menuRefs = [];
    menuRefs["특별 혜택"] = useRef();
    menuRefs["기업 소개"] = useRef();
    menuRefs["모집 분야"] = useRef();
    menuRefs["개발팀 소개"] = useRef();
    menuRefs["일정 안내"] = useRef();
    menuRefs["자주 묻는 질문"] = useRef();
    menuRefs["지원하기"] = useRef();

    const handleChangeMenu = (event) => {
        setMenu(event.target.innerText);
        if (menuRefs[event.target.innerText].current) {
            menuRefs[event.target.innerText].current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    const handleOnboard = () => {
        setIsOnboarding(true);
        if (menuRefs["지원하기"].current) {
            menuRefs["지원하기"].current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box>{children}</Box>}
            </div>
        );
    }

    return (
        <React.Fragment>
            {isMobile ? <ServiceHeader maxWidth="sm" /> : <ServiceHeaderD />}
            {isMobile ? (
                <>
                    <Box
                        className={
                            brightMode
                                ? classes.headMobile
                                : classes.headMobileDarkMode
                        }
                    ></Box>
                    <Box className={classes.actionCard}>
                        <Typography className={classes.openScoutSeason}>
                            탤런트엑스 오픈스카우트 시즌1.
                        </Typography>
                        <Typography className={classes.openScoutEpisode}>
                            삼쩜삼 백엔드 엔지니어 편
                        </Typography>
                        <Box className={classes.openScoutStep}>
                            <Typography
                                className={classes.openScoutStepContent}
                            >
                                지원 접수
                            </Typography>
                            <Typography
                                className={classes.openScoutStepContent}
                            >
                                09월 06일(월) ~ 22일(수)
                            </Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.openScoutStep}>
                            <Typography
                                className={classes.openScoutStepContent}
                            >
                                과제 테스트
                            </Typography>
                            <Typography
                                className={classes.openScoutStepContent}
                            >
                                09월 23일(목) ~ 30일(목)
                            </Typography>
                        </Box>

                        <Box
                            className={classes.openScoutTimerBox}
                            ref={menuRefs["지원하기"]}
                        >
                            <img
                                src={require("assets/icon-timer.png")}
                                srcSet={`${require("assets/icon-timer@2x.png")} 2x, ${require("assets/icon-timer@3x.png")} 3x`}
                            ></img>
                            <Typography
                                className={classes.openScoutStepTimerLabel}
                            >
                                {!isClose
                                    ? "지원 마감까지"
                                    : "지원이 마감되었습니다."}
                            </Typography>

                            <Box className={classes.openScoutStepTimer}>
                                {secondsToDeadLine}
                            </Box>
                        </Box>
                        {isOnboarding && (
                            <>
                                <Typography
                                    className={classes.applicationTitle}
                                >
                                    지원서 작성
                                </Typography>
                                <form
                                    className={classes.applicationForm}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        disabled={isClose}
                                        required
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                            },
                                        }}
                                        id="standard-basic"
                                        variant="outlined"
                                        placeholder="이름"
                                        //label="이름"
                                        value={name}
                                        onChange={handleNameChange}
                                    />

                                    <TextField
                                        disabled={isClose}
                                        required
                                        id="standard-basic"
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                            },
                                        }}
                                        placeholder="이메일을 입력해주세요"
                                        //label="이메일"
                                        value={email}
                                        onChange={handleEmailChange}
                                        //helperText="해커랭크와 탤런트엑스 이용시 활용됩니다."
                                    />

                                    <TextField
                                        disabled={isClose}
                                        required
                                        id="standard-basic"
                                        variant="outlined"
                                        style={{ marginBottom: 8 }}
                                        inputProps={{
                                            maxLength: 11,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.resize,
                                            },
                                        }}
                                        placeholder="휴대폰번호를 입력해주세요.(-없이 11자리)"
                                        //label="휴대폰번호"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        //helperText="'-' 없이 번호만 입력해 주세요."
                                    />

                                    <FormControl
                                        variant="outlined"
                                        style={{ marginBottom: 8 }}
                                    >
                                        <Select
                                            disabled={isClose}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            displayEmpty
                                            value={yearsOfExperience}
                                            onChange={
                                                handleYearsOfExperienceChange
                                            }
                                            classes={{
                                                root: classes.resize,
                                            }}
                                        >
                                            <MenuItem
                                                value=""
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                                disabled
                                            >
                                                개발 경력
                                            </MenuItem>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                                                (idx) => (
                                                    <MenuItem
                                                        key={idx}
                                                        value={idx}
                                                        classes={{
                                                            root: classes.resize,
                                                        }}
                                                    >
                                                        {idx
                                                            ? idx + "년"
                                                            : "신입"}
                                                    </MenuItem>
                                                )
                                            )}
                                            <MenuItem
                                                key={10}
                                                value={10}
                                                classes={{
                                                    root: classes.resize,
                                                }}
                                            >
                                                10년 이상
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            disabled={isClose}
                                            required
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                },
                                            }}
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="yyyy/MM/dd"
                                            invalidDateMessage={
                                                <Typography
                                                    style={{
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    YYYY/MM/DD 형식으로 입력해
                                                    주세요!
                                                </Typography>
                                            }
                                            initialFocusedDate={
                                                new Date("1990-01-01T00:00:00Z")
                                            }
                                            placeholder="생년월일"
                                            openTo="year"
                                            margin="normal"
                                            id="date-picker-inline"
                                            maxDate={new Date()}
                                            value={birth}
                                            onChange={handleBirthChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            autoOk={true}
                                            //label="생년월일"
                                        />
                                    </MuiPickersUtilsProvider>
                                </form>
                                <Box
                                    className={
                                        submittable
                                            ? classes.applyBtn
                                            : classes.applyBtnDisabled
                                    }
                                    onClick={handleApply}
                                >
                                    제출하기
                                </Box>
                            </>
                        )}
                    </Box>
                    <Divider className={classes.sectionDivider} light></Divider>
                </>
            ) : (
                <Box
                    className={brightMode ? classes.head : classes.headDarkMode}
                ></Box>
            )}

            <Container maxWidth="lg">
                <Box className={classes.openScoutMenuBar}>
                    {openScoutData.menus.map((item, idx) => (
                        <Box
                            key={idx}
                            className={
                                menu === item
                                    ? classes.scrollBtn
                                    : classes.scrollBtnOff
                            }
                            onClick={handleChangeMenu}
                        >
                            {item}
                        </Box>
                    ))}
                </Box>
                <Box className={classes.inner}>
                    <Box className={classes.leftSection}>
                        {company ? (
                            <>
                                {true && (
                                    <>
                                        <Typography
                                            className={classes.subMenuTitle}
                                        >
                                            지원자를 위한 특별 혜택! 🎁
                                            <Box
                                                ref={menuRefs["특별 혜택"]}
                                                className={classes.scrollCap}
                                            ></Box>
                                        </Typography>
                                        {isMobile ? (
                                            <>
                                                <img
                                                    src={require("assets/img-benefit-bonus-mobile.png")}
                                                    srcSet={`${require("assets/img-benefit-bonus-mobile@2x.png")} 2x, ${require("assets/img-benefit-bonus-mobile@3x.png")} 3x`}
                                                    className={
                                                        classes.benefitImg
                                                    }
                                                    alt="303만 원"
                                                ></img>
                                                <img
                                                    src={require("assets/img-benefit-applewatch-1-mobile.png")}
                                                    srcSet={`${require("assets/img-benefit-applewatch-1-mobile@2x.png")} 2x, ${require("assets/img-benefit-applewatch-1-mobile@3x.png")} 3x`}
                                                    className={
                                                        classes.benefitImg
                                                    }
                                                    alt="애플워치 1개"
                                                ></img>
                                                <img
                                                    src={require("assets/img-benefit-applewatch-2-mobile.png")}
                                                    srcSet={`${require("assets/img-benefit-applewatch-2-mobile@2x.png")} 2x, ${require("assets/img-benefit-applewatch-2-mobile@3x.png")} 3x`}
                                                    className={
                                                        classes.benefitImg
                                                    }
                                                    alt="애플워치 2개"
                                                ></img>{" "}
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={require("assets/img-benefit-bonus.png")}
                                                    srcSet={`${require("assets/img-benefit-bonus@2x.png")} 2x, ${require("assets/img-benefit-bonus@3x.png")} 3x`}
                                                    className={
                                                        classes.benefitImg
                                                    }
                                                    alt="303만 원"
                                                ></img>
                                                <img
                                                    src={require("assets/img-benefit-applewatch-1.png")}
                                                    srcSet={`${require("assets/img-benefit-applewatch-1@2x.png")} 2x, ${require("assets/img-benefit-applewatch-1@3x.png")} 3x`}
                                                    className={
                                                        classes.benefitImg
                                                    }
                                                    alt="애플워치 1개"
                                                ></img>
                                                <img
                                                    src={require("assets/img-benefit-applewatch-2.png")}
                                                    srcSet={`${require("assets/img-benefit-applewatch-2@2x.png")} 2x, ${require("assets/img-benefit-applewatch-2@3x.png")} 3x`}
                                                    className={
                                                        classes.benefitImg
                                                    }
                                                    alt="애플워치 2개"
                                                ></img>{" "}
                                            </>
                                        )}
                                        <Typography
                                            className={classes.menuTitle}
                                        >
                                            기업 소개
                                            <Box
                                                ref={menuRefs["기업 소개"]}
                                                className={classes.scrollCap}
                                            ></Box>
                                        </Typography>
                                        <Box className={classes.quoteBox}>
                                            <Typography
                                                className={classes.quote}
                                            >
                                                You work, We help!
                                                <br></br>우리는 고객의 부를
                                                창출하기 위한
                                                <br></br>서비스를 만들어갑니다.
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={
                                                classes.companyIdentityWrap
                                            }
                                        >
                                            {/* <img
                                                src={company.logo?.url}
                                                className={classes.logo}
                                                alt="logo"
                                            ></img> */}
                                            <img
                                                className={classes.logo}
                                                src={require("assets/logo_3o3.png")}
                                                srcSet={`${require("assets/logo_3o3@2x.png")} 2x, ${require("assets/logo_3o3@3x.png")} 3x`}
                                            ></img>
                                            <Box>
                                                <Box
                                                    className={
                                                        classes.companyWrap
                                                    }
                                                >
                                                    <Typography
                                                        className={classes.name}
                                                    >
                                                        {company.name}
                                                    </Typography>
                                                    {/* {company.plan ===
                                                        "premium" && (
                                                        <img
                                                            src={require("assets/img-premium-badge.png")}
                                                            srcSet={`${require("assets/img-premium-badge@2x.png")} 2x, ${require("assets/img-premium-badge@3x.png")} 3x`}
                                                            className={
                                                                classes.premium
                                                            }
                                                        ></img>
                                                    )} */}
                                                </Box>
                                                <Typography
                                                    className={classes.service}
                                                >
                                                    {
                                                        company.services[0]
                                                            .description
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className={classes.imageSlider}>
                                            <Slider {...settings}>
                                                {/* {company.photos.map(
                                                    (item, idx) => (
                                                        <img
                                                            key={idx}
                                                            src={item.url}
                                                            className={
                                                                classes.image
                                                            }
                                                        ></img>
                                                    )
                                                )} */}

                                                <img
                                                    key={0}
                                                    className={classes.image}
                                                    src={require("assets/photo_3o3_1.png")}
                                                    srcSet={`${require("assets/photo_3o3_1@2x.png")} 2x, ${require("assets/photo_3o3_1@3x.png")} 3x`}
                                                ></img>
                                            </Slider>
                                        </Box>

                                        <Typography
                                            className={classes.subMenuTitle}
                                        >
                                            세무/회계 데이터 기반의 테크핀 기업
                                        </Typography>
                                        <Typography
                                            className={classes.companyIntro}
                                        >
                                            {company.description}
                                        </Typography>
                                        <Box className={classes.askingChip}>
                                            삼쩜삼이 뭔가요?
                                        </Box>
                                        <Typography
                                            className={classes.subMenuTitle}
                                        >
                                            삼쩜삼은 세무지식이 부족한 분들을
                                            위해
                                            <br />
                                            세무 고민을 덜어주는 서비스입니다.
                                        </Typography>

                                        <img
                                            src={require("assets/showcase_service.png")}
                                            srcSet={`${require("assets/showcase_service@2x.png")} 2x, ${require("assets/showcase_service@3x.png")} 3x`}
                                            className={classes.serviceImg}
                                            alt="서비스 이미지"
                                        ></img>

                                        <Box className={classes.askingTooltip}>
                                            게다가 그거 아세요? 😉
                                        </Box>
                                        <Typography className={classes.quote}>
                                            삼쩜삼은 서비스 출시 1년만에
                                            <br />
                                            이용자 500만명 유치,
                                            <br />
                                            누적환급액 1,500억을 돌파했어요 🚀
                                        </Typography>
                                        {isMobile ? (
                                            <>
                                                <Box>
                                                    <img
                                                        src={require("assets/img-company-success-1-mobile.png")}
                                                        srcSet={`${require("assets/img-company-success-1-mobile@2x.png")} 2x, ${require("assets/img-company-success-1-mobile@3x.png")} 3x`}
                                                        className={
                                                            classes.successImg
                                                        }
                                                        alt="성과 이미지"
                                                    ></img>
                                                </Box>
                                                <Box>
                                                    <img
                                                        src={require("assets/img-company-success-2-mobile.png")}
                                                        srcSet={`${require("assets/img-company-success-2-mobile@2x.png")} 2x, ${require("assets/img-company-success-2-mobile@3x.png")} 3x`}
                                                        className={
                                                            classes.successImgBottom
                                                        }
                                                        alt="성과 이미지"
                                                    ></img>
                                                </Box>
                                            </>
                                        ) : (
                                            <img
                                                src={require("assets/img-company-success.png")}
                                                srcSet={`${require("assets/img-company-success@2x.png")} 2x, ${require("assets/img-company-success@3x.png")} 3x`}
                                                className={classes.successImg}
                                                alt="성과 이미지"
                                            ></img>
                                        )}

                                        <Typography className={classes.quote}>
                                            삼쩜삼은 세무 영역에서
                                            <br />
                                            새로운 역사를 쓰고 있습니다.
                                        </Typography>
                                        <Box className={classes.historyBox}>
                                            <Typography
                                                className={classes.historyTitle}
                                            >
                                                기업 연혁
                                            </Typography>
                                            {openScoutData.history.map(
                                                (item, idx) => (
                                                    <Box key={idx}>
                                                        <Typography
                                                            className={
                                                                classes.historyYear
                                                            }
                                                        >
                                                            {item.year}
                                                        </Typography>
                                                        <Divider></Divider>
                                                        <br />
                                                        {item.months.map(
                                                            (item, idx) => (
                                                                <Box
                                                                    key={idx}
                                                                    className={
                                                                        classes.historyItem
                                                                    }
                                                                >
                                                                    <Typography
                                                                        className={
                                                                            classes.historyMonth
                                                                        }
                                                                    >
                                                                        {
                                                                            item.month
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        className={
                                                                            classes.historyContent
                                                                        }
                                                                    >
                                                                        {
                                                                            item.content
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                )
                                            )}
                                            <br />
                                        </Box>
                                        <Typography
                                            className={classes.menuTitle}
                                        >
                                            모집 분야
                                            <Box
                                                ref={menuRefs["모집 분야"]}
                                                className={classes.scrollCap}
                                            ></Box>
                                        </Typography>
                                        <Typography className={classes.quote}>
                                            오늘도 로켓 성장 중인
                                            <br />
                                            삼쩜삼 개발팀에 합류하세요
                                        </Typography>

                                        <Paper
                                            elevation={0}
                                            className={classes.scoutInfoWrap}
                                        >
                                            <Tabs
                                                value={part}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={handleChangePart}
                                                aria-label="disabled tabs example"
                                            >
                                                <Tab label="백엔드 부문"></Tab>
                                            </Tabs>
                                            <TabPanel value={part} index={0}>
                                                <Typography
                                                    className={
                                                        classes.scoutPositionTitle
                                                    }
                                                >
                                                    Java Backend Engineer
                                                </Typography>
                                                <Box
                                                    className={
                                                        classes.requiredHardskillWrap
                                                    }
                                                >
                                                    {[
                                                        "Java",
                                                        "Spring Boot",
                                                        "Spring Framework",
                                                        "JPA",
                                                        "Querydsl",
                                                        "Redis",
                                                        "MySQL",
                                                        "AWS",
                                                        "JUnit",
                                                        "Docker",
                                                        "Jenkins",
                                                        "Git",
                                                        "Github",
                                                    ].map((hardskill, idx) => (
                                                        <Box key={idx}>
                                                            <Box
                                                                //aria-describedby={popId}
                                                                className={
                                                                    classes.devStyleBoxWithoutDesc
                                                                }
                                                            >
                                                                {hardskill}
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Box
                                                    className={
                                                        classes.requirementBox
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.requirementCategoryLabel
                                                        }
                                                    >
                                                        모집 대상
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.requirementContent
                                                        }
                                                    >
                                                        • 개발 3년차 이상 경력 /
                                                        실력 보유자
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.requirementCategoryLabel
                                                        }
                                                    >
                                                        이런 분을 찾고 있어요
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.requirementContent
                                                        }
                                                    >
                                                        • Java, Spring
                                                        Framework을 이용해
                                                        개발하신 경험이
                                                        필요합니다.
                                                        <br />• AWS 등의
                                                        클라우드 기반 서비스를
                                                        개발하고 운영하신 분을
                                                        찾습니다.
                                                        <br />• 웹 서비스의
                                                        설계/개발/성능 최적화를
                                                        경험해보신 분을
                                                        찾습니다.
                                                        <br />• 빌드, 테스트,
                                                        배포 자동화 경험이
                                                        있으신 분을 찾습니다.
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.requirementCategoryLabel
                                                        }
                                                    >
                                                        이런 일들을 하게됩니다
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.requirementContent
                                                        }
                                                    >
                                                        • 삼쩜삼의 Server API를
                                                        개발합니다.
                                                        <br />• 수많은 고객의
                                                        결과를 처리할 수 있는
                                                        데이터 모델링 및
                                                        백오피스 시스템을
                                                        개발합니다.
                                                        <br />• 대용량 트래픽을
                                                        빠르고 안정적으로
                                                        처리하는 인프라를
                                                        구성하고 운영합니다.
                                                        <br />• 프론트 엔지니어,
                                                        데이터 분석가와 협업하며
                                                        서비스 사용성을
                                                        개선합니다.
                                                        <br />• 정기적인
                                                        모니터링을 통해 최적화가
                                                        가능한 지점을 찾고
                                                        개선합니다.
                                                    </Typography>
                                                </Box>
                                            </TabPanel>
                                        </Paper>

                                        <Typography className={classes.company}>
                                            {company.name} 개발팀 정보
                                            <Box
                                                ref={menuRefs["개발팀 소개"]}
                                                className={classes.scrollCap}
                                            ></Box>
                                        </Typography>

                                        {/* <Typography
                                            className={
                                                classes.devTeamIntroduction
                                            }
                                        >
                                            자비스와 삼쩜삼의 성공을 시작으로,
                                            우리는 데이터 기반의 핀테크
                                            기업으로서 세무·회계·금융시장을
                                            리딩합니다. 우리가 꿈꾸는 모든 일은
                                            데이터와 개발에 기반하여 이루어질
                                            것입니다. 이것이 우리가 유능한 개발
                                            인재를 적극적으로 채용하는
                                            이유입니다.
                                        </Typography> */}
                                    </>
                                )}

                                <Typography className={classes.devTeamIntro}>
                                    {company.devTeam.description}
                                </Typography>
                                <Box className={classes.devTeamWrap}>
                                    <Box
                                        className={classes.devTeamStructureWrap}
                                    >
                                        <Box
                                            className={classes.devTeamHeadWrap}
                                        >
                                            <img
                                                src={require("assets/icon-devteam-people.png")}
                                                srcSet={`${require("assets/icon-devteam-people@2x.png")} 2x, ${require("assets/icon-devteam-people@3x.png")} 3x`}
                                                className={classes.devTeamImg}
                                                alt="arrow"
                                            ></img>
                                            <Typography
                                                className={
                                                    classes.devTeamStructure
                                                }
                                            >
                                                개발팀 구성{" "}
                                                {company.devTeam?.total &&
                                                    "(총 " +
                                                        company.devTeam?.total +
                                                        "명)"}
                                            </Typography>
                                        </Box>
                                        {company.devTeam.positions?.map(
                                            (position, idx) => {
                                                if (position.total)
                                                    return (
                                                        <Box
                                                            key={idx}
                                                            className={
                                                                classes.devTeamPositionWrap
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.devTeamPositionName
                                                                }
                                                            >
                                                                {position.name}
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.devTeamPositionCount
                                                                }
                                                            >
                                                                총{" "}
                                                                {position.total}
                                                                명
                                                            </Typography>
                                                            <Box
                                                                className={
                                                                    classes.devTeamPositionYearsBar
                                                                }
                                                            >
                                                                {!!position.rookie && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.rookie,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#c7e0ff",
                                                                            color: "#ffffff",
                                                                        }}
                                                                    >
                                                                        {
                                                                            position.rookie
                                                                        }
                                                                    </Box>
                                                                )}
                                                                {!!position.junior && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.junior,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#4693ff",
                                                                            color: "#ffffff",
                                                                        }}
                                                                    >
                                                                        {
                                                                            position.junior
                                                                        }
                                                                    </Box>
                                                                )}
                                                                {!!position.senior && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.senior,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#0c46f2",
                                                                            color: "#ffffff",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {
                                                                            position.senior
                                                                        }
                                                                    </Box>
                                                                )}
                                                                {!!position.leader && (
                                                                    <Box
                                                                        style={{
                                                                            flex: position.leader,
                                                                            height: "100%",
                                                                            backgroundColor:
                                                                                "#051c60",
                                                                        }}
                                                                    >
                                                                        {
                                                                            position.leader
                                                                        }
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    );
                                            }
                                        )}
                                        <Box className={classes.legendWrap}>
                                            <Box className={classes.updated}>
                                                {new Date(
                                                    company.updatedAt
                                                ).toLocaleDateString(
                                                    "ko-KR"
                                                )}{" "}
                                                기준
                                            </Box>
                                            <Box
                                                className={
                                                    classes.experienceRangesWrap
                                                }
                                            >
                                                <Box
                                                    className={
                                                        classes.experienceRange
                                                    }
                                                >
                                                    <Box
                                                        style={{
                                                            width: 8,
                                                            height: 8,
                                                            backgroundColor:
                                                                "#c7e0ff",
                                                            marginRight: 4,
                                                        }}
                                                    ></Box>
                                                    신입 (1년 미만)
                                                </Box>
                                                <Box
                                                    className={
                                                        classes.experienceRange
                                                    }
                                                >
                                                    <Box
                                                        style={{
                                                            width: 8,
                                                            height: 8,
                                                            backgroundColor:
                                                                "#4693ff",
                                                            marginRight: 4,
                                                        }}
                                                    ></Box>
                                                    주니어 (1~4년)
                                                </Box>
                                                <Box
                                                    className={
                                                        classes.experienceRange
                                                    }
                                                >
                                                    <Box
                                                        style={{
                                                            width: 8,
                                                            height: 8,
                                                            backgroundColor:
                                                                "#0c46f2",
                                                            marginRight: 4,
                                                        }}
                                                    ></Box>
                                                    시니어 (5~8년)
                                                </Box>
                                                <Box
                                                    className={
                                                        classes.experienceRange
                                                    }
                                                >
                                                    <Box
                                                        style={{
                                                            width: 8,
                                                            height: 8,
                                                            backgroundColor:
                                                                "#051c60",
                                                            marginRight: 4,
                                                        }}
                                                    ></Box>
                                                    리더 (9년 이상)
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider></Divider>
                                    <Box className={classes.hardSkillStyleWrap}>
                                        <Typography
                                            className={classes.skillStack}
                                        >
                                            기술 스택
                                        </Typography>
                                        <Box className={classes.hardSkillsWrap}>
                                            {company.hardSkills
                                                ?.filter(
                                                    (obj, idx, self) =>
                                                        idx ===
                                                        self.findIndex(
                                                            (t) =>
                                                                t.name ===
                                                                obj.name
                                                        )
                                                )
                                                .map((hardSkill, idx) => (
                                                    <Box
                                                        key={idx}
                                                        className={
                                                            classes.hardSkillBox
                                                        }
                                                    >
                                                        {hardSkill.url && (
                                                            <img
                                                                className={
                                                                    classes.hardSkillIcon
                                                                }
                                                                alt="skill"
                                                            ></img>
                                                        )}
                                                        <Typography
                                                            className={
                                                                classes.hardSkill
                                                            }
                                                        >
                                                            {hardSkill.name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                        </Box>
                                        <Typography
                                            className={classes.devStyleTitle}
                                        >
                                            개발 방식
                                        </Typography>
                                        <Box className={classes.devStyleWrap}>
                                            {company.devStyles?.map(
                                                (devStyle, idx) => (
                                                    <Box key={idx}>
                                                        <Box
                                                            //aria-describedby={popId}
                                                            className={
                                                                devStyle.description
                                                                    ? classes.devStyleBox
                                                                    : classes.devStyleBoxWithoutDesc
                                                            }
                                                            onClick={
                                                                devStyle.description
                                                                    ? handleClick
                                                                    : undefined
                                                            }
                                                        >
                                                            {devStyle.name}
                                                        </Box>
                                                    </Box>
                                                )
                                            )}
                                            <Popover
                                                id={popId}
                                                className={classes.popover}
                                                classes={{
                                                    paper: classes.popoverPaper,
                                                }}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "left",
                                                }}
                                            >
                                                <Typography
                                                    className={
                                                        classes.devStyleDesc
                                                    }
                                                >
                                                    {devStyleDesc}
                                                </Typography>
                                            </Popover>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.companyInfoWrap}>
                                        <Box className={classes.companyInfoBox}>
                                            <Typography
                                                className={
                                                    classes.companyInfoTitle
                                                }
                                            >
                                                산업
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.companyInfoContent
                                                }
                                            >
                                                {company.category}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.companyInfoBox}>
                                            <Typography
                                                className={
                                                    classes.companyInfoTitle
                                                }
                                            >
                                                사원수
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.companyInfoContent
                                                }
                                            >
                                                {company.numOfEmployees}명
                                            </Typography>
                                        </Box>
                                        <Box className={classes.companyInfoBox}>
                                            <Typography
                                                className={
                                                    classes.companyInfoTitle
                                                }
                                            >
                                                설립연도
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.companyInfoContent
                                                }
                                            >
                                                {new Date(
                                                    company.since
                                                ).getUTCFullYear()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Typography className={classes.menuTitle}>
                                    일정 안내
                                    <Box
                                        ref={menuRefs["일정 안내"]}
                                        className={classes.scrollCap}
                                    ></Box>
                                </Typography>
                                <Box className={classes.timeTable}>
                                    <Box className={classes.timeTableRow}>
                                        {!isMobile && (
                                            <Box
                                                className={
                                                    classes.timeTableLeftCol
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    9월 6일(월) ~ 22일(수)
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            className={
                                                classes.timeTableRightCol
                                            }
                                        >
                                            {isMobile && (
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    9월 6일(월) ~ 22일(수)
                                                </Typography>
                                            )}
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                1. 지원하기
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                2. 탤런트엑스 앱 설치 및
                                                회원가입
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                3. 탤런트엑스 앱 프로필 작성
                                            </Typography>
                                            <Box
                                                className={
                                                    classes.timeTableRightColNoticeBox
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableRightColNoticeTitle
                                                    }
                                                >
                                                    Q. 기존 탤런트엑스 가입자는
                                                    어떻게 진행하나요?
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.timeTableRightColNotice
                                                    }
                                                >
                                                    기존 탤런트엑스 가입자는 본
                                                    프로그램 지원만 완료해주시면
                                                    됩니다. 다만, 지원에 활용한
                                                    이메일이 보유한 계정
                                                    이메일과 동일해야 하며
                                                    앱에서 내 프로필 작성이
                                                    완료되어 있어야 합니다.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.timeTableRow}>
                                        {!isMobile && (
                                            <Box
                                                className={
                                                    classes.timeTableLeftCol
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    9월 23일(목) ~ 30일(목)
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            className={
                                                classes.timeTableRightCol
                                            }
                                        >
                                            {isMobile && (
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    9월 23일(목) ~ 30일(목)
                                                </Typography>
                                            )}
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                과제 테스트 진행
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                • 과제 테스트는 지정 기간동안
                                                개인별로 자유롭게 진행 후 별도
                                                양식으로 제출
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                • 사용언어 :
                                                Java, Spring Boot, JPA, H2, Gradle
                                            </Typography>
                                            <Box
                                                className={
                                                    classes.timeTableRightColNoticeBox
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableRightColNoticeTitle
                                                    }
                                                >
                                                    Q. 왜 코딩 테스트가 아닌
                                                    과제 테스트로 진행하나요?
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.timeTableRightColNotice
                                                    }
                                                >
                                                    알고리즘이나 경력 연차가
                                                    아닌 실무역량으로 열린
                                                    채용(오픈스카우트)을 하기
                                                    위함입니다.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.timeTableRow}>
                                        {!isMobile && (
                                            <Box
                                                className={
                                                    classes.timeTableLeftCol
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    10월 11일(월)
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            className={
                                                classes.timeTableRightCol
                                            }
                                        >
                                            {isMobile && (
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    10월 11일(월)
                                                </Typography>
                                            )}
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                과제 테스트 합격자 개별 후속전형
                                                안내
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Typography className={classes.drawAnnoucement}>
                                    *이벤트 당첨자 발표 및 상품 증정 : 10월
                                    11일(월)
                                </Typography>
                                <Box className={classes.kakaoOpenChatTitleBox}>
                                    <Typography
                                        className={classes.kakaoOpenChatTitle}
                                    >
                                        💬 LIVE Q&A 채팅
                                    </Typography>
                                    {!isMobile && (
                                        <Box
                                            className={classes.kakaoOpenChatBtn}
                                            onClick={() =>
                                                openLinkNewTab(
                                                    "https://open.kakao.com/o/gr3vXt4c"
                                                )
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.kakaoOpenChatLabel
                                                }
                                            >
                                                채팅방 참여 (입장코드: talentx)
                                            </Typography>
                                            <img
                                                src={require("assets/icon-arrow-forward-r-2-px-24-dp.png")}
                                                srcSet={`${require("assets/icon-arrow-forward-r-2-px-24-dp@2x.png")} 2x, ${require("assets/icon-arrow-forward-r-2-px-24-dp@3x.png")} 3x`}
                                            ></img>
                                        </Box>
                                    )}
                                </Box>
                                <Box className={classes.timeTable}>
                                    <Box className={classes.timeTableRow}>
                                        {!isMobile && (
                                            <Box
                                                className={
                                                    classes.timeTableLeftCol
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    프로그램 기간 내 상시
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            className={
                                                classes.timeTableRightCol
                                            }
                                        >
                                            {isMobile && (
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    프로그램 기간 내 상시
                                                </Typography>
                                            )}
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                인사 담당자에게 무엇이든
                                                물어보세요!
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.timeTableRow}>
                                        {!isMobile && (
                                            <Box
                                                className={
                                                    classes.timeTableLeftCol
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    9월 15일(수) 20:00 ~ 21:00
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            className={
                                                classes.timeTableRightCol
                                            }
                                        >
                                            {isMobile && (
                                                <Typography
                                                    className={
                                                        classes.timeTableLeftColText
                                                    }
                                                >
                                                    9월 15일(수) 20:00 ~ 21:00
                                                </Typography>
                                            )}
                                            <Typography
                                                className={
                                                    classes.timeTableRightColText
                                                }
                                            >
                                                삼쩜삼 개발팀(CTO 포함)과의
                                                실시간 채팅
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {isMobile && (
                                    <Box
                                        className={classes.kakaoOpenChatBtn}
                                        onClick={() =>
                                            openLinkNewTab(
                                                "https://open.kakao.com/o/gr3vXt4c"
                                            )
                                        }
                                    >
                                        <Typography
                                            className={
                                                classes.kakaoOpenChatLabel
                                            }
                                        >
                                            채팅방 참여 (입장코드: talentx)
                                        </Typography>
                                        <img
                                            src={require("assets/icon-arrow-forward-r-2-px-24-dp.png")}
                                            srcSet={`${require("assets/icon-arrow-forward-r-2-px-24-dp@2x.png")} 2x, ${require("assets/icon-arrow-forward-r-2-px-24-dp@3x.png")} 3x`}
                                        ></img>
                                    </Box>
                                )}
                                <Typography className={classes.bigTitle}>
                                    유의사항
                                </Typography>
                                <Typography className={classes.noticeTitle}>
                                    이벤트 관련
                                </Typography>
                                {openScoutData.notices.map((notice, idx) => (
                                    <Typography
                                        key={idx}
                                        className={classes.noticeItem}
                                    >
                                        {notice}
                                    </Typography>
                                ))}
                                <Typography
                                    className={classes.extraNoticeTitle}
                                >
                                    참여 관련
                                </Typography>
                                {openScoutData.extraNotices.map(
                                    (notice, idx) => (
                                        <Typography
                                            key={idx}
                                            className={classes.noticeItem}
                                        >
                                            {notice}
                                        </Typography>
                                    )
                                )}

                                <Typography className={classes.faqTitle}>
                                    자주 묻는 질문
                                    <Box
                                        ref={menuRefs["자주 묻는 질문"]}
                                        className={classes.scrollCap}
                                    ></Box>
                                </Typography>
                                <Box className={classes.faqNoticeBox}>
                                    <img
                                        src={require("assets/icon-error-filled-24-dp.png")}
                                        srcSet={`${require("assets/icon-error-filled-24-dp@2x.png")} 2x, ${require("assets/icon-error-filled-24-dp@3x.png")} 3x`}
                                    ></img>
                                    <Typography className={classes.faqNotice}>
                                        궁금증이 해결되지 않으셨나요? 그렇다면
                                        탤런트엑스{" "}
                                        <Link
                                            href="https://talentx.channel.io/"
                                            target="_blank"
                                        >
                                            채널톡
                                        </Link>
                                        에 문의해주세요.
                                    </Typography>
                                </Box>
                                {openScoutData.faqs.map((faq, idx) => (
                                    <Box key={idx}>
                                        <Typography
                                            className={classes.questionItem}
                                        >
                                            {faq.question}
                                        </Typography>
                                        <Typography
                                            key={idx}
                                            className={classes.answerItem}
                                        >
                                            {faq.answer}
                                        </Typography>
                                    </Box>
                                ))}
                            </>
                        ) : (
                            <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={1000}
                                animation="wave"
                            />
                        )}
                    </Box>
                    {!isMobile && (
                        <Box className={classes.rightSection}>
                            {company ? (
                                <>
                                    <Box className={classes.actionCard}>
                                        <Box
                                            className={classes.actionCardInner}
                                        >
                                            <Typography
                                                className={
                                                    classes.openScoutSeason
                                                }
                                            >
                                                탤런트엑스 오픈스카우트 시즌1.
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.openScoutEpisode
                                                }
                                            >
                                                삼쩜삼 백엔드 엔지니어 편
                                            </Typography>
                                            <Box
                                                className={
                                                    classes.openScoutStep
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.openScoutStepContent
                                                    }
                                                >
                                                    지원 접수
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.openScoutStepContent
                                                    }
                                                >
                                                    09월 06일(월) ~ 22일(수)
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <Box
                                                className={
                                                    classes.openScoutStep
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.openScoutStepContent
                                                    }
                                                >
                                                    과제 테스트
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.openScoutStepContent
                                                    }
                                                >
                                                    09월 23일(목) ~ 30일(목)
                                                </Typography>
                                            </Box>

                                            <Box
                                                className={
                                                    classes.openScoutTimerBox
                                                }
                                            >
                                                <img
                                                    src={require("assets/icon-timer.png")}
                                                    srcSet={`${require("assets/icon-timer@2x.png")} 2x, ${require("assets/icon-timer@3x.png")} 3x`}
                                                ></img>
                                                <Typography
                                                    className={
                                                        classes.openScoutStepTimerLabel
                                                    }
                                                >
                                                    {!isClose
                                                        ? "지원 마감까지"
                                                        : "지원이 마감되었습니다."}
                                                </Typography>

                                                <Box
                                                    className={
                                                        classes.openScoutStepTimer
                                                    }
                                                >
                                                    {secondsToDeadLine}
                                                </Box>
                                            </Box>
                                            <Typography
                                                className={
                                                    classes.applicationTitle
                                                }
                                            >
                                                지원서 작성
                                            </Typography>
                                            <form
                                                className={
                                                    classes.applicationFormD
                                                }
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField
                                                    disabled={isClose}
                                                    required
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}
                                                    id="standard-basic"
                                                    variant="outlined"
                                                    placeholder="이름"
                                                    //label="이름"
                                                    value={name}
                                                    onChange={handleNameChange}
                                                    //helperText="한글로 정확히 입력해 주세요."
                                                />

                                                <TextField
                                                    disabled={isClose}
                                                    required
                                                    id="standard-basic"
                                                    variant="outlined"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}
                                                    placeholder="이메일을 입력해주세요"
                                                    //label="이메일"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    //helperText="해커랭크와 탤런트엑스 이용시 활용됩니다."
                                                />

                                                <TextField
                                                    disabled={isClose}
                                                    required
                                                    id="standard-basic"
                                                    variant="outlined"
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}
                                                    placeholder="휴대폰번호를 입력해주세요.(-없이 11자리)"
                                                    //label="휴대폰번호"
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    //helperText="'-' 없이 번호만 입력해 주세요."
                                                />
                                                <Box
                                                    className={
                                                        classes.inputFieldsBox
                                                    }
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        className={
                                                            classes.leftInputFieldBox
                                                        }
                                                    >
                                                        <Select
                                                            disabled={isClose}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            displayEmpty
                                                            value={
                                                                yearsOfExperience
                                                            }
                                                            onChange={
                                                                handleYearsOfExperienceChange
                                                            }
                                                            classes={{
                                                                root: classes.resize,
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                classes={{
                                                                    root: classes.resize,
                                                                }}
                                                                disabled
                                                            >
                                                                개발 경력
                                                            </MenuItem>
                                                            {[
                                                                1, 2, 3, 4, 5,
                                                                6, 7, 8, 9,
                                                            ].map((idx) => (
                                                                <MenuItem
                                                                    key={idx}
                                                                    value={idx}
                                                                    classes={{
                                                                        root: classes.resize,
                                                                    }}
                                                                >
                                                                    {idx
                                                                        ? idx +
                                                                          "년"
                                                                        : "신입"}
                                                                </MenuItem>
                                                            ))}
                                                            <MenuItem
                                                                key={10}
                                                                value={10}
                                                                classes={{
                                                                    root: classes.resize,
                                                                }}
                                                            >
                                                                10년 이상
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}

                                                        //locale={koLocale}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled={isClose}
                                                            className={
                                                                classes.rightInputFieldBox
                                                            }
                                                            required
                                                            //disableToolbar
                                                            initialFocusedDate={
                                                                new Date(
                                                                    "1990-01-01T00:00:00Z"
                                                                )
                                                            }
                                                            placeholder="생년월일"
                                                            openTo="year"
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.resize,
                                                                },
                                                            }}
                                                            //type="date"
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            format="yyyy/MM/dd"
                                                            invalidDateMessage={
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    YYYY/MM/DD
                                                                    형식으로
                                                                    입력해
                                                                    주세요!
                                                                </Typography>
                                                            }
                                                            margin="normal"
                                                            id="date-picker-inline"
                                                            maxDate={new Date()}
                                                            //label="생년월일"
                                                            value={birth}
                                                            onChange={
                                                                handleBirthChange
                                                            }
                                                            KeyboardButtonProps={{
                                                                "aria-label":
                                                                    "change date",
                                                            }}
                                                            autoOk={true}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Box>

                                                {/* <TextField
                                                    disabled
                                                    required
                                                    id="standard-basic"
                                                    variant="outlined"
                                                    style={{ marginBottom: 8 }}
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}
                                                    placeholder="지원 직무를 선택해 주세요"
                                                    value={position}
                                                    onChange={
                                                        handlePositionChange
                                                    }
                                                /> */}
                                            </form>
                                        </Box>
                                        <Box
                                            className={
                                                submittable
                                                    ? classes.applyBtn
                                                    : classes.applyBtnDisabled
                                            }
                                            onClick={handleApply}
                                        >
                                            지원하기
                                        </Box>
                                        <Box
                                            className={classes.promotionTooltip}
                                        >
                                            <Box
                                                className={
                                                    classes.promotionTooltipArrow
                                                }
                                            ></Box>
                                            <Box
                                                className={
                                                    classes.promotionTooltipBody
                                                }
                                            >
                                                탤런트엑스로 지원하고 303만원
                                                <Box
                                                    className={
                                                        classes.promotionTooltipIconBox
                                                    }
                                                >
                                                    <img
                                                        src={require("assets/icon-nav-add-16.png")}
                                                        srcSet={`${require("assets/icon-nav-add-16@2x.png")} 2x, ${require("assets/icon-nav-add-16@3x.png")} 3x`}
                                                        className={
                                                            classes.promotionTooltipIcon
                                                        }
                                                        alt="303만 원"
                                                    ></img>
                                                </Box>
                                                애플워치 받자!
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <Skeleton
                                    variant="rect"
                                    animation="wave"
                                    height={1000}
                                    style={{ marginLeft: 20, marginRight: 20 }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Container>

            {isMobile ? (
                <>
                    <Box style={{ height: 100 }}></Box>
                    <Box
                        className={classes.actionBoxFixed}
                        onClick={handleOnboard}
                    >
                        <Box className={classes.promotionPlaCardFixed}>
                            <Typography
                                className={classes.promotionPlaCardFixedTitle}
                            >
                                탤런트엑스로 지원하고 303만원
                            </Typography>
                            <Box className={classes.promotionTooltipIconBox}>
                                <img
                                    src={require("assets/icon-nav-add-16.png")}
                                    srcSet={`${require("assets/icon-nav-add-16@2x.png")} 2x, ${require("assets/icon-nav-add-16@3x.png")} 3x`}
                                    className={classes.promotionTooltipIcon}
                                ></img>
                            </Box>
                            <Typography
                                className={classes.promotionPlaCardFixedTitle}
                            >
                                애플워치 받자!
                            </Typography>
                        </Box>
                        {!isOnboarding && (
                            <Box className={classes.applyBtnFixed}>
                                <Typography
                                    className={classes.applyBtnFixedTitle}
                                >
                                    10초 안에 지원하기
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </>
            ) : (
                <Box className={classes.openScoutFooter}>
                    <Typography className={classes.openScoutFooterTitle}>
                        Copyright © talentX Corporation. All rights reserved.
                    </Typography>
                </Box>
            )}

            <Backdrop className={classes.backdrop} open={isApplying}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                open={summited}
                onClose={handleCancle}
                className={classes.modalScreen}
            >
                <Box className={classes.modalBox}>
                    <Box className={classes.modalBoxTop}>
                        <Typography className={classes.modalTitle}>
                            오픈스카우트 시즌1 지원완료
                        </Typography>
                        <Typography className={classes.modalMessage}>
                            삼쩜쌈 백엔드 엔지니어 편에
                            <br />
                            지원해주셔서 감사드립니다.
                            <br />
                            확인 후 안내 드리겠습니다.
                        </Typography>
                        <Typography className={classes.modalInfo}>
                            지원 조건 심사는 영업일 기준 1일 소요
                        </Typography>
                    </Box>
                    <Divider />
                    <Box className={classes.modalBoxMiddle}>
                        <img
                            className={classes.modalIcon}
                            src={require("assets/img-chatting.png")}
                            srcSet={`${require("assets/img-chatting@2x.png")} 2x, ${require("assets/img-chatting@3x.png")} 3x`}
                        ></img>
                        <Typography className={classes.modalQuestion}>
                            삼쩜삼 팀과의 LIVE Q&A <br /> 채팅 참여를
                            희망하시나요?
                        </Typography>
                        <Link
                            className={classes.modalLink}
                            href="https://open.kakao.com/o/gr3vXt4c"
                            target="_blank"
                        >
                            탤런트엑스 채팅방 참여하기
                        </Link>
                    </Box>
                    <Divider />
                    <Box className={classes.modalBoxBottom}>
                        <Button
                            onClick={handleOk}
                            variant="contained"
                            color="default"
                            className={classes.modalButton}
                        >
                            확인
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {/* <Modal
                open={false}
                onClose={() => setIsOnboarding(false)}
                className={classes.modalBottomUpScreen}
            >
                <Box className={classes.modalBottomUp}>
                    <Box className={classes.modalBoxTop}>
                        <Typography className={classes.applicationTitle}>
                            지원서 작성
                        </Typography>
                        <form
                            className={classes.applicationForm}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                required
                                inputProps={{
                                    maxLength: 10,
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                id="standard-basic"
                                variant="outlined"
                                placeholder="이름"
                                value={name}
                                onChange={handleNameChange}
                            />

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd"
                                    invalidDateMessage={
                                        <Typography
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            YYYY/MM/DD 형식으로 입력해 주세요!
                                        </Typography>
                                    }
                                    margin="normal"
                                    id="date-picker-inline"
                                    maxDate={new Date()}
                                    value={birth}
                                    onChange={handleBirthChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>

                            <TextField
                                required
                                id="standard-basic"
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                placeholder="이메일을 입력해주세요"
                                //label="이메일"
                                value={email}
                                onChange={handleEmailChange}
                                //helperText="해커랭크와 탤런트엑스 이용시 활용됩니다."
                            />

                            <TextField
                                required
                                id="standard-basic"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                inputProps={{
                                    maxLength: 11,
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                placeholder="휴대폰번호를 입력해주세요.(-없이 11자리)"
                                //label="휴대폰번호"
                                value={phone}
                                onChange={handlePhoneChange}
                                //helperText="'-' 없이 번호만 입력해 주세요."
                            />

                            <FormControl
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                            >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    displayEmpty
                                    value={yearsOfExperience}
                                    onChange={handleYearsOfExperienceChange}
                                    classes={{
                                        root: classes.resize,
                                    }}
                                >
                                    <MenuItem
                                        value=""
                                        classes={{
                                            root: classes.resize,
                                        }}
                                        disabled
                                    >
                                        개발 경력
                                    </MenuItem>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={idx}
                                            classes={{
                                                root: classes.resize,
                                            }}
                                        >
                                            {idx ? idx + "년" : "신입"}
                                        </MenuItem>
                                    ))}
                                    <MenuItem
                                        key={10}
                                        value={10}
                                        classes={{
                                            root: classes.resize,
                                        }}
                                    >
                                        10년 이상
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </form>
                        <Box
                            className={
                                submittable
                                    ? classes.applyBtn
                                    : classes.applyBtnDisabled
                            }
                            onClick={handleApply}
                        >
                            제출하기
                        </Box>
                    </Box>
                </Box>
            </Modal> */}
        </React.Fragment>
    );
};

export default OpenScout;
