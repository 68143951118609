import React from "react";
import { Typography, Container } from "@material-ui/core";
import { ServiceHeaderD, ServiceFooterD } from "pages/components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 56,
        paddingBottom: 32,
    },
    title: {
        marginTop: 100,
        marginBottom: 64,
        fontSize: 32,
        lineHeight: 1.19,
        fontWeight: "bold",
        textAlign: "center",
    },
    content: {
        fontSize: 20,
        lineHeight: 1.5,
        marginTop: 32,
        marginBottom: 64,
    },
    date: {
        fontSize: 16,
        lineHeight: 1.19,
        marginTop: 32,
        marginBottom: 64,
        textAlign: "center",
    },
    party: {
        fontSize: 16,
        lineHeight: 1.5,
        marginTop: 32,
        marginBottom: 32,
    },
}));

const Announcement = () => {
    const classes = useStyles();

    return (
        <>
            <ServiceHeaderD />
            <Container className={classes.container}>
                <Typography className={classes.title}>
                    합병 및 주권제출공고
                </Typography>
                <Typography className={classes.content}>
                    주식회사 탤런티넘(갑)과 주식회사 탤런트엑스(을)는 각기
                    2022년 1월 26일 개최한 임시주주총회에서 합병을 결의하고 그
                    결과로 (갑)은 (을)을 합병하여 그 권리의무 일체를 승계하고
                    (갑)은 존속 하며 (을)은 해산하기로 결의하였으므로 본 합병에
                    대하여 이의가 있는 분이나 채권을 가지고 계신분께서는 본 공고
                    게재 익일부터 1개월 이내에 관계회사에 이의나 그 채권액을
                    제출하시고 주권을 가지고 있는 분은 관계회사에 주권을
                    제출하여 주시기 바라와 이 에 공고합니다.
                </Typography>
                <Typography className={classes.date}>
                    2022년 1월 28일
                </Typography>
                <Typography className={classes.party}>
                    (갑) 주식회사 탤런티넘
                    <br />
                    경기도 성남시 분당구 느티로 22, 비동 2504호
                    (정자동,백궁동양파라곤) <br />
                    사내이사 한신환
                </Typography>
                <Typography className={classes.party}>
                    (을) 주식회사 탤런트엑스
                    <br />
                    경기도 성남시 분당구 느티로 22, 비동 2504호
                    (정자동,백궁동양파라곤)
                    <br />
                    사내이사 한신환
                </Typography>
            </Container>

            <ServiceFooterD backgroudColor={"#2c2c2c"} />
        </>
    );
};

export default Announcement;
