import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
	AppBar,
	Toolbar,
	Box,
	IconButton,
	Badge,
	Typography,
	Container,
	List,
	ListItem,
	ListItemText,
	Divider,
	Paper,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import logo from "assets/talentx_logo_black@3x.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";

import { isAndroid, isWindows } from "react-device-detect";

import { Colors } from "../../styles";

const useStyles = makeStyles((props) => ({
	container: {
		position: "relative",
	},
	appBar: (props) => ({
		backgroundColor: props.theme.palette.background.paper,
		height: 56,
		maxWidth: props.maxWidth ? undefined : 444,
		left: "50%",
		transform: "translate(-50%, 0)",
	}),
	toolBar: {
		minHeight: 56,
	},
	identity: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		flexGrow: 1,
	},
	logo: {
		width: 70,
		height: 20,
		marginLeft: 20,
	},
	menuButton: {
		marginRight: 4,
	},
	badge: {
		backgroundColor: "#ff1717",
	},
	primaryMenuLink: {
		textDecoration: "none",
		color: "primary",
	},
	menuLink: {
		textDecoration: "none",
		color: "#111111",
		fontSize: 14,
		fontWeight: 400,
	},
	listItemText: {
		textAlign: "center",
		fontSize: 14,
		fontWeight: 400,
	},
	menuLinkForOthers: {
		textDecoration: "none",
		color: "gray",
	},
	stickyBannerForCompany: {
		top: 55,
		position: "fixed",
		maxWidth: props.maxWidth ? undefined : 444,
		width: "100%",
		height: 40,
		zIndex: 1,
		flexGrow: 1,
		backgroundColor: "#dee4fb",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		color: Colors.Gray600,
		cursor: "pointer",
	},
}));

const CompanyServiceHeader = ({ userType, maxWidth }) => {
	const history = useHistory();

	const theme = useTheme();
	// const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	// console.log("isMobile :", isMobile);

	const classes = useStyles({ theme, maxWidth });

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<React.Fragment>
			<Container
				maxWidth={maxWidth || "xs"}
				disableGutters
				className={classes.container}
			>
				<AppBar
					position="fixed"
					className={classes.appBar}
					elevation={0}
				>
					<Toolbar classes={{ root: classes.toolBar }} disableGutters>
						<RouterLink to="/company" className={classes.identity}>
							<img
								src={logo}
								className={classes.logo}
								alt="talentX for Company"
							/>
						</RouterLink>
						<IconButton
							edge="start"
							className={classes.menuButton}
							aria-label="menu"
							onClick={() => setMenuOpen(!menuOpen)}
						>
							<Badge
								badgeContent=" "
								overlap="circle"
								variant="dot"
								invisible={menuOpen}
								classes={{ dot: classes.badge }}
							>
								{!menuOpen ? <MenuIcon /> : <CloseIcon />}
							</Badge>
						</IconButton>
					</Toolbar>
					{menuOpen && (
						<Box
							style={{
								position: "absolute",
								top: 55,
								width: "100%",
								backgroundColor:
									theme.palette.background.default,
								color: "black",
							}}
						>
							<List component="nav">
								{[
									// {
									//     route: true,
									//     title: "탤런트엑스",
									//     url: "/",
									// },
									//{
									//	route: true,
									//	title: "데브스타 스카우트",
									//	url: "/devstarx",
									//	primary: true,
									//},
									{
										route: true,
										title: "기업 멤버십",
										url: "/pricing",
									},
									{
										route: false,
										title: "문의하기",
										url: "https://talentx.channel.io/",
									},
									//{
									//	route: false,
									//	title: "앱 설치",
									//	url:
									//		isAndroid || isWindows
									//			? "https://play.google.com/store/apps/details?id=com.talentinum.talentx"
									//			: "https://apps.apple.com/kr/app/%ED%83%A4%EB%9F%B0%ED%8A%B8%EC%97%91%EC%8A%A4/id1529534277",
									//},
									// {
									//     route: true,
									//     title: "인재 페이지로 가기",
									//     url: "/",
									// },
								].map((menu, idx) =>
									menu.primary ? (
										<RouterLink
											key={idx}
											to={menu.url}
											className={classes.primaryMenuLink}
										>
											<ListItem button>
												<ListItemText
													primary={menu.title}
													classes={{
														primary:
															classes.listItemText,
													}}
												></ListItemText>
											</ListItem>
										</RouterLink>
									) : menu.route ? (
										<RouterLink
											key={idx}
											to={menu.url}
											className={classes.menuLink}
										>
											<ListItem button>
												<ListItemText
													primary={menu.title}
													classes={{
														primary:
															classes.listItemText,
													}}
												></ListItemText>
											</ListItem>
										</RouterLink>
									) : (
										<Link
											key={idx}
											href={menu.url}
											color="inherit"
											target="_blank"
										>
											<ListItem button>
												<ListItemText
													primary={menu.title}
													classes={{
														primary:
															classes.listItemText,
													}}
												></ListItemText>
											</ListItem>
										</Link>
									)
								)}
								<Divider variant="middle" />
								<RouterLink
									key={5}
									to="/"
									className={classes.menuLinkForOthers}
								>
									<ListItem button>
										<ListItemText
											primary="인재 회원이신가요?"
											classes={{
												primary: classes.listItemText,
											}}
										></ListItemText>
									</ListItem>
								</RouterLink>
							</List>
						</Box>
					)}
				</AppBar>
				<Paper
					elevation={0}
					className={classes.stickyBannerForCompany}
					variant="elevation"
					square
					onClick={() =>
						window.open("https://devision.co.kr", "newWindow")
					}
				>
					[공지] 탤런트엑스 V2 개발중으로 '데비전'을 이용바랍니다.
				</Paper>
				{/* <Paper
					elevation={0}
					className={classes.stickyBannerForCompany}
					variant="elevation"
					square
					onClick={() => history.push("/company-contract")}
				>
					2024년 기업 멤버십 신청하기
				</Paper> */}
			</Container>
		</React.Fragment>
	);
};

export default CompanyServiceHeader;
