import React from "react";
import { CssBaseline, Typography, Container, Button } from "@material-ui/core";
import axios from "axios";

let isButtonActive = true;

async function handleClick(type, token) {
    if (!isButtonActive) return;

    isButtonActive = false;

    try {
        await axios.patch(
            `https://api.talentx.talentinum.com/${type}/${token}`
        );

        alert("인증되었습니다.");
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401)
                alert(
                    "인증 기한이 만료되었습니다.\n이메일 인증을 새로 요청해주세요."
                );
            else
                alert(
                    "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
                );
        } else
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
    } finally {
        isButtonActive = true;
    }
}

class UserEmailVerification extends React.Component {
    render() {
        const query = new URLSearchParams(window.location.search);

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            margin: "0 auto",
                            padding: "30px",
                            maxWidth: "350px",
                        }}
                    >
                        <Typography
                            component="h3"
                            style={{
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            이메일 인증
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() =>
                                handleClick(this.props.type, query.get("token"))
                            }
                        >
                            이메일 인증하기
                        </Button>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default UserEmailVerification;
