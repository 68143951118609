import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Header, Footer } from "pages/components";
import { auth } from "apis/firebase";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import { Container, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
        display: "flex",
        height: "100vh",
        padding: theme.spacing(10, 0),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(4, 2),
        },
    },
    authContainer: {
        margin: "auto",
    },
}));

function Authentication(props) {
    const classes = useStyles();

    const [isSignedIn, setIsSignedIn] = useState(false);
    useEffect(() => {
        const ui = new firebaseui.auth.AuthUI(auth);
        ui.start("#firebaseui-auth-container", uiConfig);
        auth.onAuthStateChanged((user) => {
            setIsSignedIn(!!user);
        });

        return function cleanup() {
            ui.delete();
        };
    }, []);

    const uiConfig = {
        signInSuccessUrl: false,
        // signInSuccessUrl: "http://localhost:3000/admin/dashboard",
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            //firebase.auth.GithubAuthProvider.PROVIDER_ID,
            //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: "/terms-of-use",
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
            window.location.assign("/terms-of-use");
        },
    };

    return (
        <React.Fragment>
            {isSignedIn ? (
                <Redirect to="/talentx/admin/dashboard" />
            ) : (
                <React.Fragment>
                    <Header />
                    <Box className={classes.container}>
                        <Container maxWidth="lg">
                            <div
                                className={classes.authContainer}
                                id="firebaseui-auth-container"
                            />
                        </Container>
                    </Box>

                    <Footer />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default Authentication;
