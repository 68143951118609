import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        padding: theme.spacing(7, 0),
        backgroundColor: "white",
        //color: "white",
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            // padding: "0 calc((100% - 296px) / 2)",
            padding: "0 32px",
        },
    },
}));

export default function RecruitemntPrecautions(params) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            <b>주의사항</b>
                        </Typography>
                        <Typography variant="subtitle2">
                            - 합당한 사유 없이 공채 전형에 불참한 경우 사전 고지
                            없이 불합격 및 지원 취소가 될 수 있습니다.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
