import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => {
    return {
        emailField: {
            height: "3rem",
            width: "44vmin",
            [theme.breakpoints.down("xs")]: {
                width: "80vmin",
            },
            margin: theme.spacing(1),
        },
    };
});

export default function DatePicker() {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(
        new Date("2000-01-01T00:00:00")
    );

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                clearable
                openTo="year"
                variant="inline"
                format="yyyy/MM/dd"
                maxDate={new Date()}
                margin="normal"
                id="date-picker-inline"
                label="생년월일"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                className={classes.emailField}
            />
        </MuiPickersUtilsProvider>
    );
}
