export const companyTestData = [
    "전체",
    "탤런티넘",
    "주식회사 피트",
    "(주)조아라",
    "오픈엣지테크놀로지(주)",
    "(주)헤븐트리",
    "(주)레인보우브레인",
    "네오위즈",
    "디아이티주식회사",
    "주식회사 위즈돔",
    "마인즈랩",
    "(주)플레이오니",
    "(주)코어트러스트",
    "그레이프시티",
    "싸이맥스",
    "(주)코비젼",
    "신세계아이앤씨",
    "에스큐앤티 주식회사",
    "람다256 주식회사",
    "코멘토",
    "주식회사 하이",
    "오스템임플란트(주)",
];

export const devstarxCompanies = [
    {
        registrationNumber: "2158772878",
        slogan: "언어 장벽 없는 세상, 플리토가 만들어갑니다.",
        name: "플리토",
        hirings: [
            { position: "개발팀장" },
            { position: "프론트엔드 엔지니어" },
            { position: "백엔드 엔지니어" },
        ],
    },
    {
        registrationNumber: "5708501897",
        slogan: "Make your life smart",
        name: "섹타나인",
        hirings: [{ position: "모바일 개발팀장" }],
    },
    {
        registrationNumber: "2148859980",
        slogan: "글로벌 패밀리 엔터테인먼트",
        name: "더핑크퐁컴퍼니",
        hirings: [{ position: "iOS 엔지니어" }, { position: "게임 엔지니어" }],
    },
    {
        registrationNumber: "4358800655",
        slogan: "피부데이터로 뷰티 산업을 연결하다.",
        name: "룰루랩",
        hirings: [
            { position: "머신러닝 엔지니어" },
            { position: "백엔드 엔지니어" },
            { position: "iOS 엔지니어" },
            { position: "안드로이드 엔지니어" },
        ],
    },
    {
        registrationNumber: "5138601891",
        slogan: "우리는 채용 문화를 바꿔나가요",
        name: "두들린",
        hirings: [
            { position: "프론트엔드 엔지니어" },
            { position: "백엔드 엔지니어" },
            { position: "데브옵스 엔지니어" },
        ],
    },
    // {
    //     registrationNumber: "2808600274",
    //     slogan: "이동이 더 편한 세상을 만든다",
    //     name: "아이엠에스모빌리티",
    //     hirings: [
    //         { position: "프론트엔드 엔지니어" },
    //         { position: "백엔드 엔지니어" },
    //         { position: "데브옵스 엔지니어" },
    //     ],
    // },
    // {
    //     registrationNumber: "2618121220",
    //     slogan: "Design your happiness",
    //     name: "아키드로우",
    //     hirings: [
    //         { position: "프론트엔드 엔지니어" },
    //         { position: "백엔드 엔지니어" },
    //         { position: "풀스택 엔지니어" },
    //         { position: "iOS 엔지니어" },
    //         { position: "안드로이드 엔지니어" },
    //         { position: "모바일 엔지니어" },
    //     ],
    // },
    // {
    //     registrationNumber: "1068677081",
    //     slogan: "데이터의 새로운 미래",
    //     name: "오픈서베이",
    //     hirings: [
    //         { position: "프론트엔드 엔지니어" },
    //         { position: "백엔드 엔지니어" },
    //         { position: "데브옵스 엔지니어" },
    //     ],
    // },
    // {
    //     registrationNumber: null,
    //     slogan: "Talent NFT Platform",
    //     name: "탤런티넘",
    //     hirings: [{ position: "블록체인 엔지니어" }],
    // },
];
