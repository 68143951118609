import { makeStyles } from "@material-ui/core/styles";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    body: {
        backgroundColor: theme.palette.common.white,
        textAlign: "center",
        width: "100%",
    },

    tabs: {
        flexGrow: 1,
    },
    values: {
        textAlign: "center",
        paddingTop: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    proposition: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 53,
        whiteSpace: "pre-wrap",
        lineHeight: 1.38,
    },
    value: {
        marginBottom: 48,
    },
    valueImg: { marginBottom: 24 },
    valueTitle: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#444444",
        marginBottom: 4,
    },
    valueDesc: {
        fontSize: 14,
        fontWeight: 300,
        color: "#999999",
        whiteSpace: "pre-wrap",
        wordBreak: "keep-all",
        letterSpacing: -0.6,
    },
    benefits: {
        //backgroundColor: "white",
        //display: "flex",
        //flexDirection: "column",
        //justifyContent: "center",
        //alignItems: "center",
        textAlign: "center",
    },
    benefit: {
        marginTop: 100,
    },
    benefitKeyword: {
        display: "inline-block",
        backgroundColor: "#0c46f2",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 24,
        color: "#ffffff",
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 32,
    },
    benefitTitle: {
        fontSize: 24,
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        marginBottom: 32,
        lineHeight: 1.38,
    },
    benefitImgIconWrap: {
        position: "relative",
    },
    benefitImg: {
        width: "100%",
        //marginBottom: 32,
    },
    benefitIcon: {
        position: "absolute",
        left: "60%",
        top: "60%",
        animation: "$motion 0.7s ease-in-out 0.3s infinite alternate",
        marginTop: 0,
    },
    "@keyframes motion": {
        "0%": {
            marginTop: 0,
        },
        "100%": {
            marginTop: 20,
        },
    },
    benefitDesc0: {
        display: "block",
        fontSize: 16,
        fontWeight: 500,
        whiteSpace: "pre-wrap",
        color: "#444444",
        marginLeft: 40,
        marginRight: 40,
        marginTop: 32,
        marginBottom: 4,
    },
    benefitDesc1: {
        display: "block",
        fontSize: 16,
        fontWeight: 500,
        color: "#666666",
        marginLeft: 40,
        marginRight: 40,
        marginTop: 4,
        marginBottom: 4,
        paddingTop: 13,
        paddingBottom: 11,
        backgroundColor: "#f8f8f8",
        boxShadow: "inset 0 2px 8px 0 rgba(125, 125, 125, 0.24)",
        borderRadius: 8,
        //border: "1px solid black",
    },
    message: {
        marginTop: 64,
        fontSize: 14,
        fontWeight: 500,
        color: "#444444",
        whiteSpace: "pre-wrap",
    },
    features: {
        position: "relative",
        textAlign: "center",
    },
    featureTitle: {
        position: "absolute",
        top: 35,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "90%",
        fontSize: 24,
        fontWeight: "bold",
        color: "#222222",
        whiteSpace: "pre-wrap",
        lineHeight: 1.33,
    },
    featureImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        marginLeft: "auto",
        marginRight: "auto",
    },
    featureMsg: {
        paddingTop: 32,
        fontSize: 14,
        fontWeight: 300,
        color: "#444444",
        whiteSpace: "pre-wrap",
        paddingBottom: 48,
    },
    steps: {
        position: "relative",
        textAlign: "center",
    },
    stepTitle: {
        position: "absolute",
        top: 65,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "80%",
        fontSize: 24,
        fontWeight: "bold",
        color: "#ffffff",
        whiteSpace: "pre-wrap",
        lineHeight: 1.33,
    },
    stepImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        marginLeft: "auto",
        marginRight: "auto",
    },
    stepMsg: {
        paddingTop: 32,
        backgroundColor: "#074396",
        fontSize: 14,
        fontWeight: 300,
        color: "#ffffff",
        whiteSpace: "pre-wrap",
        paddingBottom: 48,
    },
    manager: {
        paddingTop: 80,
        backgroundColor: "#f0f4ff",
    },
    managerKeyword: {
        display: "inline-block",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 24,
        color: "#0c46f2",
        border: "2px solid #0c46f2",
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 24,
    },
    managerTitle: {
        fontSize: 24,
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        marginBottom: 40,
        lineHeight: 1.33,
    },
    managerImgIconWrap: {
        position: "relative",
    },
    managerImg: {
        //width: "100%",
        //marginBottom: 32,
    },
    progressBar: {
        position: "relative",
        zIndex: 0,
    },
    line: {
        position: "absolute",
        top: "50%",
        left: "25%",
        width: "50%",
        borderBottom: "2px solid #1a6bff",
        zIndex: 1,
    },
    progress: {
        position: "relative",
        display: "inline-block",
        padding: theme.spacing(1, 2),
        borderRadius: 24,
        color: "#444444",
        border: "2px solid #1a6bff",
        fontSize: 16,
        fontWeight: "bold",
        margin: theme.spacing(3, 3),
        zIndex: 2,
        backgroundColor: "#ffffff",
    },
    progressLabel: {
        display: "inline-block",
        //padding: theme.spacing(1, 2),
        color: "#444444",
        fontSize: 12,
        fontWeight: 500,
        width: 70,
        margin: theme.spacing(0, 1.5, 10),
        whiteSpace: "pre-wrap",
    },
    paper: {
        margin: "0 auto",
        //width: 600,
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(30),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    newTabs: {
        backgroundColor: "#074396",
    },
    newIndicator: {
        backgroundColor: "#ffffff",
    },
    newTab: {
        color: "#ffffff",
        ["@media (min-width: 600px)"]: {
            minWidth: 120,
        },
        //fontWeight: 300,

        // "&:hover": {
        //     color: "#ffffff",
        //     opacity: 1,
        // },
        // "&$selected": {
        //     color: "#ffffff",
        //     fontWeight: "bold",
        // },
        // "&:focus": {
        //     color: "#ffffff",
        //     fontWeight: 300,
        // },
    },
    tab: {
        ["@media (min-width: 600px)"]: {
            minWidth: 120,
        },
    },
    fixedButton: {
        backgroundColor: "#207cff",
        textAlign: "center",
        position: "fixed",
        zIndex: 1,
        bottom: 0,
        width: "100%",
        maxWidth: 444,
        height: 60,
        left: "50%",
        transform: "translate(-50%, 0)",
        paddingTop: 12,
        paddingBottom: 12,
    },
    fixedButtonName: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.43,
    },
    fixedButtonDesc: {
        color: "#ffffff",
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.43,
    },
    phantom: {
        width: "100%",
        height: 60,
    },
    fixedButtonRouteLink: {
        textDecoration: "none",
    },
    clientsSection: {
        backgroundColor: "#ffffff",
        textAlign: "center",
        paddingTop: 100,
        paddingBottom: 80,
    },
    clientsTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        whiteSpace: "pre-wrap",
    },
    clientsDesc: {
        ...Texts.basic(16, 500, "#787878", 1.38),
        whiteSpace: "pre-wrap",
        marginTop: 16,
        marginBottom: 50,
    },
    clientsLogos: {
        flexGrow: 1,
    },
    clientLogoGridItem: {
        height: 54,
    },
    clientLogo: {
        height: 27,
    },
    reviewSection: {
        backgroundColor: "#f8f8f8",
        textAlign: "center",
        paddingTop: 80,
        paddingBottom: 80,
        overflowX: "hidden",
    },
    reviewSectionContainer: {
        position: "relative",
        overflowX: "visible",
    },
    reviewTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        whiteSpace: "pre-wrap",
        marginBottom: 40,
    },
    reviewCardWindow: {
        display: "flex",
        position: "relative",
        paddingLeft: 20,
        overflowX: "auto",
        overflowY: "hidden",
        flexDirection: "row",
        marginBottom: 24,
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    reviewCard: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
        borderRadius: 20,
        boxShadow: "0 2px 16px 0 #eee",
        width: 260,
        height: 288,
        padding: 24,
        marginRight: 20,
    },
    reviewRatingWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    reviewRating: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 7,
    },
    reviewRatingDesc: {
        ...Texts.basic(14, 500, "#ababab", 1.36),
        marginBottom: 20,
    },
    reviewContent: {
        flexGrow: 1,
        ...Texts.basic(14, 500, "#111111", 1.43),
        textAlign: "left",
    },
    reviewStar: {
        width: 24,
        height: 24,
    },
    reviewClientLogo: {
        height: 20,
    },
    reviewClient: {
        marginTop: 12,
        ...Texts.basic(16, 500, "#929292", 1.38),
    },
    reviewCardWindowScroll: {},
    reviewCardWindowScroller: {
        display: "inline-flex",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        boxShadow: "0 2px 16px 0 #eee",
        width: 56,
        height: 56,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 16,
    },
    qnaSection: {
        backgroundColor: "#f8f8f8",
        paddingTop: 48,
        paddingBottom: 32,
    },
    qnaSectionTitle: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 32,
    },
    qnaBox: {
        marginLeft: 24,
        marginRight: 24,
        textAlign: "left",
        marginBottom: 16,
    },
    questionBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 16,
    },
    question: {
        marginLeft: 4,
        flexGrow: 1,
    },
    answerBox: {
        backgroundColor: "#eeeeee",
        justifyContent: "flex-start",
        padding: 16,
    },
    answer: {
        fontSize: 14,
        fontWeight: 300,
        color: "#444444",
        whiteSpace: "pre-wrap",
    },
    answerBoxClosed: {
        display: "none",
    },
    divider: {
        width: "100%",
    },
}));
