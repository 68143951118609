export const companyData = {
	mission: "탤런트엑스는 기업과\nSW인재를 연결합니다",
	proposition: "개발자 채용에 왜 탤런트엑스가 필요할까요?",
	values: [
		{
			image: require("assets/img-feature-talent-v2.png"),
			image2x: require("assets/img-feature-talent-v2@2x.png"),
			image3x: require("assets/img-feature-talent-v2@3x.png"),
			title: "사전 검증된 인재풀",
			desc: "사전 검증 과정을 통해 우수한 역량을 보유한\n개발자만을 플랫폼에 참여시킵니다.",
		},
		{
			image: require("assets/value-proposition-match-ai.png"),
			image2x: require("assets/value-proposition-match-ai@2x.png"),
			image3x: require("assets/value-proposition-match-ai@3x.png"),
			title: "맞춤형 인재 추천",
			desc: "인공지능 휴리가 기업과 개발자간\n최적의 매치를 찾아 추천합니다.",
		},
		{
			image: require("assets/img-feature-salary-200.png"),
			image2x: require("assets/img-feature-salary-200@2x.png"),
			image3x: require("assets/img-feature-salary-200@3x.png"),
			title: "연봉 및 채용조건 조율 대행",
			desc: "합격한 개발자의 연봉 및 채용조건은\n탤런트엑스가 대신 조율해드립니다.",
		},
	],
	benefits: [
		{
			keyword: "쉽고 간편한 채용",
			title: "유능한 개발자를 찾는 건 어렵지만\n탤런트엑스는 간편합니다",
			image: require("assets/img-mobile-recommendation.png"),
			image2x: require("assets/img-mobile-recommendation@2x.png"),
			image3x: require("assets/img-mobile-recommendation@3x.png"),
			desc: [
				"찾아 다니는 수고 없이 우리 회사에 알맞은 개발자를 추천받으세요",
			],
		},
		{
			keyword: "시간 단축",
			title: "채용에 소요되는\n시간과 비용을 절약해줍니다",
			image: require("assets/img-clock.png"),
			image2x: require("assets/img-clock@2x.png"),
			image3x: require("assets/img-clock@3x.png"),
			icon: require("assets/icon-down-arrow.png"),
			desc: [
				"테스트를 통해 검증된 인재풀",
				"인재를 직접 찾을 필요 X",
				"인재 프로필 열람 비용 X",
				"편리한 온라인 면접 예약 시스템",
				"채용 조건 조율 대행",
			],
			caption: "담당 매니저 배정",
			message: "탤런트엑스는 SW인재 채용에 가장 효과적인 솔루션입니다.",
			arrow: require("assets/icon-nav-chevron-down-24.png"),
		},
	],
	features: [
		{
			title: "매주 우리 회사에 알맞은\n새로운 개발자를 추천 받으세요",
			buttons: ["정기 추천", "프로필 열람", "추천 설정"],
			image: require("assets/img-function-rec.png"),
			image2x: require("assets/img-function-rec@2x.png"),
			image3x: require("assets/img-function-rec@3x.png"),
			message:
				"찾아 다니는 수고 없이 매주 새로운\nSW인재를 추천 받을 수 있어요.",
		},
		{
			title: "매주 우리 회사에 알맞은\n새로운 개발자를 추천 받으세요",
			image: require("assets/img-app-talent-profile.png"),
			image2x: require("assets/img-app-talent-profile@2x.png"),
			image3x: require("assets/img-app-talent-profile@3x.png"),
			message: "SW인재 프로필 열람을 통해\n인재의 기술역량을 확인하세요.",
		},
		{
			title: "매주 우리 회사에 알맞은\n새로운 개발자를 추천 받으세요",
			image: require("assets/img-app-setting-new-hires.png"),
			image2x: require("assets/img-app-setting-new-hires@2x.png"),
			image3x: require("assets/img-app-setting-new-hires@3x.png"),
			message:
				"채용을 희망하는 포지션을 설정하면\n해당 포지션에 알맞은 인재를 추천합니다.",
		},
	],
	hiringManager: { title: "이제 편하게 개발자를 채용하세요" },
	stat: {
		title: "검증된 인재풀",
		desc: "당신이 찾는\n실력 있는 개발자\n여기 있어요!",
		total: "1,328명",
		experienced: "914명",
	},

	simple: {
		icon: "icon-briefcase-blue",
		title: "간편한 채용 솔루션",
		desc: "실력있는 개발자 영입,\n이제 쉽고 간편해집니다",
		content: [
			{
				image: "img-talent-recommend",
				desc: "힘들게 개발자 찾는 거 이제 그만.\n탤런트엑스 앱을 통해\n검증된 개발자를 추천받으세요.",
			},
			{
				image: "img-ask-to-apply",
				desc: "추천받은 개발자 중에 지원 의향을\n확인하고 싶은 개발자가 있나요?",
				more: "터치 한 번만 하면 탤런트엑스 HR팀이\n개발자의 지원 의향을 대신 확인해드려요.",
			},
			{
				image: "img-salary-nego",
				desc: "합격한 개발자의\n채용 조건\n전담 HR 매니저가\n합리적으로 조율",
			},
		],
	},

	profile: {
		icon: "icon-mobile-profile-blue",
		title: "프로필",
		desc: "개발자의 보유 기술부터\n경력, 희망사항까지\n모두 한 번에 확인하세요",
		content: [
			{
				image: "img-talent-profile-bottom",
			},
			{
				image: "img-talent-profile-top",
				icon: "icon-download-profile",
				desc: "내 이메일로 프로필 문서를\n전송받아 유관부서에 공유하고\n빠르게 의사결정하세요.",
			},
		],
	},

	support: {
		icon: "icon-hr-messenger",
		title: "HR 지원",
		desc: "프로필만 평가하면 나머진 다 알아서 해주는",
		keyword: "전담 매니저 서비스",
		content: [
			{
				desc: "개발자 프로필 평가하고\n나머지 일은 매니저에게\n맡겨주세요!",
				image: "img-talent-profile-rating",
			},
			{
				image: "icon-chat-and-phone",
				desc: "지원 의향 파악,\n면접 제안,\n면접 일정 조율 등\n전담 매니저를 통한\n효율적인 채용 진행",
			},
			{
				image: "img-cs-manager",
				title: "HR매니저\n최성원",
				chat1: "안녕하세요, 담당자님.\n임병현님 귀사 지원 의향이 있다\n하시는데 면접 일정 잡아드릴까요?",
				chat2: "네, 다음 주 중에 오후 2시 정도로 면접\n일정 조율 부탁드려요. 😄",
				chat3: "네, 알겠습니다. 😄\n희망 하시는 일정에 맞춰 개발자님과\n면접 일정 조율해 볼게요.",
			},
			{
				title: "채용 조건 조율",
				desc: "구직자의 희망 급여를 적정선으로\n맞추고, 연봉 외 기업의 장점을 활용해\n양측이 만족하는 합리적인 계약을\n성사시킵니다.",
				image: "icon-offer-nego",
			},
			{
				title: "디지털 계약",
				desc: "빠르고 간편하게 입사 전\n공식 근로 약정을 체결하여\n입사 번복을 예방합니다.",
				image: "img-emplyee-contract",
			},
		],
	},

	branding: {
		title: "채용 브랜딩",
		desc: "개발자에게\n매력적인 기업으로",
		content: [
			{
				image: "img-devteam-page",
				title: "개발자에게 매력적인\n개발팀 페이지를 만들어드려요.",
				desc: "구직자들에게 우리 개발팀을\n소개하고 사용 기술, 개발 방식,\n개발 문화 등을 이야기하세요.",
			},
			{
				image: "icon-advertizement",
				title: "지원자 모집에\n채용 페이지\n유입 광고 진행",
			},
			{
				image: "img-kakao-banner",
			},
		],
	},

	openscout: {
		title: "채용 캠페인",
		desc: "이렇게나 쉬운\n공개 채용 시작하기",
		content: [
			{
				image: "img-openscout-reference",
				title: "오픈스카우트 서비스를 통해\n간편하게 공개 채용을 진행해 보세요.",
			},
			{
				image: "icon-help-question",
				title: "오픈스카우트란?",
				desc: "오픈스카우트는 공개 채용을 진행함에 어려움이 있는 기업들을 위해\n관련 페이지를 구축해 주고 운영해 주는 프리미엄 서비스입니다.(별도 비용 발생)",
			},
			{
				image: "icon-monitor-black-36",
				title: "공개 채용 페이지\n구축 대행",
				desc: "개발자들이 좋아하는 기업 정보와\n콘텐츠를 수집하고 재단하여\n고객사 맞춤형 공개 채용 페이지를\n만들어드려요.",
			},
			{
				image: "icon-gear-black-36",
				title: "채용 프로세스\n맞춤형 설계 및 운영",
				desc: "고객사가 원하는 방식에 맞춰\n효율적인 채용 프로세스를 설계해 주고\n운영해드립니다.",
			},
			{
				image: "icon-chat-black-36",
				title: "개발자 커뮤니티 대상\n채용 설명회 운영",
				desc: "개발자들과 직접 소통해 볼 수 있는 기회.\n보유한 인재풀과 커뮤니티 대상으로\n실시간 채용 설명회를 열어드립니다.",
			},
		],
	},

	pricing: {
		title: "개발자 영입은\n탤런트엑스와 함께",
		subtitle: "[2024년 기업 멤버십]",
		plans: [
			{
				key: "lightup",
				name: "라이트업",
				desc: "개발자 채용공고에 지원자가 많지 않아 대안 솔루션이 필요한 기업이 가볍게 시작하기 좋은 멤버십",
				prices: [
					"· 개발자 채용: 1명",
					// "· 채용 수수료: 무료",
					// "· 가입 보증금: 무료",
					"· 멤버십 요금: 300만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"개발자 1명 채용권",
				],
			},
			{
				key: "scaleup",
				name: "스케일업",
				desc: "고속 성장을 위해 빠르게 개발팀을 확장해야 하는 기업이 합리적인 가격으로 이용하기 좋은 멤버십",
				prices: [
					"· 개발자 채용: 3명",
					// "· 채용 수수료: 무료",
					// "· 가입 보증금: 무료",
					"· 멤버십 요금: 700만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"개발자 3명 채용권",
					"타겟 리크루팅(카카오톡 유료광고)",
				],
			},
			{
				key: "premium",
				name: "프리미엄",
				desc: "지속 성장을 위해 상시 다수의 고급 개발자를 영입해야 하는 기업이 편리하게 이용하기 좋은 멤버십",
				prices: [
					"· 개발자 채용: 무제한",
					// "· 채용 수수료: 무료",
					// "· 가입 보증금: 무료",
					"· 멤버십 요금: 3000만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"개발자 무제한 채용권",
					"타겟 리크루팅(카카오톡 유료광고 + 커뮤니티)",
					"탤런트엑스 케어 플러스(+3개월 근속관리)",
					"채용 매니지먼트(전담 테크 리크루터 배정)",
					"프리미엄 추천(리더급, AI 등 고급 개발자)",
					"오픈스카우트(공개 채용 캠페인)",
				],
			},
		],
		oldSubtitle: "[기존 성과형 멤버십]",
		oldPlans: [
			{
				name: "베이직",
				prices: [
					"· 채용 수수료: 계약연봉의 15% ~ 25%",
					"· 가입 보증금: 1,000만원(환급)",
					"· 멤버십 요금: 100만원/가입 시",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"타겟 리크루팅(카카오톡 광고)",
				],
			},
			{
				name: "퍼포먼스",
				prices: [
					"· 채용 수수료: 계약연봉의 15% ~ 25%",
					"· 가입 보증금: 1,000만원(환급)",
					"· 멤버십 요금: 600만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",

					"타겟 리크루팅(카카오톡 유료광고 + 커뮤니티)",
					"탤런트엑스 케어 플러스(+3개월 근속관리)",
					"채용 매니지먼트(전담 테크 리크루터 배정)",
					"프리미엄 추천(리더급, AI 등 고급 개발자)",
					"오픈스카우트(공개 채용 캠페인)",
				],
			},
		],
	},

	steps: [
		{
			title: "마음에 드는 개발자에게\n면접을 제안하세요",
			buttons: ["면접 제안", "면접 알림", "합격 통보"],
			image: require("assets/img-function-interview.png"),
			image2x: require("assets/img-function-interview@2x.png"),
			image3x: require("assets/img-function-interview@3x.png"),
			message:
				"인재의 취업 활동 현황을 확인하고\n가능 일정에 면접을 제안하세요.",
		},
		{
			title: "마음에 드는 개발자에게\n면접을 제안하세요",
			image: require("assets/img-app-interview-reminding.png"),
			image2x: require("assets/img-app-interview-reminding@2x.png"),
			image3x: require("assets/img-app-interview-reminding@3x.png"),
			message:
				"혹여 면접 시간을 잊을까 걱정하지마세요.\n면접이 가까워지면 알려드려요.",
		},
		{
			title: "마음에 드는 개발자에게\n면접을 제안하세요",
			image: require("assets/img-app-interview-pass.png"),
			image2x: require("assets/img-app-interview-pass@2x.png"),
			image3x: require("assets/img-app-interview-pass@3x.png"),
			message: "면접 이후 합격 통보도\n온라인에서 간편하게!",
		},
	],
	manager: {
		keyword: "전담 매니저",
		title: "합격한 개발자의 채용 조건을\n전담 매니저가 조율해드립니다",
		image: require("assets/img-talent-manager-desktop.png"),
		image2x: require("assets/img-talent-manager-desktop@2x.png"),
		image3x: require("assets/img-talent-manager-desktop@3x.png"),
		desc: [
			"채용 희망 조건\n확인 및 조정",
			"근로 계약 조건\n조율 대행",
			"온라인\n채용 계약",
		],
	},
	clients: {
		title: "성공적인 개발자 영입의 비밀",
		desc: "성공적으로 개발자를 영입하는 기업은 탤런트엑스를 사용합니다.\n탤런트엑스를 통해 최고의 채용 성과를 내고 있습니다.",
		logos: [
			require("assets/logo-archidraw-240x100@2x.png"),
			require("assets/logo-wecode-240x100@2x.png"),
			require("assets/logo-whosgood-240x100@2x.png"),
			require("assets/logo-jobis-240x100@2x.png"),
			require("assets/logo-fitt-240x100@2x.png"),
			require("assets/logo-gec-240x100@2x.png"),
		],
	},
	clientReview: {
		title: "탤런트엑스 고객 기업의\n이용 후기를 확인하세요",
		reviews: [
			{
				client: "아키드로우",
				clientLogo: "logo-archidraw",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"유능한 개발자들을 찾는 게 힘들었는데 실력이 검증된 인재를 추천해주고 직접 중간에서 소통까지 해주시니 정말 편하게 개발자를 채용할 수 있었습니다.",
			},
			{
				client: "위코드",
				clientLogo: "logo-wecode",
				rating: 4,
				ratingDesc: "만족해요",
				content:
					"추천 받은 개발자들의 경력과 학력 등을 쉽게 확인할 수 있는 점이 좋았어요. 특히, 면접 일정 조정이 필요할 때 탤런트엑스에서 대신 해결해주시는 점이 가장 좋았습니다.",
			},
			{
				client: "지속가능발전소",
				clientLogo: "logo-whosgood",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"맞춤형으로 인재를 추천 받을 수 있고 다양한 채널을 통해 개발자를 모집해주는 점이 마음에 들어요.",
			},
			{
				client: "자비스앤빌런즈",
				clientLogo: "logo-jobis",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"저희 기업의 상황에 맞는 다양한 채용 방안들을 고민해 주시는 부분이 마음에 들었습니다.",
			},
			{
				client: "피트",
				clientLogo: "logo-fitt",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"개발직군에 특화된 서비스인만큼 타 채용 서비스들보다 더 전문적인 인재풀을 보유하고 있다고 느껴졌습니다.",
			},
			{
				client: "젠틀에너지",
				clientLogo: "logo-gec",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"좋은 개발자 인재풀을 확보하고 있는 점이 마음에 들어요.",
			},
		],
	},
	qna: {
		title: "자주 묻는 질문",
		faqs: [
			{
				question: "기업 회원 가입 요건이 있나요?",
				html: {
					__html: `<b>가입 요건은 없습니다. 채용 수요에 맞는 멤버십을 선택하시면 되고, 멤버십별 권장 기업은 다음과 같습니다.</b><br><br>- 라이트업: 임직원 수 10명 이상, 개발자 수 3명 이상, 매출/투자규모 3억원 이상<br>- 스케일업: 임직원 수 30명 이상, 개발자 수 10명 이상, 매출/투자규모 10억원 이상<br>- 프리미엄: 임직원 수 100명 이상, 개발자 수 30명 이상, 매출/투자규모 30억원 이상`,
				},
			},
			{
				question: "체험 사용이 가능한가요?",
				answer: "실제 개발자 한명을 채용하는 데 평균적으로 2~3개월 가량 소요되기 때문에 단기간 체험 서비스는 제공하고 있지 않습니다. 그 대신 도입 검토를 위한 서비스 데모를 화상 미팅으로 제공하고 있습니다.",
			},
			{
				question: "인재를 조건별로 검색할 수 있나요?",
				answer: "검색 기능은 제공하지 않습니다. 그 대신 귀사의 채용 니즈에 가장 적합한 개발자를 선별하여 매주 추천해드리기 때문에 직접 검색하고 찾는 수고를 덜으실 수 있습니다.",
			},
			{
				question: "멤버십 이용 중 해지가 가능한가요?",
				answer: "채용 실적이 전혀 없는 경우라면 이용 기간 중 언제든 해지가 가능하며, 이용 기간에 상관 없이 멤버십 정가의 70%를 환불해 드립니다.",
			},
			{
				question:
					"멤버십 기간 만료까지 채용을 못한 경우 어떻게 되나요?",
				answer: "멤버십 이용 기간 동안 채용 실적이 없었던 경우, 이용한 멤버십 정가의 70% 환불 혹은 멤버십 기간을 1년 연장해 드립니다.",
			},
			{
				question:
					"멤버십 제공 채용 인원 외 추가 채용이 필요하면 어떻게 해야 하나요?",
				answer: "추가 채용 규모에 따라 적합한 멤버십을 추가 구매하거나 상위 멤버십으로 업그레이드 후 이용하실 수 있습니다.",
			},
		],
	},
	actionBtn: {
		title: "기업 멤버십 신청하기",
		desc: "새로운 가격을 확인",
		url: "/company-contract",
	},
};

export const companyDataM = {
	mission: "탤런트엑스는 기업과\nSW인재를 연결합니다",
	proposition: "개발자 채용에 왜\n탤런트엑스가 필요할까요?",
	values: [
		{
			image: require("assets/img-feature-talent-v2.png"),
			image2x: require("assets/img-feature-talent-v2@2x.png"),
			image3x: require("assets/img-feature-talent-v2@3x.png"),
			title: "사전 검증된 인재풀",
			desc: "사전 검증 과정을 통해 우수한 역량을 보유한\n개발자만을 플랫폼에 참여시킵니다.",
		},
		{
			image: require("assets/value-proposition-match-ai.png"),
			image2x: require("assets/value-proposition-match-ai@2x.png"),
			image3x: require("assets/value-proposition-match-ai@3x.png"),
			title: "맞춤형 인재 추천",
			desc: "인공지능 휴리가 기업과 개발자간\n최적의 매치를 찾아 추천합니다.",
		},
		{
			image: require("assets/img-feature-salary-200.png"),
			image2x: require("assets/img-feature-salary-200@2x.png"),
			image3x: require("assets/img-feature-salary-200@3x.png"),
			title: "연봉 및 채용조건 조율 대행",
			desc: "합격한 개발자의 연봉 및 채용조건은\n탤런트엑스가 대신 조율해드립니다.",
		},
	],
	clients: {
		title: "성공적인 개발자\n영입의 비밀",
		desc: "성공적으로 개발자를 영입하는 기업들은\n탤런트엑스를 사용하고 있습니다.",
		logos: [
			require("assets/logo-archidraw-150x64@2x.png"),
			require("assets/logo-wecode-150x64@2x.png"),
			require("assets/logo-whosgood-150x64@2x.png"),
			require("assets/logo-jobis-150x64@2x.png"),
			require("assets/logo-fitt-150x64@2x.png"),
			require("assets/logo-gec-150x64@2x.png"),
		],
	},
	clientReview: {
		title: "탤런트엑스 고객 기업의\n이용 후기를 확인하세요",
		reviews: [
			{
				client: "아키드로우",
				clientLogo: "logo-archidraw",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"유능한 개발자들을 찾는 게 힘들었는데 실력이 검증된 인재를 추천해주고 직접 중간에서 소통까지 해주시니 정말 편하게 개발자를 채용할 수 있었습니다.",
			},
			{
				client: "위코드",
				clientLogo: "logo-wecode",
				rating: 4,
				ratingDesc: "만족해요",
				content:
					"추천 받은 개발자들의 경력과 학력 등을 쉽게 확인할 수 있는 점이 좋았어요. 특히, 면접 일정 조정이 필요할 때 탤런트엑스에서 대신 해결해주시는 점이 가장 좋았습니다.",
			},
			{
				client: "지속가능발전소",
				clientLogo: "logo-whosgood",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"맞춤형으로 인재를 추천 받을 수 있고 다양한 채널을 통해 개발자를 모집해주는 점이 마음에 들어요.",
			},
			{
				client: "자비스앤빌런즈",
				clientLogo: "logo-jobis",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"저희 기업의 상황에 맞는 다양한 채용 방안들을 고민해 주시는 부분이 마음에 들었습니다.",
			},
			{
				client: "피트",
				clientLogo: "logo-fitt",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"개발직군에 특화된 서비스인만큼 타 채용 서비스들보다 더 전문적인 인재풀을 보유하고 있다고 느껴졌습니다.",
			},
			{
				client: "젠틀에너지",
				clientLogo: "logo-gec",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"좋은 개발자 인재풀을 확보하고 있는 점이 마음에 들어요.",
			},
		],
	},
	targetUser: {
		title: "이제 편하게\n개발자를 채용하세요",
	},
	stat: {
		title: "검증된 인재풀",
		desc: "당신이 찾는\n실력 있는 개발자\n여기 있어요!",
		total: "1,328명",
		experienced: "914명",
	},

	simple: {
		icon: "icon-briefcase-blue-24",
		title: "간편한 채용 솔루션",
		desc: "실력있는 개발자 영입,\n이제 쉽고 간편해집니다",
		content: [
			{
				image: "img-talent-recommend",
				desc: "힘들게 개발자 찾는 거 이제 그만.\n탤런트엑스 앱을 통해\n검증된 개발자를 추천받으세요.",
			},
			{
				image: "img-ask-to-apply",
				desc: "추천받은 개발자 중에 지원 의향을\n확인하고 싶은 개발자가 있나요?",
				more: "터치 한 번만 하면 탤런트엑스 HR팀이\n개발자의 지원 의향을 대신 확인해드려요.",
			},
			{
				image: "img-salary-nego",
				desc: "합격한 개발자의\n채용 조건\n전담 HR 매니저가\n합리적으로 조율",
			},
		],
	},

	profile: {
		icon: "icon-mobile-profile-blue-24",
		title: "프로필",
		desc: "개발자의 보유 기술부터\n경력, 희망사항까지\n모두 한 번에 확인하세요",
		content: [
			{
				image: "img-talent-profile-bottom",
			},
			{
				image: "img-talent-profile-top",
				icon: "icon-download-profile",
				desc: "내 이메일로 프로필 문서를\n전송받아 유관부서에 공유하고\n빠르게 의사결정하세요.",
			},
		],
	},

	support: {
		icon: "icon-hr-messenger-24",
		title: "HR 지원",
		desc: "프로필만 평가하면 나머진\n다 알아서 해주는",
		keyword: "전담 매니저 서비스",
		content: [
			{
				desc: "개발자 프로필 평가하고\n나머지 일은 매니저에게\n맡겨주세요!",
				image: "img-talent-profile-rating",
			},
			{
				image: "icon-chat-and-phone",
				desc: "지원 의향 파악,\n면접 제안,\n면접 일정 조율 등\n전담 매니저를 통한\n효율적인 채용 진행",
			},
			{
				image: "img-cs-manager",
				title: "HR매니저\n최성원",
				chat1: "안녕하세요, 담당자님.\n임병현님 귀사 지원 의향이 있다\n하시는데 면접 일정 잡아드릴까요?",
				chat2: "네, 다음 주 중에 오후 2시 정도로 면접\n일정 조율 부탁드려요. 😄",
				chat3: "네, 알겠습니다. 😄\n희망 하시는 일정에 맞춰 개발자님과\n면접 일정 조율해 볼게요.",
			},
			{
				title: "채용 조건 조율",
				desc: "구직자의 희망 급여를 적정선으로\n맞추고, 연봉 외 기업의 장점을 활용해\n양측이 만족하는 합리적인 계약을\n성사시킵니다.",
				image: "icon-offer-nego",
			},
			{
				title: "디지털 계약",
				desc: "빠르고 간편하게 입사 전\n공식 근로 약정을 체결하여\n입사 번복을 예방합니다.",
				image: "img-emplyee-contract",
			},
		],
	},

	branding: {
		title: "채용 브랜딩",
		desc: "개발자에게\n매력적인 기업으로",
		content: [
			{
				image: "img-devteam-page-mobile",
				title: "개발자에게 매력적인\n개발팀 페이지를 만들어드려요.",
				desc: "구직자들에게 우리 개발팀을\n소개하고 사용 기술, 개발 방식,\n개발 문화 등을 이야기하세요.",
			},
			{
				image: "icon-advertizement",
				title: "지원자 모집에\n채용 페이지\n유입 광고 진행",
			},
			{
				image: "img-kakao-banner",
			},
		],
	},

	openscout: {
		title: "채용 캠페인",
		desc: "이렇게나 쉬운\n공개 채용 시작하기",
		content: [
			{
				image: "img-openscout-reference",
				title: "오픈스카우트 서비스를 통해\n간편하게 공개 채용을 진행해 보세요.",
			},
			{
				image: "icon-help-question",
				title: "오픈스카우트란?",
				desc: "오픈스카우트는 공개 채용을 진행함에 어려움이 있는 기업들을 위해 관련 페이지를 구축해 주고 운영해 주는 프리미엄 서비스입니다.(별도 비용 발생)",
			},
			{
				image: "icon-monitor-black-36",
				title: "공개 채용 페이지\n구축 대행",
				desc: "개발자들이 좋아하는 기업 정보와\n콘텐츠를 수집하고 재단하여\n고객사 맞춤형 공개 채용 페이지를\n만들어드려요.",
			},
			{
				image: "icon-gear-black-36",
				title: "채용 프로세스\n맞춤형 설계 및 운영",
				desc: "고객사가 원하는 방식에 맞춰\n효율적인 채용 프로세스를 설계해 주고\n운영해드립니다.",
			},
			{
				image: "icon-chat-black-36",
				title: "개발자 커뮤니티 대상\n채용 설명회 운영",
				desc: "개발자들과 직접 소통해 볼 수 있는 기회.\n보유한 인재풀과 커뮤니티 대상으로\n실시간 채용 설명회를 열어드립니다.",
			},
		],
	},
	pricing: {
		title: "개발자 영입은\n탤런트엑스와 함께",
		subtitle: "[2024년 기업 멤버십]",
		plans: [
			{
				key: "lightup",
				name: "라이트업",
				desc: "개발자 채용공고에 지원자가 많지 않아 대안 솔루션이 필요한 기업이 가볍게 시작하기 좋은 멤버십",
				prices: [
					"· 개발자 채용: 1명",
					// "· 채용 수수료: 무료",
					// "· 가입 보증금: 무료",
					"· 멤버십 요금: 300만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"개발자 1명 채용권",
				],
			},
			{
				key: "scaleup",
				name: "스케일업",
				desc: "고속 성장을 위해 빠르게 개발팀을 확장해야 하는 기업이 합리적인 가격으로 이용하기 좋은 멤버십",
				prices: [
					"· 개발자 채용: 3명",
					// "· 채용 수수료: 무료",
					// "· 가입 보증금: 무료",
					"· 멤버십 요금: 700만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"개발자 3명 채용권",
					"타겟 리크루팅(카카오톡 유료광고)",
				],
			},
			{
				key: "premium",
				name: "프리미엄",
				desc: "지속 성장을 위해 상시 다수의 고급 개발자를 영입해야 하는 기업이 편리하게 이용하기 좋은 멤버십",
				prices: [
					"· 개발자 채용: 무제한",
					// "· 채용 수수료: 무료",
					// "· 가입 보증금: 무료",
					"· 멤버십 요금: 3000만원/1년",
				],
				services: [
					"AI 기반 개발자 정기 추천",
					"개발자 프로필 PDF 문서 제공",
					"인터뷰 스케쥴러(대면/비대면)",
					"디지털 싸인(입사 전 근로계약)",
					"탤런트엑스 케어(3개월 근속관리)",
					"개발팀 중심 채용 페이지 제작",
					"개발자 무제한 채용권",
					"타겟 리크루팅(카카오톡 유료광고 + 커뮤니티)",
					"탤런트엑스 케어 플러스(+3개월 근속관리)",
					"채용 매니지먼트(전담 테크 리크루터 배정)",
					"프리미엄 추천(리더급, AI 등 고급 개발자)",
					"오픈스카우트(공개 채용 캠페인)",
				],
			},
		],
	},
	benefits: [
		{
			keyword: "쉽고 간편한 채용",
			title: "개발자를 찾는 건 어렵지만\n탤런트엑스에서는 쉽습니다",
			image: require("assets/benefit-easy.png"),
			image2x: require("assets/benefit-easy@2x.png"),
			image3x: require("assets/benefit-easy@3x.png"),
			desc: [
				"찾아 다니는 수고 없이 우리 회사에 알맞은\n개발자를 추천 받으세요.",
			],
		},
		{
			keyword: "시간 단축",
			title: "채용에 소요되는\n시간과 비용을 절약하세요",
			image: require("assets/benefit-fast.png"),
			image2x: require("assets/benefit-fast@2x.png"),
			image3x: require("assets/benefit-fast@3x.png"),
			icon: require("assets/icon-down-arrow.png"),
			desc: [
				"테스트를 통해 검증된 인재풀",
				"인재를 직접 찾을 필요 X",
				"인재 프로필 열람 비용 X",
				"편리한 온라인 면접 예약 시스템",
				"담당 매니저가 채용 조건 조율",
			],
			message: "탤런트엑스는 SW인재 채용에\n가장 효과적인 솔루션입니다.",
		},
	],
	features: [
		{
			title: "매주 우리 회사에 알맞은\n새로운 개발자를 추천 받으세요",
			image: require("assets/feature-recommedation.png"),
			image2x: require("assets/feature-recommedation@2x.png"),
			image3x: require("assets/feature-recommedation@3x.png"),
			message:
				"찾아 다니는 수고 없이 매주 새로운\n개발자를 추천 받을 수 있어요.",
		},
		{
			title: "매주 우리 회사에 알맞은\n새로운 개발자를 추천 받으세요",
			image: require("assets/feature-talent-profile.png"),
			image2x: require("assets/feature-talent-profile@2x.png"),
			image3x: require("assets/feature-talent-profile@3x.png"),
			message:
				"개발자 전용 프로필을 통해\n기술과 프로젝트 경험을 확인하세요.",
		},
		{
			title: "매주 우리 회사에 알맞은\n새로운 개발자를 추천 받으세요",
			image: require("assets/feature-recommedation-setting.png"),
			image2x: require("assets/feature-recommedation-setting@2x.png"),
			image3x: require("assets/feature-recommedation-setting@3x.png"),
			message:
				"채용 희망 포지션와 요건을 설정하면\n알맞은 개발자를 자동으로 추천합니다.",
		},
	],
	steps: [
		{
			title: "마음에 드는 개발자에게\n면접을 제안하세요",
			image: require("assets/feature-interview-offer.png"),
			image2x: require("assets/feature-interview-offer@2x.png"),
			image3x: require("assets/feature-interview-offer@3x.png"),
			message:
				"인재의 취업 활동 현황을 확인하고\n가능 일정에 면접을 제안하세요.",
		},
		{
			title: "마음에 드는 개발자에게\n면접을 제안하세요",
			image: require("assets/feature-interview-reminder.png"),
			image2x: require("assets/feature-interview-reminder@2x.png"),
			image3x: require("assets/feature-interview-reminder@3x.png"),
			message:
				"혹여 면접 시간을 잊을까 걱정하지마세요.\n면접이 가까워지면 알려드려요.",
		},
		{
			title: "마음에 드는 개발자에게\n면접을 제안하세요",
			image: require("assets/feature-interview-pass.png"),
			image2x: require("assets/feature-interview-pass@2x.png"),
			image3x: require("assets/feature-interview-pass@3x.png"),
			message: "면접 이후 합격 통보도\n온라인에서 간편하게!",
		},
	],
	manager: {
		keyword: "전담 매니저",
		title: "합격한 개발자의 채용 조건을\n매니저가 대신 조율해드립니다",
		image: require("assets/talentx-manager.png"),
		image2x: require("assets/talentx-manager@2x.png"),
		image3x: require("assets/talentx-manager@3x.png"),
		desc: [
			"채용 희망 조건\n확인 및 조정",
			"근로 계약 조건\n조율 대행",
			"온라인\n채용 계약",
		],
	},

	qna: {
		title: "자주 묻는 질문",
		faqs: [
			{
				question: "기업 회원 가입 요건이 있나요?",
				html: {
					__html: `<b>가입 요건은 없습니다. 채용 수요에 맞는 멤버십을 선택하시면 되고, 멤버십별 권장 기업은 다음과 같습니다.</b><br><br>- 라이트업: 임직원 수 10명 이상, 개발자 수 3명 이상, 매출/투자규모 3억원 이상<br>- 스케일업: 임직원 수 30명 이상, 개발자 수 10명 이상, 매출/투자규모 10억원 이상<br>- 프리미엄: 임직원 수 100명 이상, 개발자 수 30명 이상, 매출/투자규모 30억원 이상`,
				},
			},
			{
				question: "체험 사용이 가능한가요?",
				answer: "실제 개발자 한명을 채용하는 데 평균적으로 2~3개월 가량 소요되기 때문에 단기간 체험 서비스는 제공하고 있지 않습니다. 그 대신 도입 검토를 위한 서비스 데모를 화상 미팅으로 제공하고 있습니다.",
			},
			{
				question: "인재를 조건별로 검색할 수 있나요?",
				answer: "검색 기능은 제공하지 않습니다. 그 대신 귀사의 채용 니즈에 가장 적합한 개발자를 선별하여 매주 추천해드리기 때문에 직접 검색하고 찾는 수고를 덜으실 수 있습니다.",
			},
			{
				question: "멤버십 이용 중 해지가 가능한가요?",
				answer: "채용 실적이 전혀 없는 경우라면 이용 기간 중 언제든 해지가 가능하며, 이용 기간에 상관 없이 멤버십 정가의 70%를 환불해 드립니다.",
			},
			{
				question:
					"멤버십 기간 만료까지 채용을 못한 경우 어떻게 되나요?",
				answer: "멤버십 이용 기간 동안 채용 실적이 없었던 경우, 이용한 멤버십 정가의 70% 환불 혹은 멤버십 기간을 1년 연장해 드립니다.",
			},
			{
				question:
					"멤버십 제공 채용 인원 외 추가 채용이 필요하면 어떻게 해야 하나요?",
				answer: "추가 채용 규모에 따라 적합한 멤버십을 추가 구매하거나 상위 멤버십으로 업그레이드 후 이용하실 수 있습니다.",
			},
		],
	},
};

export const talentData = {
	proposition: "개발자 이직에 왜\n탤런트엑스가 필요할까요?",
	values: [
		{
			image: require("assets/value-proposition-great-company.png"),
			image2x: require("assets/value-proposition-great-company@2x.png"),
			image3x: require("assets/value-proposition-great-company@3x.png"),
			title: "사업성이 검증된 기업",
			desc: "사전 검증 과정을 통해 사업성이 검증된\nIT기업을 플랫폼에 참여시킵니다.",
		},
		{
			image: require("assets/value-proposition-curation.png"),
			image2x: require("assets/value-proposition-curation@2x.png"),
			image3x: require("assets/value-proposition-curation@3x.png"),
			title: "개인 맞춤형 기업 추천",
			desc: "개인별 프로필 데이터에 기반하여\n알맞은 기업에 귀하를 추천합니다.",
		},
		{
			image: require("assets/value-proposition-salary.png"),
			image2x: require("assets/value-proposition-salary@2x.png"),
			image3x: require("assets/value-proposition-salary@3x.png"),
			title: "연봉 및 채용조건 조율 대행",
			desc: "최종 합격 시 연봉 및 채용조건은\n탤런트엑스가 대신 조율해드립니다.",
		},
	],
	benefits: [
		// {
		//     keyword: "쉽고 간편한 취업/이직",
		//     title: "일할 곳을 찾는 건 힘들지만\n탤런트엑스는 간편합니다",
		//     image: require("assets/talent-benefit-easy.png"),
		//     image2x: require("assets/talent-benefit-easy@2x.png"),
		//     image3x: require("assets/talent-benefit-easy@3x.png"),
		//     desc: ["진정 나를 윈하는 기업들의 오퍼를\n간편하게 받아보세요."],
		// },
		{
			keyword: "시간 단축",
			title: "이직에 드는\n시간과 부담을 덜어줍니다",
			image: require("assets/benefit-fast.png"),
			image2x: require("assets/benefit-fast@2x.png"),
			image3x: require("assets/benefit-fast@3x.png"),
			icon: require("assets/icon-down-arrow.png"),
			icon2x: require("assets/icon-down-arrow@2x.png"),
			icon3x: require("assets/icon-down-arrow@3x.png"),
			desc: [
				"채용 공고에 지원할 필요 X",
				"프로필 한번만 등록하면 OK!",
				"편리한 온라인 면접 예약 시스템",
				"전담 매니저가 근로 조건 조율",
			],
			message: "탤런트엑스는 개발자 이직에\n가장 효과적인 솔루션입니다.",
		},
	],
	features: [
		// {
		//     title: "가만히 있어도\n면접 제안이 오는 탤런트엑스",
		//     image: require("assets/feature-incoming-offer.png"),
		//     image2x: require("assets/feature-incoming-offer@2x.png"),
		//     image3x: require("assets/feature-incoming-offer@3x.png"),
		//     message: "탤런트엑스는 일할 곳을 찾는데\n가장 간편한 서비스입니다.",
		// },
		// {
		//     title: "매주 새로운\n면접 제안을 받아보세요",
		//     image: require("assets/feature-regular-basis-offer.png"),
		//     image2x: require("assets/feature-regular-basis-offer@2x.png"),
		//     image3x: require("assets/feature-regular-basis-offer@3x.png"),
		//     subtitle: "프로필 한번만 등록하면 OK!",
		//     message:
		//         "프로필만 작성하면 이력서/포트폴리오가 없어도\n면접 제안을 받을 수 있습니다.",
		// },
	],
	steps: [
		{
			title: "채용 담당자들이 보낸\n면접 제안을 검토하세요",
			image: require("assets/talent-feature-interview-offer.png"),
			image2x: require("assets/talent-feature-interview-offer@2x.png"),
			image3x: require("assets/talent-feature-interview-offer@3x.png"),
			message:
				"기업들이 보낸 면접 제안을 확인하고\n원하는 제안에 응답하세요.",
		},
		{
			title: "채용 담당자들이 보낸\n면접 제안을 검토하세요",
			image: require("assets/talent-feature-interview-reschedule.png"),
			image2x: require("assets/talent-feature-interview-reschedule@2x.png"),
			image3x: require("assets/talent-feature-interview-reschedule@3x.png"),
			message: "면접 일정 조정이 필요할 경우\n일정 변경을 요청하세요.",
		},
		{
			title: "채용 담당자들이 보낸\n면접 제안을 검토하세요",
			image: require("assets/talent-feature-interview-reminder.png"),
			image2x: require("assets/talent-feature-interview-reminder@2x.png"),
			image3x: require("assets/talent-feature-interview-reminder@3x.png"),
			message:
				"혹여 면접 시간을 잊을까 걱정하지마세요.\n면접이 가까워지면 알려드려요.",
		},
	],
	manager: {
		keyword: "전담 매니저",
		title: "합격 시 연봉과 근로 조건은\n매니저가 대신 조율해드립니다",
		image: require("assets/talentx-manager.png"),
		image2x: require("assets/talentx-manager@2x.png"),
		image3x: require("assets/talentx-manager@3x.png"),
		desc: [
			"희망 근로 조건\n확인 및 조정",
			"근로 계약 조건\n조율 대행",
			"온라인\n근로 계약",
		],
	},
	talentReview: {
		title: "탤런트엑스로 이직에\n성공한 개발자들의 이용 후기",
		reviews: [
			{
				client: "백엔드 엔지니어 (주니어)",
				clientLogo: "logo_rawlabs",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"기업에서 기술과 경험이 맞는 사람을 찾아 먼저 제안을 하는 방식이라 원티드나 잡코리아 같은 여타 플랫폼으로 제가 직접 지원했을 때 보다 제 이력서를 더 많이 검토한 것으로 보여 좋았습니다.",
			},
			{
				client: "백엔드 엔지니어 (주니어)",
				clientLogo: "logo_rawlabs",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"처음부터 기업이 저에 대한 호감을 가지고 있다는 베이스로 면접을 시작하니 마음이 편했습니다.",
			},
			{
				client: "풀스택 엔지니어 (개발팀장)",
				clientLogo: "logo-flitto",
				rating: 4,
				ratingDesc: "만족해요",
				content:
					"희망하는 요구 사항에 맞춰 계약을 성사시켜주었고, 과정이 빠르게 진행되는 점이 만족스러웠습니다.",
			},
			{
				client: "풀스택 엔지니어 (주니어)",
				clientLogo: "logo-wecode",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"저 대신 기업과 면접 일정을 잡아주고 채용 조건을 조율해 주시는 부분이 너무 편하고 좋았습니다.",
			},
			{
				client: "머신러닝 엔지니어 (신입)",
				clientLogo: "logo-whosgood",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"신입이라 제안을 받지 못할 것이라 생각했는데 탤런트엑스에서 제 이력과 딱 알맞은 기업을 매칭 시켜주었습니다.",
			},
			{
				client: "백엔드 엔지니어 (시니어)",
				clientLogo: "logo-flitto",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"주변 개발자들이 아직 탤런트엑스를 잘 모르는 거 같아요. 제가 아는 다른 개발자들한테도 탤런트엑스를 소개하고 싶어요.",
			},
			{
				client: "백엔드 엔지니어 (전문연구요원)",
				clientLogo: "logo-archidraw",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"채용 과정이 빠르게 진행될 수 있도록 도와주고 저의 요구사항에 맞춰 기업과 채용 조건을 조율을 통해 계약을 성사시켜준 점이 좋았습니다.",
			},
			{
				client: "풀스택 엔지니어 (시니어)",
				clientLogo: "logo-whosgood",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"사전 검증을 거쳐 가입하는 방식에서 서비스에 대한 신뢰가 생겼고, 추천 기업에 대한 정보를 상세하게 제공해주는 부분이 가장 좋았습니다.",
			},
			{
				client: "프론트 엔지니어 (신입)",
				clientLogo: "logo-soma",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"매주 유망 기업에게 자동으로 추천해주는 매칭 시스템이 신기하고 매력적이었습니다.",
			},
		],
	},
	qna: {
		title: "자주 묻는 질문",
		faqs: [
			{
				question: "SW인재 회원 신청 및 가입 방법이 궁금합니다.",
				html: {
					__html: `SW인재 회원 신청을 위해서는 아래 조건 중 1개 이상을 충족해야 합니다.<br><br><b>&#8226  SW분야 전공 전문학사학위 이상 소지자 혹은, 6개월 내 취득 예정자</b><br><b>&#8226  SW분야 전문 교육 프로그램 이수자 (예: SW마에스트로, SSAFY, 42서울 등)</b><br><b>&#8226  현재 기업에 재직 중이거나 프리랜서로 활동 중인 현업 개발자</b><br><br>상기 조건 중 하나 이상 만족하는 경우 [SW인재 회원 신청] 페이지에서 신청 정보를 입력한 뒤 제출해주세요. 담당자 확인 후 SW테스트를 안내 드리며 통과시에만 가입이 가능합니다.`,
				},
				answer: "",
			},
			{
				question: "회원 가입비가 별도로 있나요?",
				answer: "SW인재 회원 가입시 별도의 비용은 없습니다.",
			},
			{
				question: "서비스 이용에 불편한 점이 있습니다.",
				answer: "앱에서 [내 정보 > 고객센터]에서 편하신 방법을 이용해 불편을 신고해주세요. 담당자 확인 후 빠른 시일 내에 연락 드리도록 하겠습니다.\n\n고객센터 업무는 평일 오전 9시부터 오후 6시까지이며 점심시간에는 응대가 다소 지연될 수 있습니다.",
			},
		],
	},
};

export const talentDataD = {
	proposition: "개발자 이직에 왜\n탤런트엑스가 필요할까요?",
	values: [
		{
			image: require("assets/img-feature-company-200.png"),
			image2x: require("assets/img-feature-company-200@2x.png"),
			image3x: require("assets/img-feature-company-200@3x.png"),
			title: "사업성이 검증된 기업",
			desc: "사전 검증 과정을 통해 사업성이 검증된\nIT기업을 플랫폼에 참여시킵니다.",
		},
		{
			image: require("assets/img-feature-curation-200.png"),
			image2x: require("assets/img-feature-curation-200@2x.png"),
			image3x: require("assets/img-feature-curation-200@3x.png"),
			title: "개인 맞춤형 기업 추천",
			desc: "개인별 프로필 데이터에 기반하여\n알맞은 기업에 귀하를 추천합니다.",
		},
		{
			image: require("assets/img-feature-salary-200.png"),
			image2x: require("assets/img-feature-salary-200@2x.png"),
			image3x: require("assets/img-feature-salary-200@3x.png"),
			title: "연봉 및 채용조건 조율 대행",
			desc: "최종 합격 시 연봉 및 채용조건은\n탤런트엑스가 대신 조율해드립니다.",
		},
	],
	benefits: [
		{
			keyword: "쉽고 간편한 취업/이직",
			title: "일할 곳을 찾는 건 힘들지만\n탤런트엑스는 간편합니다",
			image: require("assets/img-mobile-offer.png"),
			image2x: require("assets/img-mobile-offer@2x.png"),
			image3x: require("assets/img-mobile-offer@3x.png"),
			desc: [
				"채용공고를 찾아 지원하지 않아도\n기업들로부터 면접 제안을 받을 수 있습니다.",
			],
		},
		{
			keyword: "시간 단축",
			title: "이직에 드는\n시간과 부담을 덜어줍니다",
			image: require("assets/img-clock.png"),
			image2x: require("assets/img-clock@2x.png"),
			image3x: require("assets/img-clock@3x.png"),
			icon: require("assets/icon-down-arrow.png"),
			icon2x: require("assets/icon-down-arrow@2x.png"),
			icon3x: require("assets/icon-down-arrow@3x.png"),
			desc: [
				"채용 공고에 지원할 필요 X",
				"프로필 한번만 등록하면 OK!",
				"편리한 온라인 면접 예약 시스템",
				"담당 매니저가 근로 조건 조율",
			],
			caption: "담당 매니저 배정",
			message: "탤런트엑스는 개발자 취업에\n가장 효과적인 솔루션입니다.",
			arrow: require("assets/icon-nav-chevron-down-24.png"),
		},
	],
	offer: {
		title: "가만히 있어도\n면접 제안이 오는 탤런트엑스",
		desc: "채용공고를 찾아 지원하지 않아도\n기업들로부터 면접 제안을 받을 수 있습니다.",
		image: require("assets/img-coming-offers.png"),
		image2x: require("assets/img-coming-offers@2x.png"),
		image3x: require("assets/img-coming-offers@3x.png"),
	},
	features: [
		{
			title: "매주 새로운\n면접 제안을 받아보세요",
			subtitle: "프로필 한번만 등록하면 OK!",
			image: require("assets/img-function-offer.png"),
			image2x: require("assets/img-function-offer@2x.png"),
			image3x: require("assets/img-function-offer@3x.png"),
			message:
				"프로필만 작성하면 이력서/포트폴리오가 없어도\n면접 제안을 받을 수 있습니다.",
		},
		{
			title: "매주 월요일 오전 8시,\n새로운 SW인재를 만나는 시간",
			image: require("assets/feature-talent-profile.png"),
			image2x: require("assets/feature-talent-profile@2x.png"),
			image3x: require("assets/feature-talent-profile@3x.png"),
			message: "SW인재 프로필 열람을 통해\n인재의 기술역량을 확인하세요.",
		},
		{
			title: "매주 월요일 오전 8시,\n새로운 SW인재를 만나는 시간",
			image: require("assets/feature-recommedation-setting.png"),
			image2x: require("assets/feature-recommedation-setting@2x.png"),
			image3x: require("assets/feature-recommedation-setting@3x.png"),
			message:
				"채용을 희망하는 포지션을 설정하면\n해당 포지션에 알맞은 인재를 추천합니다.",
		},
	],
	steps: [
		{
			title: "채용 담당자들이 보낸\n면접 제안을 검토하세요",
			buttons: ["받은 제안", "일정 변경", "면접 알림"],
			image: require("assets/img-function-new-offer.png"),
			image2x: require("assets/img-function-new-offer@2x.png"),
			image3x: require("assets/img-function-new-offer@3x.png"),
			message:
				"기업들이 보낸 면접 제안을 확인하고\n원하는 제안에 응답하세요.",
		},
		{
			title: "마음에 드는 인재에게\n면접을 제안하세요",
			image: require("assets/img-app-interview-rescheduling.png"),
			image2x: require("assets/img-app-interview-rescheduling@2x.png"),
			image3x: require("assets/img-app-interview-rescheduling@3x.png"),
			message: "면접 일정 조정이 필요할 경우 일정 변경을 요청하세요.",
		},
		{
			title: "마음에 드는 인재에게\n면접을 제안하세요",
			image: require("assets/img-talent-app-interview-reminding.png"),
			image2x: require("assets/img-talent-app-interview-reminding@2x.png"),
			image3x: require("assets/img-talent-app-interview-reminding@3x.png"),
			message:
				"혹여 면접 시간을 잊을까 걱정하지마세요.\n면접이 가까워지면 알려드려요.",
		},
	],
	manager: {
		keyword: "전담 매니저",
		title: "합격 시 연봉과 근로 조건은\n매니저가 대신 조율해드립니다",
		image: require("assets/img-talent-manager-desktop.png"),
		image2x: require("assets/img-talent-manager-desktop@2x.png"),
		image3x: require("assets/img-talent-manager-desktop@3x.png"),
		desc: [
			"희망 근로 조건\n확인 및 조정",
			"근로 계약 조건\n조율 대행",
			"온라인\n근로 계약",
		],
	},
	talentReview: {
		title: "탤런트엑스로 이직에\n성공한 개발자들의 이용 후기",
		reviews: [
			{
				client: "백엔드 엔지니어 (주니어)",
				clientLogo: "logo_rawlabs",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"기업에서 기술과 경험이 맞는 사람을 찾아 먼저 제안을 하는 방식이라 원티드나 잡코리아 같은 여타 플랫폼으로 제가 직접 지원했을 때 보다 제 이력서를 더 많이 검토한 것으로 보여 좋았습니다.",
			},
			{
				client: "백엔드 엔지니어 (주니어)",
				clientLogo: "logo_rawlabs",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"처음부터 기업이 저에 대한 호감을 가지고 있다는 베이스로 면접을 시작하니 마음이 편했습니다.",
			},
			{
				client: "풀스택 엔지니어 (개발팀장)",
				clientLogo: "logo-flitto",
				rating: 4,
				ratingDesc: "만족해요",
				content:
					"희망하는 요구 사항에 맞춰 계약을 성사시켜주었고, 과정이 빠르게 진행되는 점이 만족스러웠습니다.",
			},
			{
				client: "풀스택 엔지니어 (주니어)",
				clientLogo: "logo-wecode",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"저 대신 기업과 면접 일정을 잡아주고 채용 조건을 조율해 주시는 부분이 너무 편하고 좋았습니다.",
			},
			{
				client: "머신러닝 엔지니어 (신입)",
				clientLogo: "logo-whosgood",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"신입이라 제안을 받지 못할 것이라 생각했는데 탤런트엑스에서 제 이력과 딱 알맞은 기업을 매칭 시켜주었습니다.",
			},
			{
				client: "백엔드 엔지니어 (시니어)",
				clientLogo: "logo-flitto",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"주변 개발자들이 아직 탤런트엑스를 잘 모르는 거 같아요. 제가 아는 다른 개발자들한테도 탤런트엑스를 소개하고 싶어요.",
			},
			{
				client: "백엔드 엔지니어 (전문연구요원)",
				clientLogo: "logo-archidraw",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"채용 과정이 빠르게 진행될 수 있도록 도와주고 저의 요구사항에 맞춰 기업과 채용 조건을 조율을 통해 계약을 성사시켜준 점이 좋았습니다.",
			},
			{
				client: "풀스택 엔지니어 (시니어)",
				clientLogo: "logo-whosgood",
				rating: 4,
				ratingDesc: "만족해요!",
				content:
					"사전 검증을 거쳐 가입하는 방식에서 서비스에 대한 신뢰가 생겼고, 추천 기업에 대한 정보를 상세하게 제공해주는 부분이 가장 좋았습니다.",
			},
			{
				client: "프론트 엔지니어 (신입)",
				clientLogo: "logo-soma",
				rating: 5,
				ratingDesc: "매우 만족스러워요!",
				content:
					"매주 유망 기업에게 자동으로 추천해주는 매칭 시스템이 신기하고 매력적이었습니다.",
			},
		],
	},
	qna: {
		title: "자주 묻는 질문",
		faqs: [
			{
				question: "SW인재 회원 신청 및 가입 방법이 궁금합니다.",
				html: {
					__html: `SW인재 회원 신청을 위해서는 아래 조건 중 1개 이상을 충족해야 합니다.<br><br><b>&#8226  SW분야 전공 전문학사학위 이상 소지자 혹은, 6개월 내 취득 예정자</b><br><b>&#8226  SW분야 전문 교육 프로그램 이수자 (예: SW마에스트로, SSAFY, 42서울 등)</b><br><b>&#8226  현재 기업에 재직 중이거나 프리랜서로 활동 중인 현업 개발자</b><br><br>상기 조건 중 하나 이상 만족하는 경우 [SW인재 회원 신청] 페이지에서 신청 정보를 입력한 뒤 제출해주세요. 담당자 확인 후 SW테스트를 안내 드리며 통과시에만 가입이 가능합니다.`,
				},
				answer: "",
			},
			{
				question: "회원 가입비가 별도로 있나요?",
				answer: "SW인재 회원 가입시 별도의 비용은 없습니다.",
			},
			{
				question: "서비스 이용에 불편한 점이 있습니다.",
				answer: "앱에서 [내 정보 > 고객센터]에서 편하신 방법을 이용해 불편을 신고해주세요. 담당자 확인 후 빠른 시일 내에 연락 드리도록 하겠습니다.\n\n고객센터 업무는 평일 오전 9시부터 오후 6시까지이며 점심시간에는 응대가 다소 지연될 수 있습니다.",
			},
		],
	},
	actionBtn: {
		title: "개발자 멤버십 신청",
		desc: "이직 성공 시 로열티 50만원!",
		url: "/talent-membership",
	},
};
