import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = withStyles({
    root: {
        color: "#3880ff",
        height: 2,
        padding: "15px 0",
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: "#fff",
        border: "solid 1px #c5c5c5",
        //boxShadow: iOSBoxShadow,
        marginTop: -9,
        marginLeft: -14,
        // "&:focus, &:hover, &$active": {
        //     boxShadow:
        //         "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        //     // Reset on touch devices, it doesn't add specificity
        //     "@media (hover: none)": {
        //         boxShadow: iOSBoxShadow,
        //     },
        // },
    },
    active: {},
    valueLabel: {
        fontSize: 12,
        left: "calc(-50% + 9px)",
        borderRadius: 3,
        // top: -22,
        // "& *": {
        //     background: "transparent",
        //     color: "#000",
        // },
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
        opacity: 0.5,
        backgroundColor: "#eeeeee",
        borderRadius: 8,
    },
    mark: {
        backgroundColor: "transparent",
        height: 10,
        width: 1,
        //marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: "currentColor",
    },
    markLabel: {
        top: 40,
        transform: "translateX(0%)",
        "&:nth-child(7)": {
            transform: "translateX(-100%)",
        },
    },
})(Slider);

export default IOSSlider;
