import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Box,
    Typography,
    Divider,
    Link,
    Grid,
    Paper,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import wallpaper from "assets/wallpaper-salary-highway.png";
import banner from "assets/message-promotion-title.png";
import rocket from "assets/image-rocket.png";
import promotionTitle from "assets/message-promotion.png";
import promotionSubTitle from "assets/message-promotion-subtitle.png";
import moneyStack from "assets/image-money-stack.png";
import imgPromotionalMsg from "assets/img-promotional-message.png";
import imgPromisingCompany from "assets/img-promising-company.png";
import iconDoubleQuotes from "assets/icon-double-quotes.png";
import iconChevronDown from "assets/icon-nav-chevron-down-20.png";
import imgMKTBanner from "assets/img-mkt-banner.png";
import icNo from "assets/ic-no.png";
import ilResume from "assets/il-resume.png";
import ilPorfolio from "assets/il-porfolio.png";
import imgPromotionalMsg0 from "assets/img-promotional-message-0.png";
import useScroll from "hooks/useScroll";
import { createKakaoShareButton } from "apis/kakao/share";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#010019",
    },
    skeleton: {
        position: "fixed",
        zIndex: 6,
        left: 0,
        top: 0,
        width: "100%",
    },
    phanthomImage: (props) => ({
        position: "fixed",
        zIndex: 1,
        width: "100%",
        display: "block",
        maxWidth: 444,
        objectFit: "cover",
        objectPosition: "center bottom",
        bottom: props.mainImgHeight
            ? window.innerHeight <=
              props.mainImgHeight - Math.max(0, props.scrollY)
                ? -Math.max(0, props.scrollY)
                : -(
                      (props.mainImgHeight - window.innerHeight) * 2 -
                      props.scrollY
                  )
            : 0,
        willChange: "bottom",
        opacity: props.mainImgHeight
            ? window.innerHeight / 2 <=
              props.mainImgHeight - Math.max(0, props.scrollY)
                ? 1
                : 0
            : 1,
        transition: "opacity 1s linear",
        //visibility: "hidden",
    }),
    rocketBox: (props) => ({
        position: "fixed",
        width: "100%",
        maxWidth: 444,
        zIndex: 3,
        bottom: props.mainImgHeight
            ? window.innerHeight <=
              props.mainImgHeight - Math.max(0, props.scrollY)
                ? 0
                : Math.max(0, props.scrollY) -
                  props.mainImgHeight +
                  window.innerHeight
            : 0,
        paddingTop: 60,
        textAlign: "center",
        willChange: "bottom",
        opacity: props.mainImgHeight
            ? window.innerHeight / 2 <=
              props.mainImgHeight - Math.max(0, props.scrollY)
                ? 1
                : 0
            : 1,
        transition: "opacity 1s linear",
        //transition: "bottom 0.1s linear",
    }),
    banner: {
        marginBottom: 40,
    },
    rocket: {},
    body: (props) => ({
        width: "100%",
        paddingTop: props.mainImgHeight ? props.mainImgHeight + 300 : 2500,
        //backgroundColor: "#010019",
    }),
    tailer: {
        position: "relative",
        zIndex: 2,
        width: "100%",
        borderRadius: 20,
        backgroundColor: "white",
        marginTop: 0,
        padding: "1px 20px",
        textAlign: "center",
        "& > img": {
            display: "block",
            margin: "80px auto 32px",
        },
    },
    promotionBox: {
        margin: "24px auto",
        border: "solid 1px #bbbbbb",
        borderRadius: 20,
        "& > img": {
            display: "block",
            margin: "32px auto",
        },
    },
    divider: {
        width: 34,
        height: 2,
        backgroundColor: "#222222",
        margin: "0px auto",
    },
    introduction: {
        color: "#999999",
        fontSize: 12,
        fontWeight: 300,
        marginTop: 20,
        marginBottom: 24,
    },
    companyCard: {
        display: "flex",
        alignItems: "center",
        border: "solid 1px #bbbbbb",
        borderRadius: 16,
        padding: "24px 20px 24px 24px",
        marginBottom: 12,
    },
    companyLogo: {
        width: 46,
        height: 46,
        backgroundColor: "#dddddd",
        border: "solid 1px #dddddd",
        borderRadius: 45,
    },
    companyInfo: {
        flex: "1 0 0",
        textAlign: "left",
        margin: "auto 15px",
    },
    companyName: {
        fontSize: 16,
        fontWeight: "bold",
    },
    companyFeature: {
        fontSize: 14,
        fontWeight: 500,
    },
    companyDetailBtn: {
        padding: "6px 12px",
        border: "solid 1px #444444",
        borderRadius: 15,
        fontSize: 12,
        fontWeight: 500,
    },
    question: {
        color: "#222222",
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: 1.33,
    },
    promotionCard: {
        margin: "24px auto 64px",
        padding: 32,
        border: "solid 1px #bbbbbb",
        borderRadius: 20,
    },
    font14Bold: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#222222",
    },
    fs14fw500: {
        fontSize: 14,
        fontWeight: 500,
        color: "#222222",
    },
    flexbox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    resumeAndPortfolioBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    portfolioBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icNo: {
        marginLeft: 4,
    },
    benefit: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 32,
    },
    iconDoubleQuotes: {
        marginTop: 54,
    },
    iconChevronDown: {
        marginTop: 4,
    },
    cover: {
        position: "absolute",
        display: "block",
        height: 50,
        width: "100%",
        left: 0,
        bottom: 0,
        backgroundColor: "#ffffff",
    },
    fixedBtnsBox: {
        position: "fixed",
        bottom: 0,
        zIndex: 4,
        width: "100%",
        maxWidth: 444,
    },
    fixedTopBtnsBox: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: 18,
    },
    fixedTopLeftBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff",
        border: "solid 1px #bbbbbb",
        borderRadius: 16,
        padding: "6px 12px",
        marginRight: 8,
        fontSize: 12,
        fontWeight: 500,
        color: "#bbbbbb",
        lineHeight: 1.67,
    },
    fixedTopRightBtn: {
        width: 32,
        height: 32,
        marginRight: 16,
        padding: 6,
        border: "solid 1px #bbbbbb",
        borderRadius: 45,
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fixedBottomBtnsBox: {
        position: "relative",
        width: "100%",
        backgroundColor: "#333435",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        textAlign: "center",
    },
    fixedBottomLeftBtn: {
        flex: 1,
        fontSize: 14,
        fontWeight: 500,
        color: "#222222",
        backgroundColor: "#ffd646",
        height: 52,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 8,
        borderRadius: 4,
        textDecoration: "none",
    },
    fixedBottomRightBtn: {
        flex: 2,
        fontSize: 14,
        fontWeight: "bold",
        color: "#ffffff",
        backgroundColor: "#ff6438",
        height: 52,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        textDecoration: "none",
    },
    section: {
        position: "relative",
        zIndex: 2,
        width: "100%",
        textAlign: "center",
        backgroundColor: "#ffffff",
    },
    featureBox: {
        position: "relative",
        backgroundColor: "#24429a",
        width: "100%",
        paddingTop: 64,
        paddingBottom: 64,
    },
    featureMsg: {
        color: "#ffffff",
        fontSize: 16,
        fontWeight: 500,
        opacity: 0.41,
        marginBottom: 64,
    },
    featureImg: {
        animation: "$fadein 3000ms",
    },
    "@keyframes fadein": {
        "0%": {
            opacity: 0,
            //transform: "translateX(-200%)",
        },
        "100%": {
            opacity: 1,
            //transform: "translateX(0)",
        },
    },
    prevSlideBtn: {
        position: "absolute",
        zIndex: 1,
        top: "50%",
        left: "5%",
    },
    nextSlideBtn: {
        position: "absolute",
        zIndex: 1,
        top: "50%",
        right: "5%",
    },
    problemBox: {
        height: 144,
        backgroundColor: "#ffffff",
    },
    problemImg: {
        position: "relative",
        top: -10,
    },
    actionBox: {
        height: 280,
        backgroundImage: "linear-gradient(to bottom , #ffffff, #94cbff)",
        paddingTop: 45,
        paddingBottom: 52,
        "& > img": {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    actionImg: {
        marginBottom: 51,
    },
    applyBox: {
        position: "relative",
        //    top: -20,
        backgroundColor: "#ffffff",
        //height: 600,
        textAlign: "center",
        //    borderRadius: "20px 20px 0px 0px",
        paddingTop: 150,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 66,
        "& > img": {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    applyBtnImg: (props) => ({
        position: "absolute",
        zIndex: 1,
        bottom: 450,
        left: "50%",
        transform: "translate(-50%, 0)",
        opacity: props.clicked ? 0 : 1,
        transition: "opacity 0.1s ease-out",
    }),
    applyBtnClickedImg: (props) => ({
        // position: "relative",
        // top: -50,
        position: "absolute",
        zIndex: 0,
        bottom: 450,
        left: "50%",
        transform: "translate(-50%, 0)",
    }),
    reassurance: {
        marginTop: 64,
        marginBottom: 30,
    },
    leftRoundBox: {
        width: 152,
        height: 152,
        backgroundColor: "#efefef",
        borderRadius: 90,
    },
    rightRoundBox: {
        position: "relative",
        width: 152,
        height: 152,
        backgroundColor: "#061d5e",
        borderRadius: 90,
    },
    leftRoundBoxTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: "#4e4e4e",
    },
    leftRoundBoxFigure: {
        fontSize: 28,
        fontWeight: "bold",
        color: "#3c3c3c",
    },
    rightRoundBoxTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: "#58d4ff",
    },
    rightRoundBoxFigure: {
        fontSize: 28,
        fontWeight: "bold",
        color: "#58d4ff",
    },
    timeSaving: {
        position: "absolute",
        top: -10,
        right: 0,
    },
    posterBox: {
        position: "relative",
        backgroundColor: "#ffffff",
        width: "100%",
        textAlign: "center",
    },
    posterBG: {
        position: "relative",
        display: "block",
        width: "100%",
    },
    posterTitle: {
        position: "absolute",
        top: 62,
        fontSize: 24,
        fontWeight: "bold",
        color: "#ffffff",
        width: "100%",
        lineHeight: 1.33,
    },
    posterFG: { position: "absolute", right: 0, bottom: 0, width: "100%" },
    reasonBox: {
        backgroundColor: "#ffffff",

        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 20,
        paddingRight: 20,

        //height: 600,
    },
    reasonCard: {
        marginTop: 32,
        marginBottom: 20,
        borderRadius: 20,
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        textAlign: "left",
        //height: 440,
    },
    reasonCardTop: {
        position: "relative",
        zIndex: 0,
        width: "100%",
        borderRadius: "20px 20px 0px 0px",
        //height: "80%",
        padding: 18,

        backgroundImage: "linear-gradient(to bottom , #3a3b41, #1030ff)",
    },
    reasonCardImg: {
        position: "absolute",
        zIndex: -1,
        width: "auto",
        marginLeft: 18,
        marginRight: 18,
        left: 0,
        bottom: 0,
    },
    reasonLabel: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#ffffff",
        display: "inline-block",
        padding: "4px 12px",
        borderRadius: 14,
        backgroundColor: "#2f64ff",
    },
    reasonMsgImg: {
        display: "block",
        marginTop: 24,
        marginBottom: 156,
    },
    reasonManagerProfile: {
        fontSize: 13,
        fontWeight: "bold",
        color: "#ffffff",
    },
    reasonDesc: {
        fontSize: 12,
        fontWeight: 500,
        color: "#999999",
        padding: "16px 20px",
        width: "100%",
        //backgroundColor: "#ffffff",
    },
    reasonSingleCard: {
        width: "100%",
        marginBottom: 20,
        borderRadius: 20,
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        textAlign: "left",
        padding: 20,
        backgroundImage: `url(${require("assets/img-reason-card-bg.png")})`,
        backgroundImage: `image-set(url(${require("assets/img-reason-card-bg.png")}) 1x, url(${require("assets/img-reason-card-bg@2x.png")}) 2x, url(${require("assets/img-reason-card-bg@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "120%",
        backgroundPosition: "center",
        //height: 440,
    },
    reasonITCompanies: {
        fontSize: 14,
        fontWeight: 500,
        color: "#222222",
    },
    reasonThirdCard: {
        width: "100%",
        marginBottom: 20,
        borderRadius: 20,
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        textAlign: "left",
        padding: 20,
        backgroundImage: `url(${require("assets/img-reason-deal.png")})`,
        backgroundImage: `image-set(url(${require("assets/img-reason-deal.png")}) 1x, url(${require("assets/img-reason-deal@2x.png")}) 2x, url(${require("assets/img-reason-deal@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center",
        //height: 440,
    },
    reasonMsgImgBot: {
        display: "block",
        marginTop: 70,
    },
    scheduleBox: {
        backgroundColor: "#222222",
        paddingTop: 42,
        textAlign: "center",
    },
    clockImg: {
        width: "100%",
        display: "block",
    },
    reviewBox: {
        backgroundColor: "#efefef",
        padding: "64px 20px",
        textAlign: "center",
    },
    reviewBoxTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: "#111111",
    },
    reviewBoxSubTitle: {
        fontSize: 24,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 32,
        lineHeight: 1.5,
    },
    reviewCard: {
        backgroundColor: "#ffffff",
        borderRadius: 8,
        padding: 20,
        marginTop: 16,
        textAlign: "left",
    },
    reviewCardTitle: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#222222",
    },
    reviewCardContent: {
        fontSize: 14,
        fontWeight: 500,
        color: "#222222",
        marginTop: 12,
        marginBottom: 20,
    },
    reviewCardReviewer: {
        fontSize: 14,
        fontWeight: 500,
        color: "#999999",
        marginBottom: 4,
    },
    reviewCardStarIcon: {
        width: 24,
        height: 24,
    },
    targetingBox: {
        backgroundColor: "#2f64ff",
        padding: "64px 20px",
        textAlign: "center",
    },
    targetBoxTitle: {
        fontSize: 26,
        fontWeight: "bold",
        //letterSpacing: "-0.6px",
        color: "#ffffff",
    },
    targetBoxSubTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: "#ffffff",
        marginBottom: 32,
        lineHeight: 1.5,
    },
    targetBoxList: {
        backgroundColor: "#ffffff",
        padding: "16px 20px",
        textAlign: "left",

        borderRadius: 8,
        marginBottom: 8,
    },
    targetBoxListIcon: {
        width: 20,
        height: 20,
        marginRight: 4,
        verticalAlign: "middle",
    },
    targetBoxListItem: {
        fontSize: 14,
        fontWeight: 500,
        color: "#111111",
        verticalAlign: "middle",
    },
    secretBox: {
        width: "100%",
        height: "auto",
        backgroundImage: `url(${require("assets/img-bg-secret.png")})`,
        backgroundImage: `image-set(url(${require("assets/img-bg-secret.png")}) 1x, url(${require("assets/img-bg-secret@2x.png")}) 2x, url(${require("assets/img-bg-secret@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        textAlign: "center",
    },
    secretMsgImg: { marginTop: 28, marginBottom: 28 },
    phanthomBox: {
        width: "100%",
        height: 172,
    },
    onboardingBox: {
        width: "100%",
        padding: "64px 20px",
        textAlign: "center",
    },
    onboardingTitleImg: { marginBottom: 20 },
    onboardingSteps: {
        marginBottom: 16,
    },
    onboardingStepCard: {
        border: "solid 1px #2f64ff",
        borderRadius: 12,
        height: 156,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    onboardingStepIcon: {
        marginBottom: 12,
    },
    onboardingStepDesc: {
        fontSize: 14,
        fontWeight: 500,
        color: "#222222",
    },
    onboardingNoice: {
        textAlign: "left",
        fontSize: 12,
        fontWeight: 500,
        color: "#999999",
        marginBottom: 4,
    },
    faqBox: {
        padding: 20,
        textAlign: "left",
    },
    faqBoxTitle: {
        fontSize: 16,
        fontWeight: "bold",
        color: "#222222",
    },
    faqBoxToggleBtn: {
        float: "right",
    },
    thickDivider: {
        height: 2,
        backgroundColor: "#222222",
        margin: "12px auto",
    },
    faq: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#444444",
        marginBottom: 8,
    },
    answer: {
        fontSize: 14,
        fontWeight: 300,
        color: "#444444",
        marginBottom: 16,
    },
    thinDivider: {
        backgroundColor: "#999999",
        marginBottom: 12,
    },
    appBox: {
        width: "100%",
        backgroundImage: `url(${require("assets/image-app-detail.png")})`,
        backgroundImage: `image-set(url(${require("assets/image-app-detail.png")}) 1x, url(${require("assets/image-app-detail@2x.png")}) 2x, url(${require("assets/image-app-detail@3x.png")}) 3x)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: 48,
        paddingBottom: 43,
    },
    appIntro: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#ffffff",
        //marginTop: 48,
        marginBottom: 20,
        lineHeight: 1.2,
    },
    appDetailBtn: {
        height: "auto",
        display: "inline-block",
        fontSize: 12,
        fontWeight: 500,
        color: "#ffffff",
        border: "solid 1px #ffffff",
        borderRadius: 14,
        padding: "4px 12px",
        verticalAlign: "middle",
        "& > img": {
            marginLeft: 12,
            verticalAlign: "sub",
        },
    },
    cautionBox: {
        backgroundColor: "#333435",
        padding: "20px 20px 156px",
        textAlign: "left",
        "& > p": {
            color: "#ffffff",
        },
    },
    cautionTitle: {
        fontSize: 14,
        fontWeight: "bold",
        marginTop: 12,
        marginBottom: 8,
    },
    cautionContent: {
        fontSize: 13,
        fontWeight: 300,
        marginBottom: 4,
    },
    sliderBox: {
        backgroundColor: "#24429a",
        padding: "64px 25px",
    },
    slider: {
        backgroundColor: "#24429a",
        //padding: 20,
        width: "100%",
    },
}));

const Promotion = (props) => {
    const scroll = useScroll();

    const [mainImgHeight, setMainImgHeight] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const initialWindowHeignt = useRef(window.innerHeight).current;

    const handleImageLoad = (event) => {
        setMainImgHeight(event.target.clientHeight);
        setLoaded(true);
        console.log("Image height :", event.target.clientHeight);
        window.scrollTo({ top: 0 });
    };

    useEffect(() => {
        console.log("Window Height", initialWindowHeignt);
        console.log("Main Image Height: ", mainImgHeight);
        console.log("scrollY: ", scroll.scrollY);
        console.log(
            "Main Image Height - scrollY: ",
            mainImgHeight - scroll.scrollY
        );
    }, [initialWindowHeignt, mainImgHeight, scroll.scrollY]);

    const classes = useStyles({ ...scroll, mainImgHeight, clicked });

    useEffect(() => {
        window.scrollTo({ top: 0 });
        createKakaoShareButton();
    }, []);

    const handleScrollTop = () => {
        window.scroll({ top: 0, behavior: "smooth" });
    };

    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleDetailBtn = () => {
        props.history.push("/?type=talent");
    };

    const handleApplyBtn = () => {
        setClicked((prev) => !prev);
        setTimeout(() => {
            props.history.push("/talent-application");
        }, 300);
    };

    const [slide, setSlide] = useState(0);

    const images = [
        {
            image: require("assets/img-app-feature-1.png"),
            image2x: require("assets/img-app-feature-1@2x.png"),
            image3x: require("assets/img-app-feature-1@3x.png"),
        },
        {
            image: require("assets/img-app-feature-2.png"),
            image2x: require("assets/img-app-feature-2@2x.png"),
            image3x: require("assets/img-app-feature-2@3x.png"),
        },
        {
            image: require("assets/img-app-feature-3.png"),
            image2x: require("assets/img-app-feature-3@2x.png"),
            image3x: require("assets/img-app-feature-3@3x.png"),
        },
        {
            image: require("assets/img-app-feature-4.png"),
            image2x: require("assets/img-app-feature-4@2x.png"),
            image3x: require("assets/img-app-feature-4@3x.png"),
        },
        {
            image: require("assets/img-app-feature-5.png"),
            image2x: require("assets/img-app-feature-5@2x.png"),
            image3x: require("assets/img-app-feature-5@3x.png"),
        },
    ];

    const handlePrevSlideBtn = () => {
        setSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };

    const handleNextSlideBtn = () => {
        setSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };

    const settings = {
        //dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 연봉로켓</title>
            </Helmet>
            <Container
                maxWidth="xs"
                disableGutters
                className={classes.container}
            >
                {!loaded && (
                    <Skeleton
                        variant="rect"
                        width="100%"
                        height="100vh"
                        className={classes.skeleton}
                    />
                )}

                <img
                    onLoad={handleImageLoad}
                    src={wallpaper}
                    srcSet={`${require("assets/wallpaper-salary-highway@2x.png")} 2x, ${require("assets/wallpaper-salary-highway@3x.png")} 3x`}
                    className={classes.phanthomImage}
                    alt="main"
                ></img>
                <Box
                    className={classes.rocketBox}
                    style={{ height: initialWindowHeignt }}
                >
                    <img
                        src={banner}
                        srcSet={`${require("assets/message-promotion-title@2x.png")} 2x, ${require("assets/message-promotion-title@3x.png")} 3x`}
                        className={classes.banner}
                        alt="banner"
                    ></img>
                    <img
                        src={rocket}
                        srcSet={`${require("assets/image-rocket@2x.png")} 2x, ${require("assets/image-rocket@3x.png")} 3x`}
                        className={classes.rocket}
                        alt="rocket"
                    ></img>
                </Box>
                <Box className={classes.fixedBtnsBox}>
                    <Box className={classes.fixedTopBtnsBox}>
                        <Link
                            href="https://talentx.channel.io/"
                            target="_blank"
                            color="inherit"
                            style={{ textDecoration: "none" }}
                        >
                            <Box className={classes.fixedTopLeftBtn}>
                                문의하기
                            </Box>
                        </Link>
                        <Box
                            className={classes.fixedTopRightBtn}
                            onClick={handleScrollTop}
                        >
                            <img
                                src={require("assets/icon-nav-chevron-up-20.png")}
                                srcSet={`${require("assets/icon-nav-chevron-up-20@2x.png")} 2x, ${require("assets/icon-nav-chevron-up-20@3x.png")} 3x`}
                                alt="scroll top button"
                            />
                        </Box>
                    </Box>
                    <Box className={classes.fixedBottomBtnsBox}>
                        <Box
                            id="kakaoShareBtn"
                            className={classes.fixedBottomLeftBtn}
                        >
                            <img
                                src={require("assets/icon-kakao.png")}
                                srcSet={`${require("assets/icon-kakao@2x.png")} 2x, ${require("assets/icon-kakao@3x.png")} 3x`}
                                style={{
                                    width: 16,
                                    height: 16,
                                    marginRight: 4,
                                }}
                                alt="kakao share button"
                            />
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: "#222222",
                                }}
                            >
                                공유하기
                            </Typography>
                        </Box>

                        <RouterLink
                            to="/talent-application"
                            className={classes.fixedBottomRightBtn}
                        >
                            경력 개발자 100명 한정 (~2.28)
                        </RouterLink>
                    </Box>
                </Box>
                <Box className={classes.body}>
                    <Box className={classes.tailer}>
                        <img
                            src={require("assets/message-promotion-season.png")}
                            srcSet={`${require("assets/message-promotion-season@2x.png")} 2x, ${require("assets/message-promotion-season@3x.png")} 3x`}
                            alt="title"
                        ></img>
                        <img
                            src={promotionTitle}
                            srcSet={`${require("assets/message-promotion@2x.png")} 2x, ${require("assets/message-promotion@3x.png")} 3x`}
                            alt="title"
                        ></img>
                        <Box className={classes.promotionBox}>
                            <img
                                src={promotionSubTitle}
                                srcSet={`${require("assets/message-promotion-subtitle@2x.png")} 2x, ${require("assets/message-promotion-subtitle@3x.png")} 3x`}
                                alt="subtitle"
                            ></img>
                            <img
                                src={moneyStack}
                                srcSet={`${require("assets/image-money-stack@2x.png")} 2x, ${require("assets/image-money-stack@3x.png")} 3x`}
                                alt="subtitle"
                            ></img>
                        </Box>
                        <img
                            src={imgPromotionalMsg}
                            srcSet={`${require("assets/img-promotional-message@2x.png")} 2x, ${require("assets/img-promotional-message@3x.png")} 3x`}
                            alt="message"
                        ></img>
                        <Divider className={classes.divider}></Divider>
                        {/* <Typography className={classes.introduction}>
                            자세히 보기를 하시면 각 기업별 상세 정보와 개발팀
                            규모,
                            <br />
                            사용 중인 개발언어 등 다양한 정보를 확인하실 수
                            있습니다.
                        </Typography>
                        <Box className={classes.companyCard}>
                            <div className={classes.companyLogo}></div>
                            <div className={classes.companyInfo}>
                                <p className={classes.companyName}>
                                    탤런티넘
                                    <br />
                                    <span className={classes.companyFeature}>
                                        시리즈 A 투자 유치
                                    </span>
                                </p>
                            </div>

                            <div className={classes.companyDetailBtn}>
                                자세히
                            </div>
                        </Box> */}
                        <Box>
                            <img
                                src={iconDoubleQuotes}
                                srcSet={`${require("assets/icon-double-quotes@2x.png")} 2x, ${require("assets/icon-double-quotes@3x.png")} 3x`}
                                alt="double quotes"
                                className={classes.iconDoubleQuotes}
                            ></img>
                            <Typography className={classes.question}>
                                이직, 결심만 하고
                                <br />
                                아직까지 실천 못하고 계신가요?
                            </Typography>
                            <img
                                src={iconChevronDown}
                                srcSet={`${require("assets/icon-nav-chevron-down-20@2x.png")} 2x, ${require("assets/icon-nav-chevron-down-20@3x.png")} 3x`}
                                alt="arrow down"
                                className={classes.iconChevronDown}
                            ></img>
                        </Box>
                        <img
                            src={imgMKTBanner}
                            srcSet={`${require("assets/img-mkt-banner@2x.png")} 2x, ${require("assets/img-mkt-banner@3x.png")} 3x`}
                            alt="banner"
                            className={classes.mktBanner}
                        ></img>
                        <Box className={classes.promotionCard}>
                            <Typography className={classes.benefit}>
                                이력서/포트폴리오를
                                <br />
                                준비하지 않아도 이직이 가능합니다.
                            </Typography>
                            <div className={classes.resumeAndPortfolioBox}>
                                <div>
                                    <img
                                        src={ilResume}
                                        srcSet={`${require("assets/il-resume@2x.png")} 2x, ${require("assets/il-resume@3x.png")} 3x`}
                                        alt="resume"
                                    ></img>
                                    <div className={classes.flexbox}>
                                        <span className={classes.font14Bold}>
                                            이력서 작성
                                        </span>
                                        <img
                                            src={icNo}
                                            srcSet={`${require("assets/ic-no@2x.png")} 2x, ${require("assets/ic-no@3x.png")} 3x`}
                                            alt="no"
                                            className={classes.icNo}
                                        ></img>
                                    </div>
                                </div>
                                <div>
                                    <img
                                        src={ilPorfolio}
                                        srcSet={`${require("assets/il-porfolio@2x.png")} 2x, ${require("assets/il-porfolio@3x.png")} 3x`}
                                        alt="portfolio"
                                    ></img>
                                    <div className={classes.flexbox}>
                                        <span className={classes.font14Bold}>
                                            포트폴리오 제작
                                        </span>
                                        <img
                                            src={icNo}
                                            srcSet={`${require("assets/ic-no@2x.png")} 2x, ${require("assets/ic-no@3x.png")} 3x`}
                                            alt="no"
                                            className={classes.icNo}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <Box className={classes.cover}></Box>
                    </Box>
                    <Box className={classes.section}>
                        {/* <Box className={classes.featureBox}>
                            <img
                                src={require("assets/img-promotional-message-0.png")}
                                srcSet={`${require("assets/img-promotional-message-0@2x.png")} 2x, ${require("assets/img-promotional-message-0@3x.png")} 3x`}
                                alt="message"
                                className
                            ></img>
                            <Typography className={classes.featureMsg}>
                                프로필 작성만 해도 면접 제안이 와요
                            </Typography>

                            <img
                                src={images[slide].image}
                                srcSet={`${images[slide].image2x} 2x, ${images[slide].image3x} 3x`}
                                alt="feature"
                                className={classes.featureImg}
                            ></img>
                            <div
                                className={classes.prevSlideBtn}
                                onClick={handlePrevSlideBtn}
                            >
                                <img
                                    src={require("assets/icon-nav-chevron-left-24.png")}
                                    alt="prev slide"
                                />
                            </div>
                            <div
                                className={classes.nextSlideBtn}
                                onClick={handleNextSlideBtn}
                            >
                                <img
                                    src={require("assets/icon-nav-chevron-right-24.png")}
                                    alt="prev slide"
                                />
                            </div>
                        </Box> */}
                        <Box className={classes.sliderBox}>
                            <img
                                src={require("assets/img-promotional-message-0.png")}
                                srcSet={`${require("assets/img-promotional-message-0@2x.png")} 2x, ${require("assets/img-promotional-message-0@3x.png")} 3x`}
                                alt="message"
                                className
                            ></img>
                            <Typography className={classes.featureMsg}>
                                프로필 작성만 해도 면접 제안이 와요
                            </Typography>
                            <Box className={classes.slider}>
                                <Slider {...settings}>
                                    <img
                                        src={images[0].image}
                                        srcSet={`${images[0].image2x} 2x, ${images[0].image3x} 3x`}
                                        width="100%"
                                    ></img>
                                    <img
                                        src={images[1].image}
                                        srcSet={`${images[1].image2x} 2x, ${images[1].image3x} 3x`}
                                        width="100%"
                                    ></img>
                                    <img
                                        src={images[2].image}
                                        srcSet={`${images[2].image2x} 2x, ${images[2].image3x} 3x`}
                                        width="100%"
                                    ></img>
                                    <img
                                        src={images[3].image}
                                        srcSet={`${images[3].image2x} 2x, ${images[3].image3x} 3x`}
                                        width="100%"
                                    ></img>
                                    <img
                                        src={images[4].image}
                                        srcSet={`${images[4].image2x} 2x, ${images[4].image3x} 3x`}
                                        width="100%"
                                    ></img>
                                </Slider>
                            </Box>
                        </Box>
                        <Box className={classes.problemBox}>
                            <img
                                src={require("assets/img-problem.png")}
                                srcSet={`${require("assets/img-problem@2x.png")} 2x, ${require("assets/img-problem@3x.png")} 3x`}
                                alt="problem"
                                className={classes.problemImg}
                            ></img>
                        </Box>
                        <Box className={classes.actionBox}>
                            <Typography className={classes.fs14fw500}>
                                더 좋은 조건, 더 나은 환경에, 더 편한 이직을
                                원한다면
                            </Typography>
                            <img
                                src={require("assets/img-action-message.png")}
                                srcSet={`${require("assets/img-action-message@2x.png")} 2x, ${require("assets/img-action-message@3x.png")} 3x`}
                                alt="action message"
                                className={classes.actionImg}
                            ></img>
                            <img
                                src={require("assets/img-touch-message.png")}
                                srcSet={`${require("assets/img-touch-message@2x.png")} 2x, ${require("assets/img-touch-message@3x.png")} 3x`}
                                alt="touch message"
                            ></img>
                            <img
                                src={require("assets/ic-chevron-down-20.png")}
                                srcSet={`${require("assets/ic-chevron-down-20@2x.png")} 2x, ${require("assets/ic-chevron-down-20@3x.png")} 3x`}
                                alt="arrow down"
                            ></img>
                        </Box>
                        <Box className={classes.applyBox}>
                            {/* <RouterLink to="/talent-application"></RouterLink> */}
                            <img
                                src={require("assets/img-apply-btn.png")}
                                srcSet={`${require("assets/img-apply-btn@2x.png")} 2x, ${require("assets/img-apply-btn@3x.png")} 3x`}
                                alt="apply button"
                                className={classes.applyBtnImg}
                                onClick={handleApplyBtn}
                            ></img>
                            <img
                                src={require("assets/img-apply-btn-clicked.png")}
                                srcSet={`${require("assets/img-apply-btn-clicked@2x.png")} 2x, ${require("assets/img-apply-btn-clicked@3x.png")} 3x`}
                                alt="apply button"
                                className={classes.applyBtnClickedImg}
                            ></img>
                            <img
                                src={require("assets/img-msg-save-time.png")}
                                srcSet={`${require("assets/img-msg-save-time@2x.png")} 2x, ${require("assets/img-msg-save-time@3x.png")} 3x`}
                                alt="apply button"
                                className={classes.reassurance}
                            ></img>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        className={classes.leftRoundBox}
                                    >
                                        <Typography
                                            className={
                                                classes.leftRoundBoxTitle
                                            }
                                        >
                                            경력 개발자 평균
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.leftRoundBoxFigure
                                            }
                                        >
                                            4.4개월
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        className={classes.rightRoundBox}
                                    >
                                        <Typography
                                            className={
                                                classes.rightRoundBoxTitle
                                            }
                                        >
                                            탤런트엑스
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.rightRoundBoxFigure
                                            }
                                        >
                                            1주일
                                        </Typography>
                                        <img
                                            src={require("assets/il-time-save.png")}
                                            srcSet={`${require("assets/il-time-save@2x.png")} 2x, ${require("assets/il-time-save@3x.png")} 3x`}
                                            alt="time saving"
                                            className={classes.timeSaving}
                                        ></img>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.posterBox}>
                            <img
                                src={require("assets/img-poster-bg.png")}
                                srcSet={`${require("assets/img-poster-bg@2x.png")} 2x, ${require("assets/img-poster-bg@3x.png")} 3x`}
                                alt="poster"
                                className={classes.posterBG}
                            ></img>
                            <Typography className={classes.posterTitle}>
                                탑승 이후엔 가만히 있어도
                                <br />
                                매주 새로운 면접 제안이!
                            </Typography>
                            <img
                                src={require("assets/img-poster-fg.png")}
                                srcSet={`${require("assets/img-poster-fg@2x.png")} 2x, ${require("assets/img-poster-fg@3x.png")} 3x`}
                                alt="poster"
                                className={classes.posterFG}
                            ></img>
                        </Box>
                        <Box className={classes.reasonBox}>
                            <img
                                src={require("assets/img-message-reason.png")}
                                srcSet={`${require("assets/img-message-reason@2x.png")} 2x, ${require("assets/img-message-reason@3x.png")} 3x`}
                                alt="reason"
                                className={null}
                            ></img>
                            <Box className={classes.reasonCard}>
                                <Box className={classes.reasonCardTop}>
                                    <img
                                        src={require("assets/img-talent-manager.png")}
                                        srcSet={`${require("assets/img-talent-manager@2x.png")} 2x, ${require("assets/img-talent-manager@3x.png")} 3x`}
                                        alt="reason"
                                        className={classes.reasonCardImg}
                                    ></img>
                                    <Typography className={classes.reasonLabel}>
                                        이유 하나!
                                    </Typography>
                                    <img
                                        src={require("assets/img-msg-talent-manager.png")}
                                        srcSet={`${require("assets/img-msg-talent-manager@2x.png")} 2x, ${require("assets/img-msg-talent-manager@3x.png")} 3x`}
                                        alt="reason"
                                        className={classes.reasonMsgImg}
                                    ></img>

                                    <Typography
                                        className={classes.reasonManagerProfile}
                                    >
                                        10년 차 HR매니저
                                        <br />
                                        한신환
                                    </Typography>
                                </Box>
                                <Typography className={classes.reasonDesc}>
                                    “이직에 대한 두려움은 누구나 가지고
                                    있습니다. 저는 귀하가 걱정하고 계신 걱정과
                                    두려움을 대신 해결해주는 일을 하고 있습니다.
                                    저를 믿고 당신의 커리어를 맡겨주세요.”
                                </Typography>
                            </Box>
                            <Box className={classes.reasonSingleCard}>
                                <Typography className={classes.reasonLabel}>
                                    이유 둘!
                                </Typography>
                                <img
                                    src={require("assets/img-msg-offers.png")}
                                    srcSet={`${require("assets/img-msg-offers@2x.png")} 2x, ${require("assets/img-msg-offers@3x.png")} 3x`}
                                    alt="reason"
                                    className={classes.reasonMsgImg}
                                ></img>

                                <Typography
                                    className={classes.reasonITCompanies}
                                >
                                    거기다 모두 유망 IT기업이니까!
                                </Typography>
                            </Box>
                            <Box className={classes.reasonThirdCard}>
                                <Typography className={classes.reasonLabel}>
                                    이유 셋!
                                </Typography>
                                <img
                                    src={require("assets/img-msg-deal.png")}
                                    srcSet={`${require("assets/img-msg-deal@2x.png")} 2x, ${require("assets/img-msg-deal@3x.png")} 3x`}
                                    alt="deal"
                                    className={classes.reasonMsgImgBot}
                                ></img>
                            </Box>
                        </Box>

                        <Box className={classes.scheduleBox}>
                            <img
                                src={require("assets/img-msg-hurry-up.png")}
                                srcSet={`${require("assets/img-msg-hurry-up@2x.png")} 2x, ${require("assets/img-msg-hurry-up@3x.png")} 3x`}
                                alt="hurru up"
                            ></img>
                            <img
                                src={require("assets/img-clock-large.png")}
                                srcSet={`${require("assets/img-clock-large@2x.png")} 2x, ${require("assets/img-clock-large@3x.png")} 3x`}
                                alt="clock"
                                className={classes.clockImg}
                            ></img>
                        </Box>
                        <Box className={classes.reviewBox}>
                            <Typography className={classes.reviewBoxTitle}>
                                내 능력을 높게 평가해주는 기업을 만나는
                            </Typography>
                            <Typography className={classes.reviewBoxSubTitle}>
                                가장 간편하고 쉬운 방법
                            </Typography>
                            <Box className={classes.reviewCard}>
                                <Typography className={classes.reviewCardTitle}>
                                    이직 결심을 하고 바로,
                                    <br />
                                    원하는 조건으로 이직했어요.
                                </Typography>
                                <Typography
                                    className={classes.reviewCardContent}
                                >
                                    “교수님 추천으로 취업한 곳에서 실전
                                    개발보다는 문서작업과 잡무를 더 하는것 같아
                                    고심하다가 지인 소개로 알게된 탤런트엑스를
                                    통해 원하는 연봉으로 바로 이직했어요. 현재는
                                    오롯이 개발에만 집중 할 수 있고 지금 회사
                                    대표님이 슈퍼개발자라서 많이 배울수 있어
                                    만족스럽게 다니고 있습니다.”
                                </Typography>
                                <Typography
                                    className={classes.reviewCardReviewer}
                                >
                                    탤런트엑스를 통해 이직하신 이OO님
                                </Typography>
                                {new Array(5).fill(null).map((item, idx) => (
                                    <img
                                        key={idx}
                                        src={require("assets/ic-toggle-star-on-24.png")}
                                        srcSet={`${require("assets/ic-toggle-star-on-24@2x.png")} 2x, ${require("assets/ic-toggle-star-on-24@3x.png")} 3x`}
                                        alt="rating"
                                        className={classes.reviewCardStarIcon}
                                    ></img>
                                ))}
                            </Box>
                            {/* <Box className={classes.reviewCard}></Box> */}
                        </Box>
                        <Box className={classes.targetingBox}>
                            <Typography className={classes.targetBoxTitle}>
                                이런 개발자분에게 필요해요!
                            </Typography>
                            <Typography className={classes.targetBoxSubTitle}>
                                내 얘기라면 지금 바로 연봉로켓에 탑승하세요!
                            </Typography>
                            <Box className={classes.targetBoxList}>
                                <img
                                    className={classes.targetBoxListIcon}
                                    src={require("assets/ic-check.png")}
                                    srcSet={`${require("assets/ic-check@2x.png")} 2x, ${require("assets/ic-check@3x.png")} 3x`}
                                    alt="check"
                                ></img>
                                <span className={classes.targetBoxListItem}>
                                    이직이 내 평생 처음이다.
                                </span>
                            </Box>
                            <Box className={classes.targetBoxList}>
                                <img
                                    className={classes.targetBoxListIcon}
                                    src={require("assets/ic-check.png")}
                                    srcSet={`${require("assets/ic-check@2x.png")} 2x, ${require("assets/ic-check@3x.png")} 3x`}
                                    alt="check"
                                ></img>
                                <span className={classes.targetBoxListItem}>
                                    이직 준비가 어려워 포기한 경험이 있다.
                                </span>
                            </Box>
                            <Box className={classes.targetBoxList}>
                                <img
                                    className={classes.targetBoxListIcon}
                                    src={require("assets/ic-check.png")}
                                    srcSet={`${require("assets/ic-check@2x.png")} 2x, ${require("assets/ic-check@3x.png")} 3x`}
                                    alt="check"
                                ></img>
                                <span className={classes.targetBoxListItem}>
                                    현재 급여가 만족스럽지 못하다.
                                </span>
                            </Box>
                            <Box className={classes.targetBoxList}>
                                <img
                                    className={classes.targetBoxListIcon}
                                    src={require("assets/ic-check.png")}
                                    srcSet={`${require("assets/ic-check@2x.png")} 2x, ${require("assets/ic-check@3x.png")} 3x`}
                                    alt="check"
                                ></img>
                                <span className={classes.targetBoxListItem}>
                                    나의 가치를 높게 보는 곳으로 이직하고 싶다.
                                </span>
                            </Box>
                            <Box className={classes.targetBoxList}>
                                <img
                                    className={classes.targetBoxListIcon}
                                    src={require("assets/ic-check.png")}
                                    srcSet={`${require("assets/ic-check@2x.png")} 2x, ${require("assets/ic-check@3x.png")} 3x`}
                                    alt="check"
                                ></img>
                                <span className={classes.targetBoxListItem}>
                                    좋은 제안이 오면 이직할 생각이 있다.
                                </span>
                            </Box>
                        </Box>

                        <Box className={classes.secretBox}>
                            <img
                                src={require("assets/img-msg-secret.png")}
                                srcSet={`${require("assets/img-msg-secret@2x.png")} 2x, ${require("assets/img-msg-secret@3x.png")} 3x`}
                                alt="secret"
                                className={classes.secretMsgImg}
                            ></img>
                        </Box>
                        <Box className={classes.onboardingBox}>
                            <img
                                src={require("assets/img-title-onboarding.png")}
                                srcSet={`${require("assets/img-title-onboarding@2x.png")} 2x, ${require("assets/img-title-onboarding@3x.png")} 3x`}
                                alt="onboarding"
                                className={classes.onboardingTitleImg}
                            ></img>
                            <Grid
                                container
                                spacing={1}
                                className={classes.onboardingSteps}
                            >
                                <Grid item xs={6}>
                                    <Box className={classes.onboardingStepCard}>
                                        <img
                                            src={require("assets/icon-step-one.png")}
                                            srcSet={`${require("assets/icon-step-one@2x.png")} 2x, ${require("assets/icon-step-one@3x.png")} 3x`}
                                            alt="step one"
                                            className={
                                                classes.onboardingStepIcon
                                            }
                                        ></img>
                                        <Typography
                                            className={
                                                classes.onboardingStepDesc
                                            }
                                        >
                                            탑승하기 버튼을 눌러<br></br> SW인재
                                            회원 신청하기
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className={classes.onboardingStepCard}>
                                        <img
                                            src={require("assets/icon-step-two.png")}
                                            srcSet={`${require("assets/icon-step-two@2x.png")} 2x, ${require("assets/icon-step-two@3x.png")} 3x`}
                                            alt="step one"
                                            className={
                                                classes.onboardingStepIcon
                                            }
                                        ></img>
                                        <Typography
                                            className={
                                                classes.onboardingStepDesc
                                            }
                                        >
                                            신청 승인 받은 후<br></br>탤런트엑스
                                            앱 설치하기
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box className={classes.onboardingStepCard}>
                                        <img
                                            src={require("assets/icon-step-three.png")}
                                            srcSet={`${require("assets/icon-step-three@2x.png")} 2x, ${require("assets/icon-step-three@3x.png")} 3x`}
                                            className={
                                                classes.onboardingStepIcon
                                            }
                                            alt="step one"
                                        ></img>
                                        <Typography
                                            className={
                                                classes.onboardingStepDesc
                                            }
                                        >
                                            탤런트엑스 앱에서<br></br>
                                            회원가입하기
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className={classes.onboardingStepCard}>
                                        <img
                                            src={require("assets/icon-step-four.png")}
                                            srcSet={`${require("assets/icon-step-four@2x.png")} 2x, ${require("assets/icon-step-four@3x.png")} 3x`}
                                            alt="step one"
                                            className={
                                                classes.onboardingStepIcon
                                            }
                                        ></img>
                                        <Typography
                                            className={
                                                classes.onboardingStepDesc
                                            }
                                        >
                                            프로필을 작성하면<br></br>면접
                                            제안이 알아서!
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* <Typography className={classes.onboardingNoice}>
                                &#8226; 코딩 테스트는 영문으로 진행됩니다.
                            </Typography>
                            <Typography className={classes.onboardingNoice}>
                                &#8226; 코딩 테스트시 웹캠이 달린 컴퓨터 혹은,
                                노트북이 있어야 진행 가능합니다. (스마트폰 응시
                                불가)
                            </Typography>
                            <Typography className={classes.onboardingNoice}>
                                &#8226; 코딩 테스트 점수 미달로 불합격 하셨을
                                경우 이력서, 포트폴리오 제출을 통해 나머지
                                점수를 충당하실 수 있습니다.
                            </Typography> */}
                        </Box>

                        {/* <Box className={classes.faqBox}>
                            <Box>
                                <span className={classes.faqBoxTitle}>
                                    자주 묻는 질문
                                </span>
                                <img
                                    src={
                                        open
                                            ? require("assets/icon-nav-chevron-up-20.png")
                                            : require("assets/icon-nav-chevron-down-20.png")
                                    }
                                    alt="collapse"
                                    className={classes.faqBoxToggleBtn}
                                    onClick={handleOpen}
                                ></img>
                            </Box>
                            <Divider className={classes.thickDivider}></Divider>
                            <Box className={classes.quas} hidden={!open}>
                                <Typography className={classes.faq}>
                                    Q. 연봉로켓 탑승 자격이 어떻게 되나요?
                                </Typography>
                                <Typography className={classes.answer}>
                                    A. 경력연차와 상관없이 현재 기업에서
                                    SW개발자로 재직 중이거나 프리랜서 SW개발자로
                                    활동 중이라면 누구나 지원이 가능합니다. 다만
                                    코딩테스트에 합격하셔야 연봉로켓에 탑승이
                                    가능합니다.
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                                <Typography className={classes.faq}>
                                    Q. 코딩 테스트 진행 시 웹캠 외 추가로
                                    설치해야 하는 프로그램은 없나요?
                                </Typography>
                                <Typography className={classes.answer}>
                                    A. 추가 설치할 필요 없이 웹캠만 준비되어
                                    있으면 됩니다.
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                                <Typography className={classes.faq}>
                                    Q. 마스크를 끼고 코딩 테스트 진행이
                                    가능한가요?
                                </Typography>
                                <Typography className={classes.answer}>
                                    A. 부정행위 방지를 위해 마스크 없이 응시하는
                                    것을 원칙으로 하고 있습니다. 마스크를 벗을
                                    수 있는 환경에서 진행해주세요.
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                                <Typography className={classes.faq}>
                                    Q. 테스트 진행 중 검색하는 건
                                    부정행위인가요?
                                </Typography>
                                <Typography className={classes.answer}>
                                    A. 응시 도중 검색하는 것은 부정행위에
                                    해당하지 않습니다. 다만, 다른 사람의 코드를
                                    그대로 가져와 사용하는 것은 부정행위로
                                    간주될 수 있으니 주의하시기 바랍니다.
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                                <Typography className={classes.faq}>
                                    Q. 응시 가능한 언어는 어떤 게 있나요?
                                </Typography>
                                <Typography className={classes.answer}>
                                    A. C, Clojure, Cobol, C++, C++14, C#, D,
                                    Erlang, Elixir, Fortran, F#, Go, Groovy,
                                    Haskell, Java 7, Java 8, Javascript, Julia,
                                    Kotlin, Lua, Objective-C, OCaml, Pascal,
                                    Perl, PHP, PyPy, PyPy3, Python 2, Python 3,
                                    R, Racket, Ruby, Rust, Common Lisp (SBCL),
                                    Scala, Smalltalk, Swift, VB.NET, BASH,
                                    Octave, Tcl, MySQL, Oracle, MS SQL, DB2가
                                    있습니다.
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                                <Typography className={classes.faq}>
                                    Q. 면접 제안이 오면 무조건 봐야하나요?
                                </Typography>
                                <Typography className={classes.answer}>
                                    A. 제안이 왔다고 해서 무조건 면접을 봐야
                                    하는 것은 아닙니다. 유효기간 이내 제안을
                                    검토하시고 응답하시면 됩니다.
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                            </Box>
                        </Box> */}
                        <Box className={classes.appBox}>
                            <Typography className={classes.appIntro}>
                                탤런트엑스에 대해 좀 더<br />
                                자세히 알고싶으신가요?
                            </Typography>
                            {/* <RouterLink to="/talentx"> */}
                            <Typography
                                className={classes.appDetailBtn}
                                onClick={handleDetailBtn}
                            >
                                자세히 보기{" "}
                                <img
                                    src={require("assets/icon-nav-arrow-right-16.png")}
                                    alt="right arrow"
                                ></img>
                            </Typography>
                            {/* </RouterLink> */}
                        </Box>

                        <Box className={classes.cautionBox}>
                            <Typography className={classes.cautionTitle}>
                                주의사항
                            </Typography>
                            <Typography className={classes.cautionTitle}>
                                참가자 현황에 따라 참가 신청이 제한될 수
                                있습니다.
                            </Typography>
                            {/* <Typography className={classes.cautionTitle}>
                                부정행위 처리방침
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 코딩 테스트 중 부정행위 발생 시 불합격
                                처리됩니다.
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 시스템 오류로 인해 부당하게 부정행위로
                                오인되었을 경우 재응시 혹은 재심사 요청이
                                가능합니다.
                            </Typography> */}
                            <Typography className={classes.cautionTitle}>
                                탤런트엑스 앱 이용 제한
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 탤런트엑스는 사전 심사과정을 통해 승인된
                                SW인재만 이용 가능한 서비스입니다.
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 사전 승인 없이는 앱 서비스 이용이 불가합니다.
                            </Typography>
                            {/* <Typography className={classes.cautionTitle}>
                                면접 취소 안내
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 면접예정일로부터 24시간 전까지 면접 취소
                                가능합니다.
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 면접을 취소해야 할 경우 탤런트엑스 고객센터로
                                연락주시기 바랍니다. (고객센터:{" "}
                                <a
                                    href="https://talentx.channel.io/"
                                    style={{
                                        color: "#2fafff",
                                        textDecoration: "none",
                                    }}
                                    target="_blank"
                                >
                                    https://talentx.channel.io/
                                </a>
                                )
                            </Typography>

                            <Typography className={classes.cautionContent}>
                                - 탤런트엑스 앱에서 [내 정보 {">"} 고객센터{" "}
                                {">"} 채널톡 문의]를 통해서도 연락 가능합니다.
                            </Typography>
                            <Typography className={classes.cautionTitle}>
                                면접 노쇼 안내
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 별도 연락 없이 무단으로 면접에 참석하지 않을
                                경우 플랫폼에 참여한 기업들에게 해당 사실이 모두
                                공유되며 추후 서비스 이용이 어려울 수 있습니다.
                            </Typography>
                            <Typography className={classes.cautionContent}>
                                - 면접예정일로부터 24시간 전까지 면접 취소가
                                가능하고 그 이후에 면접을 취소할 경우 해당
                                사실이 참가 기업들에게 공유됩니다.
                            </Typography> */}
                        </Box>
                        {/* <Box className={classes.phanthomBox}> </Box> */}
                    </Box>
                </Box>
                {/* <Box
                    style={{
                        width: "100%",
                        height: 6000,

                        backgroundColor: "#060075",
                    }}
                ></Box> */}
            </Container>
        </React.Fragment>
    );
};

export default Promotion;
