export const companyNameMap = new Map([
    ["케어닥", "7088100933"],
    ["caredoc", "7088100933"],
    ["버드뷰", "2328100912"],
    ["birdview", "2328100912"],
    ["룰루랩", "4358800655"],
    ["lululab", "4358800655"],
    ["식스샵", "2208841563"],
    ["sixshop", "2208841563"],
    ["아이알엠", "1448108465"],
    ["irm", "1448108465"],
    ["더핑크퐁컴퍼니", "2148859980"],
    ["pinkfong", "2148859980"],
    ["크레파스솔루션", "3718800161"],
    ["crepass", "3718800161"],
    ["두들린", "5138601891"],
    ["doodlin", "5138601891"],
    ["큐램", "1148607247"],
    ["quram", "1148607247"],
    ["로랩스", "2948701037"],
    ["rawlabs", "2948701037"],
    ["플리토", "2158772878"],
    ["flitto", "2158772878"],
    ["섹타나인", "5708501897"],
    ["secta9", "5708501897"],
    ["secta9ine", "5708501897"],
    ["sectanine", "5708501897"],
    ["아이지에이웍스", "2208741741"],
    ["igaworks", "2208741741"],
    ["더블미", "7338800035"],
    ["doubleme", "7338800035"],
    ["빅크", "5728602360"],
    ["bigc", "5728602360"],
    ["닷", "3278700041"],
    ["dot", "3278700041"],
    ["비트바이트", "7058700940"],
    ["bitbyte", "7058700940"],
]);
