import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        padding: theme.spacing(5, 0),
        backgroundColor: "black",
        color: "white",
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            // padding: "0 calc((100% - 296px) / 2)",
            padding: "0 32px",
        },
    },
}));

function Footer(params) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <RouterLink
                            to="/"
                            style={{
                                textDecoration: "none",
                                color: "white",
                            }}
                        >
                            <Typography variant="subtitle2" gutterBottom>
                                <b>주식회사 탤런티넘</b>
                            </Typography>
                        </RouterLink>
                        <Typography variant="body2">
                            본사: 경기도 성남시 분당구 느티로 22, B동 2504호
                            <br />
                            대표: 한신환
                            <br />
                            사업자등록번호: 734-81-01533
                            <br />
                            유료직업소개사업소: 탤런트엑스
                            <br />
                            유료직업소개사업번호: 제2019-3780288-14-5-00023호
                            <br />
                            통신판매신고번호: 제2020-성남분당A-0820호
                            <br />
                            개인정보관리자: 오성원
                            <br />
                            <RouterLink
                                to="/terms-of-use"
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                서비스 이용약관
                            </RouterLink>
                        </Typography>
                        <br></br>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            <b>인재 솔루션</b>
                        </Typography>
                        <Typography variant="body2">
                            <RouterLink
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                탤런트엑스
                            </RouterLink>
                            {/* <Link
                                href="https://www.talentx.co.kr"
                                target="_blank"
                                rel="noopener"
                                color="inherit"
                            >
                                talentX
                            </Link> */}
                            {/* <Link
                                href="https://www.talentx.info"
                                target="_blank"
                                rel="noopener"
                                color="inherit"
                            >
                                {" "}
                                (이용 가이드)
                            </Link> */}
                        </Typography>
                        {/* <Typography variant="body2">
                            <Link
                                href="https://www.dinomembership.com"
                                target="_blank"
                                rel="noopener"
                                color="inherit"
                            >
                                DINO
                            </Link>
                        </Typography> */}
                        <br></br>

                        {/* <Typography variant="subtitle2" gutterBottom>
                            <b>파트너사</b>
                        </Typography> */}
                        {/* <Typography variant="body2">
                            <Link
                                href="https://www.multicampus.com/"
                                target="_blank"
                                rel="noopener"
                                color="inherit"
                            >
                                멀티캠퍼스
                            </Link>
                        </Typography> */}
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <Typography variant="body2" color="inherit">
                    {"Copyright © "}
                    <Link color="inherit" href="https://talentinum.com/">
                        talentinum Inc.
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {"."}
                </Typography>
            </Container>
        </div>
    );
}

export default Footer;
