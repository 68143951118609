import { Images } from "styles";

export const content = {
    head: {
        items: [
            {
                hidden: true,
                image: require("assets/img_money_packs.png"),
                image2x: require("assets/img_money_packs@2x.png"),
                iamge3x: require("assets/img_money_packs@3x.png"),
            },
            {
                hidden: false,
                image: require("assets/tti_slogan.png"),
                image2x: require("assets/tti_slogan@2x.png"),
                iamge3x: require("assets/tti_slogan@3x.png"),
            },
            {
                hidden: false,
                image: require("assets/img_d_rocket.png"),
                image2x: require("assets/img_d_rocket@2x.png"),
                iamge3x: require("assets/img_d_rocket@3x.png"),
            },
            {
                hidden: true,
                image: require("assets/img_money_pack.png"),
                image2x: require("assets/img_money_pack@2x.png"),
                iamge3x: require("assets/img_money_pack@3x.png"),
            },
        ],
    },
    promotion: {
        items: [
            {
                image: require("assets/tti_promotion_title.png"),
                image2x: require("assets/tti_promotion_title@2x.png"),
                iamge3x: require("assets/tti_promotion_title@3x.png"),
            },
            {
                image: require("assets/tti_promotion_subtitle.png"),
                image2x: require("assets/tti_promotion_subtitle@2x.png"),
                iamge3x: require("assets/tti_promotion_subtitle@3x.png"),
            },
        ],
        btn: {
            label: "탑승 신청하기",
        },
    },
    company: {
        title: {
            image: require("assets/tti_new_opportunity_connect.png"),
            image2x: require("assets/tti_new_opportunity_connect@2x.png"),
            iamge3x: require("assets/tti_new_opportunity_connect@3x.png"),
        },
        desc: "자세히 보기를 통해 각 기업별 개발팀 규모, 개발환경 등 다양한 정보를 확인하세요.",
        cards: [
            {
                name: "탤런티넘",
                point: "시리즈 B 투자 유치",
                btn: { label: "자세히", url: null },
            },
            {
                name: "탤런티넘",
                point: "시리즈 B 투자 유치",
                btn: { label: "자세히", url: null },
            },
            {
                name: "탤런티넘",
                point: "시리즈 B 투자 유치",
                btn: { label: "자세히", url: null },
            },
            {
                name: "탤런티넘",
                point: "시리즈 B 투자 유치",
                btn: { label: "자세히", url: null },
            },
            {
                name: "탤런티넘",
                point: "시리즈 B 투자 유치",
                btn: { label: "자세히", url: null },
            },
            {
                name: "탤런티넘",
                point: "시리즈 B 투자 유치",
                btn: { label: "자세히", url: null },
            },
        ],
    },
    huddle: {
        quotes: {
            ...Images.assets("icon_double_quotes_32px"),
        },
        title: "이직, 결심만 하고\n아직까지 실천 못하고 계신가요?",
        frame: {
            title: "tti_ready",
            desc: "이력서/포트폴리오를\n준비하지 않아도 이직이 가능합니다.",
            items: [
                {
                    image: "img_resume",
                    label: "tti_resume",
                },
                {
                    image: "img_portfolio",
                    label: "tti_portfolio",
                },
            ],
        },
    },
    app: {
        title: "tti_with_talentx",
        imgNames: [
            "img-app-feature-1",
            "img-app-feature-2",
            "img-app-feature-3",
            "img-app-feature-4",
            "img-app-feature-5",
        ],
    },
    suggestion: {
        problem: {
            imgName: "img_difficulty",
        },
        solution: {
            imgName: "tti_ask_to_apply",
        },
        click: {
            imgName: "img_click_arrow_down",
        },
    },
    apply: {
        btn: {
            initial: "img-apply-btn",
            clicked: "img-apply-btn-clicked",
        },
        title: "tti_save_time",
        before: "tti_avg_time_to_transfer",
        after: "tti_talentx_time_to_transfer",
        effect: "tti_talentx_time_save_rate",
    },
    offer: {
        title: "tti_weekly_offers",
        offer: "img_offer_alarms",
    },
    reason: {
        title: "tti_why_talentx",
        cards: [
            {
                title: "tti_reason_1",
                profile: "10년 차 HR매니저\n한신환",
                visual: "img_talent_manager",
                desc: "“이직에 대한 두려움은 누구나 가지고 있습니다. 저는 귀하가 걱정하고 계신 걱정과 두려움을 대신 해결해주는 일을 하고 있습니다. 저를 믿고 당신의 커리어를 맡겨주세요.”",
            },
            {
                title: "tti_reason_2",
                visual: "img_client_fitt",
                desc: "거기다 모두 유망 IT기업이니까!",
            },
            {
                title: "tti_reason_3",
                visual: "img_hand_shaking",
                desc: "10년 노하우를 통해 빠르고 효율적으로\n일자리를 매칭시켜 드립니다.",
            },
        ],
    },
    timing: {
        title: "img_perfect_timing",
        visual: "img_d_clock",
    },
    reviewSection: {
        title: "tti_easiest_way_to_move",
        reviews: [
            {
                subject:
                    "이직 결심하고 몇주 안되서 바로,\n원하는 조건으로 이직했어요.",
                review: "“교수님 추천으로 취업한 곳에서 실전 개발보다는 문서작업과 잡무를 더 하는것 같아 고심하다가 지인 소개로 알게된 탤런트엑스를 통해 원하는 연봉으로 바로 이직했어요. 현재는 오롯이 개발에만 집중 할 수 있고 지금 회사 대표님이 슈퍼개발자라서 많이 배울수 있어 만족스럽게 다니고 있습니다.”",
                writer: "탤런트엑스를 통해 이직하신 이OO님",
                rating: 4,
            },
            {
                subject:
                    "기존에 원했던 것보다\n훨씬 더 높은 연봉으로 이직했어요.",
                review: "“기존 회사에서 3년 정도 일했는데 매년 연봉 재계약 할때 급여 상승폭이 열심히 한만큼 높지 않아서 이직을 고민하다가 탤런트엑스를 상당히 높은 연볻으로 이직했습니다. 약간 부담은 되지만 더 책임감을 가지고 열심히 해야겠다는 생각이 드니깐 개발자로서 더 빨리 성장할것 같아 좋습니다.”",
                writer: "탤런트엑스를 통해 이직하신 정OO님",
                rating: 5,
            },
        ],
    },
    target: {
        title: "tti_targeting",
        persona: [
            "이직이 내 평생 처음이다",
            "이직 준비가 어려워 포기한 경험이 있다.",
            "현재 급여가 만족스럽지 못하다.",
            "나의 가치를 높게 보는 곳으로 이직하고 싶다.",
            "좋은 제안이 오면 이직할 생각이 있다.",
        ],
    },
    secret: {
        title: "tti_privacy_keeping",
    },
    onboarding: {
        title: "tti_how_to_join",
        steps: [
            {
                number: "icon_step_one",
                desc: "탑승 신청하기를 누르고\n신청서 작성하기",
            },
            {
                number: "icon_step_two",
                desc: "신청 승인 받은 후\n탤런트엑스 앱 설치하기",
            },
            {
                number: "icon_step_three",
                desc: "탤런트엑스 앱에서\n회원가입하기",
            },
            {
                number: "icon_step_four",
                desc: "프로필을 작성하면\n면접 제안이 알아서!",
            },
        ],
        notices: [
            "코딩 테스트는 영문으로 진행됩니다",
            "코딩 테스트시 웹캠이 달린 컴퓨터 혹은, 노트북이 있어야 진행 가능합니다. (스마트폰 응시 불가)",
            "코딩 테스트 점수 미달로 불합격 하셨을 경우 이력서, 포트폴리오 제출을 통해 나머지 점수를 충당하실 수 있습니다.",
        ],
    },
    faq: [
        {
            question: "Q. 연봉로켓 탑승 자격이 어떻게 되나요?",
            answer: "A. 경력연차와 상관없이 현재 기업에서 SW개발자로 재직 중이거나 프리랜서 SW개발자로 활동 중이라면 누구나 지원이 가능합니다. 다만 코딩테스트에 합격하셔야 연봉로켓에 탑승이 가능합니다.",
        },
        {
            question:
                "Q. 코딩 테스트 진행 시 웹캠 외 추가로 설치해야 하는 프로그램은 없나요?",
            answer: "A. 추가 설치할 필요 없이 웹캠만 준비되어 있으면 됩니다.",
        },
        {
            question: "Q. 마스크를 끼고 코딩 테스트 진행이 가능한가요?",
            answer: "A. 부정행위 방지를 위해 마스크 없이 응시하는 것을 원칙으로 하고 있습니다. 마스크를 벗을 수 있는 환경에서 진행해주세요.",
        },
        {
            question: "Q. 테스트 진행 중 검색하는 건 부정행위인가요?",
            answer: "A. 응시 도중 검색하는 것은 부정행위에 해당하지 않습니다. 다만, 다른 사람의 코드를 그대로 가져와 사용하는 것은 부정행위로 간주될 수 있으니 주의하시기 바랍니다.",
        },
        {
            question: "Q. 응시 가능한 언어는 어떤 게 있나요?",
            answer: "C, Clojure, Cobol, C++, C++14, C#, D, Erlang, Elixir, Fortran, F#, Go, Groovy, Haskell, Java 7, Java 8, Javascript, Julia, Kotlin, Lua, Objective-C, OCaml, Pascal, Perl, PHP, PyPy, PyPy3, Python 2, Python 3, R, Racket, Ruby, Rust, Common Lisp (SBCL), Scala, Smalltalk, Swift, VB.NET, BASH, Octave, Tcl, MySQL, Oracle, MS SQL, DB2가 있습니다.",
        },
        {
            question: "Q. 면접 제안이 오면 무조건 봐야하나요?",
            answer: "A. 제안이 왔다고 해서 무조건 면접을 봐야 하는 것은 아닙니다. 유효기간 이내 제안을 검토하시고 응답하시면 됩니다.",
        },
    ],
    fixedBtn: {
        title: "연봉로켓 14호기 탑승 예약 중 (~ 2.28)",
    },
};
