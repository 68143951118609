export const skills = [
    "전체",
    ".NET Framework",
    ".NET Core",
    "Angular",
    "AWS",
    "Azure",
    "C",
    "C#",
    "C++",
    "Codeigniter",
    "CSS",
    "Dart",
    "Django",
    "Electron",
    "Express.js",
    "ExtJs 6",
    "Firebase",
    "Flask",
    "Flutter",
    "GCP",
    "HTML",
    "HTML5",
    "Java",
    "JavaScript",
    "Jenkins",
    "JPA",
    "jQuery",
    "JSP",
    "Keras",
    "Knex",
    "Kotlin",
    "Kubernetes",
    "Linux",
    "MFC",
    "mongoDB",
    "MyBatis",
    "MySql",
    "Nginx",
    "Node.js",
    "Objective-C",
    "OpenCV",
    "Oracle",
    "PHP",
    "PostgreSQL",
    "Python",
    "PyTorch",
    "Qt",
    "R",
    "React Native",
    "ReactJS",
    "SAP(ABAP)",
    "SCSS",
    "Spring / Spring Boot",
    "SQL",
    "Swift",
    "Tensorflow",
    "TypeORM",
    "TypeScript",
    "Visual Basic .NET",
    "Visual C++",
    "Vue.js",
];

export const skillRates = [
    { rate: 1, desc: "이제 막 공부를 시작했어요." },
    { rate: 2, desc: "이제 약간 감을 잡았어요." },
    { rate: 3, desc: "적용 사례를 이해할 수 있어요." },
    { rate: 4, desc: "적용 사례를 수정할 수 있어요." },
    { rate: 5, desc: "미니 프로젝트에 활용할 수 있어요." },
    { rate: 6, desc: "팀 프로젝트에 활용할 수 있어요." },
    { rate: 7, desc: "상용 프로젝트에 활용할 수 있어요." },
    { rate: 8, desc: "다수의 상용 프로젝트에 활용했어요." },
    { rate: 9, desc: "다른 사람을 가르칠 수 있어요." },
    { rate: 10, desc: "전문가 수준으로 리드할 수 있어요." },
];
