import firebase, { app } from "firebase/app";
import { firestore } from "apis/firebase";
import axios from "axios";
import { API_URL } from "../common/constants";

export const getTalentUserInvitorById = async (invitorId) => {
    try {
        const response = await axios.get(`${API_URL}/invitors/${invitorId}`);
        return response.data;
    } catch (error) {
        alert("유효하지 않은 초대 링크입니다.");
    }
};

export const getTalentApplcationByPhone = async (phone) => {
    try {
        const response = await axios.get(
            `${API_URL}/application/talent/${phone}`
        );
        return response.data;
    } catch (error) {
        alert(
            "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
        );
    }
};

export const putTalentDocument = async (data, config) => {
    try {
        const response = await axios.put(
            `${API_URL}/talent/documents`,
            data,
            config
        );
        console.log("putTalentDocument response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("putTalentDocument error", error);
        return false;
    }
};

export const putTalentApplication = async (
    application,
    setIsFetching,
    setOpen
) => {
    console.log("application", application);
    try {
        await axios.put(`${API_URL}/applications/talents`, {
            email: application.email,
            name: application.name,
            phone: application.phone,
            birth: application.birth,
            school: application.school,
            "school-state": application.eduState,
            major: application.major,
            bootcamp: application.bootcamp,
            working: application.working,
            "years-of-exp": application.yearsOfExperience,
            position: application.position,
            "test-date": application.testDate,
            "test-type": application.testType,
            channel: application.channel,
            mentor: application.mentor,
            companies: application.companies ?? [],
            invitor: application.invitor,
            "document-url": application.documentURL,
            "website-url": application.websiteURL,
        });

        setOpen(true);
        console.log("Success");
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401)
                alert(
                    "인증 기한이 만료되었습니다.\n이메일 인증을 새로 요청해주세요."
                );
            else
                alert(
                    "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
                );
            setIsFetching(false);
        } else
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
    } finally {
        setIsFetching(false);
    }
};

export const putCompanyDocument = async (data, config) => {
    try {
        const response = await axios.put(
            `${API_URL}/company/documents`,
            data,
            config
        );
        console.log("putCompanyDocument response", response);
        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log("putCompanyDocument error", error);
        return false;
    }
};

export const putCCApplication = async (application, setIsFetching, setOpen) => {
    try {
        await axios.put(`${API_URL}/applications/ccs`, {
            membership: application.membership,
            name: application.companyName,
            "registration-number": application.businessNumber,
            "business-email": application.businessEmail,
            phone: application.phone,
            "zip-code": application.zipCode,
            address1: application.address1,
            address2: application.address2,
            "document-url": application.documentURL,
            users: application.users,
            "num-of-employees": application.numOfEmployees,
            "num-of-dev": application.numOfSWEngineers,
            "has-dev-leader": application.leader,
            revenue: application.revenue,
            fund: application.fund,
            channel: application.channel,
            positions: application.positions ?? [],
        });

        setOpen(true);
        console.log("Success");
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401)
                alert(
                    "인증 기한이 만료되었습니다.\n이메일 인증을 새로 요청해주세요."
                );
            else {
                alert(
                    "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
                );
                console.log(err.response.data);
            }
            setIsFetching(false);
        } else
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
    } finally {
        setIsFetching(false);
    }
};

export const onTalentApply = (application, setIsFetching, setOpen) => {
    const talent = application;
    const result = firestore
        .collection("talent_applications")
        .add({
            name: talent.name,
            birth: talent.birth,
            email: talent.email,
            phone: talent.phone,
            school: talent.school,
            major: talent.major,
            school_state: talent.eduState,
            bootcamp: talent.bootcamp,
            working: talent.working,
            years_of_experience: talent.yearsOfExperience,
            position: talent.position,
            test_type: talent.testType,
            test_date: talent.testDate,
            channel: talent.channel,
            mentor: talent.mentor,
            submission_date: new Date(),
        })
        .then(function (docRef) {
            //setIsFetching(false);
            //setOpen(true);
            console.log(
                "Talent Application Document written with ID: ",
                docRef.id
            );
            return true;
        })
        .catch(function (error) {
            setIsFetching(false);
            console.log("Error adding document: ", error);
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
            return false;
        });
    return result;
};

export const onCompanyApply = (application, setIsFetching, setOpen) => {
    const company = application;
    const result = firestore
        .collection("company_applications")
        .add({
            company_name: company.companyName,
            business_number: company.businessNumber,
            phone: company.phone,
            zip_code: company.zipCode,
            address1: company.address1,
            address2: company.address2,
            users: company.users,
            num_of_employeess: company.numOfEmployees,
            num_of_sw_engineer: company.numOfSWEngineers,
            leader: company.leader,
            revenue: company.revenue,
            fund: company.fund,
            channel: company.channel,
            positions: company.positions ?? [],
            submission_date: new Date(),
        })
        .then(function (docRef) {
            //setIsFetching(false);
            //setOpen(true);
            console.log(
                "Company Application Document written with ID: ",
                docRef.id
            );
            return true;
        })
        .catch(function (error) {
            setIsFetching(false);
            console.log("Error adding document: ", error);
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
            return false;
        });
    return result;
};

export const onDemoRequest = (application, setIsFetching, setOpen) => {
    const company = application;
    const result = firestore
        .collection("company_demo_requests")
        .add({
            company_name: company.companyName,
            name: company.name,
            email: company.email,
            phone: company.phone,
            demo_date: company.demoDateTime,
            demo_type: company.demoType,
            postal_code: company.postalCode,
            address: company.address,
            address_detail: company.addressDetail,
            num_of_hire: company.numOfHire,
            channel: company.channel,
            submission_date: new Date(),
        })
        .then(function (docRef) {
            setIsFetching(false);
            setOpen(true);
            console.log(
                "Company Demo Request Document written with ID: ",
                docRef.id
            );
            return true;
        })
        .catch(function (error) {
            setIsFetching(false);
            console.log("Error adding document: ", error);
            alert(
                "문제가 발생했습니다.\n지속될 경우 문의하기를 통해 저희에게 알려주세요."
            );
            return false;
        });
    return result;
};
