import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPhotos } from "../../redux/slice/profileSlice";
import { Helmet } from "react-helmet";
import { ServiceHeaderD } from "../components";
import {
    Container,
    Box,
    Typography,
    Divider,
    Button,
    Backdrop,
} from "@material-ui/core";
import { TXImage } from "../../components/tx";
import { useStyles } from "./styleProfilePhoto";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
    putTalentProfileThumbnail,
    postTalentProfilePhoto,
    swapTalentProfilePhoto,
    deleteTalentProfilePhoto,
} from "../../apis/profile/profile";
import _ from "lodash";

const ProfilePhoto = (props) => {
    const { token, photos } = useSelector((state) => state.profile);
    const isMaxNumOfPhotos =
        photos.filter((photo) => photo.url !== null).length >= 6;
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [dragId, setDragId] = useState();
    const [open, setOpen] = useState(false);
    const [workingOnPhotoIndex, setWorkingOnPhotoIndex] = useState(null);
    const [originalPhoto, setOriginalPhoto] = useState(null);
    const [crop, setCrop] = useState({ aspect: 1 / 1 });

    window.URL = window.URL || window.webkitURL;

    useEffect(() => {
        const fileSelect = document.getElementById("fileSelect");
        const fileElem = document.getElementById("fileElem");
        fileSelect.addEventListener(
            "click",
            function (e) {
                if (fileElem) {
                    fileElem.click();
                }
                e.preventDefault(); // "#" 해시로 이동을 방지
            },
            false
        );
    }, []);

    // 파일 저장
    const openImageFiles = (e) => {
        const files = e.target.files;
        console.log("openImageFiles", files);
        const photosDeepCopy = JSON.parse(JSON.stringify(photos));
        for (let i = 0; i < files.length; i++) {
            let emptyFrameIndex = photosDeepCopy.findIndex(
                (photo) => photo.url === null
            );
            if (emptyFrameIndex !== -1) {
                console.log("emptyFrameIndex", emptyFrameIndex);
                setWorkingOnPhotoIndex(emptyFrameIndex);
                console.log("photosDeepCopy", photosDeepCopy);
                photosDeepCopy[emptyFrameIndex].url =
                    window.URL.createObjectURL(files[i]);
                photosDeepCopy[emptyFrameIndex].file = files[i];
            }
        }
        dispatch(setPhotos(photosDeepCopy));
        setOpen(true);
    };

    useEffect(() => {
        async function uploadImageFiles() {
            console.log("uploadImageFiles");
            for (let i = 0; i < photos.length; i++) {
                if (photos[i].file !== null) {
                    const headers = {
                        "Content-Type": "multipart/form-data",
                        "api-token": token,
                        index: photos[i].order,
                    };
                    const data = new FormData();
                    data.append("image", photos[i].file);
                    console.log(data);
                    console.log({ headers });
                    const postResult = await postTalentProfilePhoto(data, {
                        headers,
                    });
                    console.log("postTalentProfilePhoto", postResult);
                    if (!postResult) history.push("/profile/access-expired");

                    if (photos[i].order === 0) {
                        const thumbnailPutResult =
                            await putTalentProfileThumbnail(data, {
                                headers,
                            });
                        console.log(
                            "putTalentProfileThumbnail",
                            thumbnailPutResult
                        );
                        if (!thumbnailPutResult)
                            history.push("/profile/access-expired");
                    }
                }
            }
        }
        uploadImageFiles();
    }, [photos, token]);

    const getCroppedPhoto = async () => {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = originalPhoto.naturalWidth / originalPhoto.width;
            const scaleY = originalPhoto.naturalHeight / originalPhoto.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
                originalPhoto,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            canvas.toBlob(
                (blob) => {
                    blob.name = `cropped-${workingOnPhotoIndex}.png`;
                    const photosDeepCopy = JSON.parse(JSON.stringify(photos));
                    photosDeepCopy[workingOnPhotoIndex].url =
                        URL.createObjectURL(blob);
                    photosDeepCopy[workingOnPhotoIndex].file = blob;
                    dispatch(setPhotos(photosDeepCopy));
                },
                "image/png",
                1
            );
        } catch (e) {
            console.log("crop the image");
        } finally {
            setOpen(false);
        }
    };

    const handleHover = (e) => {
        setWorkingOnPhotoIndex(e.target.id);
    };

    const handleDeletePhoto = async (e) => {
        const photosDeepCopy = _.cloneDeep(photos);
        photosDeepCopy[e.target.id].url = null;
        photosDeepCopy[e.target.id].file = null;
        dispatch(setPhotos(photosDeepCopy));
        const headers = {
            "Content-Type": "multipart/form-data",
            "api-token": token,
            index: photosDeepCopy[e.target.id].order,
        };
        const deleteResult = await deleteTalentProfilePhoto({ headers });
        if (!deleteResult) history.push("/profile/access-expired");
    };

    const handleDrag = (e) => {
        setDragId(e.currentTarget.id);
    };

    const handleDrop = async (e) => {
        //const photosDeepCopy = JSON.parse(JSON.stringify(photos));
        const photosDeepCopy = _.cloneDeep(photos);

        const dragPhoto = photosDeepCopy.find((photo) => photo.id === dragId);
        const dropPhoto = photosDeepCopy.find(
            (photo) => photo.id === e.currentTarget.id
        );

        //Disallow to drop on itself or swap with empty frame
        if (!dropPhoto.url || dragPhoto.order === dropPhoto.order) return false;

        const dragPhotoOrder = dragPhoto.order;
        const dropPhotoOrder = dropPhoto.order;

        const photosNewState = photosDeepCopy.map((photo, idx) => {
            if (photo.id === dragId) {
                photo.order = dropPhotoOrder;
            }
            if (photo.id === e.currentTarget.id) {
                photo.order = dragPhotoOrder;
            }
            //photo.file = photos[idx].file;
            return photo;
        });

        console.log("photos after drag", photosNewState);

        dispatch(setPhotos(photosNewState));

        if (dragPhotoOrder !== dropPhotoOrder) {
            const headers = {
                "Content-Type": "application/json",
                "api-token": token,
                index: dragPhotoOrder,
            };
            const swapResult = await swapTalentProfilePhoto(
                { swapIndex: dropPhotoOrder },
                { headers }
            );
            if (!swapResult) history.push("/profile/access-expired");
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필 사진</title>
            </Helmet>
            <ServiceHeaderD />
            <Container maxWidth="sm" className={classes.container}>
                <Box className={classes.header}>
                    <TXImage
                        imgName="icon-left-arrow-32-dp"
                        onClick={() => history.goBack()}
                    />
                    <Typography className={classes.title}>
                        프로필 사진 편집
                    </Typography>
                </Box>

                <Divider />
                <Box className={classes.guideWrap}>
                    <TXImage imgName="icon-attention-filled-blue-24" />
                    <Typography className={classes.guide}>
                        서비스 이용을 위해 사진을 등록해주세요.
                    </Typography>
                </Box>
                <Typography className={classes.subGuide}>
                    - 사진이 없으면 채용 담당자에게 프로필이 노출되지 않아요.
                    <br />- 최소 1장 이상의 사진을 동록해주세요.
                </Typography>
                <Box className={classes.photoWrap}>
                    {photos
                        .map((photo, index) => (
                            <Box
                                key={photo.id}
                                draggable={!!photo.url}
                                id={photo.id}
                                order={photo.order}
                                onDragOver={(ev) => ev.preventDefault()}
                                onDragStart={handleDrag}
                                onDrop={handleDrop}
                                className={
                                    photo.order === 0
                                        ? classes.firstPhotoFrame
                                        : classes.photoFrame
                                }
                            >
                                {photo.order === 0 && (
                                    <Box className={classes.firstPhotoBadge}>
                                        <Typography
                                            className={
                                                classes.firstPhotoBadgeTitle
                                            }
                                        >
                                            대표
                                        </Typography>
                                    </Box>
                                )}
                                {photo.url && (
                                    <img
                                        id={photo.id}
                                        src={photo.url}
                                        className={classes.photo}
                                    />
                                )}
                                {photo.url && photo.order !== 0 && (
                                    <Box className={classes.photoDeleteBtn}>
                                        <img
                                            id={index}
                                            src={require("assets/icon-close-24-dp.png")}
                                            className={
                                                classes.photoDeleteBtnIcon
                                            }
                                            onClick={handleDeletePhoto}
                                        />
                                    </Box>
                                )}
                            </Box>
                        ))
                        .sort((a, b) => a.order - b.order)}
                </Box>
                <Typography className={classes.tip}>
                    사진을 끌어서 순서를 변경할 수 있어요
                </Typography>
                <Button
                    id="fileSelect"
                    variant={isMaxNumOfPhotos ? "outlined" : "contained"}
                    size="large"
                    disabled={isMaxNumOfPhotos}
                    disableElevation
                >
                    <TXImage imgName="icon-camera-black-24" />
                    <Typography className={classes.btnText}>
                        사진 등록
                    </Typography>
                </Button>
                <input
                    type="file"
                    id="fileElem"
                    //multiple
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={openImageFiles}
                />
            </Container>
            <Backdrop
                className={classes.backdrop}
                open={open}
                //onClick={() => setOpen((prev) => !prev)}
            >
                <Box className={classes.photoEditPopup}>
                    <Box className={classes.photoEditPopupHeader}>
                        <Typography
                            className={classes.photoEditPopupHeaderTitle}
                        >
                            프로필 사진 자르기
                        </Typography>
                        <img
                            src={require("assets/icon-close-24-dp.png")}
                            onClick={() => setOpen((prev) => !prev)}
                            alt="close"
                            className={classes.photoEditPopupHeaderIconBtn}
                        />
                    </Box>
                    <Box className={classes.photoEditPopupBody}>
                        <Box className={classes.photoEditPopupBodyFrame}>
                            <ReactCrop
                                src={
                                    workingOnPhotoIndex !== null
                                        ? photos[workingOnPhotoIndex].url
                                        : null
                                }
                                onImageLoaded={(image) => {
                                    console.log("onImageLoaded:", image);
                                    const croppingLength = Math.min(
                                        image.width,
                                        image.height
                                    );
                                    setCrop((prev) => ({
                                        ...prev,
                                        width: croppingLength,
                                        height: croppingLength,
                                        x: (image.width - croppingLength) / 2,
                                        y: (image.height - croppingLength) / 2,
                                    }));
                                    setOriginalPhoto(image);
                                    return false;
                                }}
                                crop={crop}
                                onChange={setCrop}
                            />
                        </Box>
                        <Button
                            className={classes.photoEditPopupBodyBtn}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={getCroppedPhoto}
                        >
                            등록하기
                        </Button>
                    </Box>
                </Box>
            </Backdrop>
        </React.Fragment>
    );
};

export default ProfilePhoto;
