import React, { useState, useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    Grid,
    Hidden,
    Button,
    Link,
    Divider,
} from "@material-ui/core";
import Slider from "react-slick";
import { Link as RouterLink } from "react-router-dom";
import { createKakaoShareButton } from "apis/kakao/share";
import { Helmet } from "react-helmet";

import { content } from "./dataPromotionD";
import { useStyles } from "./stylePromotionD";

const PromotionD = (props) => {
    const [clicked, setClicked] = useState(false);
    const classes = useStyles({ clicked });

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
        createKakaoShareButton();
    }, []);

    function _TXImage(props) {
        const { srcObj, ...rest } = props;
        return (
            <img
                src={srcObj.image}
                srcSet={`${srcObj.image2x} 2x, ${srcObj.image3x} 3x`}
                alt="auto"
                {...rest}
            ></img>
        );
    }

    function TXImage(props) {
        const { imgName, ...rest } = props;
        return (
            <img
                src={require(`assets/${imgName}.png`)}
                srcSet={`${require(`assets/${imgName}@2x.png`)} 2x, ${require(`assets/${imgName}@3x.png`)} 3x`}
                alt={imgName}
                {...rest}
            ></img>
        );
    }

    const handleApplyBtn = () => {
        setClicked((prev) => !prev);
        setTimeout(() => {
            props.history.push("/talent-application");
        }, 300);
    };

    const handleScrollTop = () => {
        window.scroll({ top: 0, behavior: "smooth" });
    };

    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 연봉로켓</title>
            </Helmet>
            <Box className={classes.headSection}>
                <Container maxWidth="lg" className={classes.haedImgsContainer}>
                    <div className={classes.headImgHelper}></div>
                    {content.head.items.map((item, idx) => (
                        <Hidden mdDown={item.hidden}>
                            <img
                                className={classes[`headImg${idx + 1}`]}
                                src={item.image}
                                srcSet={`${item.image2x} 2x, ${item.image3x} 3x`}
                                alt="money packs"
                            ></img>
                        </Hidden>
                    ))}
                </Container>
            </Box>
            <Box className={classes.promotionSection}>
                {content.promotion.items.map((item) => (
                    <img
                        className={classes.promotionImg}
                        src={item.image}
                        srcSet={`${item.image2x} 2x, ${item.image3x} 3x`}
                        alt="money packs"
                    ></img>
                ))}
                <Button
                    variant="contained"
                    className={classes.promotionBtn}
                    onClick={() => props.history.push("/talent-application")}
                >
                    {content.promotion.btn.label}
                </Button>
            </Box>
            {/* <Box className={classes.companySection}>
                <img
                    src={content.company.title.image}
                    srcSet={`${content.company.title.image2x} 2x, ${content.company.title.image3x} 3x`}
                    alt="money packs"
                ></img>
                <Typography className={classes.companySectionDesc}>
                    {content.company.desc}
                </Typography>
                <Grid container spacing={3}>
                    {content.company.cards.map((item, idx) => (
                        <Grid key={idx} item xs={6}>
                            <Box className={classes.companyCard}>
                                <div className={classes.companyLogo}></div>
                                <div className={classes.companyInfo}>
                                    <p className={classes.companyName}>
                                        {item.name}
                                        <br />
                                        <span
                                            className={classes.companyFeature}
                                        >
                                            {item.point}
                                        </span>
                                    </p>
                                </div>

                                <div className={classes.companyDetailBtn}>
                                    {item.btn.label}
                                </div>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box> */}
            <Box className={classes.huddleSection}>
                <img
                    src={content.huddle.quotes.image}
                    srcSet={`${content.huddle.quotes.image2x} 2x, ${content.huddle.quotes.image3x} 3x`}
                    alt="money packs"
                ></img>
                <Typography className={classes.huddleSectionTitle}>
                    {content.huddle.title}
                </Typography>
                <Box className={classes.huddleFrame}>
                    <TXImage
                        imgName={content.huddle.frame.title}
                        alt="resume"
                    ></TXImage>
                    <Typography className={classes.huddleFrameDesc}>
                        {content.huddle.frame.desc}
                    </Typography>
                    <Grid container>
                        {content.huddle.frame.items.map((item) => (
                            <Grid item xs={6}>
                                <TXImage
                                    imgName={item.image}
                                    alt="resume"
                                    className={classes.huddleImg}
                                ></TXImage>
                                <TXImage
                                    imgName={item.label}
                                    alt="resume"
                                ></TXImage>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Box className={classes.appSection}>
                <TXImage imgName={content.app.title}></TXImage>
                <Box className={classes.appSlider}>
                    <Slider {...settings}>
                        {content.app.imgNames.map((item, idx) => (
                            <TXImage key={idx} imgName={item}></TXImage>
                        ))}
                    </Slider>
                </Box>
            </Box>
            <Box className={classes.suggestionSectionTop}>
                <TXImage
                    imgName={content.suggestion.problem.imgName}
                    className={classes.suggestionSectionTopImg}
                ></TXImage>
            </Box>
            <Box className={classes.suggestionSectionBottom}>
                <TXImage
                    imgName={content.suggestion.solution.imgName}
                    className={classes.suggestionImg}
                ></TXImage>
                <TXImage imgName={content.suggestion.click.imgName}></TXImage>
            </Box>
            <Box className={classes.applyBox}>
                {/* <RouterLink to="/talent-application"></RouterLink> */}
                <TXImage
                    imgName={content.apply.btn.initial}
                    className={classes.applyBtnImg}
                    onClick={handleApplyBtn}
                />
                <TXImage
                    imgName={content.apply.btn.clicked}
                    className={classes.applyBtnClickedImg}
                />
                <TXImage
                    imgName={content.apply.title}
                    className={classes.reassurance}
                />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        className={classes.leftRoundBox}
                    >
                        <TXImage imgName={content.apply.before} />
                    </Grid>
                    <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        className={classes.rightRoundBox}
                    >
                        <TXImage imgName={content.apply.after} />
                        <TXImage
                            imgName={content.apply.effect}
                            className={classes.timeSaving}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.offerSection}>
                <TXImage imgName={content.offer.title} />
                <TXImage
                    imgName={content.offer.offer}
                    className={classes.offerImg}
                />
            </Box>
            <Box className={classes.reasonSection}>
                <Container maxWidth="md">
                    <TXImage imgName={content.reason.title} />
                    <Grid
                        container
                        className={classes.reasonSectionFrame}
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <Box className={classes.reasonCard}>
                                <Box className={classes.reasonCardTop}>
                                    <TXImage
                                        imgName={content.reason.cards[0].title}
                                    />
                                    <TXImage
                                        imgName={content.reason.cards[0].visual}
                                        className={classes.reasonCardImg}
                                    />
                                    <Typography
                                        className={classes.reasonManagerProfile}
                                    >
                                        {content.reason.cards[0].profile}
                                    </Typography>
                                </Box>
                                <Typography className={classes.reasonDesc}>
                                    {content.reason.cards[0].desc}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={classes.reasonSingleCard}>
                                <TXImage
                                    imgName={content.reason.cards[1].title}
                                />
                                <TXImage
                                    imgName={content.reason.cards[1].visual}
                                    className={classes.reasonSecondCardImg}
                                />
                                <Typography
                                    className={classes.reasonITCompanies}
                                >
                                    {content.reason.cards[1].desc}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.reasonThirdCard}>
                                <TXImage
                                    imgName={content.reason.cards[2].title}
                                />
                                <Typography className={classes.reasonDesc3}>
                                    {content.reason.cards[2].desc}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.timingSection}>
                <TXImage imgName={content.timing.title} />
                <TXImage
                    imgName={content.timing.visual}
                    className={classes.timingImg}
                />
            </Box>
            <Box className={classes.reviewSection}>
                <TXImage
                    imgName={content.reviewSection.title}
                    className={classes.reviewSectionTitle}
                />
                {content.reviewSection.reviews.map((item, idx) => (
                    <Box key={idx} className={classes.reviewFrame}>
                        {!!((idx + 1) % 2) && (
                            <Box className={classes.reviewCardAccessory} />
                        )}
                        <Box className={classes.reviewCard}>
                            <Typography className={classes.reviewCardTitle}>
                                {item.subject}
                            </Typography>
                            <Typography className={classes.reviewCardContent}>
                                {item.review}
                            </Typography>
                            <Typography className={classes.reviewCardReviewer}>
                                {item.writer}
                            </Typography>
                            {new Array(item.rating)
                                .fill(null)
                                .map((item, idx) => (
                                    <img
                                        key={idx}
                                        src={require("assets/ic-toggle-star-on-24.png")}
                                        srcSet={`${require("assets/ic-toggle-star-on-24@2x.png")} 2x, ${require("assets/ic-toggle-star-on-24@3x.png")} 3x`}
                                        alt="rating"
                                        className={classes.reviewCardStarIcon}
                                    ></img>
                                ))}
                        </Box>
                        {!!(idx % 2) && (
                            <Box className={classes.reviewCardAccessory} />
                        )}
                    </Box>
                ))}
            </Box>
            <Box className={classes.targetSection}>
                <Container maxWidth="sm">
                    <TXImage
                        imgName={content.target.title}
                        className={classes.targetTitle}
                    />
                    {content.target.persona.map((item, idx) => (
                        <Box key={idx} className={classes.targetBoxList}>
                            <TXImage
                                imgName={"ic-check"}
                                className={classes.targetBoxListIcon}
                            />
                            <span className={classes.targetBoxListItem}>
                                {item}
                            </span>
                        </Box>
                    ))}
                </Container>
            </Box>
            <Box className={classes.secretBox}>
                <TXImage
                    imgName={content.secret.title}
                    className={classes.secretMsgImg}
                />
            </Box>
            <Box className={classes.onboardingBox}>
                <Container maxWidth="sm">
                    <TXImage
                        imgName={content.onboarding.title}
                        className={classes.onboardingTitleImg}
                    />

                    <Grid
                        container
                        spacing={3}
                        className={classes.onboardingSteps}
                    >
                        {content.onboarding.steps.map((item) => (
                            <Grid item xs={6}>
                                <Box className={classes.onboardingStepCard}>
                                    <TXImage
                                        imgName={item.number}
                                        className={classes.onboardingStepIcon}
                                    />
                                    <Typography
                                        className={classes.onboardingStepDesc}
                                    >
                                        {item.desc}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    {/* {content.onboarding.notices.map((item) => (
                        <Typography className={classes.onboardingNotice}>
                            &#8226; {item}
                        </Typography>
                    ))} */}
                </Container>
            </Box>

            {/* <Box className={classes.faqBox}>
                <Container maxWidth="sm">
                    <Box>
                        <span className={classes.faqBoxTitle}>
                            자주 묻는 질문
                        </span>
                        <img
                            src={
                                open
                                    ? require("assets/icon-nav-chevron-up-20.png")
                                    : require("assets/icon-nav-chevron-down-20.png")
                            }
                            alt="collapse"
                            className={classes.faqBoxToggleBtn}
                            onClick={handleOpen}
                        ></img>
                    </Box>
                    <Divider className={classes.thickDivider}></Divider>
                    <Box className={classes.quas} hidden={!open}>
                        {content.faq.map((item, idx) => (
                            <>
                                <Typography className={classes.faq}>
                                    {item.question}
                                </Typography>
                                <Typography className={classes.answer}>
                                    {item.answer}
                                </Typography>
                                <Divider
                                    className={classes.thinDivider}
                                ></Divider>
                            </>
                        ))}
                    </Box>
                </Container>
            </Box> */}

            <Box className={classes.phantomFixedBtnsBox}></Box>
            <Box className={classes.fixedBtnsBox}>
                <Box className={classes.fixedTopBtnsBox}>
                    <Link
                        href="https://talentx.channel.io/"
                        target="_blank"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                    >
                        <Box className={classes.fixedTopLeftBtn}>문의하기</Box>
                    </Link>
                    <Box
                        className={classes.fixedTopRightBtn}
                        onClick={handleScrollTop}
                    >
                        <img
                            src={require("assets/icon-nav-chevron-up-20.png")}
                            srcSet={`${require("assets/icon-nav-chevron-up-20@2x.png")} 2x, ${require("assets/icon-nav-chevron-up-20@3x.png")} 3x`}
                            alt="scroll top button"
                        />
                    </Box>
                </Box>
                <Box className={classes.fixedBottomBtnsBox}>
                    <Typography className={classes.fixedBottomTitle}>
                        {content.fixedBtn.title}
                    </Typography>
                    <RouterLink
                        to="/talent-application"
                        className={classes.fixedBottomLeftBtn}
                    >
                        탑승 신청하기 (100명)
                    </RouterLink>
                    <Box
                        id="kakaoShareBtn"
                        className={classes.fixedBottomRightBtn}
                    >
                        <img
                            src={require("assets/icon-kakao.png")}
                            srcSet={`${require("assets/icon-kakao@2x.png")} 2x, ${require("assets/icon-kakao@3x.png")} 3x`}
                            style={{
                                width: 24,
                                height: 24,
                                marginRight: 8,
                            }}
                            alt="kakao share button"
                        />
                        공유하기
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default PromotionD;
