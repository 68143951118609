import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { faqs } from "data";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(17, 0, 7),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(10, 0, 4),
        },
        backgroundColor: "white",
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            padding: "0 32px",
        },
    },
    largeGutter: {
        margin: theme.spacing(0, 0, 4),
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(0, 0, 3),
        },
    },
    fAQWrap: {
        minHeight: "100px",
        border: "solid 1px #dddddd",
        [theme.breakpoints.down("xs")]: {
            //height: "100px",
            margin: theme.spacing(0, 0, 2),
        },
        padding: theme.spacing(2),
    },
    question: {
        color: "#1a1a84",
    },
    answerCategory: {
        color: "#ffbbd4",
    },
}));

export default function RecruitemntFAQ(params) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return isMobile ? (
        <div className={classes.root}>
            <Container maxWidth="sm" className={classes.container}>
                <Typography variant="h6" className={classes.largeGutter}>
                    <b>자주 묻는 질문</b>
                </Typography>
                {faqs.map((faq) => (
                    <Paper
                        key={faq.id.toString()}
                        variant="outlined"
                        className={classes.fAQWrap}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.question}
                            gutterBottom
                        >
                            <b>
                                Q.{"  "}
                                {faq.question}
                            </b>
                        </Typography>
                        <Typography variant="body2">
                            <span className={classes.answerCategory}>
                                <b>A.{"   "}</b>
                            </span>
                            {faq.answer}
                        </Typography>
                    </Paper>
                ))}
            </Container>
        </div>
    ) : (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Typography variant="h6" className={classes.largeGutter}>
                    <b>자주 묻는 질문</b>
                </Typography>
                <Grid container spacing={3}>
                    {faqs.map((faq) => (
                        <Grid item xs={12} sm={6} key={faq.id.toString()}>
                            <Paper
                                variant="outlined"
                                className={classes.fAQWrap}
                            >
                                <Typography
                                    variant="subtitle2"
                                    className={classes.question}
                                    gutterBottom
                                >
                                    <b>
                                        Q.{"  "}
                                        {faq.question}
                                    </b>
                                </Typography>
                                <Typography variant="body2">
                                    <span className={classes.answerCategory}>
                                        <b>A.{"   "}</b>
                                    </span>
                                    {faq.answer}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}
