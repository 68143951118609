import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useHistory } from "react-router-dom";

import {
    TalentServiceHeaderD,
    TalentServiceHeader,
    ServiceFooterD,
    ServiceFooter,
} from "pages/components";
import {
    Container,
    Box,
    Typography,
    Divider,
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import { useStyles } from "./styleCompanyScout";
import { companies } from "./dataCompanyScout";
import Skeleton from "@material-ui/lab/Skeleton";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCompanies } from "../../apis/firestore/fetch";
import { TXImage } from "components/tx";

import Slider from "react-slick";

function shuffle(array) {
    var m = array?.length,
        t,
        i;

    // While there remain elements to shuffle…
    while (m) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
}

const CompanyScout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles({ isMobile });
    const history = useHistory();
    const [companyInfo, setCompanyInfo] = useState(null);
    useEffect(() => window.scrollTo({ top: 0 }), []);
    const priority = {
        premium: 1,
        scaleup: 2,
        lightup: 3,
        standard: 4,
        basic: 5,
        none: 10,
    };
    useEffect(() => {
        (async () => {
            const result = await getCompanies();
            setCompanyInfo(
                shuffle(
                    result?.filter(
                        (company) =>
                            company.logo &&
                            !!company.hirings.length &&
                            company.plan
                    )
                )?.sort(function (a, b) {
                    return priority[a.plan] - priority[b.plan];
                })
            );
            console.log("company-info", JSON.stringify(result));
        })();
    }, []);

    const [open, setOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [photoIdx, setPhotoIdx] = useState(0);
    const [photoLength, setPhotoLength] = useState(0);

    const handleOpen = (company, idx, length) => {
        setSelectedCompany(company);
        setPhotoIdx(idx);
        setPhotoLength(length);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function CustomPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={require("assets/icon-prev-arrow-72.png")}
                srcSet={`${require("assets/icon-prev-arrow-72@2x.png")} 2x, ${require("assets/icon-prev-arrow-72@3x.png")} 3x`}
                className={className}
                style={{
                    ...style,
                    zIndex: 1,
                    left: -90,
                    width: 72,
                    height: 72,
                }}
                onClick={onClick}
            ></img>
        );
    }

    function CustomNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={require("assets/icon-next-arrow-72.png")}
                srcSet={`${require("assets/icon-next-arrow-72@2x.png")} 2x, ${require("assets/icon-next-arrow-72@3x.png")} 3x`}
                className={className}
                style={{
                    ...style,
                    zIndex: 1,
                    right: -90,
                    width: 72,
                    height: 72,
                }}
                onClick={onClick}
            ></img>
        );
    }

    const settings = {
        initialSlide: photoIdx,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveWidth: true,
        adaptiveHeight: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        beforeChange: (current, next) => setPhotoIdx(next),
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 유망 개발팀</title>
            </Helmet>
            {isMobile ? (
                <TalentServiceHeader maxWidth="sm" />
            ) : (
                <TalentServiceHeaderD />
            )}
            {/* {isMobile && (
                <Box className={classes.headSection}>
                    <Typography className={classes.headTitle}>
                        유망 기업 개발팀이
                        <br />
                        스카우트 중입니다.
                    </Typography>
                    <img
                        src={require("assets/img-evaluator.png")}
                        srcSet={`${require("assets/img-evaluator@2x.png")} 2x, ${require("assets/img-evaluator@3x.png")} 3x`}
                        className={classes.headImg}
                        alt="evaluator"
                    ></img>
                </Box>
            )} */}
            <Box className={classes.root}>
                <Container maxWidth="lg">
                    <Box className={classes.inner}>
                        <Box className={classes.newHeadSection}>
                            <TXImage
                                imgName="icon-devteam"
                                className={classes.newHeadImg}
                            />
                            <Typography className={classes.newHeadTitle}>
                                {isMobile
                                    ? `스카우트 포지션, 기술 스택,\n개발 문화 등 검증된 기업의\n개발팀 정보를 확인해보세요.`
                                    : `스카우트 포지션, 기술 스택, 개발 문화 등\n검증된 기업의 개발팀 정보를 확인해보세요.`}
                            </Typography>
                        </Box>

                        {!companyInfo && (
                            <>
                                {[0, 1, 2].map(() => (
                                    <>
                                        <div
                                            style={{
                                                marginTop: isMobile ? 16 : 44,
                                            }}
                                        />
                                        <Skeleton
                                            variant="rect"
                                            width={"100%"}
                                            height={isMobile ? 60 : 32}
                                            animation="wave"
                                            style={{ marginBottom: 16 }}
                                        ></Skeleton>
                                        <Box className={classes.infoWrap}>
                                            <Skeleton
                                                variant="rect"
                                                width={40}
                                                height={40}
                                                animation="wave"
                                                style={{ marginRight: 16 }}
                                            />
                                            <Box>
                                                <Skeleton
                                                    variant="text"
                                                    width={200}
                                                    height={22}
                                                    animation="wave"
                                                    style={{ marginBottom: 2 }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    width={200}
                                                    height={22}
                                                    animation="wave"
                                                />
                                            </Box>
                                        </Box>
                                        <div style={{ marginTop: 16 }} />
                                        <Box className={classes.imagesWrap}>
                                            {[1, 2, 3, 4, 5].map(
                                                (item, idx) => (
                                                    <Skeleton
                                                        key={idx}
                                                        variant="rect"
                                                        width={256}
                                                        height={161}
                                                        animation="wave"
                                                        style={{
                                                            flexShrink: 0,
                                                            marginRight: 16,
                                                        }}
                                                    ></Skeleton>
                                                )
                                            )}
                                        </Box>
                                        <Skeleton
                                            variant="rect"
                                            width={"100%"}
                                            height={24}
                                            animation="wave"
                                            style={{ marginTop: 20 }}
                                        ></Skeleton>
                                        <Skeleton
                                            variant="rect"
                                            width={"100%"}
                                            height={24}
                                            animation="wave"
                                            style={{ marginTop: 20 }}
                                        ></Skeleton>
                                    </>
                                ))}
                            </>
                        )}

                        {true &&
                            companyInfo?.map((company, idx) => (
                                <Box
                                    key={idx}
                                    className={classes.newCompanyBox}
                                >
                                    <Box
                                        id={company.registrationNumber}
                                        className={classes.scrollToBox}
                                    />
                                    <Box className={classes.companyHeader}>
                                        <Typography
                                            className={classes.newSlogan}
                                        >
                                            {company.slogan}
                                        </Typography>
                                        {!isMobile &&
                                            !!company.devTeam.positions
                                                .length && (
                                                <Box
                                                    className={
                                                        classes.devTeamBtn
                                                    }
                                                    onClick={() => {
                                                        history.push(
                                                            `/scout/${company.registrationNumber}`
                                                        );
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.devTeamBtnText
                                                        }
                                                    >
                                                        개발팀 정보 보러가기
                                                    </Typography>
                                                    <TXImage imgName="icon-arrow-forward-12-white" />
                                                </Box>
                                            )}
                                    </Box>
                                    <Box className={classes.infoWrap}>
                                        <img
                                            src={company.logo?.url}
                                            className={classes.newLogo}
                                            alt="logo"
                                        ></img>
                                        <Box>
                                            <Box className={classes.company}>
                                                <Typography
                                                    className={classes.name}
                                                >
                                                    {company.name}
                                                </Typography>
                                                {company.plan === "premium" && (
                                                    <img
                                                        src={require("assets/img-premium-badge.png")}
                                                        srcSet={`${require("assets/img-premium-badge@2x.png")} 2x, ${require("assets/img-premium-badge@3x.png")} 3x`}
                                                        className={
                                                            classes.premium
                                                        }
                                                        alt="premium"
                                                    ></img>
                                                )}
                                            </Box>
                                            <Typography
                                                className={classes.service}
                                            >
                                                {company.services[0].name}
                                                <span className={classes.ceo}>
                                                    {" "}
                                                    | {company.ceo}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.imagesWrap}>
                                        {company.photos.map(
                                            (image, idx, array) => (
                                                <Box
                                                    key={idx}
                                                    className={classes.imageBox}
                                                    onClick={() =>
                                                        handleOpen(
                                                            company.registrationNumber,
                                                            idx,
                                                            array.length
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={image.url}
                                                        className={
                                                            classes.imageBox
                                                        }
                                                        alt="photo"
                                                    ></img>
                                                </Box>
                                            )
                                        )}
                                    </Box>

                                    <Divider
                                        className={classes.companyCardDivider}
                                    />
                                    <Typography className={classes.scouting}>
                                        스카우트 중
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {company.hirings.map(
                                            (position, idx) => (
                                                <Grid item xs={12} sm={6}>
                                                    <Box
                                                        className={
                                                            classes.positionWrap
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.positionName
                                                            }
                                                        >
                                                            {position.position}
                                                        </Typography>
                                                        <Box
                                                            className={
                                                                classes.skillsWrap
                                                            }
                                                        >
                                                            {position.hardSkills.map(
                                                                (
                                                                    hardSkill,
                                                                    idx
                                                                ) => (
                                                                    <Box
                                                                        key={
                                                                            idx
                                                                        }
                                                                        className={
                                                                            classes.skillBox
                                                                        }
                                                                    >
                                                                        {hardSkill.url && (
                                                                            <img
                                                                                className={
                                                                                    classes.hardSkillIcon
                                                                                }
                                                                                alt="skill"
                                                                            ></img>
                                                                        )}
                                                                        <Typography
                                                                            className={
                                                                                classes.skillName
                                                                            }
                                                                        >
                                                                            {
                                                                                hardSkill.name
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    {isMobile &&
                                        !!company.devTeam.positions.length && (
                                            <>
                                                <Divider
                                                    className={
                                                        classes.companyCardDivider
                                                    }
                                                />
                                                <Box
                                                    className={
                                                        classes.devTeamBtn
                                                    }
                                                    onClick={() => {
                                                        history.push(
                                                            `/scout/${company.registrationNumber}`
                                                        );
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.devTeamBtnText
                                                        }
                                                    >
                                                        개발팀 정보 보러가기
                                                    </Typography>
                                                    <TXImage imgName="icon-arrow-forward-12-white" />
                                                </Box>
                                            </>
                                        )}
                                </Box>
                            ))}

                        <Typography className={classes.disclaimer}>
                            기업정보는 사업자등록증에 기재된 정보와 동일하게
                            관리되며 기업회원이 입력한 정보를 활용하고 있습니다.
                            {!isMobile && <br />}
                            실시간으로 반영되는 정보가 아니므로 현재 기업 정보와
                            차이가 있을 수 있는 점 참고 부탁드립니다.
                        </Typography>
                    </Box>
                </Container>
            </Box>

            {isMobile ? (
                <>
                    <Box className={classes.btnFixedWrap}>
                        <Box
                            className={classes.fullWidthBtn}
                            onClick={() => {
                                history.push("/talent-membership");
                            }}
                        >
                            다양한 개발팀에게 스카우트 제안 받기
                        </Box>
                    </Box>
                    <ServiceFooter backgroudColor="#2c2c2c" />
                </>
            ) : (
                <>
                    <Box
                        className={classes.btn}
                        onClick={() => {
                            history.push("/talent-membership");
                        }}
                    >
                        다양한 개발팀에게 스카우트 제안 받기
                    </Box>
                    <ServiceFooterD backgroudColor="#2c2c2c" />
                </>
            )}

            <Modal open={open} onClose={handleClose}>
                <>
                    <Box
                        className={classes.modalCloseBtn}
                        onClick={handleClose}
                    >
                        닫기
                    </Box>
                    <Box className={classes.modalWrap}>
                        <Slider {...settings}>
                            {companyInfo
                                ?.find(
                                    (company) =>
                                        company.registrationNumber ===
                                        selectedCompany
                                )
                                ?.photos.map((item, idx) => (
                                    <img
                                        key={idx}
                                        src={item.url}
                                        className={classes.modalImage}
                                    ></img>
                                ))}
                        </Slider>
                        <Typography className={classes.photoIndicator}>
                            {photoIdx + 1} / {photoLength}
                        </Typography>
                    </Box>
                </>
            </Modal>
        </React.Fragment>
    );
};

export default CompanyScout;
