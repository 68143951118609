import React, { useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Container, Divider, Grid } from "@material-ui/core";
import clsx from "clsx";
import { talentDataD as content } from "./dataTalentX3";
import { useStyles } from "./styleTalentContentD3";

const TalentContentD = () => {
	const reviewWindowRef = useRef();
	const classes = useStyles();

	const [slide, setSlide] = useState({
		interview: 0,
	});
	const handleSlideSelect = (type, value) => (event) => {
		setSlide({ ...slide, [type]: value });
	};

	const [open, setOpen] = useState(new Map());
	const handleAnswerOpen = (key, value) => (event) => {
		setOpen({ ...open, [key]: value });
	};

	function TXImage(props) {
		const { imgName, ...rest } = props;
		return (
			<img
				src={require(`assets/${imgName}.png`)}
				srcSet={`${require(`assets/${imgName}@2x.png`)} 2x, ${require(`assets/${imgName}@3x.png`)} 3x`}
				alt={imgName}
				{...rest}
			></img>
		);
	}

	const handleScrollLeft = () => {
		reviewWindowRef.current.scrollLeft += 550;
	};

	const handleScrollRight = () => {
		reviewWindowRef.current.scrollLeft += -550;
	};

	return (
		<React.Fragment>
			<Box className={classes.featureSection}>
				<Container maxWidth="lg" className={classes.featureContainer}>
					<Typography className={classes.featureCoreMsg}>
						{content.proposition}
					</Typography>
					<Box className={classes.featureBoxes}>
						{content.values.map((value, idx) => (
							<Box key={idx} className={classes.featureBox}>
								<img
									src={value.image}
									srcSet={`${value.image2x} 2x, ${value.image3x} 3x`}
									alt="talent pool"
								></img>
								<Typography className={classes.featureTitle}>
									{value.title}
								</Typography>
								<Typography className={classes.featureDesc}>
									{value.desc}
								</Typography>
							</Box>
						))}
					</Box>
				</Container>
			</Box>
			<Box className={classes.reviewSection}>
				<Container
					maxWidth="lg"
					className={classes.reviewSectionContainer}
				>
					<Typography className={classes.reviewTitle}>
						{content.talentReview.title}
					</Typography>
					<Box
						ref={reviewWindowRef}
						className={classes.reviewCardWindow}
					>
						{content.talentReview.reviews.map((review, idx) => (
							<Box key={idx} className={classes.reviewCard}>
								<Box className={classes.reviewRating}>
									{Array.apply(
										null,
										Array(review.rating)
									).map((star, idx) => (
										<TXImage
											key={idx}
											imgName="icon-star-24"
										/>
									))}
									{Array.apply(
										null,
										Array(5 - review.rating)
									).map((star, idx) => (
										<TXImage
											key={idx}
											imgName="icon-unstar-24"
										/>
									))}
									<span className={classes.reviewRatingDesc}>
										{review.ratingDesc}
									</span>
								</Box>
								<Typography className={classes.reviewContent}>
									{review.content}
								</Typography>
								<TXImage
									imgName={review.clientLogo}
									className={classes.reviewClientLogo}
								/>
								<Typography className={classes.reviewClient}>
									{review.client}
								</Typography>
							</Box>
						))}
					</Box>
					<Box className={classes.reviewCardWindowScroll}>
						<Box
							className={classes.reviewCardWindowScroller}
							onClick={handleScrollRight}
						>
							<TXImage imgName={"icon-arrow-left-scroll"} />
						</Box>
						<Box
							className={classes.reviewCardWindowScroller}
							onClick={handleScrollLeft}
						>
							<TXImage imgName={"icon-arrow-right-scroll"} />
						</Box>
					</Box>
				</Container>
			</Box>
			{/* <Box className={classes.benefitSection}>
                <Box className={classes.benefitKeyword}>
                    {content.benefits[0].keyword}
                </Box>
                <Box className={classes.benefitTitle}>
                    {content.benefits[0].title}
                </Box>
                <Box className={classes.benefitDesc}>
                    {content.benefits[0].desc}
                </Box>
                <img
                    src={content.benefits[0].image}
                    srcSet={`${content.benefits[0].image2x} 2x, ${content.benefits[0].image3x} 3x`}
                    className={classes.benefitImg}
                    alt="mobile"
                ></img>
            </Box> */}
			<Box className={classes.timeBenefitSection}>
				<Box className={classes.timeBenefitKeyword}>
					{content.benefits[1].keyword}
				</Box>
				<Box className={classes.timeBenefitTitle}>
					{content.benefits[1].title}
				</Box>
				<Box className={classes.timeBenefitImgIconWrap}>
					<img
						src={content.benefits[1].image}
						srcSet={`${content.benefits[1].image2x} 2x, ${content.benefits[1].image3x} 3x`}
						className={classes.timeBenefitImg}
						alt="clock"
					></img>
					<img
						src={content.benefits[1].icon}
						className={classes.timeBenefitIcon}
						alt="arrow down"
					></img>
				</Box>
				<Box>
					<Box className={classes.timeBenefitDesc}>
						{content.benefits[1].desc[0]}
					</Box>
					<Box className={classes.timeBenefitDesc}>
						{content.benefits[1].desc[1]}
					</Box>
				</Box>
				<Box>
					<Box className={classes.timeBenefitDesc}>
						{content.benefits[1].desc[2]}
					</Box>

					<Box className={classes.timeBenefitDesc}>
						{content.benefits[1].desc[3]}
					</Box>
				</Box>
				{/* <Box className={classes.effectiveness}>
                    {content.benefits[1].message}
                </Box>
                <img src={content.benefits[1].arrow} alt="arrow"></img> */}
			</Box>
			{/* <Box className={classes.offerSection}>
                            <Box className={classes.offerSectionTitle}>
                                {content.offer.title}
                            </Box>
                            <Box className={classes.offerSectionDesc}>
                                {content.offer.desc}
                            </Box>
                            <img
                                src={content.offer.image}
                                srcSet={`${content.offer.image2x} 2x, ${content.offer.image3x} 3x`}
                                className={classes.offerSectionImg}
                                alt="raising hands"
                            ></img>
                        </Box> */}
			<Box className={classes.managerSection}>
				<Box className={classes.managerKeyword}>
					{content.manager.keyword}
				</Box>
				<Box className={classes.managerTitle}>
					{content.manager.title}
				</Box>
				<Box className={classes.progressBar}>
					<Box className={classes.line}></Box>
					{content.manager.desc.map((item, idx) => (
						<Typography key={idx} className={classes.progress}>
							{idx + 1}
						</Typography>
					))}
				</Box>
				<Box>
					{content.manager.desc.map((item, idx) => (
						<Typography key={idx} className={classes.progressLabel}>
							{item}
						</Typography>
					))}
				</Box>
				<img
					src={content.manager.image}
					srcSet={`${content.manager.image2x} 2x, ${content.manager.image3x} 3x`}
					className={classes.managerImg}
					alt="manager"
				></img>
			</Box>
			{/* <Box className={classes.matchFunctionSection}>
                <Container maxWidth="lg" className={classes.functionContainer}>
                    <Box>
                        <Typography className={classes.matchFunctionSlogan}>
                            {content.features[0].title}
                        </Typography>
                        <Typography className={classes.matchFunctionSubtitle}>
                            {content.features[0].subtitle}
                        </Typography>
                        <Typography className={classes.matchFunctionDesc}>
                            {content.features[0].message}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={content.features[0].image}
                            srcSet={`${content.features[0].image2x} 2x, ${content.features[0].image3x} 3x`}
                            alt="feature"
                        ></img>
                    </Box>
                </Container>
            </Box> */}
			<Box className={classes.interviewFunctionSection}>
				<Container maxWidth="lg" className={classes.functionContainer}>
					<Box>
						<Typography className={classes.interviewFunctionSlogan}>
							{content.steps[0].title}
						</Typography>
						{content.steps[0].buttons.map((button, idx) => (
							<Box
								key={idx}
								className={
									slide.interview === idx
										? classes.interviewFunctionBtn
										: classes.interviewFunctionBtnUnClicked
								}
								onClick={handleSlideSelect("interview", idx)}
							>
								{button}
							</Box>
						))}
						<Typography className={classes.interviewFunctionDesc}>
							{content.steps[slide.interview].message}
						</Typography>
					</Box>
					<Box>
						<img
							src={content.steps[slide.interview].image}
							srcSet={`${
								content.steps[slide.interview].image2x
							} 2x, ${content.steps[slide.interview].image3x} 3x`}
							alt="feature"
						></img>
					</Box>
				</Container>
			</Box>
			{/* <Box className={classes.qnaSection}>
                <Container maxWidth="xs" className={classes.qnaContainer}>
                    <Typography className={classes.qnaSectionTitle}>
                        {content.qna.title}
                    </Typography>
                    {content.qna.faqs.map((faq, idx) => (
                        <Box key={idx} className={classes.qnaBox}>
                            <Box className={classes.questionBox}>
                                <img
                                    src={require("assets/question-mark.png")}
                                    alt="question"
                                />
                                <span className={classes.question}>
                                    {faq.question}
                                </span>
                                <img
                                    src={
                                        open[idx]
                                            ? require("assets/icon-chevron-up.png")
                                            : require("assets/icon-nav-chevron-down-24.png")
                                    }
                                    alt="close"
                                    style={{ width: 20, hieght: 20 }}
                                    onClick={handleAnswerOpen(idx, !open[idx])}
                                />
                            </Box>
                            {!open[idx] && (
                                <Divider className={classes.divider} />
                            )}
                            <Box
                                className={clsx(classes.answerBox, {
                                    [classes.answerBoxClosed]: !open[idx],
                                })}
                            >
                                {faq.html ? (
                                    <Typography
                                        dangerouslySetInnerHTML={faq.html}
                                        className={classes.answer}
                                    ></Typography>
                                ) : (
                                    <Typography className={classes.answer}>
                                        {faq.answer}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Container>
            </Box> */}
			{/* <RouterLink
                to={content.actionBtn.url}
                className={classes.fixedButtonRouteLink}
            >
                <Box className={classes.fixedButton}>
                    <Typography className={classes.fixedButtonName}>
                        {content.actionBtn.title}
                    </Typography>
                    <Typography className={classes.fixedButtonDesc}>
                        {content.actionBtn.desc}
                    </Typography>
                </Box>
            </RouterLink> */}
		</React.Fragment>
	);
};

export default TalentContentD;
