import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import CreateIcon from "@material-ui/icons/Create";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Link from "@material-ui/core/Link";
import talentinum_logo from "talentinum_logo.png";
import talentx_logo from "talentx_logo.png";
import { UserContext } from "providers/UserProvider";
import { auth } from "apis/firebase";

const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            backgroundColor: theme.palette.background.paper,
        },
        menuButton: {
            //marginRight: theme.spacing(2),
            color: theme.palette.text.primary,
        },
        logOutButton: {
            margin: theme.spacing(0, 0, 0, 1),
            color: theme.palette.text.secondary,
        },
        title: {
            flexGrow: 1,
            color: theme.palette.text.primary,
            textDecoration: "none",
        },
        logo: {
            height: (props) =>
                props.identity === "talentx"
                    ? theme.spacing(4)
                    : theme.spacing(2),
        },
        signedInUserName: {
            textDecoration: "none",
            color: "black",
        },
    };
});

function Header(props) {
    const { identity } = props;

    const logo = identity === "talentx" ? talentx_logo : talentinum_logo;

    const user = useContext(UserContext);
    let name = user?.displayName;

    const classes = useStyles(props);

    const signOut = () => auth.signOut();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <RouterLink to="/" className={classes.title}>
                        <img src={logo} className={classes.logo} alt="symbol" />

                        {/* <Typography variant="h6">talentinum</Typography> */}
                    </RouterLink>

                    {name ? (
                        <>
                            <RouterLink
                                to="/talentx/admin/dashboard"
                                className={classes.signedInUserName}
                            >
                                <Typography>{name}</Typography>
                            </RouterLink>
                            <IconButton
                                edge="start"
                                className={classes.logOutButton}
                                aria-label="menu"
                                onClick={signOut}
                            >
                                <ExitToAppIcon />
                            </IconButton>
                        </>
                    ) : (
                        <RouterLink to="/talentx/admin/authentication">
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                aria-label="menu"
                            >
                                <CreateIcon />
                            </IconButton>
                        </RouterLink>
                    )}
                    {/* <Link
                            href="https://open-recruitment-apply.paperform.co/"
                            target="_blank"
                        >
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                aria-label="menu"
                            >
                                <AssignmentIcon />
                            </IconButton>
                        </Link> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
