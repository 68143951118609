import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Link from "@material-ui/core/Link";
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    Container,
    List,
    ListItem,
    ListItemText,
    Button,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isAndroid, isWindows } from "react-device-detect";
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
    },
    appBar: {
        backgroundColor: "#ffffff",
        height: 56,
    },
    toolBar: {
        minHeight: 56,
    },
    identity: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    logo: {
        width: 70,
        height: 21,
    },
    link: {
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 500,
        marginRight: 12,
    },
    paddingBox: {
        flexGrow: 1,
    },
    promotionWrap: {
        position: "relative",
    },
    menuTextButton: {
        color: "#666666",
    },
    menuButton: {
        marginRight: 4,
    },
    badge: {
        backgroundColor: "#ff1717",
    },
    menuLink: {
        textDecoration: "none",
        color: "inherit",
    },
    listItemText: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 300,
    },
    thumbnailImg: {
        width: 32,
        height: 32,
        objectFit: "cover",
        borderRadius: "50%",
        marginLeft: 12,
    },
    serviceVer: {
        color: "#3c6af5",
        fontSize: 12,
        fontWeight: "bold",
        marginLeft: 12,
    },
    email: {
        color: theme.palette.common.black,
        fontSize: 14,
        //fontWeight: 500,
        //marginRight: 12,
    },
}));

const ProfileHeaderD = ({ edit = true }) => {
    const profile = useSelector((state) => state.profile);
    console.log("profile in header", profile);
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Container maxWidth="lg" className={classes.container}>
                    <Toolbar classes={{ root: classes.toolBar }} disableGutters>
                        <RouterLink to="/" className={classes.identity}>
                            <img
                                src={require("assets/talentx_logo_blue.png")}
                                srcSet={`${require("assets/talentx_logo_blue@2x.png")} 2x, ${require("assets/talentx_logo_blue@3x.png")} 3x`}
                                className={classes.logo}
                                alt="talentX"
                            />
                        </RouterLink>
                        <Typography className={classes.serviceVer}>
                            {edit ? "웹프로필 작성기 Beta" : "웹프로필 Beta"}
                        </Typography>
                        <Box className={classes.paddingBox}></Box>
                        <Typography className={classes.email}>
                            {edit ? profile?.email : "support@talentx.co.kr"}
                        </Typography>
                        <img
                            src={
                                profile.thumbnail?.url ??
                                require("assets/icon-skill-default-52.png")
                            }
                            className={classes.thumbnailImg}
                            alt="thumbnail"
                        />
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    );
};

export default ProfileHeaderD;
