import talentinum from "assets/talentinumLogo.jpg";
import talentx from "assets/talentXLogo.png";
import talentinumFull from "assets/talentinumFullLogo.png";

export const logoData = [
    {
        img: talentinum,
        title: "talentinum",
        author: "Tedy Jo",
        cols: 2,
    },
    {
        img: talentx,
        title: "talentX",
        author: "Arthur Han",
        cols: 2,
    },
    {
        img: talentinumFull,
        title: "talentinum",
        author: "Tedy Jo",
        cols: 4,
    },
];

export const steps = [
    { id: 1, order: "01", title: "참가 신청", period: "10.12 - 23" },
    { id: 2, order: "02", title: "코딩 테스트", period: "10.23 - 26" },
    {
        id: 3,
        order: "03",
        title: `1차 합격자
        AI 인적성 검사`,
        period: "10.29 - 11.01",
    },
    {
        id: 4,
        order: "04",
        title: `이력서,
        포트폴리오 제출`,
        period: "11.02 - 08",
    },
    {
        id: 5,
        order: "05",
        title: `2차 합격자
        기업 매칭 및 면접`,
        period: "11.24 - 12.31",
    },
    {
        id: 6,
        order: "06",
        title: `최종 합격자
        근로계약 및 입사`,
        period: "12.14 - 12.31",
    },
];

export const notices = [
    {
        id: 1,
        content:
            "- 각 프로세스 진행 시, 개별 안내를 드리며 필요에 따라 유선연락을 드릴 수도 있습니다.",
    },
    {
        id: 2,
        content:
            "- 1차 합격 시 제공되는 전용 양식으로 이력서와 포트폴리오를 제출하셔야 합니다.",
    },
    {
        id: 3,
        content:
            "- 코딩테스트, AI 인적성 검사 시 웹캠이 달린 컴퓨터 혹은, 노트북이 있어야 진행 가능합니다. (스마트폰 사용 불가)",
    },
    {
        id: 4,
        content: "- 코딩테스트에 나오는 문제는 모두 영문으로 출제됩니다.",
    },
    {
        id: 5,
        content:
            "- 모든 과정은 비대면으로 진행됩니다. 단 면접의 경우 기업별 채용 정책에 따라 대면으로 진행 될 수 있습니다.",
    },
];

export const faqs = [
    {
        id: 1,
        question: "코딩 테스트 난이도는 어떤가요?",
        answer: "대부분의 문제가 초, 중급 수준의 난이도로 출제되며 기본기를 파악하는 용도로 활용됩니다.",
    },
    {
        id: 2,
        question: "내가 만든 이력서 및 포트폴리오로 제출하면 안되나요?",
        answer: "공정한 평가를 위하여 불가피하게 공통된 양식으로 진행하게 되었습니다. 이 점 양해 부탁드립니다.",
    },
    {
        id: 3,
        question: "매칭된 기업과 반드시 면접을 봐야 하나요?",
        answer: "아니오, 매칭되었다고 해서 해당 기업과 꼭 면접을 볼 필요 없습니다. 기업 정보를 확인한 뒤 자유롭게 의사결정해 주세요.",
    },
    {
        id: 4,
        question: "매칭은 어떤식으로 이루어 지나요?",
        answer: "테스트 결과와 개인별로 수집된 기술 역량과 선호 기업을 고려하여 매칭이 이루어집니다.",
    },
    {
        id: 5,
        question: "중간에 다른 기업에 합격시 공채 지원 취소도 가능한가요?",
        answer: "네 다른 기업에 합격되었을 경우 취소 가능합니다.",
    },
    {
        id: 6,
        question: "공채 합격시 채용 계약 형태는 정규직인가요?",
        answer: "네, 참여기업 모두 정규직을 채용하고 있습니다.",
    },
];

export const termsOfCompanyUser = [
    {
        id: 1,
        article: "제 1 조 (목적)",
        content:
            '본 약관은 주식회사 탤런티넘(이하 "회사")이 제공하는 탤런트엑스 서비스(이하 "서비스")를 기업 고객(이하 “회원")이 이용함에 있어 "회사"와 “회원” 간의 이용 조건 및 제반 절차, 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.',
        clouses: [],
    },
    {
        id: 2,
        article: "제 2 조 (용어의 정의)",
        content: "이 약관에서 사용하는 용어의 정의는 아래와 같습니다.",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"이라 함은 자사의 직접 고용의 목적으로 채용을 원하는 기업(사업자, 기관, 단체)이 본 서비스를 이용하기 위하여 동 약관에 동의하고 "회사"와 이용계약을 체결한 “기업 회원”을 말합니다.',
            },
            {
                id: 2,
                content:
                    '"서비스"라 함은 “회사”가 직원 채용을 원하는 “회원"의 채용 과정을 지원하는 모든 부대 서비스를 말합니다.',
            },
            {
                id: 3,
                content:
                    '"사이트"라 함은 “회사”가 “서비스”를 "회원"에게 제공하기 위하여 컴퓨터 등 정보 통신 설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트 또는 모바일 앱을 말합니다.',
            },
        ],
    },
    {
        id: 3,
        article: "제 3 조 (약관의 게시와 개정)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 "회원"이 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 "회원"에게 공지해야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 약관의규제등에관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 단 "회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 합니다.',
            },
            {
                id: 4,
                content:
                    '"회원"은 변경된 약관에 대해 거부할 권리가 있습니다. "회원"은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있습니다. "회원"이 거부하는 경우 본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게 사전 통지 후 당해 "회원"과의 계약을 해지할 수 있습니다. 만약, "회원"이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 "서비스"를 이용하는 경우에는 동의한 것으로 간주합니다.',
            },
        ],
    },
    {
        id: 4,
        article: "제 4 조 (약관의 해석)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    "이 약관에서 규정하지 않은 사항에 관해서는 약관의규제등에관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등의 관계법령에 따릅니다.",
            },
            {
                id: 2,
                content:
                    '"회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용하는 경우에는 개별 계약이 우선합니다.',
            },
        ],
    },
    {
        id: 5,
        article: "제 5 조 (이용계약의 성립)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"의 서비스 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는 자가 본 약관과 개인정보취급방침을 읽고 "동의" 또는 "확인" 버튼을 누른 경우 본 약관에 동의한 것으로 간주합니다.',
            },
            {
                id: 2,
                content:
                    '제1항 신청에 있어 "회사"는 "회원"의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. "회원"은 본인인증에 필요한 이름, 생년월일, 연락처 등을 제공하여야 합니다. 또한 서비스 이용을 위해 회사명과 사업자등록번호를 제공하여야 하며 "회사"는 기업인증을 위해 사업자정보를 확인할 수 있는 증명서(사업자등록증)를 별도로 요구할 수 있습니다. 회사명과 사업자등록번호를 허위로 등록한 경우, 휴·폐업 사업자등록번호로 등록한 경우에 "회원"은 일체의 권리를 주장할 수 없습니다.',
            },
            {
                id: 3,
                content:
                    '이용신청자가 이용신청(회원가입 신청) 작성 후에 "회사"가 웹상의 안내 및 전자메일로 "회원"에게 통지함으로써 이용계약이 성립됩니다.',
            },
        ],
    },
    {
        id: 6,
        article: "제 6 조 (이용신청의 승인과 제한)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행상 또는 기술상 지장이 없는 경우에는 원칙적으로 접수 순서에 따라 서비스 이용을 승인합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 아래 사항에 해당하는 경우에 대해서는 적용하지 않습니다.',
                items: [
                    {
                        id: 1,
                        content:
                            "실제 회사명과 사업자등록번호가 아니거나 타사의 정보를 이용하여 신청한 경우",
                    },
                    {
                        id: 2,
                        content:
                            '이용계약 신청서의 내용을 허위로 기재하거나 "회사"가 제시하는 내용을 기재하지 않은 경우',
                    },
                    {
                        id: 3,
                        content:
                            "이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우",
                    },
                ],
            },
        ],
    },
    {
        id: 7,
        article: "제 7 조 (서비스의 내용)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같습니다.',
                items: [
                    {
                        id: 1,
                        content: "구직자 프로필 구독 서비스",
                    },
                    {
                        id: 2,
                        content: "구직자 소개 및 채용컨설팅 서비스",
                    },
                    {
                        id: 3,
                        content: "채용 면접 대행 서비스",
                    },
                    {
                        id: 4,
                        content: "구인과 관련된 제반 서비스",
                    },
                    {
                        id: 5,
                        content: "자료 거래에 관련된 서비스",
                    },
                    {
                        id: 6,
                        content:
                            '기타 "회사"가 추가 개발하거나 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스',
                    },
                ],
            },
            {
                id: 2,
                content:
                    '"회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 단, 이 경우 "회사"는 추가 또는 변경내용을 "회원"이 기재한 전자우편 주소로 공지할 수 있습니다.',
            },
        ],
    },
    {
        id: 8,
        article: '제 8 조 ("회원"정보, 채용활동, 금지사항)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"은 구직을 원하는 개인회원이 해당 기업에 대한 판단을 돕기 위해 기업정보를 정확히 기재하여야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회원"은 관련 법률에 의거하여 근로자의 모집 및 채용에 있어서 남녀, 연령을 차별하여서는 아니되며 15세 미만 채용 모집을 할 수 없습니다.',
            },
            {
                id: 3,
                content:
                    '제2항을 위반한 경우 이에 대한 책임은 전적으로 "회원"에게 있습니다.',
            },
            {
                id: 4,
                content:
                    '"회사"는 "회원"이 등록한 기업정보를 "회사"가 정한 방법에 의해 노출할 수 있습니다.',
            },
            {
                id: 5,
                content:
                    '"회원"은 직원 채용의 목적으로 유·무료로 구직자의 프로필를 구독할 수 있으며 "회원"은 "회사"가 정한 커뮤니케이션 방식을 통해서만 개인회원에게 연락을 취할 수 있습니다. 단, 프로필 열람 및 연락의 목적은 직원채용활동에 국한 되어야 하며, 기업의 영업·마케팅·제3자 제공 시에는 개인정보보호법, 직업안정법, 정보통신망이용촉진 및 정보보호 등에 관한 법률에 의거하여 법적 책임을 질 수 있습니다.',
            },
            {
                id: 6,
                content:
                    '"회원"이 제2조 1항에 따라 자사의 직접 고용을 목적으로 하지 않는 헤드헌팅, 아웃소싱(도급), 인재파견, 채용대행 등 인력 중개를 목적으로 “서비스”를 이용하는 경우 "회사"는 “회원”의 “서비스” 사용을 제한할 수 있습니다.',
            },
        ],
    },
    {
        id: 9,
        article: "제 9 조 (서비스의 요금)",
        content: "",
        clouses: [
            {
                id: 1,
                content: "회원 가입 후 제공되는 모든 서비스는 유료입니다.",
            },
            {
                id: 2,
                content:
                    '"회사"는 유료서비스를 제공할 경우 요금에 대해서 공지합니다. 다만, “회사”가 "회원"과 개별 계약을 체결하고 유료서비스를 제공하는 경우에는 적용하지 않습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 "회사"가 예고 없이 변경할 수 있다. 다만, 변경 이전에 적용 또는 계약한 금액은 소급하여 적용하지 않습니다.',
            },
            {
                id: 4,
                content:
                    '유료서비스 신청 후 "회원" 사정에 의해 서비스가 취소될 경우, 정부가 고시하는 "콘텐츠 이용자 보호지침"에 따라 "회사"는 본 지침이 제시하는 범위 내에서 환불 수수료를 부과할 수 있습니다.',
            },
        ],
    },
    {
        id: 10,
        article: "제 10 조 (정보의 제공 및 광고의 게재)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 "회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 서신우편을 이용한 방법으로 제공할 수 있습니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지, 전자우편 등에 게재할 수 있으며, 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않습니다.',
            },
            {
                id: 4,
                content:
                    '본 서비스의 "회원"은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는 것으로 간주합니다.',
            },
        ],
    },
    {
        id: 11,
        article: '제 11 조 (자료 내용의 책임과 "회사"의 정보 수정 권한)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '자료 내용은 "회원"이 등록한 기업 정보와 채용 정보 그리고 면접 및 채용 제안 등 을 말합니다.',
            },
            {
                id: 2,
                content:
                    '"회원"은 자료 내용을 사실에 근거하여 성실하게 작성해야 하며, 만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든 책임은 "회원"에게 있습니다.',
            },
            {
                id: 3,
                content:
                    '모든 자료 내용의 관리와 작성은 "회원" 본인이 하는 것이 원칙이나 사정상 위탁 또는 대행관리를 하더라도 자료 내용의 책임은 "회원"에게 있으며 "회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록 노력해야 합니다.',
            },
            {
                id: 4,
                content:
                    '"회사"는 "회원"이 등록한 자료 내용에 오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있습니다.',
            },
        ],
    },
    {
        id: 12,
        article: "제 12 조 (자료 내용의 활용 및 취급)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '자료 내용은 "회원"이 등록한 기업 정보와 채용 정보 그리고 면접 및 채용 제안 등 을 말합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 "사이트"를 통해 추천된 개인회원들의 프로필을 “회원"이 평가한 정보와 "회원"이 직접 열람한 개인회원들의 프로필과 관련한 각종 통계데이터를 개인회원에게 제공할 수 있습니다.',
            },
            {
                id: 3,
                content:
                    '“회원”이 "사이트"에서 정당한 절차를 거쳐 열람 또는 저장한 개인회원의 프로필 정보는 채용 여부 확정 이후 개인정보보호법에 따라 즉시 폐기합니다.',
            },
        ],
    },
    {
        id: 13,
        article: '제 13 조 ("회사"의 의무)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 서비스와 관련한 "회원"의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 "회원"에게 통지하여야 합니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 유료 결제와 관련한 결제 사항 정보를 5년간 보존합니다.',
            },
            {
                id: 4,
                content:
                    '천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 집니다.',
            },
            {
                id: 5,
                content:
                    '"회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나 열람 시킬 경우 반드시 "회원"의 동의를 얻어야 합니다.',
            },
        ],
    },
    {
        id: 14,
        article: '제 14 조 ("회원"의 의무)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 됩니다.',
            },
            {
                id: 2,
                content:
                    '"회원"이 신청한 유료서비스는 등록 또는 신청과 동시에 "회사"와 채권, 채무 관계가 발생하며, "회원"은 이에 대한 요금을 지정한 기일 내에 납부하여야 합니다.',
            },
            {
                id: 3,
                content:
                    '"회원"은 서비스를 이용하여 얻은 정보를 "회사"의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.',
            },
            {
                id: 4,
                content:
                    '"회원"은 본 서비스를 건전한 직원채용 이외의 목적으로 사용해서는 안됩니다.',
            },
            {
                id: 5,
                content:
                    '"회원"은 "서비스"를 통해 열람한 프로필 정보를 "회사" 및 당사자의 허락없이 재배포할 수 없으며 본 정보에 대한 출력 및 복사 등의 관리 책임은 전적으로 "회원"에게 있습니다.',
            },
        ],
    },
    {
        id: 15,
        article: "제 15 조 (가입 해지 / 서비스 중지 / 자료 삭제)",
        content:
            '“회원"이 다음의 사항에 해당하는 경우 "회사"는 사전 동의 없이 해당 “회원"을 가입해지 처리하거나 서비스 중지를 취할 수 있습니다.',
        clouses: [
            {
                id: 1,
                content: "본 계약 상의 의무를 성실하게 이행하지 않았을 때",
            },
            {
                id: 2,
                content: "규정한 유료서비스 이용 요금을 납부하지 않았을 때",
            },
            {
                id: 3,
                content:
                    "본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때",
            },
            {
                id: 4,
                content: "등록한 정보의 내용이 사실과 다르거나 조작되었을 때",
            },
            {
                id: 5,
                content:
                    "사업자등록증에 나타난 정보와 기업회원 가입시의 정보가 일치하지 않을 때",
            },
            {
                id: 6,
                content:
                    "타 기업의 사업자등록번호나 상호를 도용하여 허위로 기업정보를 등록하거나 휴·폐업 사업자등록번호로 기업정보를 등록했을 때",
            },
            {
                id: 7,
                content:
                    "지사, 지점, 영업소 등의 경우 구체적인 지사, 지점, 영업소 명칭을 사용하지 않고 기업명을 등록했거나 지사, 지점, 영업소의 사업자등록번호가 별도 있음에도 불구하고 본사의 사업자등록번호로 기업회원 가입을 했을 때",
            },
            {
                id: 8,
                content:
                    "회사명, 담당자 성명, 사무실 연락처 등 구인업체의 중요 정보를 정확하게 기입하지 않았을 때",
            },
            {
                id: 9,
                content:
                    "기업회원 가입 시 본 서비스에서 안내하는 방법으로 가입하지 않았거나 등록하지 않았을 때",
            },
            {
                id: 10,
                content:
                    "채용 제안 상의 직종, 업무내용, 근로조건 등이 실제와 현저히 다를 때",
            },
            {
                id: 11,
                content:
                    "자사의 직접 고용을 목적으로 하지 않는 헤드헌팅, 아웃소싱(도급), 인재파견, 채용대행 등 인력 중개를 주 목적으로 사업을 수행하는 업체의 경우",
            },
            {
                id: 12,
                content:
                    "구인을 가장하여 수강생 모집, 직업 소개, 유학 알선, 물품 판매, 자금 모금 등을 행하였을 때",
            },
            {
                id: 13,
                content:
                    '구직자의 피해사례(구직자가 입사 과정에서 피해가 발생한 경우 또는 재직중 "회원"이 관계법령을 위반하여 피해가 발생한 경우 등)가 접수됐을 때',
            },
            {
                id: 14,
                content:
                    "임금체불로 근로감독원에 진정서가 접수되거나, 관계 당국에 고소·고발되어 있는 기업 (단, 기업의 임금체불이 해소가 입증되면 확인 후에 서비스 이용 가능)",
            },
            {
                id: 15,
                content:
                    "“회사”의 동의 없이 서비스 또는 사이트에 포함된 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위를 했을 때",
            },
            {
                id: 16,
                content:
                    "관련 법령, “회사”의 모든 약관 또는 정책을 준수하지 않는 행위이거나 서비스의 원활한 운영을 위하여 운영자가 필요하다고 판단한 경우",
            },
            {
                id: 17,
                content: "본 서비스 명예를 훼손하였을 때",
            },
        ],
    },
    {
        id: 16,
        article: "제 16 조 (손해배상)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"가 이 약관의 제7조, 제13조 등의 규정에 위반한 행위로 "회원"에게 손해를 입히거나 기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로 인해 "회원"에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "회원"이 손해를 입었을 경우에 "회사"는 그 손해를 배상하여야 합니다.',
            },
            {
                id: 3,
                content:
                    '"회원"이 이 약관의 제5조, 제14조, 제15조 등의 규정에 위반한 행위로 "회사" 및 제3자에게 손해를 입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌 경우에는 "회원"은 그 손해를 배상하여야 합니다.',
            },
            {
                id: 4,
                content:
                    '타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로 "회원"의 손해가 발생한 경우 "회사"는 이에 대한 배상 책임이 없습니다.',
            },
        ],
    },
    {
        id: 17,
        article: '제 17 조 ("회원"의 개인정보보호)',
        content:
            '"회사"는 "회원"의 개인정보보호를 위하여 노력해야 합니다. "회원"의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따르고, "사이트"에 "개인정보취급방침"을 고지합니다.',
        clouses: [],
    },
    {
        id: 18,
        article: "제 18 조 (신용정보의 제공 활용 동의)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"가 회원가입과 관련하여 취득한 "회원"의 신용정보를 타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 "회원"의 동의를 얻어야 합니다.',
            },
            {
                id: 2,
                content:
                    '본 서비스와 관련하여 "회사"가 "회원"으로부터 신용정보의 이용 및 보호에 관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은 "회사"가 신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 "회원"의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주합니다.',
            },
        ],
    },
    {
        id: 19,
        article: "제 19 조 (분쟁의 해결)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.',
            },
            {
                id: 2,
                content:
                    '전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지 관할법원으로 합니다.',
            },
        ],
    },
];

export const termsOfTalentUser = [
    {
        id: 1,
        article: "제 1 조 (목적)",
        content:
            '본 약관은 주식회사 탤런티넘(이하 "회사")이 제공하는 탤런트엑스 서비스(이하 "서비스")를 개인 고객(이하 “회원")이 이용함에 있어 "회사"와 “회원” 간의 이용 조건 및 제반 절차, 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.',
        clouses: [],
    },
    {
        id: 2,
        article: "제 2 조 (용어의 정의)",
        content: "이 약관에서 사용하는 용어의 정의는 아래와 같습니다.",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"이라 함은 함은 서비스를 이용하기 위하여 동 약관에 동의하거나 소셜 로그인 연동 서비스를 통해 "회사"와 이용 계약을 체결한 "개인 회원"을 말합니다.',
            },
            {
                id: 2,
                content:
                    '"서비스"라 함은 “회사”가 구직을 원하는 개인의 입직 및 이직 과정을 지원하는 모든 부대 서비스를 말합니다.',
            },
            {
                id: 3,
                content:
                    '"사이트"라 함은 “회사”가 “서비스”를 "회원"에게 제공하기 위하여 컴퓨터 등 정보 통신 설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트 또는 모바일 앱을 말합니다.',
            },
        ],
    },
    {
        id: 3,
        article: "제 3 조 (약관의 게시와 개정)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 "회원"이 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 "회원"에게 공지해야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 약관의규제등에관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 단 "회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 합니다.',
            },
            {
                id: 4,
                content:
                    '"회원"은 변경된 약관에 대해 거부할 권리가 있습니다. "회원"은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있습니다. "회원"이 거부하는 경우 본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게 사전 통지 후 당해 "회원"과의 계약을 해지할 수 있습니다. 만약, "회원"이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 "서비스"를 이용하는 경우에는 동의한 것으로 간주합니다.',
            },
        ],
    },
    {
        id: 4,
        article: "제 4 조 (약관의 해석)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    "이 약관에서 규정하지 않은 사항에 관해서는 약관의규제등에관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등의 관계법령에 따릅니다.",
            },
            {
                id: 2,
                content:
                    '"회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용하는 경우에는 개별 계약이 우선합니다.',
            },
        ],
    },
    {
        id: 5,
        article: "제 5 조 (이용계약의 성립)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"의 서비스 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는 자가 본 약관과 개인정보취급방침을 읽고 "동의" 또는 "확인" 버튼을 누른 경우 본 약관에 동의한 것으로 간주합니다.',
            },
            {
                id: 2,
                content:
                    '제1항 신청에 있어 "회사"는 "회원"의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. "회원"은 본인인증에 필요한 이름, 생년월일, 연락처 등을 제공하여야 합니다. 또한 서비스 이용을 위해 회사명과 사업자등록번호를 제공하여야 하며 "회사"는 기업인증을 위해 사업자정보를 확인할 수 있는 증명서(사업자등록증)를 별도로 요구할 수 있습니다. 회사명과 사업자등록번호를 허위로 등록한 경우, 휴·폐업 사업자등록번호로 등록한 경우에 "회원"은 일체의 권리를 주장할 수 없습니다.',
            },
            {
                id: 3,
                content:
                    '이용신청자가 이용신청(회원가입 신청) 작성 후에 "회사"가 웹상의 안내 및 전자메일로 "회원"에게 통지함으로써 이용계약이 성립됩니다.',
            },
            {
                id: 4,
                content:
                    '탤런티넘에 공개한 “회원"의 프로필은 열람 권한을 가진 기업회원에게 제공됩니다.',
                items: [
                    {
                        id: 1,
                        content: "제공받는 자 : 탤런티넘 기업회원",
                    },
                    {
                        id: 2,
                        content:
                            "제공받는 목적 : 구직 의사가 있는 개인회원을 대상으로한 채용 활동(채용 검토, 면접 제안, 채용 제안 등 관련 활동)",
                    },
                    {
                        id: 3,
                        content:
                            "제공하는 항목 : 개인회원이 구직활동을 위해 프로필에 공개한 정보(개인정보, 학력, 경력, 시험 성적, 프로젝트 등 관련 정보)",
                    },
                    {
                        id: 4,
                        content:
                            "보유기간 및 이용기간 : 개인회원이 정보 공개 철회 전까지",
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        article: "제 6 조 (이용신청의 승인과 제한)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행상 또는 기술상 지장이 없는 경우에는 원칙적으로 접수 순서에 따라 서비스 이용을 승인합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 아래 사항에 해당하는 경우에 대해서는 이를 적용하지 않습니다.',
                items: [
                    {
                        id: 1,
                        content:
                            "실명이 아니거나 타인의 명의를 이용하여 신청한 경우",
                    },
                    {
                        id: 2,
                        content:
                            '이용계약 신청서의 내용을 허위로 기재하거나 "회사"가 제시하는 내용을 기재하지 않은 경우',
                    },
                    {
                        id: 3,
                        content:
                            "이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우",
                    },
                    {
                        id: 4,
                        content: "만 15세 미만의 아동이 신청한 경우",
                    },
                ],
            },
        ],
    },
    {
        id: 7,
        article: "제 7 조 (서비스의 내용)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같습니다.',
                items: [
                    {
                        id: 1,
                        content: "개인 프로필 등록, 관리, 게재 서비스",
                    },
                    {
                        id: 2,
                        content: "개인 포트폴리오 등록, 관리, 게재 서비스 ",
                    },
                    {
                        id: 3,
                        content:
                            "온라인 취업 관리 서비스 (면접 일정 조율, 온라인 면접 솔루션, 근로 계약 검토 등)",
                    },
                    {
                        id: 4,
                        content: "구직과 관련된 제반 서비스",
                    },
                    {
                        id: 5,
                        content: "취업 관련 자료 거래에 관련된 서비스",
                    },
                    {
                        id: 6,
                        content: "회원 간의 교류와 소통에 관련한 서비스",
                    },
                    {
                        id: 7,
                        content: "경력 개발 교육과 관련된 서비스",
                    },
                    {
                        id: 8,
                        content: "외주 프로젝트 연결 서비스",
                    },
                    {
                        id: 9,
                        content:
                            '기타 "회사"가 추가 개발하거나 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스',
                    },
                ],
            },
            {
                id: 2,
                content:
                    '"회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 단, 이 경우 "회사"는 추가 또는 변경내용을 "회원"이 기재한 전자우편 주소로 공지할 수 있습니다.',
            },
        ],
    },
    {
        id: 8,
        article: "제 8 조 (포인트 제도)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    "포인트란 “회사”가 제공하는 특정 서비스 혹은 이벤트에 “회원”이 참여했을 때 계정에 무료로 적립하여 지급하는 보상을 의미합니다.",
            },
            {
                id: 2,
                content:
                    "포인트는 네이버페이 포인트 충전권으로 교환함으로써 사용됩니다.",
            },
            {
                id: 3,
                content:
                    "포인트는 “회사”의 매월 마지막 영업일에 자동 사용됩니다.",
            },
            {
                id: 4,
                content:
                    "포인트는 5000 포인트 단위로만 사용되며, 5000 포인트 미만의 경우 사용되지 않습니다.",
            },
            {
                id: 5,
                content:
                    "포인트를 사용하여 교환한 충전권은 회원정보에 입력된 휴대전화번호로 전송됩니다. 이 때 “회원”이 기재한 회원정보의 오류 및 변동으로 인하여 생긴 불이익에 대하여 “회사”는 책임지지 않습니다.",
            },
            {
                id: 6,
                content:
                    "포인트 적립 및 사용 내역에 오류가 발생한 경우, “회원”은 오류 발생일로부터 30일 이내에 회사에 정정 신청을 할 수 있으며, “회사”는 정당한 요구임이 확인된 경우 정정 신청일로부터 30일 이내에 이를 조정합니다.",
            },
            {
                id: 7,
                content:
                    "“회사”는 “회원”이 부정한 방법으로 적립한 포인트나 잘못 적립된 포인트에 대해 임의로 정정할 수 있습니다.",
            },
            {
                id: 8,
                content:
                    "포인트는 적립일로부터 1년간 유효하며, 유효기간 내 사용되지 않은 포인트는 소멸됩니다.",
            },
            {
                id: 9,
                content:
                    "회원 탈퇴 시 보유 중인 모든 포인트는 소멸되며, 재가입 시 해당 포인트는 복구되지 않습니다.",
            },
            {
                id: 10,
                content:
                    "“회사”의 사정에 따라 포인트 정책은 변경될 수 있습니다.",
            },
        ],
    },
    {
        id: 9,
        article: '제 9 조 ("회원"정보, 구직활동, 금지사항)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"은 구인을 원하는 기업회원이 “회원"에 대한 채용 관련 판단을 명확히 할 수 있도록 “회원"의 프로필과 포트폴리오 정보를 정확히 기재하여야 합니다.',
            },
            {
                id: 2,
                content:
                    '제1항을 위반한 경우 이에 대한 책임은 전적으로 "회원"에게 있습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 "회원"이 등록한 프로필과 포트폴리오 정보를 "회사"가 정한 방법에 의해 노출할 수 있습니다.',
            },
            {
                id: 4,
                content:
                    '"회원"은 구직을 목적으로 유·무료로 기업 정보를 확인할 수 있으며 "회원"은 "회사"가 정한 커뮤니케이션 방식을 통해서만 기업회원에게 연락을 취할 수 있습니다.',
            },
        ],
    },
    {
        id: 10,
        article: "제 10 조 (서비스의 요금)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    "회원가입과 프로필 등록 서비스는 무료입니다. 다만 효과적인 구직을 위한 별도의 서비스는 유료로 제공될 수 있습니다.",
            },
            {
                id: 2,
                content:
                    '"회사"는 유료서비스를 제공할 경우 요금에 대해서 공지를 하여야 합니다. 다만, “회사”가 "회원"와 개별 계약을 체결하고 유료서비스를 제공하는 경우에는 적용하지 않습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 "회사"가 예고 없이 변경할 수 있습니다. 다만, 변경 이전에 적용 또는 계약한 금액은 소급하여 적용하지 않습니다.',
            },
            {
                id: 4,
                content:
                    '유료서비스 신청 후 "회원" 사정에 의해 서비스가 취소될 경우, 정부가 고시하는 "콘텐츠 이용자 보호지침"에 따라 "회사"는 본 지침이 제시하는 범위 내에서 환불 수수료를 부과할 수 있습니다.',
            },
        ],
    },
    {
        id: 11,
        article: "제 11 조 (정보의 제공 및 광고의 게재)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 "회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 서신우편을 이용한 방법으로 제공할 수 있습니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지, 전자우편 등에 게재할 수 있으며, 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있습니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않습니다.',
            },
            {
                id: 4,
                content:
                    '본 서비스의 "회원"은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는 것으로 간주합니다.',
            },
        ],
    },
    {
        id: 12,
        article: '제 12 조 (자료 내용의 책임과 "회사"의 정보 수정 권한)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '자료 내용은 "회원"이 등록한 개인 정보와 구직 활동에 관한 정보를 말합니다.',
            },
            {
                id: 2,
                content:
                    '"회원"은 자료 내용을 사실에 근거하여 성실하게 작성해야 하며, 만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든 책임은 "회원"에게 있습니다.',
            },
            {
                id: 3,
                content:
                    '모든 자료 내용의 관리와 작성은 "회원" 본인이 하는 것이 원칙이나 사정상 위탁 또는 대행관리를 하더라도 자료 내용의 책임은 "회원"에게 있으며 "회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록 노력해야 합니다.',
            },
            {
                id: 4,
                content:
                    '"회사"는 "회원"이 등록한 자료 내용에 오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있습니다.',
            },
        ],
    },
    {
        id: 13,
        article: "제 13 조 (자료 내용의 활용 및 취급)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"이 입력한 정보는 취업 및 관련 동향의 통계 자료로 활용될 수 있으며 그 자료는 매체를 통해 언론에 배포될 수 있습니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 "사이트"를 통해 추천된 기업회원에 대한 “회원"의 평가 내용과 “회원”이 직접 조회 및 지원한 기업들과 관련한 각종 통계데이터를 기업회원에게 제공할 수 있습니다.',
            },
        ],
    },
    {
        id: 14,
        article: '제 14 조 ("회사"의 의무)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 서비스와 관련한 "회원"의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 "회원"에게 통지하여야 합니다.',
            },
            {
                id: 3,
                content:
                    '"회사"는 유료 결제와 관련한 결제 사항 정보를 5년간 보존합니다.',
            },
            {
                id: 4,
                content:
                    '천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지 않습니다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 집니다.',
            },
            {
                id: 5,
                content:
                    '"회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나 열람 시킬 경우 반드시 "회원"의 동의를 얻어야 합니다.',
            },
        ],
    },
    {
        id: 15,
        article: '제 15 조 ("회원"의 의무)',
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 됩니다.',
            },
            {
                id: 2,
                content:
                    '"회원"이 신청한 유료서비스는 등록 또는 신청과 동시에 "회사"와 채권, 채무 관계가 발생하며, "회원"은 이에 대한 요금을 지정한 기일 내에 납부하여야 합니다.',
            },
            {
                id: 3,
                content:
                    '"회원"은 서비스를 이용하여 얻은 정보를 "회사"의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.',
            },
            {
                id: 4,
                content:
                    '"회원"은 본 서비스를 구직 이외의 목적으로 사용해서는 안됩니다.',
            },
            {
                id: 5,
                content:
                    '"회원"은 "서비스"를 통해 열람한 기업 정보를 "회사" 및 해당 기업의 허락없이 재배포할 수 없으며 본 정보에 대한 출력 및 복사본에 대한 관리 책임은 전적으로 "회원"에게 있습니다.',
            },
            {
                id: 6,
                content:
                    '"회원"이 “회사”의 기업회원으로부터 “사이트”를 이탈하여 채용 제안을 받은 경우 “회사"에 이를 즉시 고지해야 합니다.',
            },
        ],
    },
    {
        id: 16,
        article: "제 16 조 (가입 해지 / 서비스 중지 / 자료 삭제)",
        content:
            '“회원"이 다음의 사항에 해당하는 경우 "회사"는 사전 동의 없이 해당 “회원"을 가입해지 처리하거나 서비스 중지를 취할 수 있습니다.',
        clouses: [
            {
                id: 1,
                content: "본 계약 상의 의무를 성실하게 이행하지 않았을 때",
            },
            {
                id: 2,
                content: "규정한 유료서비스 이용 요금을 납부하지 않았을 때",
            },
            {
                id: 3,
                content:
                    "본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때",
            },
            {
                id: 4,
                content: "등록한 정보의 내용이 사실과 다르거나 조작되었을 때",
            },
            {
                id: 5,
                content:
                    "신분증에 나타난 정보와 개인회원 가입시의 정보가 일치하지 않을 때",
            },
            {
                id: 6,
                content:
                    "타인의 정보를 도용하거나 허위로 개인정보를 등록했을 때",
            },
            {
                id: 7,
                content:
                    "회원 가입 시 본 서비스에서 안내하는 방법으로 가입하지 않았거나 등록하지 않았을 때",
            },
            {
                id: 8,
                content:
                    '"회원"이 자신의 취업을 목적으로 하지 않고 헤드헌팅, 아웃소싱(도급), 인재파견, 채용대행 등 인력 중개를 주 목적으로 사업을 수행하는 개인 또는 기업인 경우',
            },
            {
                id: 9,
                content:
                    "구직을 가장하여 수강생 모집, 직업 소개, 유학 알선, 물품 판매, 자금 모금 등을 행하였을 때",
            },
            {
                id: 10,
                content:
                    '구인자의 피해사례(구인자가 입사 과정에서 피해를 발생시킨 경우 또는 재직중 "회원"이 관계법령을 위반하여 피해가 발생한 경우 등)가 접수됐을 때',
            },
            {
                id: 11,
                content:
                    "“회사”의 서비스 또는 사이트에 포함된 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위를 했을 때",
            },
            {
                id: 12,
                content:
                    "관련 법령, “회사”의 모든 약관 또는 정책을 준수하지 않는 행위이거나 서비스의 원활한 운영을 위하여 운영자가 필요하다고 판단한 경우",
            },
            {
                id: 13,
                content: "본 서비스 명예를 훼손하였을 때",
            },
        ],
    },
    {
        id: 17,
        article: "제 17 조 (손해배상)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"가 이 약관의 제7조, 제14조 등의 규정에 위반한 행위로 "회원"에게 손해를 입히거나 기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로 인해 "회원"에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야 합니다.',
            },
            {
                id: 2,
                content:
                    '"회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "회원"이 손해를 입었을 경우에 "회사"는 그 손해를 배상하여야 합니다.',
            },
            {
                id: 3,
                content:
                    '"회원"이 이 약관의 제5조, 제15조, 제16조 등의 규정에 위반한 행위로 "회사" 및 제3자에게 손해를 입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌 경우에는 "회원"은 그 손해를 배상하여야 합니다.',
            },
            {
                id: 4,
                content:
                    '타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로 "회원"의 손해가 발생한 경우 "회사"는 이에 대한 배상 책임이 없습니다.',
            },
        ],
    },
    {
        id: 18,
        article: '제 18 조 ("회원"의 개인정보보호)',
        content:
            '"회사"는 "회원"의 개인정보보호를 위하여 노력해야 합니다. "회원"의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따르고, "사이트"에 "개인정보취급방침"을 고지합니다.',
        clouses: [],
    },
    {
        id: 19,
        article: "제 19 조 (신용정보의 제공 활용 동의)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"가 회원가입과 관련하여 취득한 "회원"의 신용정보를 타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 "회원"의 동의를 얻어야 합니다.',
            },
            {
                id: 2,
                content:
                    '본 서비스와 관련하여 "회사"가 "회원"으로부터 신용정보의 이용 및 보호에 관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은 "회사"가 신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 "회원"의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주합니다.',
            },
        ],
    },
    {
        id: 20,
        article: "제 20 조 (분쟁의 해결)",
        content: "",
        clouses: [
            {
                id: 1,
                content:
                    '"회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.',
            },
            {
                id: 2,
                content:
                    '전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지 관할법원으로 합니다.',
            },
        ],
    },
];

export const privacyPolicy = [
    {
        id: 0,
        article:
            "주식회사 탤런티넘(이하 “회사”)은 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위 하여 다음과 같이 개인정보 처리지침을 수립하여 공개합니다.",
    },
    {
        id: 1,
        article: "1. 개인정보의 수집 및 이용목적",
        content:
            "회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다",
        clouses: [
            {
                id: 1,
                title: "1) 회원관리",
                content:
                    "회원제 서비스 제공에 따른 본인 확인, 회원자격 유지 및 관리, 서비스 부정이용 방지, 각종 고지 및 통지, 고충처리",
            },
            {
                id: 2,
                title: "2) 회원 서비스 제공",
                contennt:
                    "인재 소개 서비스 제공, 채용 정보 매칭, 구매 및 요금 결제, 물품 및 증빙 발송, 부정 이용방지",
            },
            {
                id: 3,
                title: "3) 마케팅 정보 활용",
                contennt:
                    "맞춤형 서비스 제공, 서비스 안내 및 이용권유, 행사 정보 안내, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계, 서비스 개선 및 고도화를 위한 데이터 분석",
            },
        ],
    },
    {
        id: 2,
        article: "2. 수집하는 개인정보 항목 및 수집 방법",
        content: "회사는 다음의 개인정보 항목을 처리하고 있습니다.",
        clouses: [
            {
                id: 1,
                title: "1) 수집하는 개인정보의 항목",
                content:
                    "회원제 서비스 제공에 따른 본인 확인, 회원자격 유지 및 관리, 서비스 부정이용 방지, 각종 고지 및 통지, 고충처리",
                items: [
                    {
                        id: 1,
                        title: "① 개인 회원가입시 수집하는 항목",
                        content:
                            "필수항목: 이름, 이메일, 비밀번호, 휴대전화번호, 생년월일, 취업 여부 \n 선택항목: 가입 경로",
                    },
                    {
                        id: 2,
                        title: "② 기업 회원가입 시 수집하는 항목",
                        content:
                            "필수항목: 이름, 이메일, 비밀번호, 전화번호, 회사명, 대표자명, 산업군, 기업규모, 기업 소개 정보,설립연도, 사업자등록번호, 회사주소, 서비스명 및 관련 정보, 채용 계획 \n 선택항목: 홈페이지, 임직원수, 회사 관련 이미지",
                    },
                    {
                        id: 3,
                        title: "③ 이력서 제작 서비스 이용 시 수집하는 항목",
                        content:
                            "필수 항목: 이름, 생년월일, 이메일, 휴대전화번호, 구직 현황, 희망 직무, 학력, 구직 희망 시기, 희망 근무지, 자기 소개 슬로건, 프로그래밍 언어 및 프레임워크 사용 역량 수준, 경력 및 프로젝트 수행 사항 \n 선택 항목: 프로필 사진, 대외 활동 경험, 외부 교육 수료 정보, 자격증 관련 정보, 수상 이력, 서비스 이용 경로",
                    },
                    {
                        id: 4,
                        title: "④ 역량 검증 서비스 이용 시 수집하는 항목 ",
                        content:
                            "필수 항목 : 이름, 이메일, 면접 응시 영상, 역량 평가 정보",
                    },
                    {
                        id: 5,
                        title: "⑤ 유료 서비스 이용 시 수집하는 항목",
                        content:
                            "결제정보 (카드정보, 계좌정보, 상품권 정보, 휴대폰번호 등)",
                    },
                    {
                        id: 6,
                        title: "⑥ 채용 시 수집하는 항목",
                        content: "주민등록번호, 주소지, 연봉정보",
                    },
                    {
                        id: 7,
                        title: "⑦ 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.",
                        content:
                            "IP 정보, 쿠키, 접속 기록, 결제 기록, 서비스 이용 관련 기록, 모바일 기기 정보(이동통신사 정보, 단말기 정보, 국가정보(MCC)), 광고 식별자 정보(ADID, IDFA)",
                    },
                ],
            },
            {
                id: 2,
                title: "2) 개인정보 수집방법",
                content:
                    "회사는 개인정보 항목을 다음과 같은 방법으로 수집하고 있습니다.",
                items: [
                    {
                        id: 1,
                        title: "",
                        content:
                            "- 홈페이지, 서비스 이용, 고객센터 문의, 전화, 행사 등",
                    },
                    {
                        id: 2,
                        title: "",
                        content: "- 모바일 어플리케이션을 통한 수집",
                    },
                    {
                        id: 3,
                        title: "",
                        content: "- 관계사 및 제휴사로부터의 수집",
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        article: "3. 개인정보의 처리 및 보유 기간",
        content:
            "회사는 정보주체가 회원 탈퇴시까지 수집한 개인정보를 처리 및 보유합니다. 다만, 다음의 사유에 해당하는 경우에는 예외적으로 해당 기간까지 처리 및 보유 합니다.",
        clouses: [
            {
                id: 1,
                title: "",
                content:
                    "- 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당 수사 및 조사 종료시까지",
            },
            {
                id: 2,
                title: "",
                content:
                    "- 홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지",
            },
            {
                id: 3,
                title: "",
                content:
                    "- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록의 경우 결제일로 부터 5년까지",
            },
            {
                id: 4,
                title: "",
                content:
                    "- 소비자 불만 또는 분쟁처리에 관한 기록의 경우 게시일로 부터 3년까지",
            },
        ],
    },
    {
        id: 4,
        article: "4. 개인정보의 제공",
        content:
            "회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. \n 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.",
        clouses: [
            {
                id: 1,
                title: "",
                content: "① 개인정보를 제공받는 자 : ㈜멀티캠퍼스",
            },
            {
                id: 2,
                title: "",
                content:
                    "② 개인정보를 제공받는 자의 개인정보 이용 목적 : 정보통신서비스의 제공에 따른 요금 정산, 공동 사업 등의 업무제휴 및 고용 서비스 제공",
            },
            {
                id: 3,
                title: "",
                content:
                    "③ 제공하는 개인정보 항목 : 이름, 이메일, 비밀번호, 휴대전화번호, 이력서, 구직 현황",
            },
            {
                id: 4,
                title: "",
                content:
                    "④ 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 5년",
            },
        ],
    },
    {
        id: 5,
        article: "5. 정보주체와 법정대리인의 권리 및 행사방법",
        content:
            "정보주체는 회사에 대해 언제든지 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수 있습니다.",
        clouses: [
            {
                id: 1,
                title: "",
                content:
                    "1) 위에 따른 권리 행사는 회사에 대해 개인정보보호 법 시행령 제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.",
            },
            {
                id: 2,
                title: "",
                content:
                    "2) 정보주체의 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호 법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.",
            },
            {
                id: 3,
                title: "",
                content:
                    "3) 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.",
            },
            {
                id: 4,
                title: "",
                content:
                    "4) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.",
            },
            {
                id: 5,
                title: "",
                content:
                    "5) 회사는 정보주체 권리에 따른 열람의 요구, 정정· 삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.",
            },
        ],
    },
    {
        id: 6,
        article: "6. 개인정보의 파기",
        content:
            "회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.",
        clouses: [
            {
                id: 1,
                title: "",
                content:
                    "1) 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관 장소를 달리하여 보존합니다.",
            },
            {
                id: 2,
                title: "",
                content: "2) 개인정보 파기의 절차 및 방법은 다음과 같습니다.",
                items: [
                    {
                        id: 1,
                        title: "① 파기 절차",
                        content:
                            "회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.",
                    },
                    {
                        id: 2,
                        title: "② 파기 방법",
                        content:
                            "회사는 전자적 파일 형태로 기록 및 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록 및 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.",
                    },
                ],
            },
        ],
    },
    {
        id: 7,
        article: "7. 개인정보의 안전성 확보 조치",
        content:
            "회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.",
        clouses: [
            {
                id: 1,
                title: "",
                content:
                    "1. 관리적 조치 : 내부관리계획 수립 및 시행, 정기적 직원 교육 등",
            },
            {
                id: 2,
                title: "",
                content:
                    "2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 고유식별정보 등의 암호화",
            },
            {
                id: 3,
                title: "",
                content: "3. 물리적 조치 : 보관 자료 등의 접근 통제",
            },
        ],
    },
    {
        id: 8,
        article: "8. 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항",
        content:
            "회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내 의 하드디스크에 저장되기도 합니다.",
        clouses: [
            {
                id: 1,
                title: "",
                content:
                    "1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.",
            },
            {
                id: 2,
                title: "",
                content:
                    "2) 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.",
            },
            {
                id: 3,
                title: "",
                content:
                    "3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생 할 수 있습니다.",
            },
        ],
    },
    {
        id: 9,
        article: "9. 개인정보 보호책임자에 관한 사항",
        content:
            "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.",
        clouses: [
            {
                id: 1,
                title: "- 개인정보 보호책임자",
                content:
                    "성명: 한신환\n직책: 대표이사\n연락처 : support@talentinum.com\n※ 개인정보 보호 담당부서로 연결됩니다.",
            },
            {
                id: 2,
                title: "- 개인정보 보호 담당부서",
                content:
                    "부서명: 고객관리팀\n담당자: 오성원\n연락처 : ellie@talentinum.com",
            },
        ],
    },
    {
        id: 10,
        article:
            "정보주체는 회사의 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 담당부서 및 개인정보 보호 책임자에게 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.",
    },
];
