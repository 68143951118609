import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
	Box,
	Typography,
	Container,
	Paper,
	Tabs,
	Tab,
	Divider,
	Grid,
	Button,
} from "@material-ui/core";
import clsx from "clsx";

import "date-fns";

import { companyDataM as content } from "./dataTalentX3";
import { useStyles } from "./styleCompanyPricing3";
import { TXImage } from "../../components/tx";
import { CompanyServiceHeader, ServiceFooter } from "pages/components";

const CompanyPricing = () => {
	const history = useHistory();

	useEffect(() => window.scrollTo({ top: 0 }), []);

	const classes = useStyles();

	const [feature, setFeature] = React.useState(0);

	const handleFeatureChange = (event, newFeature) => {
		setFeature(newFeature);
	};

	const [step, setStep] = React.useState(0);

	const handleStepChange = (event, newStep) => {
		setStep(newStep);
	};

	const [open, setOpen] = useState(new Map());

	const handleAnswerOpen = (key, value) => (event) => {
		setOpen({ ...open, [key]: value });
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>탤런트엑스 | 기업 멤버십</title>
			</Helmet>
			<CompanyServiceHeader />
			<Container maxWidth="xs" disableGutters>
				<Box className={classes.body}>
					<Box className={classes.pricingSection}>
						<Typography className={classes.pricingTitle}>
							{content.pricing.title}
						</Typography>
						<Typography className={classes.pricingSubTitle}>
							{content.pricing.subtitle}
						</Typography>
						<Box className={classes.pricingContentLeftWrapper}>
							<Typography
								className={classes.pricingContentLeftTitle}
							>
								{content.pricing.plans[0].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[0].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[0].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentLeftPrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[0].services.map(
								(service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								)
							)}
							<Button
								className={classes.membershipBtn}
								variant="outlined"
								size="small"
								onClick={() =>
									history.push(
										`/company-contract/${content.pricing.plans[0].key}`
									)
								}
							>
								{content.pricing.plans[0].name} 멤버십 가입
							</Button>
						</Box>
						<Box className={classes.pricingContentMiddleWrapper}>
							<Typography
								className={classes.pricingContentMiddleTitle}
							>
								{content.pricing.plans[1].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[1].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[1].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentMiddlePrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[1].services
								.slice(0, 6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[1].services
								.slice(6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-light-blue"}
										/>
										<Typography
											className={
												classes.pricingContentMiddleService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							<Button
								className={classes.membershipBtn}
								variant="outlined"
								size="small"
								onClick={() =>
									history.push(
										`/company-contract/${content.pricing.plans[1].key}`
									)
								}
							>
								{content.pricing.plans[1].name} 멤버십 가입
							</Button>
						</Box>
						<Box className={classes.pricingContentRightWrapper}>
							<Typography
								className={classes.pricingContentRightTitle}
							>
								{content.pricing.plans[2].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[2].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[2].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentRightPrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[2].services
								.slice(0, 6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[2].services
								.slice(6, 7)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-light-blue"}
										/>
										<Typography
											className={
												classes.pricingContentMiddleService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[2].services
								.slice(7)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-blue"}
										/>
										<Typography
											className={
												classes.pricingContentRightService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							<Button
								className={classes.membershipBtn}
								variant="outlined"
								size="small"
								onClick={() =>
									history.push(
										`/company-contract/${content.pricing.plans[2].key}`
									)
								}
							>
								{content.pricing.plans[2].name} 멤버십 가입
							</Button>
						</Box>
					</Box>
					<Box className={classes.qnaSection}>
						<Typography className={classes.qnaSectionTitle}>
							{content.qna.title}
						</Typography>
						{content.qna.faqs.map((faq, idx) => (
							<Box key={idx} className={classes.qnaBox}>
								<Box className={classes.questionBox}>
									<img
										style={{ width: 20, hieght: 20 }}
										src={require("assets/question-mark@2x.png")}
										alt="question"
									/>
									<span className={classes.question}>
										{faq.question}
									</span>
									<img
										src={
											open[idx]
												? require("assets/icon-chevron-up.png")
												: require("assets/icon-nav-chevron-down-24.png")
										}
										alt="close"
										style={{ width: 20, hieght: 20 }}
										onClick={handleAnswerOpen(
											idx,
											!open[idx]
										)}
									/>
								</Box>
								{!open[idx] && (
									<Divider className={classes.divider} />
								)}
								<Box
									className={clsx(classes.answerBox, {
										[classes.answerBoxClosed]: !open[idx],
									})}
								>
									{faq.html ? (
										<Typography
											dangerouslySetInnerHTML={faq.html}
											className={classes.answer}
										></Typography>
									) : (
										<Typography className={classes.answer}>
											{faq.answer}
										</Typography>
									)}
								</Box>
							</Box>
						))}
					</Box>
				</Box>
				{/* <RouterLink
                    to="/company-contract"
                    className={classes.fixedButtonRouteLink}
                >
                    <Box className={classes.fixedButton}>
                        <Typography className={classes.fixedButtonName}>
                            기업 멤버십 신청하기
                        </Typography>
                    </Box>
                </RouterLink> */}
			</Container>
			<ServiceFooter backgroudColor={"#2c2c2c"} />
			{/* <Box className={classes.phantom}></Box> */}
		</React.Fragment>
	);
};

export default CompanyPricing;
