import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, Box, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";
import { TXImage } from "components/tx";

const content = {
	logo: "logo-talentinum",
	companyName: "주식회사 탤런티넘",
	companyEngName: "talentinum Inc.",
	businessNumber: "사업자등록번호: 734-81-01533",
	executives: "대표 : 한신환 | 개인정보관리자 : 오성원",
	address: "경기도 성남시 분당구 느티로 22",
	website: "https://talentinum.com/",
	licenses: [
		{
			name: "유료직업소개사업등록번호",
			number: "제2022-3780288-14-5-00018호",
		},
		{ name: "통신판매신고번호", number: "제2020-성남분당A-0820호" },
	],
	links: [
		{ name: "서비스 이용약관", url: "/terms-of-use" },
		{ name: "개인정보 처리방침", url: "/privacy-policy" },
		{ name: "문의하기", url: "https://talentx.channel.io/" },
	],
};

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		flexGrow: 1,
		overflow: "hidden",
		padding: theme.spacing(8, 0),
		backgroundColor: props.backgroudColor || "#eeeeee",
		color: "#bbbbbb",
	}),
	container: (props) => ({
		flexGrow: 1,
		overflow: "hidden",
		backgroundColor: props.backgroudColor || "#eeeeee",
		color: "#bbbbbb",
	}),
	logo: { marginBottom: 36 },
	company: {
		...Texts.basic(15, "bold", "#999999"),
		marginBottom: 8,
	},
	itemBox: {
		marginBottom: 16,
	},
	item: {
		...Texts.basic(13, 500, "#999999"),
	},
	info: {
		...Texts.basic(13, 500, "#bbbbbb"),
		marginBottom: 4,
	},
	link: {
		...Texts.basic(16, "bold", "#bbbbbb"),
		marginBottom: 8,
	},
	copyright: {
		...Texts.basic(13, 500, "inherit"),
		marginTop: 72,
	},
}));

function ServiceFooterD(props) {
	const classes = useStyles(props);

	return (
		<Box className={classes.root}>
			<Container
				maxWidth={props.maxWidth || "lg"}
				className={classes.container}
			>
				<TXImage imgName={content.logo} className={classes.logo} />
				<Grid container spacing={1}>
					<Grid item sm={12} md={4}>
						<RouterLink
							to="/corporation"
							style={{
								textDecoration: "none",
								color: "inherit",
							}}
						>
							<Typography className={classes.company}>
								{content.companyName}
							</Typography>
						</RouterLink>
						<Typography className={classes.info}>
							{content.businessNumber}
						</Typography>
						<Typography className={classes.info}>
							{content.executives}
						</Typography>
						<Typography className={classes.info}>
							{content.address}
						</Typography>
					</Grid>
					<Grid item sm={12} md={4}>
						{content.licenses.map((license, idx) => (
							<Box key={idx} className={classes.itemBox}>
								<Typography className={classes.item}>
									{license.name}
								</Typography>
								<Typography className={classes.info}>
									{license.number}
								</Typography>
							</Box>
						))}
					</Grid>
					<Grid item sm={12} md={4}>
						<Typography className={classes.link}>
							<RouterLink
								to={content.links[0].url}
								style={{
									textDecoration: "none",
									color: "inherit",
								}}
							>
								{content.links[0].name}
							</RouterLink>
						</Typography>

						<Typography className={classes.link}>
							<RouterLink
								to={content.links[1].url}
								style={{
									textDecoration: "none",
									color: "inherit",
								}}
							>
								{content.links[1].name}
							</RouterLink>
						</Typography>

						<Link
							href={content.links[2].url}
							color="inherit"
							target="_blank"
						>
							<Typography className={classes.link}>
								{content.links[2].name}
							</Typography>
						</Link>
					</Grid>
				</Grid>
				<Typography className={classes.copyright}>
					{"Copyright © "}
					<Link color="inherit" href={content.website}>
						{content.companyEngName}
					</Link>{" "}
					{new Date().getFullYear()}
					{"."}
				</Typography>
			</Container>
		</Box>
	);
}

export default ServiceFooterD;
