export const White = "#ffffff";

export const Gray50 = "#f8f8f8";
export const Gray100 = "#eeeeee";
export const Gray150 = "#dddddd";
export const Gray200 = "#c5c5c5";
export const Gray300 = "#ababab";
export const Gray400 = "#929292";
export const Gray500 = "#787878";
export const Gray600 = "#5f5f5f";
export const Gray700 = "#454545";
export const Gray800 = "#2c2c2c";
export const Gray900 = "#111111";

export const Blue400 = "#3c6af5";
export const Blue500 = "#0c46f2";

export const Citrus500 = "#ff4800";
