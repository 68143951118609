import { makeStyles } from "@material-ui/core";
import { Texts, Colors } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    inner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 120,
    },
    alert: {
        marginTop: 64,
        ...Texts.basic(18, "bold", Colors.Gray900, 1.22),
    },
    guide: {
        ...Texts.basic(14, 500, Colors.Gray400, 1.43),
        margin: "12px 45px 8px",
        textAlign: "center",
    },
    btn: {
        alignSelf: "stretch",
        margin: 24,
    },
}));
