import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCompanies } from "../../redux/slice/profileSlice";
import {
    Box,
    Typography,
    Divider,
    Button,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
} from "@material-ui/core";
import { AntSwitch } from "../components";
import { useStyles } from "./styleProfileCompany";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import { hopePositions } from "../../data";

import {
    createTalentProfileCareer,
    updateTalentProfileCareer,
    deleteTalentProfileCareer,
} from "../../apis/profile/profile";

import { displayLink, displayLocalYearMonth } from "../../helper/misc";

const initialCompany = {
    id: undefined,
    company: "",
    position: "",
    beginAt: null,
    endAt: null,
    working: false,
    task: "",
};
// const initialCompany = {
//     id: undefined,
//     company: "",
//     position: "",
//     beginAt: new Date(2000, 0, 1).toISOString(),
//     endAt: new Date(2000, 0, 1).toISOString(),
//     working: false,
//     task: "",
//     // link: "",
// };

const ProfileCompany = ({ edit = true }) => {
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [companyAdd, setCompanyAdd] = useState(false);
    const [companyEditIndex, setCompanyEditIndex] = useState(-1);
    const [companyBeingCreated, setCompanyBeingCreated] =
        useState(initialCompany);
    const [isUploading, setIsUploading] = useState(false);

    const handleCompanyChange = (event) => {
        const key = event.target.id || event.target.name;
        const value = event.target.value;
        setCompanyBeingCreated((prev) => {
            const newCompany = { ...prev };
            newCompany[key] = value;
            return newCompany;
        });
    };

    const handleCompanyWorkingChange = (event) => {
        setCompanyBeingCreated((prev) => {
            const newCompany = { ...prev };
            newCompany.working = event.target.checked;
            newCompany.endAt = null;
            return newCompany;
        });
    };

    const handleBeginAtChange = (date, value) => {
        setCompanyBeingCreated((prev) => {
            const newCompany = { ...prev };
            newCompany.beginAt = date?.toJSON();
            return newCompany;
        });
    };

    const handleEndAtChange = (date, value) => {
        setCompanyBeingCreated((prev) => {
            const newCompany = { ...prev };
            newCompany.endAt = date?.toJSON();
            return newCompany;
        });
    };

    const handleCompanyCancle = (event) => {
        event.preventDefault();
        setCompanyBeingCreated(initialCompany);
        setCompanyEditIndex(-1);
        setCompanyAdd(false);
    };

    const handleCompanySubmit = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        let newCompany = { ...companyBeingCreated };
        let newCompanies;
        if (companyEditIndex === -1) {
            const createdCareer = await createTalentProfileCareer(
                profile.token,
                companyBeingCreated
            );
            if (createdCareer) {
                newCompany.id = createdCareer.id;
                newCompanies = profile.companies.concat(newCompany);
            } else {
                history.push("/profile/access-expired");
            }
        } else {
            const updateResult = await updateTalentProfileCareer(
                profile.token,
                companyBeingCreated.id,
                companyBeingCreated
            );
            if (updateResult?.affected === 1) {
                newCompanies = profile.companies.map((company, index) => {
                    if (index === companyEditIndex) {
                        return companyBeingCreated;
                    }
                    return company;
                });
            } else if (!updateResult) {
                history.push("/profile/access-expired");
            }
        }
        console.log("newCompanies", newCompanies);
        dispatch(setCompanies(newCompanies));
        setCompanyBeingCreated(initialCompany);
        setCompanyEditIndex(-1);
        setIsUploading(false);
        setCompanyAdd(false);
    };

    const handleCompanyEdit = (index) => {
        setCompanyEditIndex(index);
        setCompanyBeingCreated(profile.companies[index]);
        setCompanyAdd(true);
    };

    const handleCompanyDelete = async (index, companyId) => {
        const isDeleted = await deleteTalentProfileCareer(
            profile.token,
            companyId
        );
        if (isDeleted) {
            const newCompanies = profile.companies.filter(
                (_, i) => i !== index
            );
            dispatch(setCompanies(newCompanies));
        } else {
            history.push("/profile/access-expired");
        }
    };

    const submitable =
        companyBeingCreated.company &&
        companyBeingCreated.position &&
        companyBeingCreated.beginAt &&
        (companyBeingCreated.working || companyBeingCreated.endAt) &&
        companyBeingCreated.task &&
        !isUploading;

    return (
        <Box className={classes.infoWrap}>
            <Box className={classes.infoHeader}>
                <Typography className={classes.infoTitle}>경력</Typography>
                {edit && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setCompanyAdd(true)}
                        disabled={companyAdd}
                    >
                        추가하기
                    </Button>
                )}
            </Box>
            {!companyAdd &&
                companyEditIndex === -1 &&
                profile.companies?.map((company, index) => (
                    <Fragment key={index}>
                        {index > 0 && <Divider />}
                        <Grid
                            key={index}
                            container
                            spacing={1}
                            className={classes.companyItemWrap}
                        >
                            <Grid item xs={4}>
                                <Typography className={classes.companyName}>
                                    {company.company}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.companyPosition}>
                                    {company.position}
                                </Typography>
                            </Grid>
                            {edit && (
                                <Grid item xs={2}>
                                    <Box className={classes.companyItemBtnWrap}>
                                        <Typography
                                            className={classes.companyEditBtn}
                                            onClick={() =>
                                                handleCompanyEdit(index)
                                            }
                                        >
                                            편집
                                        </Typography>
                                        <Typography
                                            className={classes.companyDeleteBtn}
                                            onClick={() =>
                                                handleCompanyDelete(
                                                    index,
                                                    company.id
                                                )
                                            }
                                        >
                                            삭제
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <Typography className={classes.companyDuration}>
                                    {displayLocalYearMonth(company.beginAt)} ~{" "}
                                    {company.endAt
                                        ? displayLocalYearMonth(company.endAt)
                                        : "재직중"}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.companyDetail}>
                                    {company.task}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={4} />
                            <Grid item xs={8}>
                                <Typography className={classes.companyLink}>
                                    {displayLink(company.link)}
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Fragment>
                ))}

            {companyAdd && (
                <>
                    <Divider className={classes.darkDivider} />

                    <Grid container spacing={3}>
                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    회사명
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                inputProps={{ maxLength: 64 }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="company"
                                variant="outlined"
                                placeholder="회사명 입력"
                                value={companyBeingCreated.company}
                                onChange={handleCompanyChange}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    직무
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.noBorderSelect}
                                    labelId="demo-simple-select-label"
                                    id="position"
                                    name="position"
                                    displayEmpty
                                    value={companyBeingCreated.position}
                                    onChange={handleCompanyChange}
                                    classes={{
                                        root: classes.resize,
                                    }}
                                >
                                    <MenuItem
                                        value=""
                                        classes={{
                                            root: classes.resize,
                                        }}
                                        disabled
                                    >
                                        선택해주세요.
                                    </MenuItem>
                                    {hopePositions.map((item, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={item}
                                            classes={{
                                                root: classes.resize,
                                            }}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    입사
                                </Typography>
                                <Box className={classes.required}></Box>
                                <Box className={classes.flexGrowBox} />
                                <Typography className={classes.infoSubLabel}>
                                    재직중
                                </Typography>
                                <AntSwitch
                                    id="working"
                                    name="working"
                                    checked={companyBeingCreated.working}
                                    onChange={handleCompanyWorkingChange}
                                />
                            </Box>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: classes.dateInput,
                                            root: classes.adornedEnd,
                                            notchedOutline: classes.whiteBorder,
                                        },
                                    }}
                                    KeyboardButtonProps={{
                                        classes: {
                                            root: classes.keyboardBtn,
                                        },
                                        "aria-label": "change date",
                                    }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    views={["year", "month"]}
                                    format="yyyy/MM"
                                    invalidDateMessage={
                                        <Typography style={{ fontSize: 12 }}>
                                            YYYY/MM 형식으로 입력해 주세요!
                                        </Typography>
                                    }
                                    margin="normal"
                                    id="beginAt"
                                    disableFuture
                                    maxDateMessage={
                                        <Typography style={{ fontSize: 12 }}>
                                            오늘 이전 날짜를 입력해 주세요!
                                        </Typography>
                                    }
                                    value={companyBeingCreated.beginAt}
                                    onChange={handleBeginAtChange}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!companyBeingCreated.working && (
                            <Grid item xs={6} className={classes.gridItem}>
                                <Box className={classes.gridItemHeader}>
                                    <Typography
                                        className={classes.infoSubTitle}
                                    >
                                        퇴사
                                    </Typography>
                                    <Box className={classes.required}></Box>
                                </Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.dateInput,
                                                root: classes.adornedEnd,
                                                notchedOutline:
                                                    classes.whiteBorder,
                                            },
                                        }}
                                        KeyboardButtonProps={{
                                            classes: {
                                                root: classes.keyboardBtn,
                                            },
                                            "aria-label": "change date",
                                        }}
                                        variant="inline"
                                        inputVariant="outlined"
                                        views={["year", "month"]}
                                        format="yyyy/MM"
                                        invalidDateMessage={
                                            <Typography
                                                style={{ fontSize: 12 }}
                                            >
                                                YYYY/MM 형식으로 입력해 주세요!
                                            </Typography>
                                        }
                                        margin="normal"
                                        id="endAt"
                                        disableFuture
                                        minDate={companyBeingCreated.beginAt}
                                        minDateMessage={
                                            <Typography
                                                style={{ fontSize: 12 }}
                                            >
                                                입사 이후 날짜를 입력해 주세요!
                                            </Typography>
                                        }
                                        maxDateMessage={
                                            <Typography
                                                style={{ fontSize: 12 }}
                                            >
                                                오늘 이전 날짜를 입력해 주세요!
                                            </Typography>
                                        }
                                        value={companyBeingCreated.endAt}
                                        onChange={handleEndAtChange}
                                        autoOk={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    주요 업무 내용 및 성과
                                </Typography>
                                <Box className={classes.required}></Box>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                inputProps={{ maxLength: 1000 }}
                                multiline
                                minRows={3}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="task"
                                variant="outlined"
                                placeholder="주요 업무 내용 입력 (최대 1000자)"
                                value={companyBeingCreated.task}
                                onChange={handleCompanyChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.gridItemHeader}>
                                <Typography className={classes.infoSubTitle}>
                                    참고 링크 (선택)
                                </Typography>
                            </Box>
                            <TextField
                                required
                                className={classes.textField}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                        notchedOutline: classes.noBorder,
                                    },
                                }}
                                id="link"
                                variant="outlined"
                                placeholder="https://www.talentx.co.kr"
                                value={companyBeingCreated.link}
                                onChange={handleCompanyChange}
                            />
                        </Grid> */}
                    </Grid>
                    <Box className={classes.infoFooter}>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="outlined"
                            color="default"
                            disableElevation
                            onClick={handleCompanyCancle}
                        >
                            취소
                        </Button>
                        <Button
                            className={classes.infoFooterBtn}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleCompanySubmit}
                            disabled={!submitable}
                        >
                            {companyEditIndex !== -1 ? "수정" : "등록"}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProfileCompany;
