import { makeStyles } from "@material-ui/core/styles";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: 56,
        backgroundColor: theme.palette.common.white,
        textAlign: "center",
        width: "100%",
    },
    tabs: {
        flexGrow: 1,
    },
    values: {
        textAlign: "center",
        paddingTop: 60,
        paddingLeft: 36,
        paddingRight: 36,
    },
    proposition: {
        ...Texts.basic(26, "bold", "#2c2c2c", 1.29),
        marginBottom: 80,
        whiteSpace: "pre-wrap",
    },
    value: {
        marginBottom: 80,
    },
    valueImg: { marginBottom: 32 },
    valueTitle: {
        ...Texts.basic(20, "bold", "#111111", 1.4),
        marginBottom: 12,
    },
    valueDesc: {
        ...Texts.basic(14, 500, "#5f5f5f", 1.38),
        whiteSpace: "pre-wrap",
        wordBreak: "keep-all",
        letterSpacing: -0.6,
    },
    clientsSection: {
        backgroundColor: "#ffffff",
        textAlign: "center",
        paddingTop: 80,
        paddingBottom: 80,
    },
    clientsTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        whiteSpace: "pre-wrap",
    },
    clientsDesc: {
        ...Texts.basic(16, 500, "#787878", 1.38),
        whiteSpace: "pre-wrap",
        marginTop: 16,
        marginBottom: 50,
    },
    clientsLogos: {
        flexGrow: 1,
    },
    clientLogoGridItem: {
        height: 64,
    },
    clientLogo: {
        height: 64,
    },
    targetUserSection: {
        height: 640,
        backgroundImage: `url(${require("assets/bgi-target-user.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    targetUserTitle: {
        ...Texts.basic(28, "bold", "#ffffff", 1.29),
        textShadow: "0px 0px 16px rgba(0, 0, 0, 0.6)",
        whiteSpace: "pre-wrap",
    },
    reviewSection: {
        backgroundColor: "#f8f8f8",
        textAlign: "center",
        paddingTop: 80,
        paddingBottom: 80,
        overflowX: "hidden",
    },
    reviewSectionContainer: {
        position: "relative",
        overflowX: "visible",
    },
    reviewTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.38),
        whiteSpace: "pre-wrap",
        marginBottom: 40,
    },
    reviewCardWindow: {
        display: "flex",
        position: "relative",
        paddingLeft: 20,
        overflowX: "auto",
        overflowY: "hidden",
        flexDirection: "row",
        marginBottom: 24,
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    reviewCard: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
        borderRadius: 20,
        boxShadow: "0 2px 16px 0 #eee",
        width: 260,
        height: 288,
        padding: 24,
        marginRight: 20,
    },
    reviewRatingWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    reviewRating: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 7,
    },
    reviewRatingDesc: {
        ...Texts.basic(14, 500, "#ababab", 1.36),
        marginBottom: 20,
    },
    reviewContent: {
        flexGrow: 1,
        ...Texts.basic(14, 500, "#111111", 1.43),
        textAlign: "left",
    },
    reviewStar: {
        width: 24,
        height: 24,
    },
    reviewClientLogo: {
        height: 20,
    },
    reviewClient: {
        marginTop: 12,
        ...Texts.basic(16, 500, "#929292", 1.38),
    },
    reviewCardWindowScroll: {},
    reviewCardWindowScroller: {
        display: "inline-flex",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        boxShadow: "0 2px 16px 0 #eee",
        width: 56,
        height: 56,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 16,
    },
    statSection: {
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        textAlign: "left",
        backgroundColor: "#f8f8f8",
    },
    statTitleWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    statTitle: { ...Texts.basic(18, "bold", "#929292", 1.38), marginLeft: 12 },
    statDesc: {
        ...Texts.basic(28, "bold", "#2c2c2c", 1.29),
        whiteSpace: "pre-wrap",
        marginTop: 20,
        marginBottom: 44,
    },
    statValueWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 44,
    },
    statKey: { ...Texts.basic(14, 500, "#ababab", 1.38), marginBottom: 12 },
    statValue: { ...Texts.basic(32, 500, "#111111", 1.41) },
    verticalDivider: {
        backgroundColor: "#dddddd",
        width: 1,
        height: 64,
        marginLeft: 32,
        marginRight: 32,
    },
    statImg: { width: "100%", display: "block" },
    simpleSection: {
        backgroundColor: "#ebf0ff",
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 80,
    },
    simpleTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
    simpleTitle: {
        ...Texts.basic(18, "bold", "#929292", 1.33),
        marginLeft: 12,
    },
    simpleDesc: {
        ...Texts.basic(28, "bold", "#2c2c2c", 1.29),
        whiteSpace: "pre-wrap",
        marginBottom: 52,
    },
    simpleContentTopWrapper: {
        backgroundColor: "#ffffff",
        borderRadius: 28,
        paddingTop: 32,
        paddingLeft: 32,
        marginBottom: 20,
        paddingRight: 32,
    },
    simpleContentTopImg: {
        width: "100%",
        display: "block",
    },
    simpleContentTopDesc: {
        ...Texts.basic(16, "bold", "#2c2c2c", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginBottom: 40,
    },
    simpleContentBottomLeftWrapper: {
        borderRadius: 28,
        backgroundColor: "#3c6af5",
        padding: 32,
        marginBottom: 20,
    },
    simpleContentBottomLeftDesc: {
        ...Texts.basic(16, "bold", "#9db4fa", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginBottom: 20,
    },
    simpleContentBottomLeftMore: {
        ...Texts.basic(16, "bold", "#ffffff", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginBottom: 49,
    },
    simpleContentBottomLeftImg: { width: "100%" },
    simpleContentBottomRightWrapper: {
        backgroundColor: "#051c60",
        borderRadius: 28,
        padding: 32,
    },
    simpleContentBottomRightDesc: {
        ...Texts.basic(24, "bold", "#ffffff", 1.38),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        marginBottom: 52,
    },
    simpleContentBottomRightImg: { alignSelf: "center" },
    profileSection: {
        backgroundColor: "#ffffff",
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 60,
        textAlign: "left",
    },

    profileTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    profileTitle: {
        ...Texts.basic(18, "bold", "#929292", 1.38),
        marginLeft: 12,
    },
    profileDesc: {
        ...Texts.basic(28, "bold", "#2c2c2c", 1.29),
        whiteSpace: "pre-wrap",
        marginBottom: 64,
    },
    profileContentTopImg: { width: "100%" },
    profileContentMiddleIcon: {
        marginTop: 20,
        marginBottom: 12,
    },
    profileContentMiddleDesc: {
        ...Texts.basic(20, "bold", "#111111", 1.4),
        whiteSpace: "pre-wrap",
        marginBottom: 40,
    },
    profileContentBottomImg: { width: "100%" },
    supportSection: {
        backgroundColor: "#f8f8f8",
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 80,
    },
    supportTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
    supportTitle: {
        ...Texts.basic(18, "bold", "#929292", 1.38),
        marginLeft: 12,
    },
    supportDesc: {
        ...Texts.basic(20, "bold", "#2c2c2c", 1.41),
        whiteSpace: "pre-wrap",
        marginBottom: 8,
    },
    supportKeyword: {
        ...Texts.basic(28, "bold", "#3c6af5", 1.29),
        whiteSpace: "pre-wrap",
        marginBottom: 54,
    },
    supportContentTopWrapper: {
        backgroundColor: "#051c60",
        borderRadius: 28,
        paddingLeft: 27,
        paddingRight: 27,
        paddingBottom: 40,
        marginBottom: 20,
        textAlign: "center",
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    supportContentTopImg: { width: "100%" },
    supportContentTopDesc: {
        ...Texts.basic(20, "bold", "#ffffff", 1.4),
        whiteSpace: "pre-wrap",
    },
    supportContentMiddleLeftWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0a38c1",
        paddingTop: 80,
        paddingBottom: 80,
        borderRadius: 28,
        marginBottom: 20,
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    supportContentMiddleLeftImg: { marginBottom: 40 },
    supportContentMiddleLeftDesc: {
        ...Texts.basic(24, "bold", "#ffffff", 1.38),
        whiteSpace: "pre-wrap",
    },
    supportContentMiddleRightWrapper: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        padding: 20,
        marginBottom: 20,
        boxShadow: "0 8px 48px 0 #e5e5e",
    },
    supportContentMiddleRightChatLeftWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
    },
    supportContentMiddleRightChatLeftImg: {
        width: 48,
        height: 48,
        marginRight: 12,
    },
    supportContentMiddleRightChatLeftTextWrapper: {
        backgroundColor: "#3c6af5",
        borderRadius: 16,
        padding: 12,
        marginLeft: 12,
        whiteSpace: "pre-wrap",
        textAlign: "left",
        ...Texts.basic(13, 500, "#ffffff", 1.4),
    },
    supportContentMiddleRightChatRightWrapper: {
        backgroundColor: "#eeeeee",
        flex: 1,
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        borderRadius: 16,
        padding: 12,
        whiteSpace: "pre-wrap",
        textAlign: "left",
        ...Texts.basic(13, 500, "#111111", 1.4),
        marginTop: 24,
        marginBottom: 32,
    },

    supportContentBottomLeftWrapper: {
        textAlign: "center",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        paddingTop: 52,
        marginBottom: 20,
        boxShadow: "0 8px 48px 0 #e5e5e",
    },
    supportContentBottomTitle: {
        ...Texts.basic(24, "bold", "#2c2c2c", 1.4),
        marginBottom: 12,
    },
    supportContentBottomDesc: {
        ...Texts.basic(16, 500, "#454545", 1.38),
        whiteSpace: "pre-wrap",
        marginBottom: 40,
    },
    supportContentBottomImg: { marginBottom: 64 },
    supportContentBottomRightWrapper: {
        textAlign: "center",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        paddingTop: 52,
        marginBottom: 20,
        boxShadow: "0 8px 48px 0 #e5e5e",
    },
    brandingSection: {
        backgroundColor: "#ffffff",
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 80,
        textAlign: "left",
    },
    brandingTitleWrapper: { marginBottom: 16 },
    brandingTitle: { ...Texts.basic(18, "bold", "#3c6af5", 1.38) },
    brandingDesc: {
        ...Texts.basic(28, "bold", "#2c2c2c", 1.29),
        marginBottom: 52,
        whiteSpace: "pre-wrap",
    },
    brandingContentTopWrapper: {
        backgroundColor: "#3c6af5",
        borderRadius: 24,
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
        marginBottom: 20,
        boxShadow: "0 8px 48px 0 #eeeeee",
    },
    brandingContentTopTitle: {
        ...Texts.basic(16, "bold", "#ffffff", 1.38),
        marginBottom: 24,
        whiteSpace: "pre-wrap",
    },
    brandingContentTopDesc: {
        ...Texts.basic(16, "bold", "#cdd9fd", 1.46),
        whiteSpace: "pre-wrap",
        marginBottom: 20,
    },
    brandingContentTopImg: { width: "100%", display: "block" },
    brandingContentTopRightWrapper: { marginLeft: 45 },
    brandingContentTopRightImg: { display: "block" },

    brandingContentMiddleWrapper: {
        borderRadius: 28,
        backgroundColor: "#213563",
        display: "flex",
        flexDirection: "column",
        padding: 30,
        textAlign: "left",
        boxShadow: "0 8px 48px 0 #eeeeee",
        marginBottom: 20,
    },
    brandingContentMiddleTitle: {
        ...Texts.basic(24, "bold", "#ffffff", 1.4),
        whiteSpace: "pre-wrap",
    },
    brandingContentMiddleImg: { marginTop: 36, alignSelf: "flex-end" },
    brandingContentBottomWrapper: {
        borderRadius: 28,
        boxShadow: "0 8px 48px 0 #e5e5e5",
        paddingTop: 46,
    },
    brandingContentBottomImg: {
        width: "100%",
        display: "block",
    },
    openscoutTopSection: {
        backgroundColor: "#f8f8f8",
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 48,
        textAlign: "left",
    },
    openscoutTitleWrapper: { marginBottom: 16 },
    openscoutTitle: { ...Texts.basic(18, "bold", "#3c6af5", 1.38) },
    openscoutDesc: {
        ...Texts.basic(28, "bold", "#111111", 1.38),
        marginBottom: 32,
        whiteSpace: "pre-wrap",
    },
    openscoutDescMore: {
        ...Texts.basic(20, "bold", "#3c6af5", 1.43),

        whiteSpace: "pre-wrap",
    },
    openscoutContentTopWrapper: { marginBottom: 40 },
    openscoutMiddleSection: {
        backgroundColor: "#020e30",
        boxShadow: "0 8px 48px 0 #e6e6e6",
        paddingTop: 64,
        paddingLeft: 30,
        paddingRight: 30,
    },
    openscoutContentImg: { width: "100%", display: "block" },
    openscoutContentMiddleTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 12,
    },
    openscoutContentMiddleTitle: {
        ...Texts.basic(18, "bold", "#5f5f5f", 1.36),
        marginLeft: 8,
    },
    openscoutContentMiddleDesc: {
        ...Texts.basic(16, 500, "#929292", 1.4),
        marginBottom: 64,
        whiteSpace: "pre-wrap",
    },
    openscoutBottomSection: {
        backgroundColor: "#f8f8f8",
        paddingTop: 32,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 48,
        textAlign: "left",
    },
    openscoutContentWrapper: { textAlign: "left", marginBottom: 52 },
    openscoutContentBottomTitle: {
        ...Texts.basic(24, "bold", "#111111", 1.43),
        marginTop: 20,
        marginBottom: 16,
        whiteSpace: "pre-wrap",
    },
    openscoutContentBottomDesc: {
        ...Texts.basic(16, 500, "#5f5f5f", 1.4),
        whiteSpace: "pre-wrap",
    },
    openscoutContentBottomMiddleWrapper: { flex: 1 },
    openscoutContentBottomRightWrapper: { flex: 1 },
    pricingSection: {
        backgroundColor: "#ebf0ff",
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 80,
        textAlign: "center",
    },
    pricingTitle: {
        ...Texts.basic(28, "bold", "#020e30", 1.33),
        marginBottom: 52,
        whiteSpace: "pre-wrap",
    },
    pricingSubTitle: {
        ...Texts.basic(20, "bold", "#020e30", 1.33),
        marginBottom: 24,
        whiteSpace: "pre-wrap",
    },
    pricingContentPriceWrapper: { marginBottom: 33 },
    pricingContentServiceWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 17,
    },
    pricingContentLeftWrapper: {
        flex: 1,
        backgroundColor: "#ffffff",
        padding: 24,
        borderRadius: 12,
        boxShadow: "0 6px 36px -10px #cdd9fd",
        borderTopStyle: "solid",
        borderTopWidth: 10,
        borderTopColor: "#929292",
        textAlign: "left",
        marginBottom: 20,
    },
    pricingContentLeftTitle: {
        ...Texts.basic(16, "bold", "#2c2c2c", 1.4),
        marginBottom: 30,
        textAlign: "center",
    },
    pricingContentDesc: {
        ...Texts.basic(14, 500, "#5f5f5f", 1.4),
        marginTop: -22,
        marginBottom: 30,
    },
    pricingContentLeftPrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentLeftService: {
        marginLeft: 8,
        ...Texts.basic(14, 500, "#5f5f5f", 1.38),
    },
    membershipBtn: {
        marginTop: 16,
        width: "100%",
    },
    pricingContentMiddleWrapper: {
        backgroundColor: "#ffffff",
        padding: 24,
        borderRadius: 12,
        border: "solid 2px #c6d4ff",
        borderTopWidth: 10,
        borderTopColor: "#8ca9ff",
        boxShadow: "0 6px 36px -10px #cdd9fd",
        textAlign: "left",
        marginBottom: 20,
    },
    pricingContentMiddleTitle: {
        ...Texts.basic(16, "bold", "#496fe5", 1.4),
        marginBottom: 30,
        textAlign: "center",
    },
    pricingContentMiddlePrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentMiddleService: {
        marginLeft: 8,
        ...Texts.basic(14, "bold", "#6a8ceb", 1.38),
    },

    pricingContentRightWrapper: {
        backgroundColor: "#ffffff",
        padding: 24,
        borderRadius: 12,
        border: "solid 2px #a1b1e3",
        borderTopWidth: 10,
        borderTopColor: "#2352dc",
        boxShadow: "0 6px 36px -10px #cdd9fd",
        textAlign: "left",
    },
    pricingContentRightTitle: {
        ...Texts.basic(16, "bold", "#0d3bc0", 1.4),
        marginBottom: 30,
        textAlign: "center",
    },
    pricingContentRightPrice: {
        ...Texts.basic(16, "bold", "#111111", 1.38),
        marginBottom: 12,
    },
    pricingContentRightService: {
        marginLeft: 8,
        ...Texts.basic(14, "bold", "#0d3bc0", 1.38),
    },
    benefits: {
        //backgroundColor: "white",
        //display: "flex",
        //flexDirection: "column",
        //justifyContent: "center",
        //alignItems: "center",
        textAlign: "center",
    },
    benefit: {
        marginTop: 100,
    },
    benefitKeyword: {
        display: "inline-block",
        backgroundColor: "#0c46f2",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 24,
        color: "#ffffff",
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 32,
    },
    benefitTitle: {
        fontSize: 24,
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        marginBottom: 32,
        lineHeight: 1.38,
    },
    benefitImgIconWrap: {
        position: "relative",
    },
    benefitImg: {
        width: "100%",
        //marginBottom: 32,
    },
    benefitIcon: {
        position: "absolute",
        left: "60%",
        top: "60%",
        animation: "$motion 0.7s ease-in-out 0.3s infinite alternate",
        marginTop: 0,
    },
    "@keyframes motion": {
        "0%": {
            marginTop: 0,
        },
        "100%": {
            marginTop: 20,
        },
    },
    benefitDesc0: {
        display: "block",
        fontSize: 16,
        fontWeight: 500,
        whiteSpace: "pre-wrap",
        color: "#444444",
        marginLeft: 40,
        marginRight: 40,
        marginTop: 32,
        marginBottom: 4,
    },
    benefitDesc1: {
        display: "block",
        fontSize: 16,
        fontWeight: 500,
        color: "#666666",
        marginLeft: 40,
        marginRight: 40,
        marginTop: 4,
        marginBottom: 4,
        paddingTop: 13,
        paddingBottom: 11,
        backgroundColor: "#f8f8f8",
        boxShadow: "inset 0 2px 8px 0 rgba(125, 125, 125, 0.24)",
        borderRadius: 8,
        //border: "1px solid black",
    },
    message: {
        marginTop: 64,
        fontSize: 14,
        fontWeight: 500,
        color: "#444444",
        whiteSpace: "pre-wrap",
    },
    features: {
        position: "relative",
        textAlign: "center",
    },
    featureTitle: {
        position: "absolute",
        top: 35,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "90%",
        fontSize: 24,
        fontWeight: "bold",
        color: "#222222",
        whiteSpace: "pre-wrap",
        lineHeight: 1.33,
    },
    featureImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        marginLeft: "auto",
        marginRight: "auto",
    },
    featureMsg: {
        paddingTop: 32,
        fontSize: 14,
        fontWeight: 300,
        color: "#444444",
        whiteSpace: "pre-wrap",
        paddingBottom: 48,
    },
    steps: {
        position: "relative",
        textAlign: "center",
    },
    stepTitle: {
        position: "absolute",
        top: 65,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "80%",
        fontSize: 24,
        fontWeight: "bold",
        color: "#ffffff",
        whiteSpace: "pre-wrap",
        lineHeight: 1.33,
    },
    stepImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        marginLeft: "auto",
        marginRight: "auto",
    },
    stepMsg: {
        paddingTop: 32,
        backgroundColor: "#074396",
        fontSize: 14,
        fontWeight: 300,
        color: "#ffffff",
        whiteSpace: "pre-wrap",
        paddingBottom: 48,
    },
    manager: {
        paddingTop: 80,
        backgroundColor: "#f0f4ff",
    },
    managerKeyword: {
        display: "inline-block",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 24,
        color: "#0c46f2",
        border: "2px solid #0c46f2",
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 24,
    },
    managerTitle: {
        fontSize: 24,
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        marginBottom: 40,
        lineHeight: 1.33,
    },
    managerImgIconWrap: {
        position: "relative",
    },
    managerImg: {
        //width: "100%",
        //marginBottom: 32,
    },
    progressBar: {
        position: "relative",
        zIndex: 0,
    },
    line: {
        position: "absolute",
        top: "50%",
        left: "25%",
        width: "50%",
        borderBottom: "2px solid #1a6bff",
        zIndex: 1,
    },
    progress: {
        position: "relative",
        display: "inline-block",
        padding: theme.spacing(1, 2),
        borderRadius: 24,
        color: "#444444",
        border: "2px solid #1a6bff",
        fontSize: 16,
        fontWeight: "bold",
        margin: theme.spacing(3, 3),
        zIndex: 2,
        backgroundColor: "#ffffff",
    },
    progressLabel: {
        display: "inline-block",
        //padding: theme.spacing(1, 2),
        color: "#444444",
        fontSize: 12,
        fontWeight: 500,
        width: 70,
        margin: theme.spacing(0, 1.5, 10),
        whiteSpace: "pre-wrap",
    },
    paper: {
        margin: "0 auto",
        //width: 600,
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(30),
        },
    },
    fixedButton: {
        backgroundColor: "#4d4d4d",
        textAlign: "center",
        position: "fixed",
        zIndex: 1,
        bottom: 0,
        width: "100%",
        maxWidth: 444,
        height: 60,
        left: "50%",
        transform: "translate(-50%, 0)",
        paddingTop: 18,
        paddingBottom: 18,
    },
    fixedButtonName: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.43,
    },
    // fixedButtonDesc: {
    //     color: "#ffffff",
    //     fontSize: 10,
    //     fontWeight: 500,
    //     lineHeight: 1.43,
    // },
    phantom: {
        width: "100%",
        height: 50,
    },
    fixedButtonRouteLink: {
        textDecoration: "none",
    },

    qnaSection: {
        backgroundColor: "#ffffff",
        paddingTop: 52,
        paddingBottom: 42,
    },
    qnaSectionTitle: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 32,
    },
    qnaBox: {
        marginLeft: 24,
        marginRight: 24,
        textAlign: "left",
        marginBottom: 16,
    },
    questionBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 16,
    },
    question: {
        marginLeft: 4,
        flexGrow: 1,
    },
    answerBox: {
        backgroundColor: "#f2f2f2",
        justifyContent: "flex-start",
        padding: 16,
    },
    answer: {
        fontSize: 14,
        fontWeight: 300,
        color: "#444444",
        whiteSpace: "pre-wrap",
    },
    answerBoxClosed: {
        display: "none",
    },
    divider: {
        width: "100%",
    },
}));
