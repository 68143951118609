import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    Container,
    List,
    ListItem,
    ListItemText,
    Button,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isAndroid, isWindows } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
    },
    appBar: {
        backgroundColor: "#ffffff",
        height: 56,
    },
    toolBar: {
        minHeight: 56,
    },
    identity: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    logo: {
        width: 70,
        height: 21,
    },
    link: {
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 500,
        marginRight: 12,
    },
    paddingBox: {
        flexGrow: 1,
    },
    promotionWrap: {
        position: "relative",
    },
    menuTextButton: {
        color: "#666666",
    },
    menuButton: {
        marginRight: 4,
    },
    badge: {
        backgroundColor: "#ff1717",
    },
    menuLink: {
        textDecoration: "none",
        color: "inherit",
    },
    listItemText: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 300,
    },
}));

const ServiceHeaderD = ({ userType }) => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Container maxWidth="lg" className={classes.container}>
                    <Toolbar classes={{ root: classes.toolBar }} disableGutters>
                        <RouterLink to="/" className={classes.identity}>
                            <img
                                src={require("assets/talentx_logo_blue.png")}
                                srcSet={`${require("assets/talentx_logo_blue@2x.png")} 2x, ${require("assets/talentx_logo_blue@3x.png")} 3x`}
                                className={classes.logo}
                                alt="talentX"
                            />
                        </RouterLink>
                        <Box className={classes.paddingBox}></Box>
                        <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/openscout/devstarx")}
                        >
                            데브스타 스카우트
                        </Button>
                        <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/pricing")}
                        >
                            가격 정책
                        </Button>
                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() =>
                                window.open(
                                    "https://talentx.notion.site/X-b29d54b8a653496c8c2452bedb07a4e6",
                                    "newWindow"
                                )
                            }
                        >
                            이직하기
                        </Button> */}
                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/talent-application")}
                        >
                            개발자 회원 신청
                        </Button> */}
                        <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/scout")}
                        >
                            스카우트 제안받기
                        </Button>
                        {/* <Box className={classes.promotionWrap}>
                            <Button
                                className={classes.menuTextButton}
                                onClick={() => history.push("/promotion")}
                            >
                                연봉로켓
                            </Button>
                            {userType === 1 && (
                                <Box
                                    style={{
                                        position: "absolute",
                                        top: 32,
                                        right: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            left: 87,
                                            width: 0,
                                            height: 0,
                                            borderLeft: "7px solid transparent",
                                            borderRight:
                                                "4px solid transparent",
                                            borderBottom: "5px solid #ff5252",
                                        }}
                                    ></div>
                                    <Typography
                                        style={{
                                            width: 110,
                                            backgroundColor: "#ff5252",
                                            borderRadius: 20,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            padding: theme.spacing(0.5, 2),
                                        }}
                                    >
                                        프로모션 진행 중
                                    </Typography>
                                </Box>
                            )}
                        </Box> */}
                        {/* <Box className={classes.promotionWrap}>
                            <Button
                                className={classes.menuTextButton}
                                onClick={() => history.push("/openscout")}
                            >
                                오픈스카우트
                            </Button>
                            {userType === 1 && (
                                <Box
                                    style={{
                                        position: "absolute",
                                        top: 32,
                                        right: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            left: 77,
                                            width: 0,
                                            height: 0,
                                            borderLeft: "7px solid transparent",
                                            borderRight:
                                                "4px solid transparent",
                                            borderBottom: "5px solid #ff5252",
                                        }}
                                    ></div>
                                    <Typography
                                        style={{
                                            width: 110,
                                            backgroundColor: "#ff5252",
                                            borderRadius: 20,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            padding: theme.spacing(0.5, 2, 0.6),
                                            textAlign: "center",
                                        }}
                                    >
                                        시즌1 진행 중
                                    </Typography>
                                </Box>
                            )}
                        </Box> */}
                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/company-application")}
                        >
                            기업 회원 신청
                        </Button>
                        <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/talent-application")}
                        >
                            SW인재 회원 신청
                        </Button> */}

                        <Button
                            className={classes.menuTextButton}
                            onClick={() =>
                                window.open(
                                    "https://open.kakao.com/o/gr3vXt4c",
                                    "newWindow"
                                )
                            }
                        >
                            오픈 채팅방
                        </Button>
                        <Button
                            className={classes.menuTextButton}
                            onClick={() =>
                                window.open(
                                    isAndroid || isWindows
                                        ? "https://play.google.com/store/apps/details?id=com.talentinum.talentx"
                                        : "https://apps.apple.com/kr/app/%ED%83%A4%EB%9F%B0%ED%8A%B8%EC%97%91%EC%8A%A4/id1529534277",
                                    "newWindow"
                                )
                            }
                        >
                            앱 설치
                        </Button>
                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() =>
                                window.open(
                                    "https://talentx.channel.io/",
                                    "newWindow"
                                )
                            }
                        >
                            문의하기
                        </Button> */}
                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/corporation")}
                        >
                            회사소개
                        </Button> */}
                        {/* <Button
                            className={classes.menuTextButton}
                            onClick={() => history.push("/announcement")}
                        >
                            합병공고
                        </Button> */}
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    );
};

export default ServiceHeaderD;
