export const companyMembershipData = [
    {
        name: "lightup",
        displayName: "라이트업",
        desc: "개발자 채용공고에 지원자가 많지 않아 대안 솔루션이 필요한 기업이 가볍게 시작하기 좋은 멤버십",
        values: ["· 개발자 채용: 1명"],
        price: "300만원/1년",
        features: [
            "· 멤버십 요금: 300만원/1년",
            "· 채용 수수료: 무료",
            "· 가입 보증금: 무료",
        ],
        services: [
            "AI 기반 개발자 정기 추천",
            "개발자 프로필 PDF 문서 제공",
            "인터뷰 스케쥴러(대면/비대면)",
            "디지털 싸인(입사 전 근로계약)",
            "탤런트엑스 케어(3개월 근속관리)",
            "개발팀 중심 채용 페이지 제작",
            "개발자 1명 채용권",
        ],
        benefits: [
            {
                icon: "face",
                title: "맞춤형 개발자 추천",
                desc: "채용공고에 지원자를 마냥 기다릴 필요 없이, 귀사 맞춤형 개발자를 매주 추천 받을 수 있습니다.",
            },
            {
                icon: "bolt",
                title: "빠른 모바일 시스템",
                desc: "추천 받은 개발자를 모바일로 빠르게 검토한 후, 맘에 드는 후보자만 골라서, 지원의향을 묻고 영입전형을 제안 할 수 있습니다.",
            },
            {
                icon: "money_off",
                title: "개발자 채용권 제공",
                desc: "채용 건별 수수료가 없으며 연회비만으로 경력 개발자 1명과 신입 개발자 1명을 채용할 수 있습니다.",
            },
        ],
        requirements: [
            { name: "임직원수", value: "10명 이상" },
            { name: "개발자수", value: "3명 이상" },
            { name: "매출/투자규모", value: "3억원 이상" },
        ],
    },
    {
        name: "scaleup",
        displayName: "스케일업",
        desc: "고속 성장을 위해 빠르게 개발팀을 확장해야 하는 기업이 합리적인 가격으로 이용하기 좋은 멤버십",
        values: ["· 개발자 채용: 3명"],
        price: "700만원/1년",
        features: [
            "· 멤버십 요금: 1,200만원/1년",
            "· 채용 수수료: 무료",
            "· 가입 보증금: 무료",
        ],
        services: [
            "AI 기반 개발자 정기 추천",
            "개발자 프로필 PDF 문서 제공",
            "인터뷰 스케쥴러(대면/비대면)",
            "디지털 싸인(입사 전 근로계약)",
            "탤런트엑스 케어(3개월 근속관리)",
            "개발팀 중심 채용 페이지 제작",
            "개발자 3명 채용권",
            "타겟 리크루팅(카카오톡 유료광고)",
        ],
        benefits: [
            {
                icon: "face",
                title: "맞춤형 개발자 추천",
                desc: "채용공고에 지원자를 마냥 기다릴 필요 없이, 귀사 맞춤형 개발자를 매주 추천 받을 수 있습니다.",
            },
            {
                icon: "bolt",
                title: "빠른 모바일 시스템",
                desc: "추천 받은 개발자를 모바일로 빠르게 검토한 후, 맘에 드는 후보자만 골라서, 지원의향을 묻고 영입전형을 제안 할 수 있습니다.",
            },
            {
                icon: "money_off",
                title: "개발자 무제한 채용",
                desc: "채용 건별 수수료가 없으며 연회비만으로 경력 개발자 3명과 신입 개발자를 인원수 제한 없이 채용할 수 있습니다.",
            },
        ],
        requirements: [
            { name: "임직원수", value: "30명 이상" },
            { name: "개발자수", value: "10명 이상" },
            { name: "매출/투자규모", value: "10억원 이상" },
        ],
    },
    {
        name: "premium",
        displayName: "프리미엄",
        desc: "지속 성장을 위해 상시 다수의 고급 개발자를 영입해야 하는 기업이 편리하게 이용하기 좋은 멤버십",
        values: ["· 개발자 채용: 무제한"],
        price: "3000만원/1년",
        features: [
            "· 멤버십 요금: 3000만원/1년",
            "· 채용 수수료: 무료",
            "· 가입 보증금: 무료",
        ],
        services: [
            "AI 기반 개발자 정기 추천",
            "개발자 프로필 PDF 문서 제공",
            "인터뷰 스케쥴러(대면/비대면)",
            "디지털 싸인(입사 전 근로계약)",
            "탤런트엑스 케어(3개월 근속관리)",
            "개발팀 중심 채용 페이지 제작",
            "개발자 무제한 채용권",
            "타겟 리크루팅(카카오톡 유료광고 + 커뮤니티)",
            "탤런트엑스 케어 플러스(+3개월 근속관리)",
            "채용 매니지먼트(전담 테크 리크루터 배정)",
            "프리미엄 추천(리더급, AI 등 고급 개발자)",
            "오픈스카우트(공개 채용 캠페인)",
        ],
        benefits: [
            {
                icon: "face",
                title: "맞춤형 개발자 추천",
                desc: "채용공고에 지원자를 마냥 기다릴 필요 없이, 귀사 맞춤형 개발자를 매주 추천 받을 수 있습니다.",
            },
            {
                icon: "bolt",
                title: "빠른 모바일 시스템",
                desc: "추천 받은 개발자를 모바일로 빠르게 검토한 후, 맘에 드는 후보자만 골라서, 지원의향을 묻고 영입전형을 제안 할 수 있습니다.",
            },
            {
                icon: "money_off",
                title: "개발자 무제한 채용",
                desc: "채용 건별 수수료가 없으며 연회비만으로 경력 개발자와 신입 개발자를 인원수 제한 없이 채용할 수 있습니다.",
            },
        ],
        requirements: [
            { name: "임직원수", value: "100명 이상" },
            { name: "개발자수", value: "30명 이상" },
            { name: "매출/투자규모", value: "30억원 이상" },
        ],
    },
];

export const companyMembershipDefaultData = {
    name: undefined,
    displayName: "",
    desc: "개발자 채용공고에 지원자가 많지 않아 대안 솔루션이 필요한 기업이 가볍게 시작하기 좋은 멤버십",
    values: ["· 경력 개발자: 1명", "· 신입 개발자: 1명"],
    price: "300만원/1년 ~",
    features: [
        "· 멤버십 요금: 300만원/1년",
        "· 채용 수수료: 무료",
        "· 가입 보증금: 무료",
    ],
    services: [
        "AI 기반 개발자 정기 추천",
        "개발자 프로필 PDF 문서 제공",
        "인터뷰 스케쥴러(대면/비대면)",
        "디지털 싸인(입사 전 근로계약)",
        "탤런트엑스 케어(3개월 근속관리)",
        "개발팀 중심 채용 페이지 제작",
        "개발자 1명 채용권",
    ],
    benefits: [
        {
            icon: "face",
            title: "맞춤형 개발자 추천",
            desc: "채용공고에 지원자를 마냥 기다릴 필요 없이, 귀사 맞춤형 개발자를 매주 추천 받을 수 있습니다.",
        },
        {
            icon: "bolt",
            title: "빠른 모바일 시스템",
            desc: "추천 받은 개발자를 모바일로 빠르게 검토한 후, 맘에 드는 후보자만 골라서, 지원의향을 묻고 영입전형을 제안 할 수 있습니다.",
        },
        {
            icon: "money_off",
            title: "개발자 채용권 제공",
            desc: "채용 건별 수수료가 없으며 멤버십 별로 정해진 인원수 만큼 개발자를 채용할 수 있습니다. 일부 멤버십은 무제한 채용권도 제공합니다.",
        },
    ],
    requirements: [
        { name: "임직원수", value: "10명 이상" },
        { name: "개발자수", value: "3명 이상" },
        { name: "매출/투자규모", value: "3억원 이상" },
    ],
};
