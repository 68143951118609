import React from "react";
import { Container, Box, Typography, Button } from "@material-ui/core";
import { ProfileHeaderD, ServiceFooterD } from "../components";
import { Helmet } from "react-helmet";
import { useStyles } from "./styleProfileAccessExpired";
import { TXImage } from "../../components/tx";

const ProfileAccessExpiredForCompany = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 프로필 작성링크 만료</title>
            </Helmet>
            <ProfileHeaderD edit={false} />
            <Container maxWidth="lg" disableGutters>
                <Box className={classes.inner}>
                    <Typography className={classes.alert}>
                        탤런트엑스 프로필 링크가 유효하지 않습니다.
                    </Typography>
                    <Typography className={classes.guide}>
                        앱의 '채용' > '인재 프로필' > '문서로 받기'를 클릭하면
                        유효링크를 이메일로 받을 수 있습니다.
                    </Typography>
                    {/* <TXImage
                        imgName="img-mobile-app-bg"
                        className={classes.img}
                    /> */}
                    <img
                        src={require("assets/image-talentx-qr.png")}
                        alt="img"
                        className={classes.img}
                    />
                    <Typography className={classes.imgLabel}>
                        탤런트엑스 채용 바로가기
                    </Typography>
                </Box>
            </Container>
            <ServiceFooterD backgroudColor={"#2c2c2c"} />
        </React.Fragment>
    );
};

export default ProfileAccessExpiredForCompany;
