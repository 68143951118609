import React from "react";
import * as config from "config";

export const createKakaoShareButton = () => {
    if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
            kakao.init(config.REACT_APP_KAKAO_JAVASCRIPT_SDK_API_KEY);
            console.log("KAKAO SDK is initialized :", kakao.isInitialized());
        }
        kakao.Link.createDefaultButton({
            container: "#kakaoShareBtn",
            objectType: "feed",
            content: {
                title: "탤런트엑스 연봉로켓",
                description: "2021 경력 개발자 이직 프로그램",
                imageUrl:
                    "https://talentinum.com/images/kakao-share-btn-img.png",
                link: {
                    mobileWebUrl: "https://talentinum.com/promotion",
                    webUrl: "https://talentinum.com/promotion",
                },
            },
            social: {
                likeCount: 10,
                commentCount: 20,
                sharedCount: 30,
            },
            buttons: [
                {
                    title: "연봉로켓 정보",
                    link: {
                        mobileWebUrl: "https://talentinum.com/promotion",
                        webUrl: "https://talentinum.com/promotion",
                    },
                },
                {
                    title: "탑승 신청하기",
                    link: {
                        mobileWebUrl:
                            "https://talentinum.com/talent-application",
                        webUrl: "https://talentinum.com/talent-application",
                    },
                },
            ],
            installTalk: true,
        });
    }
};
