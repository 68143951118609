import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            textAlign: "center",
            padding: theme.spacing(10, 0),
            backgroundColor: "#ffbbd4",
            color: "#1a1a84",
        },
        container: {
            [theme.breakpoints.down("xs")]: {
                padding: "0 32px",
            },
        },
        divider: {
            background: "#1a1a84",
            height: "2px",
            margin: theme.spacing(6, 0),
            [theme.breakpoints.down("xs")]: {
                margin: theme.spacing(3, 0),
            },
        },
        largeGutter: {
            margin: theme.spacing(0, 0, 8),
            [theme.breakpoints.down("xs")]: {
                margin: theme.spacing(0, 0, 6),
            },
        },
    };
});

export default function RecruitmentSummary(params) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Container maxWidth="sm" className={classes.container}>
                <Typography variant="h3" gutterBottom>
                    <b>APPLY</b>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    취업/이직을 준비하고 있는 사람이라면
                    <br />
                    제한없이 누구든 지원 가능해요!
                </Typography>
                <Divider variant="middle" className={classes.divider} />

                <Typography
                    variant="h5"
                    className={classes.typography}
                    gutterBottom
                >
                    <b>모집 기간</b>
                </Typography>
                <Typography
                    variant="subtitle1"
                    className={classes.largeGutter}
                    gutterBottom
                >
                    10.12(월) - 10.23(금)
                </Typography>

                <Typography variant="h5" gutterBottom>
                    <b>모집 대상</b>
                </Typography>
                <Typography
                    variant="subtitle1"
                    className={classes.largeGutter}
                    gutterBottom
                >
                    SW직군으로 취업/이직을 준비하고 있는 사람
                </Typography>

                <Typography variant="h5" gutterBottom>
                    <b>신청 방법</b>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    신청하기 버튼을 누르고{" "}
                    <b>
                        이름, 생년월일,
                        <br />
                        이메일, 휴대폰번호
                    </b>
                    만 입력하면 신청 완료!
                </Typography>
            </Container>
        </Box>
    );
}
