import { makeStyles } from "@material-ui/core";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "120px 20px 0px",
        "& > *": {
            color: "#222222",
        },
    },
    section: (props) => ({
        marginTop: props.isMobile ? 80 : 120,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: 20,
    }),
    programTitleWrap: {
        alignSelf: "flex-start",
        backgroundColor: "#3c6af5",
        borderRadius: 4,
    },
    programTitle: {
        marginTop: 4,
        marginRight: 8,
        marginBottom: 4,
        marginLeft: 8,
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.71,
        color: "#ffffff",
        letterSpacing: -0.6,
    },
    pageTitle: (props) => ({
        marginTop: 12,
        marginBottom: 12,
        fontSize: props.isMobile ? 24 : 32,
        fontWeight: "bold",
        lineHeight: 1.19,
        letterSpacing: -0.6,
    }),
    thickDivider: {
        marginBottom: 32,
        height: 2,
        backgroundColor: "#111111",
    },
    sectionTitle: {
        fontSize: 20,
        lineHeight: 1.2,
        fontWeight: "bold",
    },
    additInfo: {
        marginTop: 4,
        marginBottom: 8,
        fontSize: 14,
        fontWeight: "500",
        lineHeight: 1.7,
        color: "#929292",
    },
    stepList: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: 8,
    },
    stepTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.75,
        color: "#929292",
        flexShrink: 0,
    },
    stepContent: {
        marginLeft: 41,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.75,
        color: "#111111",
    },
    stepNotice: {
        marginTop: 4,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        color: "#787878",
    },
    eventBox: {
        marginTop: 24,
        padding: 20,
        border: "1px solid #eeeeee",
        boxShadow: "0 2px 12px 0 rgba(153, 153, 153, 0.5)",
        borderRadius: 8,
    },
    eventChip: {
        padding: "4px 8px",
        marginBottom: 26,
        backgroundColor: "#ffe9ee",
        color: "#3c6af5",
        fontSize: 12,
        fontWeight: "bold",
    },
    eventTitle: (props) => ({
        marginBottom: 12,
        fontSize: props.isMobile ? 18 : 20,
        fontWeight: "bold",
        lineHeight: 1.19,
    }),
    eventTitleBlue: (props) => ({
        color: "#3c6af5",
        fontSize: props.isMobile ? 18 : 20,
        fontWeight: 700,
        lineHeight: 1.19,
        display: "inline",
    }),
    eventGuide: {
        ...Texts.basic(14, 500, "#5f5f5f", 1.71),
    },
    eventGuideLink: {
        ...Texts.basic(14, "bold", "#3c6af5", 1.71),
        textDecoration: "underline",
        cursor: "pointer",
    },
    body: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: "32px 20px 12px",
        "& > *": {
            color: "#222222",
        },
    },

    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: theme.spacing(1),
            width: "100%",
        },
        "& > div": {
            margin: theme.spacing(1, 0, 4),
        },
        "& > hr": {
            margin: theme.spacing(3, 0),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    resize: {
        fontSize: 14,
    },
    sectionIcon: {
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 20,
        fontWeight: "bold",
        "&:nth-of-type(1)": {
            marginBottom: 28,
        },
    },
    desc: {
        fontSize: 14,
        fontWeight: 300,
        margin: "8px 0px 20px",
    },
    noticeBox: {
        backgroundColor: "#f8f8f8",
        border: "1px solid #dddddd",
        borderRadius: 8,
        padding: 16,
        marginBottom: 12,
        "& > *": {
            fontSize: 14,
            color: "#222222",
            margin: theme.spacing(0.5, 0, 0),
        },
        "& > p:first-child": {
            marginTop: 0,
        },
    },
    noticeTitle: {
        fontWeight: "bold",
        margin: theme.spacing(0, 0, 1),
    },
    caption: {
        ...Texts.basic(12, 300, "#bbbbbb"),
    },
    requirementBox: {
        border: "1px solid #999999",
        borderRadius: 8,
        padding: theme.spacing(2, 2, 3),
        marginBottom: 32,
    },
    requirementTitle: {
        ...Texts.basic(16, 500, "#222222"),
    },
    requirementItemBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 24,
    },
    requirementContentBox: {
        marginLeft: 12,
    },
    requirementLabel: {
        ...Texts.basic(12, "normal", "#999999"),
    },
    requirement: {
        ...Texts.basic(14, "normal", "#222222"),
    },
    stepSection: {
        "& > stepRoundNum:nth-child(1)": {
            backgroundColor: "#444444",
        },
    },

    stepBox: { display: "flex", flexDirection: "row", marginBottom: 24 },
    stepRoundNum: {
        border: "1px solid #dddddd",
        borderRadius: 18,
        backgroundColor: "#eeeeee",
        width: 36,
        height: 36,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...Texts.basic(14, "normal", "#999999", 1.71),
        marginRight: 12,
        flexShrink: 0,
    },
    stepRoundNumDark: {
        border: "1px solid #dddddd",
        borderRadius: 18,
        backgroundColor: "#444444",
        width: 36,
        height: 36,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...Texts.basic(14, "normal", "#ffffff", 1.71),
        marginRight: 12,
        flexShrink: 0,
    },
    step: {
        marginTop: 5,
        ...Texts.basic(16, 500, "#222222", 1.5),
        marginBottom: 3,
    },

    duration: {
        marginTop: 12,
        backgroundColor: "#eeeeee",
        padding: "6px 12px",
        ...Texts.basic(12, "normal", "#999999", 1.33),
        borderRadius: 14,
        display: "inline-flex",
    },
    inputTitle: {
        fontSize: 16,
        fontWeight: "bold",
    },
    inputGuide: {
        ...Texts.basic(14, 500, "#787878", 1.71),
        marginTop: 0,
    },
    inputNotices: {
        fontSize: 12,
        fontWeight: 300,
        color: "#fe2b2b",
        marginTop: 0,
    },
    imputLabel: {
        flex: 1,
    },
    inlinSelect: {
        paddingTop: 17.5,
        paddingBottom: 17.5,
        fontSize: 14,
    },
    addressTitleWrap: {
        display: "flex",
        justifyContent: "space-between",
    },
    resize2: {
        paddingRight: 0,
    },
    searchButton: {
        backgroundColor: "#0c46f2",
        ...Texts.basic(14, "normal", "#ffffff"),
        padding: 17,
        width: 140,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    majorBox: {
        display: "flex",
    },
    major: {
        flex: 0.6,
        marginRight: 16,
    },
    schoolState: {
        flex: 0.4,
    },
    sectionDivider: {
        height: theme.spacing(1),
        marginTop: 32,
    },
    positionBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "14px 16px",
        borderRadius: 8,
        border: "1px solid #dddddd",
        marginTop: 8,
    },
    positionBoxChecked: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "14px 16px",
        borderRadius: 8,
        border: "1px solid #3c6af5",
        marginTop: 8,
    },
    positionName: {
        marginLeft: 11,
        ...Texts.basic(16, "bold", "#111111", 1.38),
    },
    submitButton: {
        height: 56,
        width: "100%",
        backgroundColor: "#0c46f2",
        ...Texts.basic(16, "normal", "#ffffff"),
    },
    modalScreen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        outline: 0,
        textAlign: "center",
        borderRadius: 12,
        width: 320,
        padding: "32px 16px 20px",
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#222222",
        marginBottom: 8,
    },
    modalMessage: {
        fontSize: 16,
        fontWeight: 500,
        color: "#222222",
        marginBottom: 12,
    },
    modalInfo: {
        fontSize: 12,
        fontWeight: 300,
        color: "#999999",
        marginBottom: 32,
    },
    modalButton: {
        width: "100%",
        fontSize: 16,
        height: 56,
    },
    userBox: {
        border: "solid 1px #999999",
        borderRadius: 8,
        padding: 16,
        "& > *": {
            margin: theme.spacing(1, 0, 0),
            width: "100%",
            fontSize: 14,
            fontWeight: "bold",
        },
        "& > div:first-child": {
            marginTop: 0,
            marginBottom: 4,
            "& > *": {
                fontSize: 14,
                fontWeight: "bold",
            },
        },
    },
    foot: {
        backgroundColor: theme.palette.common.white,
        textAlign: "left",
        width: "100%",
        padding: 20,
    },
    consentTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 8,
    },
    consentNotice: {
        fontSize: 14,
        fontWeight: 300,
        marginBottom: 20,
    },
    noticeContent: {
        marginBottom: 16,
    },
    noticeCaption: {
        fontSize: 12,
        fontWeight: 500,
        color: "#bbbbbb",
    },
    noticeCaution: {
        ...Texts.basic(14, 500, "#454545", 1.71),
    },
    table: {
        borderTopStyle: "solid",
        borderTopWidth: 2,
        borderTopColor: "#111111",
        marginBottom: 20,
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
    },
    tableLeftTopCol: {
        flex: 1,
        backgroundColor: "#eeeeee",
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#c5c5c5",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#c5c5c5",
        padding: 12,
    },
    tableTopColText: {
        ...Texts.basic(14, 500, "#929292", 1.71),
    },
    tableLeftCol: {
        flex: 1,
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#c5c5c5",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#c5c5c5",
        padding: 12,
    },
    tableLeftColText: {
        ...Texts.basic(14, 500, "#454545", 1.71),
    },
    tableRightTopCol: {
        flex: 1,
        padding: 12,
        backgroundColor: "#eeeeee",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#c5c5c5",
    },
    tableRightCol: {
        flex: 1,
        padding: 12,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#c5c5c5",
    },
    tableRightColText: {
        ...Texts.basic(14, 500, "#454545", 1.71),
    },
    darkDevider: {
        backgroundColor: "#454545",
        marginBottom: 32,
    },
    consentBtn: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: 20,
        marginBottom: 28,
    },
    consent: {
        fontSize: 16,
        fontWeight: 300,
        marginLeft: 9,
    },
}));
