import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";

import Header from "./Header";
import CompanyListItem from "./CompanyListItem";
import CompanyListTitle from "./CompanyListTitle";

import { companyData } from "data";
import { shuffle } from "../../helper/misc";

import Link from "@material-ui/core/Link";
import { MyButton } from "pages/components";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(6),
        paddingBottom: 20,
        backgroundColor: "white",
    },
    midContainer: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    companyListContainer: {
        marginTop: theme.spacing(6),
    },
    upBtn: {
        marginTop: 40,
        marginBottom: 30,
        width: 83,
        height: 36,
        borderRadius: 20,
        border: "solid 1px #bababa",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    applyBtn: {
        margin: theme.spacing(10, 0),
        width: "33%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
        //borderRadius: 20,
        //border: "solid 1px #bababa",
        textDecoration: "none",
    },
    upText: {
        fontFamily: "Spoqa Han Sans",
        fontSize: 12,
        color: "#999999",
    },
}));

export default function CompanyListPage() {
    const classes = useStyles();
    const history = useHistory();

    // Functions
    const onBackPress = () => history.goBack();

    const getHiringStr = (hopePositions) => {
        return hopePositions.length === 0
            ? "내용없음"
            : hopePositions.join(", ") + " 채용중";
    };

    const onPressUp = () => window.scrollTo({ top: 0, behavior: "smooth" });

    useEffect(() => window.scrollTo({ top: 0 }), []);

    return (
        <>
            <Header onBackPress={onBackPress} />

            <Grid container className={classes.container}>
                <Grid item xs={false} sm={1} />

                <Grid
                    container
                    item
                    direction="column"
                    alignItems="center"
                    xs={12}
                    sm={10}
                    className={classes.midContainer}
                >
                    <CompanyListTitle numCompanies={companyData.length} />

                    <Grid
                        container
                        item
                        spacing={2}
                        className={classes.companyListContainer}
                    >
                        {shuffle(companyData).map((item, idx) => (
                            <CompanyListItem
                                key={idx}
                                idx={idx}
                                img={item.img}
                                name={item.name}
                                link={item.link}
                                goTo={item.goTo}
                                hiringStr={getHiringStr(item.hopePositions)}
                                isGood={item.isGood}
                                isNew={item.isNew}
                                isHot={item.isHot}
                            />
                        ))}
                    </Grid>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        //component={Link}
                        href="https://open-recruitment-apply.paperform.co/"
                        target="_blank"
                        className={classes.applyBtn}
                        disabled
                    >
                        신청이 마감되었습니다.
                    </Button>
                    <Button className={classes.upBtn} onClick={onPressUp}>
                        <Typography className={classes.upText}>
                            맨 위로
                        </Typography>
                    </Button>
                </Grid>

                <Grid item xs={false} sm={1} />
            </Grid>
            {/* <MyButton
                color="white"
                component={Link}
                href="https://open-recruitment-apply.paperform.co/"
                target="_blank"
            >
                <Typography variant="h6" noWrap>
                    <b>참가 신청하기</b>
                </Typography>
            </MyButton> */}
        </>
    );
}
