import React, { useEffect, useState } from "react";
import { Header, Footer } from "pages/components";

import {
    RecruitmentProcess,
    RecruitmentSummary,
    RecruitmentCompany,
    RecruitmentBenefits,
    RecruitmentIntro,
    RecruitmentMilestones,
    RecruitemntFAQ,
    RecruitemntPrecautions,
    RecruitemntOKR,
    RecruitmentApplyForm,
    HideNoticeBar,
    PopUp,
} from "pages/recruitment";
import { MainImage, steps, notices } from "data";
import Link from "@material-ui/core/Link";
import { MyButton } from "pages/components";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

export default function Recruitment() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadSteps, setLoadSteps] = useState(true);

    useEffect(() => {
        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;

        if (isEdge) {
            setInterval(() => {
                console.log("hi");
                setLoadSteps(false);
            }, 100);
        }
    }, []);

    useEffect(() => {
        setLoadSteps(true);
    }, [loadSteps]);

    return (
        <React.Fragment>
            {/* <Header /> */}
            <PopUp />
            {/* <HideNoticeBar /> */}
            <MainImage isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
            <RecruitmentIntro />
            <RecruitmentBenefits />
            <RecruitmentCompany />
            <RecruitmentSummary />
            <RecruitmentProcess
                steps={steps}
                notices={notices}
                isLoaded={loadSteps}
                setIsLoaded={setLoadSteps}
            />
            <RecruitmentMilestones />
            <RecruitemntFAQ />
            <RecruitemntPrecautions />
            {/* <RecruitemntOKR /> */}
            {/* <RecruitmentApplyForm /> */}

            {/* <MyButton
                color="white"
                component={Link}
                href="https://open-recruitment-apply.paperform.co/"
                target="_blank"
            >
                <Typography variant="h6" noWrap>
                    <b>참가 신청하기</b>
                </Typography>
            </MyButton> */}
            <Footer />
        </React.Fragment>
    );
}
