import React, { useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Home,
    UserEmailVerification,
    PassReset,
    Recruitment,
    Test,
    TermsOfUse,
    PrivacyPolicy,
    Authentication,
    Dashboard,
    TalentX,
    TalentXD,
    TalentApplication,
    TalentApplicationD,
    TalentMembership,
    TalentMembershipD,
    CompanyApplication,
    CompanyApplicationD,
    Promotion,
    PromotionD,
    CompanyIdentity,
    CompanyIdentityD,
    Download,
    DemoRequest,
    DemoRequestD,
    CompanyScout,
    CompanyDetail,
    OpenScout,
    OpenScoutX,
    TalentX3,
    TalentXD3,
    Announcement,
    AnnouncementD,
    ProfileViewForCompany,
    ProfileAccessExpiredForCompany,
    ProfileUnsupportedForCompany,
    ProfileView,
    Profile,
    ProfilePhoto,
    ProfileSkill,
    ProfileUnsupported,
    ProfileAccessExpired,
    DevStarX,
    DevStarXD,
    DevStarXCompanyApplication,
    CompanyListPage,
    ReverseOpenScoutAppication,
    Company3,
    CompanyD3,
    CompanyPricing3,
    CompanyPricingD3,
    CompanyContract,
    CompanyContractD,
} from "pages";

function Root() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Switch>
            <Route
                exact
                path={["/", "/talentx"]}
                component={isMobile ? TalentX3 : TalentXD3}
            ></Route>
            <Route
                exact
                path={["/", "/company"]}
                component={isMobile ? Company3 : CompanyD3}
            ></Route>
            <Route
                exact
                path="/profileview"
                component={isMobile ? ProfileUnsupported : ProfileView}
            ></Route>
            <Route
                exact
                path="/recommendations/access-expired"
                component={
                    isMobile
                        ? ProfileUnsupportedForCompany
                        : ProfileAccessExpiredForCompany
                }
            ></Route>
            <Route
                path="/recommendations/:id"
                component={
                    isMobile
                        ? ProfileUnsupportedForCompany
                        : ProfileViewForCompany
                }
            />
            <Route
                exact
                path="/profile"
                component={isMobile ? ProfileUnsupported : Profile}
            ></Route>
            <Route
                exact
                path="/profile/access-expired"
                component={isMobile ? ProfileUnsupported : ProfileAccessExpired}
            ></Route>
            <Route
                exact
                path="/profile/photo"
                component={isMobile ? ProfileUnsupported : ProfilePhoto}
            ></Route>
            <Route
                exact
                path="/profile/skill"
                component={isMobile ? ProfileUnsupported : ProfileSkill}
            ></Route>
            <Route
                exact
                path="/openscout/rocketx"
                component={OpenScoutX}
            ></Route>
            <Route
                exact
                path="/openscout/application"
                component={ReverseOpenScoutAppication}
            ></Route>
            <Route
                exact
                path="/openscout/devstarx/company-application"
                component={DevStarXCompanyApplication}
            ></Route>
            <Route
                exact
                path="/devstarx"
                component={isMobile ? DevStarX : DevStarXD}
            ></Route>
            <Route exact path="/scout" component={CompanyScout}></Route>
            <Route path="/scout/:id" component={CompanyDetail} />
            <Route
                exact
                path={["/download", "/talentx/download"]}
                component={Download}
            />
            <Route path="/app" component={Download} />
            <Route
                exact
                path={[
                    "/talent-application",
                    "/talent-application/:target",
                    "/talentx/talent",
                ]}
                component={isMobile ? TalentApplication : TalentApplicationD}
            />
            <Route
                exact
                path={["/talent-membership", "/talent-membership/:invitor"]}
                component={isMobile ? TalentMembership : TalentMembershipD}
            />
            <Route
                exact
                path={["/premium-invitation", "/premium-invitation/:invitor"]}
                component={isMobile ? TalentMembership : TalentMembershipD}
            />
            <Route
                exact
                path={["/company-contract", "/company-contract/:target"]}
                component={isMobile ? CompanyContract : CompanyContractD}
            />
            <Route
                exact
                path={["/company-application", "/talentx/company"]}
                component={isMobile ? CompanyContract : CompanyContractD}
            />
            <Route
                exact
                path={["/demo", "/talentx/demo"]}
                component={isMobile ? DemoRequest : DemoRequestD}
            />
            <Route
                path={["/promotion", "/talentx/promotion"]}
                component={isMobile ? Promotion : PromotionD}
            />

            <Route
                exact
                path="/ccs/verification-requests"
                component={() => <UserEmailVerification type="ccs" />}
            />
            <Route
                exact
                path="/talents/verification-requests"
                component={() => <UserEmailVerification type="talents" />}
            />
            <Route
                exact
                path="/ccs/passreset-requests"
                component={() => <PassReset type="ccs" />}
            />
            <Route
                exact
                path="/talents/passreset-requests"
                component={() => <PassReset type="talents" />}
            />
            <Route exact path="/recruitment" component={Recruitment} />
            <Route exact path="/company-list" component={CompanyListPage} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
                exact
                path="/talentx/admin/authentication"
                component={Authentication}
            />
            <Route
                exact
                path="/talentx/admin/dashboard"
                component={Dashboard}
            />
            <Route
                exact
                path="/pricing"
                component={isMobile ? CompanyPricing3 : CompanyPricingD3}
            />
            <Route
                exact
                path="/open-scout"
                component={() => <OpenScout attribution="external" />}
            ></Route>
            <Route
                exact
                path="/openscout"
                component={() => <OpenScoutX attribution="internal" />}
            ></Route>
            <Route
                exact
                path="/announcement"
                component={isMobile ? Announcement : AnnouncementD}
            ></Route>
            <Route
                exact
                path="/corporation"
                component={isMobile ? CompanyIdentity : CompanyIdentityD}
            />
        </Switch>
    );
}

export default Root;
