import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
    Container,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    FormControl,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Modal,
    Select,
    MenuItem,
    IconButton,
    Icon,
} from "@material-ui/core";
import { CompanyServiceHeaderD } from "pages/components";

import CloseIcon from "@material-ui/icons/Close";

import {
    putCompanyDocument,
    onCompanyApply,
    putCCApplication,
} from "../../apis/firestore/apply";

import "date-fns";
import NumberFormat from "react-number-format";
import {
    companyChannelData,
    companyMembershipData,
    companyMembershipDefaultData,
} from "data";

import { useStyles } from "./styleCompanyApplicationD";
import DaumPostcode from "react-daum-postcode";

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            //prefix="₩"
            suffix="원"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function HeadCountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            //prefix="₩"
            suffix="명"
        />
    );
}

HeadCountFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CompanyContractD = (props) => {
    const { target } = props.match.params;
    const query = new URLSearchParams(window.location.search);
    const utmSource = query.get("utm_source") || query.get("source");
    const utmMedium = query.get("utm_medium") || query.get("media");
    const initialChannel = `${utmSource ? utmSource + " " : ""}${
        utmMedium ? utmMedium : ""
    }`;
    let initialUsers = [{ name: "", email: "", phone: "" }];
    const classes = useStyles();
    const [membership, setMembership] = useState(target);
    const [companyName, setCompanyName] = useState("");
    const [businessNumber, setBusinessNumber] = useState("");
    const [businessEmail, setBusinessEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState();
    const [isOpenPost, setIsOpenPost] = useState(false);
    const [users, setUsers] = useState(initialUsers);
    const [numOfUsers, setNumOfUsers] = useState(1);
    const nextId = useRef(initialUsers.length);
    const [numOfEmployees, setNumOfEmployees] = useState("");
    const [numOfSWEngineers, setNumOfSWEngineers] = useState("");
    const [leader, setLeader] = useState("yes");
    const [revenue, setRevenue] = useState("");
    const [fund, setFund] = useState("");
    const [channel, setChannel] = useState(initialChannel);
    const [consent, setConsent] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);

    const handleMembershipChange = (event) => {
        setMembership(event.target.value);
    };

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const handleBusinessNumberChange = (event) => {
        setBusinessNumber(event.target.value);
    };

    const handleBusinessEmailChange = (event) => {
        setBusinessEmail(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    //Adress Input
    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    const AddressSearchButton = () => {
        return (
            <Button
                onClick={onChangeOpenPost}
                variant="contained"
                color="primary"
                disableElevation
                className={classes.searchButton}
            >
                주소검색
            </Button>
        );
    };

    const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddr += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddr +=
                    extraAddr !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
        }

        setPostalCode(data.zonecode);
        setAddress(fullAddr);
        setIsOpenPost(false);
    };

    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleAddressDetailChange = (event) => {
        setAddressDetail(event.target.value);
    };

    useEffect(() => {
        const fileSelect = document.getElementById("fileSelect");
        const fileElem = document.getElementById("fileElem");
        fileSelect.addEventListener(
            "click",
            function (e) {
                if (fileElem) {
                    fileElem.click();
                }
                e.preventDefault(); // "#" 해시로 이동을 방지
            },
            false
        );
    }, []);

    const openFiles = (e) => {
        const files = e.target.files;
        console.log("openFiles", files[0]);
        if (files[0]) {
            setFile(files[0]);
        }
    };

    useEffect(() => {
        async function uploadFile() {
            if (file) {
                const headers = {
                    "Content-Type": "multipart/form-data",
                };
                const data = new FormData();
                data.append("certificate", file);
                console.log("FormData", data);
                console.log({ headers });
                const uploadFileResult = await putCompanyDocument(data, {
                    headers,
                });
                console.log("uploadFileResult", uploadFileResult);
                console.log(
                    "uploadFileResult Location",
                    uploadFileResult.data.Location
                );
                setFileURL(uploadFileResult.data.Location);
            }
        }
        if (file) uploadFile();
    }, [file]);

    const handleUsersChange = (event) => {
        const { id, name, value } = event.target;
        let newUsers = users.slice(0);
        newUsers[Number(id)][name] = value;
        setUsers(newUsers);
    };

    const addUser = () => {
        let newUser = { name: "", email: "", phone: "" };
        setUsers((prevUsers) => prevUsers.concat(newUser));
        setNumOfUsers(++nextId.current);
    };

    const deleteUser = (idx) => {
        let newUsers = users.slice(0);
        newUsers.splice(idx, 1);
        setUsers(newUsers);

        setNumOfUsers(--nextId.current);
    };

    const handleNumOfEmployeesChange = (event) => {
        setNumOfEmployees(event.target.value);
    };

    const handleNumOfSWEngineersChange = (event) => {
        console.log("handleNumOfSWEngineersChange", event.target.value);
        setNumOfSWEngineers(event.target.value);
    };

    const handleLeaderChange = (event) => {
        setLeader(event.target.value);
    };
    const handleRevenueChange = (event) => {
        setRevenue(event.target.value);
    };
    const handleFundChange = (event) => {
        setFund(event.target.value);
    };
    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    };
    const handleSubmit = () => {
        setIsFetching(true);
        const usersWithAdmin = users.slice(0).concat({
            name: "휴리",
            email: `${businessNumber}@talentx.co.kr`,
            phone: "01048547456",
        });
        const application = {
            membership,
            companyName,
            businessNumber,
            businessEmail,
            phone: String(phone),
            zipCode: String(postalCode),
            address1: address,
            address2: addressDetail ?? null,
            documentURL: fileURL,
            users: usersWithAdmin,
            numOfEmployees: Number(numOfEmployees),
            numOfSWEngineers: Number(numOfSWEngineers),
            leader: leader === "yes",
            revenue: String(revenue),
            fund: String(fund),
            channel,
        };
        const result = onCompanyApply(application, setIsFetching, setOpen);
        if (result) putCCApplication(application, setIsFetching, setOpen);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        setIsFetching(true);
        setTimeout(() => props.history.push("/company"), 1000);
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        //setSubmittable(true);
        setSubmittable(
            membership &&
                companyName &&
                businessNumber &&
                businessEmail &&
                phone &&
                postalCode &&
                address &&
                users &&
                numOfEmployees &&
                numOfSWEngineers &&
                leader &&
                revenue &&
                fund &&
                channel &&
                consent &&
                file &&
                fileURL
        );
    }, [
        membership,
        companyName,
        businessNumber,
        businessEmail,
        phone,
        postalCode,
        address,
        users,
        numOfEmployees,
        numOfSWEngineers,
        leader,
        revenue,
        fund,
        channel,
        consent,
        file,
        fileURL,
    ]);

    return (
        <React.Fragment>
            <Helmet>
                <title>탤런트엑스 | 기업 멤버십</title>
            </Helmet>
            <CompanyServiceHeaderD maxWidth="sm" />
            <Container maxWidth="sm">
                <Box className={classes.head}>
                    <img
                        src={require("assets/icon-company.png")}
                        srcSet={`${require("assets/icon-company@2x.png")} 2x, ${require("assets/icon-company@3x.png")} 3x`}
                        className={classes.sectionIcon}
                        alt="company icon"
                    ></img>
                    <Typography className={classes.sectionTitle}>
                        탤런트엑스 멤버사가 되어,
                        <br />
                        프리미엄 개발자를 매주 추천 받으세요!
                    </Typography>
                    <Box className={classes.requirementBox}>
                        <Typography className={classes.requirementTitle}>
                            {
                                companyMembershipData.find(
                                    (item) => item.name === membership
                                )?.displayName
                            }{" "}
                            멤버십 혜택
                        </Typography>
                        {companyMembershipData
                            .find((item) => item.name === membership)
                            ?.benefits.map((benefit, idx) => (
                                <Box
                                    key={idx}
                                    className={classes.requirementItemBox}
                                >
                                    <Icon>{benefit.icon}</Icon>
                                    <Box
                                        className={
                                            classes.requirementContentBox
                                        }
                                    >
                                        <Typography
                                            className={classes.requirementLabel}
                                        >
                                            {benefit.title}
                                        </Typography>
                                        <Typography
                                            className={classes.requirement}
                                        >
                                            {benefit.desc}
                                        </Typography>
                                    </Box>
                                </Box>
                            )) ??
                            companyMembershipDefaultData.benefits.map(
                                (benefit, idx) => (
                                    <Box
                                        key={idx}
                                        className={classes.requirementItemBox}
                                    >
                                        <Icon>{benefit.icon}</Icon>
                                        <Box
                                            className={
                                                classes.requirementContentBox
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.requirementLabel
                                                }
                                            >
                                                {benefit.title}
                                            </Typography>
                                            <Typography
                                                className={classes.requirement}
                                            >
                                                {benefit.desc}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            )}

                        {/* <Typography className={classes.requirementGuide}>
                            * 권장 기업 : 임직원 수 10명 이상 / 개발자 수 3명
                            이상 / 매출 및 누적투자액 3억 이상
                        </Typography> */}
                    </Box>

                    <Box className={classes.stepSection}>
                        <Typography className={classes.stepTitle}>
                            가입 절차
                        </Typography>
                        <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNum}>1</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    신청서 제출
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    아래 <strong>멤버십 신청서</strong>를 제출해
                                    주시면 탤런트엑스 매니저가 검토 후{" "}
                                    <strong>멤버십 약정서</strong>와{" "}
                                    <strong>연회비 인보이스</strong>를 이메일로
                                    보내 드립니다.
                                </Typography>
                                <Typography className={classes.duration}>
                                    영업일 기준 1일 ~ 3일 소요
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNum}>2</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    연회비 납부
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    멤버십 약정서와 연회비 인보이스를 검토 하신
                                    후 <strong>연회비를 납입</strong>
                                    하시면 전자세금계산서를 발행해 드립니다.
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.stepBox}>
                            <Box className={classes.stepRoundNum}>3</Box>
                            <Box>
                                <Typography className={classes.step}>
                                    온보딩 세션
                                </Typography>
                                <Typography className={classes.stepContent}>
                                    탤런트엑스 매니저가 화상으로{" "}
                                    <strong>
                                        귀사의 채용 계획 및 상세 요건을 청취
                                    </strong>
                                    하고{" "}
                                    <strong>
                                        탤런트엑스 채용앱 설치 및 상세 이용
                                        방법을 안내
                                    </strong>
                                    해 드립니다.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Divider className={classes.sectionDivider} light></Divider>
            <Container maxWidth="sm">
                <Box className={classes.body}>
                    <Typography className={classes.sectionTitle}>
                        신청서 작성
                    </Typography>
                    <form
                        id="company-contract"
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography className={classes.inputTitle}>
                            탤런트엑스 멤버십 *
                        </Typography>
                        <FormControl
                            variant="outlined"
                            style={{ marginBottom: 32 }}
                        >
                            <Select
                                //labelId="demo-simple-select-label"
                                displayEmpty
                                value={membership}
                                onChange={handleMembershipChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    id="company-membership"
                                    value={target}
                                    classes={{ root: classes.resize }}
                                    disabled
                                >
                                    선택해주세요
                                </MenuItem>
                                {companyMembershipData.map((item, idx) => (
                                    <MenuItem
                                        id="company-membership"
                                        key={idx}
                                        value={item.name}
                                        classes={{ root: classes.resize }}
                                        ListItemClasses={classes}
                                    >
                                        <Box
                                            component={"span"}
                                            className={classes.menuItemWrap}
                                        >
                                            <Typography
                                                className={classes.menuItemText}
                                            >
                                                {item.displayName}
                                            </Typography>
                                            <Typography
                                                className={classes.menuItemText}
                                            >
                                                {item.price}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography className={classes.inputTitle}>
                            기업명 *
                        </Typography>
                        <TextField
                            required
                            id="company-name"
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="기업명을 입력해주세요."
                            //label="이름"
                            value={companyName}
                            onChange={handleCompanyNameChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            사업자 등록번호 *
                        </Typography>
                        <TextField
                            required
                            id="business-number"
                            inputProps={{ maxLength: 10 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="사업자 등록번호를 입력해주세요.(-없이 10자리)"
                            //label="이름"
                            value={businessNumber}
                            onChange={handleBusinessNumberChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            전자세금계산서 수신 이메일 *
                        </Typography>
                        <TextField
                            required
                            id="business-email"
                            inputProps={{ maxLength: 128 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="정산 담당자의 이메일을 입력해주세요."
                            value={businessEmail}
                            onChange={handleBusinessEmailChange}
                        />
                        <Typography className={classes.inputTitle}>
                            기업 전화번호 *
                        </Typography>
                        <TextField
                            required
                            id="phone"
                            inputProps={{ maxLength: 14 }}
                            InputProps={{
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="기업 전화번호를 입력해주세요.(-없이 번호만)"
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                        <Box
                            style={{ marginTop: 8, marginBottom: 8 }}
                            className={classes.addressTitleWrap}
                        >
                            <Typography className={classes.inputTitle}>
                                기업 주소 (채용 시 근무지) *
                            </Typography>
                            {isOpenPost ? (
                                <IconButton
                                    className={classes.closeBtn}
                                    aria-label="close"
                                    onClick={onChangeOpenPost}
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : (
                                <Box />
                            )}
                        </Box>
                        {isOpenPost ? (
                            <DaumPostcode
                                style={{
                                    display: "block",
                                    position: "relative",
                                    top: "50%",
                                    width: "100%",
                                    height: "500px",
                                    //padding: "7px",
                                }}
                                autoClose
                                onComplete={onCompletePost}
                            />
                        ) : (
                            <>
                                <TextField
                                    required
                                    id="postal-code"
                                    style={{ marginBottom: 0 }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        classes: {
                                            root: classes.resize2,
                                            input: classes.resize,
                                        },
                                        endAdornment: <AddressSearchButton />,
                                    }}
                                    variant="outlined"
                                    placeholder="우편번호"
                                    value={postalCode}
                                    onChange={handlePostalCodeChange}
                                />
                                <TextField
                                    required
                                    id="address"
                                    style={{ marginBottom: 0 }}
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    variant="outlined"
                                    placeholder="기본 주소"
                                    value={address}
                                    onChange={handleAddressChange}
                                />
                                <TextField
                                    required
                                    id="address-detail"
                                    style={{ marginBottom: 32 }}
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    variant="outlined"
                                    placeholder="상세 주소"
                                    value={addressDetail}
                                    onChange={handleAddressDetailChange}
                                />
                            </>
                        )}
                        <Typography className={classes.inputTitle}>
                            사업자등록증 *
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                PDF (최대 2MB)
                            </span>
                        </Typography>
                        <Button
                            id="fileSelect"
                            variant="contained"
                            //size="large"
                            disabled={false}
                            disableElevation
                            style={{
                                boxShadow: "none",
                                height: 56,
                                backgroundColor: "#eeeeee",
                                fontWeight: "bold",
                                marginBottom: 32,
                            }}
                        >
                            {/* <TXImage imgName="icon-camera-black-24" /> */}
                            <Icon>post_add</Icon>

                            {file?.name ?? "파일 등록"}
                        </Button>
                        <input
                            type="file"
                            id="fileElem"
                            //multiple
                            accept="image/*,.pdf"
                            style={{ display: "none" }}
                            onChange={openFiles}
                        />
                        <Typography className={classes.inputTitle}>
                            채용 담당자 (최소 1명) *
                        </Typography>
                        <Typography className={classes.inputNotices}>
                            - 기업 도메인으로 된 이메일로만 가입 가능
                            <br />- 기업당 최대 3명까지 가입 가능
                        </Typography>
                        {users.map((user, idx) => {
                            return (
                                <Box key={idx} className={classes.userBox}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>담당자 정보</Typography>
                                        {idx > 0 && (
                                            <CloseIcon
                                                style={{ color: "#bbbbbb" }}
                                                onClick={() => deleteUser(idx)}
                                            />
                                        )}
                                    </Box>
                                    <TextField
                                        required
                                        id={String(idx)}
                                        name="name"
                                        variant="outlined"
                                        inputProps={{ maxLength: 10 }}
                                        InputProps={{
                                            classes: { input: classes.resize },
                                        }}
                                        placeholder="담당자 이름"
                                        value={users[idx].name}
                                        onChange={handleUsersChange}
                                    />
                                    <TextField
                                        id={String(idx)}
                                        name="email"
                                        variant="outlined"
                                        InputProps={{
                                            classes: { input: classes.resize },
                                        }}
                                        placeholder="사용 이메일"
                                        value={users[idx].email}
                                        onChange={handleUsersChange}
                                    />
                                    <TextField
                                        required
                                        id={String(idx)}
                                        name="phone"
                                        variant="outlined"
                                        inputProps={{ maxLength: 11 }}
                                        InputProps={{
                                            classes: { input: classes.resize },
                                        }}
                                        placeholder="휴대폰번호(-없이 최대 11자리)"
                                        value={users[idx].phone}
                                        onChange={handleUsersChange}
                                    />
                                </Box>
                            );
                        })}

                        <Button
                            variant="contained"
                            //color="primary"
                            onClick={addUser}
                            style={{
                                boxShadow: "none",
                                height: 56,
                                backgroundColor: "#eeeeee",
                                fontWeight: "bold",
                            }}
                            disabled={numOfUsers === 3}
                        >
                            <img src={require("assets/icon-add.png")} />{" "}
                            추가하기
                        </Button>
                        <Divider></Divider>

                        <Typography className={classes.inputTitle}>
                            전체 사원 수 *
                        </Typography>
                        <TextField
                            required
                            id="num-of-employees"
                            inputProps={{ min: "0", maxLength: 5 }}
                            InputProps={{
                                inputComponent: HeadCountFormatCustom,
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="전체 사원 수를 입력해주세요.(단위 : 명)"
                            //label="이름"
                            //type="number"
                            value={numOfEmployees}
                            onChange={handleNumOfEmployeesChange}
                            //helperText="한글로 정확히 입력해 주세요."
                        />
                        <Typography className={classes.inputTitle}>
                            개발팀 인원 *
                        </Typography>
                        <TextField
                            required
                            id="num-of-swengineers"
                            inputProps={{ min: "0" }}
                            InputProps={{
                                inputComponent: HeadCountFormatCustom,
                                classes: { input: classes.resize },
                            }}
                            variant="outlined"
                            placeholder="개발팀 인원을 입력해주세요.(단위 : 명)"
                            //type="number"
                            value={numOfSWEngineers}
                            onChange={handleNumOfSWEngineersChange}
                        />
                        <Typography className={classes.inputTitle}>
                            현재 CTO 또는, 개발팀 리더가 있나요? *
                        </Typography>
                        <FormControl component="fieldset">
                            {/* <FormLabel component="legend">
                                labelPlacement
                            </FormLabel> */}
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={leader}
                                defaultValue="yes"
                                onChange={handleLeaderChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="있다"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="없다"
                                    classes={{
                                        root: classes.imputLabel,
                                        label: classes.resize,
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                        <Divider></Divider>

                        <Typography className={classes.inputTitle}>
                            매출액 *
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                전년도 매출액 (단위 : 원)
                            </span>
                        </Typography>
                        <TextField
                            required
                            id="revenue"
                            name="numberformat"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                classes: { input: classes.resize },
                            }}
                            inputProps={{ maxLength: 21 }}
                            variant="outlined"
                            placeholder="숫자로만 입력해주세요.(단위 : 원)"
                            //type="number"
                            value={revenue}
                            onChange={handleRevenueChange}
                        />
                        <Typography className={classes.inputTitle}>
                            투자액 *
                            <span
                                style={{
                                    fontSize: 11,
                                    color: "#999999",
                                    float: "right",
                                    lineHeight: "24px",
                                    verticalAlign: "middle",
                                }}
                            >
                                누적 투자유치액 (단위 : 원)
                            </span>
                        </Typography>
                        <TextField
                            required
                            id="fund"
                            name="numberformat"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                classes: { input: classes.resize },
                            }}
                            inputProps={{ maxLength: 21 }}
                            variant="outlined"
                            placeholder="숫자로만 입력해주세요.(단위 : 원)"
                            //label="이름"
                            //type="number"
                            value={fund}
                            onChange={handleFundChange}

                            //helperText="한글로 정확히 입력해 주세요."
                        />

                        <Typography className={classes.inputTitle}>
                            저희를 어떤 경로로 알게 되셨나요? *
                        </Typography>
                        <FormControl
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                        >
                            {/* <InputLabel id="demo-simple-select-label">
                                경력년수
                            </InputLabel> */}
                            <Select
                                //labelId="demo-simple-select-label"
                                displayEmpty
                                value={channel}
                                onChange={handleChannelChange}
                                classes={{ root: classes.resize }}
                            >
                                <MenuItem
                                    id="channel"
                                    value={initialChannel}
                                    classes={{ root: classes.resize }}
                                    disabled={!initialChannel}
                                >
                                    {initialChannel || "선택해주세요."}
                                </MenuItem>
                                {companyChannelData.map((item, idx) => (
                                    <MenuItem
                                        id="channel"
                                        key={idx}
                                        value={item}
                                        classes={{ root: classes.resize }}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <FormHelperText>
                                SW개발 경력만 계산해서 선택해 주세요.
                            </FormHelperText> */}
                        </FormControl>
                    </form>

                    <Backdrop className={classes.backdrop} open={isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        className={classes.modalScreen}
                    >
                        <Box className={classes.modalBox}>
                            <Typography className={classes.modalTitle}>
                                신청완료
                            </Typography>
                            <Typography className={classes.modalMessage}>
                                신청해주셔서 감사합니다!
                                <br />
                                담당자 확인 후 가입 조건 충족 시
                                <br />
                                가격 정책을 안내해 드리겠습니다.
                            </Typography>
                            <Typography className={classes.modalInfo}>
                                영업일 기준 1 - 3일 소요됨
                            </Typography>
                            <Button
                                onClick={handleOk}
                                variant="contained"
                                color="primary"
                                className={classes.modalButton}
                            >
                                확인
                            </Button>
                        </Box>
                    </Modal>
                </Box>
            </Container>
            <Divider className={classes.sectionDivider} light></Divider>
            <Container maxWidth="sm">
                <Box className={classes.foot}>
                    <Typography className={classes.consentTitle}>
                        기업 및 개인정보 수집·이용 동의
                    </Typography>
                    <Typography className={classes.consentNotice}>
                        (주)탤런티넘은 서비스 제공을 위한 기업 및 개인정보를
                        수집·이용합니다.
                    </Typography>
                    <Box className={classes.noticeBox}>
                        <Typography className={classes.noticeTitle}>
                            1) 기업 및 개인정보 수집 목적
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            가입 심사 및 확인
                        </Typography>
                        <Typography className={classes.noticeTitle}>
                            2) 기업 및 개인정보 수집 항목
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            기업명, 사업자등록번호, 채용담당자
                            이름/이메일/연락처, 사내인력현황, 매출액, 투자액,
                            가입신청경로
                        </Typography>
                        {/* <Typography className={classes.noticeTitle}>
                            3) 개인정보 보유 및 이용기간
                        </Typography>
                        <Typography className={classes.noticeContent}>
                            가입심사 탈락 또는 회원 탈퇴 시
                        </Typography> */}
                        <Typography className={classes.noticeCaption}>
                            &#8226; 수집 목적이 달성된 이후 개인정보는 즉시
                            파기합니다.
                        </Typography>
                    </Box>

                    <Box
                        className={classes.consentBtn}
                        onClick={() => {
                            setConsent((prev) => !prev);
                        }}
                    >
                        {consent ? (
                            <img
                                src={require("assets/icon-toggle-checkbox-checked-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-checked-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-checked-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        ) : (
                            <img
                                src={require("assets/icon-toggle-checkbox-24.png")}
                                srcSet={`${require("assets/icon-toggle-checkbox-24@2x.png")} 2x, ${require("assets/icon-toggle-checkbox-24@3x.png")} 3x`}
                                alt="consent checkbox"
                            ></img>
                        )}
                        <Typography className={classes.consent}>
                            기업 및 개인정보 수집·이용에 동의합니다
                        </Typography>
                    </Box>
                    <Button
                        id="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!submittable}
                        className={classes.submitButton}
                    >
                        신청하기
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default CompanyContractD;
