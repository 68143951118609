import React, { useContext } from "react";
import firebase from "firebase/app";
import { auth } from "apis/firebase";
import * as firebaseui from "firebaseui";

function Test() {
    const ui = new firebaseui.auth.AuthUI(auth);

    var uiConfig = {
        signInSuccessUrl: "http://localhost:3000/terms-of-use",
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // firebase.auth.GithubAuthProvider.PROVIDER_ID,
            // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: "https://www.talentinum.com/terms-of-use",
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
            window.location.assign("https://www.talentinum.com/terms-of-use");
        },
    };

    ui.start("#firebase-auth", uiConfig);

    return <div id="firebase-auth"></div>;
}

export default Test;
