import React, { useEffect } from "react";
import { Box, Typography, Container, Paper } from "@material-ui/core";
import { TalentServiceHeaderD, ServiceFooterD } from "pages/components";
import { makeStyles } from "@material-ui/core/styles";

import TalentContentD from "./TalentContentD3";
import { Colors } from "../../styles";

import "date-fns";
import { Block } from "@material-ui/icons";
import PopupD from "./PopupD";

const content = {
	mission: "기업에게 직접\n영입 제안을 받는\n정말 편한 개발자 이직",
};

const useStyles = makeStyles((theme) => ({
	wallpaper: {
		marginTop: 56,
		height: "800px",
		background: "linear-gradient(#363741,#101010)",
	},
	topContainer: {
		width: "1000px",
		height: "100%",
		paddingTop: "100px",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
	},
	mainImg: {
		width: "50%",
		height: 400,
		position: "relative",
		backgroundImage: `url(${require("assets/feature-incoming-offer@3x.png")})`,
		backgroundImage: `image-set(url(${require("assets/feature-incoming-offer.png")}) 1x, url(${require("assets/feature-incoming-offer@2x.png")}) 2x, url(${require("assets/feature-incoming-offer@3x.png")}) 3x)`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom",
		backgroundSize: "cover",
	},
	mainTitle: {
		color: "#ffffff",
		fontSize: 48,
		fontWeight: "bold",
		lineHeight: 1.3,
		whiteSpace: "pre-wrap",
		marginTop: 12,
	},
	body: {
		backgroundColor: theme.palette.common.white,
		textAlign: "center",
		width: "100%",
	},
	phantom: {
		backgroudColor: "#2c2c2c",
		width: "100%",
		height: 50,
	},
}));

const TalentXD3 = () => {
	const classes = useStyles();
	const query = new URLSearchParams(window.location.search);
	// const [userType, setUserType] = React.useState(
	//     query.get("type") === "talent" ? 1 : 0
	// );

	// const handleUserTypeChange = (event, newUserType) => {
	//     setUserType(newUserType);
	// };

	useEffect(() => window.scrollTo({ top: 0 }), []);

	return (
		<React.Fragment>
			<TalentServiceHeaderD />
			<Box className={classes.wallpaper}>
				<Container maxWidth="lg" className={classes.topContainer}>
					<Typography className={classes.mainTitle}>
						{content.mission}
					</Typography>
					<Box className={classes.mainImg}></Box>
				</Container>
			</Box>
			{/* <PopupD /> */}
			<Box className={classes.body}>
				<TalentContentD />
			</Box>
			<ServiceFooterD backgroudColor={"#2c2c2c"} />
			{/* <Box className={classes.phantom}></Box> */}
		</React.Fragment>
	);
};

export default TalentXD3;
