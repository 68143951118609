import { firestore } from "apis/firebase";

export const onDeleteSelectedApplicants = (selected, onSelect) => {
    const selectedTalentIds = selected;

    selectedTalentIds.forEach((selectedTalentId) =>
        firestore
            .collection("applicants")
            .doc(selectedTalentId)
            .delete()
            .then(function (docRef) {
                //deleteSelectedState(null, selectedTalentId);

                onSelect([]); //Delete selected rows state value after the real data row is deleted in firestore
                console.log(selected);
                console.log(
                    "Document [" + selectedTalentId + "] successfully deleted."
                );
            })
            .catch(function (error) {
                console.log("Error deleting document: ", error);
            })
    );

    //console.log("last state: " + selected);
};
