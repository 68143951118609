import { makeStyles } from "@material-ui/core/styles";
import { Texts } from "styles";

export const useStyles = makeStyles((theme) => ({
    featureSection: {
        backgroundColor: "#ffffff",
        height: 800,
    },
    featureContainer: {
        width: 1000,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        whiteSpace: "pre-wrap",
    },
    featureCoreMsg: {
        ...Texts.basic(48, "bold", "#222222", 1.33),
        marginBottom: 80,
    },
    featureBoxes: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    featureBox: { textAlign: "center", flex: 1 },
    featureTitle: {
        ...Texts.basic(20, "bold", "#444444"),
        marginTop: 32,
        marginBottom: 4,
    },
    featureDesc: {
        ...Texts.basic(14, 300, "#999999"),
        whiteSpace: "pre-wrap",
    },
    benefitSection: {
        backgroundColor: "#3c73ff",
        textAlign: "center",
        paddingTop: 120,
    },
    benefitKeyword: {
        display: "inline-block",
        backgroundColor: "#ffffff",
        color: "#3c73ff",
        padding: "8px 16px",
        borderRadius: 24,
        marginBottom: 32,
    },
    benefitTitle: {
        ...Texts.basic(48, "bold", "#ffffff", 1.33),
        whiteSpace: "pre-wrap",
    },
    benefitDesc: {
        ...Texts.basic(16, 500, "#ffffff"),
        marginTop: 24,
        marginBottom: 43,
        whiteSpace: "pre-wrap",
    },
    benefitImg: { display: "block", marginLeft: "auto", marginRight: "auto" },
    offerSection: {
        backgroundColor: "#151515",
        textAlign: "center",
        paddingTop: 120,
    },
    offerSectionTitle: {
        ...Texts.basic(48, "bold", "#ffffff", 1.33),
        whiteSpace: "pre-wrap",
    },
    offerSectionDesc: {
        ...Texts.basic(16, 500, "#ffffff"),
        marginTop: 24,
        marginBottom: 105,
        whiteSpace: "pre-wrap",
    },
    offerSectionImg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },

    timeBenefitSection: {
        backgroundColor: "#ffffff",
        textAlign: "center",
        paddingTop: 120,
        paddingBottom: 100,
    },
    timeBenefitKeyword: {
        display: "inline-block",
        backgroundColor: "#3c73ff",
        color: "#ffffff",
        padding: "8px 16px",
        borderRadius: 24,
        marginBottom: 32,
    },
    timeBenefitTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.33),
        marginBottom: 24,
        whiteSpace: "pre-wrap",
    },
    timeBenefitDesc: {
        display: "inline-block",
        margin: 6,
        width: 350,
        ...Texts.basic(20, 500, "#666666"),
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: "#f8f8f8",
        boxShadow: "inset 0 2px 8px 0 rgba(125, 125, 125, 0.24)",
        borderRadius: 8,
    },
    timeBenefitLongDesc: {
        display: "inline-block",
        margin: 6,
        width: 712,
        ...Texts.basic(20, 500, "#666666"),
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: "#f8f8f8",
        boxShadow: "inset 0 2px 8px 0 rgba(125, 125, 125, 0.24)",
        borderRadius: 8,
    },
    timeBenefitCaption: {
        ...Texts.basic(16, 300, "#999999"),
    },
    timeBenefitImgIconWrap: {
        position: "relative",
    },
    timeBenefitImg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    timeBenefitIcon: {
        position: "absolute",
        left: "55%",
        top: "60%",
        animation: "$motion 0.7s ease-in-out 0.3s infinite alternate",
        marginTop: 0,
    },
    "@keyframes motion": {
        "0%": {
            marginTop: 0,
        },
        "100%": {
            marginTop: 20,
        },
    },
    effectiveness: {
        marginTop: 64,
        marginBottom: 8,
        ...Texts.basic(14, 500, "#444444"),
    },
    matchFunctionSection: {
        height: 960,
        backgroundColor: "#f8f8f8",
    },
    functionContainer: {
        width: 1000,
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
    },
    matchFunctionBox: {},
    matchFunctionSlogan: {
        marginBottom: 64,
        ...Texts.basic(48, "bold", "#222222", 1.33),
        whiteSpace: "pre-wrap",
    },
    matchFunctionSubtitle: {
        ...Texts.basic(24, "bold", "#444444"),
    },
    matchFunctionBtn: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#0c46f2",
        borderRadius: 26.5,
        ...Texts.basic(20, "bold", "#ffffff"),
    },
    matchFunctionDesc: {
        marginTop: 12,
        ...Texts.basic(16, 300, "#444444"),
        whiteSpace: "pre-wrap",
    },
    interviewFunctionSection: {
        height: 960,
        backgroundColor: "#051C60",
    },
    interviewFunctionSlogan: {
        marginBottom: 64,
        ...Texts.basic(48, "bold", "#ffffff", 1.33),
        whiteSpace: "pre-wrap",
    },
    interviewFunctionBtn: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#ffffff",
        borderRadius: 26.5,
        ...Texts.basic(20, "bold", "#074396"),
    },
    interviewFunctionBtnUnClicked: {
        display: "inline-block",
        padding: "12px 16px",
        marginRight: 8,
        backgroundColor: "#003b8f",
        borderRadius: 26.5,
        ...Texts.basic(20, "bold", "#8da4c3"),
    },
    interviewFunctionDesc: {
        marginTop: 32,
        ...Texts.basic(16, 300, "#ffffff"),
    },
    reviewSection: {
        backgroundColor: "#f8f8f8",
        textAlign: "left",
        paddingTop: 140,
        paddingBottom: 140,
        overflowX: "hidden",
    },
    reviewSectionContainer: {
        position: "relative",
        overflowX: "visible",
    },
    reviewTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.33),
        whiteSpace: "pre-wrap",
        marginBottom: 64,
    },
    reviewCardWindow: {
        display: "flex",
        position: "relative",
        marginRight: -1100,
        paddingRight: 1780,
        overflowX: "auto",
        overflowY: "hidden",
        flexDirection: "row",
        marginBottom: 24,
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    reviewCard: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
        borderRadius: 20,
        boxShadow: "0 2px 16px 0 #eee",
        width: 529,
        height: 330,
        padding: 32,
        marginRight: 20,
    },
    reviewRatingWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    reviewRating: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    reviewRatingDesc: {
        marginLeft: 12,
        ...Texts.basic(16, 500, "#ababab", 1.38),
    },
    reviewContent: {
        flexGrow: 1,
        ...Texts.basic(20, 500, "#454545", 1.6),
    },
    reviewClientLogo: {
        height: 30,
    },
    reviewClient: {
        marginTop: 12,
        ...Texts.basic(16, 500, "#929292", 1.38),
    },
    reviewCardWindowScroll: {},
    reviewCardWindowScroller: {
        display: "inline-flex",
        backgroundColor: "#ffffff",
        borderRadius: 28,
        boxShadow: "0 2px 16px 0 #eee",
        width: 56,
        height: 56,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 16,
    },
    managerSection: {
        backgroundColor: "#f8f8f8",
        textAlign: "center",
        paddingTop: 120,
    },
    managerKeyword: {
        display: "inline-block",
        backgroundColor: "#ffffff",
        color: "#0c46f2",
        border: "solid 2px #0c46f2",
        padding: "8px 16px",
        borderRadius: 24,
        marginBottom: 41,
    },
    managerTitle: {
        ...Texts.basic(48, "bold", "#222222", 1.3),
        whiteSpace: "pre-wrap",
    },
    managerDesc: {
        ...Texts.basic(16, 500, "#ffffff"),
        marginTop: 24,
        marginBottom: 43,
    },
    managerImg: { display: "block", marginLeft: "auto", marginRight: "auto" },
    progressBar: {
        position: "relative",
        zIndex: 0,
        display: "inline-block",
        textAlign: "center",
        width: 420,
        marginTop: 52,
    },
    line: {
        position: "absolute",
        top: "50%",
        left: "10%",
        width: "80%",
        borderBottom: "2px solid #1a6bff",
        zIndex: 1,
    },
    progress: {
        position: "relative",
        display: "inline-block",
        padding: "20px 22px",
        borderRadius: 45,
        border: "2px solid #1a6bff",
        ...Texts.basic(20, "bold", "#444444", 0.8),
        margin: theme.spacing(2, 5),
        zIndex: 2,
        backgroundColor: "#ffffff",
    },
    progressLabel: {
        display: "inline-block",
        ...Texts.basic(16, 500, "#444444", 1.25),
        width: 120,
        margin: theme.spacing(0, 1.5, 7),
        whiteSpace: "pre-wrap",
    },
    qnaSection: {
        backgroundColor: "#f8f8f8",
        paddingTop: 80,
        paddingBottom: 80,
        textAlign: "center",
    },
    qnaSectionTitle: {
        ...Texts.basic(32, "bold", "#222222"),
        marginBottom: 52,
    },
    qnaBox: {
        marginLeft: 24,
        marginRight: 24,
        textAlign: "left",
        marginBottom: 16,
    },
    questionBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 16,
    },
    question: {
        ...Texts.basic(16, "bold", "#222222"),
        marginLeft: 4,
        flexGrow: 1,
    },
    answerBox: {
        backgroundColor: "#eeeeee",
        justifyContent: "flex-start",
        padding: 16,
    },
    answer: {
        ...Texts.basic(14, 300, "#444444"),
        whiteSpace: "pre-wrap",
    },
    answerBoxClosed: {
        display: "none",
    },
    divider: {
        width: "100%",
    },
    fixedButtonRouteLink: {
        textDecoration: "none",
    },
    fixedButton: {
        backgroundColor: "#207cff",
        textAlign: "center",
        position: "fixed",
        zIndex: 1,
        bottom: 0,
        width: "100%",
        height: 60,
        paddingTop: 12,
        paddingBottom: 12,
        boxShadow: "0px 0px 10px 1px #00000040",
    },
    fixedButtonName: {
        ...Texts.basic(14, "bold", "#ffffff", 1.43),
    },
    fixedButtonDesc: {
        ...Texts.basic(10, 500, "#ffffff", 1.43),
    },
    phantom: {
        width: "100%",
        height: 50,
    },
}));
