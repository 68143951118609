import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
	Container,
	Grid,
	Box,
	Typography,
	Link,
	Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { createKakaoShareButton } from "apis/kakao/share";
import * as config from "config";
import { TXImage } from "components/tx";

const content = {
	logo: "logo-talentinum",
	companyName: "주식회사 탤런티넘",
	companyEngName: "talentinum Inc.",
	businessNumber: "사업자등록번호: 734-81-01533",
	executives: "대표 : 한신환 | 개인정보관리자 : 오성원",
	address: "경기도 성남시 분당구 느티로 22",
	website: "https://talentinum.com/",
	licenses: [
		{
			name: "유료직업소개사업등록번호",
			number: "제2022-3780288-14-5-00018호",
		},
		{ name: "통신판매신고번호", number: "제2020-성남분당A-0820호" },
	],
	links: [
		{ name: "서비스 이용약관", url: "/terms-of-use" },
		{ name: "개인정보 처리방침", url: "/privacy-policy" },
		{ name: "문의하기", url: "https://talentx.channel.io/" },
	],
};

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		flexGrow: 1,
		overflow: "hidden",
		padding: theme.spacing(3, 0),
		backgroundColor: "#eeeeee",
		color: "#bbbbbb",
	}),
	container: (props) => ({
		flexGrow: 1,
		overflow: "hidden",
		padding: theme.spacing(3, 3),
		backgroundColor: props.backgroudColor || "#eeeeee",
		color: "#bbbbbb",
	}),
	logo: { marginTop: 12, marginBottom: 32 },
	company: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#999999",
	},
	item: {
		fontSize: 13,
		fontWeight: 500,
		color: "#999999",
	},
	info: {
		fontSize: 13,
		fontWeight: 500,
		color: "#bbbbbb",
	},
}));

function ServiceFooter(props) {
	const classes = useStyles(props);

	// useEffect(() => {
	//     createKakaoShareButton();
	// }, []);

	return (
		<Container
			maxWidth={props.maxWidth ?? "xs"}
			className={classes.container}
		>
			<TXImage imgName={content.logo} className={classes.logo} />

			<Grid container spacing={1}>
				<Grid item xs={12} sm={6}>
					<RouterLink
						to="/corporation"
						style={{
							textDecoration: "none",
							color: "inherit",
						}}
					>
						<Typography className={classes.company} gutterBottom>
							{content.companyName}
						</Typography>
					</RouterLink>
					<Typography className={classes.info} gutterBottom>
						{content.businessNumber}
						<br />
						{content.executives}
						<br />
						{content.address}
					</Typography>
					<br />
					<Typography className={classes.item}>
						유료직업소개사업등록번호
					</Typography>
					<Typography className={classes.info}>
						제2019-3780288-14-5-00023호
					</Typography>
					<br />

					<Typography className={classes.item}>
						통신판매신고번호
					</Typography>
					<Typography className={classes.info} gutterBottom>
						제2020-성남분당A-0820호
					</Typography>
					<br />
					<Typography className={classes.item}>
						<RouterLink
							to="/terms-of-use"
							style={{
								textDecoration: "none",
								color: "inherit",
							}}
						>
							서비스 이용약관
						</RouterLink>
					</Typography>
					<br />
					<Typography className={classes.item}>
						<RouterLink
							to="/privacy-policy"
							style={{
								textDecoration: "none",
								color: "inherit",
							}}
						>
							개인정보처리방침
						</RouterLink>
					</Typography>
					<br />

					<Link
						href="https://talentx.channel.io/"
						color="inherit"
						target="_blank"
					>
						<Typography className={classes.item}>
							문의하기
						</Typography>
					</Link>
					{/* <br />
                    <br /> */}

					{/* <Box
                        id="kakaoShareBtn"
                        style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#ffd646",
                            borderRadius: 16,
                            padding: "6px 12px",
                            //width: 109,
                            //height: 32,
                        }}
                    >
                        <img
                            src={require("assets/icon-kakao.png")}
                            style={{ width: 16, height: 16, marginRight: 4 }}
                        />
                        <Typography
                            style={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: "#222222",
                            }}
                        >
                            카카오톡 공유
                        </Typography>
                    </Box> */}
				</Grid>
			</Grid>
			<br />
			<br />
			<br />
			<Typography variant="body2" color="inherit">
				{"Copyright © "}
				<Link color="inherit" href="https://talentinum.com/">
					talentinum Inc.
				</Link>{" "}
				{new Date().getFullYear()}
				{"."}
			</Typography>
		</Container>
	);
}

export default ServiceFooter;
