import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Container, Divider, Grid } from "@material-ui/core";
import clsx from "clsx";

import { companyData as content } from "./dataTalentX3";
import { useStyles } from "./styleCompanyContentD3";

const CompanyContentD = () => {
	const reviewWindowRef = useRef();
	const classes = useStyles();

	const [slide, setSlide] = useState({
		recommendation: 0,
		interview: 0,
	});
	const handleSlideSelect = (type, value) => (event) => {
		setSlide({ ...slide, [type]: value });
	};

	const [open, setOpen] = useState(new Map());
	const handleAnswerOpen = (key, value) => (event) => {
		setOpen({ ...open, [key]: value });
	};

	function TXImage(props) {
		const { imgName, ...rest } = props;
		return (
			<img
				src={require(`assets/${imgName}.png`)}
				srcSet={`${require(`assets/${imgName}@2x.png`)} 2x, ${require(`assets/${imgName}@3x.png`)} 3x`}
				alt={imgName}
				{...rest}
			></img>
		);
	}

	const handleScrollLeft = () => {
		reviewWindowRef.current.scrollLeft += 550;
	};

	const handleScrollRight = () => {
		reviewWindowRef.current.scrollLeft += -550;
	};

	return (
		<React.Fragment>
			<Box className={classes.featureSection}>
				<Container maxWidth="lg" className={classes.featureContainer}>
					<Typography className={classes.featureCoreMsg}>
						{content.proposition}
					</Typography>
					<Box className={classes.featureBoxes}>
						{content.values.map((value, idx) => (
							<Box key={idx} className={classes.featureBox}>
								<img
									src={value.image}
									srcSet={`${value.image2x} 2x, ${value.image3x} 3x`}
									alt="talent pool"
								></img>
								<Typography className={classes.featureTitle}>
									{value.title}
								</Typography>
								<Typography className={classes.featureDesc}>
									{value.desc}
								</Typography>
							</Box>
						))}
					</Box>
				</Container>
			</Box>
			<Box className={classes.statSection}>
				<Container maxWidth="lg" className={classes.statContainer}>
					<Box className={classes.statWrap}>
						<Box className={classes.statTitleWrap}>
							<TXImage imgName={"icon-person-blue"} />
							<Typography className={classes.statTitle}>
								{content.stat.title}
							</Typography>
						</Box>
						<Box className={classes.statDesc}>
							{content.stat.desc}
						</Box>
						<Box className={classes.statValueWrap}>
							<Box>
								<Typography className={classes.statKey}>
									전체 개발자풀
								</Typography>
								<Typography className={classes.statValue}>
									{content.stat.total}
								</Typography>
							</Box>
							<Box>
								<Typography className={classes.statKey}>
									경력 개발자
								</Typography>
								<Typography className={classes.statValue}>
									{content.stat.experienced}
								</Typography>
							</Box>
						</Box>
					</Box>
					<Box>
						<TXImage
							imgName={"img-talent-profile-cards"}
							className={classes.statImg}
						/>
					</Box>
				</Container>
			</Box>
			<Box className={classes.clientsSection}>
				<Container maxWidth="lg">
					<Box className={classes.clientsTitle}>
						{content.clients.title}
					</Box>
					<Box className={classes.clientsDesc}>
						{content.clients.desc}
					</Box>
					<Box className={classes.clientsLogos}>
						<Grid container spacing={1}>
							{content.clients.logos.map((logo, idx) => (
								<Grid
									item
									xs={6}
									md={4}
									className={classes.clientLogoGridItem}
								>
									<img
										key={idx}
										src={logo}
										className={classes.clientLogo}
										alt="client logo"
									></img>
								</Grid>
							))}
						</Grid>
					</Box>
				</Container>
			</Box>
			<Box className={classes.reviewSection}>
				<Container
					maxWidth="lg"
					className={classes.reviewSectionContainer}
				>
					<Typography className={classes.reviewTitle}>
						{content.clientReview.title}
					</Typography>
					<Box
						ref={reviewWindowRef}
						className={classes.reviewCardWindow}
					>
						{content.clientReview.reviews.map((review, idx) => (
							<Box key={idx} className={classes.reviewCard}>
								<Box className={classes.reviewRating}>
									{Array.apply(
										null,
										Array(review.rating)
									).map((star, idx) => (
										<TXImage
											key={idx}
											imgName="icon-star-24"
										/>
									))}
									{Array.apply(
										null,
										Array(5 - review.rating)
									).map((star, idx) => (
										<TXImage
											key={idx}
											imgName="icon-unstar-24"
										/>
									))}
									<span className={classes.reviewRatingDesc}>
										{review.ratingDesc}
									</span>
								</Box>
								<Typography className={classes.reviewContent}>
									{review.content}
								</Typography>
								<TXImage
									imgName={review.clientLogo}
									className={classes.reviewClientLogo}
								/>
								<Typography className={classes.reviewClient}>
									{review.client}
								</Typography>
							</Box>
						))}
					</Box>
					<Box className={classes.reviewCardWindowScroll}>
						<Box
							className={classes.reviewCardWindowScroller}
							onClick={handleScrollRight}
						>
							<TXImage imgName={"icon-arrow-left-scroll"} />
						</Box>
						<Box
							className={classes.reviewCardWindowScroller}
							onClick={handleScrollLeft}
						>
							<TXImage imgName={"icon-arrow-right-scroll"} />
						</Box>
					</Box>
				</Container>
			</Box>
			<Box className={classes.hiringManagerSection}>
				<Container
					maxWidth="lg"
					className={classes.hiringManagerContainer}
				>
					<Box className={classes.hiringManagerTitle}>
						{content.hiringManager.title}
					</Box>
				</Container>
			</Box>
			<Box className={classes.simpleSection}>
				<Container maxWidth="lg" className={classes.simpleContainer}>
					<Box className={classes.simpleTitleWrapper}>
						<TXImage imgName={content.simple.icon} />
						<Typography className={classes.simpleTitle}>
							{content.simple.title}
						</Typography>
					</Box>
					<Typography className={classes.simpleDesc}>
						{content.simple.desc}
					</Typography>
					<Box className={classes.simpleContentTopWrapper}>
						<TXImage imgName={content.simple.content[0].image} />
						<Typography className={classes.simpleContentTopDesc}>
							{content.simple.content[0].desc}
						</Typography>
					</Box>
					<Box className={classes.simpleContentBottomWrapper}>
						<Box className={classes.simpleContentBottomLeftWrapper}>
							<Typography
								className={classes.simpleContentBottomLeftDesc}
							>
								{content.simple.content[1].desc}
							</Typography>
							<Typography
								className={classes.simpleContentBottomLeftMore}
							>
								{content.simple.content[1].more}
							</Typography>
							<TXImage
								imgName={content.simple.content[1].image}
							/>
						</Box>
						<Box
							className={classes.simpleContentBottomRightWrapper}
						>
							<Typography
								className={classes.simpleContentBottomRightDesc}
							>
								{content.simple.content[2].desc}
							</Typography>
							<TXImage
								className={classes.simpleContentBottomRightImg}
								imgName={content.simple.content[2].image}
							/>
						</Box>
					</Box>
				</Container>
			</Box>

			<Box className={classes.profileSection}>
				<Container maxWidth="lg" className={classes.profileContainer}>
					<Box className={classes.profileTitleWrapper}>
						<TXImage imgName={content.profile.icon} />
						<Typography className={classes.profileTitle}>
							{content.profile.title}
						</Typography>
					</Box>
					<Box className={classes.profileContentWrapper}>
						<Box className={classes.profileContentLeftWrapper}>
							<Typography className={classes.profileDesc}>
								{content.profile.desc}
							</Typography>
							<TXImage
								className={classes.profileContentLeftImg}
								imgName={content.profile.content[0].image}
							/>
						</Box>
						<Box className={classes.profileContentRightWrapper}>
							<TXImage
								className={classes.profileContentRightImg}
								imgName={content.profile.content[1].image}
							/>
							<TXImage
								className={classes.profileContentRightIcon}
								imgName={content.profile.content[1].icon}
							/>
							<Typography
								className={classes.profileContentRightDesc}
							>
								{content.profile.content[1].desc}
							</Typography>
						</Box>
					</Box>
				</Container>
			</Box>

			<Box className={classes.supportSection}>
				<Container maxWidth="lg" className={classes.supportContainer}>
					<Box className={classes.supportTitleWrapper}>
						<TXImage imgName={content.support.icon} />
						<Typography className={classes.supportTitle}>
							{content.support.title}
						</Typography>
					</Box>
					<Typography className={classes.supportDesc}>
						{content.support.desc}
					</Typography>
					<Typography className={classes.supportKeyword}>
						{content.support.keyword}
					</Typography>

					<Box className={classes.supportContentTopWrapper}>
						<Typography className={classes.supportContentTopDesc}>
							{content.support.content[0].desc}
						</Typography>
						<TXImage
							className={classes.supportContentTopImg}
							imgName={content.support.content[0].image}
						/>
					</Box>
					<Box className={classes.supportContentMiddleWrapper}>
						<Box
							className={classes.supportContentMiddleLeftWrapper}
						>
							<TXImage
								className={classes.supportContentMiddleLeftImg}
								imgName={content.support.content[1].image}
							/>
							<Typography
								className={classes.supportContentMiddleLeftDesc}
							>
								{content.support.content[1].desc}
							</Typography>
						</Box>
						<Box
							className={classes.supportContentMiddleRightWrapper}
						>
							<Box
								className={
									classes.supportContentMiddleRightChatLeftWrapper
								}
							>
								<TXImage
									className={
										classes.supportContentMiddleRightImg
									}
									imgName={content.support.content[2].image}
								/>
								<Box
									className={
										classes.supportContentMiddleRightChatLeftTextWrapper
									}
								>
									{content.support.content[2].chat1}
								</Box>
							</Box>
							<Box
								className={
									classes.supportContentMiddleRightChatRightWrapper
								}
							>
								<Box
									className={
										classes.supportContentMiddleRightChatRightTextWrapper
									}
								>
									{content.support.content[2].chat2}
								</Box>
							</Box>
							<Box
								className={
									classes.supportContentMiddleRightChatLeftWrapper
								}
							>
								<TXImage
									className={
										classes.supportContentMiddleRightImg
									}
									imgName={content.support.content[2].image}
								/>
								<Box
									className={
										classes.supportContentMiddleRightChatLeftTextWrapper
									}
								>
									{content.support.content[2].chat3}
								</Box>
							</Box>
						</Box>
					</Box>
					<Box className={classes.supportContentBottomWrapper}>
						<Box
							className={classes.supportContentBottomLeftWrapper}
						>
							<Typography
								className={classes.supportContentBottomTitle}
							>
								{content.support.content[3].title}
							</Typography>
							<Typography
								className={classes.supportContentBottomDesc}
							>
								{content.support.content[3].desc}
							</Typography>
							<TXImage
								className={classes.supportContentBottomImg}
								imgName={content.support.content[3].image}
							/>
						</Box>
						<Box
							className={classes.supportContentBottomRightWrapper}
						>
							<Typography
								className={classes.supportContentBottomTitle}
							>
								{content.support.content[4].title}
							</Typography>
							<Typography
								className={classes.supportContentBottomDesc}
							>
								{content.support.content[4].desc}
							</Typography>
							<TXImage
								className={classes.supportContentBottomImg}
								imgName={content.support.content[4].image}
							/>
						</Box>
					</Box>
				</Container>
			</Box>

			<Box className={classes.brandingSection}>
				<Container maxWidth="lg" className={classes.brandingContainer}>
					<Box className={classes.brandingTitleWrapper}>
						<Typography className={classes.brandingTitle}>
							{content.branding.title}
						</Typography>
					</Box>
					<Box className={classes.brandingDesc}>
						{content.branding.desc}
					</Box>
					<Box className={classes.brandingContentTopWrapper}>
						<Box className={classes.brandingContentTopLeftWrapper}>
							<Typography
								className={classes.brandingContentTopLeftTitle}
							>
								{content.branding.content[0].title}
							</Typography>
							<Typography
								className={classes.brandingContentTopLeftDesc}
							>
								{content.branding.content[0].desc}
							</Typography>
						</Box>
						<TXImage imgName={content.branding.content[0].image} />
					</Box>
					<Box className={classes.brandingContentBottomWrapper}>
						<Box
							className={classes.brandingContentBottomLeftWrapper}
						>
							<Typography
								className={
									classes.brandingContentBottomLeftTitle
								}
							>
								{content.branding.content[1].title}
							</Typography>
							<TXImage
								className={classes.brandingContentBottomLeftImg}
								imgName={content.branding.content[1].image}
							/>
						</Box>
						<Box
							className={
								classes.brandingContentBottomRightWrapper
							}
						>
							<TXImage
								className={
									classes.brandingContentBottomRightImg
								}
								imgName={content.branding.content[2].image}
							/>
						</Box>
					</Box>
				</Container>
			</Box>
			<Box className={classes.openscoutSection}>
				<Container maxWidth="lg" className={classes.openscoutContainer}>
					<Box className={classes.openscoutTitleWrapper}>
						<Typography className={classes.openscoutTitle}>
							{content.openscout.title}
						</Typography>
					</Box>
					<Box className={classes.openscoutDesc}>
						{content.openscout.desc}
					</Box>
					<Box className={classes.openscoutContentTopWrapper}>
						<Typography
							className={classes.openscoutContentTopTitle}
						>
							{content.openscout.content[0].title}
						</Typography>
						<Box className={classes.openscoutContentTopImgWarpper}>
							<TXImage
								className={classes.openscoutContentTopImg}
								imgName={content.openscout.content[0].image}
							/>
						</Box>
					</Box>
					<Box className={classes.openscoutContentMiddleTitleWrapper}>
						<TXImage imgName={content.openscout.content[1].image} />
						<Typography
							className={classes.openscoutContentMiddleTitle}
						>
							{content.openscout.content[1].title}
						</Typography>
					</Box>
					<Typography className={classes.openscoutContentMiddleDesc}>
						{content.openscout.content[1].desc}
					</Typography>
					<Box className={classes.openscoutContentBottomWrapper}>
						<Box
							className={
								classes.openscoutContentBottomLeftWrapper
							}
						>
							<TXImage
								imgName={content.openscout.content[2].image}
							/>
							<Typography
								className={classes.openscoutContentBottomTitle}
							>
								{content.openscout.content[2].title}
							</Typography>
							<Typography
								className={classes.openscoutContentBottomDesc}
							>
								{content.openscout.content[2].desc}
							</Typography>
						</Box>
						<Box
							className={
								classes.openscoutContentBottomMiddleWrapper
							}
						>
							<TXImage
								imgName={content.openscout.content[3].image}
							/>
							<Typography
								className={classes.openscoutContentBottomTitle}
							>
								{content.openscout.content[3].title}
							</Typography>
							<Typography
								className={classes.openscoutContentBottomDesc}
							>
								{content.openscout.content[3].desc}
							</Typography>
						</Box>
						<Box
							className={
								classes.openscoutContentBottomRightWrapper
							}
						>
							<TXImage
								imgName={content.openscout.content[4].image}
							/>
							<Typography
								className={classes.openscoutContentBottomTitle}
							>
								{content.openscout.content[4].title}
							</Typography>
							<Typography
								className={classes.openscoutContentBottomDesc}
							>
								{content.openscout.content[4].desc}
							</Typography>
						</Box>
					</Box>
				</Container>
			</Box>
			<Box className={classes.pricingSection}>
				<Container maxWidth="lg" className={classes.pricingContainer}>
					<Typography className={classes.pricingTitle}>
						{content.pricing.title}
					</Typography>
					<Typography className={classes.pricingSubTitle}>
						{content.pricing.subtitle}
					</Typography>
					<Box className={classes.pricingContentWrapper}>
						<Box className={classes.pricingContentLeftWrapper}>
							<Typography
								className={classes.pricingContentLeftTitle}
							>
								{content.pricing.plans[0].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[0].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[0].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentLeftPrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[0].services.map(
								(service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								)
							)}
						</Box>
						<Box className={classes.pricingContentMiddleWrapper}>
							<Typography
								className={classes.pricingContentMiddleTitle}
							>
								{content.pricing.plans[1].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[1].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[1].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentMiddlePrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[1].services
								.slice(0, 6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[1].services
								.slice(6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-light-blue"}
										/>
										<Typography
											className={
												classes.pricingContentMiddleService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
						</Box>
						<Box className={classes.pricingContentRightWrapper}>
							<Typography
								className={classes.pricingContentRightTitle}
							>
								{content.pricing.plans[2].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[2].desc}
							</Typography>

							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[2].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentRightPrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[2].services
								.slice(0, 6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[2].services
								.slice(6, 7)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-light-blue"}
										/>
										<Typography
											className={
												classes.pricingContentMiddleService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[2].services
								.slice(7)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-blue"}
										/>
										<Typography
											className={
												classes.pricingContentRightService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
						</Box>
					</Box>
				</Container>
			</Box>
			{/* <RouterLink
                to={content.actionBtn.url}
                className={classes.fixedButtonRouteLink}
            >
                <Box className={classes.fixedButton}>
                    <Typography className={classes.fixedButtonName}>
                        {content.actionBtn.title}
                    </Typography>
                    <Typography className={classes.fixedButtonDesc}>
                        {content.actionBtn.desc}
                    </Typography>
                </Box>
            </RouterLink> */}
		</React.Fragment>
	);
};

export default CompanyContentD;
