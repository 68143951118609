import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { GIWhite, MainImage } from "data";
import parkImage from "assets/park.png";
import parkImageMobile from "assets/parkMobile.png";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6, 0),
        backgroundImage: `url(${parkImage})`,
        backgroundSize: "100% 100%",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${parkImageMobile})`,
        },

        color: "white",
    },
    gridItemImage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            minHeight: "100px",
            justifyContent: "flex-start",
            alignItems: "flex-end",
        },
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            // padding: "0 calc((100% - 296px) / 2)",
            padding: "0 32px",
        },
    },
}));

export default function RecruitemntOKR(params) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            우리의 목표
                        </Typography>
                        <br></br>
                        <Typography variant="h5" gutterBottom>
                            놀이공원에 갈 때처럼
                            <br />
                            당신의 출근길도 행복하기를
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            정말 나와 딱 맞는 회사를 찾는 건 어쩌면 불가능에
                            가까울 수도 있습니다. {isMobile || <br />}
                            저희가 연결해준 곳이 당신에게 100% 만족스럽지 않을
                            수도 있어요. {isMobile || <br />}하지만, 저희는
                            적어도 당신의 출근길이 행복해졌으면 좋겠습니다.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridItemImage}>
                        <GIWhite />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
