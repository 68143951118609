import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Container, Grid } from "@material-ui/core";
import clsx from "clsx";
import { getTalentNum } from "../../apis/firestore/fetch";

import "date-fns";

import { companyDataM as content } from "./dataTalentX3";
import { useStyles } from "./styleCompanyContent3";
import { TXImage } from "../../components/tx";

const CompanyContent = () => {
	const classes = useStyles();

	const [feature, setFeature] = React.useState(0);

	const handleFeatureChange = (event, newFeature) => {
		setFeature(newFeature);
	};

	const [step, setStep] = React.useState(0);

	const handleStepChange = (event, newStep) => {
		setStep(newStep);
	};

	const [open, setOpen] = useState(new Map());

	const handleAnswerOpen = (key, value) => (event) => {
		setOpen({ ...open, [key]: value });
	};

	const [totalTalentNum, setTotalTalentNum] = useState(1328);
	const [expTalentNum, setExpTalentNum] = useState(914);

	useEffect(() => {
		(async () => {
			const tmpTalentNum = await getTalentNum();
			if (tmpTalentNum != null) {
				setTotalTalentNum(tmpTalentNum.totalTalentNum);
				setExpTalentNum(tmpTalentNum.expTalentNum);
			}
		})();
	}, []);

	return (
		<React.Fragment>
			<Container maxWidth="xs" disableGutters>
				<Box className={classes.body}>
					<Box className={classes.values}>
						<Typography className={classes.proposition}>
							{content.proposition}
						</Typography>
						{content.values.map((value, idx) => (
							<Box key={idx} className={classes.value}>
								<img
									src={value.image}
									srcSet={`${value.image2x} 2x, ${value.image3x} 3x`}
									alt="value"
									className={classes.valueImg}
								></img>
								<Typography className={classes.valueTitle}>
									{value.title}
								</Typography>
								<Typography className={classes.valueDesc}>
									{value.desc}
								</Typography>
							</Box>
						))}
					</Box>
					<Box className={classes.statSection}>
						<Box className={classes.statTitleWrap}>
							<TXImage imgName={"icon-person-blue-24"} />
							<Typography className={classes.statTitle}>
								{content.stat.title}
							</Typography>
						</Box>
						<Box className={classes.statDesc}>
							{content.stat.desc}
						</Box>
						<Box className={classes.statValueWrap}>
							<Box>
								<Typography className={classes.statKey}>
									전체 개발자풀
								</Typography>
								<Typography className={classes.statValue}>
									{totalTalentNum}
								</Typography>
							</Box>
							<Box className={classes.verticalDivider}></Box>
							<Box>
								<Typography className={classes.statKey}>
									경력 개발자
								</Typography>
								<Typography className={classes.statValue}>
									{expTalentNum}
								</Typography>
							</Box>
						</Box>
						<TXImage
							imgName={"img-talent-profile-cards"}
							className={classes.statImg}
						/>
					</Box>
					<Box className={classes.clientsSection}>
						<Container maxWidth="lg">
							<Box className={classes.clientsTitle}>
								{content.clients.title}
							</Box>
							<Box className={classes.clientsDesc}>
								{content.clients.desc}
							</Box>
							<Box className={classes.clientsLogos}>
								<Grid container spacing={1}>
									{content.clients.logos.map((logo, idx) => (
										<Grid
											item
											xs={6}
											md={4}
											className={
												classes.clientLogoGridItem
											}
										>
											<img
												key={idx}
												src={logo}
												className={classes.clientLogo}
												alt="client logo"
											></img>
										</Grid>
									))}
								</Grid>
							</Box>
						</Container>
					</Box>
					<Box className={classes.reviewSection}>
						<Typography className={classes.reviewTitle}>
							{content.clientReview.title}
						</Typography>
						<Box className={classes.reviewCardWindow}>
							{content.clientReview.reviews.map((review, idx) => (
								<Box key={idx} className={classes.reviewCard}>
									<Box className={classes.reviewRating}>
										{Array.apply(
											null,
											Array(review.rating)
										).map((star, idx) => (
											<TXImage
												key={idx}
												imgName="icon-star-24"
												className={classes.reviewStar}
											/>
										))}
										{Array.apply(
											null,
											Array(5 - review.rating)
										).map((star, idx) => (
											<TXImage
												key={idx}
												imgName="icon-unstar-24"
												className={classes.reviewStar}
											/>
										))}
									</Box>
									<Typography
										className={classes.reviewRatingDesc}
									>
										{review.ratingDesc}
									</Typography>
									<Typography
										className={classes.reviewContent}
									>
										{review.content}
									</Typography>
									<TXImage
										imgName={review.clientLogo}
										className={classes.reviewClientLogo}
									/>
								</Box>
							))}
						</Box>
					</Box>
					<Box className={classes.targetUserSection}>
						<Typography className={classes.targetUserTitle}>
							{content.targetUser.title}
						</Typography>
					</Box>
					<Box className={classes.simpleSection}>
						<Box className={classes.simpleTitleWrapper}>
							<TXImage imgName={content.simple.icon} />
							<Typography className={classes.simpleTitle}>
								{content.simple.title}
							</Typography>
						</Box>
						<Typography className={classes.simpleDesc}>
							{content.simple.desc}
						</Typography>
						<Box className={classes.simpleContentTopWrapper}>
							<Typography
								className={classes.simpleContentTopDesc}
							>
								{content.simple.content[0].desc}
							</Typography>
							<TXImage
								className={classes.simpleContentTopImg}
								imgName={content.simple.content[0].image}
							/>
						</Box>

						<Box className={classes.simpleContentBottomLeftWrapper}>
							<Typography
								className={classes.simpleContentBottomLeftDesc}
							>
								{content.simple.content[1].desc}
							</Typography>
							<Typography
								className={classes.simpleContentBottomLeftMore}
							>
								{content.simple.content[1].more}
							</Typography>
							<TXImage
								className={classes.simpleContentBottomLeftImg}
								imgName={content.simple.content[1].image}
							/>
						</Box>
						<Box
							className={classes.simpleContentBottomRightWrapper}
						>
							<Typography
								className={classes.simpleContentBottomRightDesc}
							>
								{content.simple.content[2].desc}
							</Typography>
							<TXImage
								className={classes.simpleContentBottomRightImg}
								imgName={content.simple.content[2].image}
							/>
						</Box>
					</Box>

					<Box className={classes.profileSection}>
						<Box className={classes.profileTitleWrapper}>
							<TXImage imgName={content.profile.icon} />
							<Typography className={classes.profileTitle}>
								{content.profile.title}
							</Typography>
						</Box>
						<Typography className={classes.profileDesc}>
							{content.profile.desc}
						</Typography>
						<TXImage
							className={classes.profileContentTopImg}
							imgName={content.profile.content[1].image}
						/>
						<TXImage
							className={classes.profileContentMiddleIcon}
							imgName={content.profile.content[1].icon}
						/>
						<Typography
							className={classes.profileContentMiddleDesc}
						>
							{content.profile.content[1].desc}
						</Typography>
						<TXImage
							className={classes.profileContentBottomImg}
							imgName={content.profile.content[0].image}
						/>
					</Box>

					<Box className={classes.supportSection}>
						<Box className={classes.supportTitleWrapper}>
							<TXImage imgName={content.support.icon} />
							<Typography className={classes.supportTitle}>
								{content.support.title}
							</Typography>
						</Box>
						<Typography className={classes.supportDesc}>
							{content.support.desc}
						</Typography>
						<Typography className={classes.supportKeyword}>
							{content.support.keyword}
						</Typography>

						<Box className={classes.supportContentTopWrapper}>
							<TXImage
								className={classes.supportContentTopImg}
								imgName={content.support.content[0].image}
							/>
							<Typography
								className={classes.supportContentTopDesc}
							>
								{content.support.content[0].desc}
							</Typography>
						</Box>

						<Box
							className={classes.supportContentMiddleLeftWrapper}
						>
							<TXImage
								className={classes.supportContentMiddleLeftImg}
								imgName={content.support.content[1].image}
							/>
							<Typography
								className={classes.supportContentMiddleLeftDesc}
							>
								{content.support.content[1].desc}
							</Typography>
						</Box>
						<Box
							className={classes.supportContentMiddleRightWrapper}
						>
							<Box
								className={
									classes.supportContentMiddleRightChatLeftWrapper
								}
							>
								<TXImage
									className={
										classes.supportContentMiddleRightImg
									}
									imgName={content.support.content[2].image}
								/>
								<Box
									className={
										classes.supportContentMiddleRightChatLeftTextWrapper
									}
								>
									{content.support.content[2].chat1}
								</Box>
							</Box>
							<Box
								className={
									classes.supportContentMiddleRightChatRightWrapper
								}
							>
								<Box
									className={
										classes.supportContentMiddleRightChatRightTextWrapper
									}
								>
									{content.support.content[2].chat2}
								</Box>
							</Box>
							<Box
								className={
									classes.supportContentMiddleRightChatLeftWrapper
								}
							>
								<TXImage
									className={
										classes.supportContentMiddleRightImg
									}
									imgName={content.support.content[2].image}
								/>
								<Box
									className={
										classes.supportContentMiddleRightChatLeftTextWrapper
									}
								>
									{content.support.content[2].chat3}
								</Box>
							</Box>
						</Box>

						<Box
							className={classes.supportContentBottomLeftWrapper}
						>
							<Typography
								className={classes.supportContentBottomTitle}
							>
								{content.support.content[3].title}
							</Typography>
							<Typography
								className={classes.supportContentBottomDesc}
							>
								{content.support.content[3].desc}
							</Typography>
							<TXImage
								className={classes.supportContentBottomImg}
								imgName={content.support.content[3].image}
							/>
						</Box>
						<Box
							className={classes.supportContentBottomRightWrapper}
						>
							<Typography
								className={classes.supportContentBottomTitle}
							>
								{content.support.content[4].title}
							</Typography>
							<Typography
								className={classes.supportContentBottomDesc}
							>
								{content.support.content[4].desc}
							</Typography>
							<TXImage
								className={classes.supportContentBottomImg}
								imgName={content.support.content[4].image}
							/>
						</Box>
					</Box>
					<Box className={classes.brandingSection}>
						<Box className={classes.brandingTitleWrapper}>
							<Typography className={classes.brandingTitle}>
								{content.branding.title}
							</Typography>
						</Box>
						<Box className={classes.brandingDesc}>
							{content.branding.desc}
						</Box>
						<Box className={classes.brandingContentTopWrapper}>
							<Typography
								className={classes.brandingContentTopTitle}
							>
								{content.branding.content[0].title}
							</Typography>
							<Typography
								className={classes.brandingContentTopDesc}
							>
								{content.branding.content[0].desc}
							</Typography>

							<TXImage
								className={classes.brandingContentTopImg}
								imgName={content.branding.content[0].image}
							/>
						</Box>

						<Box className={classes.brandingContentMiddleWrapper}>
							<Typography
								className={classes.brandingContentMiddleTitle}
							>
								{content.branding.content[1].title}
							</Typography>
							<TXImage
								className={classes.brandingContentMiddleImg}
								imgName={content.branding.content[1].image}
							/>
						</Box>
						<Box className={classes.brandingContentBottomWrapper}>
							<TXImage
								className={classes.brandingContentBottomImg}
								imgName={content.branding.content[2].image}
							/>
						</Box>
					</Box>
					<Box className={classes.openscoutTopSection}>
						<Box className={classes.openscoutTitleWrapper}>
							<Typography className={classes.openscoutTitle}>
								{content.openscout.title}
							</Typography>
						</Box>
						<Box className={classes.openscoutDesc}>
							{content.openscout.desc}
						</Box>
						<Typography className={classes.openscoutDescMore}>
							{content.openscout.content[0].title}
						</Typography>
					</Box>

					<Box className={classes.openscoutMiddleSection}>
						<TXImage
							className={classes.openscoutContentImg}
							imgName={content.openscout.content[0].image}
						/>
					</Box>

					<Box className={classes.openscoutBottomSection}>
						<Box
							className={
								classes.openscoutContentMiddleTitleWrapper
							}
						>
							<TXImage
								imgName={content.openscout.content[1].image}
							/>
							<Typography
								className={classes.openscoutContentMiddleTitle}
							>
								{content.openscout.content[1].title}
							</Typography>
						</Box>
						<Typography
							className={classes.openscoutContentMiddleDesc}
						>
							{content.openscout.content[1].desc}
						</Typography>

						<Box className={classes.openscoutContentWrapper}>
							<TXImage
								imgName={content.openscout.content[2].image}
							/>
							<Typography
								className={classes.openscoutContentBottomTitle}
							>
								{content.openscout.content[2].title}
							</Typography>
							<Typography
								className={classes.openscoutContentBottomDesc}
							>
								{content.openscout.content[2].desc}
							</Typography>
						</Box>
						<Box className={classes.openscoutContentWrapper}>
							<TXImage
								imgName={content.openscout.content[3].image}
							/>
							<Typography
								className={classes.openscoutContentBottomTitle}
							>
								{content.openscout.content[3].title}
							</Typography>
							<Typography
								className={classes.openscoutContentBottomDesc}
							>
								{content.openscout.content[3].desc}
							</Typography>
						</Box>
						<Box className={classes.openscoutContentWrapper}>
							<TXImage
								imgName={content.openscout.content[4].image}
							/>
							<Typography
								className={classes.openscoutContentBottomTitle}
							>
								{content.openscout.content[4].title}
							</Typography>
							<Typography
								className={classes.openscoutContentBottomDesc}
							>
								{content.openscout.content[4].desc}
							</Typography>
						</Box>
					</Box>
					<Box className={classes.pricingSection}>
						<Typography className={classes.pricingTitle}>
							{content.pricing.title}
						</Typography>
						<Typography className={classes.pricingSubTitle}>
							{content.pricing.subtitle}
						</Typography>
						<Box className={classes.pricingContentLeftWrapper}>
							<Typography
								className={classes.pricingContentLeftTitle}
							>
								{content.pricing.plans[0].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[0].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[0].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentLeftPrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[0].services.map(
								(service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								)
							)}
						</Box>
						<Box className={classes.pricingContentMiddleWrapper}>
							<Typography
								className={classes.pricingContentMiddleTitle}
							>
								{content.pricing.plans[1].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[1].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[1].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentMiddlePrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[1].services
								.slice(0, 6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[1].services
								.slice(6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-light-blue"}
										/>
										<Typography
											className={
												classes.pricingContentMiddleService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
						</Box>
						<Box className={classes.pricingContentRightWrapper}>
							<Typography
								className={classes.pricingContentRightTitle}
							>
								{content.pricing.plans[2].name}
							</Typography>
							<Typography className={classes.pricingContentDesc}>
								{content.pricing.plans[2].desc}
							</Typography>
							<Box className={classes.pricingContentPriceWrapper}>
								{content.pricing.plans[2].prices.map(
									(price, index) => (
										<Typography
											className={
												classes.pricingContentRightPrice
											}
										>
											{price}
										</Typography>
									)
								)}
							</Box>
							{content.pricing.plans[2].services
								.slice(0, 6)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-grey"}
										/>
										<Typography
											className={
												classes.pricingContentLeftService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[2].services
								.slice(6, 7)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-light-blue"}
										/>
										<Typography
											className={
												classes.pricingContentMiddleService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
							{content.pricing.plans[2].services
								.slice(7)
								.map((service, index) => (
									<Box
										className={
											classes.pricingContentServiceWrapper
										}
									>
										<TXImage
											imgName={"icon-done-24-blue"}
										/>
										<Typography
											className={
												classes.pricingContentRightService
											}
										>
											{service}
										</Typography>
									</Box>
								))}
						</Box>
					</Box>
				</Box>
				{/* <RouterLink
                    to="/company-contract"
                    className={classes.fixedButtonRouteLink}
                >
                    <Box className={classes.fixedButton}>
                        <Typography className={classes.fixedButtonName}>
                            기업 멤버십 신청하기
                        </Typography>
                        <Typography className={classes.fixedButtonDesc}>
                            새로운 가격을 확인
                        </Typography>
                    </Box>
                </RouterLink> */}
			</Container>
		</React.Fragment>
	);
};

export default CompanyContent;
