import firebase from "firebase/app";
import { firestore } from "apis/firebase";

export const onJudgeOnApplicants = (selected, judge, result) => {
    const selectedTalentIds = selected;

    selectedTalentIds.forEach((selectedTalentId) =>
        firestore
            .collection("applicants")
            .doc(selectedTalentId)
            .update({
                result: result,
                records: firebase.firestore.FieldValue.arrayUnion({
                    date: new Date(),
                    name: judge,
                    result: result,
                    score: 100,
                }),
            })
            .then(function (docRef) {
                console.log(
                    "Document [" + selectedTalentId + "] successfully updated."
                );
            })
            .catch(function (error) {
                console.log("Error adding document: ", error);
            })
    );
};
