export const channelData = [
    "IT개발자 이직/취업 정보공유방",
    //"탤런트엑스 커뮤니티 사이트",
    "카카오톡 광고",
    //"탤런트엑스 멘토 추천",
    //"탤런트엑스 오리지널 멤버",
    "탤런트엑스 이메일",
    "SW마에스트로(SoMa)",
    //"삼성SW청년아카데미(SSAFY)",
    //"42서울",
    //"실리콘밸리 프리인턴십",
    //"멀티캠퍼스",
    "위코드(>wecode)",
    //"코드캠프({}code.camp)",
    //"{w}coding(더블유 코딩)",
    //"컬루런트",
    //"KxCoding",
    //"비단뱀 커뮤니티",
    "네이버 검색",
    "구글 검색",
    "페이스북 광고",
    "인스타그램 광고",
    "지인 및 동료 추천",
    "기타",
];

export const companyChannelData = [
    "KIC 실리콘밸리",
    "코리아 스타트업 포럼",
    "스파크랩",
    "위워크",
    "탤런트엑스 이메일",
    "다른 회사 추천",
    "회사 동료 추천",
    "지인 추천",
    "카카오톡 광고",
    "네이버 검색",
    "구글 검색",
    "페이스북 광고",
    "인스타그램 광고",
    "기타",
];
